import React from 'react';
import { FiltersCollapse } from 'application';
import { FiltersWrapper } from './styles';
import { CheckedFilterItem } from './CheckedFilterItem';

//this filter should not be displayed, used only to solve the problem with not displaying icons on filters.
export const BlindFilter = () => {
  return (<FiltersCollapse label={'Blind filter'} count={0} display={'none'}>
    <FiltersWrapper>
      <CheckedFilterItem label={'Blind filter'}/>
    </FiltersWrapper>
  </FiltersCollapse>);
}
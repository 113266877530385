import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { type LoginDataT } from "common";
import {
  UserPool,
  getSessionFromUser,
  login,
  logout,
} from "integration/cognito";
import { PathNames } from "application/routes";
import { useAppDispatch } from "application/store";
import { setIsAuth } from "../store/authStore";
import { handleGetSdrProfileAfterLogin } from "common/sdrProfile/store/actions";
import { handleGetNotifications } from "common/notificationsPage/store/actions";

export const useSignIn = () => {
  const [pending, setPending] = useState(false);
  const dispatch = useAppDispatch();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const singIn = useCallback(
    async ({ email, password }: LoginDataT) => {
      setError("");
      setPending(true);
      try {
        await login({ email, password });
        const cognitoUser = UserPool.getCurrentUser();
        if (cognitoUser) {
          const data: any = await getSessionFromUser(cognitoUser);
          const sdr = Object.entries(data.idToken.payload).find(
            (e) => e[1] === "SDR"
          );
          const orgManager = Object.entries(data.idToken.payload).find(
            (e) => e[1] === "ORG_MANAGER"
          );
          if (sdr || orgManager) {
            dispatch(setIsAuth(true));
            navigate(PathNames.companies);
            dispatch(handleGetSdrProfileAfterLogin());
            dispatch(handleGetNotifications());
          } else {
            logout();
            setError("That email and password combination is incorrect");
          }
        } else {
          logout();
          setError("That email and password combination is incorrect");
        }
      } catch (error: any) {
        if (error.message === "User is disabled.") {
          setError("User is disabled");
        } else {
          setError("That email and password combination is incorrect");
        }
      } finally {
        setPending(false);
      }
    },
    [navigate]
  );

  return { singIn, pending, error };
};

import { ThunkDispatchT, VoidThunk } from "application/types";
import {
  companySortSelector,
  filteredCompaniesSelector,
  filterFormSelector,
  hideCompanyContactsLoader,
  setCompaniesExtraInformation,
  setCompanyContacts,
  setExtraInformationIsLoading,
  showCompanyContactsLoader
} from './index';
import {
  handleErrorNotification,
  showLoader,
  hideLoader,
} from "common";
import {ISavedSearchesData} from "common/companies/types"
import {
  CompanyI,
  CompanyFilterDataI,
  FilterSavedDataI,
} from "integration/api/companies/types";
import {
  hideCompanyInfoLoader,
  hideLoaderStructure,
  setCompanyStructure,
  setFilterFormData,
  setFilteredCompanies,
  setSavedSearches,
  setSelectedCompany,
  setSelectedSearch,
  setSubContractorCategories,
  setTags,
  showCompanyInfoLoader,
  showExportResult,
  showLoaderStructure,
  showNotify,
  setPermitRangeValues,
} from ".";
import {
  deleteOrganizationFromNotAGoodFit,
  deleteSavedFilteredSearch,
  getAllTags,
  getCompaniesExtraInfo,
  getCompanyById,
  getCompanyContacts,
  getCompanyStructure,
  getCompanyPermitRangeValues,
  getSavedFilteredSearches,
  getSavedSearchById,
  getSubContractorCategories,
  saveFilteredSearches,
  searchCompanies,
  setOrganizationAsNotAGoodFit,
} from "integration/api/companies";
import {
  mapPermitRangeValuesResponse,
  prepareFilterToRender,
  prepareFilterToSave,
  prepareToSearch,
} from "./utils";
import {
  addEmployeesToBulkExport,
  exportContacts,
} from "integration/api/bulkExports";
import { handleResponse } from "common/responseService/responseService";
import { ExportDataI } from "common/bulkExport/store/types";
import { ExportedCompaniesRespI } from "integration/api/bulkExports/types";
import { setExportedContactsTypes } from "common/bulkExport/store";
import { SortBy } from "../companiesFilter/const";
import { SortOrder } from 'application/types';

export const handleGetSavedSearches = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());

    try {
      const response: ISavedSearchesData = await getSavedFilteredSearches();
      dispatch(setSavedSearches(response));
    } catch (error) {
      dispatch(setSavedSearches([]));
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleGetCompanyStructure = (
  id: number,
  page: number
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoaderStructure());
    try {
      const response: any = await getCompanyStructure(id, page);
      dispatch(setCompanyStructure(response));
    } catch (error) {
      dispatch(setCompanyStructure({}));
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoaderStructure());
    }
  };
};

export const handleGetCompanyInfo = (id: number) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showCompanyInfoLoader());
    try {
      const response: CompanyI = await getCompanyById(id);
      dispatch(setSelectedCompany(response));
      dispatch(setCompanyStructure({}));
    } catch (error) {
      dispatch(setSelectedCompany({}));
      handleErrorNotification(error);
    } finally {
      dispatch(hideCompanyInfoLoader());
    }
  };
};

export const handleDeleteSavedSearch = (userId: number): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await deleteSavedFilteredSearch(userId);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      await dispatch(handleGetSavedSearches());
      await dispatch(setSelectedSearch({}));
    }
  };
};
export const handleSearchCompanies = (
  filterData: CompanyFilterDataI,
  page: number,
  size = 20
) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    const preparedFilter = prepareToSearch(filterData);
    try {
      const { sortBy, sortOrder } = companySortSelector();
      const response: any = await searchCompanies(preparedFilter, page, size, sortBy, sortOrder);
      dispatch(setFilteredCompanies(response));
      dispatch(setSelectedCompany({}));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleSearchCompaniesWithoutLoader = (
  filterData: CompanyFilterDataI,
  page: number,
  size = 20
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    const preparedFilter = prepareToSearch(filterData);
    try {
      const { sortBy, sortOrder } = companySortSelector();
      const response: any = await searchCompanies(preparedFilter, page, size, sortBy, sortOrder);
      dispatch(setFilteredCompanies(response));
    } catch (error) {
      handleErrorNotification(error);
    }
  };
};

export const handleSortCompanies = (
  sorting: {
    sortBy: SortBy;
    sortOrder: SortOrder;
  }
) => {
  return async (dispatch: ThunkDispatchT) => {
    const { sortBy, sortOrder } = sorting;
    dispatch(showLoader());
    const filterData = filterFormSelector();
    const { size } = filteredCompaniesSelector();
    const preparedFilter = prepareToSearch(filterData);
    try {
      const response: any = await searchCompanies(preparedFilter, 0, size, sortBy, sortOrder);
      dispatch(setFilteredCompanies(response));
      dispatch(setSelectedCompany({}));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const handleGetSavedSearchById = (userId: number): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    try {
      const response: ISavedSearchesData = await getSavedSearchById(userId);
      const preparedResponse = prepareFilterToRender(response);
      dispatch(setSelectedSearch(preparedResponse));
      dispatch(setFilterFormData(preparedResponse.filterValue));
      dispatch(handleSearchCompanies(preparedResponse.filterValue, 0));
    } catch (error) {
      handleErrorNotification(error);
    }
  };
};

export const handleSaveFilteredSearches = (
  filterSavedData: FilterSavedDataI
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    const preparedFilteredData = prepareFilterToSave(filterSavedData);
    try {
      await saveFilteredSearches(preparedFilteredData);
      dispatch(handleGetSavedSearches());
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleGetFiltersList = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const tags: string[] = await getAllTags();
      const subContractorCategories: string[] =
        await getSubContractorCategories();
      const permitRangeValues = await getCompanyPermitRangeValues();

      dispatch(setSubContractorCategories(subContractorCategories));
      dispatch(setTags(tags));
      dispatch(setPermitRangeValues(mapPermitRangeValuesResponse(permitRangeValues)));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleAddDataToBulkExport = (data: any) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await addEmployeesToBulkExport(data);
      return true;
    } catch (error) {
      handleErrorNotification(error);
      return false;
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleExportOrganization = (
  exportData: ExportDataI,
  type?: string
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: ExportedCompaniesRespI = await exportContacts(exportData);
      handleResponse({
        message: `Contact was successfully exported!`,
      });
      if(type === "CSV") {
        await dispatch(setExportedContactsTypes(response));
        await dispatch(showExportResult());
      }
      response.s3FileUrl &&
        window.open(response.s3FileUrl, "_blank", "noreferrer");
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleChangeOrganizationNotAGoodFitStatus = (
  id: number, notAGoodFit: boolean
) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      if(notAGoodFit) {
        await setOrganizationAsNotAGoodFit(id);
      } else {
        await deleteOrganizationFromNotAGoodFit(id);
      }
      return true;
    } catch (error) {
      handleErrorNotification(error);
      return false;
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const handleGetCompanyContacts = (
    id: number
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showCompanyContactsLoader());
    try {
      const response: any = await getCompanyContacts(id);
      dispatch(setCompanyContacts(response));
      handleResponse({
        message: `1 credit was charged for Unlock Contact Data.`,
      });
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideCompanyContactsLoader());
    }
  };
}

export const handleGetCompaniesExtraInformation = (
  companyIds: number[]
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(setExtraInformationIsLoading(true));
    try {
      const response: any = await getCompaniesExtraInfo(companyIds);
      dispatch(setCompaniesExtraInformation(response));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(setExtraInformationIsLoading(false));
    }
  };
}

import styled from "@emotion/styled";
import { Box, CircularProgress } from "@mui/material";
import theme from "application/theme";

export const LoaderWrapper = styled(Box)`
  width: ${theme.spacing(60)};
  height: ${theme.spacing(25)};
  background-color: ${theme.palette.common.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${theme.spacing(1)};
`;

export const StyledCircularProgress = styled(CircularProgress)`
  color: ${theme.palette.common.blue700};
  animation-duration: 550ms;
  position: absolute;
  left: 0;

  & .MuiCircularProgress-circle {
    stroke-linecap: round;
  }
`;

import { Add } from "@mui/icons-material";
import { Button } from "application";
import useToggleModal from "application/hooks/useToggleModal";
import theme from "application/theme";
import React from "react";
import { AddCreditsForCompanyModal } from "common/organization/modals/AddCreditsForCompanyModal";
import { useAppDispatch, useAppSelector } from "application/store";
import {
  closeAddCreditsModal,
  openAddCreditsModal,
  organizationsSelector,
} from "common/organization/store";

export const RequestHistoryInfo = () => {
  const dispatch = useAppDispatch();
  const { isAddCreditsModalOpen } = useAppSelector(organizationsSelector);

  const openModal = () => {
    dispatch(openAddCreditsModal());
  };

  const closeModal = () => {
    dispatch(closeAddCreditsModal());
  };

  return (
    <>
      <Button
        label="Request new credits"
        startAdornment={<Add sx={{ color: theme.palette.common.grey800 }} />}
        height={5.5}
        onClick={openModal}
        type="button"
      />
      <AddCreditsForCompanyModal
        isOpen={isAddCreditsModalOpen}
        handleClose={closeModal}
      />
    </>
  );
};

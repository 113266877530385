import { createSlice } from "@reduxjs/toolkit";
import {
  PermitWithCompanyFilterDataI,
  PermitsInfo,
  companyDataResponse,
  permitsDataResponse,
  PermitsDisplayMode,
  PermitAffiliationType,
  ZoomedAreaI,
  PermitInfoExtendedI,
  PermitFilterDataI,
  PermitStatusI,
  FilterStatus,
  SavedPermitAndCompanyFiltersI,
} from "common/permits/types";
import { store } from "application/store";
import { CompanyFilterDataI, CompanyI } from "integration/api/companies/types";
import { emptyPageResponse } from 'application/types';

export const permitStatusInitialState: PermitStatusI[] = [
  {
    status: FilterStatus.inReview,
    current: false,
    to: null,
    from: null,
  },
  {
    status: FilterStatus.active,
    current: false,
    to: null,
    from: null,
  },
  {
    status: FilterStatus.final,
    current: false,
    to: null,
    from: null,
  },
];
export const permitFilterInitialState: PermitFilterDataI = {
  permitValue: {
    min: undefined,
    max: undefined,
  },
  squareFootage: {
    min: undefined,
    max: undefined,
  },
  affiliations: [],
  tags: [],
  permitTypes: [],
  dwellingTypes: [],
  statusFilter: permitStatusInitialState,
  productTypes: [],
  locations: {
    statesInclude: [],
    statesExclude: [],
    citiesInclude: [],
    citiesExclude: [],
    zipCodesInclude: [],
    zipCodesExclude: [],
    countiesInclude: [],
    countiesExclude: [],
  },
}

export const companyFilterInitialState: CompanyFilterDataI = {
  companyId: null,
  name: "",
  companySizeRange: [],
  annualRevenueRange: [],
  yearsInBusinessRange: [],
  isSubContractor: false,
  isGeneralContractor: false,
  subContractorCategories: [],
  hasPermit: false,
  permitFilters: {
    annualPermitCount: undefined,
    annualPermitValue: undefined,
  },
  serviceType: [],
  reputationScore: "",
  permit: false,
  skipExported: false,
  tags: [],
  citiesInclude: [],
  citiesExclude: [],
  statesInclude: [],
  statesExclude: [],
  location: "BY_STATE",
  notGoodFit: null,
  productTypes: [],
}
const filterInitialValues: PermitWithCompanyFilterDataI = {
  companyFilter: companyFilterInitialState,
  permitFilter: permitFilterInitialState,
};

const initialState = {
  foundPermits: permitsDataResponse,
  foundCompanies: companyDataResponse,
  permitsLoading: false,
  companiesLoading: false,
  filterFormData: filterInitialValues,
  searchChanged: false,
  savedSearches: [] as SavedPermitAndCompanyFiltersI[],
  selectedSavedSearch: null as SavedPermitAndCompanyFiltersI | null,
  tags: [],
  permitTypes: [],
  dwellingTypes: [],
  permitStatus: [],
  productTypes: [],
  squareFeetRange: null,
  permitValueRange: null,
  selectedPermitsColumns: [
    "statusNormalized",
    "fileDate",
    "permitDate",
    "finalDate",
    "jobValue",
    "city",
    "state",
    "description",
    "contractorBizNameOriginal",
    "id",
  ],
  selectedCompaniesColumns: [
    "businessName",
    "reputationScore",
    "companyContactInfo",
    "serviceType",
    "generalContractorCategory",
    "permit",
    "avgAnnualValue",
    "id",
  ],
  permitMapView: emptyPageResponse,
  companyMapView: emptyPageResponse,
  mapIsLoading: false,
  showTooltip: false,
  showOnMap: false,
  showOnMapItemId: null,
  mapInitialView: {
    latitude: 40.67,
    longitude: -103.59,
    zoom: 3,
  },
  zoomedArea: {} as ZoomedAreaI,
  searchInArea: false,
  companiesInfo: [] as CompanyI[],
  permitsInfo: [] as PermitsInfo[],
  selectedPermit: {
    isLoading: false,
    data: null as PermitInfoExtendedI | null,
  },
  tooltipLoading: false,
  viewSettings: {
    mapView: {
      viewModes: [PermitsDisplayMode.COMPANIES, PermitsDisplayMode.PERMITS],
      executorTypes: [
        PermitAffiliationType.OWNER,
        PermitAffiliationType.DESIGNER,
        PermitAffiliationType.ARCHITECT,
        PermitAffiliationType.ENGINEER,
        PermitAffiliationType.CONTRACTOR_COMPANY,
      ],
    },
  },
};

export const permitsSlice = createSlice({
  name: "permits",
  initialState,
  reducers: {
    setFoundPermits(state, action) {
      state.foundPermits = action.payload;
    },
    setFoundCompanies(state, action) {
      state.foundCompanies = action.payload;
    },
    setSearchInArea(state, action) {
      state.searchInArea = action.payload;
    },
    setPermitsLoading(state, action) {
      state.permitsLoading = action.payload;
    },
    setCompaniesLoading(state, action) {
      state.companiesLoading = action.payload;
    },
    //
    setFilterFormData(state, action) {
      state.filterFormData = action.payload;
    },
    setPermitTags(state, action) {
      state.tags = action.payload;
    },
    setPermitTypes(state, action) {
      state.permitTypes = action.payload;
    },
    setDwellingTypes(state, action) {
      state.dwellingTypes = action.payload;
    },
    setSquareFeetRange(state, action) {
      state.squareFeetRange = action.payload;
    },
    setPermitValueRange(state, action) {
      state.permitValueRange = action.payload;
    },
    setSelectedPermitsColumns(state, action) {
      state.selectedPermitsColumns = action.payload;
    },
    setSelectedCompaniesColumns(state, action) {
      state.selectedCompaniesColumns = action.payload;
    },
    setCompaniesInfo(state, action) {
      state.companiesInfo = action.payload;
    },
    setPermitsInfo(state, action) {
      state.permitsInfo = action.payload;
    },
    setTooltipLoading(state, action) {
      state.tooltipLoading = action.payload;
    },
    setMapViewModes(state, action) {
      state.viewSettings.mapView.viewModes = action.payload;
    },
    setMapViewCompaniesTypes(state, action) {
      state.viewSettings.mapView.executorTypes = action.payload;
    },
    setPermitProductTypes(state, action) {
      state.productTypes = action.payload;
    },
    setSearchChanged(state, action) {
      state.searchChanged = action.payload;
    },
    // Map view
    setMapInitialView(state, action) {
      state.mapInitialView = action.payload;
    },
    setZoomedArea(state, action) {
      state.zoomedArea = action.payload;
    },
    setPermitMapView(state, action) {
      state.permitMapView = action.payload;
    },
    setCompanyMapView(state, action) {
      state.companyMapView = action.payload;
    },
    setMapIsLoading(state, action) {
      state.mapIsLoading = action.payload;
    },
    setShowTooltip(state, action) {
      state.showTooltip = action.payload;
    },
    setShowOnMap(state, action) {
      state.showOnMap = action.payload;
    },
    setShowOnMapItemId(state, action) {
      state.showOnMapItemId = action.payload;
    },
    // permit info
    setSelectedPermit(state, action) {
      state.selectedPermit.data = action.payload;
    },
    setPermitInfoLoading(state, action) {
      state.selectedPermit.isLoading = action.payload;
    },
    setSavedSearches(state, action) {
      state.savedSearches = action.payload;
    },
    setSelectedSavedSearch(state, action) {
      state.selectedSavedSearch = action.payload;
    }
  },
});

export const permitsSelector = () => store.getState().permits;
export const foundPermitsSelector = () => store.getState().permits.foundPermits;
export const foundPermitsOnMapSelector = () => store.getState().permits.permitMapView;
export const foundCompaniesOnMapSelector = () => store.getState().permits.companyMapView;
export const permitFiltersSelector = () => store.getState().permits.filterFormData.permitFilter;
export const companyFiltersSelector = () => store.getState().permits.filterFormData.companyFilter;
export const selectedPermitSelector = () => store.getState().permits.selectedPermit.data;

export const foundCompaniesSelector = () =>
  store.getState().permits.foundCompanies;

export const {
  // Search table data
  setFoundPermits,
  setFoundCompanies,
  setPermitsLoading,
  setCompaniesLoading,
  setSearchInArea,
  //
  setFilterFormData,
  setPermitTags,
  setPermitTypes,
  setDwellingTypes,
  setSquareFeetRange,
  setPermitValueRange,
  setSelectedPermitsColumns,
  setSelectedCompaniesColumns,
  setCompaniesInfo,
  setPermitsInfo,
  setTooltipLoading,
  setMapViewModes,
  setMapViewCompaniesTypes,
  setPermitProductTypes,
  setSearchChanged,
  //Map View
  setMapIsLoading,
  setShowTooltip,
  setMapInitialView,
  setZoomedArea,
  setPermitMapView,
  setCompanyMapView,
  setShowOnMap,
  setShowOnMapItemId,
  // permit info
  setSelectedPermit,
  setPermitInfoLoading,
  //saved search,
  setSavedSearches,
  setSelectedSavedSearch,
} = permitsSlice.actions;

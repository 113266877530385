import { createSlice } from "@reduxjs/toolkit";
import { store } from "application/store";
import {
  ManagementCreditsType,
  OrgRequestHistoryI,
  OrganizationProfile,
  SubscriptionsI,
  TeamMemberI,
} from "./types";

export const initialState = {
  organizationProfile: {} as OrganizationProfile,
  changedCredits: [] as ManagementCreditsType[],
  managementTeamCreditsError: "",
  isAddMemberModalOpen: false,
  isAddCreditsModalOpen: false,
  isLoadingAddMember: false,
  editModalOpen: false,
  requestHistory: [] as OrgRequestHistoryI[],
  teamMembers: [] as TeamMemberI[],
  subscriptions: [] as SubscriptionsI[],
};

export const organizationsSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setOrganizationProfile: (state, action) => {
      state.organizationProfile = action.payload;
    },
    setChangedCredits: (state, { payload }) => {
      state.changedCredits = payload;
    },
    setManagementTeamCreditsError: (state, { payload }) => {
      state.managementTeamCreditsError = payload;
    },
    setIsAddMembeModalOpen: (state, { payload }) => {
      state.isAddMemberModalOpen = payload;
    },
    setIsLoadingAddMember: (state, { payload }) => {
      state.isLoadingAddMember = payload;
    },
    openEditModal: (state) => {
      state.editModalOpen = true;
    },
    closeEditModal: (state) => {
      state.editModalOpen = false;
    },
    openAddCreditsModal: (state) => {
      state.isAddCreditsModalOpen = true;
    },
    closeAddCreditsModal: (state) => {
      state.isAddCreditsModalOpen = false;
    },
    setRequestHistory: (state, { payload }) => {
      state.requestHistory = payload;
    },
    setTeamMembers: (state, { payload }) => {
      state.teamMembers = payload;
    },
    setSubscriptions: (state, { payload }) => {
      state.subscriptions = payload;
    },
    cleanCompaniesStore: (state) => {
      state.changedCredits = initialState.changedCredits;
      state.organizationProfile = initialState.organizationProfile;
      state.requestHistory = initialState.requestHistory;
      state.teamMembers = initialState.teamMembers;
      state.isLoadingAddMember = initialState.isLoadingAddMember;
      state.isAddMemberModalOpen = initialState.isAddMemberModalOpen;
      state.editModalOpen = initialState.editModalOpen;
      state.managementTeamCreditsError =
        initialState.managementTeamCreditsError;
      state.subscriptions = initialState.subscriptions;
      state.isAddCreditsModalOpen = initialState.isAddCreditsModalOpen;
    },
  },
});

export default organizationsSlice.reducer;

export const organizationsSelector = () => store.getState().organizations;

export const { setOrganizationProfile } = organizationsSlice.actions;
export const { setChangedCredits } = organizationsSlice.actions;
export const { setManagementTeamCreditsError } = organizationsSlice.actions;
export const { setIsAddMembeModalOpen } = organizationsSlice.actions;
export const { setIsLoadingAddMember } = organizationsSlice.actions;
export const { setRequestHistory } = organizationsSlice.actions;
export const { setTeamMembers } = organizationsSlice.actions;
export const { setSubscriptions } = organizationsSlice.actions;
export const { cleanCompaniesStore } = organizationsSlice.actions;

export const { openEditModal } = organizationsSlice.actions;
export const { openAddCreditsModal } = organizationsSlice.actions;
export const { closeAddCreditsModal } = organizationsSlice.actions;
export const { closeEditModal } = organizationsSlice.actions;

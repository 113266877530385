import React from "react";

import { Button } from "application";
import { CsvIcon } from "application/assets/CsvIcon";
import { InventoryIcon } from "application/assets/InventoryIcon";

import theme from "application/theme";
import { Box, Divider, Typography } from "@mui/material";
import { ModalComponent } from "application/components/ModalComponent";
import { useAppSelector } from "application/store";
import { selectedPermitSelector } from "../../store";
import CreditsRequiredItem from "./CreditsRequiredItem";
import { useCompaniesAndPermitsExport } from 'common/permits/hooks/useCompaniesAndPermitsExport';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ExportPermitModal = ({
                                    isOpen, onClose
                                  }: Props) => {
  const {exportPermit, addPermitToBulkExport} = useCompaniesAndPermitsExport();
  const selectedPermit = useAppSelector(selectedPermitSelector);

  const handleAddToBulkExport = async () => {
    if (!selectedPermit?.id) return;
    await addPermitToBulkExport(selectedPermit.id);
    onClose();
  };

  const handleDownloadCsv = async () => {
    if (!selectedPermit?.id) return;
    exportPermit(selectedPermit.id);
    onClose();
  };

  return (
    <>
      <ModalComponent
        isOpen={isOpen}
        handleClose={onClose}
        padding={theme.spacing(3)}
        title={"Export permit data"}
        description={"This data includes only permit information."}
        width={475}
      >
        <Box
          mt={theme.spacing(4)}
          textAlign="center"
          display={"flex"}
          flexDirection={"column"}
          gap={theme.spacing(3)}
        >
          <Button
            label="Add to bulk export"
            height={5.5}
            width={"100%"}
            type="button"
            startAdornment={<InventoryIcon/>}
            onClick={handleAddToBulkExport}
          />
          <Box display={"flex"} gap={3} alignItems="center">
            <Divider
              sx={{width: "42%", color: theme.palette.common.grey400}}
            />
            <Typography variant="body2" color={theme.palette.common.grey600}>
              OR
            </Typography>
            <Divider
              sx={{width: "42%", color: theme.palette.common.grey400}}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={theme.spacing(2)}>
            <Typography
              variant="button"
              color={theme.palette.common.grey900}
              textAlign="start"
            >
              Export single record:
            </Typography>
            <Button
              label="CSV"
              height={5.5}
              width={"100%"}
              type="button"
              onClick={handleDownloadCsv}
              startAdornment={<CsvIcon/>}
            />
            <Box display="flex" flexDirection="column" alignItems="start">
              <CreditsRequiredItem label={'Required company credits balance:'} value={1}/>
            </Box>
          </Box>
        </Box>
      </ModalComponent>
    </>
  );
};

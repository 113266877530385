import { CircularProgress, circularProgressClasses } from "@mui/material";
import { Box } from "@mui/system";
import theme from "application/theme";
import React from "react"

export const SmallLoader = ({ size, width }: { size: number; width?: number }) => {
    return (
        <Box position="relative" width={size} height={size}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: theme.palette.common.blue100,
          }}
          size={size}
          thickness={4}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: theme.palette.common.blue700,
            animationDuration: "550ms",
            position: "absolute",
            left: 0,
            bottom: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          size={size}
          thickness={4}
        />
      </Box>
    )
}
import React from 'react';
import { ToggleButtonStyled } from './styles';

interface Props {
  checked: boolean;
  onChange: (event: React.ChangeEvent) => void;
}
export const ToggleButton = ({checked, onChange}: Props) => {
  return (
    <ToggleButtonStyled
      checked={checked}
      onChange={onChange}
    />
  )
}
import { Box, IconButton, Typography, styled, Slider } from "@mui/material";

import theme from "application/theme";
import {InputStyled} from "application/components/FormInput/styles";

export const CollapseLabelWrapper = styled(Typography)`
  color: ${theme.palette.common.grey700};
  width: 100%;
  display: flex;
  height: ${theme.spacing(2.5)};
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const RangeSelectorTitleStyled = styled(Typography)`
  color: ${theme.palette.common.grey700};
  width: 100%;
`
export const RangeSelectorSliderStyled = styled(Slider)`
  & .MuiSlider-thumb {
    color: ${theme.palette.common.white};
    border: 2px solid ${theme.palette.common.blue600};
    width: ${theme.spacing(2.5)};
    height: ${theme.spacing(2.5)};
    :hover {
      box-shadow: 0 0 0 3px ${theme.palette.common.blue600}2A;
      z-index: 10;
      & .MuiSlider-valueLabel {
         background-color: ${theme.palette.common.grey150};
      }
    }
    &[data-index="0"] {
      & .MuiSlider-valueLabel {
        left: ${theme.spacing(-1)};
        background-color: ${theme.palette.common.grey150};
      }
    }
    &[data-index="1"] {
      & .MuiSlider-valueLabel {
        right: ${theme.spacing(-1)};
        z-index: 5;
        background-color: ${theme.palette.common.grey150};
      }
    }
  }
  & .MuiSlider-rail {
    color: ${theme.palette.common.grey400};
    height: ${theme.spacing(0.75)};
    border-radius: ${theme.spacing(0.5)};
  }
  .MuiSlider-track {
    color: ${theme.palette.common.blue600};
  }
  .MuiSlider-valueLabel {
    padding: ${theme.spacing(0.25, 0.5)};
    background-color: unset;
    color: ${theme.palette.common.grey900};
    top: ${theme.spacing(6)};
    :before {
      display: none;
    }
  }
`

export const RangeSelectorWrapperStyled = styled(Box)`
  padding: ${theme.spacing(0.5, 0.5, 1)};
  margin-top: ${theme.spacing(0.5)};
`

export const HasPermitsSwitcherStyled = styled(Box)`
  color: ${theme.palette.common.grey900};
  display: flex;
  gap: ${theme.spacing(0.5)};
  align-items: center;
  height: ${theme.spacing(4)};
`

export const IconButtonStyled = styled(IconButton)`
  &:hover:enabled {
    background-color: transparent;
    border: 1px solid ${theme.palette.common.blue700};
    svg path {
      fill: ${theme.palette.common.blue700};
    }
  }
  width: ${theme.spacing(2.5)};
  height: ${theme.spacing(2.5)};
  padding: 0;
`;

export const RemoveIconButtonStyled = styled(IconButtonStyled)`
  &:hover {
    svg rect {
      fill: ${theme.palette.common.blue700};
    }
  }
`;

export const ButtonsWrapper = styled(Box)`
  display: flex;
  border: none;
  align-items: center;
  border-radius: ${theme.spacing(0.5)};
  width: ${theme.spacing(5)};
  height: ${theme.spacing(3)};
  margin-right: ${theme.spacing(1)};
`;

export const PermitInputStyled = styled(InputStyled)`
  height: ${theme.spacing(3)};
  margin-bottom: ${theme.spacing(1)};
    & .MuiInputBase-input {
      text-align: right;
      font-size: ${theme.typography.caption.fontSize};
      padding: ${theme.spacing(0.5)};
    }
`

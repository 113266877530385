import React from 'react';
import { useAppSelector } from 'application/store';
import { filterFormSelector } from 'common/companies/store';
import { FiltersCollapse } from 'application';
import { FiltersWrapper, FilterTitleStyled, FilterGroupWrapper } from './styles';
import { CheckedFilterItem } from './CheckedFilterItem';

export const LocationFilter = () => {
  const {citiesInclude, citiesExclude, statesInclude, statesExclude} = useAppSelector(filterFormSelector);
  const filtersCount = citiesInclude.length + citiesExclude.length + statesInclude.length + statesExclude.length;
  return filtersCount > 0 ? (
    <FiltersCollapse label={'LOCATION'} count={filtersCount}>
      <FiltersWrapper>
        {statesInclude.length > 0 && <LocationsFilterGroup label={'States include'} locations={statesInclude}/>}
        {statesExclude.length > 0 &&
          <LocationsFilterGroup excludeFilter label={'States exclude'} locations={statesExclude}/>}
        {citiesInclude.length > 0 && <LocationsFilterGroup label={'Metro area include'} locations={citiesInclude}/>}
        {citiesExclude.length > 0 &&
          <LocationsFilterGroup excludeFilter label={'Metro area exclude'} locations={citiesExclude}/>}
      </FiltersWrapper>
    </FiltersCollapse>
  ) : null;
}

const LocationsFilterGroup = ({label, locations, excludeFilter}: {
  label: string,
  locations: string[],
  excludeFilter?: boolean
}) => {
  return (
    <FilterGroupWrapper>
      <FilterTitleStyled>{label}</FilterTitleStyled>
      {locations.map((location) => (
        <CheckedFilterItem isExcluded={excludeFilter} label={location} key={location}/>
      ))}
    </FilterGroupWrapper>
  )
}
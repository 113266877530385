import React from 'react';
import { Button } from 'application';
import { ExportAllIcon } from 'application/assets';
import { useLargeBulkExportNew } from 'common/permits/components/largeBulkExport/hooks/useLargeBulkExportNew';
import { LargeBulkExportScope } from 'common/largeBulkExport/store/types';

interface Props {
  byPermitFilters?: boolean;
}
export const StartExportCompaniesButton = () => {
  const { startBulkExport, companiesFound } = useLargeBulkExportNew();
  return (
    <Button
      disabled={companiesFound === 0}
      startAdornment={<ExportAllIcon/>}
      label="Export all"
      onClick={() => startBulkExport(LargeBulkExportScope.COMPANIES)}
      height={5.5}
      type="submit"
    />
  )
}

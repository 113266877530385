import React from 'react';
import { useAppSelector } from 'application/store';
import { FiltersCollapse } from 'application';
import { FiltersWrapper } from '../components/styles';
import { CheckedFilterItem } from '../components/CheckedFilterItem';
import { getReputationScoreLabel } from 'common/companies/companiesFilter/utils';
import { companyFiltersSelector } from 'common/permits/store';

export const VisibilityScoreFilter = () => {
  const {reputationScore} = useAppSelector(companyFiltersSelector);
  return reputationScore ? (
    <FiltersCollapse label={'VISIBILITY SCORE'} count={1}>
      <FiltersWrapper>
        <CheckedFilterItem label={getReputationScoreLabel(reputationScore)}/>
      </FiltersWrapper>
    </FiltersCollapse>
  ) : <></>;
};

import React, { useMemo } from 'react';
import theme from 'application/theme';
import { TableCell, TableRow, Typography } from '@mui/material';
import { CompanyI } from 'integration/api/companies/types';
import { ReputationScoreStyled, WebsiteLinkStyled } from './styles';
import { showReputationScore } from 'common/companies/companiesList/utils';
import { ArrowOutward } from 'application/assets/ArrowOutward';
import { getAbsoluteLink, getNumberFormatWithSeparators } from 'application/utils';
import { getStringFirstUppercaseLetter } from 'application/utils/stringUtils';
import CompanyItemActions from './CompanyItemActions';
interface Props {
  company: CompanyI;
  onShowCompanyInfo: () => void;
}
const CompanyTableItem = ({company, onShowCompanyInfo}: Props) => {
  const {
    id,
    businessName,
    reputationScore,
    serviceType,
    generalContractorCategory,
    subContractorCategory,
    companyContactInfo,
    avgAnnualValue,
    permit
  } = company;
  const reputationScoreDisplay = reputationScore ?
    `${showReputationScore(reputationScore)}: ${Math.floor(reputationScore/10)}+` : "-";

  const serviceTypeDisplay = serviceType ?
    getStringFirstUppercaseLetter(serviceType.replaceAll('_', ' ')) : "-";

  const categoryDisplay = useMemo(() => {
    let category = "-";
    if (generalContractorCategory) {
      category = 'General Contractor';
    } else if (subContractorCategory) {
      category = "Subcontractor, " + subContractorCategory;
    }
    return category;
  }, [generalContractorCategory, subContractorCategory]);

  const averagePermitValueDisplay = avgAnnualValue ? `$${getNumberFormatWithSeparators(avgAnnualValue)}` : "-";
  return (
    <TableRow
      key={id}
      sx={{
        height: theme.spacing(7.5),
      }}
    >
      <TableCell component="th" scope="row">
        <Typography
          variant="caption"
          color={theme.palette.common.grey800}
        >
          {businessName || "-"}
        </Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <ReputationScoreStyled
          score={reputationScore}
          variant="caption"
          color={theme.palette.common.grey800}
        >
          {reputationScoreDisplay}
        </ReputationScoreStyled>
      </TableCell>
      <TableCell component="th" scope="row">
        {
          companyContactInfo?.website ?
            <WebsiteLinkStyled
              target="_blank"
              href={getAbsoluteLink(companyContactInfo.website) || "#"}>
              <Typography
                variant="caption"
                color={theme.palette.common.grey800}
              >
                Website
              </Typography>
              <ArrowOutward color={theme.palette.common.grey900}/>
            </WebsiteLinkStyled>
            : "-"
        }
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography
          variant="caption"
          color={theme.palette.common.grey800}
        >
          {serviceTypeDisplay}
        </Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography
          variant="caption"
          color={theme.palette.common.grey800}
        >
          {categoryDisplay}
        </Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography
          variant="caption"
          color={theme.palette.common.grey800}
        >
          {permit ? "Yes" : "No"}
        </Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <Typography
          variant="caption"
          color={theme.palette.common.grey800}
        >
          {averagePermitValueDisplay}
        </Typography>
      </TableCell>
      <TableCell component="th" scope="row">
        <CompanyItemActions
          companyId={id}
          companyName={businessName}
          onShowCompanyInfo={onShowCompanyInfo}/>
      </TableCell>
    </TableRow>
  )
}
export default CompanyTableItem;
import React, { useMemo } from 'react';
import { TabItem } from "./TabItem";
import { PathNames } from "application/routes";
import { Box } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { bulkExportSelector, setSelectedContacts, setSelectedEmployees, setTotalSelectedContacts } from "../store";
import { useAppDispatch, useAppSelector } from "application/store";
import { ExportTab } from '../types';
import { useLargeBulkExportInfo } from 'common/largeBulkExport/hooks/useLargeBulkExportInfo';
import useBulkExport from '../useBulkExport';

export const NavTabs = () => {
  const { name, scope } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const newTab = name === ExportTab.NEW;
  const allTab = name === ExportTab.ALL;
  const exportedTab = name === ExportTab.EXPORTED;
  const bulkHistoryTab = name === ExportTab.BULK_HISTORY;
  const {isExportScopeEmployees, isExportScopeCompanies, isExportScopePermits} = useBulkExport();

  const {
    newEmployees,
    allEmployees,
    exportedEmployees,
    allCompanies,
    newCompanies,
    exportedCompanies,
    allPermits,
    newPermits,
    exportedPermits,
  } = useAppSelector(bulkExportSelector);

  const navigateToNew = () => {
    navigate(`${PathNames.bulkExport}/${scope}/${ExportTab.NEW}`);
    clearAllSelection();
  }
  const navigateToAll = () => {
    navigate(`${PathNames.bulkExport}/${scope}/${ExportTab.ALL}`);
    clearAllSelection();
  }
  const navigateToExported = () => {
    navigate(`${PathNames.bulkExport}/${scope}/${ExportTab.EXPORTED}`);
    clearAllSelection();
  }
  const navigateToBulkHistory = () => {
    navigate(`${PathNames.bulkExport}/${scope}/${ExportTab.BULK_HISTORY}`);
    clearAllSelection();
  }
  const {companyBulkExportProcesses, permitBulkExportProcesses} = useLargeBulkExportInfo();

  const counter = useMemo(() => {
    if(isExportScopeEmployees)  {
      return {
        new: newEmployees.totalElements,
        all: allEmployees.totalElements,
        exported: exportedEmployees.totalElements,
        bulkExportProcesses: 0
      }
    }
    if(isExportScopeCompanies) {
      return {
        new: newCompanies.totalElements,
        all: allCompanies.totalElements,
        exported: exportedCompanies.totalElements,
        bulkExportProcesses: companyBulkExportProcesses.totalElements
      }
    }
    if(isExportScopePermits) {
      return {
        new: newPermits.totalElements,
        all: allPermits.totalElements,
        exported: exportedPermits.totalElements,
        bulkExportProcesses: permitBulkExportProcesses.totalElements
      }
    }
    return {
      new: 0,
      all: 0,
      exported: 0,
      bulkExportProcesses: 0
    }

  }, [allEmployees,allCompanies, allPermits,
    newPermits, newCompanies, newEmployees,
    exportedCompanies, exportedEmployees, exportedPermits,
    isExportScopeCompanies, isExportScopePermits, isExportScopeEmployees,
    companyBulkExportProcesses, permitBulkExportProcesses]);


  const clearAllSelection = () => {
    dispatch(setTotalSelectedContacts([]));
    dispatch(setSelectedContacts([]));
    dispatch(setSelectedEmployees([]));
  };

  return (
    <Box display="flex">
      <TabItem
        label="New"
        count={counter.new}
        active={newTab}
        onClick={navigateToNew}
      />
      <TabItem
        label="All"
        count={counter.all}
        active={allTab}
        onClick={navigateToAll}
      />
      <TabItem
        label="Exported"
        count={counter.exported}
        active={exportedTab}
        onClick={navigateToExported}
      />
      {
        !isExportScopeEmployees &&
        <TabItem
          label="Bulk exports"
          count={counter.bulkExportProcesses}
          active={bulkHistoryTab}
          onClick={navigateToBulkHistory}
        />
      }
    </Box>
  )
}
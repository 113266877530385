import { Box } from "@mui/system";
import { CustomTooltip } from "application";
import { AddToBulkIcon, CheckIcon } from "application/assets";
import React from "react";
import { useCompaniesAndPermitsExport } from 'common/permits/hooks/useCompaniesAndPermitsExport';

interface Props {
  permitId: number;
  isAddedToBulk: boolean;
  onAddToBulk: (permitId: number) => void;
}
export const AddPermitToBulkExport = ({permitId, isAddedToBulk, onAddToBulk} : Props): JSX.Element => {
  const { addPermitToBulkExport } = useCompaniesAndPermitsExport();

  const handleAddToBulk = async () => {
    if (isAddedToBulk) return;
    const result = await addPermitToBulkExport(permitId);
    if (result) {
      onAddToBulk(permitId);
    }
  };

  return (
    <Box
      onClick={handleAddToBulk}
      sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
    >
      {isAddedToBulk ? (
        <CustomTooltip title="Added to bulk export">
          <CheckIcon />
        </CustomTooltip>
      ) : (
        <CustomTooltip title="Add to bulk export">
          <AddToBulkIcon />
        </CustomTooltip>
      )}
    </Box>
  );
};

import React from "react";
import theme from 'application/theme';
import { IconProps } from './types';

export const DownloadIcon: React.FC<IconProps> = ({color = theme.palette.common.grey900}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M7.00033 1.1665C7.32249 1.1665 7.58366 1.42767 7.58366 1.74984V8.50821L10.0878 6.00402C10.3157 5.77622 10.685 5.77622 10.9128 6.00402C11.1406 6.23183 11.1406 6.60118 10.9128 6.82898L7.4128 10.329C7.185 10.5568 6.81565 10.5568 6.58785 10.329L3.08785 6.82898C2.86004 6.60118 2.86004 6.23183 3.08785 6.00402C3.31565 5.77622 3.685 5.77622 3.9128 6.00402L6.41699 8.50821V1.74984C6.41699 1.42767 6.67816 1.1665 7.00033 1.1665ZM1.16699 12.2498C1.16699 11.9277 1.42816 11.6665 1.75033 11.6665H12.2503C12.5725 11.6665 12.8337 11.9277 12.8337 12.2498C12.8337 12.572 12.5725 12.8332 12.2503 12.8332H1.75033C1.42816 12.8332 1.16699 12.572 1.16699 12.2498Z"
            fill={color}/>
    </svg>
  );
};


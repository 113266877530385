import CircularProgress, {circularProgressClasses} from "@mui/material/CircularProgress";
import theme from "application/theme";
import {Box} from "@mui/system";
import React from "react";


export const LocationsLoader = ({size} : {size: number}) => {
    return (
        <Box display='flex' justifyContent='center' alignItems='center' height={theme.spacing(12)}>
            <Box position="relative" width={size} height={size}>
                <CircularProgress
                    variant="determinate"
                    sx={{
                        color: theme.palette.common.blue100,
                    }}
                    size={size}
                    thickness={6}
                    value={100}
                />
                <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    sx={{
                        color: theme.palette.common.blue700,
                        animationDuration: "1000ms",
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: "round",
                        },
                    }}
                    size={size}
                    thickness={6}
                />
            </Box>
        </Box>

    )
}
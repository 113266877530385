import theme from "application/theme";
import React from "react";
import { FilledIconProps } from './types';

export const ProcessingCircleIcon = (
  {
    color = theme.palette.common.blue800,
    fillColor = theme.palette.common.blue50,
  }: FilledIconProps) => {
  return (
    <svg width="83" height="82" viewBox="0 0 83 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="41.5" cy="41" r="41" fill="#EBF2FF"/>
      <mask id="mask0_4271_71355" maskUnits="userSpaceOnUse" x="20" y="20" width="42" height="42">
        <rect x="20" y="20" width="42" height="42" fill={fillColor}/>
      </mask>
      <g mask="url(#mask0_4271_71355)">
        <path d="M55.7934 42.5616C55.3073 47.1289 52.7194 51.4089 48.4372 53.8813C41.3226 57.9889 32.2251 55.5512 28.1175 48.4366L27.68 47.6789M26.206 39.4366C26.6922 34.8693 29.2801 30.5893 33.5623 28.1169C40.6769 24.0093 49.7743 26.447 53.8819 33.5616L54.3194 34.3193M26.1133 51.6145L27.3944 46.8334L32.1755 48.1145M49.8241 33.8836L54.6052 35.1647L55.8863 30.3836"
              stroke={color} strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </svg>
  );
};

const THOUSANDS_SEPARATOR_PATTERN = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g;

export const getNumberFormatWithSeparators = (value: number | null, thousandsSeparator = ',') => {
  if(value === null || value === undefined) return null;
  return value.toString().replace(THOUSANDS_SEPARATOR_PATTERN, thousandsSeparator);
}

export const formatLargeNumbers = (number: number, digits = 0, thousandsSeparator = ',') => {
  let result = number.toFixed(digits);
  if (number > 10000) result =  `${(number / 1000).toFixed(digits)}K`;
  if (number > 10000000) result = `${(number / 1000000).toFixed(digits)}M`;
  if (number > 10000000000) result = `${(number / 1000000000).toFixed(digits)}B`;
  return result.replace(THOUSANDS_SEPARATOR_PATTERN, thousandsSeparator);
}

export const getPercents = (firstValue: number, secondValue: number) => {
  if (secondValue < firstValue) return 0;
  return Number(Math.round((firstValue / secondValue) * 100));
};
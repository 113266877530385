import { Checkbox, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import theme from "application/theme";

export const TypographyStyled = styled(Typography)`
  color: ${theme.palette.common.grey900};
  white-space: initial;
  owerflow-wrap: brake-all;
  width: 100%;
`;

export const RatingNumberWrapper = styled(Box)`
  display: flex;
  height: ${theme.spacing(4)};
  width: ${theme.spacing(4)};
  padding: ${theme.spacing(0.75)};
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing(1)};
  border-radius: ${theme.spacing(1)};
  background: ${theme.palette.common.blue800};
  color: ${theme.palette.common.white};
  text-align: center;
  font-weight: 500;
  font-size: ${theme.spacing(2)};
`;

export const Headerwrapper = styled(Box)`
  height: ${theme.spacing(4)};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.spacing(1.5)};
`;

export const TextItemLabel = styled(Typography)`
  color: ${theme.palette.common.grey800};
  white-space: initial;
  owerflow-wrap: brake-all;
  width: ${theme.spacing(12.5)};
  margin-right: ${theme.spacing(1)};
`;

export const StyledCheckbox = styled(Checkbox)`
  color: ${theme.palette.common.grey500};
  padding: 0;
  margin-right: ${theme.spacing(1)};
  &.MuiCheckbox-root:hover {
    background-color: transparent;
  }
  &.Mui-checked {
    color: ${theme.palette.common.blue700};
  }
`;

export const TypographyWithOverflow = styled(Typography)`
  max-width: ${theme.spacing(58.5)};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

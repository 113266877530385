import React from "react";

export const LinkedinIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 0.75C17.6641 0.75 18.25 1.33594 18.25 2.03906V17C18.25 17.7031 17.6641 18.25 17 18.25H1.96094C1.29688 18.25 0.75 17.7031 0.75 17V2.03906C0.75 1.33594 1.29688 0.75 1.96094 0.75H17ZM6.02344 15.75V7.42969H3.44531V15.75H6.02344ZM4.73438 6.25781C5.55469 6.25781 6.21875 5.59375 6.21875 4.77344C6.21875 3.95312 5.55469 3.25 4.73438 3.25C3.875 3.25 3.21094 3.95312 3.21094 4.77344C3.21094 5.59375 3.875 6.25781 4.73438 6.25781ZM15.75 15.75V11.1797C15.75 8.95312 15.2422 7.19531 12.625 7.19531C11.375 7.19531 10.5156 7.89844 10.1641 8.5625H10.125V7.42969H7.66406V15.75H10.2422V11.6484C10.2422 10.5547 10.4375 9.5 11.8047 9.5C13.1328 9.5 13.1328 10.75 13.1328 11.6875V15.75H15.75Z"
        fill="#6D6D6D"
      />
    </svg>
  );
};

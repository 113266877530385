import { createSlice } from "@reduxjs/toolkit";
import { store } from "application/store";
import { ExportedCompaniesRespI } from "integration/api/bulkExports/types";
import {
  BulkExportContactI,
  CompaniesDataI,
  EmployeesDataI,
  ExportCompanyI,
  ExportEmployeesI,
  ExportPermitI
} from "./types";
import { emptyPageResponse, PageResponseI } from 'application/types';

export const emptyBulkExportData = {
  content: [],
  pageable: {
    sort: {
      empty: true,
      sorted: false,
      unsorted: true,
    },
    offset: 0,
    pageNumber: 0,
    pageSize: 20,
    paged: true,
    unpaged: false,
  },
  totalPages: 0,
  totalElements: 0,
  last: true,
  size: 10,
  number: 0,
  sort: {
    empty: true,
    sorted: false,
    unsorted: true,
  },
  numberOfElements: 0,
  first: true,
  empty: true,
};

export const initialState= {
  allEmployees: emptyBulkExportData as EmployeesDataI,
  newEmployees: emptyBulkExportData as EmployeesDataI,
  exportedEmployees: emptyBulkExportData as EmployeesDataI,
  pageable: {
    totalPages: 1,
    totalElements: 0,
    pageNumber: 0,
    numberOfElements: 0,
  },
  selectedContacts: [] as ExportEmployeesI[],
  totalSelectedContacts: []  as ExportEmployeesI[],
  selectedEmployees: [] as BulkExportContactI[],
  exportResult: {} as ExportedCompaniesRespI,
  allCompanies: emptyBulkExportData as CompaniesDataI,
  newCompanies: emptyBulkExportData as CompaniesDataI,
  exportedCompanies: emptyBulkExportData as CompaniesDataI,
  selectedCompanies: [] as ExportCompanyI[],
  totalSelectedCompanies: []  as ExportCompanyI[],
  allPermits: emptyPageResponse as PageResponseI<ExportPermitI>,
  newPermits: emptyPageResponse as PageResponseI<ExportPermitI>,
  exportedPermits: emptyPageResponse as PageResponseI<ExportPermitI>,
  selectedPermits: [] as ExportPermitI[],
  totalSelectedPermits: []  as ExportPermitI[],
};

export const bulkExportSlice = createSlice({
  name: "bulkExport",
  initialState,
  reducers: {
    setAllEmployees: (state, { payload }) => {
      state.allEmployees = payload;
    },
    setNewEmployees: (state, { payload }) => {
      state.newEmployees = payload;
    },
    setExportedEmployees: (state, { payload }) => {
      state.exportedEmployees = payload;
    },
    setPageable: (state, action) => {
      state.pageable = action.payload;
    },
    setSelectedContacts: (state, action) => {
      state.selectedContacts = action.payload;
    },
    setSelectedEmployees: (state, action) => {
      state.selectedEmployees = action.payload;
    },
    setExportedContactsTypes: (state, action) => {
      state.exportResult = action.payload;
    },
    setTotalSelectedContacts: (state, action) => {
      state.totalSelectedContacts = action.payload;
    },
    setAllCompanies: (state, { payload }) => {
      state.allCompanies = payload;
    },
    setNewCompanies: (state, { payload }) => {
      state.newCompanies = payload;
    },
    setExportedCompanies: (state, { payload }) => {
      state.exportedCompanies = payload;
    },
    setSelectedCompanies: (state, { payload }) => {
      state.selectedCompanies = payload;
    },
    setTotalSelectedCompanies: (state, { payload }) => {
      state.totalSelectedCompanies = payload;
    },
    setAllPermits: (state, { payload }) => {
      state.allPermits = payload;
    },
    setNewPermits: (state, { payload }) => {
      state.newPermits = payload;
    },
    setExportedPermits: (state, { payload }) => {
      state.exportedPermits = payload;
    },
    setSelectedPermits: (state, { payload }) => {
      state.selectedPermits = payload;
    },
    setTotalSelectedPermits: (state, { payload }) => {
      state.totalSelectedPermits = payload;
    },
    cleanBulkExportStore: (state) => {
      state.allEmployees = initialState.allEmployees;
      state.exportResult = initialState.exportResult;
      state.exportedEmployees = initialState.exportedEmployees;
      state.newEmployees = initialState.newEmployees;
      state.pageable = initialState.pageable;
      state.selectedContacts = initialState.selectedContacts;
      state.selectedEmployees = initialState.selectedEmployees;
      state.totalSelectedContacts = initialState.totalSelectedContacts;
      state.allCompanies = initialState.allCompanies;
      state.newCompanies = initialState.newCompanies;
      state.exportedCompanies = initialState.exportedCompanies;
      state.selectedCompanies = initialState.selectedCompanies;
      state.totalSelectedCompanies = initialState.totalSelectedCompanies;
      state.allPermits = initialState.allPermits;
      state.newPermits = initialState.newPermits;
      state.exportedPermits = initialState.exportedPermits;
      state.selectedPermits = initialState.selectedPermits;
      state.totalSelectedPermits = initialState.totalSelectedPermits;
    },
  },
});

export default bulkExportSlice.reducer;

export const bulkExportSelector = () => store.getState().bulkExport;

export const { setAllEmployees } = bulkExportSlice.actions;
export const { setNewEmployees } = bulkExportSlice.actions;
export const { setExportedEmployees } = bulkExportSlice.actions;
export const { setPageable } = bulkExportSlice.actions;
export const { setSelectedContacts } = bulkExportSlice.actions;
export const { setSelectedEmployees } = bulkExportSlice.actions;
export const { setExportedContactsTypes } = bulkExportSlice.actions;
export const { setTotalSelectedContacts } = bulkExportSlice.actions;
export const { cleanBulkExportStore } = bulkExportSlice.actions;
export const { setAllCompanies } = bulkExportSlice.actions;
export const { setNewCompanies } = bulkExportSlice.actions;
export const { setExportedCompanies } = bulkExportSlice.actions;
export const { setSelectedCompanies } = bulkExportSlice.actions;
export const { setTotalSelectedCompanies } = bulkExportSlice.actions;
export const {
  setAllPermits,
  setNewPermits,
  setExportedPermits,
  setSelectedPermits,
  setTotalSelectedPermits,
} = bulkExportSlice.actions;

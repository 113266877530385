import React, { useState } from 'react';
import { Box } from '@mui/system';
import { CollapseLabel } from '../CollapseLabel';
import { Collapse } from '@mui/material';
import { CollapseFilterWrapper } from './styles';

interface Props {
  label: string;
  children: React.ReactNode;
  count?: number;
  tooltip?: string;
  opened?: boolean;
  small?: boolean;
}
export const CollapseFilter = ({label, count, children, tooltip, opened = false, small}: Props) => {
  const [expanded, setExpanded] = useState(opened);

  return (
    <CollapseFilterWrapper>
      <CollapseLabel
        label={label}
        expanded={expanded}
        setExpanded={setExpanded}
        count={count}
        tooltipText={tooltip}
        small={small}
      />
      <Collapse in={expanded}>
        <Box width={"100%"}>
          {children}
        </Box>
      </Collapse>
    </CollapseFilterWrapper>
  )
}
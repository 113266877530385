import { Typography } from "@mui/material";
import theme from "application/theme";
import React from "react";
import { NavbarItemWrapper, NavbarBadge, NavbarBadgeText } from "../styles";

export const TabItem = ({
  label,
  count,
  active,
  onClick,
}: {
  label: string;
  count: number;
  active?: boolean;
  onClick: () => void;
}) => {
  return (
    <NavbarItemWrapper active={active ? 1 : 0} onClick={onClick}>
      <Typography
        variant="caption"
        color={
          active ? theme.palette.common.blue700 : theme.palette.common.grey600
        }
        mr={1}
        fontWeight={500}
      >
        {label}
      </Typography>
      <NavbarBadge active={active ? 1 : 0}>
        <NavbarBadgeText active={active ? 1 : 0}>{count}</NavbarBadgeText>
      </NavbarBadge>
    </NavbarItemWrapper>
  );
};

import React, {useMemo} from "react";

import { Button } from "application";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import theme from "application/theme";
import { CreditChargedText, HubspotModalContentWrapper, ListHideEffect, } from "../styles";
import { ModalComponent } from "application/components/ModalComponent";
import { useAppDispatch, useAppSelector } from "application/store";
import { bulkExportSelector, setExportedContactsTypes, } from "../store";
import { ExportCompanyI, ExportEmployeesI } from "../store/types";
import { sdrProfileSelector } from "common/sdrProfile/store";
import useBulkExport from "../useBulkExport";
import { ExportedEmployeesTable } from "./ExportedEmployeesTable";
import { ExportedCompaniesTable } from "./ExportedCompaniesTable";

export const ExportModal = ({
                              isOpen,
                              handleClose,
                            }: {
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const { exportResult, totalSelectedContacts, totalSelectedCompanies } =
    useAppSelector(bulkExportSelector);
  const { sdrProfile } = useAppSelector(sdrProfileSelector);
  const { isExportScopeEmployees, fetchEmployees, fetchCompanies, clearAllSelection } = useBulkExport();
  const dispatch = useAppDispatch();
  const exportedEmployees = useMemo(() => {
    const result = [] as ExportEmployeesI[];
    exportResult.exportResultInfos.forEach((exportedItem) => {
      const exportedEmployee = totalSelectedContacts.find((item: ExportEmployeesI) => item.externalId == exportedItem.id);
      if(exportedEmployee) {
        result.push({...exportedEmployee,
            phoneExported: exportedItem.phoneExported,
            emailExported: exportedItem.emailExported,
        })
      }
    });
    return result;
  }, [totalSelectedContacts, exportResult]);


  const exportedCompanies = useMemo(() => {
    return totalSelectedCompanies.filter((selectedCompany: ExportCompanyI) => {
      return exportResult.exportResultInfos.some((exportedCompany) => selectedCompany.companyId == exportedCompany.id)
    });
  }, [totalSelectedCompanies, exportResult]);

  const handleClear = () => {
    clearAllSelection();
    dispatch(setExportedContactsTypes([]));
  };

  const handleDownloadFile = () => {
    handleClear();
    isExportScopeEmployees ? fetchEmployees(sdrProfile.organizationId) : fetchCompanies(sdrProfile.organizationId);
    if(exportResult.s3FileUrl) {
      setTimeout(() => {
        window.open(exportResult.s3FileUrl, "_blank", "noreferrer");}, 10);
    }
    handleClose();
  };


  return (
    <ModalComponent
      isOpen={isOpen}
      padding={3}
      width={theme.spacing(78.5)}
      handleClose={() => {
        handleClear();
        handleClose();
      }}
    >
      <Box>
        <Typography variant="subtitle1" color={theme.palette.common.grey900}>
          Data successfully exported
        </Typography>
        <Typography variant="overline" color={theme.palette.common.grey900}>
          Check the list below to confirm what data was available and exported
        </Typography>
        <HubspotModalContentWrapper
          mt={2}
          sx={{
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: theme.palette.common.grey400,
              width: theme.spacing(0.5),
              height: theme.spacing(13.75),
            },
          }}
        >
          {isExportScopeEmployees ?
            <ExportedEmployeesTable exportedEmployees={exportedEmployees}/>
            : <ExportedCompaniesTable exportedCompanies={exportedCompanies}/>
          }
          <ListHideEffect/>
        </HubspotModalContentWrapper>
        <Box mt={3} display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            {
              isExportScopeEmployees ?
                <>
                  <Typography variant="overline" color={theme.palette.common.grey800}>
                    Total email credits charged:&nbsp;
                    <CreditChargedText>
                      {exportResult.emailCreditsCharged} credits
                    </CreditChargedText>
                  </Typography>
                  <Typography variant="overline" color={theme.palette.common.grey800}>
                    Total phone credits charged:&nbsp;
                    <CreditChargedText>
                      {exportResult.phoneCreditsCharged} credits
                    </CreditChargedText>
                  </Typography>
                </>
                :
                <Typography variant="overline" color={theme.palette.common.grey800}>
                  Total company credits charged:&nbsp;
                  <CreditChargedText>
                    {exportResult.companyDataCreditsCharged} credits
                  </CreditChargedText>
                </Typography>
            }
          </Box>
          <Button
            label="Done"
            height={5.5}
            type="submit"
            onClick={handleDownloadFile}
          />
        </Box>
      </Box>
    </ModalComponent>
  );
};

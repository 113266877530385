import { ThunkDispatchT, VoidThunk } from "application/types";
import { ExportDataI, EmployeesDataI } from "./types";
import { handleErrorNotification, showLoader, hideLoader } from "common";
import {
  deleteFromBulkExport,
  deletePermitFromBulkExport,
  exportContacts,
  getAllCompaniesByOrgId,
  getAllEmployeesByOrgId,
  getAllPermitsByOrganization,
  getExportedCompaniesByOrgId,
  getExportedEmployeesByOrgId,
  getExportedPermitsByOrganization,
  getNewCompaniesBySdr,
  getNewEmployeesBySdrId,
  getNewPermitsBySdr,
} from "integration/api/bulkExports";
import {
  setNewEmployees,
  setExportedContactsTypes,
  setAllEmployees,
  setExportedEmployees,
  setPageable, setNewCompanies, setAllCompanies, setExportedCompanies, setNewPermits, setAllPermits, setExportedPermits,
} from ".";
import { ExportedCompaniesRespI } from "integration/api/bulkExports/types";
import { handleResponse } from "common/responseService/responseService";

export const handleExportContacts = (exportData: ExportDataI): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: ExportedCompaniesRespI = await exportContacts(exportData);
      dispatch(setExportedContactsTypes(response));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleGetNewEmployeesBySdrId = (
  page: number
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: EmployeesDataI = await getNewEmployeesBySdrId(page);
      dispatch(setNewEmployees(response));
      dispatch(
        setPageable({
          totalPages: response.totalPages,
          totalElements: response.totalElements,
          pageNumber: response.number,
          numberOfElements: response.numberOfElements,
        })
      );
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleGetAllEmployeesByOrgId = (
  orgId: number,
  page: number
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: EmployeesDataI = await getAllEmployeesByOrgId(
        orgId,
        page
      );
      dispatch(setAllEmployees(response));
      dispatch(
        setPageable({
          totalPages: response.totalPages,
          totalElements: response.totalElements,
          pageNumber: response.number,
          numberOfElements: response.numberOfElements,
        })
      );
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleGetExportedEmployeesByOrgId = (
  orgId: number,
  page: number
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: EmployeesDataI = await getExportedEmployeesByOrgId(
        orgId,
        page
      );
      dispatch(setExportedEmployees(response));
      dispatch(
        setPageable({
          totalPages: response.totalPages,
          totalElements: response.totalElements,
          pageNumber: response.number,
          numberOfElements: response.numberOfElements,
        })
      );
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleDeleteEmployees = (
  employeesId: number,
  orgId: number
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await deleteFromBulkExport(employeesId);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(handleGetNewEmployeesBySdrId(0));
      dispatch(handleGetAllEmployeesByOrgId(orgId, 0));
      dispatch(handleGetExportedEmployeesByOrgId(orgId, 0));
      dispatch(hideLoader());
      handleResponse({
        message: `Employees was successfully deleted`,
      });
    }
  };
};

export const handleGetNewCompaniesBySdr = (
  page: number
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    const getCompanies = () => getNewCompaniesBySdr(page);
    return await handleGetBulkExportData(dispatch, getCompanies, setNewCompanies);
  };
};

export const handleGetAllCompaniesByOrgId = (
  orgId: number,
  page: number
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    const getAllCompanies = () => getAllCompaniesByOrgId(orgId, page);
    return await handleGetBulkExportData(dispatch, getAllCompanies, setAllCompanies);
  };
};

export const handleGetExportedCompaniesByOrgId = (
  orgId: number,
  page: number
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    const getCompanies = () => getExportedCompaniesByOrgId(orgId, page);
    return await handleGetBulkExportData(dispatch, getCompanies, setExportedCompanies);
  };
};

export const handleGetNewPermitsBySdr = (
  page: number
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    const getPermits = () => getNewPermitsBySdr(page);
    return await handleGetBulkExportData(dispatch, getPermits, setNewPermits);
  };
}

export const handleGetAllPermitsByOrgId = (
  orgId: number,
  page: number
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    const getPermits = () => getAllPermitsByOrganization(orgId, page);
    return await handleGetBulkExportData(dispatch, getPermits, setAllPermits);
  };
}

export const handleGetExportedPermitsByOrgId = (
  orgId: number,
  page: number
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    const getPermits = () => getExportedPermitsByOrganization(orgId, page);
    return await handleGetBulkExportData(dispatch, getPermits, setExportedPermits);
  };
}


const handleGetBulkExportData = async (dispatch: ThunkDispatchT, fetchFunc : any, setFunc: any) => {
  dispatch(showLoader());
  try {
    const response = await fetchFunc();
    dispatch(setFunc(response));
    dispatch(
      setPageable({
        totalPages: response.totalPages,
        totalElements: response.totalElements,
        pageNumber: response.number,
        numberOfElements: response.numberOfElements,
      })
    );
  } catch (error) {
    handleErrorNotification(error);
  } finally {
    dispatch(hideLoader());
  }
}

export const handleDeleteCompanyFromBulkExport = (
  orgId: number,
  bulkExportId: number,
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await deleteFromBulkExport(bulkExportId);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(handleGetNewCompaniesBySdr(0));
      dispatch(handleGetAllCompaniesByOrgId(orgId, 0));
      dispatch(handleGetExportedCompaniesByOrgId(orgId, 0));
      dispatch(hideLoader());
      handleResponse({
        message: `Company was successfully deleted`,
      });
    }
  };
}

export const handleDeletePermitFromBulkExport = (
  permitId: number,
  orgId: number,
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await deletePermitFromBulkExport(permitId);
      handleResponse({
        message: `Permit was successfully deleted`,
      });
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(handleGetNewPermitsBySdr(0));
      dispatch(handleGetAllPermitsByOrgId(orgId, 0));
      dispatch(handleGetExportedPermitsByOrgId(orgId, 0));
      dispatch(hideLoader());
    }
  };
}

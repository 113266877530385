import { styled } from "@mui/material";

import { Box } from "@mui/system";

import theme from "application/theme";

export const EmptyContentWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.palette.common.white};
  flex: 1;
  height: 100%;
`;

export const EmptyContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing(1.5)};
`;

export const EmptyContentWithTutorial = styled(Box)`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing(1.5)};
`;

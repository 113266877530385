import React, { useState } from "react";

import { Button } from "application";

import { HubspotIcon } from "application/assets/HubspotIcon";
import { CsvIcon } from "application/assets/CsvIcon";
import { InventoryIcon } from "application/assets/InventoryIcon";

import theme from "application/theme";
import { Box, Divider, Typography } from "@mui/material";
import { ModalComponent } from "application/components/ModalComponent";
import { useAppDispatch, useAppSelector } from "application/store";
import { organizationSelector, selectedCompanySelector } from "../../store";
import { handleAddDataToBulkExport, handleExportOrganization, } from "../../store/actions";
import { sdrProfileSelector } from "common/sdrProfile/store";
import { organizationsSelector } from "common/organization/store";
import { HubspotIntegrationStatus } from "common/organization";
import { ConnectToHubspotModal } from "common/organization/modals/ConnectToHubspotModal";
import CreditsRequiredItem from "./CreditsRequiredItem";
import { ExportScope, ExportType } from "common/companies/types";
import { useInAppNotification } from 'common/notify';

const ExportModal = ({
  exportType,
  setExportType,
}: {
  exportType: ExportType | null;
  setExportType: (b: ExportType | null) => void;
}) => {
  const dispatch = useAppDispatch();
  const organization = useAppSelector(organizationSelector);
  const { sdrProfile } = useAppSelector(sdrProfileSelector);
  const company = useAppSelector(selectedCompanySelector);
  const { organizationProfile } = useAppSelector(organizationsSelector);
  const [isOpenHubspot, setIsOpenHubspot] = useState(false);
  const {showBulkExportNotification} = useInAppNotification();

  const handleClose = () => {
    setExportType(null);
  };

  const handleCloseAllModal = () => {
    handleClose();
    setIsOpenHubspot(false);
  };

  const handleAddToBulkExport = async () => {
    const { id, ...organizationWithoutId } = organization;
    const result = await dispatch(
      handleAddDataToBulkExport({
        ...organizationWithoutId,
        externalId: id,
        sdrId: sdrProfile.id,
        organizationId: sdrProfile.organizationId,
        companyId: company.id,
        companyName: company.businessName,
        exportScope: getExportScope(),
      })
    );
    if(result) {
      showBulkExportNotification(getExportScope())
    }
    handleClose();
  };
  const getExportScope = () => {
    return exportType === ExportType.COMPANY_DATA ? ExportScope.COMPANY : ExportScope.EMPLOYEE
  }
  const getExportType = () => {
    if (exportType === null) return undefined;
    return exportType === ExportType.COMPANY_DATA ? undefined : exportType;
  }

  const handleDownloadCsv = () => {
    if(exportType === null) return;
    dispatch(
      handleExportOrganization(
        {
          employeeExportInfoDtos: [
            {
              id: organization.id,
              exportType: getExportType(),
              companyId: company.id,
              exportScope: getExportScope(),
            },
          ],
          organizationId: sdrProfile.organizationId,
          exportTo: "CSV",
        },
        "CSV"
      )
    );
    handleClose();
  };

  const handleDownloadHubspot = () => {
    if(exportType === null) return;
    if (
      organizationProfile.hubSpotIntegrationStatus ===
      HubspotIntegrationStatus.INTEGRATED
    ) {
      dispatch(
        handleExportOrganization({
          employeeExportInfoDtos: [
            {
              id: organization.id,
              exportType: getExportType(),
              companyId: company.id,
              exportScope: getExportScope()
            },
          ],
          organizationId: sdrProfile.organizationId,
          exportTo: "HUBSPOT",
        })
      );
      handleClose();
    } else {
      setIsOpenHubspot(true);
    }
  };

  return (
    <>
      <ModalComponent
        isOpen={!!exportType}
        handleClose={handleClose}
        padding={theme.spacing(3)}
        title={ExportType.COMPANY_DATA ? "Export company data" : "Export request"}
        description={ ExportType.COMPANY_DATA ? "This data includes only company contact information." : "This contact was already exported. We will not charge you for export again."}
        width={475}
      >
        <Box
          mt={theme.spacing(4)}
          textAlign="center"
          display={"flex"}
          flexDirection={"column"}
          gap={theme.spacing(3)}
        >
          <Button
            label="Add to bulk export"
            height={5.5}
            width={"100%"}
            type="button"
            startAdornment={<InventoryIcon/>}
            onClick={handleAddToBulkExport}
          />
          <Box display={"flex"} gap={3} alignItems="center">
            <Divider
              sx={{ width: "42%", color: theme.palette.common.grey400 }}
            />
            <Typography variant="body2" color={theme.palette.common.grey600}>
              OR
            </Typography>
            <Divider
              sx={{ width: "42%", color: theme.palette.common.grey400 }}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={theme.spacing(2)}>
            <Typography
              variant="button"
              color={theme.palette.common.grey900}
              textAlign="start"
            >
              Export single record:
            </Typography>
            <Box display="flex" justifyContent="center" gap={theme.spacing(2)}>
              <Button
                label="CSV"
                height={5.5}
                width={theme.spacing(25.5)}
                type="button"
                onClick={handleDownloadCsv}
                startAdornment={<CsvIcon/>}
              />
              <Button
                label="Hubspot"
                height={5.5}
                width={theme.spacing(25.5)}
                type="button"
                onClick={handleDownloadHubspot}
                startAdornment={<HubspotIcon/>}
              />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="start">
              {exportType === ExportType.COMPANY_DATA ?
                <CreditsRequiredItem label={'Required company credits balance:'} value={1}/>
                : <>
                  <CreditsRequiredItem label={'Required email credits balance:'}
                                      value={exportType === ExportType.EMAIL || exportType === ExportType.EMAIL_AND_PHONE ? 1 : 0}/>
                  <CreditsRequiredItem label={'Required phone credits balance:'}
                                      value={exportType === ExportType.PHONE || exportType === ExportType.EMAIL_AND_PHONE ? 1 : 0}/>
                </>
              }
            </Box>
          </Box>
        </Box>
      </ModalComponent>
      <ConnectToHubspotModal
        isOpen={isOpenHubspot}
        handleClose={handleCloseAllModal}
        onConfirmUrl={organizationProfile.hubspotAuthUrl}
      />
    </>
  );
};

export default ExportModal;

import React from 'react';
import { CompanyAutoSuggestOptionI } from '../types';
import theme from 'application/theme';
import { Box } from '@mui/material';
import { AutosuggestItemStyled, BusinessNameStyled, CompanyWebsiteStyled, SearchIconStyled } from './styles';

interface Props {
  option: CompanyAutoSuggestOptionI;
  props: React.HTMLAttributes<HTMLLIElement>,
  onSelect: (id: number) => void;
}
export const AutosuggestOption = ({option, props, onSelect}: Props) => {
  const handleSelectOption = () => {
    onSelect(option.companyId);
  }
  return (
    <AutosuggestItemStyled
      {...props}
      onClick={handleSelectOption}
      key={option.companyId}
    >
      <SearchIconStyled/>
      <Box display={'flex'} flexDirection={'column'}
           overflow={'hidden'}
           gap={theme.spacing(0.5)}>
        <BusinessNameStyled variant="overline">
          {option.businessName}
        </BusinessNameStyled>
        <CompanyWebsiteStyled variant="caption">
          {option.website}
        </CompanyWebsiteStyled>
      </Box>

    </AutosuggestItemStyled>
  )
}
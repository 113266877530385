import React, { useMemo, useState } from 'react';
import { Button } from 'application';
import { ExportAllIcon } from 'application/assets';
import { useLargeBulkExportNew } from 'common/permits/components/largeBulkExport/hooks/useLargeBulkExportNew';
import { usePermitsNavigation } from 'common/permits/hooks/usePermitsNavigation';
import { SelectExportScopeModal } from './SelectExportScopeModal';
import { LargeBulkExportScope } from 'common/largeBulkExport/store/types';

export const StartExportButton = () => {
  const {startBulkExport, companiesFound, permitsFound, companiesOnMapFound, permitsOnMapFound} = useLargeBulkExportNew();
  const {isMapView, isPermitTableMode} = usePermitsNavigation();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const isDisabled = useMemo(() => {
    if (isMapView) {
      return permitsOnMapFound === 0 && companiesOnMapFound === 0;
    }
    return isPermitTableMode ? permitsFound === 0 : companiesFound === 0;
  },[isMapView, isPermitTableMode, permitsFound, companiesFound, permitsOnMapFound, companiesOnMapFound]);
  const handleClick = () => {
    if (isMapView) {
      setIsConfirmModalOpen(true);
    } else {
      isPermitTableMode ? startBulkExport(LargeBulkExportScope.PERMITS) : startBulkExport(LargeBulkExportScope.COMPANIES);
    }
  }

  return (
    <>
      <Button
        disabled={isDisabled}
        startAdornment={<ExportAllIcon/>}
        label="Export all"
        onClick={handleClick}
        height={5.5}
        type="submit"
      />
      <SelectExportScopeModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}/>
    </>
  )
}

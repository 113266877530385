import React from 'react';
import { FiltersGroup } from '../FiltersGroup';
import {
  AffiliationFilter,
  DwellingTypeFilter,
  PermitStatusFilter,
  PermitTypeFilter,
  PermitValueFilter,
  PermitProductTypeFilter,
  SquareFeetFilter,
  TagsFilter,
  LocationFilter
} from './components';

export const PermitFiltersGroup = () => {
  return (
    <FiltersGroup label={'PERMIT FILTERS'}>
      <AffiliationFilter/>
      <PermitStatusFilter/>
      <PermitTypeFilter/>
      <DwellingTypeFilter/>
      <SquareFeetFilter/>
      <PermitValueFilter/>
      <PermitProductTypeFilter />
      <TagsFilter/>
      <LocationFilter/>
    </FiltersGroup>
  )
}
import React, { useEffect } from "react";
import { Box } from '@mui/system';
import { useCompaniesSearch } from 'common/companies/hooks/useCompaniesSearch';
import { CompanyI } from 'integration/api/companies/types';
import { CompaniesListWrapper } from './styles';
import CompaniesTableWrapper from './components/CompaniesTableWrapper';
import theme from 'application/theme';
import { TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import CompanyTableItem from './components/CompanyTableItem';
import CompanyInfoModal from './components/CompanyInfoModal';
import { SearchResultInfo } from '../components';
import CompaniesSortSelect from '../companiesList/CompaniesSortSelect';
import { StartExportCompaniesButton } from 'common/largeBulkExport/components';
import LargeBulkExport from 'common/largeBulkExport';
import { useCompaniesExtraInfo } from '../hooks/useCompaniesExtraInfo';
import { CompanyFiltersTutorial } from 'common/tutorial';
import { NotifyReviewBulkExports } from '../modal/NotifyReviewBulkExports';
import { ExportScope } from '../types';
import { useCompaniesPageContext } from '../hooks/useCompaniesPageContext';

const tableHead = ["Company name", "Visibility Score", "Website", "Major category", "Minor category", "Pulls Permits", "Average Permit Value","Actions"];

const CompaniesListExtended = () => {
  const {content, totalElements, numberOfElements,totalPages,
    fetchCompaniesPage, changePageSize } = useCompaniesSearch();
  const { getCompaniesExtraInfo } = useCompaniesExtraInfo();
  const { isOpenCompanyInfo, setIsOpenCompanyInfo } = useCompaniesPageContext();

  useEffect(() => {
    changePageSize(20);
  },[]);

  useEffect(() => {
    if(content && content.length > 0){
      getCompaniesExtraInfo(content.map((company: CompanyI) => company.id));
    }
  }, [content])

  const handleChangePage = (page: number) => {
    fetchCompaniesPage(page, true);
  }

  return (
    <CompaniesListWrapper>
      <LargeBulkExport/>
      <Box width={'100%'} display={'flex'} justifyContent={'space-between'}>
        <SearchResultInfo totalElements={totalElements}/>
        <StartExportCompaniesButton />
      </Box>
      <Box width={'100%'} display={'flex'} justifyContent={'space-between'}>
        <CompaniesSortSelect horizontalView/>
      </Box>
      <CompanyFiltersTutorial/>
      <CompaniesTableWrapper
        totalElements={totalElements}
        totalPages={totalPages}
        numberOfElements={numberOfElements}
        pageRequest={handleChangePage}
        contentIsEmpty={content.length === 0}
      >
        <TableHead sx={{ background: theme.palette.common.grey200 }}>
          <TableRow sx={{ height: theme.spacing(6.25) }}>
            {tableHead.map((title) => (
              <TableCell key={title}>
                <Typography
                  variant="body2"
                  color={theme.palette.common.grey800}
                >
                  {title}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!!content?.length && content.map((company: CompanyI) =>
            <CompanyTableItem key={company.id}
                              company={company}
                              onShowCompanyInfo={() => setIsOpenCompanyInfo(true)}/>)}
        </TableBody>
      </CompaniesTableWrapper>
      <CompanyInfoModal
        isOpen={isOpenCompanyInfo}
        onClose={() => setIsOpenCompanyInfo(false)}/>
      <NotifyReviewBulkExports/>
    </CompaniesListWrapper>
  )
}

export default CompaniesListExtended;
import React from "react";

export const FacebookIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.375 0.75C17.3906 0.75 18.25 1.60938 18.25 2.625V16.375C18.25 17.4297 17.3906 18.25 16.375 18.25H10.9844V12.3125H13.25L13.6797 9.5H10.9844V7.70312C10.9844 6.92188 11.375 6.17969 12.5859 6.17969H13.7969V3.79688C13.7969 3.79688 12.7031 3.60156 11.6094 3.60156C9.42188 3.60156 7.97656 4.96875 7.97656 7.39062V9.5H5.51562V12.3125H7.97656V18.25H2.625C1.57031 18.25 0.75 17.4297 0.75 16.375V2.625C0.75 1.60938 1.57031 0.75 2.625 0.75H16.375Z"
        fill="#6D6D6D"
      />
    </svg>
  );
};

import { useContext } from 'react';
import { CompaniesPageContext } from '../contexts/CompaniesPageContext';

export const useCompaniesPageContext = () => {
  const context = useContext(CompaniesPageContext);
  if (!context) throw new Error('CompaniesPageContextContext  must be use inside CompaniesPageContextProvider');

  return {
    ...context
  };
};

import React from 'react';
import { Box, Typography } from '@mui/material';
import theme from 'application/theme';
import {
  CompanyNameFilter,
  ServiceTypeFilter,
  TagsFilter,
  CheckedFilterItem,
  CompanyTypeFilter,
  VisibilityScoreFilter,
  LocationFilter,
  CompanyPermitFilter,
  AnnualRevenueFilter,
  NumberOfEmployeesFilter,
  YearsInBusinessFilter,
  BlindFilter
} from './components';
import { useAppSelector } from 'application/store';
import { filterFormSelector } from 'common/companies/store';
import { PermitTagsFilter } from './permitFilters/PermitTagsFilter';
import { PermitLocationFilter } from './permitFilters/PermitLocationFilter';
import { DwellingTypeFilter } from './permitFilters/DwellingTypeFilter';
import { PermitValueFilter } from './permitFilters/PermitValueFilter';
import { SquareFeetFilter } from './permitFilters/SquareFeetFilter';
import { PermitStatusFilter } from './permitFilters/PermitStatusFilter';
import { PermitTypesFilter } from './permitFilters/PermitTypeFilter';
import { PermitAffiliationFilter } from './permitFilters/PermitAffiliationFilter';
import { PermitProductTypesFilter } from './permitFilters/PermitProductTypesFilter';
export const AppliedFiltersList = () => {
  const {skipExported, notGoodFit} = useAppSelector(filterFormSelector);
  return (
    <Box display="flex" flexDirection={"column"}
         gap={2} overflow={'auto'} height={'100%'}
    >
      <Typography variant="button" color={theme.palette.common.grey900}>
        Applied permit filters:
      </Typography>
      <BlindFilter/>
      <PermitAffiliationFilter/>
      <PermitStatusFilter/>
      <PermitTypesFilter/>
      <DwellingTypeFilter/>
      <SquareFeetFilter/>
      <PermitValueFilter/>
      <PermitLocationFilter/>
      <PermitTagsFilter/>
      <PermitProductTypesFilter/>

      <Typography variant="button" color={theme.palette.common.grey900}>
        Applied company filters:
      </Typography>

      <CompanyNameFilter/>
      {skipExported && <CheckedFilterItem label={'Skip exported'}/>}
      <ServiceTypeFilter/>
      <CompanyTypeFilter/>
      <CompanyPermitFilter/>
      <TagsFilter/>
      <LocationFilter/>
      <VisibilityScoreFilter/>
      <NumberOfEmployeesFilter/>
      <AnnualRevenueFilter/>
      <YearsInBusinessFilter/>
      {notGoodFit && <CheckedFilterItem label={'Show only not a good fit'}/>}
    </Box>
  )
}

import { styled } from "@mui/system";
import { Box } from "@mui/material";
import theme from "../../theme";

export const InfoCardWrapper = styled(Box)<{ width?: any }>`
  background: ${theme.palette.common.white};
  padding: ${theme.spacing(3)};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.palette.common.grey400};
  width: ${(props) => props.width || "100%"};
  border-radius: ${theme.spacing(1)};
`;

export const WarningBadge = styled(Box)`
  height: ${theme.spacing(3.5)};
  padding: ${theme.spacing(0, 1.5)};
  border-radius: ${theme.spacing(2)};
`;
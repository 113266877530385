import { SavedPermitAndCompanyFiltersI } from 'common/permits/types';
import { clientRequest } from '../requestHandler';

export const saveCompanyAndPermitFilters = (data: SavedPermitAndCompanyFiltersI) =>
  clientRequest({
    url: `/api/companies/permit-filtered/saved-searches`,
    method: "POST",
    data,
  });

export const getSavedCompanyAndPermitFilters = () =>
  clientRequest({
    url: `/api/companies/permit-filtered/saved-searches`,
    method: "GET",
  });

import React from 'react';
import { getArrayLength } from 'application/utils/arrayUtils';
import { FilterGroupWrapper, FilterTitleStyled } from './styles';
import { CheckedFilterItem } from './CheckedFilterItem';

export const LocationsFilterGroup = ({label, locations, excludeFilter}: {
  label: string,
  locations?: string[],
  excludeFilter?: boolean
}) => {
  if (!locations || getArrayLength(locations) == 0) {
    return <></>;
  }
  return (
    <FilterGroupWrapper>
      <FilterTitleStyled>{label}</FilterTitleStyled>
      {locations.map((location) => (
        <CheckedFilterItem isExcluded={excludeFilter} label={location} key={location}/>
      ))}
    </FilterGroupWrapper>
  )
}
import React from "react";
import { InputAdornment, Typography } from "@mui/material";
import { EmailOutlined } from "@mui/icons-material";
import { Box } from "@mui/system";
import theme from "application/theme";

import { InputStyled } from "./styles";

import { FormInputProps } from "./types";
import { WebsiteIcon } from "./inputIcons";

const FormInput = ({
  label,
  name,
  defaultValue,
  value,
  error,
  placeholder,
  register: { onChange, onBlur, ref, name: fieldName, ...rest },
  isValid,
  type = "text",
}: FormInputProps) => {
  const adornmentIcon = () => {
    const color = error
      ? theme.palette.common.red600
      : theme.palette.common.grey800;
    if (type === "email") {
      return (
        <EmailOutlined
          sx={{
            color: color,
            fontWeight: 400,
          }}
        />
      );
    }
    if (type === "website") {
      return <WebsiteIcon color={color} />;
    }
    return null;
  };

  return (
    <Box sx={{ width: "100%" }} mb={theme.spacing(1)} textAlign="start">
      {label && (
        <Typography
          variant="caption"
          color={
            error ? theme.palette.common.red700 : theme.palette.common.grey800
          }
          sx={{ display: "block" }}
          mb={0.75}
        >
          {label}
        </Typography>
      )}
      <InputStyled
        placeholder={placeholder || "Enter data"}
        type={type}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        name={fieldName}
        isvalid={isValid ? 1 : 0}
        {...rest}
        fullWidth
        id={name}
        error={!!error}
        helperText={error}
        InputProps={{
          startAdornment: adornmentIcon() ? (
            <InputAdornment position="start">{adornmentIcon()}</InputAdornment>
          ) : undefined,
        }}
      />
    </Box>
  );
};

export default FormInput;

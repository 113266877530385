import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import theme from "application/theme";
import React, {useEffect, useMemo} from "react";
import { Box } from "@mui/system";
import { LabelQuantity } from "./styles";
import ManagementTeamCreditsTableRow from "./ManagementTeamCreditsTableRow";
import { useAppDispatch, useAppSelector } from "application/store";

import { creditsColorHelper } from "./utils";
import {
  organizationsSelector,
  setChangedCredits,
  setManagementTeamCreditsError,
} from "common/organization/store";
import { getNumberFormatWithSeparators } from 'application/utils/numberUtils';

const tableHeadShort = [
  "Team member Name",
  "Company Credits",
  "Manage Company Credits",

];
const tableHeadExtended = [
  "Team member Name",
  "Company Credits",
  "Manage Company Credits",
  "Phone Credits",
  "Manage Phone Credits",
  "Email Credits",
  "Manage Email Credits",
];


const ManagementTeamCreditsTable = () => {
  const {
    changedCredits,
    managementTeamCreditsError,
    teamMembers,
    organizationProfile,
  } = useAppSelector(organizationsSelector);
  const {organizationStructureAccessPermitted: displayEmailAndPhoneCredits} = organizationProfile;
  const totalPhoneCredits = organizationProfile.phoneCredits ?? 0;
  const totalEmailCredits = organizationProfile.emailCredits ?? 0;
  const totalCompanyDataCredits = organizationProfile.companyDataCredits ?? 0;
  const dispatch = useAppDispatch();
  let totalEmailCreditsChanged = 0;
  let totalPhoneCreditsChanged = 0;
  let totalCompanyDataCreditsChanged = 0;
  changedCredits.map((e) => (totalEmailCreditsChanged += e.emailCredits));
  changedCredits.map((e) => (totalPhoneCreditsChanged += e.phoneCredits));
  changedCredits.map((e) => (totalCompanyDataCreditsChanged += e.companyDataCredits));
  const emailAmountError = totalEmailCredits < totalEmailCreditsChanged;
  const phoneAmountError = totalPhoneCredits < totalPhoneCreditsChanged;
  const companyDataAmountError = totalCompanyDataCredits < totalCompanyDataCreditsChanged;
  const tableHead = useMemo(() => {
    return displayEmailAndPhoneCredits ? tableHeadExtended : tableHeadShort;
  },[displayEmailAndPhoneCredits])

  const amountErrorHandler = () => {
    const emailCreditsErrors = changedCredits.filter((e) => e.emailAmountError);
    const phoneCreditsError = changedCredits.filter((e) => e.phoneAmountError);
    const constCompanyDataCreditsError = changedCredits.filter((e) => e.companyDataAmountError);
    const hasErrors =
      emailCreditsErrors.length > 0 || phoneCreditsError.length > 0 || constCompanyDataCreditsError.length > 0;
    const dispatchError = (error: string) => {
      dispatch(
          setManagementTeamCreditsError(error)
      );
    }
    switch (true) {
      case phoneAmountError:
        dispatchError("You have reached the maximum amount of Phone credits");
        return;
      case emailAmountError:
        dispatchError("You have reached the maximum amount of Email credits");
        return;
      case companyDataAmountError:
        dispatchError("You have reached the maximum amount of Company Data credits");
        return;
      case hasErrors:
        dispatchError("Less then minimum value");
        return;
      default:
        dispatchError("");
        return;
    }
  };

  useEffect(() => {
    amountErrorHandler();
  }, [totalEmailCreditsChanged, totalPhoneCreditsChanged, totalCompanyDataCreditsChanged]);

  useEffect(() => {
    dispatch(
      setChangedCredits(
        teamMembers.map((e) => {
          return {
            id: e.id,
            emailCredits: e.emailCredits,
            phoneCredits: e.phoneCredits,
            companyDataCredits: e.companyDataCredits,
            emailAmountError: false,
            phoneAmountError: false,
          };
        })
      )
    );
  }, []);

  return (
    <>
      <Box position="relative" display='flex'
           flexDirection='column' justifyContent='space-between' minHeight={theme.spacing(40)}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ background: theme.palette.common.grey200 }}>
            <TableRow>
              {tableHead.map((e) => (
                <TableCell key={e}>
                  <Typography
                    variant="caption"
                    fontWeight={500}
                    color={theme.palette.common.grey800}
                  >
                    {e}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{overflowY: "auto"}}>
            {teamMembers.map((row) => (
                <ManagementTeamCreditsTableRow
                    key={row.id}
                    id={row.id}
                    name={`${row.firstName || ""} ${row.lastName || ""}`}
                    emailCredits={row.emailCredits}
                    usedEmailCredits={row.usedEmailCredits}
                    phoneCredits={row.phoneCredits}
                    usedPhoneCredits={row.usedPhoneCredits}
                    companyDataCredits={row.companyDataCredits}
                    usedCompanyDataCredits={row.usedCompanyDataCredits}
                    emailAmountError={emailAmountError}
                    phoneAmountError={phoneAmountError}
                    companyDataAmountError={companyDataAmountError}
                />
            ))}
          </TableBody>
        </Table>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" width={"20%"}>
                <Typography
                  variant="caption"
                  color={theme.palette.common.grey800}
                  fontWeight={500}
                >
                  Total Credits,
                  <br />
                  Available to Add
                </Typography>
              </TableCell>
              <TotalCreditsCells
                  creditsLabel={"Company credits"}
                  totalCredits={totalCompanyDataCredits}
                  totalCreditsCharged={totalCompanyDataCreditsChanged}/>
              {
                displayEmailAndPhoneCredits &&
                    <>
                      <TotalCreditsCells
                          creditsLabel={"Phone credits"}
                          totalCredits={totalPhoneCredits}
                          totalCreditsCharged={totalPhoneCreditsChanged}/>
                      <TotalCreditsCells
                          creditsLabel={"Email credits"}
                          totalCredits={totalEmailCredits}
                          totalCreditsCharged={totalEmailCreditsChanged}/>
                    </>
              }

            </TableRow>
          </TableBody>
        </Table>
        {!!managementTeamCreditsError ? (
          <Box textAlign="end" mt={2.5}>
            <Typography variant="caption" color={theme.palette.common.red600}>
              {managementTeamCreditsError}
            </Typography>
          </Box>
        ) : (
          null
        )}
      </Box>
    </>
  );
};

export default ManagementTeamCreditsTable;


interface TotalCreditsCellsProps {
    creditsLabel: string;
    totalCredits: number;
    totalCreditsCharged: number;
}
const TotalCreditsCells = ({creditsLabel, totalCredits, totalCreditsCharged} : TotalCreditsCellsProps) => {
  const creditsLeft = totalCredits - totalCreditsCharged;
  return (
      <>
        <TableCell component="th" scope="row">
          <Typography
              variant="caption"
              color={theme.palette.common.grey800}
          >
            {`${creditsLabel}: `}
            <LabelQuantity>{getNumberFormatWithSeparators(totalCredits)}</LabelQuantity>
          </Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography
              variant="caption"
              color={theme.palette.common.grey800}
          >
            Left:{" "}
            <LabelQuantity
                style={{
                  color: creditsColorHelper(
                      totalCredits,
                      totalCreditsCharged
                  ),
                }}
            >
              {getNumberFormatWithSeparators(creditsLeft)}
            </LabelQuantity>
          </Typography>
        </TableCell>
      </>
  )
}

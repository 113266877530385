import React from 'react'
import { FiltersCountStyled, FiltersHeaderWrapper, FiltersToggleStyled } from './styles';
import { Typography } from '@mui/material';
import theme from 'application/theme';
import { Box } from '@mui/system';
import { ChevronDoubleIcon } from 'application/assets';

interface Props {
  count: number;
  displayFilters: boolean;
  onToggleFilters: () => void
}

export const FiltersHeader = ({displayFilters, count, onToggleFilters}: Props) => {
  return (
    <FiltersHeaderWrapper>
      <Box display={"flex"} gap={0.5} alignItems={"center"}>
        <Typography variant="h5" color={theme.palette.common.grey800}>
          {displayFilters ? 'Permit filters' : 'Filters'}
        </Typography>
        {count ? <FiltersCountStyled>{count}</FiltersCountStyled> : null}
      </Box>
      <FiltersToggleStyled
        onClick={onToggleFilters}
      >
        <ChevronDoubleIcon left={displayFilters}/>
      </FiltersToggleStyled>

    </FiltersHeaderWrapper>
  )
}

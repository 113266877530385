import React, { useState } from 'react';
import * as yup from 'yup';
import { useForm, UseFormReturn } from 'react-hook-form';
import { CompanyFilterDataI } from 'integration/api/companies/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { companiesListViewModeService } from '../localStorageService';

export type CompaniesPageStateT = {
  companiesFilterForm: UseFormReturn<CompanyFilterDataI, any>,
  isOpenCompanyInfo: boolean,
  setIsOpenCompanyInfo: (isOpen: boolean) => void,
  isExtendedView: boolean,
  setIsExtendedView: (isExtendedView: boolean) => void,
}

export const CompaniesPageContext = React.createContext<CompaniesPageStateT>({} as CompaniesPageStateT);

export const CompaniesPageContextProvider = (props: any) => {
  const validationSchema = yup.object().shape({});
  const [isOpenCompanyInfo, setIsOpenCompanyInfo] = useState(false);
  const [isExtendedView, setIsExtendedView] = useState(companiesListViewModeService.isExtendedView());

  const companiesFilterForm = useForm<CompanyFilterDataI>({
    resolver: yupResolver(validationSchema),
  });

  return (
    <CompaniesPageContext.Provider
      value={{
        companiesFilterForm,
        isOpenCompanyInfo,
        setIsOpenCompanyInfo,
        isExtendedView,
        setIsExtendedView
      }}
    >
      {props.children}
    </CompaniesPageContext.Provider>
  )
}
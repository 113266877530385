import { Box, styled } from "@mui/system";
import theme from "application/theme";

export const DatePickerInputStyled = styled("button")`
  cursor: pointer;
  height: ${theme.spacing(5)};
  border: ${theme.spacing(0.125)} solid ${theme.palette.common.grey400};
  padding: ${theme.spacing(1.25, 1.25)};
  border-radius: ${theme.spacing(1)};
  background: ${theme.palette.common.white};
  font-size: ${theme.spacing(1.75)};
  line-height: ${theme.spacing(2.5)};
  color: ${theme.palette.common.grey900};
  text-align: start;
  width: ${theme.spacing(13.5)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DatepickerWrapper = styled(Box)`
  .react-datepicker-popper {
    z-index: 50;
  }
`;

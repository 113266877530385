import { Box, styled } from '@mui/system';
import theme from 'application/theme';

export const PageContentWrapper = styled(Box)`
  padding: ${theme.spacing(4,2, 0)};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  height: calc(100% - 72px);
`

import React from 'react';
import { Button } from 'application';
import { ExportAllIcon } from 'application/assets';
import { useLargeBulkExport } from '../../hooks/useLargeBulkExport';

interface Props {
  byPermitFilters?: boolean;
}
export const StartExportCompaniesButton = () => {
  const { startBulkExport, companiesFound } = useLargeBulkExport();
  return (
    <Button
      disabled={companiesFound === 0}
      startAdornment={<ExportAllIcon/>}
      label="Export all"
      onClick={startBulkExport}
      height={5.5}
      type="submit"
    />
  )
}
import React from "react";

import theme from "application/theme";

import { EmailIcon } from "application/assets/EmailIcon";
import { PhoneIcon } from "application/assets/PhoneIcon";

import {
  EmailButtonWrapper,
  GroupButtonBorder,
  PhoneAndEmailButtonWrapper,
  PhoneButtonWrapper,
} from "./styles";
import { Add } from "@mui/icons-material";
import { ExportType } from "common/companies/types";

interface Props {
  emailStatus?: "";
  openExportModal: (type: ExportType | null) => void;
}

const ExportGroupButton = ({ openExportModal }: Props) => {
  const renderPhoneSection = () => (
    <PhoneButtonWrapper onClick={() => openExportModal(ExportType.PHONE)}>
      <PhoneIcon color={theme.palette.common.grey800} />
    </PhoneButtonWrapper>
  );

  const renderEmailSection = () => (
    <EmailButtonWrapper onClick={() => openExportModal(ExportType.EMAIL)}>
      <EmailIcon color={theme.palette.common.grey800} />
    </EmailButtonWrapper>
  );

  const renderEmailPlusPhoneSection = () => (
    <PhoneAndEmailButtonWrapper onClick={() => openExportModal(ExportType.EMAIL_AND_PHONE)}>
      <EmailIcon color={theme.palette.common.grey800} />
      <Add sx={{ color: theme.palette.common.grey800 }} />
      <PhoneIcon color={theme.palette.common.grey800} />
    </PhoneAndEmailButtonWrapper>
  );

  return (
    <GroupButtonBorder>
      {renderEmailSection()}
      {renderPhoneSection()}
      {renderEmailPlusPhoneSection()}
    </GroupButtonBorder>
  );
};

export default ExportGroupButton;

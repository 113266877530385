import * as yup from "yup";
import { Errors } from "../constants";
import { useForm } from "react-hook-form";
import { SurveyFormDataT } from "../types";
import { yupResolver } from "@hookform/resolvers/yup";

export const useSurveyForm = () => {
  const phoneNumberRegex = /^(\+?)(\d{1,3})(\s?)(\d{3})(\s?)(\d{3})(\s?)(\d{2})(\s?)(\d{2})$/;
  const schema = yup
    .object({
      organizationName: yup.string()
        .required(Errors.organizationNameRequired),
      contactFirstName: yup.string()
        .required(Errors.contactFirstNameRequired),
      contactLastName: yup.string()
        .required(Errors.contactLastNameRequired),
      contactEmail: yup.string()
        .email(Errors.emailFormat)
        .required(Errors.emailRequired),
      contactPhone: yup.string()
        .matches(phoneNumberRegex, Errors.contactPhoneFormat)
        .required(Errors.contactPhoneRequired),
      organizationWebsite: yup.string()
        .required(Errors.organizationWebsiteRequired),
      timezone: yup.string()
        .required(Errors.timezoneRequired),
    })
    .required();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SurveyFormDataT>({
    resolver: yupResolver(schema),
  });

  return { register, handleSubmit, reset, errors };
}

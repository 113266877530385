import React from "react";

import theme from "application/theme";

import { CompanyI } from "integration/api/companies/types";
import { labelByType, showReputationScore } from "./utils";
import { useAppDispatch } from "application/store";

import { BadgeCompanyType } from "../components";

import { Typography } from "@mui/material";

import {
  CompanyItemWrapper,
  CompanyInfoWrapper,
  GeneralInfoWrapper,
  RatingWrapper,
  RatingNumberWrapper,
  TypographyStyled,
} from "./styles";
import { handleGetCompanyInfo } from "../store/actions";

interface PropsI {
  company: CompanyI;
  active: boolean;
}
const CompanyItem = ({ company, active }: PropsI) => {
  const dispatch = useAppDispatch();

  return (
    <CompanyItemWrapper
      active={active ? 1 : 0}
      onClick={() => {
        dispatch(handleGetCompanyInfo(company.id));
      }}
    >
      <CompanyInfoWrapper>
        <GeneralInfoWrapper>
          {company?.businessType && (
            <BadgeCompanyType businessType={company.businessType} />
          )}
          <TypographyStyled
            variant="subtitle2"
            color={theme.palette.common.grey900}
          >
            {company.businessName}
          </TypographyStyled>
          {company.serviceType ? (
            <Typography color={theme.palette.common.blue800} variant="body2">
              {labelByType(company.serviceType)}
            </Typography>
          ) : (
            <></>
          )}
        </GeneralInfoWrapper>
        <RatingWrapper>
          <Typography
            color={theme.palette.common.grey900}
            variant="body2"
            fontWeight={400}
          >
            {showReputationScore(company.reputationScore)}
          </Typography>
          <RatingNumberWrapper>
            <Typography variant="button">
              {company.reputationScore / 10}
            </Typography>
          </RatingNumberWrapper>
        </RatingWrapper>
      </CompanyInfoWrapper>
      {/* <Box width={theme.spacing(19)}>
        <ExportedLabel>
          <StyledDot />
          <Typography variant="caption" color={theme.palette.common.green900}>
            Partially Exported
          </Typography>
        </ExportedLabel>
      </Box> */}
      {/* TODO */}
    </CompanyItemWrapper>
  );
};

export default CompanyItem;

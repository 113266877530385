import React from "react";

import { Box, Typography } from "@mui/material";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { CollapseLabelWrapper } from "./styles";
import { FilterCounter } from "./styles";
import theme from "application/theme";
import { CustomTooltip } from "application";

interface Props {
  label: string;
  expanded: boolean;
  setExpanded: (b: boolean) => void;
  count?: number;
  tooltipText?: string;
  small?: boolean;
}

export const CollapseLabel = ({
  label,
  expanded,
  setExpanded,
  count = 0,
  tooltipText,
  small,
}: Props) => {
  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <CollapseLabelWrapper onClick={handleToggle} variant="caption">
      <Box display={"flex"} gap={0.5} alignItems={"center"}>
        <Typography
          variant="caption"
          color={theme.palette.common.grey700}
          letterSpacing={0.7}
          textTransform={"uppercase"}
          sx={{ fontSize: small ? "12px" : "14px" }}
        >
          {label}
        </Typography>
        {count ? <FilterCounter>{count}</FilterCounter> : null}
        {tooltipText ? <CustomTooltip title={tooltipText} /> : <></>}
      </Box>
      {expanded ? (
        <KeyboardArrowDownOutlinedIcon />
      ) : (
        <ChevronRightOutlinedIcon />
      )}
    </CollapseLabelWrapper>
  );
};

export default CollapseLabel;

import React from 'react';
import { useAppSelector } from 'application/store';
import { FiltersCollapse } from 'application';
import { FilterGroupWrapper, FiltersWrapper, FilterTextStyled, FilterTitleStyled } from '../components/styles';
import { permitFiltersSelector } from 'common/permits/store';
import { FilterStatus, PermitStatusI } from 'common/permits/types';
import { CheckedFilterItem } from '../components';

export const PermitStatusFilter = () => {
  const {statusFilter} = useAppSelector(permitFiltersSelector);

  const getGeneralCount = () => {
    if(!statusFilter) return 0;
    return statusFilter.filter((status) => status.current || status.from || status.to).length;
  }

  return getGeneralCount() > 0 ? (
    <FiltersCollapse label={'PERMIT STATUS'} count={getGeneralCount()}>
      <FiltersWrapper>
        {
          statusFilter && statusFilter.length > 0 && statusFilter.map((status) => (
            <StatusFilterItem permitStatus={status}/>
          ))
        }
      </FiltersWrapper>
    </FiltersCollapse>
  ) : <></>;
}

interface StatusFilterItemPropsI {
  permitStatus : PermitStatusI
}
const StatusFilterItem = ({permitStatus} : StatusFilterItemPropsI) => {
  if (!permitStatus) {
    return <></>;
  }
  if ((!permitStatus.current) && !(permitStatus.from || permitStatus.to)) {
    return <></>;
  }
  const getLabel= () => {
    switch (permitStatus.status) {
      case FilterStatus.active:
        return 'Active';
      case FilterStatus.final:
        return 'Final';
      case FilterStatus.inReview:
        return 'In review';
    }
  }
  return (
    <FilterGroupWrapper>
      <FilterTitleStyled>{getLabel()}</FilterTitleStyled>
      {
        permitStatus.current ? <CheckedFilterItem label={'Current'}/>
          : <>
            <FilterTextStyled>
              {permitStatus.from ? `From ${permitStatus.from} ` : ''}
              {permitStatus.to ? `to ${permitStatus.to}` : ''}
            </FilterTextStyled>
          </>
      }
    </FilterGroupWrapper>
  )
}

import React, { useEffect, useState } from "react";
import {Box, Typography} from "@mui/material";

import { CustomSwitch, FilterCounter } from "./styles";
import { CollapseLabelWrapper } from "./components/styles";
import { Controller, useFormContext } from "react-hook-form";
import theme from "application/theme";
import { useAppSelector } from '../../../application/store';
import { filterFormSelector } from '../store';

const SkipExportedCompanies = ({ label }: { label: string }) => {
  const [expanded, setExpanded] = useState(false);
  const { control, watch, setValue } = useFormContext();
  const { skipExported } = watch();
  const { skipExported: skipExportedFromStore } = useAppSelector(filterFormSelector);
  useEffect(() => {
    if(skipExportedFromStore !== skipExported) {
      setValue('skipExported', skipExportedFromStore);
    }
  }, [skipExportedFromStore]);
  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Box width={"100%"}>
      <CollapseLabelWrapper onClick={handleToggle} variant="caption">
        <Box display={"flex"} gap={0.5} alignItems={"center"}>
          <Typography variant="caption" color={theme.palette.common.grey900}>{label}</Typography>
          {skipExported ? <FilterCounter>{skipExported ? 1 : 0}</FilterCounter> : null}
        </Box>
        <Controller
          name="skipExported"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <CustomSwitch
              name={field.name}
              checked={field.value || false}
              onChange={field.onChange}
            />
          )}
        />
      </CollapseLabelWrapper>
    </Box>
  );
};

export default SkipExportedCompanies;

import * as React from "react";
import { useSelector } from "react-redux";
import { styled, useTheme } from "@mui/system";
import { Box } from "@mui/material";
import StyledLoader from "./StyledLoader";

export const LoaderStyled = styled(Box)`
  width: 100%;
  height: 100vh;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.dark};

  & svg {
    width: 100px;
    height: 100px;
  }
`;

type Props = {
  isLoading?: boolean;
};

export const Loader = ({ isLoading }: Props) => {
  const isVisible = useSelector((state: any) => state.loading);
  const theme = useTheme();

  return isVisible || isLoading ? (
    <LoaderStyled theme={theme}>
      <StyledLoader size={100} width={12.5} />
    </LoaderStyled>
  ) : null;
};

import { Box, styled } from '@mui/system';
import theme, { commonColors} from 'application/theme';

export const GeneralInfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const PermitDescriptionStyled  = styled(Box)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  background-color: ${commonColors.grey150};
  padding: ${theme.spacing(1)};
  border-radius: ${theme.spacing(1)};
`

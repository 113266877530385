import {CheckboxGroupStyled} from "../styles";
import {FormGroup} from "@mui/material";
import MultipleController from "../components/MultipleController";
import React from "react";

interface ListOfLocationsProps {
    fieldNameInclude: string;
    fieldNameExclude: string;
    locations: string[];
}
const ListOfLocationsControl = ({fieldNameInclude, fieldNameExclude, locations}: ListOfLocationsProps) => {
    return (
        <CheckboxGroupStyled
            mt={0.5}
            height={locations.length > 5 ? 320 : 0}
        >
            <FormGroup>
                {locations.map((item: any) => (
                    <MultipleController
                        fieldNameInclude={fieldNameInclude}
                        fieldNameExclude={fieldNameExclude}
                        value={item}
                        label={item}
                        key={item}
                        iconButton
                    />
                ))}
            </FormGroup>
        </CheckboxGroupStyled>
    )
}
export default ListOfLocationsControl;
import React from "react";
import theme from 'application/theme';
import { IconProps } from './types';

export const CheckIcon: React.FC<IconProps> = ({ color = theme.palette.common.green800 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <mask id="mask0_4447_21897" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_4447_21897)">
        <path d="M7.95898 15.0001L3.20898 10.2501L4.39648 9.06258L7.95898 12.6251L15.6048 4.97925L16.7923 6.16675L7.95898 15.0001Z" fill={color}/>
      </g>
    </svg>
  );
};


import React from 'react';
import { usePermitFiltersContext } from 'common/permits/hooks/usePermitFiltersContext';
import { useAppSelector } from 'application/store';
import { ProductTypeI } from 'common/permits/types';
import { getCompanyProductTypeBrands, getCompanyProductTypeBrandName } from 'integration/api/companies';
import { ProductTypeFilter } from '../ProductTypeFilter';
import { companySelector } from 'common/companies/store';

const PRODUCT_TYPE_FILTER_NAME = "productTypes";
export const CompanyProductTypeFilter = () => {
  const {companyFilterForm: {watch, setValue}} = usePermitFiltersContext();
  const {productTypes} = useAppSelector(companySelector);
  const selectedTypes = watch(PRODUCT_TYPE_FILTER_NAME) || [];
  const handleChangeSelected = (selectedTypes: ProductTypeI[]) => {
    setValue(PRODUCT_TYPE_FILTER_NAME, selectedTypes);
  }
  return (
    <ProductTypeFilter
      productTypes={productTypes}
      selectedTypes={selectedTypes}
      onSelectedTypesChange={handleChangeSelected}
      fetchProductBrandsCallback={getCompanyProductTypeBrands}
      fetchProductNamesCallback={getCompanyProductTypeBrandName}
    />
  )
}

import React, { useMemo } from "react";

import { Box } from "@mui/system";

import theme from "application/theme";

import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { StyledCheckbox } from "../../../styles";
import { SelectEmployeeCheckbox } from "./SelectEmployeeCheckbox";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "application/store";
import {
  handleDeleteEmployees,
  handleGetAllEmployeesByOrgId,
  handleGetExportedEmployeesByOrgId,
  handleGetNewEmployeesBySdrId,
} from "common/bulkExport/store/actions";
import { UserPhoto } from "application";
import { EmployeesDataI, ExportEmployeesI } from "common/bulkExport/store/types";
import ExportTypeSelect from "../../ExportTypeSelect";
import { BinIcon } from "application/assets/BinIcon";
import { sdrProfileSelector } from "common/sdrProfile/store";
import { BulkExportTableWrapper } from "./BulkExportTableWrapper";
import { useEmployeeBulkExport } from '../../../hooks/useEmployeeBulkExport';

const tableHead = ["Selected for export", "Company", "Location", ""];

const EmployeeBulkExportTable = ({
  exportContacts,
}: {
  exportContacts: EmployeesDataI;
}) => {
  const dispatch = useAppDispatch();
  const { name } = useParams();
  const { sdrProfile } = useAppSelector(sdrProfileSelector);
  const {
    isPhoneSelected,
    isEmailSelected,
    selectEmployeesList,
    addEmployeeToSelected
  } = useEmployeeBulkExport();

  const { totalElements, totalPages, numberOfElements, content } =
    exportContacts;

  const isAllSelectedOnPage = useMemo(() => {
    return content.every((item) => {
      return item.externalId && (
        isPhoneSelected(item.externalId) || isEmailSelected(item.externalId)
      );
    });

  },[isPhoneSelected, isEmailSelected, content]);

  const pageRequest = (page: number) => {
    name === "new"
      ? dispatch(handleGetNewEmployeesBySdrId(page))
      : name === "all"
      ? dispatch(handleGetAllEmployeesByOrgId(sdrProfile.organizationId, page))
      : dispatch(
          handleGetExportedEmployeesByOrgId(sdrProfile.organizationId, page)
        );
  };

  const handleDeleteEmployee = (bulkExportId: number, externalId: number) => {
    dispatch(handleDeleteEmployees(bulkExportId, sdrProfile.organizationId));
    addEmployeeToSelected(externalId, '', false, false);
  };

  const onSelectAll = () => {
    selectEmployeesList(content, !isAllSelectedOnPage);
  };

  return (
    <BulkExportTableWrapper totalElements={totalElements}
                            numberOfElements={numberOfElements}
                            totalPages={totalPages}
                            pageRequest={pageRequest}
                            contentIsEmpty={!content?.length}>
      <TableHead sx={{ background: theme.palette.common.grey200 }}>
        <TableRow sx={{ height: theme.spacing(6.25) }}>
          <TableCell key={"check"}>
            <Box display="flex" alignItems="center">
              {content?.length ? (
                <StyledCheckbox
                  onClick={onSelectAll}
                  checked={isAllSelectedOnPage}
                />
              ) : null}
              <Typography
                variant="body2"
                color={theme.palette.common.grey800}
              >
                Contacts
              </Typography>
            </Box>
          </TableCell>
          {tableHead.map((title) => (
            <TableCell key={title}>
              <Typography
                variant="body2"
                color={theme.palette.common.grey800}
              >
                {title}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {content?.length ? (
          content.map((row: ExportEmployeesI) => (
            <TableRow
              key={row.id}
              sx={{
                height: theme.spacing(7.5),
              }}
            >
              <TableCell component="th" scope="row">
                <Box gap={2} display={"flex"}>
                  <SelectEmployeeCheckbox
                    externalId={row.externalId}
                    companyId={row.companyId} />
                  <UserPhoto
                    photoUrl={row.photoUrl}
                    id={row.id}
                    firstName={row.firstName || ""}
                    lastName={row.lastName || ""}
                  />
                  <Box display={"flex"} flexDirection={"column"}>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {row.firstName} {row.lastName}
                    </Typography>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey600}
                    >
                      {row.title}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>

              <TableCell sx={{ minWidth: theme.spacing(18.75) }}>
                <ExportTypeSelect
                  exportedEmail={row.emailExported}
                  exportedPhone={row.phoneExported}
                  id={row.externalId}
                  companyId={row.companyId}
                />
              </TableCell>

              <TableCell sx={{ minWidth: theme.spacing(18.75) }}>
                <Typography
                  variant="caption"
                  color={theme.palette.common.grey800}
                >
                  {row.companyName || "-"}
                </Typography>
              </TableCell>

              <TableCell>
                <Typography
                  variant="caption"
                  color={theme.palette.common.grey800}
                >
                  <Box display={"flex"} flexDirection={"column"}>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {row.city}
                    </Typography>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey600}
                    >
                      {row.state ? `${row.state}, ` : ""}
                      {row.country}
                    </Typography>
                  </Box>
                </Typography>
              </TableCell>
              <TableCell>
                <Box
                  onClick={() => handleDeleteEmployee(row.id, row.externalId)}
                  sx={{ cursor: "pointer" }}
                >
                  <BinIcon
                    color={theme.palette.common.grey600}
                    width="20"
                    height="20"
                  />
                </Box>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <></>
        )}
      </TableBody>
    </BulkExportTableWrapper>
  )
};

export default EmployeeBulkExportTable;

import React from 'react';
import { BulkExportStatus } from 'common/largeBulkExport/store/types';
import { ExportTo } from 'common/companies/types';
import theme from 'application/theme';
import { Box } from '@mui/material';
import { ActionStyled } from './styles';
import { downloadFileFromS3 } from 'application/utils/fileUtils';
import { useLargeBulkExport } from 'common/largeBulkExport/hooks/useLargeBulkExport';
import { useInAppNotification } from 'common/notify';
import { useLargeBulkExportInfo } from 'common/largeBulkExport/hooks/useLargeBulkExportInfo';
import { useSdrProfile } from 'common/sdrProfile/hooks/useSdrProfile';

interface ActionProps {
  processId: number;
  status: BulkExportStatus;
  exportTo: ExportTo;
  s3Url: string;
  onExportToHubspot: (processId: number) => void;
  onReexportToCsv: (processId: number) => void;
  showHubspotAction: boolean;
}
export const Actions = (props:ActionProps) => {

  return (
    <Box display={'flex'} gap={2} padding={theme.spacing(2.5, 2.5)}>
      <ExportToCsvAction {...props}/>
      {props.showHubspotAction && <ExportToHubspotAction {...props}/>}
    </Box>
  )
}

const ExportToCsvAction = ({status, s3Url, exportTo, processId, onReexportToCsv}: ActionProps) => {
  const { reexportCompanyBulkExportProcess } = useLargeBulkExport();
  const { sdrProfile: { organizationId } } = useSdrProfile();
  const { showInfoMessage } = useInAppNotification();
  const handleDownloadCsv = () => {
    downloadFileFromS3(s3Url);
  }
  const handleReexportToCsv = () => {
    onReexportToCsv(processId);

  }
  if(status === BulkExportStatus.IN_PROGRESS) {
    return <ActionStyled disabled onClick={() => {}}>Export as CSV</ActionStyled>
  }

  if(status === BulkExportStatus.FINISHED && exportTo === ExportTo.CSV) {
    return <ActionStyled onClick={handleDownloadCsv}>Download as CSV</ActionStyled>
  }
  if(exportTo === ExportTo.HUBSPOT) {
    return <ActionStyled onClick={handleReexportToCsv}>Export as CSV</ActionStyled>
  }
  return (
    <ActionStyled onClick={handleReexportToCsv}>Reexport as CSV</ActionStyled>
  )
}

const ExportToHubspotAction = ({status, exportTo, processId, onExportToHubspot }: ActionProps) => {
  const handleExportToHubspot = () => {
    onExportToHubspot(processId);
  }
  if (status === BulkExportStatus.IN_PROGRESS) {
    return <ActionStyled disabled onClick={() => {
    }}>Export to Hubspot</ActionStyled>
  }

  return <ActionStyled onClick={handleExportToHubspot}>
    {exportTo === ExportTo.HUBSPOT ? 'Reexport to Hubspot' : 'Export to Hubspot'}
  </ActionStyled>
}
import { Typography } from '@mui/material';
import { commonColors } from 'application/theme';
import React from 'react';
import { PermitInfoItemStyled } from './styles';

interface PermitInfoItemProps {
  display: boolean;
  icon?: React.ReactNode;
  title?: string;
  value?: string | number | null;
}
export const PermitInfoItem = ({display, icon, title, value}: PermitInfoItemProps) => {
  if (!display) return null;
  return (
    <PermitInfoItemStyled>
      <>
        {icon}
        <Typography
          variant="overline"
          color={commonColors.grey800}
        >
          {title || ''}
        </Typography>
        <Typography
          variant="overline"
          fontWeight={500}
          color={commonColors.grey800}
        >
          {(value || value === 0) ? value : '-'}
        </Typography>
      </>

    </PermitInfoItemStyled>
  )
}
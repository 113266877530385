import theme from "../../theme";

export const warningColor = (param: number) => {
  if (param >= 90)
    return {
      bgColor: theme.palette.common.red50,
      textColor: theme.palette.common.red600,
    };
  if (60 <= param)
    return {
      bgColor: theme.palette.common.yellow50,
      textColor: theme.palette.common.orange800,
    };
  if (30 <= param)
    return {
      bgColor: theme.palette.common.purple50,
      textColor: theme.palette.common.purple600,
    };

  return {
    bgColor: theme.palette.common.green50,
    textColor: theme.palette.common.turquoise600,
  };
};

export const notActiveDays = (param: string) => {
  const lastActivityDate = Date.parse(param);
  const currentDate = new Date().getTime();
  const difference = (currentDate - lastActivityDate) / 1000 / 60 / 60 / 24;
  return Math.floor(difference);
};

export const showNotActiveDays = (param: number) => {
  if(param >= 90) return "90 days";
  if(param >= 60) return "60 days";
  if(param >= 30) return "30 days";
  return;
}
import { CreditsErrorType } from "./modals/AddCreditsForTeamMember";

export const errorTextHelper = ({
  toMuchEmailCredits,
  toMuchPhoneCredits,
  toLessEmailCredits,
  toLessPhoneCredits,
  toLessCompanyDataCredits,
  toMuchCompanyDataCredits,
}: CreditsErrorType) => {
  switch (true) {
    case toMuchEmailCredits:
      return "Assigned too many email credits";
    case toMuchPhoneCredits:
      return "Assigned too many phone credits";
    case toLessEmailCredits:
      return "Can't assign fewer email credits then user already used";
    case toLessPhoneCredits:
      return "Can't assign fewer phone credits then user already used";
    case toLessCompanyDataCredits:
      return "Can't assign fewer company data credits than user already used";
    case toMuchCompanyDataCredits:
      return "Assigned too many company data credits";
    default:
      return "Select at least 1 credit export";
  }
};

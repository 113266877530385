import React, { useLayoutEffect } from 'react';
import { Routes, useLocation } from 'react-router-dom';
import { RoutesProps } from 'react-router/lib/components';
import useUpdateLastActivityDate from '../../hooks/useUpdateLastActivityDate';

const RoutesWrapper = ({ children, }: RoutesProps) => {
  const location = useLocation();
  const { updateLastActivityDate } = useUpdateLastActivityDate();
  useLayoutEffect(() => {
    updateLastActivityDate();
  }, [location]);

  return (<Routes>
    {children}
  </Routes>);
}

export default RoutesWrapper;
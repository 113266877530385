import React from 'react';
import Typography from '@mui/material/Typography';
import theme from 'application/theme';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { FiltersGroupCollapseLabelWrapper } from './styles';

interface Props {
  label: string;
  expanded: boolean;
  setExpanded: (b: boolean) => void;
}

export const FiltersGroupCollapseLabel = ({label, expanded, setExpanded}: Props) => {

  const handleToggle = () => {
    setExpanded(!expanded);
  };
  return (
    <FiltersGroupCollapseLabelWrapper onClick={handleToggle}>
      <Typography
        variant="caption"
        color={theme.palette.common.grey700}
        letterSpacing={0.7}
      >
        {label}
      </Typography>
      {expanded ? (
        <KeyboardArrowDownOutlinedIcon/>
      ) : (
        <ChevronRightOutlinedIcon/>
      )}
    </FiltersGroupCollapseLabelWrapper>
  )
}
import {
  CoordinatesContent,
  PermitFilterDataI,
  PermitStatusI,
  PermitWithCompanyFilterDataI,
  ProductBrandI,
  ProductTypeI,
  ZoomedAreaI,
} from "../types";
import { prepareToSearch } from "common/companies/store/utils";
import { store } from "application/store";
import { cloneDeep } from 'lodash';
import { PageResponseI } from 'application/types';

export const prepareCompanyWithPermitFiltersToSearch = (
  filterData: PermitWithCompanyFilterDataI
): PermitWithCompanyFilterDataI => {
  const companyFilter = prepareToSearch(cloneDeep(filterData.companyFilter));
  const permitFilter = preparePermitFiltersToSearch(cloneDeep(filterData.permitFilter));
  return {
    companyFilter,
    permitFilter,
  };
};

const clearMaxValues = (filterData: PermitFilterDataI, squareFeetRange :any, permitValueRange: any) => {
  if(filterData?.squareFootage?.max === squareFeetRange?.max && filterData?.squareFootage?.min === squareFeetRange?.min) {
    filterData.squareFootage = {};
  }
  if(filterData?.permitValue?.max === permitValueRange?.max && filterData?.permitValue?.min === permitValueRange?.min) {
    filterData.permitValue = {};
  }
}
const preparePermitFiltersToSearch = (
  filterData: PermitFilterDataI
): PermitFilterDataI => {
  const filledAffiliations = filterData.affiliations.filter(
    (affiliation) => affiliation.name || affiliation.types?.length > 0
  );
  const {squareFeetRange, permitValueRange} = store.getState().permits;
  clearMaxValues(filterData, squareFeetRange, permitValueRange);
  return {
    ...filterData,
    affiliations: filledAffiliations,
  };
};

export const countPermitStatusFilter = (statusFilter: PermitStatusI[]) => {
  return statusFilter?.filter((e) => e.current || e.from).length || 0;
}

const countBrandsWithNames = (brands: ProductBrandI[]) => {
  let count = 0;
  brands.forEach((brand) => {
    count += brand?.names?.length || 1;
  });
  return count;
}

export const countProductTypeFilters = (productTypes: ProductTypeI[]) => {
  let count = 0;
  productTypes.forEach((productType) => {
    count += productType?.brands ? countBrandsWithNames(productType.brands) : 1
  });
  return count;
}

export const getCountPermitFilters = (filterData: PermitFilterDataI) => {
  const { permitTypes, tags, affiliations, dwellingTypes,
    locations, statusFilter,
    squareFootage, permitValue
  } =
    filterData;
  const statusFilterCount = countPermitStatusFilter(statusFilter);
  const countProductTypes = countProductTypeFilters(filterData.productTypes);
  return (
    (permitTypes?.length ?? 0) +
    (dwellingTypes?.length ?? 0) +
    (tags?.length ?? 0) +
    (squareFootage?.min || squareFootage?.max ? 1 : 0) +
    (permitValue?.min || permitValue?.max ? 1 : 0) +
    (affiliations?.length ?? 0) +
    (locations?.statesInclude?.length ?? 0) +
    (locations?.statesExclude?.length ?? 0) +
    (locations?.citiesInclude?.length ?? 0) +
    (locations?.citiesExclude?.length ?? 0) +
    (locations?.zipCodesInclude?.length ?? 0) +
    (locations?.zipCodesExclude?.length ?? 0) +
    (locations?.countiesInclude?.length ?? 0) +
    (locations?.countiesExclude?.length ?? 0) +
    (statusFilterCount) +
    (countProductTypes)
  );
};

export const mappingMapResponse = (pageResponse: PageResponseI<CoordinatesContent>) => {
  const content = mapCoordinates(pageResponse.content);
  return  {
    ...pageResponse,
    content,
  }
}

const mapCoordinates = (array: CoordinatesContent[]) => {
  return array.map((e: CoordinatesContent) => {
    return {
      type: "Feature",
      properties: {
        id: e.id,
      },
      geometry: {
        type: "Point",
        coordinates: [e.lg, e.lt],
      },
    };
  });
};

export const addZoomedAreaToFilterData = (filterData: PermitWithCompanyFilterDataI, zoomedArea?: ZoomedAreaI) => {
  if (!zoomedArea) {
    return filterData;
  }
  return {
    ...filterData,
    companyFilter: { ...filterData.companyFilter, zoomedArea },
    permitFilter: { ...filterData.permitFilter, zoomedArea },
  };
}

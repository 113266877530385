import React from 'react';
import { Box } from '@mui/system';
import theme from 'application/theme';
import { ModalComponent } from 'application/components/ModalComponent';
import { QuestionCircleIcon } from 'application/assets';
import { DescriptionTextStyled, ModalContentWrapper, ModalHeaderStyled } from './styles';
import { Button } from 'application';
import { useSdrProfile } from 'common/sdrProfile/hooks/useSdrProfile';
import { LARGE_BULK_EXPORT_LIMIT } from 'common/largeBulkExport/hooks/useLargeBulkExport';

interface Props {
  isOpen: boolean;
  onConfirm: () => void;
  onReject: () => void;
}
export const StartExportModal = ({isOpen, onConfirm, onReject}: Props) => {
  const { availableCredits : {companyDataCredits: companyCreditsAvailable} } = useSdrProfile();
  const exportCount = Math.min(LARGE_BULK_EXPORT_LIMIT, companyCreditsAvailable);
  return (
    <ModalComponent
      isOpen={isOpen}
      padding={4}
      width={theme.spacing(60)}
      handleClose={onReject}
    >
      <ModalContentWrapper>
        <QuestionCircleIcon/>
        <Box display="flex"
             flexDirection="column"
             gap={2.5}>
          <ModalHeaderStyled>
            You can export <span className="export-count">{exportCount}</span> company contacts
          </ModalHeaderStyled>
        </Box>
        {companyCreditsAvailable > LARGE_BULK_EXPORT_LIMIT ?
          <DescriptionTextStyled>
            {`You have an upload limit of ${LARGE_BULK_EXPORT_LIMIT} companies at a time from search results. You can go back and filter the results to get more relevant list.`}
          </DescriptionTextStyled>
          :
          <>
            <DescriptionTextStyled>
              {`You have available ${companyCreditsAvailable} company credits.`} <br/>
              You can go back and filter the results to get more relevant list.
            </DescriptionTextStyled>
          </>
        }
        <Box display="flex" gap={2}>
          <Button
            label="Back to search"
            onClick={onReject}
            height={5.5}
            type="button"
          />
          <Button
            label="Continue"
            onClick={onConfirm}
            height={5.5}
            type="submit"
          />
        </Box>
      </ModalContentWrapper>
    </ModalComponent>
  )
}

export enum NotificationStatus {
  READ = "READ",
  NOT_READ = "NOT_READ",
}


export interface NotificationsI {
  id: number;
  name: string | null;
  description: string | null;
  status: NotificationStatus;
  type: string;
  creationDate: string;
}

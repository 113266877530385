import React from "react";

import { Typography } from "@mui/material";

import { ToolbarWrapper } from "./styles";

export const NavBar = () => {
  return (
    <ToolbarWrapper>
      <Typography variant="h5" color="inherit" noWrap flexGrow={1}>
        Companies list
      </Typography>
    </ToolbarWrapper>
  );
};

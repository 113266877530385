import React from "react";
import { IconProps } from './types';
import theme from 'application/theme';

export const ExportAllIcon: React.FC<IconProps> = ({color = theme.palette.common.white, ...rest}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" {...rest}>
      <mask id="mask0_4588_22076" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
        <rect y="0.5" width="24" height="24" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_4588_22076)">
        <path
          d="M6.5 20.5001C4.98333 20.5001 3.6875 19.9751 2.6125 18.9251C1.5375 17.8751 1 16.5918 1 15.0751C1 13.7751 1.39167 12.6168 2.175 11.6001C2.95833 10.5834 3.98333 9.93343 5.25 9.6501C5.53333 8.4501 6.24167 7.30843 7.375 6.2251C8.50833 5.14176 9.71667 4.6001 11 4.6001C11.55 4.6001 12.0208 4.79593 12.4125 5.1876C12.8042 5.57926 13 6.0501 13 6.6001V12.6501L14.6 11.1001L16 12.5001L12 16.5001L8 12.5001L9.4 11.1001L11 12.6501V6.6001C9.73333 6.83343 8.75 7.44593 8.05 8.4376C7.35 9.42926 7 10.4501 7 11.5001H6.5C5.53333 11.5001 4.70833 11.8418 4.025 12.5251C3.34167 13.2084 3 14.0334 3 15.0001C3 15.9668 3.34167 16.7918 4.025 17.4751C4.70833 18.1584 5.53333 18.5001 6.5 18.5001H18.5C19.2 18.5001 19.7917 18.2584 20.275 17.7751C20.7583 17.2918 21 16.7001 21 16.0001C21 15.3001 20.7583 14.7084 20.275 14.2251C19.7917 13.7418 19.2 13.5001 18.5 13.5001H17V11.5001C17 10.7001 16.8167 9.95426 16.45 9.2626C16.0833 8.57093 15.6 7.98343 15 7.5001V5.1751C16.2333 5.75843 17.2083 6.62093 17.925 7.7626C18.6417 8.90426 19 10.1501 19 11.5001C20.15 11.6334 21.1042 12.1293 21.8625 12.9876C22.6208 13.8459 23 14.8501 23 16.0001C23 17.2501 22.5625 18.3126 21.6875 19.1876C20.8125 20.0626 19.75 20.5001 18.5 20.5001H6.5Z"
          fill={color}/>
      </g>
    </svg>
  );
};


import React from 'react';
import { SearchBarStyled } from './styles';
import { DisplayModeTogler } from '../components';
import { CompanySearchAutocomplete } from './CompanySearchAutocomplete';

interface Props {
  isExtendedView: boolean;
  onChangeView: (extendedView: boolean) => void;
}
export const SearchBar = ({isExtendedView, onChangeView}: Props) => {

  return (
    <SearchBarStyled>
      <CompanySearchAutocomplete/>
      <DisplayModeTogler isExtendedView={isExtendedView} onChangeView={onChangeView}/>
    </SearchBarStyled>
  )
}
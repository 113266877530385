import React, { FC } from "react";

import theme from "application/theme";

import { StyledSurveyIntroWrapper } from "./styles";

import { LogoIcon } from "application/assets/LogoIcon";
import certifiedIcon from "../assets/certifiedIcon.svg";
import integratedIcon from "../assets/integratedIcon.svg";
import timeEffectiveIcon from "../assets/timeEffectiveIcon.svg";

import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import { SurveyIntroItem } from "./SurveyIntroItem";

export const SurveyIntro: FC = () => {
  return (
    <StyledSurveyIntroWrapper>
      <Box mb={9} >
        <LogoIcon />
      </Box>
      <Box display="flex" flexDirection="column" gap={4}>
        <SurveyIntroItem
          title="Distilled"
          description="Construction industry-specific filters and key word search to
              discover businesses with precision and without wasting time"
          imgSrc={certifiedIcon}
        />
        <SurveyIntroItem
          title="Integrated"
          description="  In-app integration for the best contact data available, and an
          optional export integration into Hubspot – allowing you to work
          smarter and not harder"
          imgSrc={integratedIcon}
        />
        <SurveyIntroItem
          title="Time-effective"
          description="Intuitive interface built by user experience professionals with
          bulk exports enabled to save you time"
          imgSrc={timeEffectiveIcon}
        />
      </Box>
      <Typography
        variant="overline"
        color={theme.palette.common.grey800}
        marginTop="auto"
      >
        Our products are trusted 1 000+ customers worldwide.
      </Typography>
    </StyledSurveyIntroWrapper>
  );
};

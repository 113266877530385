import React, { SVGProps } from "react";

import { Box, Typography } from "@mui/material";

import theme from "application/theme";
import { StyledLink } from "./styles";

interface Props {
  url: string | null | undefined;
  Icon: React.FC<SVGProps<SVGSVGElement>>;
  label: string;
}

const LinkInfoItem = ({ url, Icon, label }: Props) => {
  if (url) {
    return (
      <Box display={"flex"} gap={1}>
        <Icon />
        <StyledLink href={url} target="_blank" rel="noopener noreferrer">
          <Typography variant="overline" color={theme.palette.common.grey800}>
            {label}
          </Typography>
        </StyledLink>
      </Box>
    );
  } else {
    return null;
  }
};

export default LinkInfoItem;

import React from "react";
import { Box } from "@mui/system";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { TabelWrapper } from "./styles";
import theme from "application/theme";
import { Typography } from "@mui/material";
import { Pagination } from "application";
import { useAppDispatch, useAppSelector } from "application/store";
import { permitsSelector } from "common/permits/store";
import { TextAlignType } from "common/permits/types";
import { usePermitFiltersContext } from "common/permits/hooks/usePermitFiltersContext";
import { permitColumnsList } from "./permitColumnsList";
import { formatDateTime } from "application/utils/dateTimeUtil";
import { getNumberFormatWithSeparators } from "application/utils";
import TableActions from "./TableActions";
import { handleSearchPermitsByCompanyFilters } from "common/permits/store/actions";

const gridStyle = {
  minHeight: 780,
  marginTop: 10,
  borderRadius: 8,
  overflow: "hidden",
};

export const PermitTableView = () => {
  const textAlign: TextAlignType = "start";
  const headerAlign: TextAlignType = "start";
  const dispatch = useAppDispatch();
  const [addedToBulk, setAddedToBulk] = React.useState<number[]>([]);

  const isAddedToBulk = (permitId: number) => {
    return addedToBulk.includes(permitId);
  }
  const handleAddToBulk = (permitId: number) => {
    setAddedToBulk([...addedToBulk, permitId]);
  }

  const { permitFilterForm, companyFilterForm } = usePermitFiltersContext();

  const { selectedPermitsColumns, foundPermits, searchInArea, zoomedArea } =
    useAppSelector(permitsSelector);

  const columns = permitColumnsList.map((e) => {
    return {
      name: e.value,
      header: e.label,
      minWidth: 200,
      defaultFlex: 2,
      textAlign,
      headerAlign,
      textVerticalAlign: "top",
      headerProps: {
        style: {
          background: theme.palette.common.grey200,
          color: theme.palette.common.grey800,
        },
      },
      render: ({ value }: any) => {
        switch (e.value) {
          case "id":
            return <TableActions id={value} key={value} isAddedToBulk={isAddedToBulk(value)} onAddToBulk={handleAddToBulk}/>;
          case "fileDate":
          case "permitDate":
          case "finalDate":
            return (
              <Typography
                color={theme.palette.common.grey800}
                variant="caption"
              >
                {value ? formatDateTime(value + "Z", "MMM DD, YYYY") : "-"}
              </Typography>
            );
          case "jobValue":
          case "totalFees":
            return (
              <Typography
                color={theme.palette.common.grey800}
                variant="caption"
              >
                {(value || value===0) ? `$${getNumberFormatWithSeparators(value)}` : "-"}
              </Typography>
            );
          default:
            return (
              <Typography
                color={theme.palette.common.grey800}
                variant="caption"
              >
                {value ?? "-"}
              </Typography>
            );
        }
      },
    };
  });

  const handlePageRequest = (page: number) => {
    const permitFilter = permitFilterForm.watch();
    const companyFilter = companyFilterForm.watch();
    dispatch(
      handleSearchPermitsByCompanyFilters(
        {
          permitFilter,
          companyFilter,
        },
        page,
        20,
        false,
        searchInArea ? zoomedArea : undefined
      )
    );
  }


  const renderPaginationToolbar = (paginationProps: any) => {
    return (
      <Box sx={{ borderTop: `1px solid ${theme.palette.common.grey200}` }}>
        <Pagination
          totalElements={foundPermits.totalElements}
          numberOfElements={foundPermits.numberOfElements}
          totalPages={foundPermits.totalPages}
          hidePageSelector={true}
          pageSize={foundPermits.size}
          pageRequest={handlePageRequest}
          currentPage={foundPermits.number}
        />
      </Box>
    );
  };

  const filteredColumns = columns.filter((e) =>
    selectedPermitsColumns.includes(e.name) || e.name === "id" // action column (id) should always be visible
  );

  return (
    <TabelWrapper>
      <ReactDataGrid
        idProperty="id"
        dataSource={foundPermits.content}
        style={gridStyle}
        columns={filteredColumns}
        pagination={true}
        defaultLimit={100}
        showColumnMenuTool={false}
        rowHeight={56}
        headerHeight={44}
        showCellBorders="horizontal"
        showZebraRows={false}
        showActiveRowIndicator={false}
        nativeScroll={true}
        renderPaginationToolbar={renderPaginationToolbar}
      />
    </TabelWrapper>
  );
};

import {TableRow, Typography} from "@mui/material";
import {warningColor} from "application/components/InfoCard/utils";
import theme from "application/theme";
import { getNumberFormatWithSeparators, getPercents } from "application/utils";
import {StyledWarning} from "common/organization/OrganizationProfile/TeamTable/styles";
import React, {useState} from "react";
import {QuantityPickerSmall} from "./QuantityPicker";
import {useAppDispatch, useAppSelector} from "application/store";
import {TableCellCustom} from "./styles";
import {
    organizationsSelector,
    setChangedCredits,
} from "common/organization/store";

type ManagementTeamCreditsTableRowProps = {
    name: string;
    id: number;
    usedPhoneCredits: number;
    phoneCredits: number;
    usedEmailCredits: number;
    emailCredits: number;
    companyDataCredits: number;
    usedCompanyDataCredits: number;
    emailAmountError: boolean;
    phoneAmountError: boolean;
    companyDataAmountError: boolean;
};

const ManagementTeamCreditsTableRow = ({
                                           name,
                                           id,
                                           usedPhoneCredits,
                                           phoneCredits,
                                           usedEmailCredits,
                                           emailCredits,
                                           companyDataCredits,
                                           usedCompanyDataCredits,
                                           emailAmountError,
                                           phoneAmountError,
                                           companyDataAmountError,
                                       }: ManagementTeamCreditsTableRowProps) => {
    const {changedCredits, organizationProfile} = useAppSelector(organizationsSelector);
    const {organizationStructureAccessPermitted: showEmailAndPhoneCredits} = organizationProfile;
    const dispatch = useAppDispatch();

    const [emailCreditsValue, setEmailCreditsValue] =
        useState<number>(emailCredits);

    const [phoneCreditsValue, setPhoneCreditsValue] =
        useState<number>(phoneCredits);
    const [companyDataCreditsValue, setCompanyDataCreditsValue] = useState<number>(companyDataCredits);

    const handleChangePhoneCredits = (newPhoneCredits: number) => {
        setPhoneCreditsValue(newPhoneCredits);
        dispatchChangedCredits(newPhoneCredits, emailCreditsValue, companyDataCreditsValue);
    };

    const handleChangeEmailCredits = (newEmailCredits: number) => {
        setEmailCreditsValue(newEmailCredits);
        dispatchChangedCredits(phoneCreditsValue, newEmailCredits, companyDataCreditsValue);
    };

    const handleChangeCompanyDataCredits = (newCompanyDataCredits: number) => {
        setCompanyDataCreditsValue(newCompanyDataCredits);
        dispatchChangedCredits(phoneCreditsValue, emailCreditsValue, newCompanyDataCredits);
    };
    const dispatchChangedCredits = (phoneCredits: number, emailCredits: number, companyDataCredits: number) => {
        const exceptExistedElement = changedCredits.filter((e) => e.id !== id);
        dispatch(
            setChangedCredits([
                ...exceptExistedElement,
                {
                    id,
                    emailCredits,
                    phoneCredits,
                    companyDataCredits,
                    emailAmountError: emailCredits < usedEmailCredits,
                    phoneAmountError: phoneCredits < usedEmailCredits,
                    companyDataAmountError: companyDataCredits < usedCompanyDataCredits,
                },
            ])
        );
    }

    return (
        <TableRow sx={{height: "60px"}}>
            <TableCellCustom component="th" scope="row">
                <Typography variant="caption" color={theme.palette.common.grey800}>
                    {name}
                </Typography>
            </TableCellCustom>
            <TableCellCustom component="th" scope="row">
                <CreditsItem used={usedCompanyDataCredits} total={companyDataCreditsValue}/>
            </TableCellCustom>
            <TableCellCustom component="th" scope="row">
                <QuantityPickerSmall
                    min={usedCompanyDataCredits}
                    getValue={handleChangeCompanyDataCredits}
                    isError={companyDataAmountError}
                    defaultValue={companyDataCredits}
                    max={999999}
                />
            </TableCellCustom>
            {
                showEmailAndPhoneCredits && <>
                    <TableCellCustom component="th" scope="row">
                        <CreditsItem used={usedPhoneCredits} total={phoneCreditsValue}/>
                    </TableCellCustom>
                    <TableCellCustom component="th" scope="row">
                        <QuantityPickerSmall
                            min={usedPhoneCredits}
                            getValue={handleChangePhoneCredits}
                            isError={phoneAmountError}
                            defaultValue={phoneCredits}
                        />
                    </TableCellCustom>
                    <TableCellCustom component="th" scope="row">
                        <CreditsItem used={usedPhoneCredits} total={emailCreditsValue}/>
                    </TableCellCustom>
                    <TableCellCustom component="th" scope="row">
                        <QuantityPickerSmall
                            min={usedEmailCredits}
                            getValue={handleChangeEmailCredits}
                            isError={emailAmountError}
                            defaultValue={emailCredits}
                        />
                    </TableCellCustom>

                </>
            }

        </TableRow>
    );
};

export default ManagementTeamCreditsTableRow;

const CreditsItem = ({used, total}: { used: number; total: number }) => {
    return (
        <>
            <Typography
                variant="caption"
                color={theme.palette.common.grey800}
                mr={2}
                sx={{display: "inline-block", alignItems: "center"}}
            >
                {`${getNumberFormatWithSeparators(used ?? "0")}/${getNumberFormatWithSeparators(total)}`}
            </Typography>
            {used <= total && used ? (
                <StyledWarning
                    bgcolor={
                        warningColor(getPercents(used, total))
                            .bgColor
                    }
                    color={
                        warningColor(getPercents(used, total))
                            .textColor
                    }
                    sx={{display: "inline-block", alignSelf: "center"}}
                >
                    {getPercents(used, total)}%
                </StyledWarning>
            ) : null}
        </>
    )
}

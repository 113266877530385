import React, { useEffect } from "react";
import DropdownWithMultiselect from "application/components/DropdownWithMultiselect";
import { useAppDispatch, useAppSelector } from "application/store";
import {
  permitsSelector,
  setSelectedCompaniesColumns,
} from "common/permits/store";
import {
  getCompaniesColumns,
  saveCompaniesColumns,
} from "../../Views/LocalStorageService";
import { companyColumnsList } from '../../Views/CompanyTableView/companyColumnsList';

export const CompaniesColumnsSettings = () => {
  const dispatch = useAppDispatch();
  const { selectedCompaniesColumns } = useAppSelector(permitsSelector);

  const onSelectChange = (value: string[]) => {
    dispatch(setSelectedCompaniesColumns(value));
    saveCompaniesColumns(value);
  };

  useEffect(() => {
    const savedSelectedColumns = getCompaniesColumns();
    if (savedSelectedColumns) {
      dispatch(setSelectedCompaniesColumns(savedSelectedColumns));
    }
  }, []);

  const columnListWithoutId = companyColumnsList.filter((column) => column.value !== "id");

  return (
    <DropdownWithMultiselect
      options={columnListWithoutId}
      selectedOptions={selectedCompaniesColumns}
      onChangeSelected={(value) => onSelectChange(value)}
      label={"Columns"}
    />
  );
};

import { Box, Typography } from "@mui/material";
import theme from "application/theme";
import React from "react";
import { ArrowOutward } from "application/assets/ArrowOutward";
import {
  ContactCardWrapper,
  ContactLink,
  TypographyWithOverflow,
} from "./styles";
import BadgeComponent from "../BadgeComponent";

type ContactCardT = {
  name: string;
  label: string | null;
  link?: boolean;
  onClick?: () => void;
  url?: string;
  withIcon?: boolean;
  badgeText?: string;
};

const ContactCard = ({
  name,
  label,
  link,
  withIcon,
  onClick,
  url = "#",
  badgeText,
}: ContactCardT) => {
  return (
    <ContactCardWrapper>
      <Typography variant="caption" color={theme.palette.common.grey800}>
        {name}
      </Typography>
      {label ? (
        <>
          {link ? <ContactCardLinkItem badgeText={badgeText} url={url} label={label} withIcon={withIcon}/> :
              (onClick ? <ContactCardActionItem badgeText={badgeText} onClick={onClick} label={label} withIcon={withIcon}/> :
                <Typography variant="button" color={theme.palette.common.grey800}>
                  {label}
                </Typography>
          )}
        </>
      ) : (
        <Typography variant="button" mr={0.5}>
          -
        </Typography>
      )}
    </ContactCardWrapper>
  );
};

export default ContactCard;

const ContactCardLinkItem = ({badgeText, url, label, withIcon}: {
  badgeText?: string;
  url: string;
  label: string;
  withIcon?: boolean;
}) => {
  return (
      <Box display="flex">
        {badgeText ? <BadgeComponent text={badgeText}/> : null}
        <ContactLink href={url} target="_blank" rel="noopener noreferrer">
          <TypographyWithOverflow variant="button" mr={0.5}>
            {label}
          </TypographyWithOverflow>
          {withIcon && <ArrowOutward/>}
        </ContactLink>
      </Box>)
}

const ContactCardActionItem = ({badgeText, onClick, label, withIcon}: {
  badgeText?: string;
  onClick: () => void;
  label: string;
  withIcon?: boolean;
}) => {
  return (
      <Box display="flex">
        {badgeText ? <BadgeComponent text={badgeText}/> : null}
        <ContactLink onClick={onClick}>
          <TypographyWithOverflow variant="button" mr={0.5}>
            {label}
          </TypographyWithOverflow>
          {withIcon && <ArrowOutward/>}
        </ContactLink>
      </Box>)
}

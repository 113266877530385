import React from 'react';
import { useAppSelector } from 'application/store';
import { filterFormSelector } from 'common/companies/store';
import { FiltersCollapse } from 'application';
import { FilterGroupWrapper, FiltersWrapper } from './styles';
import { CheckedFilterItem } from './CheckedFilterItem';
import { getAnnualRevenueLabel } from 'common/companies/companiesFilter/utils';

export const AnnualRevenueFilter = () => {
  const {annualRevenueRange} = useAppSelector(filterFormSelector);
  return annualRevenueRange.length > 0 ? (
    <FiltersCollapse label={'REVENUE'} count={annualRevenueRange.length}>
      <FiltersWrapper>
        <FilterGroupWrapper>
          {annualRevenueRange.map((item) => (
            <CheckedFilterItem
              key={item}
              label={getAnnualRevenueLabel(item)}/>
          ))
          }
        </FilterGroupWrapper>
      </FiltersWrapper>
    </FiltersCollapse>
  ) : null;
}
import React from 'react';
import { ViewsWrapper } from './styles';
import { usePermitsNavigation } from 'common/permits/hooks/usePermitsNavigation';
import { MapView } from './MapView';
import { PermitTableView } from './PermitTableView';
import { CompanyTableView } from './CompanyTableView';
import CompanyInfoModal from 'common/companies/companiesListExtended/components/CompanyInfoModal';
import { useViewDetails } from 'common/permits/hooks/useViewDetails';
import { PermitInfoModal } from '../../PermitInfoModal';

export const Views = () => {
  const {isMapView, isPermitTableMode} = usePermitsNavigation();
  const {isOpenCompanyInfo, hideCompanyDetails, isOpenPermitInfo, hidePermitDetails} = useViewDetails();
  return <ViewsWrapper>
    {isMapView ? <MapView/>
      : isPermitTableMode ? <PermitTableView/> : <CompanyTableView/>}
    <CompanyInfoModal isOpen={isOpenCompanyInfo} onClose={hideCompanyDetails} showTutorial={false} />
    <PermitInfoModal isOpen={isOpenPermitInfo} onClose={hidePermitDetails} />
  </ViewsWrapper>
}

import { useAppDispatch, useAppSelector } from 'application/store';
import { companyExtraInformationSelector } from '../store';
import { handleGetCompaniesExtraInformation } from '../store/actions';

export const useCompaniesExtraInfo = () => {
  const dispatch = useAppDispatch();
  const { isLoading, data } = useAppSelector(companyExtraInformationSelector);

  const getCompaniesExtraInfo = (companyIds: number[]) => {
    dispatch(handleGetCompaniesExtraInformation(companyIds));
  }

  return {
    isLoading,
    companiesExtraInfo: data,
    getCompaniesExtraInfo
  }
}
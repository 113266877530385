import React from "react";
import { Typography } from "@mui/material";

import theme from "application/theme";
import noContactsAdded from "application/assets/noContactsAdded.svg";

import { EmptyContentWrapper } from "./styles";
import { Button } from "application";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { PathNames } from "application/routes";

const EmptyInfo = () => {
  const navigate = useNavigate();

  return (
    <EmptyContentWrapper>
      <img src={noContactsAdded} alt="No results found" />
      <Typography variant="button" color={theme.palette.common.grey800} mt={2}>
        No Contacts Added Yet
      </Typography>
      <Typography variant="caption" color={theme.palette.common.grey600} mb={2}>
        Please, start adding
      </Typography>
      <Button
        label="Search Companies"
        startAdornment={<Add sx={{ color: theme.palette.common.white }} />}
        type="submit"
        onClick={() => navigate(`${PathNames.companies}`)}
      />
    </EmptyContentWrapper>
  );
};

export default EmptyInfo;

import React, { useState } from "react";
import { MultiSelect, MultiSelectOptionI } from "../MultiSelect";
import { DropdownWrapper, SelectButton, SelectContainer } from "./styles";
import { ClickAwayListener, Typography } from "@mui/material";
import theme from "application/theme";
import { ChevronDownIcon } from "application/assets/ChevronDownIcon";
import { ChevronUpIcon } from "application/assets/ChevronUpIcon";

interface DropdownWithMultiSelectProps {
  options: MultiSelectOptionI[];
  selectedOptions: any[];
  getKeyFromValue?: (value: any) => string;
  onChangeSelected: (selectedOptions: any) => void;
  label: string;
  buttonWidth?: string;
}

const DropdownWithMultiselect = ({
  options,
  selectedOptions,
  onChangeSelected,
  label,
  buttonWidth = "150px",
}: DropdownWithMultiSelectProps) => {
  const [open, setOpen] = useState(false);
  const toggleSelect = () => {
    setOpen(!open);
  };
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <SelectContainer>
        <SelectButton onClick={toggleSelect} sx={{ width: buttonWidth }}>
          <Typography
            variant="body2"
            mr={1}
            color={theme.palette.common.grey800}
          >
            {label}
          </Typography>
          {open ? (
            <ChevronUpIcon />
          ) : (
            <ChevronDownIcon />
          )}
        </SelectButton>
        <DropdownWrapper visible={open ? 1 : 0}>
          <MultiSelect
            options={options}
            selectedOptions={selectedOptions}
            onChangeSelected={onChangeSelected}
          />
        </DropdownWrapper>
      </SelectContainer>
    </ClickAwayListener>
  );
};

export default DropdownWithMultiselect;

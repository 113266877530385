import { Box } from "@mui/material";
import { styled } from "@mui/system";
import theme from "application/theme";

export const BadgeWrapper = styled(Box)`
  background-color: ${theme.palette.common.green100};
  color: ${theme.palette.common.green900};
  display: flex;
  align-items: center;
  padding: ${theme.spacing(0, 1.5)};
  border-radius: ${theme.spacing(2)};
`;

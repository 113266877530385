import { StorageFields } from "application";

export const savePermitColumns = (permitColumns: string[]) => {
  localStorage.setItem(
    StorageFields.permitColumns,
    JSON.stringify(permitColumns)
  );
};

export const getPermitColumns = () => {
  const value = localStorage.getItem(StorageFields.permitColumns);
  if (!value) return false;
  return JSON.parse(value);
};

export const saveCompaniesColumns = (permitColumns: string[]) => {
  localStorage.setItem(
    StorageFields.companiesColumns,
    JSON.stringify(permitColumns)
  );
};

export const getCompaniesColumns = () => {
  const value = localStorage.getItem(StorageFields.companiesColumns);
  if (!value) return false;
  return JSON.parse(value);
};

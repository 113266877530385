import React from 'react'
import { useLargeBulkExportNew } from './hooks/useLargeBulkExportNew';
import { ExportStage } from 'common/largeBulkExport/store/types';
import {
  ConfirmExportModal,
  StartExportModal,
  BackToSearchModal,
  ExportProcessingModal
} from './components';

import {
  NoCreditsModal,
} from 'common/largeBulkExport/components';
import { useSelector } from 'react-redux';
import { isLoaderSelector } from 'common/loaderModal/loaderSlice';

export const LargeBulkExportNew = () => {
  const {exportStage, updateExportStage, openConfirmExportModal} = useLargeBulkExportNew();
  const { isLoading } = useSelector(isLoaderSelector);
  const showStartExportModal = exportStage === ExportStage.START;
  const showNoCreditsModal = exportStage === ExportStage.NO_CREDITS;
  const showConfirmExportModal = exportStage === ExportStage.CONFIRM;
  const showExportProcessingModal = exportStage === ExportStage.PROCESSING;
  const showBackToSearchModal = exportStage === ExportStage.BACK_TO_SEARCH;

  const handleCloseExport = () => {
    updateExportStage(null);
  }

  return (
    <>
      {
        isLoading ? null :
          <>
            <NoCreditsModal
              isOpen={showNoCreditsModal}
              onClose={handleCloseExport}/>
            <StartExportModal
              isOpen={showStartExportModal}
              onConfirm={() => openConfirmExportModal()}
              onReject={handleCloseExport}
            />
            <ConfirmExportModal
              isOpen={showConfirmExportModal}
              onClose={handleCloseExport}/>
            <ExportProcessingModal
              isOpen={showExportProcessingModal}
              onClose={handleCloseExport}/>
            <BackToSearchModal
              isOpen={showBackToSearchModal}
              onClose={handleCloseExport}/>
          </>
      }
    </>
  )
}

import { Box } from "@mui/material";
import { styled } from "@mui/system";
import theme from "application/theme";

export const CompanyMainBarStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${theme.palette.common.grey150};
`;

export const CompanyContentWrapperStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${theme.palette.common.white};
`

export const CompanyInfoItemWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${theme.spacing(1.5)};
`;

export const CompanyInfoWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  gap: ${theme.spacing(1.5)};
  align-self: stretch;
`;

export const GeneralInfoWrapper = styled(Box)`
  display: flex;
  width: calc(100% - ${theme.spacing(17.75)});
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.spacing(0.5)};
  flex-shrink: 0;
`;

export const RatingWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${theme.spacing(1)};
  width: ${theme.spacing(13.75)};
`;

export const RatingNumberWrapper = styled(Box)`
  display: flex;
  height: ${theme.spacing(4)};
  width: ${theme.spacing(4)};
  padding: ${theme.spacing(0.75)};
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing(1)};
  border-radius: ${theme.spacing(1)};
  background: ${theme.palette.common.blue800};
  color: ${theme.palette.common.white};
  text-align: center;
  font-weight: 500;
  font-size: ${theme.spacing(2)};
`;

export const StructureWrapper = styled(Box)`
  background-color: ${theme.palette.common.white};
  border: 1px solid ${theme.palette.common.grey400};
  border-radius: ${theme.spacing(1)};
`;

export const StickyBottomMenuStyled = styled(Box)`
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing(1.5, 2)};
  background-color: ${theme.palette.common.grey150};
  border-top: 1px solid ${theme.palette.common.grey200};
`

import { styled } from "@mui/system";
import { Switch } from "@mui/material";

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: theme.spacing(6.5),
  height: theme.spacing(4),
  padding: 0,
  display: 'flex',
  '& .MuiSwitch-switchBase': {
    padding: theme.spacing(0.25),
    '&.Mui-checked': {
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.common.blue700
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    borderRadius: theme.spacing(2),
  },
  '& .MuiSwitch-track': {
    borderRadius: theme.spacing(4),
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));
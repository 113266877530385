import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";

import { MenuItemStyled, SelectStyled } from "./styles";

import { FormSelectProps } from "./types";
import { Typography } from "@mui/material";
import theme from "application/theme";

const SelectComponent = ({
  label,
  name,
  options,
  width,
  error,
  register: { onChange, onBlur, ref, name: fieldName, ...rest },
  defaultValue,
  placeholder,
}: FormSelectProps) => {
  return (
    <Box sx={{ width: width || "100%" }}>
      {label && (
        <Typography
          variant="caption"
          color={
            error ? theme.palette.common.red700 : theme.palette.common.grey800
          }
          sx={{ display: "block" }}
          mb={0.75}
        >
          {label}
        </Typography>
      )}
      <SelectStyled
        defaultValue={defaultValue}
        id={name}
        IconComponent={ExpandMoreIcon}
        onBlur={onBlur}
        ref={ref}
        name={fieldName}
        {...rest}
        fullWidth
        error={!!error}
        helpertext={error}
        placeholder={placeholder}
      >
        {options.map((option) => (
          <MenuItemStyled key={option.value} value={option.value}>
            {option.label}
          </MenuItemStyled>
        ))}
      </SelectStyled>
      {error && (
        <Typography
          variant="caption"
          color={theme.palette.common.red600}
          mt={1}
          mr={1.75}
        >
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default SelectComponent;

import theme from "application/theme";
import { ActivationStatus } from "common/organization";

export const badgeHelper = (status: ActivationStatus | string) => {
  switch (status) {
    case "ARCHIVED":
      return {
        background: theme.palette.common.grey300,
        color: theme.palette.common.grey800,
        text: "Archived",
      };
    case "ACTIVE":
      return {
        background: theme.palette.common.green100,
        color: theme.palette.common.green900,
        text: "Active",
      };
    case "WAITING_FOR_ACTIVATION":
      return {
        background: theme.palette.common.orange50,
        color: theme.palette.common.orange800,
        text: "Invite Sent",
      };
    case "BLOCKED":
    case "DECLINED":
    default:
      return {
        background: theme.palette.common.red50,
        color: theme.palette.common.red600,
        text: "Not Active",
      };
  }
};

import React, { useEffect } from 'react'
import { ActionItemStyled, ActionsWrapperStyled, NotGoodFitControlStyled } from './styles';
import { Checkbox, Typography } from '@mui/material';
import theme from 'application/theme';
import {
  CheckIcon,
  AddToBulkIcon,
  ArrowOutward,
  UncheckIcon
} from 'application/assets';
import {
  handleAddDataToBulkExport,
  handleChangeOrganizationNotAGoodFitStatus,
  handleGetCompanyInfo
} from 'common/companies/store/actions';
import { useAppDispatch, useAppSelector } from 'application/store';
import { sdrProfileSelector } from 'common/sdrProfile/store';
import { ExportScope } from 'common/companies/types';
import { selectedCompanySelector as selectedCompanySelector } from 'common/companies/store';
import { useCompaniesExtraInfo } from 'common/companies/hooks/useCompaniesExtraInfo';
import { LoaderOverlap } from 'common/loader';
import { useInAppNotification } from 'common/notify';

interface Props {
  companyId: number;
  companyName: string;
  onShowCompanyInfo: () => void;
}
const CompanyItemActions = ({companyId, companyName, onShowCompanyInfo}: Props) => {
  const dispatch = useAppDispatch();
  const [addedToBulk, setAddedToBulk] = React.useState(false);
  const [notGoodFit, setNotGoodFit] = React.useState(false);
  const {sdrProfile} = useAppSelector(sdrProfileSelector);
  const selectedCompany = useAppSelector(selectedCompanySelector);
  const { companiesExtraInfo, isLoading } = useCompaniesExtraInfo();
  const { showBulkExportNotification } = useInAppNotification();
  const paletteCommon = theme.palette.common;

  useEffect(() => {
    const extraInfo = companiesExtraInfo
      .find((info) => info.companyId === companyId);
    if (extraInfo) {
      setAddedToBulk(extraInfo.isInBulkExport);
      setNotGoodFit(extraInfo.isInNotGoodFit);
    }
  }, [companiesExtraInfo]);

  const handleAddToBulk = async () => {
    if (addedToBulk) return;
    const result = await dispatch(
      handleAddDataToBulkExport({
        sdrId: sdrProfile.id,
        organizationId: sdrProfile.organizationId,
        companyId,
        companyName,
        exportScope: ExportScope.COMPANY,
      })
    );
    if (result) {
      showBulkExportNotification(ExportScope.COMPANY);
      setAddedToBulk(true);
    }
  }
  const handleViewDetails = () => {
    if (selectedCompany.id !== companyId) {
      dispatch(handleGetCompanyInfo(companyId));
    }
    onShowCompanyInfo();
  }
  const handleSetAsNotAGoodFit = async () => {
    const isSuccessful = await dispatch(handleChangeOrganizationNotAGoodFitStatus(companyId, !notGoodFit));
    if (isSuccessful) {
      setNotGoodFit(!notGoodFit);
    }
  }
  return (
    <ActionsWrapperStyled>
      <LoaderOverlap isLoading={isLoading} size={40}/>
      <ActionItemStyled onClick={handleAddToBulk}>
        {addedToBulk ? <CheckIcon/> : <AddToBulkIcon/>}
        <Typography
          variant="caption"
          fontWeight={500}
          color={addedToBulk ? paletteCommon.green800 : paletteCommon.blue800}
        >
          Add to bulk
        </Typography>
      </ActionItemStyled>
      <ActionItemStyled onClick={handleViewDetails}>
        <ArrowOutward/>
        <Typography
          variant="caption"
          fontWeight={500}
          color={paletteCommon.blue800}
        >
          View details
        </Typography>
      </ActionItemStyled>
      <NotGoodFitControlStyled
        control={
          <Checkbox
            checked={notGoodFit}
            onChange={handleSetAsNotAGoodFit}
            checkedIcon={<UncheckIcon/>}
          />
        }
        label={<Typography
          variant="caption"
          color={paletteCommon.grey800}
          whiteSpace={'nowrap'}
        >
          Not good fit
        </Typography>}
      />
    </ActionsWrapperStyled>
  )
}

export default CompanyItemActions;
import {
  Box,
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import theme from "application/theme";

export const FilterWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${theme.spacing(36)};
  height: 100vh;
  position: sticky;
  top: 0;
  padding: ${theme.spacing(2)};
  align-items: flex-start;
  background: ${theme.palette.common.grey150};
`;

export const FiltersApplied = styled(Box)`
  display: flex;
  width: ${theme.spacing(3)};
  height: ${theme.spacing(3)};
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing(1)};
  border-radius: ${theme.spacing(0.75)};
  font-size: ${theme.spacing(2)};
  font-weight: 500;
  color: ${theme.palette.common.grey700};
  background: ${theme.palette.common.grey300};
`;

export const FilterCounter = styled(FiltersApplied)`
  width: ${theme.spacing(2.5)};
  height: ${theme.spacing(2.5)};
  font-size: ${theme.spacing(1.5)};
  color: ${theme.palette.common.grey800};
  background: ${theme.palette.common.grey250};
`;

export const CustomSwitch = styled(Switch)`
  width: ${theme.spacing(4.5)};
  height: ${theme.spacing(2.5)};
  padding: 0;

  & .MuiSwitch-switchBase {
    padding: 0;
    margin: ${theme.spacing(0.25)};
    transition-duration: 300ms;

    &.Mui-checked {
      transform: translateX(${theme.spacing(2)});
      color: ${theme.palette.common.white};

      & + .MuiSwitch-track {
        background-color: ${theme.palette.common.blue700};
        opacity: 1;
        border: 0;
      }

      &.Mui-disabled + .MuiSwitch-track {
        opacity: 0.5;
      }
    }

    &.Mui-disabled + .MuiSwitch-track {
      opacity: 0.3;
    }
  }

  & .MuiSwitch-thumb {
    box-sizing: border-box;
    width: ${theme.spacing(2)};
    height: ${theme.spacing(2)};
  }

  & .MuiSwitch-track {
    border-radius: ${theme.spacing(1.5)};
    background-color: ${theme.palette.common.grey400};
    opacity: 1;
  }
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  min-height: ${theme.spacing(2)};
  max-height: ${theme.spacing(5)};
  padding: ${theme.spacing(0.5)};
  .MuiFormControlLabel-label {
    font-size: ${theme.spacing(1.75)};
    font-weight: 400;
    width: 100%;
    color: ${theme.palette.common.grey900} !important;
  }
  .Mui-checked,
  .MuiCheckbox-indeterminate {
    color: ${theme.palette.common.blue700} !important;
  }

  .MuiSvgIcon-root path {
    width: ${theme.spacing(2.75)};
    height: ${theme.spacing(2.75)};
  }
`;

export const CheckboxLabelWithOverflow = styled(Typography)`
  width: fit-content;
  max-width: ${theme.spacing(25)};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const CheckboxLabel = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    background-color: ${theme.palette.common.blue700};
    height: ${theme.spacing(0.125)};
  }
  & .MuiTab-root.Mui-selected {
    color: ${theme.palette.common.blue700};
    padding: ${theme.spacing(0.25, 0)};
  }
  & .MuiTab-root {
    width: 50%;
    padding: ${theme.spacing(0.25, 0)};
  }
`;

export const StyledTab = styled(Tab)`
  font-size: ${theme.spacing(1.75)};
  font-weight: 400;
`;

export const CheckboxGroupStyled = styled(Box)<{ height?: number }>`
  .MuiFormControlLabel-root {
    margin: 0;
    :hover {
      border-radius: ${theme.spacing(0.5)};
      background-color: ${theme.palette.common.grey300};
    }
  }
  .MuiCheckbox-root {
    padding: ${theme.spacing(0, 1)};
    color: ${theme.palette.common.grey500};
    border-radius: ${theme.spacing(0.25)};
    :hover {
      background-color: transparent;
    }
  }
  height: auto;
  ${(props) =>
    props.height &&
    `
    overflow-y: auto;
    max-height: ${props.height}px;
    
    ::-webkit-scrollbar {
      padding-top: ${theme.spacing(2)};
      width: ${theme.spacing(0.5)};
    }

    ::-webkit-scrollbar-track {
      background: ${theme.palette.common.grey150};
      border-radius: ${theme.spacing(0.5)};
      height: ${theme.spacing(2)};
    }

    ::-webkit-scrollbar-thumb {
      border-radius: ${theme.spacing(0.5)};
      background: #667085;
    }

    .MuiToolbar-root {
      border: none;
      background: none;
    }
    `};
`;

export const StickyFooterWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(2, 0)};
  position: sticky;
  bottom: 0;
  background: ${theme.palette.common.grey150};
  z-index: 10;
`;

export const StickyHeaderWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${theme.spacing(2, 0)};
  position: sticky;
  top: 0;
  background: ${theme.palette.common.grey150};
  z-index: 10;
`;

export const CollapseWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: calc(100% - 60px);
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding-right: ${theme.spacing(2)};
  box-sizing: content-box;

  ::-webkit-scrollbar {
    width: 0;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
`;

export const FilterLabelWrapper = styled(Box)`
  max-width: ${theme.spacing(23.75)};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  font-size: ${theme.spacing(1.75)};
  font-weight: 400;
  color: ${theme.palette.common.grey900};
`;

export const SavedFiltersWrapper = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: ${theme.spacing(1)};
  height: ${theme.spacing(2.5)};
  padding: ${theme.spacing(0.75, 0.5)};
  border-radius: ${theme.spacing(0.5)};
  &:hover {
    background: ${theme.palette.common.grey300};
  }
  cursor: pointer;

  .delete-icon {
    visibility: hidden;
    padding: ${theme.spacing(0, 0.5, 0, 1)};
    display: flex;
    align-items: center;
  }

  &:hover .delete-icon {
    visibility: visible;
    padding: ${theme.spacing(0, 0.5, 0, 1)};
  }
`;

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputStyled } from 'application/components/FormInput/styles';
import theme from 'application/theme';
import { ShowLessAndMore } from 'common/companies/components';
import {
  CheckboxGroupController
} from 'common/companies/companiesFilter/components/CheckboxGroupController';
import { Box, Typography } from '@mui/material';

interface Props {
  name: string;
  options: string[];
  inputPlaceholder?: string;
  noOptionsLabel?: string;
}
export const MultiSelectWithSearch = ({options, name, inputPlaceholder, noOptionsLabel}: Props) => {
  const [search, setSearch] = useState("");
  const [countToDisplay, setCountToDisplay] = useState(5);
  const {watch} = useFormContext();
  const selectedOptions = watch(name);
  const selectedOptionsCount = selectedOptions?.length;
  const optionsEndRef = useRef<HTMLDivElement | null>(null);
  const filteredOptions = useMemo(() => {
    return options.filter((item: string | null) =>
      item ? item.toLowerCase().includes(search.toLowerCase()) : null
    );
  }, [search, options]);
  const displayedOptions = useMemo(
    () => filteredOptions.slice(0, countToDisplay)
    , [filteredOptions, countToDisplay]);
  const handleShowMore = (showMore: boolean) => {
    if (showMore) {
      setCountToDisplay(prev => prev + 10);
    } else {
      setCountToDisplay(5);
    }
  }

  const hasMore = useMemo(() => filteredOptions.length >= countToDisplay,
    [filteredOptions, countToDisplay]);

  useEffect(() => {
    if (optionsEndRef.current && countToDisplay > 5) {
      optionsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  },[countToDisplay]);

  const handleInputChange = (e: any) => {
    setSearch(e.target.value);
  };

  return (
    <>
      {
        selectedOptions && selectedOptions.length > 0 ?
          <CheckboxGroupController
            items={selectedOptions}
            fieldName={name}/> : null
      }
      {
        options.length > 0 ?
          <Box width={"100%"} pt={1.5}>
            <InputStyled
              font_size={theme.spacing(1.75)}
              sx={{width: "100%"}}
              value={search}
              placeholder={inputPlaceholder}
              height={4}
              autofillcolor={theme.palette.common.grey150}
              onChange={handleInputChange}
            />
          </Box>
          : <Typography variant="caption">{noOptionsLabel}</Typography>
      }
      <CheckboxGroupController
        items={displayedOptions}
        fieldName={name}/>
      {filteredOptions?.length > 5 ? (
        <Box ml={1}>
          <ShowLessAndMore
            showAll={!hasMore}
            setShowAll={handleShowMore}
            fontSize={theme.spacing(1.75)}
          />
        </Box>
      ) : null}
    </>)
}

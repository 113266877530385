import { useAppDispatch, useAppSelector } from 'application/store';
import { handleGetSdrProfile } from '../store/actions';

export const useSdrProfile = () => {
  const dispatch = useAppDispatch();
  const sdrProfile = useAppSelector((state) => state.sdr.sdrProfile);
  const {
    phoneCredits,
    emailCredits,
    usedPhoneCredits,
    usedEmailCredits,
    companyDataCredits,
    usedCompanyDataCredits } = sdrProfile;
  const availableCredits = {
    companyDataCredits: companyDataCredits - usedCompanyDataCredits,
    phoneCredits: phoneCredits - usedPhoneCredits,
    emailCredits: emailCredits - usedEmailCredits
  }
  const getSdrProfileInfo = () => {
    dispatch(handleGetSdrProfile());
  }

  return {
    sdrProfile,
    availableCredits,
    getSdrProfileInfo
  }
}

import { StyledTableContainer } from "./styles";
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import theme from "application/theme";
import { NotificationsI } from "../store/types";
import { formatDate } from "application/utils";
import { CloseOutlined } from "@mui/icons-material";
import { useAppDispatch } from "application/store";
import { handleDeleteNotificationById } from "../store/actions";
import EmptyTableInfo from "application/components/EmptyTable";

interface Props {
  notifications: NotificationsI[];
}

export const NotificationsTable = ({ notifications }: Props) => {
  const dispatch = useAppDispatch();
  const tableHead = ["Date", "Notification Name", ""];

  const handleDelete = (id: number) => {
    dispatch(handleDeleteNotificationById(id));
  };

  return (
    <StyledTableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ background: theme.palette.common.grey200 }}>
          <TableRow>
            {tableHead.map((header) => (
              <TableCell key={header}>
                <Typography
                  variant="body2"
                  color={theme.palette.common.grey800}
                >
                  {header}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {notifications?.length ? (
          <TableBody>
            {notifications.map((row: NotificationsI) => (
              <TableRow key={row.id}>
                <TableCell width={theme.spacing(20)}>
                  <Typography
                    variant="caption"
                    color={theme.palette.common.grey800}
                  >
                    {formatDate(row.creationDate)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="caption"
                    color={theme.palette.common.grey800}
                  >
                    {row.description}
                  </Typography>
                </TableCell>
                <TableCell width={theme.spacing(1)}>
                  <CloseOutlined
                    sx={{
                      cursor: "pointer",
                      width: theme.spacing(2),
                      height: theme.spacing(2),
                    }}
                    onClick={() => handleDelete(row.id)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        ) : null}
      </Table>
      {!notifications?.length ? (
        <EmptyTableInfo title="No notifications yet" />
      ) : null}
    </StyledTableContainer>
  );
};

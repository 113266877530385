import React from 'react';
import { useAppSelector } from 'application/store';
import { filterFormSelector } from 'common/companies/store';
import { FiltersCollapse } from 'application';
import { FiltersWrapper, FilterGroupWrapper } from './styles';
import { CheckedFilterItem } from './CheckedFilterItem';
import { getServiceTypeLabel } from 'common/companies/companiesFilter/utils';

export const ServiceTypeFilter = () => {
  const {serviceType} = useAppSelector(filterFormSelector);
  return serviceType && serviceType.length > 0 ? (
    <FiltersCollapse label={'SERVICE TYPE'} count={serviceType.length}>
      <FiltersWrapper>
        <FilterGroupWrapper>
          {serviceType.map((item) => (
            <CheckedFilterItem
              key={item}
              label={getServiceTypeLabel(item)}/>
          ))}
        </FilterGroupWrapper>
      </FiltersWrapper>
    </FiltersCollapse>
  ) : null;
}
import { BulkProcessI, ExportStage, LargeBulkExportScope } from './types';
import { createSlice } from '@reduxjs/toolkit';
import { emptyPageResponse, PageResponseI } from 'application/types';

interface InitialState {
  exportStage: ExportStage | null;
  alreadyExportedCompaniesAmount: number;
  bulkExportProcesses: PageResponseI<BulkProcessI>;
  alreadyExportedPermitsAmount: number;
  permitBulkExportProcesses: PageResponseI<BulkProcessI>;
  exportScope: LargeBulkExportScope | null;
}
const initialState : InitialState = {
  exportStage: null,
  alreadyExportedCompaniesAmount: 0,
  bulkExportProcesses: {
    content: [],
    empty: true,
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    totalPages: 0,
    totalElements: 0,
    size: 0,
  },
  alreadyExportedPermitsAmount: 0,
  exportScope: null,
  permitBulkExportProcesses: emptyPageResponse,
}

export const largeBulkExportSlice = createSlice({
  name: 'largeBulkExport',
  initialState,
  reducers: {
    setExportStage: (state, action) => {
      state.exportStage = action.payload;
    },
    cleanLargeBulkExportStore: (state) => {
      state.exportStage = initialState.exportStage;
    },
    setNumberOfExportedCompanies: (state, action) => {
      state.alreadyExportedCompaniesAmount = action.payload;
    },

    setBulkExportProcesses: (state, action) => {
      state.bulkExportProcesses = action.payload;
    },
    setPermitBulkExportProcesses: (state, action) => {
      state.permitBulkExportProcesses = action.payload;
    },
    setNumberOfExportedPermits: (state, action) => {
      state.alreadyExportedPermitsAmount = action.payload;
    },
    setExportScope: (state, action) => {
      state.exportScope = action.payload;
    }
  },
});

export const {
  setExportStage,
  setNumberOfExportedCompanies,
  cleanLargeBulkExportStore,
  setBulkExportProcesses,
  setPermitBulkExportProcesses,
  setNumberOfExportedPermits,
  setExportScope,
} = largeBulkExportSlice.actions;

import React from "react";
import { ButtonsWrapper, SettingsBarWrapper } from "./styles";
import { ViewToggleButton } from "./ViewToggleButton";
import { PermitColumnsSettings } from "./PermitColumnsSettings";
import { usePermitsNavigation } from "common/permits/hooks/usePermitsNavigation";
import { CompaniesColumnsSettings } from "./CompaniesColumnsSettings";
import { MapSettings } from "./MapSettings";
import { Box } from "@mui/system";
import { ShowAllButton } from "./ShowAllButton";

export const SettingsBar = () => {
  const { isMapView, isPermitTableMode } = usePermitsNavigation();
  return (
    <SettingsBarWrapper>
      <Box display={"flex"} gap={1}>
        {isMapView ? (
          <MapSettings />
        ) : isPermitTableMode ? (
          <PermitColumnsSettings />
        ) : (
          <CompaniesColumnsSettings />
        )}
      </Box>
      <ButtonsWrapper>
        {!isMapView && <ShowAllButton />}
        <ViewToggleButton />
      </ButtonsWrapper>
    </SettingsBarWrapper>
  );
};

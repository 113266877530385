import React, { useState } from "react";
import { Box } from "@mui/system";
import { Button } from "application";
import ExportModal from "../../modal/ExportModal";
import { ExportType } from "common/companies/types";

const ExportCompanyDataButton = () => {
  const [openExportModal, setOpenExportModal] = useState<ExportType | null>(null);

  const handleClick = () => {
    setOpenExportModal(ExportType.COMPANY_DATA)
  }
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Button
        label="Export company data"
        onClick={handleClick}
        height={5.5}
        type="submit"
      />
      {openExportModal ? (
        <ExportModal exportType={openExportModal} setExportType={setOpenExportModal} />
      ) : null}
    </Box>
  );
};

export default ExportCompanyDataButton;

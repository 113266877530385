import React from "react";
import { IconProps } from './types';
import theme from 'application/theme';

export const InfoIcon = ({color = theme.palette.common.grey700}:IconProps) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons" clipPath="url(#clip0_2653_66478)">
        <path
          id="Icon (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.99998 1.74992C4.10048 1.74992 1.74998 4.10042 1.74998 6.99992C1.74998 9.89941 4.10048 12.2499 6.99998 12.2499C9.89948 12.2499 12.25 9.89941 12.25 6.99992C12.25 4.10042 9.89948 1.74992 6.99998 1.74992ZM0.583313 6.99992C0.583313 3.45609 3.45615 0.583252 6.99998 0.583252C10.5438 0.583252 13.4166 3.45609 13.4166 6.99992C13.4166 10.5437 10.5438 13.4166 6.99998 13.4166C3.45615 13.4166 0.583313 10.5437 0.583313 6.99992ZM6.99998 4.08325C7.32215 4.08325 7.58331 4.34442 7.58331 4.66659V6.99992C7.58331 7.32209 7.32215 7.58325 6.99998 7.58325C6.67781 7.58325 6.41665 7.32209 6.41665 6.99992V4.66659C6.41665 4.34442 6.67781 4.08325 6.99998 4.08325ZM6.41665 9.33325C6.41665 9.01109 6.67781 8.74992 6.99998 8.74992H7.00581C7.32798 8.74992 7.58915 9.01109 7.58915 9.33325C7.58915 9.65542 7.32798 9.91659 7.00581 9.91659H6.99998C6.67781 9.91659 6.41665 9.65542 6.41665 9.33325Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2653_66478">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

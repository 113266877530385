import React, { useMemo, useState } from "react";

import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import theme from "application/theme";

import { TagStyled } from "./styles";

import { ShowLessAndMore } from "common/companies/components";
import CompanyInfoLabel from "../InfoLabel";
import { useAppSelector } from "application/store";
import { selectedCompanyTags } from "common/companies/store";

const TagItems = () => {
  const [showAllTags, setShowAllTags] = useState(true);

  const tags = useAppSelector(selectedCompanyTags);

  const displayTags = useMemo(
    () => (showAllTags && tags?.length ? tags : tags?.slice(0, 5)) ?? [],
    [showAllTags, tags]
  );

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={1.5}
      width={"100%"}
      p={theme.spacing(2, 4)}
    >
      <CompanyInfoLabel label="Tags" amount={tags?.length ?? 0} />
      <Box
        gap={1}
        display={"flex"}
        flexWrap={"wrap"}
        minWidth={theme.spacing(41.25)}
      >
        {displayTags.map((name) => (
          <TagStyled key={name}>
            <Typography variant="overline" color={theme.palette.common.grey800}>
              {name}
            </Typography>
          </TagStyled>
        ))}
      </Box>
      {tags && tags?.length > 5 ? (
        <ShowLessAndMore showAll={showAllTags} setShowAll={setShowAllTags} />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default TagItems;

import React from "react";
import { IconProps } from './types';
import theme from '../theme';

export const StarIcon: React.FC<IconProps & {size?: number}> = ({color = theme.palette.common.blue800, size = 40}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons">
        <path
          id="Icon"
          d="M19.3044 5.75561C19.6885 4.97738 19.8806 4.58826 20.1414 4.46394C20.3682 4.35577 20.6318 4.35577 20.8587 4.46394C21.1194 4.58826 21.3115 4.97738 21.6956 5.75561L25.3401 13.1389C25.4535 13.3686 25.5102 13.4835 25.5931 13.5727C25.6664 13.6517 25.7544 13.7157 25.8522 13.7611C25.9626 13.8125 26.0893 13.831 26.3429 13.868L34.495 15.0596C35.3534 15.1851 35.7826 15.2478 35.9813 15.4575C36.1541 15.6399 36.2354 15.8906 36.2025 16.1397C36.1647 16.426 35.8539 16.7287 35.2324 17.334L29.3357 23.0774C29.1519 23.2565 29.06 23.346 29.0007 23.4525C28.9482 23.5468 28.9145 23.6504 28.9015 23.7576C28.8868 23.8786 28.9085 24.0051 28.9519 24.258L30.3432 32.3703C30.49 33.2259 30.5634 33.6537 30.4255 33.9076C30.3055 34.1285 30.0922 34.2834 29.8451 34.3292C29.561 34.3819 29.1768 34.1798 28.4085 33.7758L21.1206 29.9431C20.8935 29.8237 20.78 29.764 20.6604 29.7406C20.5545 29.7198 20.4455 29.7198 20.3396 29.7406C20.22 29.764 20.1065 29.8237 19.8794 29.9431L12.5915 33.7758C11.8232 34.1798 11.439 34.3819 11.1549 34.3292C10.9078 34.2834 10.6945 34.1285 10.5745 33.9076C10.4366 33.6537 10.51 33.2259 10.6568 32.3703L12.0481 24.258C12.0915 24.0051 12.1132 23.8786 12.0985 23.7576C12.0855 23.6504 12.0518 23.5468 11.9993 23.4525C11.94 23.346 11.8481 23.2565 11.6643 23.0774L5.76758 17.334C5.14608 16.7287 4.83534 16.426 4.79752 16.1397C4.76462 15.8906 4.8459 15.6399 5.01873 15.4575C5.21737 15.2478 5.6466 15.1851 6.50505 15.0596L14.6572 13.868C14.9107 13.831 15.0374 13.8125 15.1478 13.7611C15.2456 13.7157 15.3336 13.6517 15.407 13.5727C15.4898 13.4835 15.5465 13.3686 15.6599 13.1389L19.3044 5.75561Z"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

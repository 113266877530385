import React from 'react';
import { useAppSelector } from 'application/store';
import { FiltersCollapse } from 'application';
import { FilterGroupWrapper, FiltersWrapper, FilterTitleStyled } from '../components/styles';
import { permitFiltersSelector } from 'common/permits/store';
import { getArrayLength } from 'application/utils/arrayUtils';
import { PermitAffiliationI } from 'common/permits/types';
import { CheckedFilterItem } from '../components';
import { affiliationTypeOptions } from '../../../../../../PermitFilters/FiltersBody/PermitFiltersGroup/components';

export const PermitAffiliationFilter = () => {
  const {affiliations} = useAppSelector(permitFiltersSelector);
  const getGeneralCount = () => {
    if(!affiliations) return 0;
    return affiliations.filter((affiliation) => affiliation.name || getArrayLength(affiliation.types) > 0).length;
  }
  return getGeneralCount() > 0 ? (
    <FiltersCollapse label={'PERMIT AFFILIATION'} count={getGeneralCount()}>
      <FiltersWrapper>
        {
          affiliations.map((affiliation) => (
            <AffiliationFilterItem permitAffiliation={affiliation}/>
          ))
        }
      </FiltersWrapper>
    </FiltersCollapse>
  ) : <></>;
}

const getAffiliationTypeLabel = (type: string) => {
  return affiliationTypeOptions.find((option) => option.value === type)?.label;
}

interface AffiliationFilterItemPropsI {
  permitAffiliation : PermitAffiliationI
}
const AffiliationFilterItem = ({permitAffiliation} : AffiliationFilterItemPropsI) => {
  if (!permitAffiliation) {
    return <></>;
  }
  if (!permitAffiliation.name && (getArrayLength(permitAffiliation.types) === 0 )) {
    return <></>;
  }
  return (
    <FilterGroupWrapper>
      <FilterTitleStyled>{`Name: ${permitAffiliation.name}`}</FilterTitleStyled>
      {
        getArrayLength(permitAffiliation.types) > 0 ? permitAffiliation.types.map((type) => (
          <CheckedFilterItem label={getAffiliationTypeLabel(type) || ''}/>
        )) : <></>
      }
    </FilterGroupWrapper>
  )
}

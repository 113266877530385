import { useNavigate, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { PermitsDisplayMode, PermitsView } from '../types';
import { PathNames } from 'application/routes';

export const usePermitsNavigation = () => {
  const { view, mode } = useParams();
  const navigate = useNavigate();
  const isMapView = useMemo(() => view === PermitsView.MAP, [view]);
  const isPermitTableMode = useMemo(() => mode === PermitsDisplayMode.PERMITS, [mode]);

  const toggleViewMode = (newView: PermitsView) => {
    newView === PermitsView.MAP ?
     navigate(`${PathNames.permits}/${newView}`)
      : navigate(`${PathNames.permits}/${newView}/${PermitsDisplayMode.PERMITS}`)
  }

  const toggleTableMode = (tableMode: PermitsDisplayMode) => {
    navigate(`${PathNames.permits}/${view}/${tableMode}`)
  }

  return {
    isMapView,
    isPermitTableMode,
    toggleViewMode,
    toggleTableMode
  }
}

import { ThunkDispatchT, VoidThunk } from "application/types";
import {
  closeEditModal,
  setIsAddMembeModalOpen,
  setIsLoadingAddMember,
  setOrganizationProfile,
  setRequestHistory,
  setSubscriptions,
  setTeamMembers,
} from ".";
import {
  addTeamMember,
  createCreditRequest,
  deleteSdr,
  editOrganization,
  getOrgRequestHistory,
  getOrganizationProfile,
  getSubscriptions,
  getTeamMembers,
  renewSubscriptions,
  resendInvitation,
  updateSdrCredits, removeHubspotIntegration,
} from "integration/api/organizations";
import { handleErrorNotification, showLoader, hideLoader } from "common";
import {
  CreditRequestFormI,
  OrgRequestHistoryI,
  OrganizationProfile,
  SubscriptionsI,
  TeamMemberI,
} from "./types";
import {
  AddMemberI,
  UpdateSdrCredits,
} from "integration/api/organizations/types";
import { sortSubscriptionsByLastDate } from "./utils";
import {handleResponse} from "../../responseService/responseService";

export const handleGetOrganizationProfile = (id: number, withLoader: boolean = true): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    if(withLoader) dispatch(showLoader());
    
    try {
      const response: OrganizationProfile = await getOrganizationProfile(id);
      dispatch(setOrganizationProfile(response));
    } catch (error) {
      console.error(error);
    } finally {
      if(withLoader) dispatch(hideLoader());
    }
  };
};

export const handleEditOrganization = (
  organization: OrganizationProfile
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(closeEditModal());
    dispatch(showLoader());
    try {
      const { id, hubspotAuthUrl, hubspotRemoveAppUrl, ...organizationList } =
        organization;
      const response: OrganizationProfile = await editOrganization(
        organizationList,
        id
      );
      dispatch(setOrganizationProfile(response));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleGetTeamMembers = (
  organizationId: number | string
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: TeamMemberI[] = await getTeamMembers(organizationId);
      dispatch(setTeamMembers(response));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setIsLoadingAddMember(false));
      dispatch(hideLoader());
    }
  };
};

export const handleGetSubscriptions = (
  organizationId: number | string
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: SubscriptionsI[] = await getSubscriptions(organizationId);
      dispatch(setSubscriptions(sortSubscriptionsByLastDate(response)));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleRenewSubscriptions = (
  userId: number,
  organizationId: number | string
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await renewSubscriptions(userId, organizationId);
      dispatch(handleGetSubscriptions(organizationId));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleAddTeamMember = (data: AddMemberI): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(setIsLoadingAddMember(true));
    try {
      await addTeamMember(data);
      dispatch(handleGetTeamMembers(data.organizationId));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(setIsLoadingAddMember(false));
      dispatch(setIsAddMembeModalOpen(false));
    }
  };
};

export const handleResendInvitation = ({
  organizationId,
  email,
  name,
}: {
  organizationId: number;
  email: string;
  name: string;
}): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await resendInvitation({
        organizationId,
        email,
        name,
      });
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleSendAndAddNewOne = (data: AddMemberI): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    try {
      await addTeamMember(data);
    } catch (error) {
      handleErrorNotification(error);
    }
  };
};

export const handleGetOrgRequestHistory = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: OrgRequestHistoryI = await getOrgRequestHistory();
      dispatch(setRequestHistory(response));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleCreateCreditRequest = (
  creditRequestForm: CreditRequestFormI
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await createCreditRequest(creditRequestForm);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(handleGetOrgRequestHistory());
      dispatch(handleGetOrganizationProfile(creditRequestForm.organizationId));
    }
  };
};

export const handleUpdateSdrCredits = (
  data: UpdateSdrCredits,
  organizationId: number
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await updateSdrCredits(data);
      dispatch(handleGetTeamMembers(organizationId));
      dispatch(handleGetOrganizationProfile(organizationId));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleDeleteSdr = (
  sdrId: number,
  organizationId: number
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await deleteSdr(sdrId);
      dispatch(handleGetTeamMembers(organizationId));
      dispatch(handleGetOrganizationProfile(organizationId));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleRemoveHubspotIntegration = (organizationId: number): VoidThunk => {
  return async(dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await removeHubspotIntegration(organizationId);
      await dispatch(handleGetOrganizationProfile(organizationId));
      handleResponse({
        message: `Hubspot integration has been removed successfully`,
      });
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  }
}

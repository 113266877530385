import { Box, styled } from "@mui/system";
import { Link } from "react-router-dom";
import theme from "application/theme";
import { IconButton, TableContainer } from "@mui/material";

export const TableContainerStyled = styled(TableContainer)`
  overflow-x: inherit;
  border: 1px solid ${theme.palette.common.grey400};
  border-radius: ${theme.spacing(1)};
  background: ${theme.palette.common.white};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`;

export const ListWrapper = styled(Box)`
  background-color: ${theme.palette.common.white};
  border: 1px solid ${theme.palette.common.grey400};
  border-radius: ${theme.spacing(1)};
`;

export const ListHeaderWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing(2, 2.5)};
`;

export const StyledWarning = styled(Box)`
  display: flex;
  text-align: center;
  height: ${theme.spacing(2.75)};
  padding: ${theme.spacing(0.25, 1)};
  border-radius: ${theme.spacing(2)};
  font-size: ${theme.spacing(1.5)};
  line-height: ${theme.spacing(2.25)};
  font-weight: 400;
`;

export const LinkStyled = styled(Link)`
  color: ${theme.palette.common.blue700};
  font-weight: 500;
  font-weight: ${theme.typography.fontWeightMedium};
  font-size: ${theme.spacing(1.75)};
  text-decoration: none;
`;

export const StyledIconButton = styled(IconButton)`
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: ${theme.spacing(1)};
  &.MuiButtonBase-root:hover {
    background-color: ${theme.palette.common.grey100};
  }
`;

export const OptionsWrapper = styled(Box)`
  position: absolute;
  width: ${theme.spacing(19)};
  background-color: ${theme.palette.common.white};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  z-index: 10;
  top: ${theme.spacing(5)};
  right: 0;
  border-radius: ${theme.spacing(1)};
`;

export const ShowMoreItem = styled(Box)`
  cursor: pointer;
  padding: ${theme.spacing(1.25, 2)};
  width: 100%;
  &:hover {
    background-color: ${theme.palette.common.grey100};
  }
`;

import React, { useEffect } from "react";
import { ModalComponent } from "application/components/ModalComponent";
import { StyledButtonContainer, StyledProfileForm } from "./styles";
import * as yup from "yup";
import { Errors } from "./constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, FormInput, FormSwitch } from "application";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { commonColors } from "application/theme";
import { EditSdrProfileI } from "../store/types";

interface Props {
  isOpen: boolean;
  profile: EditSdrProfileI;
  onClose: () => void;
  onSubmit: (values: EditSdrProfileI) => void;
  onClickChangePassword: () => void;
}

export const EditProfileModal = ({
  isOpen,
  profile,
  onClose,
  onSubmit,
  onClickChangePassword,
}: Props) => {
  const schema = yup
    .object({
      firstName: yup.string().required(Errors.firstNameRequired),
      lastName: yup.string().required(Errors.lastNameRequired),
      emailNotificationEnabled: yup.boolean(),
    })
    .required();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<EditSdrProfileI>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(profile);
  }, [profile]);

  const onSubmitForm = (values: EditSdrProfileI) => {
    onSubmit(values);
  };

  return (
    <ModalComponent
      title="Edit Profile"
      isOpen={isOpen}
      handleClose={onClose}
      width={"30%"}
    >
      <StyledProfileForm onSubmit={handleSubmit(onSubmitForm)}>
        <FormInput
          label={"First Name"}
          name={"firstName"}
          register={register("firstName")}
          error={errors.firstName?.message}
          placeholder={"Name"}
        />
        <FormInput
          label={"Last Name"}
          name={"lastName"}
          register={register("lastName")}
          error={errors.lastName?.message}
          placeholder={"Name"}
        />
        <Box>
          <Typography
            variant="caption"
            color={commonColors.grey800}
            sx={{ display: "block" }}
            mb={0.75}
          >
            Password
          </Typography>
          <Typography variant="overline" color={commonColors.grey900}>
            ****************
          </Typography>
          <Typography
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            ml={1}
            variant="button"
            color={commonColors.blue800}
            onClick={onClickChangePassword}
          >
            Change
          </Typography>
        </Box>
        <FormSwitch
          label={"Enable Email Notifications"}
          checked={!!watch("emailNotificationEnabled")}
          register={register("emailNotificationEnabled")}
        />

        <StyledButtonContainer>
          <Button type="button" label={"Cancel"} onClick={onClose} />
          <Button type="submit" label={"Save"} />
        </StyledButtonContainer>
      </StyledProfileForm>
    </ModalComponent>
  );
};

import React, { useEffect, useMemo, useState } from "react";
import { Box, Collapse, Divider, FormGroup, Typography } from "@mui/material";
import theme from "application/theme";
import { CheckboxGroupStyled, CustomSwitch } from "./styles";
import { Controller, useFormContext } from "react-hook-form";
import { MultipleCheckboxController } from "./components";
import { ShowLessAndMore } from "../components";
import { useAppSelector } from "application/store";
import { subContractorCategoriesSelector } from "../store";
import { FiltersCollapse } from 'application';

const CompanyTypeCollapse = ({ label }: { label: string }) => {
  const subContractorOptions = useAppSelector(subContractorCategoriesSelector);
  const [showAll, setShowAll] = useState(false);
  const [counter, setCounter] = useState(0);
  const { control, watch, resetField } = useFormContext();
  const { isGeneralContractor, subContractorCategories, isSubContractor } =
    watch();

  useEffect(() => {
    const newCounter =
      (isGeneralContractor ? 1 : 0) + (subContractorCategories?.length || 0);

    if (newCounter !== counter) {
      setCounter(newCounter);
    }
    if (!isSubContractor) {
      resetField("subContractorCategories");
    }
  }, [
    isGeneralContractor,
    subContractorCategories,
    isSubContractor,
    counter,
    resetField,
  ]);

  const displayOptions = useMemo(
    () => (showAll ? subContractorOptions : subContractorOptions.slice(0, 5)),
    [showAll, subContractorOptions]
  );

  return (
    <Box width={"100%"} className="company-type-collapse">
      <FiltersCollapse label={label} count={counter}>
        <Box display="flex" flexDirection="column" gap={1.5} pt={2.25}>
          <Box display="flex" width="100%" justifyContent="space-between">
            <Typography variant="caption" color={theme.palette.common.grey900}>
              General Contractor
            </Typography>
            <Controller
              name="isGeneralContractor"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <CustomSwitch
                  name={field.name}
                  checked={field.value || false}
                  onChange={field.onChange}
                />
              )}
            />
          </Box>
          <Divider />
          <Box display="flex" width="100%" justifyContent="space-between">
            <Typography variant="caption" color={theme.palette.common.grey900}>
              Subcontractor
            </Typography>
            <Controller
              name="isSubContractor"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <CustomSwitch
                  name={field.name}
                  checked={field.value || false}
                  onChange={field.onChange}
                />
              )}
            />
          </Box>
          {watch("isSubContractor") ? (
            <Box>
              <Typography
                variant="caption"
                fontSize={theme.spacing(1.5)}
                color={theme.palette.common.grey700}
                textTransform="uppercase"
                ml={2}
              >
                Subcontractor category
              </Typography>
              <CheckboxGroupStyled height={displayOptions.length > 5 ? 320 : 0}>
                <FormGroup>
                  {displayOptions.map((item: string, idx: number) => (
                    <MultipleCheckboxController
                      fieldName={"subContractorCategories"}
                      value={item}
                      label={item}
                      key={idx}
                    />
                  ))}
                </FormGroup>
              </CheckboxGroupStyled>
              {subContractorOptions?.length > 5 ? (
                <Box ml={1}>
                  <ShowLessAndMore
                    showAll={showAll}
                    setShowAll={setShowAll}
                    fontSize={theme.spacing(1.75)}
                  />
                </Box>
              ) : null}
              <Divider />
            </Box>
          ) : null}
        </Box>
      </FiltersCollapse>
    </Box>
  )
};

export default CompanyTypeCollapse;

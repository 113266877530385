import React, { useMemo } from 'react';
import { useAppSelector } from 'application/store';
import { permitsSelector } from 'common/permits/store';
import { CollapseFilter } from '../../../CollapseFilter';
import { useFormContext } from 'react-hook-form';
import { MultiSelectWithSearch } from '../MultiSelectWithSearch';


const PERMIT_TYPE_FILTER_NAME = 'permitTypes';
export const PermitTypeFilter = () => {
  const { permitTypes } = useAppSelector(permitsSelector);
  const {watch} = useFormContext();

  const filtersCount = useMemo(() => {
    const selectedTypes = watch(PERMIT_TYPE_FILTER_NAME);
    return selectedTypes?.length;
  }, [watch(PERMIT_TYPE_FILTER_NAME)]);

  return (
    <CollapseFilter label={'Permit type'} count={filtersCount}>
      <MultiSelectWithSearch
        name={PERMIT_TYPE_FILTER_NAME}
        options={permitTypes}
        inputPlaceholder="Search by permit type"
        noOptionsLabel="No permit types"
      />
    </CollapseFilter>
  )
}

import React, { useEffect, useMemo, useState } from "react";

import { useAppDispatch, useAppSelector } from "application/store";
import {
  companyStructureSelector,
  companyStructureContentSelector,
  setSelectedOrganization,
  resultExportSelector,
  selectedCompanySelector,
} from "common/companies/store";

import { Box } from "@mui/system";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import theme from "application/theme";
import { StructureWrapper } from "../styles";

import ExportGroupButton from "./ExportGroupButton";
import CompanyInfoLabel from "../InfoLabel";
import { ShowLessAndMore } from "common/companies/components";

import { Pagination, UserPhoto } from "application";
import { StructureI } from "integration/api/companies/types";
import { handleGetCompanyStructure } from "common/companies/store/actions";
import ExportModal from "common/companies/modal/ExportModal";
import { EmailStatuses } from "common/companies/companiesFilter/const";
import TableSkeleton from "./TableSkeleton";
import { useLocation } from "react-router-dom";
import { ExportResultModal } from "common/companies/modal/ExportResultModal";
import { NotifyReviewBulkExports } from "common/companies/modal/NotifyReviewBulkExports";
import { handleGetOrganizationProfile } from "common/organization/store/actions";
import { sdrProfileSelector } from "common/sdrProfile/store";
import {ExportScope, ExportType} from "common/companies/types";
import { usePageQueryParams } from 'application/hooks/usePageQueryParams';

const tableHead = ["Full Name", "Location", "Available for export"];

const OrganizationStructure = () => {
  const dispatch = useAppDispatch();
  const [showAllStructure, setShowAllStructure] = useState(true);
  const [openExportModal, setOpenExportModal] = useState<ExportType | null>(null);
  const location = useLocation();
  const selectedCompany = useAppSelector(selectedCompanySelector);
  const isOpen = useAppSelector(resultExportSelector);
  const { sdrProfile } = useAppSelector(sdrProfileSelector);

  const { totalElements, numberOfElements, totalPages } = useAppSelector(
    companyStructureSelector
  );
  const companyStructureContent = useAppSelector(
    companyStructureContentSelector
  );

  const pageRequest = (page: number) => {
    dispatch(handleGetCompanyStructure(selectedCompany.id, page));
  };

  const {currentPage} = usePageQueryParams();

  const displayData = useMemo(
    () =>
      (showAllStructure && companyStructureContent?.length
        ? companyStructureContent
        : companyStructureContent?.slice(0, 5)) ?? [],
    [showAllStructure, companyStructureContent]
  );

  useEffect(() => {
    dispatch(handleGetOrganizationProfile(sdrProfile.organizationId, false));

    return () => {
      const newUrl = window.location.pathname;
      window.history.replaceState({}, "", newUrl);
    };
  }, []);

  return (
    <Box
      className="org-structure-info"
      display={"flex"}
      flexDirection={"column"}
      gap={1.5}
      width={"100%"}
      p={theme.spacing(2, 4)}
    >
      <CompanyInfoLabel
        label="Organization structure"
        amount={totalElements || 0}
      />
      <StructureWrapper>
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "none",
            borderRadius: theme.spacing(1),
            overflow: "hidden",
          }}
        >
          <Table sx={{ minWidth: 350 }} aria-label="simple table">
            <TableHead
              sx={{
                background: theme.palette.common.grey200,
              }}
            >
              <TableRow sx={{ height: theme.spacing(6.25) }}>
                {tableHead.map((title) => (
                  <TableCell key={title}>
                    <Typography
                      variant="body2"
                      color={theme.palette.common.grey800}
                    >
                      {title}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {displayData?.length > 0 ? (
              <TableBody>
                {displayData.map((row: StructureI, idx) => (
                  <TableRow
                    key={row.id || idx}
                    sx={{
                      height: theme.spacing(7.5),
                    }}
                  >
                    <TableCell component="th" scope="row" width={"30%"}>
                      <Box gap={2} display={"flex"} alignItems="center">
                        <UserPhoto
                          photoUrl={row.photoUrl || ""}
                          id={+row.id}
                          firstName={row.firstName || ""}
                          lastName={row.lastName || ""}
                        />
                        <Box display={"flex"} flexDirection={"column"}>
                          <Typography
                            variant="caption"
                            color={theme.palette.common.grey800}
                          >
                            {row.firstName || ""} {row.lastName || ""}
                          </Typography>
                          <Typography
                            variant="caption"
                            color={theme.palette.common.grey600}
                          >
                            {row.title || ""}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>

                    <TableCell width={"35%"}>
                      <Typography
                        variant="caption"
                        color={theme.palette.common.grey800}
                      >
                        <Box display={"flex"} flexDirection={"column"}>
                          <Typography
                            variant="caption"
                            color={theme.palette.common.grey800}
                          >
                            {row.city || ""}
                          </Typography>
                          <Typography
                            variant="caption"
                            color={theme.palette.common.grey600}
                          >
                            {row.state ? `${row.state}, ` : ""}
                            {row.country || ""}
                          </Typography>
                        </Box>
                      </Typography>
                    </TableCell>
                    <TableCell
                      width={"35%"}
                      sx={{ minWidth: theme.spacing(18.75) }}
                    >
                      {row.emailStatus === EmailStatuses.VERIFIED ? (
                        <ExportGroupButton
                          openExportModal={(type) => {
                            setOpenExportModal(type);
                            dispatch(setSelectedOrganization(row));
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <TableSkeleton />
            )}
          </Table>
        </TableContainer>
        {showAllStructure && totalElements ? (
          <Pagination
            totalElements={totalElements}
            numberOfElements={numberOfElements}
            totalPages={totalPages}
            pageRequest={pageRequest}
            currentPage={currentPage - 1}
          />
        ) : null}
      </StructureWrapper>
      {companyStructureContent && companyStructureContent?.length ? (
        <ShowLessAndMore
          showAll={showAllStructure}
          setShowAll={setShowAllStructure}
        />
      ) : null}
      {openExportModal ? (
        <ExportModal exportType={openExportModal}
                     setExportType={setOpenExportModal} />
      ) : null}
      {isOpen ? <ExportResultModal /> : null}
      <NotifyReviewBulkExports/>
    </Box>
  );
};

export default OrganizationStructure;

import { styled } from "@mui/system";
import { Box, TableContainer } from "@mui/material";
import theme from "application/theme";

export const NotificationsPageWrapper = styled(Box)`
  display: flex;
  padding: ${theme.spacing(4)};
  flex-direction: column;
  gap: ${theme.spacing(2.5)};
`;

export const StyledTableContainer = styled(TableContainer)`
  border: 1px solid ${theme.palette.common.grey400};
  border-radius: ${theme.spacing(1)};
  background: ${theme.palette.common.white};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`;

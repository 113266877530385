import React from "react";
import theme from 'application/theme';
import { IconProps } from 'application/assets/types';
export const MapPointIcon: React.FC<IconProps> = ({color = theme.palette.common.blue800}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M15.9992 15.9994C16.5355 15.9994 16.9945 15.8114 17.3764 15.4354C17.7583 15.0594 17.9492 14.6074 17.9492 14.0794C17.9492 13.5514 17.7583 13.0994 17.3764 12.7234C16.9945 12.3474 16.5355 12.1594 15.9992 12.1594C15.463 12.1594 15.0039 12.3474 14.622 12.7234C14.2402 13.0994 14.0492 13.5514 14.0492 14.0794C14.0492 14.6074 14.2402 15.0594 14.622 15.4354C15.0039 15.8114 15.463 15.9994 15.9992 15.9994ZM15.9992 25.5994C13.383 23.4074 11.4289 21.3714 10.137 19.4914C8.84516 17.6114 8.19922 15.8714 8.19922 14.2714C8.19922 11.8714 8.98328 9.95941 10.5514 8.53541C12.1195 7.11141 13.9355 6.39941 15.9992 6.39941C18.063 6.39941 19.8789 7.11141 21.447 8.53541C23.0152 9.95941 23.7992 11.8714 23.7992 14.2714C23.7992 15.8714 23.1533 17.6114 21.8614 19.4914C20.5695 21.3714 18.6155 23.4074 15.9992 25.5994Z"
        fill={color}/>
    </svg>
  );
};

import { ThunkDispatchT, VoidThunk } from "application/types";
import { handleErrorNotification, hideLoader, showLoader } from "common";
import { NotificationsI } from "./types";
import {
  deleteNotificationById,
  getAllNotifications,
  readNotificationById,
} from "integration/api/notifications";
import { setNotifications } from ".";

export const handleGetNotifications = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    try {
      const response: NotificationsI = await getAllNotifications();
      dispatch(setNotifications(response));
    } catch (error) {
      handleErrorNotification(error);
    }
  };
};

export const handleReadAllNotifications = (ids: number[]): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    try {
      await readNotificationById(ids);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(handleGetNotifications());
    }
  };
};

export const handleDeleteNotificationById = (id: number): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await deleteNotificationById(id);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(handleGetNotifications());
      dispatch(hideLoader());
    }
  };
};

import React from 'react';
import { Button } from 'application';
import { ActionsWrapper } from './styles';

interface Props {
  onClear: () => void;
  onSubmit: () => void;
}

export const Actions = ({onClear, onSubmit}: Props) => {
  return (
    <ActionsWrapper>
      <Button
        label="Clear all"
        height={5.5}
        fullWidth
        type="button"
        onClick={onClear}
      />
      <Button
        label="Apply"
        height={5.5}
        fullWidth
        type="submit"
        onClick={onSubmit}
      />
    </ActionsWrapper>
  )
}

import { usePermitFiltersContext } from './usePermitFiltersContext';
import { useAppDispatch, useAppSelector } from 'application/store';
import { handleDeleteSavedSearch, handleGetSavedFilters, handleSaveFilters } from '../store/actions';
import { PermitWithCompanyFilterDataI } from '../types';
import { usePermitsNavigation } from './usePermitsNavigation';
import { usePermitsWithCompaniesSearch } from './usePermitsWithCompaniesSearch';
import { setSearchInArea, setZoomedArea } from '../store';

export const useSavedFilters = () => {
  const {filtersWithZoomedArea,
    permitFilterForm,
    companyFilterForm} = usePermitFiltersContext();
  const dispatch = useAppDispatch();
  const {savedSearches, selectedSavedSearch} = useAppSelector((state) => state.permits);
  const {isMapView, isPermitTableMode} = usePermitsNavigation();
  const {searchForPermitsAndCompaniesOnBackground, searchForCompaniesAndPermitsCoordinates, searchForCompaniesAndPermitsOnBackground} = usePermitsWithCompaniesSearch();

  const getSavedFilters = () => {
    return dispatch(handleGetSavedFilters());
  }
  const deleteSavedSearch = (id: number) => {
    return dispatch(handleDeleteSavedSearch(id));
  }

  const saveFilters = async (name: string) => {
    return await dispatch(handleSaveFilters(name, filtersWithZoomedArea));
  }

  const resetForm = (filterData: PermitWithCompanyFilterDataI) => {
    permitFilterForm.reset(filterData.permitFilter);
    companyFilterForm.reset(filterData.companyFilter);
  }
  const applyZoomedArea = (filterData: PermitWithCompanyFilterDataI) => {
    const zoomedArea = filterData.permitFilter.zoomedArea || filterData.companyFilter.zoomedArea;
    if (zoomedArea) {
      dispatch(setZoomedArea(zoomedArea));
      dispatch(setSearchInArea(true));
    } else {
      dispatch(setSearchInArea(false));
      dispatch(setZoomedArea(null));
    }
  }
  const applySavedSearch = (id: number) => {
    const savedFilter = savedSearches.find((search) => search.id === id);
    if (!savedFilter) {
      return;
    }
    const filterData = savedFilter.filterValue;
    resetForm(filterData);
    applyZoomedArea(filterData);
    if (isMapView) {
      searchForCompaniesAndPermitsCoordinates(filterData);
    } else if (isPermitTableMode) {
      searchForPermitsAndCompaniesOnBackground(filterData);
    } else {
      searchForCompaniesAndPermitsOnBackground(filterData);
    }
  }

  return {
    saveFilters,
    getSavedFilters,
    savedSearches,
    selectedSavedSearch,
    deleteSavedSearch,
    applySavedSearch,
  }
}

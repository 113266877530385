import React from "react";

import { Controller, useFormContext } from "react-hook-form";

import { MultiSelectItem } from 'application/components/MultiSelect/MultiSelectItem';

interface Props {
  fieldName: string;
  value: string;
  label: string;
  tooltip?: string;
}
const MultipleCheckboxController = ({
  fieldName,
  value,
  label,
  tooltip,
}: Props) => {
  const { control, getValues, setValue } = useFormContext();

  const handleClick = (value: string | null) => {
    if (value !== null) {
      const selectedOptions = getValues(fieldName) || [];
      const updatedOptions = selectedOptions.includes(value)
        ? selectedOptions.filter((option: string) => option !== value)
        : [...selectedOptions, value];
      setValue(fieldName, updatedOptions);
    }
  };

  return (
    <Controller
      key={fieldName}
      name={fieldName}
      control={control}
      defaultValue={[]}
      render={({ field }) => (
        <MultiSelectItem
          checked={field.value.includes(value)}
          label={label}
          value={value}
          onClick={handleClick}
          field={field}
          tooltip={tooltip}
        />
      )}
    />
  );
};

export default MultipleCheckboxController;

import React from 'react';
import { useAppSelector } from 'application/store';
import { filterFormSelector } from 'common/companies/store';
import { FiltersCollapse } from 'application';
import { FilterGroupWrapper, FiltersWrapper } from './styles';
import { CheckedFilterItem } from './CheckedFilterItem';
import { getNumberOfEmployeesLabel } from 'common/companies/companiesFilter/utils';

export const NumberOfEmployeesFilter = () => {
  const {companySizeRange} = useAppSelector(filterFormSelector);
  return companySizeRange.length > 0 ? (
    <FiltersCollapse label={'# OF EMPLOYEES'} count={companySizeRange.length}>
      <FiltersWrapper>
        <FilterGroupWrapper>
          {companySizeRange.map((item) => (
            <CheckedFilterItem
              key={item}
              label={getNumberOfEmployeesLabel(item)}/>
          ))
          }
        </FilterGroupWrapper>
      </FiltersWrapper>
    </FiltersCollapse>
  ) : null;
}
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import theme from 'application/theme';

export const StructureWrapper = styled(Box)`
  background-color: ${theme.palette.common.white};
  border: 1px solid ${theme.palette.common.grey400};
  border-radius: ${theme.spacing(1)};
`;

export const EmptyTableWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing(1.5)};
  padding: ${theme.spacing(4, 0)};
`;

import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import theme from "application/theme";

interface CheckboxGroupProps {
  height?: number;
  maxHeight?: number;
}

export const CheckItemWrapper = styled(Box)`
  display: flex;
  min-height: ${theme.spacing(2)};
  max-height: ${theme.spacing(5)};
  padding: ${theme.spacing(0.5)};
  font-size: ${theme.spacing(1.75)};
  font-weight: 400;
  width: 100%;
  color: ${theme.palette.common.grey900} !important;
  .Mui-checked,
  .MuiCheckbox-indeterminate {
    color: ${theme.palette.common.blue700} !important;
  }

  .MuiSvgIcon-root path {
    width: ${theme.spacing(2.75)};
    height: ${theme.spacing(2.75)};
  }
`;

export const CheckboxLabel = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const CheckboxLabelWithOverflow = styled(Typography)`
  width: fit-content;
  max-width: ${theme.spacing(25)};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const CheckboxGroupStyled = styled(Box)<CheckboxGroupProps>`
  display: flex;
  flex-direction: column;
  max-height: ${(props) =>
    props.maxHeight ? `${props.height}px` : theme.spacing(40)};
  .MuiFormControlLabel-root {
    margin: 0;
    :hover {
      border-radius: ${theme.spacing(0.5)};
      background-color: ${theme.palette.common.grey300};
    }
  }
  .MuiCheckbox-root {
    padding: ${theme.spacing(0, 1)};
    color: ${theme.palette.common.grey500};
    border-radius: ${theme.spacing(0.25)};
    :hover {
      background-color: transparent;
    }
  }
  height: auto;
  ${(props) =>
    props.height &&
    `
    overflow-y: auto;
    max-height: ${props.height}px;
    
    ::-webkit-scrollbar {
      padding-top: ${theme.spacing(2)};
      width: ${theme.spacing(0.5)};
    }

    ::-webkit-scrollbar-track {
      background: ${theme.palette.common.grey150};
      border-radius: ${theme.spacing(0.5)};
      height: ${theme.spacing(2)};
    }

    ::-webkit-scrollbar-thumb {
      border-radius: ${theme.spacing(0.5)};
      background: #667085;
    }

    .MuiToolbar-root {
      border: none;
      background: none;
    }
    `};
`;

export const LoaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
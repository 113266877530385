import { Box, styled } from "@mui/system";
import theme from "application/theme";
import { TableContainer } from "@mui/material";

export const TableContainerStyled = styled(TableContainer)`
  overflow-x: inherit;
  border: 1px solid ${theme.palette.common.grey400};
  border-radius: ${theme.spacing(1)};
  background: ${theme.palette.common.white};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`;

export const StyledWarning = styled(Box)`
  display: inline-block;
  text-align: center;
  height: ${theme.spacing(2.75)};
  padding: ${theme.spacing(0.25, 1)};
  border-radius: ${theme.spacing(2)};
  font-size: ${theme.spacing(1.5)};
  line-height: ${theme.spacing(2.25)};
  font-weight: 400;
  background-color: ${theme.palette.common.red50};
  color: ${theme.palette.common.red600};
`;

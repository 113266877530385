import React, { useMemo } from 'react';
import { CollapseFilter } from '../../../CollapseFilter';
import { useAppSelector } from 'application/store';
import { permitsSelector } from 'common/permits/store';
import { useFormContext } from 'react-hook-form';
import { MultiSelectWithSearch } from '../MultiSelectWithSearch';


const DWELLING_TYPE_FILTER_NAME = "dwellingTypes";
export const DwellingTypeFilter = () => {
  const { dwellingTypes } = useAppSelector(permitsSelector);
  const {watch} = useFormContext();

  const dwellingTypesCount = useMemo(() => {
    const selectedDwellingTypes = watch(DWELLING_TYPE_FILTER_NAME);
    return selectedDwellingTypes?.length;
  }, [watch(DWELLING_TYPE_FILTER_NAME)]);

  return (
    <CollapseFilter label={'Dwelling type'} count={dwellingTypesCount}>
      <MultiSelectWithSearch
        name={DWELLING_TYPE_FILTER_NAME}
        options={dwellingTypes}
        inputPlaceholder="Search by dwelling types"
        noOptionsLabel="No dwelling types"
      />
    </CollapseFilter>
  )
}

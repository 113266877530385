import theme from "application/theme";
import CompanyInfoLabel from "../InfoLabel";
import React, {useMemo} from "react";
import {Box} from "@mui/system";
import {useAppSelector} from "application/store";
import {companyContactsLoadingSelector, selectedCompanySelector} from "../../store";
import {useAppDispatch} from "application/store";
import {handleGetCompanyContacts} from "../../store/actions";
import {ContactsTableSkeleton} from "./ContactsTableSkeleton";
import {UnlockContactsButton} from "./UnlockContactsButton";
import {ContactsTable} from "./ContactsTable";


export const BusinessContactData = () => {
    const { companyContactData, id: companyId} = useAppSelector(selectedCompanySelector);
    const { phones, emails, availablePhonesCount, availableEmailsCount, isContactsAlreadyUnlocked  } = useMemo(() => companyContactData || {
        phones: [],
        emails: [],
        isContactsAlreadyUnlocked: false,
        availableEmailsCount: 0,
        availablePhonesCount: 0,
    }, [companyContactData]);
    const dispatch = useAppDispatch();
    const isCompanyContactsLoading = useAppSelector(companyContactsLoadingSelector);

    const contactsCount = useMemo(() => {
        return availableEmailsCount + availablePhonesCount;
    }, [availableEmailsCount, availablePhonesCount]);
    const contactsAreAvailable = useMemo(() => {
        return contactsCount > 0;
    }, [contactsCount]);
    const displayContacts = useMemo(() => {
        return contactsCount > 0 && isContactsAlreadyUnlocked;
    }, [emails, phones]);

    const getCompanyContacts = async () => {
        await dispatch(handleGetCompanyContacts(companyId));
    }
    return (
        <Box
            display={"flex"}
            flexDirection={"column"}
            gap={1.5}
            width={"100%"}
            p={theme.spacing(2, 4)}
        >
            <CompanyInfoLabel
                label="Contact data"
                amount={contactsCount}
                withoutAmount={contactsCount === 0}
            />
            { displayContacts ? <ContactsTable emails={emails || []} phones={phones || []}/>
                : contactsAreAvailable && <>
                    <Box position='relative'>
                        <ContactsTableSkeleton/>
                        <UnlockContactsButton isLoading={isCompanyContactsLoading} onClick={getCompanyContacts}/>
                    </Box>
                </> }
        </Box>)
}
import React from "react";
import { Typography } from "@mui/material";

import theme from "application/theme";

import noInfo from "application/assets/noInfo.png";

import { EmptyContentWrapper } from "./styles";
import { Button } from "application";
import { Add } from "@mui/icons-material";

const EmptyInfo = () => {
  return (
    <EmptyContentWrapper>
        <img src={noInfo} alt="No results found" />
        <Typography
          variant="button"
          color={theme.palette.common.grey800}
          mt={2}
        >
          No added seats yet
        </Typography>
        <Typography
          variant="caption"
          color={theme.palette.common.grey600}
          mb={2}
        >
          We didn’t find any active seats in your list. Looks, like you hasn’t
          assign yet.
          <br />
          Please, add new seat.
        </Typography>
        <Button
          label="Assign a seat"
          startAdornment={<Add sx={{ color: theme.palette.common.white }} />}
          height={5.5}
          type="submit"
        />
    </EmptyContentWrapper>
  );
};

export default EmptyInfo;

export interface PaletteColorOptions {
  main: string;
  light: string;
  dark?: string;
}
export interface PaletteOptions {
  primary: PaletteColorOptions;
  secondary: PaletteColorOptions;
  text: PalleteTextOptions;
  success: PaletteColorOptions;
  error: PaletteColorOptions;
  warning: PaletteColorOptions;
  info: PaletteColorOptions;
  common: ColorsPallet;
}
export interface PalleteTextOptions {
  primary: string;
  secondary: string;
  disabled?: string;
}

export const colors = {
  // Grey
  grey900: "#2B2B2B",
  grey800: "#4D4D4D",
  grey700: "#6D6D6D",
  grey600: "#818181",
  grey500: "#ABABAB",
  grey400: "#C9C9C9",
  grey300: "#EBEBEB",
  grey200: "#F2F2F2",
  grey250: "#EBEDEF",
  grey150: "#f6f7f9",
  grey100: "#F7F7F7",
  grey50: "#FCFCFC",
  // Blue (main/light)
  blue900: "#2F40CD",
  blue800: "#2863EC",
  blue700: "#2176FF",
  blue600: "#108AFF",
  blue500: "#0099FF",
  blue400: "#28A9FF",
  blue300: "#58B9FF",
  blue200: "#8DCDFF",
  blue100: "#BBE0FF",
  blue50: "#EBF2FF",
  //Turquoise
  turquoise900: "#004A44",
  turquoise800: "#006662",
  turquoise700: "#007673",
  turquoise600: "#008584",
  turquoise500: "#009292",
  turquoise400: "#00A3A5",
  turquoise300: "#00B3B6",
  turquoise200: "#6CC9CC",
  turquoise100: "#A9DEE0",
  turquoise50: "#DDF1F3",
  //Orange
  orange900: "#DB5719",
  orange800: "#E56F1C",
  orange700: "#EC7E1F",
  orange600: "#F38D21",
  orange500: "#F79824",
  orange400: "#F8A637",
  orange300: "#FAB657",
  orange200: "#FBCB86",
  orange100: "#FCDFB5",
  orange50: "#FEF3E1",
  //Yellow
  yellow900: "#F77421",
  yellow800: "#F99228",
  yellow700: "#FAA22A",
  yellow600: "#FBB42E",
  yellow500: "#FCC132",
  yellow400: "#FDCA40",
  yellow300: "#FED55D",
  yellow200: "#FEE08A",
  yellow100: "#FEECB7",
  yellow50: "#FFFAEB",
  //Red
  red900: "#BC0007",
  red800: "#CA0617",
  red700: "#D7151F",
  red600: "#E82425",
  red500: "#F73124",
  red400: "#F34643",
  red300: "#E96B69",
  red200: "#F39593",
  red100: "#FFCACF",
  red50: "#FFF0F2",
  //Green
  green900: "#33905A",
  green800: "#42B271",
  green700: "#4AC47E",
  green600: "#54D98C",
  green500: "#5DEA99",
  green400: "#78EFAA",
  green300: "#94F4BC",
  green200: "#B5F7D0",
  green100: "#E3FCED",
  green50: "#ECFDF3",
  //Purple
  purple900: "#6D00D4",
  purple800: "#9B00DC",
  purple700: "#B300E1",
  purple600: "#CC00E5",
  purple500: "#DF00E8",
  purple400: "#EE00F5",
  purple300: "#F324F7",
  purple200: "#F87DF9",
  purple100: "#FBB5FA",
  purple50: "#FFEBFE",
  // Additional colours
  white: "#FFFFFF",
};

export type ColorsPallet = typeof colors;

const Palette: PaletteOptions = {
  primary: {
    main: "#153743",
    light: "#6D6D6D",
    dark: "#0a222a",
  },
  secondary: {
    main: "#C9C9C9",
    light: "#00B3B6",
    dark: "#818181",
  },
  text: {
    primary: "#2B2B2B",
    secondary: "#4D4D4D",
  },
  success: {
    main: "#42B271",
    light: "#0ec48c",
    dark: "#0d8253",
  },
  warning: {
    main: "#F77421",
    light: "#ffbc00",
    dark: "#f6993f",
  },
  error: {
    main: "#E82425",
    light: "#D7151F",
  },
  info: {
    main: "#2176FF",
    light: "#0099FF",
  },
  common: colors,
};
export default Palette;

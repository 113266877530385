import { styled, TableRow, Typography } from '@mui/material';
import theme from 'application/theme';

export const TableRowStyled = styled(TableRow)`
  height: ${theme.spacing(7.5)};
`

export const TableTextStyled = styled(Typography)`
  font-size: ${theme.spacing(1.75)};
  line-height: 140%;
  color: ${theme.palette.common.grey800};
`

export const TableTextSecondaryStyled = styled(TableTextStyled)`
  color: ${theme.palette.common.grey600};
`
import theme from "application/theme";

export const buttonHelper = (
  type: "button" | "submit" | "reset" | "blue-button" | undefined
) => {
  switch (type) {
    case "button":
      return {
        background: theme.palette.common.white,
        color: theme.palette.common.grey700,
        hoverBg: theme.palette.common.grey100,
        border: `1px solid ${theme.palette.common.grey400}`,
        disabledBorderColor: theme.palette.common.grey300,
      };
    case "submit":
      return {
        background: theme.palette.common.blue700,
        color: theme.palette.common.white,
        hoverBg: theme.palette.common.blue500,
        border: "none",
        disabledBorderColor: "none",
      };
    case "reset":
      return {
        background: theme.palette.common.white,
        color: theme.palette.common.blue700,
        hoverBg: theme.palette.common.blue50,
        border: `1px solid ${theme.palette.common.blue700}`,
        disabledBorderColor: theme.palette.common.blue200,
      };
    default:
      return {
        background: theme.palette.common.blue700,
        color: theme.palette.common.white,
        hoverBg: theme.palette.common.blue500,
        disabledBorderColor: "none",
        border: "none",
      };
  }
};

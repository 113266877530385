import React, { lazy, Suspense } from "react";
import { Loader } from "common/loader/Loader";
import { MainLayout } from "common/layout/mainLayout/MainLayout";
import { PathNames } from "./constants";
import { Navigate } from "react-router-dom";
import BulkExportPage from "application/pages/BulkExportPage";

import {
  CompaniesPage,
  NotFoundPage,
  NotificationsPage,
  TeamManagementPage,
  SurveyPage,
  SdrProfilePage, PermitsPage,
} from "application";
import { CompaniesPageContextProvider } from "common/companies/contexts/CompaniesPageContext";

const AuthPage = lazy(() => import("../pages/AuthPage"));

export const authRouts = [
  {
    path: "/",
    title: "Companies List",
    component: (
      <Suspense fallback={<Loader />}>
        <Navigate to={PathNames.companies} replace />
      </Suspense>
    ),
  },
  {
    path: PathNames.login,
    title: "Companies List",
    component: (
      <Suspense fallback={<Loader />}>
        <Navigate to={PathNames.companies} replace />
      </Suspense>
    ),
  },
  {
    path: PathNames.registration,
    title: "Companies List",
    component: (
      <Suspense fallback={<Loader />}>
        <Navigate to={PathNames.companies} replace />
      </Suspense>
    ),
  },
  {
    path: PathNames.forgotPassword,
    title: "Companies List",
    component: (
      <Suspense fallback={<Loader />}>
        <Navigate to={PathNames.companies} replace />
      </Suspense>
    ),
  },
  {
    path: PathNames.createPassword,
    title: "Companies List",
    component: (
      <Suspense fallback={<Loader />}>
        <Navigate to={PathNames.companies} replace />
      </Suspense>
    ),
  },
  {
    path: PathNames.confirmChange,
    title: "Companies List",
    component: (
      <Suspense fallback={<Loader />}>
        <Navigate to={PathNames.companies} replace />
      </Suspense>
    ),
  },
  {
    path: PathNames.survey,
    title: "Companies List",
    component: (
      <Suspense fallback={<Loader />}>
        <Navigate to={PathNames.companies} replace />
      </Suspense>
    ),
  },
  {
    path: PathNames.companies,
    title: "Companies List",
    component: (
      <Suspense fallback={<Loader />}>
        <MainLayout>
          <CompaniesPageContextProvider>
            <CompaniesPage />
          </CompaniesPageContextProvider>
        </MainLayout>
      </Suspense>
    ),
  },
  {
    path: `${PathNames.permits}/:view`,
    title: "Permits",
    component: (
      <Suspense fallback={<Loader />}>
        <MainLayout>
          <PermitsPage />
        </MainLayout>
      </Suspense>
    ),
  },
  {
    path: `${PathNames.permits}/:view/:mode`,
    title: "Permits",
    component: (
      <Suspense fallback={<Loader />}>
        <MainLayout>
          <PermitsPage />
        </MainLayout>
      </Suspense>
    ),
  },
  {
    path: `${PathNames.bulkExport}/:scope/:name`,
    title: "Bulk Export",
    component: (
      <Suspense fallback={<Loader />}>
        <MainLayout>
          <BulkExportPage />
        </MainLayout>
      </Suspense>
    ),
  },
  {
    path: PathNames.notifications,
    title: "Notifications",
    component: (
      <Suspense fallback={<Loader />}>
        <MainLayout>
          <NotificationsPage />
        </MainLayout>
      </Suspense>
    ),
  },
  {
    path: `${PathNames.teamManagement}/:name`,
    title: "Team Management",
    component: <TeamManagementPage />,
  },
  {
    path: PathNames.sdrProfile,
    title: "Profile",
    component: (
      <Suspense fallback={<Loader />}>
        <MainLayout>
          <SdrProfilePage />
        </MainLayout>
      </Suspense>
    ),
  },
  {
    path: "/*",
    title: "Error",
    component: <NotFoundPage />,
  },
];

export const publicRouts = [
  {
    path: PathNames.login,
    title: "Login",
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.registration,
    title: "Registration",
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.forgotPassword,
    title: "Forgot Password",
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.createPassword,
    title: "Create New Password",
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.confirmChange,
    title: "Confirm Password",
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.survey,
    title: "Survey",
    component: (
      <Suspense fallback={<Loader />}>
        <SurveyPage />
      </Suspense>
    ),
  },
  {
    path: "/*",
    title: "Login",
    component: (
      <Suspense fallback={<Loader />}>
        <Navigate to="/login" replace />
      </Suspense>
    ),
  },
];

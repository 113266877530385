import React, { useMemo } from 'react';
import { ModalComponent } from 'application/components/ModalComponent';
import theme from 'application/theme';
import { Box } from '@mui/system';
import { Button } from 'application';
import { Typography } from '@mui/material';
import { CsvIcon, HubspotIcon } from 'application/assets';
import { useLargeBulkExport } from 'common/largeBulkExport/hooks/useLargeBulkExport';
import {
  ActionsWrapper,
  ExportResultsStyled,
  IncludeExportedCompaniesInfoStyled,
  InfoPanelWrapper,
  ModalWrapper
} from './styles';
import { AppliedFiltersList, SkipExportedToggle } from './components';
import { ExportTo } from 'common/companies/types';
import { useHubspotIntegrationModal } from 'common/organization/hooks/useHubspotIntegrationModal';
import { ConnectToHubspotModal } from 'common/organization/modals/ConnectToHubspotModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ConfirmExportModal = ({isOpen, onClose}: Props) => {
  const {
    countOfCompaniesForExport,
    companiesFound,
    alreadyExportedCompaniesAmount,
    skipExportedAndUpdateFilters,
    exportCompanies
  } = useLargeBulkExport();

  const { isHubspotIntegrated,
    hubspotAuthUrl,
    hubspotModalIsOpened,
    openHubspotIntegrationModal,
    closeHubspotIntegrationModal} = useHubspotIntegrationModal();

  const excludeExportedMessage = useMemo(() => {
    return companiesFound > countOfCompaniesForExport ?
      'REPLACE EXPORTED COMPANIES WITH THE NEXT ONES IN THE LIST' :
      'EXCLUDE EXPORTED COMPANIES';
  }, [companiesFound, countOfCompaniesForExport]);

  const creditsToBeCharged = useMemo(() => countOfCompaniesForExport - alreadyExportedCompaniesAmount
    , [countOfCompaniesForExport, alreadyExportedCompaniesAmount]);

  const handleExportToCsv = () => {
    exportCompanies(ExportTo.CSV)
  }
  const handleExportToHubspot = () => {
    isHubspotIntegrated ? exportCompanies(ExportTo.HUBSPOT) :
      openHubspotIntegrationModal();
  }

  return (
    <>
      <ModalComponent
        isOpen={isOpen}
        padding={3}
        width={theme.spacing(78)}
        handleClose={onClose}
        closeOnClickOutside={false}
      >
        <ModalWrapper>
          <InfoPanelWrapper>
            <Box>
              <ExportResultsStyled>
                Export results: <span className="export-count">{countOfCompaniesForExport}</span>
              </ExportResultsStyled>
              <Typography variant="overline" color={theme.palette.common.grey900}>
                Check the list of filters below to confirm.
              </Typography>
            </Box>
            {
              alreadyExportedCompaniesAmount > 0 &&
              <Box display="flex" flexDirection="column" gap={1}>
                <IncludeExportedCompaniesInfoStyled>
                  The list includes <span
                  className="export-count">{alreadyExportedCompaniesAmount} already exported</span> companies.
                </IncludeExportedCompaniesInfoStyled>
                <SkipExportedToggle
                  message={excludeExportedMessage}
                  onClick={skipExportedAndUpdateFilters}/>
              </Box>
            }
            <AppliedFiltersList/>
          </InfoPanelWrapper>
          <ActionsWrapper>
            <Typography variant="button" color={theme.palette.common.grey900}>
              Export company list to
            </Typography>
            <Box display="flex" width="100%" gap={2}>
              <Button
                fullWidth
                startAdornment={<CsvIcon/>}
                label="CSV"
                onClick={handleExportToCsv}
                height={5.5}
                type="button"
              />
              <Button
                fullWidth
                startAdornment={<HubspotIcon/>}
                label="Hubspot"
                onClick={handleExportToHubspot}
                height={5.5}
                type="button"
              />
            </Box>
            <Box display="flex" gap={1}>
              <Typography variant="overline" color={theme.palette.common.grey700}>
                Total company credits charged:
              </Typography>
              <Typography variant="button" color={theme.palette.common.orange500}>
                {creditsToBeCharged}
              </Typography>
            </Box>
          </ActionsWrapper>
        </ModalWrapper>
      </ModalComponent>
      <ConnectToHubspotModal
        isOpen={hubspotModalIsOpened}
        handleClose={closeHubspotIntegrationModal}
        onConfirmUrl={hubspotAuthUrl}
      />
    </>
  )
}

import React from "react";
import theme from 'application/theme';
import { IconProps } from './types';

export const ClockIcon: React.FC<IconProps> = ({color = theme.palette.common.grey900}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <mask id="mask0_4781_12709" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="14">
        <rect width="14" height="14" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_4781_12709)">
        <path
          d="M8.92533 9.7415L9.74199 8.92484L7.58366 6.7665V4.08317H6.41699V7.23317L8.92533 9.7415ZM7.00033 12.8332C6.19338 12.8332 5.43505 12.68 4.72533 12.3738C4.0156 12.0675 3.39824 11.6519 2.87324 11.1269C2.34824 10.6019 1.93262 9.98456 1.62637 9.27484C1.32012 8.56511 1.16699 7.80678 1.16699 6.99984C1.16699 6.19289 1.32012 5.43456 1.62637 4.72484C1.93262 4.01511 2.34824 3.39775 2.87324 2.87275C3.39824 2.34775 4.0156 1.93213 4.72533 1.62588C5.43505 1.31963 6.19338 1.1665 7.00033 1.1665C7.80727 1.1665 8.5656 1.31963 9.27533 1.62588C9.98505 1.93213 10.6024 2.34775 11.1274 2.87275C11.6524 3.39775 12.068 4.01511 12.3743 4.72484C12.6805 5.43456 12.8337 6.19289 12.8337 6.99984C12.8337 7.80678 12.6805 8.56511 12.3743 9.27484C12.068 9.98456 11.6524 10.6019 11.1274 11.1269C10.6024 11.6519 9.98505 12.0675 9.27533 12.3738C8.5656 12.68 7.80727 12.8332 7.00033 12.8332ZM7.00033 11.6665C8.29338 11.6665 9.39442 11.212 10.3035 10.303C11.2125 9.39393 11.667 8.29289 11.667 6.99984C11.667 5.70678 11.2125 4.60574 10.3035 3.69671C9.39442 2.78768 8.29338 2.33317 7.00033 2.33317C5.70727 2.33317 4.60623 2.78768 3.6972 3.69671C2.78817 4.60574 2.33366 5.70678 2.33366 6.99984C2.33366 8.29289 2.78817 9.39393 3.6972 10.303C4.60623 11.212 5.70727 11.6665 7.00033 11.6665Z"
          fill={color}/>
      </g>
    </svg>
  );
};


import React, { useMemo } from 'react';
import { ModalComponent } from 'application/components/ModalComponent';
import theme from 'application/theme';
import { Box } from '@mui/system';
import { Button } from 'application';
import { Typography } from '@mui/material';
import { CsvIcon, HubspotIcon } from 'application/assets';
import {
  ActionsWrapper,
  ExportResultsStyled,
  IncludeExportedCompaniesInfoStyled,
  InfoPanelWrapper,
  ModalWrapper
} from './styles';
import { AppliedFiltersList, SkipExportedToggle } from './components';
import { ExportTo } from 'common/companies/types';
import { useHubspotIntegrationModal } from 'common/organization/hooks/useHubspotIntegrationModal';
import { ConnectToHubspotModal } from 'common/organization/modals/ConnectToHubspotModal';
import { useLargeBulkExportNew } from 'common/permits/components/largeBulkExport/hooks/useLargeBulkExportNew';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ConfirmExportModal = ({isOpen, onClose}: Props) => {
  const {
    countOfCompaniesForExport,
    countOfPermitsNoMapForExport,
    countOfCompaniesOnMapForExport,
    countOfPermitsForExport,
    companiesFound,
    alreadyExportedCompaniesAmount,
    alreadyExportedPermitsAmount,
    skipExportedAndUpdateFilters,
    exportCompanies,
    exportCompaniesOnMap,
    isPermitExport,
    isCompanyExport,
    isPermitsByCoordinatesExport,
    isCompaniesByCoordinatesExport,
    exportPermits,
    exportPermitsOnMap,
    skipExportedCompaniesOnMapAndUpdateFilters
  } = useLargeBulkExportNew();

  const {
    isHubspotIntegrated,
    hubspotAuthUrl,
    hubspotModalIsOpened,
    openHubspotIntegrationModal,
    closeHubspotIntegrationModal
  } = useHubspotIntegrationModal();

  const excludeExportedMessage = useMemo(() => {
    const currentFoundCompanies = isCompanyExport ? companiesFound : countOfCompaniesOnMapForExport;
    return currentFoundCompanies > countOfCompaniesForExport ?
      'REPLACE EXPORTED COMPANIES WITH THE NEXT ONES IN THE LIST' :
      'EXCLUDE EXPORTED COMPANIES';
  }, [companiesFound, countOfCompaniesOnMapForExport, countOfCompaniesForExport]);

  const creditsToBeCharged = useMemo(() => {
    switch (true) {
      case isPermitExport:
        return countOfPermitsForExport - alreadyExportedPermitsAmount;
      case isPermitsByCoordinatesExport:
        return countOfPermitsNoMapForExport - alreadyExportedPermitsAmount;
      case isCompanyExport:
        return countOfCompaniesForExport - alreadyExportedCompaniesAmount;
      case isCompaniesByCoordinatesExport:
        return countOfCompaniesOnMapForExport - alreadyExportedCompaniesAmount;
      default:
        return 0;
    }
  }, [countOfCompaniesForExport, countOfCompaniesOnMapForExport, countOfPermitsNoMapForExport, countOfPermitsForExport,
    alreadyExportedCompaniesAmount, alreadyExportedPermitsAmount,
    isPermitExport, isCompanyExport, isPermitsByCoordinatesExport, isCompaniesByCoordinatesExport]);

  const countForExport = useMemo(() => {
    switch (true) {
      case isPermitExport:
        return countOfPermitsForExport;
      case isPermitsByCoordinatesExport:
        return countOfPermitsNoMapForExport;
      case isCompanyExport:
        return countOfCompaniesForExport;
      case isCompaniesByCoordinatesExport:
        return countOfCompaniesOnMapForExport;
      default:
        return 0;
    }
  }, [isPermitExport, isCompanyExport,]);
  const getCountForExport = () => {
    switch (true) {
      case isPermitExport:
        return countOfPermitsForExport;
      case isPermitsByCoordinatesExport:
        return countOfPermitsNoMapForExport;
      case isCompanyExport:
        return countOfCompaniesForExport;
      case isCompaniesByCoordinatesExport:
        return countOfCompaniesOnMapForExport;
      default:
        return 0;
    }
  }


  const handleExportToCsv = () => {
    if (isPermitExport) {
      exportPermits();
    } else if (isPermitsByCoordinatesExport) {
      exportPermitsOnMap();
    } else if (isCompanyExport) {
      exportCompanies(ExportTo.CSV);
    } else if (isCompaniesByCoordinatesExport) {
      exportCompaniesOnMap(ExportTo.CSV);
    }
  }
  const handleExportToHubspot = () => {
    if (isPermitExport && isPermitsByCoordinatesExport) {
      console.error('Permits export to Hubspot is not implemented yet');
      return;
    }
    if (!isHubspotIntegrated) {
      openHubspotIntegrationModal();
      return;
    }
    if (isCompanyExport) {
      exportCompanies(ExportTo.HUBSPOT);
    } else if (isCompaniesByCoordinatesExport) {
      exportCompaniesOnMap(ExportTo.HUBSPOT);
    }
  }
  const handleSkipExportedCompanies = () => {
    if (isCompanyExport) {
      skipExportedAndUpdateFilters();
    } else if (isCompaniesByCoordinatesExport) {
      skipExportedCompaniesOnMapAndUpdateFilters();
    }
  }


  return (
    <>
      <ModalComponent
        isOpen={isOpen}
        padding={3}
        width={theme.spacing(78)}
        handleClose={onClose}
        closeOnClickOutside={false}
      >
        <ModalWrapper>
          <InfoPanelWrapper>
            <Box>
              <ExportResultsStyled>
                Export results: <span className="export-count">{getCountForExport()}</span>
              </ExportResultsStyled>
              <Typography variant="overline" color={theme.palette.common.grey900}>
                Check the list of filters below to confirm.
              </Typography>
            </Box>
            {
              //todo: implement the same for the permits when filter skip exported permits will be implemented.
              (isCompanyExport || isCompaniesByCoordinatesExport) && alreadyExportedCompaniesAmount > 0 &&
              <Box display="flex" flexDirection="column" gap={1}>
                <IncludeExportedCompaniesInfoStyled>
                  The list includes <span
                  className="export-count">{alreadyExportedCompaniesAmount} already exported</span> companies.
                </IncludeExportedCompaniesInfoStyled>
                <SkipExportedToggle
                  message={excludeExportedMessage}
                  onClick={handleSkipExportedCompanies}/>
              </Box>
            }
            <AppliedFiltersList/>
          </InfoPanelWrapper>
          <ActionsWrapper>
            <Typography variant="button" color={theme.palette.common.grey900}>
              {`Export ${(isPermitExport || isPermitsByCoordinatesExport) ? 'permits' : 'companies'} list to`}
            </Typography>
            <Box display="flex" width="100%" gap={2}>
              <Button
                fullWidth
                startAdornment={<CsvIcon/>}
                label="CSV"
                onClick={handleExportToCsv}
                height={5.5}
                type="button"
              />
              {
                (isCompanyExport || isCompaniesByCoordinatesExport) &&
                <Button
                  fullWidth
                  startAdornment={<HubspotIcon/>}
                  label="Hubspot"
                  onClick={handleExportToHubspot}
                  height={5.5}
                  type="button"
                />
              }
            </Box>
            <Box display="flex" gap={1}>
              <Typography variant="overline" color={theme.palette.common.grey700}>
                Total company credits charged:
              </Typography>
              <Typography variant="button" color={theme.palette.common.orange500}>
                {creditsToBeCharged}
              </Typography>
            </Box>
          </ActionsWrapper>
        </ModalWrapper>
      </ModalComponent>
      <ConnectToHubspotModal
        isOpen={hubspotModalIsOpened}
        handleClose={closeHubspotIntegrationModal}
        onConfirmUrl={hubspotAuthUrl}
      />
    </>
  )
}

import React, { useEffect, useState } from "react";
import { StyledSsrProfileDashboard } from "./styles";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { Button } from "application";
import theme from "application/theme";
import { EditIcon } from "application/assets/EditIcon";
import { ProfileDetails } from "./ProfileDetails";
import { CreditInfoBoard } from "./CreditInfoBoard";
import { OrganizationTeamTable } from "./OrganizationTeamTable";
import { EditProfileModal } from "../editProfileModal/EditProfileModal";
import { ChangePasswordModal } from "../changePasswordModal/ChangePasswordModal";
import { EditSdrProfileI } from "../store/types";
import { useAppDispatch, useAppSelector } from "application/store";
import { handleGetSdrProfile, handleUpdateSdrProfile } from "../store/actions";
import { sdrProfileSelector } from "../store";

const ProfileDashboard = () => {
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const dispatch = useAppDispatch();
  const { sdrProfile } = useAppSelector(sdrProfileSelector);

  useEffect(() => {
    dispatch(handleGetSdrProfile());
  }, []);

  const handleUpdateProfile = (newProfile: EditSdrProfileI) => {
    dispatch(
      handleUpdateSdrProfile({
        firstName: newProfile.firstName,
        lastName: newProfile.lastName,
        emailNotificationEnabled: newProfile.emailNotificationEnabled,
      })
    );
    setUpdateModalOpen(false);
  };

  const handleOpenChangePasswordModal = () => {
    setUpdateModalOpen(false);
    setChangePasswordModal(true);
  };

  return (
    <>
      {sdrProfile.organizationId && (
        <EditProfileModal
          isOpen={updateModalOpen}
          profile={{
            firstName: sdrProfile.firstName || "",
            lastName: sdrProfile.lastName || "",
            emailNotificationEnabled: sdrProfile.emailNotificationEnabled,
          }}
          onClose={() => setUpdateModalOpen(false)}
          onSubmit={handleUpdateProfile}
          onClickChangePassword={handleOpenChangePasswordModal}
        />
      )}
      <ChangePasswordModal
        isOpen={changePasswordModal}
        onClose={() => setChangePasswordModal(false)}
      />
      <StyledSsrProfileDashboard>
        <Box>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography
              variant="h4"
              color={theme.palette.common.grey900}
              noWrap
              mb={2.5}
            >
              My Profile
            </Typography>
            <Button
              label="Edit"
              onClick={() => setUpdateModalOpen(true)}
              startAdornment={<EditIcon color={theme.palette.common.grey900} />}
              height={5.5}
              type="button"
            />
          </Box>
          <Typography
            variant="overline"
            color={theme.palette.common.grey700}
            noWrap
          >
            Organization name
          </Typography>
          <Box mt={1.5}>
            <CreditInfoBoard {...sdrProfile} />
          </Box>
        </Box>
        <Box>
          <ProfileDetails
            fullName={
              (sdrProfile.firstName || "-") + " " + (sdrProfile.lastName || "")
            }
            email={sdrProfile.email}
            notificationEnabled={sdrProfile.emailNotificationEnabled}
          />
        </Box>
        <Box>
          <OrganizationTeamTable teamMembers={sdrProfile.team} />
        </Box>
      </StyledSsrProfileDashboard>
    </>
  );
};

export default ProfileDashboard;

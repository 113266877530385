import React from "react";
import { Box, Typography } from "@mui/material";
import theme from "application/theme";
import { ModalComponent } from "application/components/ModalComponent";
import { Button } from "application";
import { DeleteUserIcon } from "application/assets/DeleteUserIcon";
import { useAppDispatch, useAppSelector } from "application/store";
import { handleDeleteSdr } from "../store/actions";
import { sdrProfileSelector } from "common/sdrProfile/store";

export const UserDeleteModal = ({
  isOpen,
  handleClose,
  sdrId,
}: {
  isOpen: boolean;
  handleClose: () => void;
  sdrId: number;
}) => {
  const dispatch = useAppDispatch();
  const { sdrProfile } = useAppSelector(sdrProfileSelector);

  const onSubmit = () => {
    handleClose();
    dispatch(handleDeleteSdr(sdrId, sdrProfile.organizationId));
  };
  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleClose}
      padding={3}
      width={475}
    >
      <Box textAlign="center">
        <Box display="flex" justifyContent="center" mb={4}>
          <DeleteUserIcon />
        </Box>
        <Typography variant="h5" color={theme.palette.common.grey800} mb={1.5}>
          Are you Sure to Remove This
          <br />
          Team Member from a Team?
        </Typography>

        <Typography
          variant="caption"
          color={theme.palette.common.grey700}
          display="block"
        >
          Credits will become available in overall credits balance of
          organization
        </Typography>

        <Box
          display="flex"
          justifyContent="center"
          gap={theme.spacing(2)}
          mt={4}
        >
          <Button
            label="No"
            height={5.5}
            width={120}
            type="button"
            onClick={handleClose}
          />
          <Button
            label="Remove"
            width={120}
            type="submit"
            red
            onClick={onSubmit}
          />
        </Box>
      </Box>
    </ModalComponent>
  );
};

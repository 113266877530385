import React from "react";
import { Typography } from "@mui/material";
import {
    KeyboardArrowDownOutlined,
} from "@mui/icons-material";
import theme from "application/theme";
import {ShowLessAndMoreWrapper} from "../../components/styles";

interface Props {
    loadMoreText?: string;
    onLoadMore: () => void;
    fontSize?: string;
}

export const LoadMoreButton: React.FC<Props> = ({
                                             loadMoreText = "Show more", onLoadMore,
                                             fontSize = theme.spacing(2),
                                         }) => {
    return (
        <ShowLessAndMoreWrapper onClick={onLoadMore}>
            <Typography
                fontSize={fontSize}
                fontWeight={400}
                color={theme.palette.common.blue700}
            >
                {loadMoreText}
            </Typography>
            <KeyboardArrowDownOutlined
                sx={{
                    color: theme.palette.common.blue700,
                }}
            />
        </ShowLessAndMoreWrapper>
    );
};

import React from "react";
import { Typography } from "@mui/material";
import { NavBarStyled } from "./styles";
import { ExportScopeToggler } from "../ExportScopeToggler";

export const BulkExportHeader = () => {
  return (
    <NavBarStyled display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
      <Typography variant="h4" color="inherit">
        Bulk export
      </Typography>
      <ExportScopeToggler/>
    </NavBarStyled>
  );
};
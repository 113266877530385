import React, { FC, useLayoutEffect, useRef } from "react";
import { BrowserRouter, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { errorsSelector, Notify, Loader, LoaderModal, InAppNotification } from "common";
import { PathNames } from "./constants";
import { authRouts, publicRouts } from "./routes";
import { useAppDispatch, useAppSelector } from "application/store";
import { authSelector } from "common/auth/store/authStore";
import { handleIsAuth } from "common/auth/store/actions";
import { responseSelector } from "common/responseService";
import { isLoaderSelector } from "common/loaderModal/loaderSlice";
import RoutesWrapper from './RoutesWrapper';

export const AppRouter: FC = () => {
  const error = useSelector(errorsSelector);
  const response = useSelector(responseSelector);
  const loader = useSelector(isLoaderSelector);
  const dispatch = useAppDispatch();
  const { isAuth, isLoading } = useAppSelector(authSelector);
  const initialized = useRef(false);

  useLayoutEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      dispatch(handleIsAuth())
    }
  }, [dispatch]);

  if (isLoading) {
    return <Loader isLoading={isLoading} />;
  }

  return (
    <BrowserRouter>
      <RoutesWrapper>
        {isAuth
          ? authRouts.map(({ path, component }) => (
              <Route path={path} element={component} key={path} />
            ))
          : publicRouts.map(({ path, component }) => (
              <Route path={path} element={component} key={path} />
            ))}
        <Route path="/*" element={<Navigate to={PathNames.error} />} />
      </RoutesWrapper>
      <Loader />
      <LoaderModal isOpen={loader.isLoading} />
      <Notify message={error?.message || ""} type="error" />
      <Notify message={response?.message || ""} type={"success"} />
      <InAppNotification type={"success"} />
    </BrowserRouter>
  );
};

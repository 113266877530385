import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import theme from 'application/theme';
import { ModalComponent } from 'application/components/ModalComponent';
import { ProcessingCircleIcon } from 'application/assets';
import { PathNames } from 'application/routes';
import { ModalContentWrapper } from './styles';
import { Button } from 'application';
import { ExportStage } from 'common/largeBulkExport/store/types';
import { ExportScopePath, ExportTab } from 'common/bulkExport/types';
import { useLargeBulkExportNew } from '../../hooks/useLargeBulkExportNew';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ExportProcessingModal = ({isOpen, onClose}: Props) => {
  const navigate = useNavigate();
  const {updateExportStage, isPermitExport, isPermitsByCoordinatesExport} = useLargeBulkExportNew();
  const handleBackToSearch = () => {
    updateExportStage(ExportStage.BACK_TO_SEARCH)
  }

  const handleCheckExportStatus = () => {
    updateExportStage(null);
    const path = (isPermitExport || isPermitsByCoordinatesExport) ? `${PathNames.bulkExport}/${ExportScopePath.PERMITS}`
      : PathNames.bulkExportCompany;
    navigate(`${path}/${ExportTab.BULK_HISTORY}`)
  }
  return (
    <ModalComponent
      isOpen={isOpen}
      padding={4}
      width={theme.spacing(60)}
      handleClose={onClose}
      closeOnClickOutside={false}
    >
      <ModalContentWrapper>
        <Box>
          <ProcessingCircleIcon/>
        </Box>
        <Typography variant="subtitle1" color={theme.palette.common.grey800}>
          It could spend a some time. Please, wait.
        </Typography>
        <Typography variant="caption" textAlign="center">
          You can check status on Bulk Export History tab. <br/>
          Or back to search and export next part of contacts.
        </Typography>
        <Box display={'flex'} width={'100%'}>
          <Box display="flex" width="100%" gap={2}>
            <Button
              fullWidth
              label="Back to search"
              onClick={handleBackToSearch}
              height={5.5}
              type="button"
            />
            <Button
              fullWidth
              label="Check export status"
              onClick={handleCheckExportStatus}
              height={5.5}
              type="submit"
            />
          </Box>
        </Box>
      </ModalContentWrapper>
    </ModalComponent>
  )
}
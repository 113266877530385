import React, { useEffect, useRef, useState } from "react";
import {
  MapRef,
  Map,
  Popup,
  Source,
  Layer,
  FullscreenControl,
  NavigationControl,
  ScaleControl,
} from "react-map-gl";
import { MAPBOX_ACCESS_TOKEN } from "config";
import { LoaderWrapper, MapViewWrapper } from "./styles";
import MapTooltip from "./MapTooltip";
import type {
  GeoJSONSource,
  MapLayerMouseEvent,
  MapboxGeoJSONFeature,
  ViewStateChangeEvent,
} from "react-map-gl";
import {
  companyLayer,
  companyCountLayer,
  singleCompanyLayer,
  permitLayer,
  permitCountLayer,
  singlePermitLayer,
  countiesLayer,
} from "./layers";
import {
  permitsSelector, setCompaniesInfo,
  setMapInitialView,
  setSearchChanged,
  setShowOnMap,
  setShowTooltip,
  setZoomedArea,
} from "common/permits/store";
import { useAppDispatch, useAppSelector } from "application/store";
import {
  handleGetCompaniesInfoById,
  handleGetPermitsInfoById,
} from "common/permits/store/actions";
import PermitIcon from "application/assets/PermitMapIcon.svg";
import CompanyImage from "application/assets/CompanyMapIcon.svg";
import { debounce } from "lodash";
import { SmallLoader } from "common/loader/SmallLoader";
import mapboxgl from "mapbox-gl";
import { usePermitsViewSettings } from "common/permits/hooks/usePermitsViewSettings";
import { FeaturesArray } from "common/permits/types";
import { MapViewSource } from './types';
import { MaxItemsCountTooltip } from './MaxItemsCountTooltip';

export const MapView = () => {
  const {
    permitMapView,
    companyMapView,
    mapInitialView,
    mapIsLoading,
    showTooltip,
    searchChanged,
    showOnMap,
  } = useAppSelector(permitsSelector);

  const { displayCompaniesOnMap, displayPermitsOnMap } =
    usePermitsViewSettings();

  const mapRef = useRef<MapRef>(null);
  const elementRef = useRef(null);
  const [popupInfo, setPopupInfo] = useState<{
    latitude: number;
    longitude: number;
  } | null>(null);
  const offsetPopup: [number, number] = [0, -20];

  const permitImage = new Image(124, 124);
  permitImage.src = PermitIcon;
  const companyImage = new Image(124, 124);
  companyImage.src = CompanyImage;

  useEffect(() => {
    const havePermitIcon = mapRef.current?.hasImage("permit-icon");
    const haveCompanyIcon = mapRef.current?.hasImage("company-icon");
    if (!havePermitIcon) mapRef.current?.addImage("permit-icon", permitImage);
    if (!haveCompanyIcon)
      mapRef.current?.addImage("company-icon", companyImage);
  }, [mapRef.current]);

  const getIdsFromFeatures = (features: MapboxGeoJSONFeature[], source: MapViewSource) => {
    const allIds = features
      .filter((feature) => feature.source === source)
      .map((item) => item.properties?.id);
    return [...new Set(allIds)];
  }
  const singleViewOnMapClick = ({
    features,
    latitude,
    longitude,
  }: {
    features: MapboxGeoJSONFeature[];
    latitude: number;
    longitude: number;
  }) => {
    const allDots = features;
    const companyIds = getIdsFromFeatures(allDots, MapViewSource.COMPANY);
    const permitIds = getIdsFromFeatures(allDots, MapViewSource.PERMIT);
    if(companyIds.length > 0) {
      dispatch(handleGetCompaniesInfoById(companyIds.toString()));
    } else {
      dispatch(setCompaniesInfo([]));
    }
    if(permitIds.length > 0) {
      dispatch(handleGetPermitsInfoById(permitIds.toString()));
    } else {
      dispatch(setCompaniesInfo([]));
    }
    setPopupInfo({ latitude: latitude, longitude: longitude });
    if (mapRef.current) {
      mapRef.current?.easeTo({
        center: {
          lat: latitude,
          lng: longitude,
        },
      });
    }
  };

  const mapOnLoad = () => {
    mapRef.current?.easeTo({
      center: {
        lat: mapInitialView.latitude,
        lng: mapInitialView.longitude,
      },
    });
  };

  const showAreaForDots = () => {
    const permitMapViewCoordinates = permitMapView.content.map(
      (e) => e.geometry.coordinates
    );
    const companyMapViewCoordinates = companyMapView.content.map(
      (e) => e.geometry.coordinates
    );
    const coordinates = permitMapViewCoordinates.concat(
      companyMapViewCoordinates
    );
    const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);
    for (const coord of coordinates) {
      bounds.extend(coord);
    }

    mapRef.current?.fitBounds(bounds, {
      padding: 60,
    });
  };

  useEffect(() => {
    if (
      mapRef.current &&
      !mapIsLoading &&
      searchChanged &&
      companyMapView.content.length &&
      permitMapView.content.length
    ) {
      if (showOnMap) {
        dispatch(setSearchChanged(false));
        dispatch(setShowOnMap(false));
      }
      showAreaForDots();
    }
  }, [permitMapView, companyMapView, mapRef.current, searchChanged]);

  const getVisibleLayers = () => {
    let visibleLayers = [];
    if(displayPermitsOnMap) {
      visibleLayers.push(permitLayer.id as string);
      visibleLayers.push(permitCountLayer.id as string);
      visibleLayers.push(singlePermitLayer.id as string);
    }
    if(displayCompaniesOnMap) {
      visibleLayers.push(companyLayer.id as string);
      visibleLayers.push(companyCountLayer.id as string);
      visibleLayers.push(singleCompanyLayer.id as string);
    }
    return visibleLayers;
  }

  const onClick = (event: MapLayerMouseEvent) => {
    event.originalEvent.stopPropagation();
    const companySource = displayCompaniesOnMap ? mapRef.current?.getSource(
      MapViewSource.COMPANY
    ) as GeoJSONSource : null;
    const permitSource = displayPermitsOnMap ? mapRef.current?.getSource(
      MapViewSource.PERMIT
    ) as GeoJSONSource : null;
    // @ts-ignore
    const feature = event?.features[0];
    const features = mapRef.current?.queryRenderedFeatures(event.point, {
      layers: getVisibleLayers(),
    });
    if (!features?.length) return;

    const layerId = features[0].layer.id;
    const clusterId = feature?.properties?.cluster_id;

    switch (layerId) {
      case "permits":
      case "permit-count":
        permitSource && zoomToCluster(permitSource, clusterId, feature);
        break;
      case "companies":
      case "companies-count":
        companySource && zoomToCluster(companySource, clusterId, feature);
        break;
      case "single-permit-point":
      case "single-company-point":
        singleViewOnMapClick({ features,
          latitude: event.lngLat.lat,
          longitude: event.lngLat.lng});
        break;
      default:
        // optional: handle unexpected cases
        break;
    }
  };

  const handleViewOnMap = ({ point }: { point: mapboxgl.Point }) => {
    const features = mapRef.current?.queryRenderedFeatures(point, {
      layers: [
        "permits",
        "permit-count",
        "single-permit-point",
        "single-company-point",
        "companies-count",
        "companies",
      ],
    });
    if (!features?.length) return;

    const layerId = features[0].layer.id;

    switch (layerId) {
      case "single-permit-point":
      case "single-company-point":
        singleViewOnMapClick({
          features,
          latitude: mapInitialView.latitude,
          longitude: mapInitialView.longitude,
        });
        break;
      default:
        // optional: handle unexpected cases
        break;
    }
  };

  const onMoveChange = (e: ViewStateChangeEvent) => {
    dispatch(
      setMapInitialView({
        latitude: e.viewState.latitude,
        longitude: e.viewState.longitude,
        zoom: e.viewState.zoom,
      })
    );
    const boundaries = mapRef.current?.getBounds();
    if (boundaries) {
      const minLatitude =
        boundaries._ne?.lat > boundaries?._sw?.lat
          ? boundaries._sw.lat
          : boundaries?._ne?.lat;
      const maxLatitude =
        boundaries._ne?.lat < boundaries?._sw?.lat
          ? boundaries._sw.lat
          : boundaries?._ne?.lat;
      const minLongitude =
        boundaries._ne?.lng > boundaries?._sw?.lng
          ? boundaries._sw.lng
          : boundaries._ne?.lng;
      const maxLongitude =
        boundaries._ne?.lng < boundaries?._sw?.lng
          ? boundaries._sw.lng
          : boundaries._ne?.lng;

      dispatch(
        setZoomedArea({
          minLongitude,
          minLatitude,
          maxLongitude,
          maxLatitude,
        })
      );
    }
  };

  const moveChangeWithDebounce = debounce(onMoveChange, 200);

  const dispatch = useAppDispatch();

  const zoomToCluster = (
    source: GeoJSONSource,
    clusterId: any,
    feature: any
  ) => {
    const pointsInCluster = feature.properties.point_count;
    if (pointsInCluster > 3) {
      source.getClusterLeaves(clusterId, 5000, 2, (err: any, features: any) => {
        const allDots = features.map(
          (e: FeaturesArray) => e.geometry.coordinates
        );
        const bounds = new mapboxgl.LngLatBounds(allDots[0], allDots[0]);
        for (const coord of allDots) {
          bounds.extend(coord);
        }

        mapRef.current?.fitBounds(bounds, {
          padding: 60,
        });
      });
    } else {
      source.getClusterExpansionZoom(clusterId, (err: any, zoom: any) => {
        if (err) {
          return;
        }
        mapRef.current?.easeTo({
          center: feature.geometry.coordinates,
          zoom,
          duration: 500,
        });
      });
    }
  };

  useEffect(() => {
    if (!elementRef.current) return;
    const resizer = new ResizeObserver(
      debounce(() => mapRef.current?.resize(), 0)
    );

    resizer.observe(elementRef.current);
    // TODO re-search when filters will be in store
    if (searchChanged && !showOnMap) dispatch(setSearchChanged(false));

    return () => {
      resizer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (showTooltip && !mapIsLoading && mapRef.current) {
      dispatch(setShowTooltip(false));
      const coordinate: [number, number] = [
        mapInitialView.longitude,
        mapInitialView.latitude,
      ];
      const point = mapRef.current?.project(coordinate);
      handleViewOnMap({ point });
    }
  }, [showTooltip, mapIsLoading, mapRef.current]);

  mapRef.current?.on(
    "mouseenter",
    [
      "permits",
      "permit-count",
      "single-permit-point",
      "single-company-point",
      "companies-count",
      "companies",
    ],
    () => {
      if (mapRef.current) mapRef.current.getCanvas().style.cursor = "pointer";
    }
  );
  mapRef.current?.on(
    "mouseleave",
    [
      "permits",
      "permit-count",
      "single-permit-point",
      "single-company-point",
      "companies-count",
      "companies",
    ],
    () => {
      if (mapRef.current) mapRef.current.getCanvas().style.cursor = "";
    }
  );

  return (
    <MapViewWrapper ref={elementRef} className="mapboxgl-map">
      {mapIsLoading ? (
        <LoaderWrapper>
          <SmallLoader size={160} width={100} />
        </LoaderWrapper>
      ) : (
        <Map
          initialViewState={mapInitialView}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
          onDragStart={() => {
            if (popupInfo) setPopupInfo(null);
          }}
          bearing={0}
          pitch={0}
          onClick={onClick}
          maxZoom={16}
          interactiveLayerIds={[
            permitLayer.id as string,
            companyLayer.id as string,
          ]}
          onLoad={mapOnLoad}
          ref={mapRef}
          onMove={(e) => moveChangeWithDebounce(e)}
        >
          {displayPermitsOnMap ? (
            <Source
              id={MapViewSource.PERMIT}
              type="geojson"
              data={{features: permitMapView.content, type: 'FeatureCollection'}}
              cluster={true}
              clusterMaxZoom={14}
              clusterRadius={50}
            >
              <Layer {...permitLayer} />
              <Layer {...permitCountLayer} />
              <Layer {...singlePermitLayer} />
            </Source>
          ) : (
            <></>
          )}
          {displayCompaniesOnMap ? (
            <Source
              id={MapViewSource.COMPANY}
              type="geojson"
              data={{features: companyMapView.content, type: 'FeatureCollection'}}
              cluster={true}
              clusterMaxZoom={14}
              clusterRadius={50}
            >
              <Layer {...companyLayer} />
              <Layer {...companyCountLayer} />
              <Layer {...singleCompanyLayer} />
            </Source>
          ) : (
            <></>
          )}
          <Source type="vector" url="mapbox://mapbox.82pkq93d">
            <Layer beforeId="waterway-label" {...countiesLayer} />
          </Source>
          <FullscreenControl position="top-left" />
          <NavigationControl position="top-left" />
          <ScaleControl />
          <MaxItemsCountTooltip/>
          {popupInfo && (
            <Popup
              anchor="bottom"
              longitude={Number(popupInfo.longitude)}
              latitude={Number(popupInfo.latitude)}
              onClose={() => setPopupInfo(null)}
              offset={offsetPopup}
              key={`${Number(popupInfo.longitude)}+${Number(
                popupInfo.latitude
              )}`}
            >
              <MapTooltip
                active={popupInfo ? true : false}
                onClose={() => setPopupInfo(null)}
              />
            </Popup>
          )}
        </Map>
      )}
    </MapViewWrapper>
  );
};

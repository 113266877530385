import { Box, styled } from '@mui/material';
import theme from 'application/theme';

export const LoaderWrapper = styled(Box)`
  position: absolute;
  display: flex;
  background: ${theme.palette.common.white};
  width: 100%;
  height: 100%;
  z-index: 1;
  justify-content: center;
  align-items: center;
`
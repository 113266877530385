import React from 'react';
import theme from "application/theme";
import { Typography } from "@mui/material";

interface Props {
  label: string;
  value: number;
}
const CreditsRequiredItem = ({label, value}:Props) => {
  return (
    <Typography
      variant="overline"
      color={theme.palette.common.grey900}
    >
      {label}&nbsp;
      <Typography
        variant="button"
        color={theme.palette.common.orange500}
      >
        {value}&nbsp;credits
      </Typography>
    </Typography>
  );
}

export default CreditsRequiredItem;
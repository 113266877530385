import React, { useEffect } from 'react';
import { CollapseFilter } from '../../../CollapseFilter';
import { RangeSelector } from 'common/companies/companiesFilter/components';
import { useAppSelector } from 'application/store';
import { permitsSelector } from 'common/permits/store';

const PERMIT_VALUE_FILTER_NAME = 'permitValue';
export const PermitValueFilter = () => {
  const { permitValueRange } = useAppSelector(permitsSelector);
  return (
    <CollapseFilter label={'Permit value'}>
      {
        permitValueRange ?
          <RangeSelector
            maxRange={permitValueRange}
            valuePrefix={"$"}
            name={PERMIT_VALUE_FILTER_NAME}/>
          : <></>
      }
    </CollapseFilter>
  )
}

import React from "react";
import theme from 'application/theme';
import { IconProps } from './types';

interface Props extends IconProps {
  left?: boolean
}

export const ChevronDoubleIcon: React.FC<Props> = ({color = theme.palette.common.grey600, left}) => {
  return (
    <svg
      style={{transform: left ? 'rotate(180deg)' : 'none'}}
      xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M6 17.0005L11 12.0005L6 7.00049M13 17.0005L18 12.0005L13 7.00049" stroke={color} strokeWidth="2"
            strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

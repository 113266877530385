import { Box, styled } from '@mui/system';
import theme, {commonColors} from 'application/theme';
import { Typography } from '@mui/material';

export const SavedPermitFiltersWrapper = styled(Box)`
  padding: ${theme.spacing(0, 1, 1.5)};
  width: 100%;
  border-bottom: 1px solid ${theme.palette.common.grey300};
`;

export const SaveFiltersItemWrapperWrapper = styled(Box)`
  padding: ${theme.spacing(2, 1,1)};
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

interface Props {
  active: boolean;
}
export const SaveFiltersButtonStyled = styled(Typography)<Props>`
  cursor: ${(props) => props.active ? 'pointer' : 'not-allowed'};
  color: ${(props) => props.active ? commonColors.blue700 : commonColors.grey400};
`

export const SavedFiltersWrapper = styled(Box)<Props>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: ${theme.spacing(1)};
  padding: ${theme.spacing( 0.5)};
  border-radius: ${theme.spacing(0.5)};
  background: ${(props) => props.active ? theme.palette.common.grey300 : 'transparent'};
  &:hover {
    background: ${theme.palette.common.grey300};
  }
  cursor: pointer;
  .star-icon {
    width: ${theme.spacing(3)};
  }
  .delete-icon {
    visibility: hidden;
    padding: ${theme.spacing(0, 0.5, 0, 1)};
    display: flex;
    align-items: center;
  }

  &:hover .delete-icon {
    visibility: visible;
    padding: ${theme.spacing(0, 0.5, 0, 1)};
  }
`;

export const FilterLabelWrapper = styled(Box)`
  max-width: ${theme.spacing(23.75)};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  font-size: ${theme.spacing(1.75)};
  font-weight: 400;
  color: ${theme.palette.common.grey900};
`;
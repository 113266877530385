import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  ButtonContainer,
  FormContainer,
  FormItemContainer,
  FormWrapper,
} from "../styles";
import { schema } from "./formSchema";
import { IEditModal, OrganizationProfile } from "../store/types";
import { ModalComponent } from "application/components/ModalComponent";
import { OrganizationForm } from "../OrganizationForm";
import { Button } from "application";

export const EditOrganizationModal = ({
  isOpen,
  handleClose,
  organization,
  onConfirm,
}: IEditModal) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, dirtyFields },
    watch,
  } = useForm<OrganizationProfile>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: organization,
  });
  const onSubmit = async (data: OrganizationProfile) => {
    onConfirm(data);
  };

  const handleCloseModal = () => {
    handleClose();
    reset();
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleCloseModal}
      padding={3}
      title={"Edit organization profile"}
    >
      <FormWrapper>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <FormItemContainer>
            <OrganizationForm
              register={register}
              errors={errors}
              dirtyFields={dirtyFields}
              setValue={setValue}
              watch={watch}
            />
          </FormItemContainer>
          <ButtonContainer>
            <Button
              label="Cancel"
              height={5.5}
              width={120}
              type="button"
              onClick={handleCloseModal}
            />
            <Button
              label="Save"
              height={5.5}
              width={120}
              type="submit"
              onClick={handleSubmit(onSubmit)}
            />
          </ButtonContainer>
        </FormContainer>
      </FormWrapper>
    </ModalComponent>
  );
};

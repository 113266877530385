import React from "react";

import OrganizationStructure from "./organizationStructure";
import { EmptyCompanyInfo } from "../components";
import CompanyInfoHeader from "./infoHeader";
import GeneralInfo from "./generalInfo";
import TagItems from "./tags";

import { useAppSelector } from "application/store";

import {
  CompanyContentWrapperStyled,
  CompanyMainBarStyled,
  StickyBottomMenuStyled,
} from "./styles";

import { Box, Divider } from "@mui/material";
import { companySelector } from "../store";
import StyledLoader from "common/loader/StyledLoader";
import { sdrProfileSelector } from "common/sdrProfile/store";
import AggregatedPermitData from "./aggregatedPermitData";
import { NotAGoodFitCheckbox } from "./NotAGoodFitCheckbox";
import ExportCompanyDataButton from "./companyImport/ExportCompanyDataButton";
import { BusinessContactData } from "./businessContactData/intex";
import { CompanyInfoTutorial } from "common/tutorial";
const CompanyInfo = ({ showTutorial }: { showTutorial?: boolean }) => {
  const { company, isCompanyInfoLoading } = useAppSelector(companySelector);
  const {
    sdrProfile: { organizationStructureAccessPermitted },
  } = useAppSelector(sdrProfileSelector);

  if (!isCompanyInfoLoading) {
    return (
      <>
        {company.id ? (
          <CompanyMainBarStyled>
            <CompanyContentWrapperStyled>
              {showTutorial && (
                <Box display="flex" p={2} width="100%">
                  <CompanyInfoTutorial />
                </Box>
              )}
              <CompanyInfoHeader company={company} key={company.id} />
              <Divider />
              <GeneralInfo />
              <Divider />
              <TagItems />
              <Divider />
              <AggregatedPermitData />
              <Divider />
              <BusinessContactData />
              {organizationStructureAccessPermitted && (
                <>
                  <Divider />
                  <OrganizationStructure />
                </>
              )}
            </CompanyContentWrapperStyled>

            <StickyBottomMenuStyled>
              <NotAGoodFitCheckbox />
              <ExportCompanyDataButton />
            </StickyBottomMenuStyled>
          </CompanyMainBarStyled>
        ) : (
          <EmptyCompanyInfo />
        )}
      </>
    );
  } else
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <StyledLoader size={82} />
      </Box>
    );
};

export default CompanyInfo;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { ModalComponent } from 'application/components/ModalComponent';
import theme from 'application/theme';
import { ModalContentWrapper } from './styles';
import { LockCircleIcon } from 'application/assets';
import { Button } from 'application';
import { PathNames } from 'application/routes';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const NoCreditsModal = ({isOpen, onClose}: Props) => {
  const navigate = useNavigate();
  const handleGoToSettings = () => {
    navigate(PathNames.sdrProfile)
    onClose();
  }
  return (
    <ModalComponent
      isOpen={isOpen}
      padding={4}
      width={theme.spacing(60)}
      handleClose={onClose}
      closeOnClickOutside={false}
    >
      <ModalContentWrapper>
        <Box>
          <LockCircleIcon/>
        </Box>
        <Typography variant="subtitle1" color={theme.palette.common.grey800}>
          You cannot export company contacts
        </Typography>
        <Typography variant="caption" textAlign="center">
          You don’t have any available company credits. <br/>
          You can go to settings and request new credits.
        </Typography>
        <Box>
          <Button
            label="Go to settings"
            onClick={handleGoToSettings}
            height={5.5}
            type="button"
          />
        </Box>
      </ModalContentWrapper>
    </ModalComponent>
  )
}
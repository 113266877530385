import ListOfLocationsControl from "./ListOfLocationsControl";
import LocationSearchInput from "./LocationSearchInput";
import {LocationsLoader} from "./LocationsLoader";
import {Box, Typography} from "@mui/material";
import {LoadMoreButton} from "./LoadMoreButton";
import theme from "application/theme";
import React, {useMemo} from "react";

interface Props {
    searchType: string;
    selectedLocations: string[];
    fieldNameInclude: string;
    fieldNameExclude: string;
    searchInputValue: string;
    setSearchInputValue: (value: string) => void;
    handleSearch: () => void;
    isLoading: boolean;
    totalFoundLocations: number;
    foundLocations: string[];
    couldLoadMoreLocations: boolean;
    loadMoreLocations: () => void;
    noSearchResults: boolean;
    onClear: () => void;
}

const LocationSearchTab = (
    {
        selectedLocations,
        fieldNameInclude,
        fieldNameExclude,
        searchInputValue,
        setSearchInputValue,
        handleSearch,
        isLoading,
        totalFoundLocations,
        foundLocations,
        couldLoadMoreLocations,
        loadMoreLocations,
        noSearchResults,
        searchType,
        onClear

    }: Props
) => {

    const displayTypeToSearch = useMemo(() => {
        return !searchInputValue && totalFoundLocations === 0 && !noSearchResults
    }, [searchInputValue, totalFoundLocations, noSearchResults]);

    const displayResults = useMemo(() => {
        return !displayTypeToSearch && !noSearchResults
    }, [displayTypeToSearch, noSearchResults]);

    return (
        <>
            <ListOfLocationsControl
                locations={selectedLocations}
                fieldNameInclude={fieldNameInclude}
                fieldNameExclude={fieldNameExclude}
            />
            <LocationSearchInput
                value={searchInputValue}
                onChange={setSearchInputValue}
                placeholder={`Search by ${searchType} name`}
                onSubmit={handleSearch}
                disabled={isLoading}
                onClear={onClear}
            />
            {isLoading ? <LocationsLoader size={40}/>
                : <>
                    {noSearchResults && <NoResultsMessage/>}
                    {displayTypeToSearch && <TypeToSearchMessage searchType={searchType}/>}
                    {displayResults && <>
                        <ResultsCount count={totalFoundLocations}/>
                        <ListOfLocationsControl
                            locations={foundLocations}
                            fieldNameInclude={fieldNameInclude}
                            fieldNameExclude={fieldNameExclude}
                        />
                        {couldLoadMoreLocations &&
                            <Box ml={1}>
                                <LoadMoreButton onLoadMore={loadMoreLocations}
                                                fontSize={theme.spacing(1.75)}/>
                            </Box>
                        }
                    </>}

                </>
            }
        </>
    )
}

export default LocationSearchTab;

const ResultsCount = ({count}: any) => {
    if (!count || count === 0) return null;
    return (
        <Typography
            variant="caption"
            color={theme.palette.common.grey600}
        >{`Results (${count})`}</Typography>
    )
}

const NoResultsMessage = () => {
    return <Typography variant="caption"
                       color={theme.palette.common.grey800}>
        Sorry. We couldn't find any matches. Change your search request and try again.
    </Typography>
}

const TypeToSearchMessage = ({searchType}: { searchType: string }) => {
    return (<Box display='flex' alignItems='center' justifyContent='center' height={theme.spacing(8)}>
            <Typography variant="caption"
                        textAlign='center'
                        color={theme.palette.common.grey400}>
                {`Type ${searchType} name and push Enter`}
            </Typography>
        </Box>
    )
}
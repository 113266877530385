import { useAppDispatch, useAppSelector } from "../store";
import { organizationsSelector } from "common/organization/store";
import { useMemo } from "react";
import { HubspotIntegrationStatus } from "common/organization";
import {handleGetOrganizationProfile, handleRemoveHubspotIntegration} from "common/organization/store/actions";

const useOrganizationProfile = () => {
  const { organizationProfile } = useAppSelector(organizationsSelector);
  const { organizationStructureAccessPermitted } = organizationProfile;
  const dispatch = useAppDispatch();

  const isHubspotIntegrated = useMemo(() => {
    return  organizationProfile.hubSpotIntegrationStatus === HubspotIntegrationStatus.INTEGRATED
  }, [organizationProfile.hubSpotIntegrationStatus])

  const fetchOrganizationProfile = (organizationId: number) => {
    dispatch(handleGetOrganizationProfile(organizationId))
  }

  const removeHubspotIntegration = () => {
    if(!organizationProfile.id && !isHubspotIntegrated) {
        return;
    }
    dispatch(handleRemoveHubspotIntegration(organizationProfile.id))
  }

  return {
    organizationProfile,
    isHubspotIntegrated,
    fetchOrganizationProfile,
    organizationStructureAccessPermitted,
    removeHubspotIntegration
  }
}
export default useOrganizationProfile;
import { clientRequest } from "../requestHandler";
import {
  MultiplePermitsExportRequestI,
  PermitWithCompanyFilterDataI,
  SinglePermitExportRequestI
} from 'common/permits/types';
import { SortBy } from 'common/companies/companiesFilter/const';
import { SortOrder } from 'application/types';

export const getSinglePermitById = (permitId: number) => {
  return clientRequest({
    url: `/api/permits/${permitId}`,
    method: "GET",
  });
}


export const getPermitsInfoById = (permitIds: string) =>
  clientRequest({
    url: `/api/permits`,
    method: "GET",
    params: {
      permitIds,
    },
  });

export const getPermitProductType = () =>
  clientRequest({
    url: `/api/permits/product-types`,
    method: "GET",
  });

export const getPermitProductTypeBrand = (id: number) =>
  clientRequest({
    url: `/api/permits/product-types/${id}/brands`,
    method: "GET",
  });

export const getPermitProductTypeBrandName = (typeId: number, brandId: number) =>
  clientRequest({
    url: `api/permits/product-types/${typeId}/brands/${brandId}/names`,
    method: "GET",
  });

export const getAllPermitTags = () => {
  return clientRequest({
    url: `/api/permits/tags`,
    method: "GET",
  });
}
export const getAllPermitTypes = () => {
  return clientRequest({
    url: `/api/permits/permit-types`,
    method: "GET",
  });
}
export const getAllDwellingTypes = () => {
  return clientRequest({
    url: `/api/permits/dwelling-types`,
    method: "GET",
  });
}

export const getSquareFeetRangeValues = () => {
  return clientRequest({
    url: `/api/permits/square-feet-range-values`,
    method: "GET",
  });
}

export const getPermitRangeValues = () => {
  return clientRequest({
    url: `/api/permits/permit-range-values`,
    method: "GET",
  });
}
export const getPermitCities = (name: string, page: number, size: number) =>
  clientRequest({
    url: `/api/permits/cities`,
    method: "GET",
    params: {
      page,
      size,
      name,
    },
  });

export const getPermitStates = (name: string, page: number, size: number) =>
  clientRequest({
    url: `/api/permits/states`,
    method: "GET",
    params: {
      page,
      size,
      name,
    },
  });

export const getPermitZipCodes = (name: string, page: number, size: number) =>
  clientRequest({
    url: `/api/permits/zip-codes`,
    method: "GET",
    params: {
      page,
      size,
      name,
    },
  });

export const getPermitCounties = (name: string, page: number, size: number) =>
  clientRequest({
    url: `/api/permits/counties`,
    method: "GET",
    params: {
      page,
      size,
      name,
    },
  });

export const exportPermitsToCsv = (exportData: MultiplePermitsExportRequestI) =>
  clientRequest({
    url: `/api/permits/exports`,
    method: "POST",
    data: exportData,
  });


export const addPermitToBulkExport = (data: SinglePermitExportRequestI) => {
  return clientRequest({
    url: `/api/permits/bulk-export`,
    method: "POST",
    data,
  });
}

export const searchPermitsMapView = (
  filterData: PermitWithCompanyFilterDataI,
  sortBy?: SortBy,
  sortOrder?: SortOrder
) =>
  clientRequest({
    url: `/api/permits/coordinates`,
    method: "POST",
    data: filterData,
    params: {
      sort: sortBy ? `${sortBy},${sortOrder}` : undefined,
    },
  });

export const searchPermitsByCompanyFilters = (
  filterData: PermitWithCompanyFilterDataI,
  page: number,
  size: number,
  sortBy?: SortBy,
  sortOrder?: SortOrder
) =>
  clientRequest({
    url: `/api/permits`,
    method: "POST",
    data: filterData,
    params: {
      page,
      size,
      sort: sortBy ? `${sortBy},${sortOrder}` : undefined,
    },
  });

import React from 'react';
import { useAppSelector } from 'application/store';
import { FiltersCollapse } from 'application';
import { FiltersWrapper, FilterTextStyled } from '../components/styles';
import { formatLargeNumbers } from 'application/utils';
import { permitFiltersSelector } from 'common/permits/store';

export const SquareFeetFilter = () => {
  const {squareFootage} = useAppSelector(permitFiltersSelector);
  const displayFilter  = squareFootage?.min || squareFootage?.max;
  return displayFilter ? (
    <FiltersCollapse label={'SQUARE FEET'} count={1}>
      <FiltersWrapper>
        <FilterTextStyled>
                {`${formatLargeNumbers(squareFootage?.min || 0)}
                 - ${formatLargeNumbers(squareFootage?.max || 0)}`}
        </FilterTextStyled>
      </FiltersWrapper>
    </FiltersCollapse>
  ) : <></>;
}
import { Button } from "application";
import { useAppDispatch, useAppSelector } from "application/store";
import { usePermitFiltersContext } from "common/permits/hooks/usePermitFiltersContext";
import { usePermitsNavigation } from "common/permits/hooks/usePermitsNavigation";
import { permitsSelector, setSearchInArea } from "common/permits/store";
import React from "react";
import { usePermitsWithCompaniesSearch } from 'common/permits/hooks/usePermitsWithCompaniesSearch';

export const ShowAllButton = () => {
  const dispatch = useAppDispatch();
  const {isPermitTableMode} = usePermitsNavigation();
  const {zoomedArea, searchInArea} = useAppSelector(permitsSelector);
  const {
    searchForCompaniesAndPermitsOnBackground,
    searchForPermitsAndCompaniesOnBackground
  } = usePermitsWithCompaniesSearch();
  const {
    permitFilterForm,
    companyFilterForm,
    filtersWithZoomedArea,
    filtersWithoutZoomedArea
  } = usePermitFiltersContext();

  const handleToggleView = () => {
    dispatch(setSearchInArea(!searchInArea));
    const permitFilter = permitFilterForm.watch();
    const companyFilter = companyFilterForm.watch();
    const filterData = searchInArea ? filtersWithoutZoomedArea : filtersWithZoomedArea;

    if (isPermitTableMode) {
      searchForPermitsAndCompaniesOnBackground(filterData);
    } else {
      searchForCompaniesAndPermitsOnBackground(filterData);
    }
  };
  return (
    <Button
      label={searchInArea ? "Show all" : "Show area"}
      height={4.5}
      onClick={handleToggleView}
      type="button"
      mr={2}
    />
  );
};

import React from "react";
import { Box } from "@mui/system";
import { ShowOnMapButton } from "./ShowOnMap";
import { useAppSelector } from "application/store";
import { permitsSelector } from "common/permits/store";
import { AddPermitToBulkExport } from './AddPermitToBulkExport';
import { ViewPermitDetails } from './ViewPermitDetails';

interface Props {
  id: number;
  isAddedToBulk: boolean;
  onAddToBulk: (permitId: number) => void;
}
const TableActions = ({id, isAddedToBulk, onAddToBulk}: Props): JSX.Element => {
  const {foundPermits} = useAppSelector(permitsSelector);
  const itemInfo = foundPermits.content.filter((e) => e.id === id)[0];
  return (
    <Box display="flex">
      <AddPermitToBulkExport
        permitId={id}
        onAddToBulk={onAddToBulk}
        isAddedToBulk={isAddedToBulk}/>
      <ShowOnMapButton itemInfo={itemInfo}/>
      <ViewPermitDetails permitId={id}/>
    </Box>
  );
};

export default TableActions;

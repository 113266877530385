import React from "react";
import { Box } from "@mui/system";
import { LoaderWrapper, PermitsTableModeTabItemStyled } from "./styles";
import { usePermitsNavigation } from "common/permits/hooks/usePermitsNavigation";
import { PermitsDisplayMode } from "common/permits/types";
import { getNumberFormatWithSeparators } from "application/utils/numberUtils";
import { permitsSelector } from "common/permits/store";
import { useAppSelector } from "application/store";
import { SmallLoader } from "common/loader/SmallLoader";

export const TableViewResults = () => {
  const { isPermitTableMode, toggleTableMode } = usePermitsNavigation();
  const { foundCompanies, foundPermits, permitsLoading, companiesLoading } =
    useAppSelector(permitsSelector);

  const handleSwitchToCompaniesMode = () => {
    toggleTableMode(PermitsDisplayMode.COMPANIES);
  };
  const handleSwitchToPermitMode = () => {
    toggleTableMode(PermitsDisplayMode.PERMITS);
  };

  return (
    <Box display="flex" gap={1.5}>
      {companiesLoading ? (
        <LoaderWrapper><SmallLoader size={20} width={20} /></LoaderWrapper>
      ) : (
        <PermitsTableModeTabItemStyled
          onClick={handleSwitchToCompaniesMode}
          active={!isPermitTableMode ? 1 : 0}
        >
          {`Companies ${getNumberFormatWithSeparators(
            foundCompanies.totalElements,
            " "
          )}`}
        </PermitsTableModeTabItemStyled>
      )}
      {permitsLoading ? (
        <LoaderWrapper><SmallLoader size={20} width={20} /></LoaderWrapper>
      ) : (
        <PermitsTableModeTabItemStyled
          onClick={handleSwitchToPermitMode}
          active={isPermitTableMode ? 1 : 0}
        >
          {`Permits ${getNumberFormatWithSeparators(
            foundPermits.totalElements,
            " "
          )}`}
        </PermitsTableModeTabItemStyled>
      )}
    </Box>
  );
};

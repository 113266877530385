import React from 'react';
import { CollapseFilter } from '../../../CollapseFilter';
import { RangeSelector } from 'common/companies/companiesFilter/components';
import { useAppSelector } from 'application/store';
import { permitsSelector } from 'common/permits/store';

const SQUARE_FEET_FILTER_NAME = 'squareFootage';
export const SquareFeetFilter = () => {
  const { squareFeetRange } = useAppSelector(permitsSelector);
  return (
    <CollapseFilter label={'Square feet'}>
      {
        squareFeetRange ?
          <RangeSelector
            maxRange={squareFeetRange}
            name={SQUARE_FEET_FILTER_NAME}/>
          : <></>
      }
    </CollapseFilter>
  )
}

import { Box, styled } from "@mui/system";
import theme from "application/theme";
import {TableContainer, Typography} from "@mui/material";

export const TableContainerStyled = styled(TableContainer)`
  border: 1px solid ${theme.palette.common.grey400};
  border-radius: ${theme.spacing(1)};
  background: ${theme.palette.common.white};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`;

export const ActivationBadge = styled(Box)`
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: ${theme.spacing(2)};
  background-color: ${theme.palette.common.grey200};
  padding: ${theme.spacing(0.5, 1.5, 0.5, 1.25)};
`;

export const TableCellTextStyled = styled(Typography)`
  color: ${theme.palette.common.grey800};
  font-size: ${theme.typography.pxToRem(14)};
`

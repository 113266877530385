import { NotificationStatus } from "common/notificationsPage/store/types";
import { clientRequest } from "../requestHandler";

export const getAllNotifications = () => {
  return clientRequest({
    url: `/api/notifications`,
    method: "GET",
  });
};

export const readNotificationById = (ids: number[]) => {
  return clientRequest({
    url: `api/notifications?status=${NotificationStatus.READ}`,
    method: "PATCH",
    data: { ids: ids },
  });
};

export const deleteNotificationById = (id: number) => {
  return clientRequest({
    url: `/api/notifications/${id}`,
    method: "DELETE",
  });
};

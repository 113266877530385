import { Box } from "@mui/system";
import { ArrowOutward } from "application/assets";
import React from "react";
import { useViewDetails } from 'common/permits/hooks/useViewDetails';

interface Props {
  permitId: number;
}
export const ViewPermitDetails = ({permitId} : Props): JSX.Element => {
  const {showPermitDetails} = useViewDetails();

  const handleViewDetails = () => {
    showPermitDetails(permitId);
  }

  return (
    <Box
      sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      onClick={handleViewDetails}
    >
      <ArrowOutward />
    </Box>
  );
};

import { styled } from '@mui/system';
import { Box } from '@mui/material';
import theme from 'application/theme';

export const CompaniesListWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  overflow: hidden auto;
  width: 100%;
  gap: ${theme.spacing(1)};
  padding: ${theme.spacing(2)};
`;

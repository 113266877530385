import React, {
  FC,
  Suspense,
  useEffect,
  useState,
} from "react";
import TeamProfile from "common/organization/OrganizationProfile";
import { useAppSelector } from "application/store";
import { sdrProfileSelector } from "common/sdrProfile/store";
import NotFoundPage from "./NotFoundPage";
import { Loader, MainLayout, handleLoading } from "common";
import { UserRoles } from "common/sdrProfile/store/types";

const TeamManagementPage: FC = () => {
  const [isOrgManager, setIsOrgManager] = useState(false);
  const { sdrProfile } = useAppSelector(sdrProfileSelector);

  useEffect(() => {
    handleLoading(true);
    if (sdrProfile.organizationName) {
      const orgManager = !!sdrProfile.userRoles.filter(
        (e) => e === UserRoles.orgManager
      ).length;
      setIsOrgManager(orgManager);
      handleLoading(false);
    }
  }, [sdrProfile.organizationName]);

  return isOrgManager ? (
    <Suspense fallback={<Loader />}>
      <MainLayout withNavBar={false}>
        <TeamProfile />
      </MainLayout>
    </Suspense>
  ) : (
    <NotFoundPage />
  );
};

export default TeamManagementPage;

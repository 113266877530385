import React, { useCallback, useMemo } from 'react';
import { CollapseFilter } from '../../../CollapseFilter';
import { useFormContext } from 'react-hook-form';
import theme from 'application/theme';
import { InputStyled } from 'application/components/FormInput/styles';
import { PermitAffiliationI, PermitAffiliationType } from 'common/permits/types';
import { Typography } from '@mui/material';
import { AddAffiliationStyled } from './styles';
import { MultiSelect } from 'application/components/MultiSelect';
import { SelectedAffiliations } from './SelectedAffiliations';

export const affiliationTypeOptions = [{
  label: 'Owner',
  value: PermitAffiliationType.OWNER,
}, {
  label: 'Designer',
  value: PermitAffiliationType.DESIGNER,
}, {
  label: 'Architect',
  value: PermitAffiliationType.ARCHITECT,
}, {
  label: 'Engineer',
  value: PermitAffiliationType.ENGINEER,
}, {
  label: 'Contractor Company',
  value: PermitAffiliationType.CONTRACTOR_COMPANY,
}, {
  label: 'Contractor Name',
  value: PermitAffiliationType.CONTRACTOR_NAME,
}];

export const AFFILIATION_FILTER_NAME = 'affiliations';
export const AffiliationFilter = () => {
  const {watch, setValue} = useFormContext();

  const currentAffiliation = useMemo(() => {
    const affiliations = watch(AFFILIATION_FILTER_NAME);
    return affiliations?.length > 0 ? affiliations[affiliations.length - 1] : {
      name: '',
      types: [],
    }
  }, [watch(AFFILIATION_FILTER_NAME)]);

  const currentAffiliationIsEmpty = useMemo(() => {
    return !currentAffiliation.name && currentAffiliation.types?.length === 0;
  },[currentAffiliation]);

  const isAffiliationsEqual = (affiliation1: any, affiliation2: any) => {
    return affiliation1.name === affiliation2.name && affiliation1.types.join('') === affiliation2.types.join('');
  }

  const currentAffiliationExists = useMemo(() => {
    const affiliations = watch(AFFILIATION_FILTER_NAME);
    if(affiliations.length < 2) return false;
    return affiliations.slice(0, affiliations.length - 1)
      .some((affiliation: any) => isAffiliationsEqual(affiliation, currentAffiliation));
  },[watch(AFFILIATION_FILTER_NAME)]);



  const handeAddAffiliation = () => {
    if(currentAffiliationIsEmpty) return;
    const affiliations = watch(AFFILIATION_FILTER_NAME);
    const emptyAffiliation = {
      name: '',
      types: [],
    }
    setValue(AFFILIATION_FILTER_NAME, [...affiliations, emptyAffiliation]);
  }

  const changeCurrentAffiliation = (current: PermitAffiliationI) => {
    const affiliations = watch(AFFILIATION_FILTER_NAME);
    if(!affiliations) {
      setValue(AFFILIATION_FILTER_NAME, [current]);
      return;
    }
    const newAffiliations = affiliations.slice(0, affiliations.length - 1).concat(current);
    setValue(AFFILIATION_FILTER_NAME, newAffiliations);
  }

  const handleChangeSelectedTypes = useCallback((types: PermitAffiliationType[]) => {
    changeCurrentAffiliation({
      ...currentAffiliation,
      types,
    });
  }, [currentAffiliation]);

  const handleChangeName = useCallback((e: any) => {
    changeCurrentAffiliation({
      ...currentAffiliation,
      name: e.target.value,
    });
  }, [currentAffiliation]);

  const handleRemoveAffiliation = (index: number) => {
    const affiliations = watch(AFFILIATION_FILTER_NAME);
    const newAffiliations = affiliations.filter((affiliation: PermitAffiliationI, i: number) => i !== index);
    setValue(AFFILIATION_FILTER_NAME, newAffiliations);
  }

  return (
    <CollapseFilter label={'Permit Affiliation'}>
      {
        <SelectedAffiliations
          affiliations={watch(AFFILIATION_FILTER_NAME)}
          onRemove={handleRemoveAffiliation}
        />
      }
      <InputStyled
        font_size={theme.spacing(1.75)}
        sx={{width: "100%"}}
        value={currentAffiliation.name}
        placeholder={"Search by executor name"}
        height={4}
        autofillcolor={theme.palette.common.grey150}
        onChange={handleChangeName}
      />
      <Typography fontSize={14} marginTop={1} color={theme.palette.common.grey900}>Type</Typography>

      <MultiSelect
        options={affiliationTypeOptions}
        selectedOptions={currentAffiliation.types}
        onChangeSelected={handleChangeSelectedTypes}
      />
      <AddAffiliationStyled
        onClick={handeAddAffiliation}
        disabled={currentAffiliationIsEmpty || currentAffiliationExists}>
        {'+  Add another'}
      </AddAffiliationStyled>

    </CollapseFilter>
  )
}

import { styled } from "@mui/system";
import { Box } from "@mui/material";
import theme from "../../../application/theme";

export const ContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.spacing(3)};
`;

export const StyledChangePasswordForm = styled("form")`
  padding-top: ${theme.spacing(4)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.spacing(2.5)};
`;

export const StyledButtonContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: ${theme.spacing(2)};
  margin-top: ${theme.spacing(1)};
`;
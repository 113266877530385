import React, { FC } from "react";
import { useAppSelector } from "application/store";
import theme from "application/theme";
import { Box } from "@mui/system";
import CompanyInfoLabel from "../InfoLabel";
import { PermitDataItem } from "./PermitDataItem";
import { getNumberFormatWithSeparators } from "application/utils";
import {selectedCompanySelector} from "../../store";

const AggregatedPermitData: FC = () => {
  const { permitHistoryTimeframe, avgAnnualPermits, avgAnnualValue, maxPermitValue } = useAppSelector(selectedCompanySelector);
  const timeFrame = permitHistoryTimeframe ? `${Math.round(permitHistoryTimeframe)} (years)` : null;
  const annualPermits = avgAnnualPermits ? getNumberFormatWithSeparators(Math.round(avgAnnualPermits)) : null;
  const annualPermitsValue = avgAnnualValue ? ('$' + getNumberFormatWithSeparators(Math.round(avgAnnualValue))) : null;
  const maxPermits = maxPermitValue ? ('$' + getNumberFormatWithSeparators(Math.round(maxPermitValue))) : null;
  return (
    <Box
      className="general-company-info"
      display="flex"
      flexDirection="column"
      gap={1.5}
      width="100%"
      p={theme.spacing(2, 4)}
    >
      <CompanyInfoLabel label="Aggregated permit data" withoutAmount/>
      {!!permitHistoryTimeframe &&
          <PermitDataItem label={'Permit History Timeframe'}
                          value={timeFrame}/>
      }
      <PermitDataItem label={'Average Annual Permits'}
                      value={annualPermits}/>
      <PermitDataItem label={'Average Annual Value'}
                      value={annualPermitsValue}/>
      <PermitDataItem label={'Max Permit Value'}
                      value={maxPermits}/>
    </Box>
  )
}

export default AggregatedPermitData;
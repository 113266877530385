import { createSlice } from "@reduxjs/toolkit";
import { store } from "application/store";
import { NotificationsI } from "./types";

export const initialState = {
  notifications: [] as NotificationsI[],
};

export const notificationsSlice = createSlice({
  name: "sdr",
  initialState,
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    cleanNotificationsStore: (state) => {
      state.notifications = initialState.notifications;
    },
  },
});

export default notificationsSlice.reducer;

export const notificationsSelector = () => store.getState().notifications;

export const { setNotifications } = notificationsSlice.actions;
export const { cleanNotificationsStore } = notificationsSlice.actions;

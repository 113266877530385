import React from "react";
import * as yup from "yup";
import { Button, FormInput, QuantityPicker } from "application";
import { Box, Typography } from "@mui/material";
import theme from "application/theme";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ModalComponent } from "application/components/ModalComponent";
import { handleAddTeamMember, handleSendAndAddNewOne } from "../store/actions";
import { useAppDispatch, useAppSelector } from "application/store";
import { LoadingIcon } from "application/assets/LoadingIcon";
import { organizationsSelector } from "../store";
import { sdrProfileSelector } from "common/sdrProfile/store";
import useOrganizationProfile from "application/hooks/useOrganizationProfile";

interface FormDataI {
  emailContactPerson: string;
  firstName: string;
  lastName: string;
  phoneCredits: number;
  emailCredits: number;
  companyDataCredits: number;
}

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  phoneCredits: yup.number(),
  emailCredits: yup.number(),
  emailContactPerson: yup
    .string()
    .email("Invalid email")
    .required("Email is required"),
});

export const AddTeamMemberModal = ({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { isLoadingAddMember } = useAppSelector(organizationsSelector);
  const { sdrProfile } = useAppSelector(sdrProfileSelector);
  const { organizationStructureAccessPermitted }  = useOrganizationProfile();

  const formInitValues = {
    firstName: "",
    lastName: "",
    emailContactPerson: "",
    emailCredits: 0,
    phoneCredits: 0,
    companyDataCredits: 0,
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields },
    watch,
  } = useForm<FormDataI>({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: formInitValues,
  });

  const disabled = Object.keys(errors).length > 0;

  const onSubmit = async (data: FormDataI) => {
    dispatch(
      handleAddTeamMember({
        organizationId: sdrProfile.organizationId,
        email: data.emailContactPerson,
        emailCredits: data.emailCredits,
        phoneCredits: data.phoneCredits,
        companyDataCredits: data.companyDataCredits,
        firstName: data.firstName,
        lastName: data.lastName,
      })
    );
  };

  const sendAndAddNewOne = (data: FormDataI) => {
    dispatch(
      handleSendAndAddNewOne({
        organizationId: sdrProfile.organizationId,
        email: data.emailContactPerson,
        emailCredits: data.emailCredits,
        phoneCredits: data.phoneCredits,
        firstName: data.firstName,
        lastName: data.lastName,
        companyDataCredits: data.companyDataCredits,
      })
    );
    reset(formInitValues);
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleClose}
      padding={3}
      width={545}
    >
      <Box textAlign="center" display="flex" flexDirection="column">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="start"
          textAlign="start"
          maxWidth={theme.spacing(52.75)}
          mb={4}
        >
          <Typography
            variant="h4"
            color={theme.palette.common.grey900}
            mb={0.5}
          >
            Add Team Member
          </Typography>
          <Typography variant="overline" color={theme.palette.common.grey800}>
            Team Member will get invite to join an organization via email.
          </Typography>
        </Box>
        <Box display="flex" gap={3} mb={3}>
          <FormInput
            name="name"
            placeholder="First name"
            label="First Name"
            error={errors.firstName?.message}
            register={register("firstName")}
            value={watch("firstName")}
            isValid={dirtyFields.firstName}
          />
          <FormInput
            name="name"
            placeholder="Last name"
            label="Last Name"
            error={errors.lastName?.message}
            register={register("lastName")}
            value={watch("lastName")}
            isValid={dirtyFields.lastName}
          />
        </Box>
        <FormInput
          name="emailContactPerson"
          type="email"
          placeholder="Rayna.Botosh@example.com"
          label="Email Contact Person"
          error={errors.emailContactPerson?.message}
          register={register("emailContactPerson")}
          value={watch("emailContactPerson")}
          isValid={dirtyFields.emailContactPerson}
        />
        <Typography
          variant="overline"
          color={theme.palette.common.grey900}
          fontWeight={500}
          mt={4}
          mb={1.5}
        >
          Assign credits to a Team Member:
        </Typography>
        <Box display="flex" justifyContent="center" gap={3} mb={4}>
          <QuantityPicker
              label={"Company credits"}
              max={999999}
              register={register("companyDataCredits")}
          />
          {
            organizationStructureAccessPermitted && <>
                <QuantityPicker
                    label={"Email credits"}
                    register={register("emailCredits")}
                />
                <QuantityPicker
                    label={"Phone credits"}
                    register={register("phoneCredits")}
                />
              </>
          }

        </Box>
        <Box
          width={"100%"}
          sx={{ borderBottom: `1px solid ${theme.palette.common.grey300}` }}
          mb={4}
        />
        <Box display="flex" justifyContent="center" gap={theme.spacing(2)}>
          <Button
            label={isLoadingAddMember ? "Loading" : "Send and Add New One"}
            height={5.5}
            width={220}
            type="button"
            onClick={handleSubmit(sendAndAddNewOne)}
            disabled={disabled || isLoadingAddMember}
          />
          <Button
            label={isLoadingAddMember ? "Loading" : "Send Invitation"}
            width={220}
            type="submit"
            startAdornment={isLoadingAddMember ? <LoadingIcon /> : undefined}
            onClick={handleSubmit(onSubmit)}
            disabled={isLoadingAddMember || disabled}
            rotate={isLoadingAddMember}
          />
        </Box>
      </Box>
    </ModalComponent>
  );
};

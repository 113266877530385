import { useState } from "react";
import { useAppSelector } from "application/store";
import {
  getCompaniesAndPermitsAutoSuggest,
  searchCompaniesByPermitFilters,
  searchCompaniesMapView,
} from "integration/api/companies";
import { CompanyAutoSuggestOptionI } from "../CompanySearchAutocomplete/AutosuggestOption";
import { PermitWithCompanyFilterDataI } from "common/permits/types";
import { prepareCompanyWithPermitFiltersToSearch } from "common/permits/utils";
import { permitsSelector } from "common/permits/store";
import { usePermitsNavigation } from "common/permits/hooks/usePermitsNavigation";

export const useCompanySearchAutocomplete = () => {
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);
  const [isTotalResultsAreLoading, setTotalResultsAreLoading] = useState(false);
  const [options, setOptions] = useState<CompanyAutoSuggestOptionI[]>([]);
  const { isMapView } = usePermitsNavigation();
  const { zoomedArea, searchInArea } = useAppSelector(permitsSelector);

  const [totalResults, setTotalResults] = useState(0);

  const fetchOptions = async (filterData: PermitWithCompanyFilterDataI) => {
    setIsOptionsLoading(true);
    setTotalResultsAreLoading(true);
    filterData = prepareCompanyWithPermitFiltersToSearch(filterData);
    const newFilterData = {
      companyFilter: { ...filterData.companyFilter, zoomedArea },
      permitFilter: { ...filterData.permitFilter, zoomedArea },
    };
    try {
      if (isMapView) { 
        const autoSuggestResult = await getCompaniesAndPermitsAutoSuggest(
          filterData,
          0,
          5
        );
        setOptions(autoSuggestResult.content);
      }
      const autoSuggestResult = await getCompaniesAndPermitsAutoSuggest(
        searchInArea ? newFilterData : filterData,
        0,
        5
      );
      setOptions(autoSuggestResult.content);
    } finally {
      setIsOptionsLoading(false);
    }
    try {
      if (isMapView) {
        const companiesTotalResult = await searchCompaniesMapView(filterData);
        setTotalResults(companiesTotalResult?.content?.length);
      } else {
        const companiesTotalResult = await searchCompaniesByPermitFilters(
          searchInArea ? newFilterData : filterData,
          0,
          20
        );
        setTotalResults(companiesTotalResult?.totalElements);
      }
    } finally {
      setTotalResultsAreLoading(false);
    }
  };
  const getCompanyInfo = (companyId: number) => {
    // for drawer
    // return dispatch(handleGetCompanyInfo(companyId));
  };
  const clearOptions = () => {
    setOptions([]);
  };

  return {
    isOptionsLoading,
    options,
    fetchOptions,
    getCompanyInfo,
    clearOptions,
    totalResults,
    isTotalResultsAreLoading,
  };
};

import React from 'react';
import { AutocompleteRenderInputParams, InputAdornment, TextField } from '@mui/material';
import { Close, Search } from '@mui/icons-material';
import theme from 'application/theme';

interface SearchInputProps {
  disabled: boolean,
  params: AutocompleteRenderInputParams,
  value: string,
  setValue: (newValue: string) => void,
  onClearSearch: () => void,
  placeholder?: string,
}

export const SearchInput = ({params, value, setValue, disabled, onClearSearch, placeholder = 'Search'}: SearchInputProps) => {
  const clearInput = () => {
    onClearSearch();
    setValue('');
  }

  return (
    <TextField
      {...params}
      disabled={disabled}
      placeholder={placeholder}
      onClick={(event) => {
        event.stopPropagation();
      }}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <Search
              sx={{
                color: value
                  ? theme.palette.common.grey800
                  : theme.palette.common.grey600,
              }}
            />
          </InputAdornment>
        ),
        endAdornment: value && (
          <InputAdornment
            position="end"
            onClick={clearInput}
            sx={{ cursor: "pointer" }}
          >
            <Close sx={{ color: theme.palette.common.grey800 }} />
          </InputAdornment>
        ),
      }}
    />
  )
}
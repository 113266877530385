import { ClickAwayListener, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { OptionsWrapper, ShowMoreItem, StyledIconButton } from "./styles";
import theme from "application/theme";
import { UserDeleteModal } from "common/organization/modals/UserDeleteModal";
import useToggleModal from "application/hooks/useToggleModal";
import { DotsIcon } from "application/assets/DotsIcon";
import { ReInvitationModal } from "common/organization/modals/ReInvitationModal";
import { Refresh } from "@mui/icons-material";
import { TeamMemberI } from "common/organization/store/types";
import { resendInvitation } from "integration/api/organizations";

export const Options = ({
  archived,
  sdr,
  isTeamMember,
}: {
  archived: boolean;
  sdr: TeamMemberI;
  isTeamMember: boolean;
}) => {
  const [isOpenMore, setIsOpenMore] = useState(false);
  const { isOpen, closeModal, openModal } = useToggleModal();
  const [invitationSent, setInvitationSent] = useState(false);
  const isActive = sdr.activationStatus === "ACTIVE";

  const openReInvitationModal = async () => {
    setIsOpenMore(false);
    await resendInvitation({
      organizationId: sdr.organization.id,
      email: sdr.email,
      name: `${sdr.firstName || ""} ${sdr.lastName || ""}`,
    });
    setInvitationSent(true);
  };

  const closeReInvitationModal = () => {
    setInvitationSent(false);
  };

  const openMore = () => {
    setIsOpenMore(true);
  };

  const closeMore = () => {
    setIsOpenMore(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={closeMore}>
        <Box ml={2} position="relative">
          {archived ? (
            <StyledIconButton>
              <Refresh />
            </StyledIconButton>
          ) : (
            <StyledIconButton onClick={openMore}>
              <DotsIcon />
            </StyledIconButton>
          )}
          {isOpenMore && (
            <OptionsWrapper>
              {!isActive && (
                <ShowMoreItem
                  sx={{
                    borderRadius: theme.spacing(1, 1, 0, 0),
                    cursor: isTeamMember ? "not-allowed" : "pointer",
                  }}
                  onClick={() => !isTeamMember && openReInvitationModal()}
                >
                  <Typography
                    variant="caption"
                    color={
                      isTeamMember
                        ? theme.palette.common.grey400
                        : theme.palette.common.grey800
                    }
                    display="inline"
                  >
                    Resend Invitation
                  </Typography>
                </ShowMoreItem>
              )}
              <ShowMoreItem
                sx={{
                  borderRadius: theme.spacing(0, 0, 1, 1),
                  cursor: isTeamMember ? "not-allowed" : "pointer",
                }}
                onClick={() => !isTeamMember && openModal()}
              >
                <Typography
                  variant="caption"
                  color={
                    isTeamMember
                      ? theme.palette.common.grey400
                      : theme.palette.common.grey800
                  }
                  display="inline"
                >
                  Remove from seat
                </Typography>
              </ShowMoreItem>
            </OptionsWrapper>
          )}
        </Box>
      </ClickAwayListener>
      <UserDeleteModal
        isOpen={isOpen}
        handleClose={closeModal}
        sdrId={sdr.id}
      />
      <ReInvitationModal
        isOpen={invitationSent}
        handleClose={closeReInvitationModal}
        email={sdr.email}
      />
    </>
  );
};

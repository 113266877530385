import { useAppDispatch, useAppSelector } from "application/store";
import {
  handleDeleteCompanyFromBulkExport,
  handleDeletePermitFromBulkExport,
  handleGetAllCompaniesByOrgId,
  handleGetAllEmployeesByOrgId,
  handleGetAllPermitsByOrgId,
  handleGetExportedCompaniesByOrgId,
  handleGetExportedEmployeesByOrgId,
  handleGetExportedPermitsByOrgId,
  handleGetNewCompaniesBySdr,
  handleGetNewEmployeesBySdrId,
  handleGetNewPermitsBySdr
} from "./store/actions";
import { sdrProfileSelector } from "../sdrProfile/store";
import { useParams } from "react-router-dom";
import {
  setSelectedContacts,
  setSelectedEmployees,
  setTotalSelectedCompanies,
  setTotalSelectedContacts,
  setTotalSelectedPermits
} from "./store";
import { useMemo } from "react";
import { ExportScopePath } from './types';

const useBulkExport = () => {
  const dispatch = useAppDispatch();
  const { sdrProfile } = useAppSelector(sdrProfileSelector);
  const { scope } = useParams();
  const isExportScopeEmployees = useMemo(() => scope === ExportScopePath.EMPLOYEES, [scope]);
  const isExportScopeCompanies = useMemo(() => scope === ExportScopePath.COMPANIES, [scope]);
  const isExportScopePermits = useMemo(() => scope === ExportScopePath.PERMITS, [scope]);

  const fetchEmployees = (organizationId: number) => {
    dispatch(handleGetNewEmployeesBySdrId(0));
    dispatch(handleGetAllEmployeesByOrgId(organizationId, 0));
    dispatch(handleGetExportedEmployeesByOrgId(organizationId, 0));
  }

  const fetchCompanies = (organizationId: number) => {
    dispatch(handleGetNewCompaniesBySdr(0));
    dispatch(handleGetAllCompaniesByOrgId(organizationId, 0));
    dispatch(handleGetExportedCompaniesByOrgId(organizationId, 0));
  }
  const fetchAllCompaniesPage = (organizationId: number, page: number) => {
    dispatch(handleGetAllCompaniesByOrgId(organizationId, page));
  }
  const fetchExportedCompaniesPage = (organizationId: number, page: number) => {
    dispatch(handleGetExportedCompaniesByOrgId(organizationId, page));
  }
  const fetchNewCompaniesPage = (page: number) => {
    dispatch(handleGetNewCompaniesBySdr(page));
  }
  const deleteCompanyFromBulkExport = async (bulkExportItemId: number) => {
    await dispatch(handleDeleteCompanyFromBulkExport(sdrProfile.organizationId, bulkExportItemId))
  }

  const fetchAllPermitsPage = (organizationId: number, page: number) => {
    dispatch(handleGetAllPermitsByOrgId(organizationId, page));
  }
  const fetchExportedPermitsPage = (organizationId: number, page: number) => {
    dispatch(handleGetExportedPermitsByOrgId(organizationId, page));
  }
  const fetchNewPermitsPage = (page: number) => {
    dispatch(handleGetNewPermitsBySdr(page));
  }

  const fetchPermits = (organizationId: number) => {
    fetchNewPermitsPage(0);
    fetchAllPermitsPage(organizationId, 0);
    fetchExportedPermitsPage(organizationId, 0);
  }

  const deletePermitFromBulkExport = async (bulkExportItemId: number) => {
    await dispatch(handleDeletePermitFromBulkExport(bulkExportItemId, sdrProfile.organizationId))
  }

  const clearAllSelection = () => {
    if(isExportScopeEmployees) {
      dispatch(setTotalSelectedContacts([]));
      dispatch(setSelectedContacts([]));
      dispatch(setSelectedEmployees([]));
    } else if (isExportScopeCompanies) {
      dispatch(setTotalSelectedCompanies([]));
    } else {
      dispatch(setTotalSelectedPermits([]));
    }
  };

  return {
    fetchEmployees,
    fetchCompanies,
    isExportScopeEmployees,
    isExportScopeCompanies,
    isExportScopePermits,
    fetchAllCompaniesPage,
    fetchExportedCompaniesPage,
    fetchNewCompaniesPage,
    deleteCompanyFromBulkExport,
    clearAllSelection,
    fetchAllPermitsPage,
    fetchExportedPermitsPage,
    fetchNewPermitsPage,
    fetchPermits,
    deletePermitFromBulkExport
  }
};

export default useBulkExport;
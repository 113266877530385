import React from 'react';
import { useAppSelector } from 'application/store';
import { FiltersCollapse } from 'application';
import { FiltersWrapper } from '../components/styles';
import { companyFiltersSelector } from 'common/permits/store';
import { getArrayLength } from 'application/utils/arrayUtils';
import { LocationsFilterGroup } from '../components/LocationsFilterGroup';

export const LocationFilter = () => {
  const {citiesInclude, citiesExclude,
    statesInclude, statesExclude} = useAppSelector(companyFiltersSelector);
  const filtersCount = getArrayLength(citiesInclude) + getArrayLength(citiesExclude) + getArrayLength(statesInclude) + getArrayLength(statesExclude);
  return filtersCount > 0 ? (
    <FiltersCollapse label={'LOCATION'} count={filtersCount}>
      <FiltersWrapper>
        <LocationsFilterGroup label={'States include'} locations={statesInclude}/>
        <LocationsFilterGroup excludeFilter label={'States exclude'} locations={statesExclude}/>
        <LocationsFilterGroup label={'Metro area include'} locations={citiesInclude}/>
        <LocationsFilterGroup excludeFilter label={'Metro area exclude'} locations={citiesExclude}/>
      </FiltersWrapper>
    </FiltersCollapse>
  ) : <></>;
}

import { BulkExportContactI, ExportCompanyI, ExportInfoDto } from "./types";
import { ExportScope } from "common/companies/types";

export const convertEmployeesExportData = (
  data: BulkExportContactI[]
): ExportInfoDto[] => {
  const uniqueIdsSet = new Set<string | number>();
  const result: ExportInfoDto[] = [];

  for (const item of data) {
    if (!uniqueIdsSet.has(item.externalId)) {
      let exportType = "";

      if (item.emailExported) {
        exportType = "EMAIL";
      } else if (item.phoneExported) {
        exportType = "PHONE";
      } else {
        exportType = "BOTH";
      }

      result.push({
        id: item.externalId,
        exportType: exportType,
        companyId: item.companyId,
        exportScope: ExportScope.EMPLOYEE,
      });

      uniqueIdsSet.add(item.externalId);
    }
  }

  return result;
};

export const convertCompaniesExportData = ( companiesForExport: ExportCompanyI[]): ExportInfoDto[] => {
  return companiesForExport.map((item: ExportCompanyI) => {
    return {
      id: item.id,
      companyId: item.companyId,
      exportScope: ExportScope.COMPANY
    }
  });
}

export enum tutorialType {
  COMPANY_INFO_TUTORIAL = "COMPANY_INFO_TUTORIAL",
  FILTERS_TUTORIAL = "FILTERS_TUTORIAL",
}
export interface TutorialsI {
  id: number;
  isViewed: boolean;
  type: tutorialType;
}


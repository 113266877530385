import { useEffect, useMemo, useState } from 'react';
import { emptyPageResponse, PageResponseI } from 'application/types';

interface SearchPropsI {
  getLocationsCallback: (search: string, page: number, size: number) => Promise<PageResponseI<string>>;
}
export const usePermitLocationSearch = ({getLocationsCallback}: SearchPropsI) => {
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isResponseReceived, setIsResponseReceived] = useState(false);
  const [locationResponse, setLocationResponse] = useState<PageResponseI<string>>(emptyPageResponse);
  const [foundLocations, setFoundLocations] = useState<string[]>([]);
  useEffect(() => {
    setIsResponseReceived(false);
  },[searchInput]);

  const locationsNotFound = useMemo(() => {
    return isResponseReceived && !isLoading && locationResponse.totalElements === 0;
  },[isResponseReceived, isLoading, locationResponse.totalElements]);

  const couldLoadMoreLocations = useMemo(() => !locationResponse.last, [locationResponse.last]);
  const totalLocationsFound = useMemo(() => locationResponse.totalElements, [locationResponse.totalElements]);

  const fetchLocations = async (page = 0, addToFounded = false) => {
    setIsLoading(true);
    const response : PageResponseI<string> = await getLocationsCallback(searchInput, page, 10);
    setLocationResponse(response);
    addToFounded ? setFoundLocations([...foundLocations, ...response.content]) : setFoundLocations(response.content);
    setIsResponseReceived(true)
    setIsLoading(false);
  }

  const clearSearch = () => {
    setSearchInput('');
    setFoundLocations([]);
    setLocationResponse(emptyPageResponse);
  }

  const loadMoreLocations = () => {
    fetchLocations(locationResponse.number + 1, true);
  }

  return {
    searchInput,
    setSearchInput,
    isLoading,
    foundLocations,
    locationsNotFound,
    couldLoadMoreLocations,
    totalLocationsFound,
    loadMoreLocations,
    clearSearch,
    fetchLocations
  }
}
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { OrganizationProfileWrapper } from "../styles";
import ProfileHeader from "./ProfileHeader";
import { useAppDispatch, useAppSelector } from "application/store";
import ContactBlock from "./ContactBlock";
import InfoBlock from "./InfoBlock";
import { handleGetTimezoneItems } from "common/dictionary";
import { EditOrganizationModal } from "../modals/EditOrganizationModal";
import ConnectToHubspot from "./TopMessages/ConnectToHubspot";
import NavBar from "./NavBar";
import { RoutesParam } from "application/routes/constants";
import CreditTable from "./CreditTable";
import ContactSalesMessage from "./TopMessages/ContactSalesMessage";
import TeamTable from "./TeamTable";
import {
  handleEditOrganization,
  handleGetOrgRequestHistory,
  handleGetOrganizationProfile,
  handleGetSubscriptions,
  handleGetTeamMembers,
} from "../store/actions";
import { closeEditModal, openEditModal, organizationsSelector } from "../store";
import { HubspotIntegrationStatus, OrganizationProfile } from "../store/types";
import { sdrProfileSelector } from "common/sdrProfile/store";
import SubscriptionTable from "./SubscriptionTable";

const TeamProfile = () => {
  const dispatch = useAppDispatch();
  const { name } = useParams();
  const requestHistory = name === RoutesParam.requestHistory;
  const subscriptionHistory = name === RoutesParam.subscriptionHistory;
  const team = name === RoutesParam.team;
  const { organizationProfile, editModalOpen } = useAppSelector(
    organizationsSelector
  );

  const { sdrProfile } = useAppSelector(sdrProfileSelector);

  const openModal = () => {
    dispatch(openEditModal());
  };
  const closeModal = () => {
    dispatch(closeEditModal());
  };

  const editOrganization = (data: OrganizationProfile) => {
    dispatch(handleEditOrganization(data));
  };

  useEffect(() => {
    if (sdrProfile.organizationId) {
      dispatch(handleGetOrganizationProfile(sdrProfile.organizationId));
      dispatch(handleGetTeamMembers(sdrProfile.organizationId));
      dispatch(handleGetSubscriptions(sdrProfile.organizationId));
      dispatch(handleGetOrgRequestHistory());
    }
  }, [sdrProfile.organizationId, dispatch]);

  useEffect(() => {
    dispatch(handleGetTimezoneItems());
  }, [dispatch]);

  return (
    <OrganizationProfileWrapper>
      {requestHistory &&
        organizationProfile.hubSpotIntegrationStatus !==
          HubspotIntegrationStatus.INTEGRATED && <ConnectToHubspot />}
      {team && organizationProfile.seats === organizationProfile.usedSeats && (
        <ContactSalesMessage />
      )}
      <ProfileHeader
        name={organizationProfile.companyName || ""}
        status={organizationProfile.activationStatus}
        type={organizationProfile.organizationType}
        onEdit={openModal}
      />
      <InfoBlock />
      <ContactBlock />
      <NavBar />
      {requestHistory && <CreditTable />}
      {team && <TeamTable />}
      {subscriptionHistory && <SubscriptionTable />}
      {editModalOpen && (
        <EditOrganizationModal
          organization={organizationProfile}
          isOpen={editModalOpen}
          handleClose={closeModal}
          onConfirm={editOrganization}
        />
      )}
    </OrganizationProfileWrapper>
  );
};

export default TeamProfile;

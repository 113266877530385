import React, { useMemo, useState } from "react";
import { Box } from '@mui/system';
import { InputStyled } from 'application/components/FormInput/styles';
import theme from 'application/theme';
import { Typography } from '@mui/material';
import { CheckboxGroupStyled } from './styles';
import { ShowLessAndMore } from 'common/companies/components';
import { ProductTypeMultiselect } from './ProductTypeMultiselect';

import { ProductBrandI, ProductNameI, ProductTypeI } from 'common/permits/types';
import { countProductTypeFilters } from 'common/permits/utils';
import { CollapseFilter } from '../CollapseFilter';
import { PageResponseI } from 'application/types';

interface ProductTypeFilterProps {
  productTypes: ProductTypeI[];
  selectedTypes: ProductTypeI[];
  onSelectedTypesChange: (selectedTypes: ProductTypeI[]) => void;
  fetchProductBrandsCallback: (typeId: number) => Promise<PageResponseI<ProductBrandI>>;
  fetchProductNamesCallback: (typeId: number, brandId: number) => Promise<PageResponseI<ProductNameI>>;
}

export const ProductTypeFilter = (
  {
    productTypes,
    selectedTypes,
    onSelectedTypesChange,
    fetchProductBrandsCallback,
    fetchProductNamesCallback
  }: ProductTypeFilterProps) => {
  const [search, setSearch] = useState("");
  const [countToDisplay, setCountToDisplay] = useState(5);

  const productTypesOptions = useMemo(() => {
    return productTypes?.length > 0 ? productTypes : selectedTypes;
  }, [productTypes, selectedTypes]);

  const handleInputChange = (e: any) => {
    setSearch(e.target.value);
  };

  const totalElementCount = useMemo(() => {
    return countProductTypeFilters(selectedTypes)
  }, [selectedTypes]);

  const filteredProductTypes: ProductTypeI[] = useMemo(() => {
    return productTypesOptions.filter((item: ProductTypeI) =>
      item?.name?.toLowerCase().includes(search.toLowerCase())
    );
  }, [search, productTypesOptions]);

  const typesToDisplay: ProductTypeI[] = useMemo(
    () => filteredProductTypes.slice(0, countToDisplay),
    [filteredProductTypes, countToDisplay]);

  const hasMore = useMemo(
    () => filteredProductTypes.length >= countToDisplay,
    [filteredProductTypes, countToDisplay]);

  const handleShowMore = (showMore: boolean) => {
    if (showMore) {
      setCountToDisplay((prev) => prev + 10);
    } else {
      setCountToDisplay(5);
    }
  };

  const handleChangeSelectedType = (id: number, value: any) => {
    const newSelectedTypes = selectedTypes.filter((e) => e.id !== id);
    if (value !== null) {
      newSelectedTypes.push(value);
    }
    onSelectedTypesChange(newSelectedTypes);
  }
  const typeIsSelected = (id: number) => {
    return selectedTypes.some(
      (selectedType: any) => selectedType.id === id
    );
  }

  const getProductTypeFromSelected = (productType: ProductTypeI) => {
    const selectedType = selectedTypes.find((e) => e.id === productType.id);
    if (selectedType) {
      return selectedType;
    }
    return productType;
  }

  return (
    <CollapseFilter label={"Product type"} count={totalElementCount}>
      {productTypes.length > 0 ? (
        <Box width={"100%"} pt={1.5} mb={1.5}>
          <InputStyled
            font_size={theme.spacing(1.75)}
            sx={{width: "100%"}}
            value={search}
            placeholder={"Search product type"}
            height={4}
            autofillcolor={theme.palette.common.grey150}
            onChange={handleInputChange}
          />
        </Box>
      ) : (
        <Typography variant="caption">{"No product types found"}</Typography>
      )}
      <CheckboxGroupStyled height={320}>
        <Box>
          {typesToDisplay.map((prodType: ProductTypeI) => (
            <ProductTypeMultiselect
              key={prodType.id}
              checked={typeIsSelected(prodType.id)}
              productType={getProductTypeFromSelected(prodType)}
              onChangeSelected={(selection) => handleChangeSelectedType(prodType.id, selection)}
              fetchProductBrandsCallback={fetchProductBrandsCallback}
              fetchProductNamesCallback={fetchProductNamesCallback}
            />
          ))}
        </Box>
        {filteredProductTypes?.length > 5 ? (
          <Box ml={1}>
            <ShowLessAndMore
              showAll={!hasMore}
              setShowAll={handleShowMore}
              fontSize={theme.spacing(1.75)}
            />
          </Box>
        ) : null}
      </CheckboxGroupStyled>
    </CollapseFilter>
  );
};

import { Box, IconButton, TextField } from "@mui/material";
import { styled } from "@mui/system";
import theme from "application/theme";
import { Link } from "react-router-dom";

export const ListWrapper = styled(Box)`
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  background-color: ${theme.palette.common.white};
  border: 1px solid ${theme.palette.common.grey400};
  border-radius: ${theme.spacing(1)};
`;

export const ListHeaderWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing(2, 2.5)};
`;

export const FormWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const FormContainer = styled("form")`
  width: 100%;
`;

export const FormItemContainer = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${theme.spacing(3)};
  margin-bottom: ${theme.spacing(2)};
`;

export const ButtonContainer = styled(Box)`
  margin-left: calc(100% - ${theme.spacing(32.5)});
  width: ${theme.spacing(32.5)};
  display: flex;
  justify-content: space-between;
`;

export const PaginationWrapper = styled(Box)`
  min-height: ${theme.spacing(4)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const OrganizationProfileWrapper = styled(Box)`
  padding: ${theme.spacing(4)};
`;

export const LeftSideHeader = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: ${theme.spacing(4)};
  cursor: pointer;
`;

export const ContactCardWrapper = styled(Box)`
  height: ${theme.spacing(5.75)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const WarningBadge = styled(Box)`
  height: ${theme.spacing(3.5)};
  padding: ${theme.spacing(0, 1.5)};
  border-radius: ${theme.spacing(2)};
`;

export const ContactLink = styled(Link)`
  color: ${theme.palette.common.blue800};
  text-decoration: none;
  display: flex;
  align-items: center;
`;

export const ContactPersonStyled = styled("span")`
  color: ${theme.palette.common.turquoise600};
  display: inline;
`;

export const TextFieldStyled = styled(TextField)`
  width: 100%;
  color: ${theme.palette.common.grey800};
  font-weight: ${theme.typography.fontWeightMedium};
  font-size: ${theme.spacing(2)};
  line-height: ${theme.spacing(2.75)};
  & .MuiOutlinedInput-root {
    height: ${theme.spacing(5)};
  }
  & .MuiInputBase-adornedEnd {
    padding-right: ${theme.spacing(1.75)};
  }
  & .MuiInputLabel-root {
    color: ${theme.palette.common.grey900};
    font-weight: ${theme.typography.fontWeightRegular};
  }
  & fieldset {
    border-radius: ${theme.spacing(1)};
  }
  & label.Mui-focused {
    color: ${theme.palette.common.grey900};
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid ${theme.palette.common.blue700};
    }
    &.Mui-fo &:hover fieldset {
      border-color: inherit;
    }
  }
  & .MuiOutlinedInput-input {
    padding: ${theme.spacing(0)};
  }
  & .MuiOutlinedInput-input::placeholder {
    font-size: ${theme.spacing(2)};
    line-height: ${theme.spacing(2.75)};
    opacity: 1;
    color: ${theme.palette.common.grey600};
  }
`;

export const ConnetToHubspotWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing(2)};
  background-color: ${theme.palette.common.blue50};
  border: 1px solid ${theme.palette.common.blue800};
  border-radius: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(4)};
`;

export const StyledIconButton = styled(IconButton)`
  cursor: pointer;
  padding: ${theme.spacing(0)};
  display: flex;
  align-items: center;
  &.MuiButtonBase-root:hover {
    background-color: transparent;
  }
`;

export const NavbarItemWrapper = styled(Box)<{ active: number }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: ${theme.spacing(2)};
  padding: ${theme.spacing(0, 0.5, 2, 0.5)};
  border-bottom: ${(props) =>
    props.active ? `2px solid ${theme.palette.common.blue700}` : "none"};
`;

export const TeamMemberNameStyled = styled("span")`
  font-weight: 500;
  color: ${theme.palette.common.turquoise600};
  display: inline;
`;

import React, { useState } from "react";

import { Button } from "application";

import { HubspotIcon } from "application/assets/HubspotIcon";
import { CsvIcon } from "application/assets/CsvIcon";

import theme from "application/theme";
import { Box } from "@mui/material";
import { ModalComponent } from "application/components/ModalComponent";
import { useAppDispatch, useAppSelector } from "application/store";
import { organizationSelector } from "common/companies/store";
import { sdrProfileSelector } from "common/sdrProfile/store";
import { organizationsSelector } from "common/organization/store";
import { HubspotIntegrationStatus } from "common/organization";
import { ConnectToHubspotModal } from "common/organization/modals/ConnectToHubspotModal";
import CreditsRequiredItem from "common/companies/modal/ExportModal/CreditsRequiredItem";
import { useCompaniesAndPermitsExport } from 'common/permits/hooks/useCompaniesAndPermitsExport';

interface ExportCompanyModalProps {
  companyId: number;
  isOpen: boolean;
  onClose: () => void;
}

export const ExportCompanyModal = ({
                                     isOpen, onClose, companyId
                                   }: ExportCompanyModalProps) => {
  const dispatch = useAppDispatch();
  const organization = useAppSelector(organizationSelector);
  const {sdrProfile} = useAppSelector(sdrProfileSelector);
  const {organizationProfile} = useAppSelector(organizationsSelector);
  const {exportCompanyToCSV, exportCompanyToHubspot} = useCompaniesAndPermitsExport();
  const [isOpenHubspot, setIsOpenHubspot] = useState(false);

  const handleCloseAllModal = () => {
    onClose();
    setIsOpenHubspot(false);
  };

  const handleDownloadCsv = () => {
    exportCompanyToCSV(companyId);
    onClose();
  };

  const handleExportToHubspot = () => {
    if (
      organizationProfile.hubSpotIntegrationStatus ===
      HubspotIntegrationStatus.INTEGRATED
    ) {
      exportCompanyToHubspot(companyId);
      onClose();
    } else {
      setIsOpenHubspot(true);
    }
  };

  return (
    <>
      <ModalComponent
        isOpen={isOpen}
        handleClose={onClose}
        padding={theme.spacing(3)}
        title={"Export company data"}
        description={"This data includes only company contact information."}
        width={475}
      >
        <Box
          mt={theme.spacing(4)}
          textAlign="center"
          display={"flex"}
          flexDirection={"column"}
          gap={theme.spacing(3)}
        >
          <Box display="flex" flexDirection="column" gap={theme.spacing(2)}>
            <Box display="flex" justifyContent="center" gap={theme.spacing(2)}>
              <Button
                label="CSV"
                height={5.5}
                width={theme.spacing(25.5)}
                type="button"
                onClick={handleDownloadCsv}
                startAdornment={<CsvIcon/>}
              />
              <Button
                label="Hubspot"
                height={5.5}
                width={theme.spacing(25.5)}
                type="button"
                onClick={handleExportToHubspot}
                startAdornment={<HubspotIcon/>}
              />
            </Box>
            <Box display="flex" flexDirection="column" alignItems="start">
              <CreditsRequiredItem label={'Required company credits balance:'} value={1}/>
            </Box>
          </Box>
        </Box>
      </ModalComponent>
      <ConnectToHubspotModal
        isOpen={isOpenHubspot}
        handleClose={handleCloseAllModal}
        onConfirmUrl={organizationProfile.hubspotAuthUrl}
      />
    </>
  );
};

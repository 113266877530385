import { createSlice } from "@reduxjs/toolkit";
import { store } from "application/store";
import { SdrProfileI } from "./types";

export const initialState = {
  sdrProfile: {
    firstName: "-",
  } as SdrProfileI,
};

export const sdrSlice = createSlice({
  name: "sdr",
  initialState,
  reducers: {
    setSdrProfile: (state, action) => {
      state.sdrProfile = action.payload;
    },
    cleanSdrsStore: (state) => {
      state.sdrProfile = initialState.sdrProfile;
    },
  },
});

export default sdrSlice.reducer;

export const sdrProfileSelector = () => store.getState().sdr;

export const { setSdrProfile } = sdrSlice.actions;
export const { cleanSdrsStore } = sdrSlice.actions;

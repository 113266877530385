import { useAppDispatch, useAppSelector } from 'application/store';
import { setExportScope, setExportStage, setNumberOfExportedCompanies } from 'common/largeBulkExport/store';
import { ExportStage, LargeBulkExportScope } from 'common/largeBulkExport/store/types';
import { useSdrProfile } from 'common/sdrProfile/hooks/useSdrProfile';
import {
  handleExportCompaniesByPermitFilters,
  handleExportCompaniesOnMap,
  handleExportPermits,
  handleExportPermitsFromMap,
  handleGetNumberOfExportedCompaniesByPermitFilters,
  handleGetNumberOfExportedCompaniesOnMapByPermitFilters,
  handleGetNumberOfExportedPermitsByCompanyAndPermitFilters,
  handleGetNumberOfExportedPermitsOnMapByCompanyAndPermitFilters
} from 'common/largeBulkExport/store/actions';
import { ExportTo } from 'common/companies/types';
import {
  foundCompaniesOnMapSelector,
  foundCompaniesSelector,
  foundPermitsOnMapSelector,
  foundPermitsSelector,
  setFilterFormData
} from 'common/permits/store';
import { usePermitsWithCompaniesSearch } from 'common/permits/hooks/usePermitsWithCompaniesSearch';
import {
  handleGetCompaniesCoordinates,
  handleSearchCompaniesByPermitFilters
} from 'common/permits/store/actions';
import { cloneDeep } from 'lodash';

export const LARGE_BULK_EXPORT_LIMIT = 5000;
export const useLargeBulkExportNew = () => {
  const dispatch = useAppDispatch();
  const {getSdrProfileInfo} = useSdrProfile();

  const { totalElements: companiesFound} = useAppSelector( foundCompaniesSelector );
  const { totalElements: permitsFound} = useAppSelector( foundPermitsSelector );
  const { totalElements: permitsOnMapFound} = useAppSelector( foundPermitsOnMapSelector );
  const { totalElements: companiesOnMapFound} = useAppSelector( foundCompaniesOnMapSelector );

  const {filterFormData} = usePermitsWithCompaniesSearch();
  const {availableCredits: {companyDataCredits}} = useSdrProfile();
  const {
    exportStage,
    alreadyExportedCompaniesAmount,
    exportScope,
    alreadyExportedPermitsAmount,
  } = useAppSelector((state) => state.largeBulkExport);

  const isPermitExport = exportScope === LargeBulkExportScope.PERMITS;
  const isCompanyExport = exportScope === LargeBulkExportScope.COMPANIES;
  const isCompaniesByCoordinatesExport = exportScope === LargeBulkExportScope.COMPANIES_BY_COORDINATES;
  const isPermitsByCoordinatesExport = exportScope === LargeBulkExportScope.PERMITS_BY_COORDINATES;

  const countOfCompaniesForExport = Math.min(companiesFound, companyDataCredits, LARGE_BULK_EXPORT_LIMIT);
  const countOfPermitsForExport = Math.min(permitsFound, companyDataCredits, LARGE_BULK_EXPORT_LIMIT);
  const countOfCompaniesOnMapForExport = Math.min(companiesOnMapFound, companyDataCredits, LARGE_BULK_EXPORT_LIMIT);
  const countOfPermitsNoMapForExport = Math.min(permitsOnMapFound, companyDataCredits, LARGE_BULK_EXPORT_LIMIT);

  const getNumberOfExportedCompanies = () => {
    const pageRequest = {
      page: 0,
      size: countOfCompaniesForExport,
    }
    return dispatch(handleGetNumberOfExportedCompaniesByPermitFilters(filterFormData, pageRequest));
  }
  const getNumberOfExportedCompaniesOnMap = () => {
    const pageRequest = {
      page: 0,
      size: countOfCompaniesOnMapForExport,
    }
    return dispatch(handleGetNumberOfExportedCompaniesOnMapByPermitFilters(filterFormData, pageRequest));
  }

  const getNumberOfExportedPermits = () => {
    const pageRequest = {
      page: 0,
      size: countOfPermitsForExport,
    }
    return dispatch(handleGetNumberOfExportedPermitsByCompanyAndPermitFilters(filterFormData, pageRequest));
  }

  const getNumberOfExportedPermitsOnMap = () => {
    const pageRequest = {
      page: 0,
      size: countOfPermitsNoMapForExport,
    }
    return dispatch(handleGetNumberOfExportedPermitsOnMapByCompanyAndPermitFilters(filterFormData, pageRequest));
  }

  const getNumberOfExported = async (exportScope?: LargeBulkExportScope | null) => {
    switch (exportScope) {
      case LargeBulkExportScope.COMPANIES:
        return await getNumberOfExportedCompanies();
      case LargeBulkExportScope.PERMITS:
        return await getNumberOfExportedPermits();
      case LargeBulkExportScope.COMPANIES_BY_COORDINATES:
        return await getNumberOfExportedCompaniesOnMap();
      case LargeBulkExportScope.PERMITS_BY_COORDINATES:
        return await getNumberOfExportedPermitsOnMap();
    }
  }

  const openConfirmExportModal = (providedExportScope?: LargeBulkExportScope) => {
    const scope = providedExportScope || exportScope;
    getNumberOfExported(scope).then(result => {
      if (result) {
        updateExportStage(ExportStage.CONFIRM);
      }
    });
    return;
  }

  const skipExportedAndUpdateFilters = () => {
    const {companyFilter} = filterFormData;
    const newCompanyFilter = {
      ...companyFilter,
      skipExported: true,
    }
    const newFilterFormData = {
      ...filterFormData,
      companyFilter: newCompanyFilter,
    }
    dispatch(setFilterFormData(cloneDeep(newFilterFormData)))
    dispatch(handleSearchCompaniesByPermitFilters(newFilterFormData, 0, 20, true ));
    dispatch(setNumberOfExportedCompanies(0));
  }

  const skipExportedCompaniesOnMapAndUpdateFilters = () => {
    const {companyFilter} = filterFormData;
    const newCompanyFilter = {
      ...companyFilter,
      skipExported: true,
    }
    const newFilterFormData = {
      ...filterFormData,
      companyFilter: newCompanyFilter,
    }
    dispatch(setFilterFormData(cloneDeep(newFilterFormData)))
    dispatch(handleGetCompaniesCoordinates(newFilterFormData, true ));
    dispatch(setNumberOfExportedCompanies(0));
  }

  const exportCompanies = (exportTo: ExportTo) => {
    const pageRequest = {
      page: 0,
      size: countOfCompaniesForExport,
    }
    dispatch(handleExportCompaniesByPermitFilters(filterFormData, pageRequest, exportTo))
      .then(result => {
        if (result) {
          updateExportStage(ExportStage.PROCESSING);
          getSdrProfileInfo();
        }
      });
  }

  const exportCompaniesOnMap = (exportTo: ExportTo) => {
    const pageRequest = {
      page: 0,
      size: countOfCompaniesOnMapForExport,
    }
    dispatch(handleExportCompaniesOnMap(filterFormData, pageRequest, exportTo))
      .then(result => {
        if (result) {
          updateExportStage(ExportStage.PROCESSING);
          getSdrProfileInfo();
        }
      });
  }

  const exportPermits = () => {
    const pageRequest = {
      page: 0,
      size: countOfPermitsForExport,
    }
    dispatch(handleExportPermits(filterFormData, pageRequest))
      .then(result => {
        if (result) {
          updateExportStage(ExportStage.PROCESSING);
          getSdrProfileInfo();
        }
      });
  }
  const exportPermitsOnMap = () => {
    const pageRequest = {
      page: 0,
      size: countOfPermitsNoMapForExport,
    }
    dispatch(handleExportPermitsFromMap(filterFormData, pageRequest))
      .then(result => {
        if (result) {
          updateExportStage(ExportStage.PROCESSING);
          getSdrProfileInfo();
        }
      });
  }

  const getCountForExport = (bulkExportScope: LargeBulkExportScope) => {
    switch (bulkExportScope) {
      case LargeBulkExportScope.COMPANIES:
        return countOfCompaniesForExport;
      case LargeBulkExportScope.PERMITS:
        return countOfPermitsForExport;
      case LargeBulkExportScope.COMPANIES_BY_COORDINATES:
        return countOfCompaniesOnMapForExport;
      case LargeBulkExportScope.PERMITS_BY_COORDINATES:
        return countOfPermitsNoMapForExport;
      default:
        return 0;
    }
  }

  const getFoundResultsForExport = (bulkExportScope: LargeBulkExportScope) => {
    switch (bulkExportScope) {
      case LargeBulkExportScope.COMPANIES:
        return companiesFound;
      case LargeBulkExportScope.PERMITS:
        return permitsFound;
      case LargeBulkExportScope.COMPANIES_BY_COORDINATES:
        return companiesOnMapFound;
      case LargeBulkExportScope.PERMITS_BY_COORDINATES:
        return permitsOnMapFound;
      default:
        return 0;
    }
  }
  const startBulkExport = async (bulkExportScope: LargeBulkExportScope) => {
    dispatch(setExportScope(bulkExportScope));
    if (companyDataCredits === 0) {
      updateExportStage(ExportStage.NO_CREDITS);
      return;
    }
    const countForExport = getFoundResultsForExport(bulkExportScope);
    if (countForExport > LARGE_BULK_EXPORT_LIMIT || countForExport > companyDataCredits) {
      updateExportStage(ExportStage.START);
      return;
    }
    openConfirmExportModal(bulkExportScope);
  }

  const updateExportStage = (exportStage: ExportStage | null) => {
    dispatch(setExportStage(exportStage));
  }

  return {
    alreadyExportedCompaniesAmount,
    alreadyExportedPermitsAmount,
    companiesFound,
    permitsFound,
    companiesOnMapFound,
    permitsOnMapFound,
    countOfCompaniesForExport,
    countOfPermitsForExport,
    countOfCompaniesOnMapForExport,
    countOfPermitsNoMapForExport,
    exportStage,
    startBulkExport,
    updateExportStage,
    openConfirmExportModal,
    skipExportedAndUpdateFilters,
    exportCompanies,
    exportPermits,
    isPermitExport,
    isCompanyExport,
    isCompaniesByCoordinatesExport,
    isPermitsByCoordinatesExport,
    exportPermitsOnMap,
    exportCompaniesOnMap,
    skipExportedCompaniesOnMapAndUpdateFilters,
    exportScope,
  }
}
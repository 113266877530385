import { StorageFields } from 'application';

export const companiesListViewModeService = {
  saveIsExtendedView(isExtendedView: boolean) {
    localStorage.setItem(StorageFields.companiesListView, JSON.stringify(isExtendedView));
  },
  isExtendedView(): boolean {
    const value = localStorage.getItem(StorageFields.companiesListView);
    if(!value) return true;
    return JSON.parse(value);
  },
}
import React from 'react';
import { Box } from '@mui/system';
import { InfoItemsWrapper } from './styles';
import { PermitExecutorI } from 'common/permits/types';
import { PermitInfoItem } from './PermitInfoItem';
import { BuildingIcon, DocIcon, GlobeIcon, MarkerIcon, UserIcon, UserIconSquare } from 'application/assets';

interface PropsI {
  executor?: PermitExecutorI;
}

export const ExecutorInfo = ({executor}: PropsI) => {
  return (
    <Box display={'flex'}>
      <InfoItemsWrapper>
        <PermitInfoItem
          display={true}
          icon={<DocIcon/>}
          title={'License'}
          value={executor?.license || '-'}
        />
        <PermitInfoItem
          display={true}
          icon={<UserIconSquare/>}
          title={executor?.companyBusinessName || '-'}
        />
        <PermitInfoItem
          display={true}
          icon={<UserIcon/>}
          title={executor?.name || '-'}
        />
      </InfoItemsWrapper>
      <InfoItemsWrapper>
        <PermitInfoItem
          display={true}
          icon={<GlobeIcon/>}
          title={executor?.state || '-'}
        />
        <PermitInfoItem
          display={true}
          icon={<BuildingIcon/>}
          title={executor?.city || '-'}
        />
        <PermitInfoItem
          display={true}
          icon={<MarkerIcon/>}
          title={executor?.zipcode || '-'}
        />
      </InfoItemsWrapper>
    </Box>
  )
}

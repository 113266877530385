import React from "react";

import theme from "application/theme";

import { CompanyI } from "integration/api/companies/types";

import { BadgeCompanyType } from "common/companies/components";

import { Box } from "@mui/system";
import { Typography } from "@mui/material";

import {
  CompanyInfoWrapper,
  GeneralInfoWrapper,
  RatingNumberWrapper,
  CompanyInfoItemWrapper,
} from "../styles";
import {
  RatingWrapper,
  TypographyStyled,
} from "./styles";
import {
  labelByType,
  showReputationScore,
} from "common/companies/companiesList/utils";

interface PropsI {
  company: CompanyI;
}

const CompanyInfoHeader = ({ company }: PropsI) => {
  return (
    <CompanyInfoItemWrapper p={theme.spacing(2, 4)}>
      <CompanyInfoWrapper>
        <GeneralInfoWrapper>
          {company?.businessType && (
            <BadgeCompanyType businessType={company.businessType} />
          )}
          <TypographyStyled variant="h4" noWrap>
            {company.businessName}
          </TypographyStyled>
        </GeneralInfoWrapper>
        <RatingWrapper>
          <Typography color={theme.palette.common.grey900} variant="button">
            {showReputationScore(company.reputationScore)}
          </Typography>
          <RatingNumberWrapper>
            {company.reputationScore / 10}
          </RatingNumberWrapper>
        </RatingWrapper>
      </CompanyInfoWrapper>
      <Box
        display={"flex"}
        alignItems={"center"}
        width="100%"
        justifyContent={"space-between"}
      >
        {company.serviceType ? (
          <Typography color={theme.palette.common.blue800} variant="body2">
            {labelByType(company.serviceType)}
          </Typography>
        ) : (
          <></>
        )}
        {/* <ExportedLabel>
          <StyledDot />
          <Typography variant="overline" color={theme.palette.common.green900}>
            Partially Exported
          </Typography>
        </ExportedLabel> */}
        {/* TODO */}
      </Box>
    </CompanyInfoItemWrapper>
  );
};

export default CompanyInfoHeader;

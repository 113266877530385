import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "application/store";
import {
  handleDeleteSavedSearch,
  handleGetSavedSearchById,
  handleGetSavedSearches,
} from "../store/actions";

import { Box, Collapse, Typography } from "@mui/material";

import theme from "application/theme";
import { FilterLabelWrapper, SavedFiltersWrapper } from "./styles";

import { CollapseLabel } from "./components";
import { ShowLessAndMore } from "../components";
import {
  companySelector,
  filterInitialValues,
  setSelectedSearch,
} from "../store";
import { StarIcon } from "../assets/StarIcon";
import { useFormContext } from "react-hook-form";
import { BinIcon } from "application/assets/BinIcon";

const SavedFiltersCollapse = ({
  label,
  scrollTo,
}: {
  label: string;
  scrollTo: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { reset } = useFormContext();
  const [expanded, setExpanded] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const { savedSearches, selectedSavedSearch } =
    useAppSelector(companySelector);

  useEffect(() => {
    expanded && dispatch(handleGetSavedSearches());
  }, [expanded, dispatch]);

  const displayData = useMemo(
    () => (showAll ? savedSearches : savedSearches.slice(0, 5)),
    [savedSearches, showAll]
  );

  const handleDeleteSavedFilter = (id: number) => {
    dispatch(handleDeleteSavedSearch(id));
  };

  const handleSelectSavedFilter = (id: number) => {
    if (selectedSavedSearch.id === id) {
      reset(filterInitialValues);
      dispatch(setSelectedSearch({}));
    } else {
      scrollTo();
      dispatch(handleGetSavedSearchById(id));
    }
  };

  return (
    <Box width={"100%"}>
      <CollapseLabel
        label={label}
        expanded={expanded}
        setExpanded={setExpanded}
      />
      <Collapse in={expanded}>
        <Box pt={1.5}>
          {displayData?.length ? (
            displayData.map((item: any) => (
              <SavedFiltersWrapper
                key={item.id}
                sx={{
                  background:
                    selectedSavedSearch.id === item.id
                      ? theme.palette.common.grey300
                      : "transparent",
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  gap={1}
                  flexGrow={1}
                  onClick={() => handleSelectSavedFilter(item.id)}
                >
                  <StarIcon />
                  <FilterLabelWrapper>{item.name || "-"}</FilterLabelWrapper>
                </Box>
                <Box
                  onClick={() => handleDeleteSavedFilter(item.id)}
                  className="delete-icon"
                >
                  <BinIcon />
                </Box>
              </SavedFiltersWrapper>
            ))
          ) : (
            <Typography variant="caption">No saved filters</Typography>
          )}
        </Box>
        {savedSearches?.length > 5 ? (
          <ShowLessAndMore
            showAll={showAll}
            setShowAll={setShowAll}
            fontSize={theme.spacing(1.75)}
          />
        ) : null}
      </Collapse>
    </Box>
  );
};

export default SavedFiltersCollapse;

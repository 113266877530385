import React from "react";
import { IconProps } from './types';
import theme from 'application/theme';
export const UncheckIcon: React.FC<IconProps> = ({ color = theme.palette.common.blue800, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
         style={{padding: '2px'}}>
      <rect width="100%" height="100%" fill={color} rx="2"/>
      <path d="M5.83398 10.8334V9.16675H14.1673V10.8334H5.83398Z" fill="#F7F7F7"/>
    </svg>
  );
};


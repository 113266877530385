import { ExportTo } from 'common/companies/types';

export enum ExportStage {
  START = 'START',
  CONFIRM = 'CONFIRM',
  PROCESSING = 'PROCESSING',
  NO_CREDITS = 'NO_CREDITS',
  BACK_TO_SEARCH = 'BACK_TO_SEARCH',
}
export enum LargeBulkExportScope {
  COMPANIES = 'COMPANIES',
  PERMITS = 'PERMITS',
  COMPANIES_BY_COORDINATES = 'COMPANIES_BY_COORDINATES',
  PERMITS_BY_COORDINATES = 'PERMITS_BY_COORDINATES',
}

export enum BulkExportStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED',
  EXPIRED = 'EXPIRED'
}

export interface BulkProcessI {
  id: number;
  s3Url: string;
  exportStatus: BulkExportStatus;
  fullName: string;
  email: string;
  numberOfRecords: number;
  startedAt: string;
  finishedAt: string | null;
  exportTo: ExportTo;
}
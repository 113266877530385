import React, {useEffect, useMemo, useState} from "react";
import { Button } from "application";
import { Box, Typography } from "@mui/material";
import theme from "application/theme";
import { ContactPersonStyled } from "../styles";
import { ModalComponent } from "application/components/ModalComponent";
import { AddCredits } from "application/assets/AddCredits";
import { useAppDispatch, useAppSelector } from "application/store";
import { sdrProfileSelector } from "common/sdrProfile/store";
import { handleUpdateSdrCredits } from "../store/actions";
import { organizationsSelector } from "../store";
import QuantityPickerForTeamMembers from "../QuantityPickerForTeamMembers";
import { errorTextHelper } from "../utils";

export type CreditsErrorType = {
  toMuchPhoneCredits: boolean;
  toMuchEmailCredits: boolean;
  toLessEmailCredits: boolean;
  toLessPhoneCredits: boolean;
  toMuchCompanyDataCredits: boolean;
  toLessCompanyDataCredits: boolean;
};

const defaultErrorState: CreditsErrorType = {
  toMuchEmailCredits: false,
  toMuchPhoneCredits: false,
  toLessEmailCredits: false,
  toLessPhoneCredits: false,
  toMuchCompanyDataCredits: false,
  toLessCompanyDataCredits: false,
}

export type CreditsType = {
    emailCredits: number;
    phoneCredits: number;
    companyDataCredits: number;
}
type creditsFieldType = "emailCredits" | "phoneCredits" | "companyDataCredits";

export const AddCreditsForTeamMember = ({
  isOpen,
  handleClose,
  sdrId,
}: {
  isOpen: boolean;
  handleClose: () => void;
  sdrId: number | null;
}) => {
  const [updatedSdrCredits, setUpdatedSdrCredits] = useState<CreditsType>({
    emailCredits: 0,
    phoneCredits: 0,
    companyDataCredits: 0

  });
  const { sdrProfile } = useAppSelector(sdrProfileSelector);
  const { teamMembers, organizationProfile } = useAppSelector(
    organizationsSelector
  );
  const { organizationStructureAccessPermitted } = organizationProfile;
  const [error, setError] = useState<CreditsErrorType>(defaultErrorState);
  const currentSdr = useMemo(() => {
    return teamMembers.filter((e) => e.id === sdrId)[0];
  }, [teamMembers, sdrId]);

  useEffect(() => {
    setUpdatedSdrCredits({
        emailCredits: currentSdr?.emailCredits || 0,
        phoneCredits: currentSdr?.phoneCredits || 0,
        companyDataCredits: currentSdr?.companyDataCredits || 0
    });
  }, [currentSdr]);

  const usedSdrCredits = useMemo(() => {
    return {
        emailCredits: currentSdr?.usedEmailCredits || 0,
        phoneCredits: currentSdr?.usedPhoneCredits || 0,
        companyDataCredits: currentSdr?.usedCompanyDataCredits || 0
    }
  },[currentSdr]);

  const handleUpdateEmailCredits = (value: number) => {
    setUpdatedSdrCredits({
        ...updatedSdrCredits,
        emailCredits: value
    })
  }
  const handleUpdatePhoneCredits = (value: number) => {
    setUpdatedSdrCredits({
      ...updatedSdrCredits,
      phoneCredits: value
    })
  }
  const handleUpdateCompanyDataCredits = (value: number) => {
    setUpdatedSdrCredits({
      ...updatedSdrCredits,
      companyDataCredits: value
    })
  }

  const dispatch = useAppDispatch();

  const closeModal = () => {
    handleClose();
    setError(defaultErrorState);
  };

  const totalTeamMembersCredits = useMemo(() => {
    const initial = {phoneCredits: 0, emailCredits: 0, companyDataCredits: 0};
    return teamMembers.reduce((acc, e) => {
      acc.phoneCredits += e.phoneCredits;
      acc.emailCredits += e.emailCredits;
      acc.companyDataCredits += e.companyDataCredits;
      return acc;
    }, initial);
  }, [teamMembers]);

  const organizationCredits = useMemo(() => {
    return {
        phoneCredits: organizationProfile?.phoneCredits || 0,
        emailCredits: organizationProfile?.emailCredits  || 0,
        companyDataCredits: organizationProfile?.companyDataCredits  || 0,
    }
  },[organizationProfile]);

  const availableCredits = useMemo(() => {
    return {
        phoneCredits: organizationCredits.phoneCredits - totalTeamMembersCredits.phoneCredits,
        emailCredits: organizationCredits.emailCredits - totalTeamMembersCredits.emailCredits,
        companyDataCredits: organizationCredits.companyDataCredits - totalTeamMembersCredits.companyDataCredits,
    }
  }, [totalTeamMembersCredits, organizationCredits]);

  const checkContainsError = (errors: CreditsErrorType) : boolean => {
    return errors.toLessCompanyDataCredits || errors.toMuchCompanyDataCredits
        || errors.toLessEmailCredits || errors.toMuchEmailCredits
        || errors.toLessPhoneCredits || errors.toMuchPhoneCredits;
  }

  const containsError = useMemo(() => {
    return checkContainsError(error);
  }, [error, checkContainsError]);

  useEffect(() => {
    if(containsError) {
      const validatedCredits = validateCredits();
      setError(validatedCredits);
    }
  }, [containsError, updatedSdrCredits]);


  const validateCredits = () : CreditsErrorType => {
    const initialSdrCredits = {
        emailCredits: currentSdr.emailCredits,
        phoneCredits: currentSdr.phoneCredits,
        companyDataCredits: currentSdr.companyDataCredits
    }
    return {
      toLessEmailCredits: checkTooLessCredits('emailCredits', updatedSdrCredits, usedSdrCredits),
      toLessCompanyDataCredits: checkTooLessCredits('companyDataCredits', updatedSdrCredits, usedSdrCredits),
      toLessPhoneCredits: checkTooLessCredits('phoneCredits', updatedSdrCredits, usedSdrCredits),
      toMuchEmailCredits: checkTooMuchCredits('emailCredits', updatedSdrCredits, initialSdrCredits, availableCredits),
      toMuchPhoneCredits: checkTooMuchCredits('phoneCredits', updatedSdrCredits, initialSdrCredits, availableCredits),
      toMuchCompanyDataCredits: checkTooMuchCredits('companyDataCredits', updatedSdrCredits, initialSdrCredits, availableCredits),
    }

  }
  const checkTooLessCredits = (field: creditsFieldType, newCredits: CreditsType, usedSdrCredits: CreditsType): boolean => {
    return newCredits[field] < usedSdrCredits[field];
  }
  const checkTooMuchCredits = (field: creditsFieldType, newCredits: CreditsType, initialSdrCredits: CreditsType, availableCredits: CreditsType) => {
    return newCredits[field] > initialSdrCredits[field] + availableCredits[field];
  }

  const prepareCreditsToUpdate = (credits: CreditsType) => {
    return organizationStructureAccessPermitted ? updatedSdrCredits :
        { companyDataCredits : updatedSdrCredits.companyDataCredits}
  }

  const onSubmit = async () => {
    const validatedCredits = validateCredits();
    setError(validatedCredits);
    const containsError = checkContainsError(validatedCredits);
    if (!containsError && sdrId) {
      closeModal();
      const creditsToUpdate = organizationStructureAccessPermitted ? updatedSdrCredits : { companyDataCredits : updatedSdrCredits.companyDataCredits}
      dispatch(
          handleUpdateSdrCredits(
              [{sdrId, ...prepareCreditsToUpdate(updatedSdrCredits)}],
              sdrProfile.organizationId
          )
      );
    }
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={closeModal}
      padding={3}
      width={500}
      key={sdrProfile.organizationId}
    >
      <Box textAlign="center" display="flex" flexDirection="column">
        <Box
          display="flex"
          justifyContent="center"
          width={"100%"}
        >
          <AddCredits />
        </Box>
        <Box mt={4} mb={1.5}>
          <Typography variant="h5" color={theme.palette.common.grey800}>
            Edit credits for&nbsp;
            <ContactPersonStyled>
              {`${currentSdr.firstName || ''} ${currentSdr.lastName || ''}`}
            </ContactPersonStyled>
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          gap={theme.spacing(4)}
          mb={1.5}
        >
          <QuantityPickerForTeamMembers
              label={"Available company credits"}
              setValue={handleUpdateCompanyDataCredits}
              error={
                  error.toMuchCompanyDataCredits ||
                  error.toLessCompanyDataCredits
              }
              availableCredits={availableCredits.companyDataCredits}
              min={currentSdr.usedCompanyDataCredits}
              max={999999}
              defaultValue={currentSdr.companyDataCredits}
          />
          {
            organizationStructureAccessPermitted ? <>
              <QuantityPickerForTeamMembers
                  label={"Available email credits"}
                  setValue={handleUpdateEmailCredits}
                  error={
                      error.toMuchEmailCredits ||
                      error.toLessEmailCredits
                  }
                  availableCredits={availableCredits.emailCredits}
                  min={currentSdr.usedEmailCredits}
                  defaultValue={currentSdr.emailCredits}
              />
              <QuantityPickerForTeamMembers
                  label={"Available phone credits"}
                  setValue={handleUpdatePhoneCredits}
                  error={
                      error.toMuchPhoneCredits ||
                      error.toLessPhoneCredits
                  }
                  availableCredits={availableCredits.phoneCredits}
                  min={currentSdr.usedPhoneCredits}
                  defaultValue={currentSdr.phoneCredits}
              /></> : null
          }

        </Box>
        {containsError ? (
          <Typography variant="caption" color={theme.palette.common.red600}>
            {errorTextHelper(error)}
          </Typography>
        ) : (
          <></>
        )}
        <Typography variant="caption" color={theme.palette.common.grey700}>
          Then contact person:&nbsp;
          <ContactPersonStyled>
            {organizationProfile.contactPerson}
          </ContactPersonStyled>
          &nbsp;will get email notification about it.
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          gap={theme.spacing(2)}
          mt={2.5}
        >
          <Button
            label="Cancel"
            height={5.5}
            width={120}
            type="button"
            onClick={closeModal}
          />
          <Button
            label="Apply"
            disabled={containsError}
            height={5.5}
            width={120}
            type="submit"
            onClick={onSubmit}
          />
        </Box>
      </Box>
    </ModalComponent>
  );
};

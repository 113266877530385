import React from 'react';
import { Box } from "@mui/system";
import theme from "application/theme";
import { StyledButton } from "application/components/Button/styles";
import { useNavigate, useParams } from "react-router-dom";
import { PathNames } from "application/routes";
import { ExportScopePath, ExportTab } from '../types';


export const ExportScopeToggler = () => {
  const { name, scope } = useParams();
  const navigate = useNavigate();
  const switchToCompany = () => {
    navigate(`${PathNames.bulkExport}/${ExportScopePath.COMPANIES}/${name}`)
  }
  const switchToEmployees = () => {
    const exportTable = name === ExportTab.BULK_HISTORY ? ExportTab.NEW : name;
    navigate(`${PathNames.bulkExport}/${ExportScopePath.EMPLOYEES}/${exportTable}`)

  }
  const switchToPermits = () => {
    navigate(`${PathNames.bulkExport}/${ExportScopePath.PERMITS}/${name}`)
  }
  return (
    <Box display={'flex'}>
      <StyledButton
        onClick={switchToCompany}
        className={scope === ExportScopePath.COMPANIES ? "selected" : ""}
        sx={{
        borderRadius: theme.spacing(1, 0, 0, 1),
      }} type="button">Company data</StyledButton>
      <StyledButton
        className={scope === ExportScopePath.EMPLOYEES ? "selected" : ""}
        onClick={switchToEmployees}
        sx={{
          borderLeft: 'none',
          borderRight: 'none',
          borderRadius: theme.spacing(0),
        }} type="button">Employees data</StyledButton>
      <StyledButton
        className={scope === ExportScopePath.PERMITS ? "selected" : ""}
        onClick={switchToPermits}
        sx={{
          borderRadius: theme.spacing(0, 1, 1, 0),
        }} type="button">Permit data</StyledButton>
    </Box>
  );
};
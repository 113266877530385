import theme from "application/theme";

import { Autocomplete, Box, styled } from "@mui/material";
import { Search } from '@mui/icons-material';
import { Typography } from "@mui/material";
export const StyledAutocomplete = styled(Autocomplete)`
  width: ${theme.spacing(65)};
  & .MuiOutlinedInput-root .MuiAutocomplete-input {
    font-size: ${theme.spacing(1.75)};
    padding: 0;
    height: ${theme.spacing(2.5)};
  }
  & .MuiOutlinedInput-root {
    padding: ${theme.spacing(1.25, 1.75)} !important;
  }
  & .MuiAutocomplete-popupIndicator {
    display: none;
  }
  & fieldset {
    border-radius: ${theme.spacing(1)};
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: 1px solid ${theme.palette.common.blue700} !important;
    }
    &.Mui-fo &:hover fieldset {
      border-color: ${theme.palette.common.grey400};
    }
  }
  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${theme.palette.common.grey400};
  }
  & .MuiOutlinedInput-input::placeholder {
    font-size: ${theme.spacing(1.75)};
    line-height: ${theme.spacing(2.75)};
    opacity: 1;
    color: ${theme.palette.common.grey600};
  }
`;

export const AutosuggestItemStyled = styled("li")`
  padding: ${theme.spacing(1, 1.75)};
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: ${theme.spacing(1)};
  &:hover {
    background-color: ${theme.palette.common.grey100};
  }
`;

export const ListboxWrapper = styled(Box)`
  height: 100%;
  border-radius: ${theme.spacing(1)};
  padding: 0 !important;
  max-height: 100%;
`;

export const TotalResultsStyled = styled(Typography)`
  font-size: ${theme.spacing( 1.75)};
  cursor: pointer;
  color: ${theme.palette.common.blue700};
  line-height: 140%;
  padding: ${theme.spacing(1, 2)}
`;

export const SearchIconStyled = styled(Search)`
  color: ${theme.palette.common.grey600};
`

export const BusinessNameStyled = styled(Typography)`
  color: ${theme.palette.common.grey800};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const CompanyWebsiteStyled = styled(BusinessNameStyled)`
  color: ${theme.palette.common.grey600};
`

export const AutoSuggestLoaderWrapper = styled(Box)`
  width: 100%;
  height: ${theme.spacing(12)};
  position: relative;
`

export const TotalResultsLoaderWrapper = styled(Box)`
  width: ${theme.spacing(8)};
  height: ${theme.spacing(3)};
  position: relative;
  display: flex;
`

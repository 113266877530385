import React from "react";
import { StyledProfileDetailsItem } from "./styles";
import { Box } from "@mui/system";
import ContactCard from "../../../application/components/ContactCard";

interface Props {
  fullName: string;
  email: string;
  notificationEnabled: boolean;
}

export const ProfileDetails = ({
  fullName,
  email,
  notificationEnabled,
}: Props) => {
  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      <StyledProfileDetailsItem>
        <ContactCard name="Full Name" label={fullName} />
      </StyledProfileDetailsItem>
      <StyledProfileDetailsItem>
        <ContactCard name="Email" label={email} withIcon />
      </StyledProfileDetailsItem>
      <StyledProfileDetailsItem>
        <ContactCard
          name="Email Notifications"
          label={notificationEnabled ? "Enabled" : "Disabled"}
        />
      </StyledProfileDetailsItem>
    </Box>
  );
};

import { useAppDispatch, useAppSelector } from 'application/store';
import { handleFetchCompaniesBulkExportProcesses, handleFetchPermitsBulkExportProcesses } from '../store/actions';
import { SortOrder } from 'application/types';

export const useLargeBulkExportInfo = () => {
  const dispatch = useAppDispatch();
  const {bulkExportProcesses, permitBulkExportProcesses} = useAppSelector(state => state.largeBulkExport);
  const fetchCompaniesBulkExportProcesses = (organizationId: number, page: number, showLoader = true) => {
    const pageRequest = {
      page,
      size: 10,
      sortBy: 'startedAt',
      sortOrder: SortOrder.DESC,
    }
    dispatch(handleFetchCompaniesBulkExportProcesses(organizationId, pageRequest, showLoader));
  }

  const fetchPermitsBulkExportProcesses = (organizationId: number, page: number, showLoader = true) => {
    const pageRequest = {
      page,
      size: 10,
      sortBy: 'startedAt',
      sortOrder: SortOrder.DESC,
    }
    dispatch(handleFetchPermitsBulkExportProcesses(organizationId, pageRequest, showLoader));
  }

  return {
    companyBulkExportProcesses: bulkExportProcesses,
    permitBulkExportProcesses,
    fetchCompaniesBulkExportProcesses,
    fetchPermitsBulkExportProcesses,
  }
}
import React from "react";
import { IconProps } from './types';
import theme from 'application/theme';

export const PermitDescriptionIcon: React.FC<IconProps> = ({color = theme.palette.common.grey250, ...rest}) => {
  return (
    <svg width="60" height="66" viewBox="0 0 60 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_ddi_6178_19695)">
        <path
          d="M39.9999 11.2246H19.9999C18.5272 11.2246 17.3333 12.4185 17.3333 13.8913V40.5579C17.3333 42.0307 18.5272 43.2246 19.9999 43.2246H39.9999C41.4727 43.2246 42.6666 42.0307 42.6666 40.5579V13.8913C42.6666 12.4185 41.4727 11.2246 39.9999 11.2246Z"
          fill="url(#pattern0)"/>
        <path
          d="M39.9999 11.2246H19.9999C18.5272 11.2246 17.3333 12.4185 17.3333 13.8913V40.5579C17.3333 42.0307 18.5272 43.2246 19.9999 43.2246H39.9999C41.4727 43.2246 42.6666 42.0307 42.6666 40.5579V13.8913C42.6666 12.4185 41.4727 11.2246 39.9999 11.2246Z"
          fill="white"/>
      </g>
      <path
        d="M38.3334 16.5581H21.6667C21.1145 16.5581 20.6667 17.0058 20.6667 17.5581C20.6667 18.1104 21.1145 18.5581 21.6667 18.5581H38.3334C38.8857 18.5581 39.3334 18.1104 39.3334 17.5581C39.3334 17.0058 38.8857 16.5581 38.3334 16.5581Z"
        fill={color}/>
      <path
        d="M33.0001 21.2246H21.6667C21.1145 21.2246 20.6667 21.6723 20.6667 22.2246C20.6667 22.7769 21.1145 23.2246 21.6667 23.2246H33.0001C33.5524 23.2246 34.0001 22.7769 34.0001 22.2246C34.0001 21.6723 33.5524 21.2246 33.0001 21.2246Z"
        fill={color}/>
    </svg>

  )
};

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import theme from "application/theme";
import {
  IconButtonStyled,
  QuantityPickerInput,
  QuantityPickerWrapper,
} from "./styles";
import { NumericFormat } from 'react-number-format';

type QuantityPickerProps = {
  getValue: (value: number) => void;
  isError: boolean;
  defaultValue: number;
  min?: number;
  max?: number;
};

export const QuantityPickerSmall = ({
  getValue,
  isError,
  defaultValue,
  min = 0,
  max = 9999,
}: QuantityPickerProps) => {
  const [quantity, setQuantity] = useState(defaultValue);
  const [error, setError] = useState(isError);
  const handleIncrement = () => {
    const newQuantity = Math.min(quantity + 1, max);
    setQuantity(newQuantity);
    if (getValue) {
      getValue(newQuantity);
    }
  };

  const handleDecrement = () => {
    const newQuantity = Math.max(quantity - 1, min);
    setQuantity(newQuantity);
    if (getValue) {
      getValue(newQuantity);
    }
  };

  const handleChangeQuantity = (quantity: number) => {
    let newQuantity = Math.min(quantity, max);
    newQuantity = Math.max(newQuantity, min);
    setQuantity(newQuantity);
    if (getValue) {
      getValue(newQuantity);
    }
  };

  useEffect(() => {
    if (quantity < min) {
      setError(true);
    } else {
      setError(isError);
    }
  }, [isError, quantity]);

  const isValueAllowed = (values: any) => {
    if(values.value === '00') return false;
    return !(max && values.floatValue > max);
  }

  return (
    <Box>
      <QuantityPickerWrapper
        sx={{
          borderColor: error
            ? theme.palette.common.red600
            : theme.palette.common.grey400,
        }}
      >
        <IconButtonStyled
          onClick={handleDecrement}
          size="small"
          disabled={quantity <= min}
        >
          <ArrowLeft />
        </IconButtonStyled>
        <NumericFormat value={quantity}
          // @ts-ignore
                       customInput={QuantityPickerInput}
                       type="text"
                       thousandSeparator=','
                       onValueChange={(values) => handleChangeQuantity(values.floatValue || 0)}
                       isAllowed={isValueAllowed}
        />
        <IconButtonStyled
          onClick={handleIncrement}
          size="small"
          disabled={quantity >= max}
        >
          <ArrowRight />
        </IconButtonStyled>
      </QuantityPickerWrapper>
    </Box>
  );
};

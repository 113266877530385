import React, { useState } from "react";

import { useFormContext } from "react-hook-form";

import theme from "application/theme";
import { Button } from "application";
import { useAppDispatch } from "application/store";

import { Box } from "@mui/system";
import { Typography } from "@mui/material";

import {
  LabelIconWrapper,
  LabelContentWrapper,
  ContentWrapper,
} from "./styles";

import { CompanyFilterDataI } from "integration/api/companies/types";
import { InputStyled } from "application/components/FormInput/styles";
import { handleSaveFilteredSearches } from "../store/actions";
import { ModalComponent } from "application/components/ModalComponent";
import { BlueStarIcon } from "../assets/BlueStarIcon";

interface Props {
  open: boolean;
  setOpen: (b: boolean) => void;
}

const SaveFilterModal = ({ open, setOpen }: Props) => {
  const dispatch = useAppDispatch();
  const [filterName, setFilterName] = useState("");
  const { getValues } = useFormContext();
  const handleChangeName = (event: any) => {
    setFilterName(event.target.value);
  };

  const handleSaveFilter = () => {
    const formData = getValues();

    dispatch(
      handleSaveFilteredSearches({
        name: filterName,
        filterValue: formData as CompanyFilterDataI,
      })
    );
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setFilterName("");
  };

  return (
    <ModalComponent isOpen={open} handleClose={handleClose} width={"30%"}>
      <ContentWrapper>
        <LabelContentWrapper>
          <LabelIconWrapper>
            <BlueStarIcon />
          </LabelIconWrapper>
          <Typography variant="subtitle1" color={theme.palette.common.grey700}>
            Save search
          </Typography>
          <Box width={"100%"}>
            <Typography
              variant="caption"
              color={theme.palette.common.grey800}
              sx={{ display: "block" }}
              mb={0.75}
            >
              Add name to this search
            </Typography>
            <InputStyled
              sx={{ width: "100%" }}
              name="searchName"
              placeholder="Search"
              onChange={handleChangeName}
            />
          </Box>
        </LabelContentWrapper>
        <Box display={"flex"} justifyContent={"space-between"} gap={3}>
          <Button
            label="No"
            height={5.5}
            width={theme.spacing(15)}
            type="button"
            onClick={handleClose}
          />
          <Button
            label="Save"
            height={5.5}
            width={theme.spacing(15)}
            type="submit"
            onClick={handleSaveFilter}
          />
        </Box>
      </ContentWrapper>
    </ModalComponent>
  );
};

export default SaveFilterModal;

import {useContext} from 'react';
import {ViewDetailsContext} from '../contexts/ViewDetailsContext';
import { handleGetCompanyInfo } from 'common/companies/store/actions';
import { useAppDispatch, useAppSelector } from 'application/store';
import { selectedCompanySelector } from '../../companies/store';
import { selectedPermitSelector } from '../store';
import { handleGetPermitInfoById } from '../store/actions';

export const useViewDetails = () => {
  const {
    isOpenCompanyInfo,
    isOpenPermitInfo,
    setIsOpenCompanyInfo,
    setIsOpenPermitInfo, ...context} = useContext(ViewDetailsContext);
  if (!context) throw new Error('ViewDetailsContext must be use inside ViewDetailsContextProvider');

  const dispatch = useAppDispatch();
  const selectedCompany = useAppSelector(selectedCompanySelector);
  const selectedPermit = useAppSelector(selectedPermitSelector);

  const showPermitDetails = (id: number) => {
    if (!(selectedPermit?.id) || selectedPermit?.id !== id) {
      dispatch(handleGetPermitInfoById(id));
    }
    setIsOpenPermitInfo(true);
  }
  const showCompanyDetails = (companyId: number) => {
    if (selectedCompany.id !== companyId) {
      dispatch(handleGetCompanyInfo(companyId));
    }
    setIsOpenCompanyInfo(true);
  }
  const hidePermitDetails = () => {
    setIsOpenPermitInfo(false);
  }
  const hideCompanyDetails = () => {
    setIsOpenCompanyInfo(false);
  }

  return {
    isOpenCompanyInfo,
    isOpenPermitInfo,
    showPermitDetails,
    showCompanyDetails,
    hidePermitDetails,
    hideCompanyDetails,
    selectedPermit,
  };
}
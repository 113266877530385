import React from "react";
import { BadgeWrapper } from "./style";
import { Typography } from "@mui/material";
import { TeamStatus } from "common/organization";
import theme from "application/theme";
import { badgeHelper } from "../BadgeWithDot/utils";
import { Check } from "@mui/icons-material";

const BadgeWithCheck = ({
  status,
  height = 3.5,
}: {
  status: TeamStatus | string;
  height?: number;
}) => {
  return (
    <BadgeWrapper status={status} height={theme.spacing(height)}>
      {status !== TeamStatus.archived && (
        <Check
          sx={{
            color: badgeHelper(status).text,
            width: theme.spacing(1.75),
            height: theme.spacing(1.75),
            mr: theme.spacing(0.5),
          }}
        />
      )}
      <Typography variant="caption">{badgeHelper(status).text}</Typography>
    </BadgeWrapper>
  );
};

export default BadgeWithCheck;

import { Box } from "@mui/system";
import { CustomTooltip } from "application";
import { AddToBulkIcon, CheckIcon } from "application/assets";
import { useAppDispatch, useAppSelector } from "application/store";
import { useCompaniesExtraInfo } from "common/companies/hooks/useCompaniesExtraInfo";
import { handleAddDataToBulkExport } from "common/companies/store/actions";
import { sdrProfileSelector } from "common/sdrProfile/store";
import { CompanyI } from "integration/api/companies/types";
import React, { useEffect } from "react";
import { useInAppNotification } from 'common/notify';
import { ExportScope } from 'common/companies/types';

interface Props {
  itemInfo: CompanyI;
  isAddedToBulk: boolean;
  onAddToBulk: (companyId: number) => void;
}
export const AddToBulkExport = ({
  itemInfo,
  isAddedToBulk,
  onAddToBulk,
}: Props): JSX.Element => {
  const { sdrProfile } = useAppSelector(sdrProfileSelector);
  const dispatch = useAppDispatch();
  const [addedToBulk, setAddedToBulk] = React.useState(false);
  const { companiesExtraInfo } = useCompaniesExtraInfo();
  const {showBulkExportNotification} = useInAppNotification()

  useEffect(() => {
    const extraInfo = companiesExtraInfo.find(
      (info) => info.companyId === itemInfo.id
    );
    if (extraInfo) {
      setAddedToBulk(extraInfo.isInBulkExport);
    }
  }, [companiesExtraInfo]);

  const handleAddToBulk = async () => {
    if (addedToBulk) return;
    onAddToBulk(itemInfo.id);
  };

  return (
    <Box
      onClick={handleAddToBulk}
      sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
    >
      {isAddedToBulk ? (
        <CustomTooltip title="Added to bulk export">
          <CheckIcon />
        </CustomTooltip>
      ) : (
        <CustomTooltip title="Add to bulk export">
          <AddToBulkIcon />
        </CustomTooltip>
      )}
    </Box>
  );
};

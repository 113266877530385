import React from 'react';
import { Modal } from "@mui/material";
import {
  CloseButtonStyled,
  CompanyInfoModalWrapper
} from 'common/companies/companiesListExtended/components/CompanyInfoModal/styles';
import { CloseOutlined } from '@mui/icons-material';
import { PermitInfo } from '../PermitInfo';

interface PermitInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
}
export const PermitInfoModal = ({isOpen, onClose}: PermitInfoModalProps) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        "& :focus": {
          outline: "none",
        },
      }}
    >
      <CompanyInfoModalWrapper>
        <CloseButtonStyled onClick={onClose}>
          <CloseOutlined/>
        </CloseButtonStyled>
        <PermitInfo/>
      </CompanyInfoModalWrapper>
    </Modal>
  );
}
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import theme from "application/theme";

export const EmptyContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: ${theme.palette.common.white};
  width: 100%;
  padding: ${theme.spacing(5, 0)};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`;

import { Box, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import theme from "application/theme";

export const ConnetToHubspotWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing(2)};
  background-color: ${theme.palette.common.blue50};
  border: 1px solid ${theme.palette.common.blue800};
  border-radius: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(4)};
`;

export const StyledIconButton = styled(IconButton)`
  cursor: pointer;
  padding: ${theme.spacing(0)};
  display: flex;
  align-items: center;
  &.MuiButtonBase-root:hover {
    background-color: transparent;
  }
`;
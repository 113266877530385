import React, { useState } from "react";
import { Box } from "@mui/system";
import ShowLessAndMore from "common/companies/components/ShowLessAndMore";
import { GeneralInfoContainer } from "./styles";
import { InfoItemsWrapper } from "../styles";
import { PermitDescription } from "./PermitDescription";
import { PermitInfoItem } from "../PermitInfoItem";
import { DocIcon, DocIcon2, UsersIcon } from "application/assets";
import { getNumberFormatWithSeparators } from "application/utils/numberUtils";
import { formatDateTime } from "application/utils/dateTimeUtil";
import { useAppSelector } from "application/store";
import { selectedPermitSelector } from "common/permits/store";

const getPermitValueString = (value: number | undefined) => {
  if (value === 0) {
    return 0;
  }
  return value ? "$ " + getNumberFormatWithSeparators(value) : "-";
};

const getPermitDate = (date: string | undefined) => {
  return date ? formatDateTime(date) : "-";
};
export const PermitGeneralInfo = () => {
  const [showInfo, setShowInfo] = useState(true);
  const selectedPermit = useAppSelector(selectedPermitSelector);

  return (
    <GeneralInfoContainer>
      {showInfo && (
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={1.5}
          paddingTop={1.5}
        >
          <Box display={"flex"} gap={1}>
            <InfoItemsWrapper>
              <PermitInfoItem
                display={true}
                title={"Status normalized"}
                value={selectedPermit?.statusNormalized || "-"}
                icon={<DocIcon />}
              />
              <PermitInfoItem
                display={true}
                title={"Job value"}
                value={getPermitValueString(selectedPermit?.jobValue)}
                icon={<UsersIcon />}
              />
              <PermitInfoItem
                display={true}
                title={"Total fees"}
                value={getPermitValueString(selectedPermit?.totalFees)}
                icon={<DocIcon2 />}
              />
            </InfoItemsWrapper>
            <InfoItemsWrapper>
              <PermitInfoItem
                display={true}
                title={"File date"}
                value={getPermitDate(selectedPermit?.fileDate)}
              />
              <PermitInfoItem
                display={true}
                title={"Permit date"}
                value={getPermitDate(selectedPermit?.permitDate)}
              />
              <PermitInfoItem
                display={true}
                title={"Final date"}
                value={getPermitDate(selectedPermit?.finalDate)}
              />
              <PermitInfoItem
                display={true}
                title={"File permit timeframe"}
                value={selectedPermit?.filePermitTimeframe || "-"}
              />
              <PermitInfoItem
                display={true}
                title={"Permit final timeframe"}
                value={selectedPermit?.permitFinalTimeframe || "-"}
              />
            </InfoItemsWrapper>
          </Box>
          <PermitDescription text={selectedPermit?.description || "-"} />
        </Box>
      )}
      <ShowLessAndMore showAll={showInfo} setShowAll={setShowInfo} />
    </GeneralInfoContainer>
  );
};

import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { commonColors } from 'application/theme';
import { SaveFiltersButtonStyled, SaveFiltersItemWrapperWrapper } from './styles';
import { usePermitFiltersContext } from 'common/permits/hooks/usePermitFiltersContext';
import { SaveFiltersModal } from './SaveFiltersModal';

export const SaveFiltersItem = () => {
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const {filtersTotalCount} = usePermitFiltersContext();
  const saveIsActive = filtersTotalCount > 0;

  const handleSaveFilters = () => {
    if (!saveIsActive) return;
    setSaveModalOpen(true);
  }
  return (
    <>
      <SaveFiltersItemWrapperWrapper>
        <Typography variant="caption" color={commonColors.grey700}>
          {`${filtersTotalCount > 0 ? filtersTotalCount : 'No'} filters selected`}
        </Typography>
        <SaveFiltersButtonStyled
          variant="body2"
          active={saveIsActive}
          onClick={handleSaveFilters}
        >
          Save Filter
        </SaveFiltersButtonStyled>
      </SaveFiltersItemWrapperWrapper>
      <SaveFiltersModal isOpen={saveModalOpen}
                        onClose={() => setSaveModalOpen(false)}
      />
    </>
  )
}

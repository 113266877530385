import React from "react";
import theme from "application/theme";
import { IconProps } from './types';

export const AddToBulkIcon = ({color=theme.palette.common.blue800}: IconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <mask id="mask0_4447_19213" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_4447_19213)">
        <path d="M9.16602 15.8334V10.8334H4.16602V9.16675H9.16602V4.16675H10.8327V9.16675H15.8327V10.8334H10.8327V15.8334H9.16602Z" fill={color}/>
      </g>
    </svg>
  );
};

import React from 'react';
import { SectionStyled } from '../styles';
import { Typography } from '@mui/material';
import { commonColors } from 'application/theme';

interface PermitInfoSectionProps {
  title: string;
  children: React.ReactNode;
}
export const PermitInfoSection = ({title, children}: PermitInfoSectionProps) => {
  return (
    <SectionStyled>
      <Typography variant={'subtitle2'} color={commonColors.grey900}>{title}</Typography>
      {
        children
      }
    </SectionStyled>
  )
}
import { Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { MTheme } from "application/theme";
import React from "react";
import AuthImage from "./AuthImage";
import { LoginPageContentWrapper, LoginPageWrapper } from "./styles";
import { HelpOutline } from "@mui/icons-material";

export const AuthPageWrapper = ({ children }: { children: JSX.Element }) => {
  const theme: MTheme = useTheme();
  return (
    <LoginPageWrapper>
      <AuthImage />
      <LoginPageContentWrapper>
        {children}
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography variant="caption" color={theme.palette.common.grey700}>
            ©BuilderBinder copyright
          </Typography>
          <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }}>
            <HelpOutline
              sx={{
                color: theme.palette.common.grey700,
                height: theme.spacing(2.5),
                width: theme.spacing(2.5),
                marginRight: theme.spacing(0.5),
              }}
            />
            <Typography variant="caption" color={theme.palette.common.grey700}>
              Support
            </Typography>
          </Box>
        </Box>
      </LoginPageContentWrapper>
    </LoginPageWrapper>
  );
};

import { useState } from 'react';
import useOrganizationProfile from 'application/hooks/useOrganizationProfile';

export const useHubspotIntegrationModal = () => {
  const [isOpened, setIsOpened] = useState(false);
  const { organizationProfile, isHubspotIntegrated } = useOrganizationProfile();

  return {
    isHubspotIntegrated,
    hubspotModalIsOpened: isOpened,
    openHubspotIntegrationModal: () => setIsOpened(true),
    closeHubspotIntegrationModal: () => setIsOpened(false),
    hubspotAuthUrl: organizationProfile?.hubspotAuthUrl || null,
  }
}

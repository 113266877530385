import React, { useState } from "react";
import { PhotoWrapper, PlaceholderWrapper } from "./styles";
import { Typography } from "@mui/material";

interface Props {
  photoUrl: string | null;
  id: number;
  firstName: string;
  lastName: string;
}

const UserPhoto = ({ photoUrl, id, firstName, lastName }: Props) => {
  const [isInvalidUrl, setIsInvalidUrl] = useState(false);

  const onError = () => {
    setIsInvalidUrl(true);
  };

  return (
    <PhotoWrapper>
      {!isInvalidUrl && photoUrl ? (
        <img src={photoUrl} alt={`avatar ${id}`} onError={onError} />
      ) : (
        <PlaceholderWrapper>
          <Typography variant="body2" color="initial">
            {firstName.charAt(0).toUpperCase()}
            {lastName.charAt(0).toUpperCase()}
          </Typography>
        </PlaceholderWrapper>
      )}
    </PhotoWrapper>
  );
};

export default UserPhoto;

import React, { FC } from "react";
import { MTheme } from "application/theme";
import { Box, useTheme } from "@mui/system";
import { StyledSurveyFormWrapper } from "./styles";
import { Typography } from "@mui/material";
import { SurveyForm } from "./surveyForm/SurveyForm";


export const SurveyFormWrapper: FC = () => {
  const theme: MTheme = useTheme();

  return (
    <StyledSurveyFormWrapper>
      <Box>
        <Typography variant="h4" color={theme.palette.common.grey900}>
          Contact Sales!
        </Typography>
        <Typography color={theme.palette.common.grey800}>
          Our team is dedicated to helping you explore BuilderBinder your way. You can also email us directly at
          hey@builderbinder.co
        </Typography>
      </Box>
      <SurveyForm/>
    </StyledSurveyFormWrapper>
  );
};

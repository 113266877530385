import React, { useMemo, useState } from "react";
import { CollapseFilter } from "../../../CollapseFilter";
import StatusFilterItem from "./StatusFilterItem";
import { useFormContext } from "react-hook-form";
import { formatDateToYYYYMMDD } from "application/utils";
import { FilterStatus, PermitStatusI } from "common/permits/types";
import { countPermitStatusFilter } from 'common/permits/utils';

export const PERMIT_STATUS_FILTER_NAME = "statusFilter";
export const PermitStatusFilter = () => {
  const { watch, setValue } = useFormContext();
  const maxDate = new Date();
  const defaultMinDate = new Date();
  defaultMinDate.setDate(defaultMinDate.getDate() - 1);
  const selectedTypes: PermitStatusI[] = watch(PERMIT_STATUS_FILTER_NAME);
  const filtersCount = countPermitStatusFilter(selectedTypes);

  const inReview = useMemo(() => {
    return selectedTypes?.filter((e) => e.status === FilterStatus.inReview)[0];
  }, [selectedTypes]);

  const active = useMemo(() => {
    return selectedTypes?.filter((e) => e.status === FilterStatus.active)[0];
  }, [selectedTypes]);

  const final = useMemo(() => {
    return selectedTypes?.filter((e) => e.status === FilterStatus.final)[0];
  }, [selectedTypes]);

  const inReviewSelected = useMemo(() => {
    return inReview?.current || !!inReview?.from || !!inReview?.to;
  },[inReview]);

  const activeSelected = useMemo(() => {
    return active?.current || !!active?.from || !!active?.to;
  },[active]);

  const finalSelected = useMemo(() => {
    return final?.current;
  },[final]);

  const activeDisabled = inReview.current;

  const finalDisabled = inReview.current || active.current;

  const selectInReview = () => {
    if (inReviewSelected) {
      setValue(PERMIT_STATUS_FILTER_NAME, [
        { status: FilterStatus.inReview, current: false, to: null, from: null },
        { status: FilterStatus.active, current: false, to: null, from: null },
        { status: FilterStatus.final, current: false, to: null, from: null },
      ]);
    } else {
      const otherFilters = selectedTypes.filter(
        (e) => e.status !== FilterStatus.inReview
      );
      setValue(PERMIT_STATUS_FILTER_NAME, [
        ...otherFilters,
        { status: FilterStatus.inReview, current: true, to: null, from: null },
      ]);
    }
  };
  const selectActive = () => {
    if (!inReview.current) {
      setValue(PERMIT_STATUS_FILTER_NAME, [
        inReview,
        { status: FilterStatus.active, current: true, to: null, from: null },
        { status: FilterStatus.final, current: false, to: null, from: null },
      ]);
    }

    if (activeSelected) {
      setValue(PERMIT_STATUS_FILTER_NAME, [
        inReview,
        { status: FilterStatus.active, current: false, to: null, from: null },
        { status: FilterStatus.final, current: false, to: null, from: null },
      ]);
    }
  };
  const selectFinal = () => {
    if (!inReview.current && !active.current)
    setValue(PERMIT_STATUS_FILTER_NAME, [
      ...selectedTypes.filter((e) => e.status !== FilterStatus.final),
      { ...final, current: !final.current },
    ]);
  };

  // in review filter
  const getInReviewDateFrom = (value: Date | null | undefined) => {
    const otherFilters = selectedTypes.filter((e) => e.status !== FilterStatus.inReview);
    if (value)
      setValue(PERMIT_STATUS_FILTER_NAME, [
        ...otherFilters,
        { ...inReview, from: formatDateToYYYYMMDD(value) },
      ]);
  };
  const getInReviewDateTo = (value: Date | null | undefined) => {
    const otherFilters = selectedTypes.filter((e) => e.status !== FilterStatus.inReview);
    if (value)
      setValue(PERMIT_STATUS_FILTER_NAME, [
        ...otherFilters,
        { ...inReview, to: formatDateToYYYYMMDD(value) },
      ]);
  };
  const selectInReviewCurrent = () => {
    setValue(PERMIT_STATUS_FILTER_NAME, [
      {
        ...inReview,
        current: !inReview.current,
        to: inReview.to ? inReview.to : formatDateToYYYYMMDD(maxDate),
        from: inReview.from
          ? inReview.from
          : formatDateToYYYYMMDD(new Date(defaultMinDate.toISOString())),
      },
      { status: FilterStatus.active, current: false, to: null, from: null },
      { status: FilterStatus.final, current: false, to: null, from: null },
    ]);
  };
  const selectInReviewDateRange = () => {
    setValue(PERMIT_STATUS_FILTER_NAME, [
      ...selectedTypes.filter((e) => e.status !== FilterStatus.inReview),
      {
        ...inReview,
        to: inReview.to ? inReview.to : formatDateToYYYYMMDD(maxDate),
        from: inReview.from
          ? inReview.from
          : formatDateToYYYYMMDD(new Date(defaultMinDate.toISOString())),
        current: !inReview.current,
      },
    ]);
  };

  // active filter
  const getActiveDateFrom = (value: Date | null | undefined) => {
    const otherFilters = selectedTypes.filter((e) => e.status !== FilterStatus.active);
    if (value)
      setValue(PERMIT_STATUS_FILTER_NAME, [
        ...otherFilters,
        { ...active, from: formatDateToYYYYMMDD(value) },
      ]);
  };
  const getActiveDateTo = (value: Date | null | undefined) => {
    const otherFilters = selectedTypes.filter((e) => e.status !== FilterStatus.active);
    if (value)
      setValue(PERMIT_STATUS_FILTER_NAME, [
        ...otherFilters,
        { ...active, to: formatDateToYYYYMMDD(value) },
      ]);
  };
  const selectActiveCurrent = () => {
    setValue(PERMIT_STATUS_FILTER_NAME, [
      inReview,
      {
        ...active,
        current: !active.current,
        to: active.to ? active.to : formatDateToYYYYMMDD(maxDate),
        from: active.from
          ? active.from
          : formatDateToYYYYMMDD(new Date(defaultMinDate.toISOString())),
      },
      { status: FilterStatus.final, current: false, to: null, from: null },
    ]);
  };
  const selectActiveDateRange = () => {
    const otherFilters = selectedTypes.filter((e) => e.status !== FilterStatus.active);
    setValue(PERMIT_STATUS_FILTER_NAME, [
      ...otherFilters,
      {
        ...active,
        to: active.to ? active.to : formatDateToYYYYMMDD(maxDate),
        from: active.from
          ? active.from
          : formatDateToYYYYMMDD(new Date(defaultMinDate.toISOString())),
        current: !active.current,
      },
    ]);
  };

  // final filter
  const selectFinalCurrent = () => {
    setValue(PERMIT_STATUS_FILTER_NAME, [
      ...selectedTypes.filter((e) => e.status !== FilterStatus.final),
      { ...final, current: true },
    ]);
  };

  return (
    <CollapseFilter
      label={"Permit status"}
      tooltip={"Filter by permit status"}
      count={filtersCount}
    >
      <div>
        <StatusFilterItem
          filterData={inReview}
          checked={inReviewSelected}
          selectThisFilter={selectInReview}
          selectCurrent={selectInReviewCurrent}
          selectDateRange={selectInReviewDateRange}
          getDateFrom={getInReviewDateFrom}
          getDateTo={getInReviewDateTo}
          label="In review"
          value="in_review"
          disabledDatePick={inReview.current}
          maxDate={maxDate}
        />
        <StatusFilterItem
          filterData={active}
          checked={activeSelected}
          disabled={activeDisabled}
          selectThisFilter={selectActive}
          selectCurrent={selectActiveCurrent}
          selectDateRange={selectActiveDateRange}
          getDateFrom={getActiveDateFrom}
          getDateTo={getActiveDateTo}
          label="Active"
          value="active"
          disabledDatePick={active.current || inReview.current}
          maxDate={maxDate}
        />
        <StatusFilterItem
          filterData={final}
          checked={finalSelected}
          disabled={finalDisabled}
          selectThisFilter={selectFinal}
          selectCurrent={selectFinalCurrent}
          label="Final"
          value="final"
        />
      </div>
    </CollapseFilter>
  );
};

import React from "react";

import theme from "application/theme";

export const UserProfileIcon = ({
  color = theme.palette.common.grey800,
}: {
  color?: string;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons">
        <path
          id="Icon (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99996 4C8.06696 4 6.49996 5.567 6.49996 7.5C6.49996 9.433 8.06696 11 9.99996 11C11.933 11 13.5 9.433 13.5 7.5C13.5 5.567 11.933 4 9.99996 4ZM4.49996 7.5C4.49996 4.46243 6.96239 2 9.99996 2C13.0375 2 15.5 4.46243 15.5 7.5C15.5 10.5376 13.0375 13 9.99996 13C6.96239 13 4.49996 10.5376 4.49996 7.5ZM7.32623 14.5C7.38329 14.5 7.44119 14.5 7.49997 14.5H12C12.5522 14.5 13 14.9477 13 15.5C13 16.0523 12.5522 16.5 12 16.5H7.49997C6.03164 16.5 5.51917 16.5109 5.12911 16.6292C4.1703 16.92 3.41999 17.6703 3.12914 18.6291C3.01082 19.0192 2.99996 19.5317 2.99996 21C2.99996 21.5523 2.55225 22 1.99996 22C1.44768 22 0.999961 21.5523 0.999961 21C0.999961 20.9412 0.99994 20.8833 0.999919 20.8263C0.999465 19.599 0.999155 18.761 1.21526 18.0486C1.70001 16.4506 2.95053 15.2 4.54853 14.7153C5.26094 14.4992 6.09895 14.4995 7.32623 14.5ZM22.7071 15.2929C23.0976 15.6834 23.0976 16.3166 22.7071 16.7071L18.7071 20.7071C18.3165 21.0976 17.6834 21.0976 17.2929 20.7071L15.2929 18.7071C14.9023 18.3166 14.9023 17.6834 15.2929 17.2929C15.6834 16.9024 16.3165 16.9024 16.7071 17.2929L18 18.5858L21.2929 15.2929C21.6834 14.9024 22.3165 14.9024 22.7071 15.2929Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

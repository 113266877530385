import React from "react";
import { Typography } from "@mui/material";

import theme from "application/theme";

import noInfo from "application/assets/noInfo.png";
import { EmptyContentWrapper } from "./styles";

const EmptyInfoSubscription = () => {
  return (
    <EmptyContentWrapper>
      <img src={noInfo} alt="No results found" />
      <Typography variant="button" color={theme.palette.common.grey800} mt={2}>
        No added subscriptions yet
      </Typography>
      <Typography variant="caption" color={theme.palette.common.grey600} mb={2}>
        We didn’t find any active subscriptions in your list.
      </Typography>
    </EmptyContentWrapper>
  );
};

export default EmptyInfoSubscription;

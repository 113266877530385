import { Box, styled } from "@mui/material";
import theme from "application/theme";

export const LabelContentWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing(2)};
`;

export const LabelIconWrapper = styled(Box)`
  width: ${theme.spacing(10.25)};
  height: ${theme.spacing(10.25)};
  border-radius: 50%;
  background: ${theme.palette.common.blue50};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentWrapper = styled(Box)`
  gap: ${theme.spacing(4)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


export const HubspotModalContentWrapper = styled(Box)`
  position: relative;
  max-height: ${theme.spacing(60)};
  overflow-y: auto;
`;

export const ListHideEffect = styled(Box)`
  height: ${theme.spacing(3.75)};
  width: 100%;
  position: fixed;
  bottom: ${theme.spacing(11.5)};
  left: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
`;

export const CreditChargedText = styled("span")`
  font-family: Rubik;
  font-size: ${theme.spacing(2)};
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;
  color: ${theme.palette.common.orange500};
`;



import { useAppDispatch, useAppSelector } from 'application/store';
import { useRef } from 'react';
import { handleSetSdrLastActivityDay } from '../../common/sdrProfile/store/actions';
import { authSelector } from '../../common/auth/store/authStore';

const useUpdateLastActivityDate = () => {
  const initialized = useRef<String>('');
  const dispatch = useAppDispatch();
  const { isAuth } = useAppSelector(authSelector);
  const getToday = () => {
    return new Date().toISOString().split('T')[0];
  }
  const updateLastActivityDate = () => {
    if (initialized.current !== getToday() && isAuth) {
      dispatch(handleSetSdrLastActivityDay());
      initialized.current = getToday();
    }
  }
  return  {
    updateLastActivityDate
  }

}
export default useUpdateLastActivityDate;
import React from "react";
import {TableCellTextStyled} from "./styles";
import { getNumberFormatWithSeparators } from 'application/utils';

interface Props {
    label: string;
    value?: number | null;
}
export const CreditsQuantityItem = ({ label, value }: Props) => {
    if (value && value > 0) {
        return (
            <TableCellTextStyled lineHeight={1.6}>
                {`${label}: ${getNumberFormatWithSeparators(value)}`}
            </TableCellTextStyled>
        )
    }
    return <></>
}
import React from "react";
import { Box, Typography } from "@mui/material";
import theme from "application/theme";
import { ModalComponent } from "application/components/ModalComponent";
import { ContactPersonStyled } from "../styles";
import { AddUserIcon } from "application/assets/AddUserIcon";
import { sdrProfileSelector } from "common/sdrProfile/store";
import { useAppSelector } from "application/store";
import { organizationsSelector } from "../store";

export const ContactSalesModal = ({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const { organizationProfile } = useAppSelector(organizationsSelector);

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleClose}
      padding={3}
      width={475}
    >
      <Box textAlign="center">
        <Box display="flex" justifyContent="center" mb={4}>
          <AddUserIcon />
        </Box>
        <Typography variant="h5" color={theme.palette.common.grey800} mb={1.5}>
          You Have Reached the Maximum Number of Team Members for&nbsp;
          <ContactPersonStyled>
            {organizationProfile.companyName}
          </ContactPersonStyled>
        </Typography>

        <Typography
          variant="caption"
          color={theme.palette.common.grey700}
          display="block"
        >
          Please contact:&nbsp;
          <ContactPersonStyled>
            {organizationProfile.activatedByEmail}
          </ContactPersonStyled>
          &nbsp; or request more seats to your organization to add more team
          members.
        </Typography>
      </Box>
    </ModalComponent>
  );
};

import React, {useEffect, useMemo, useReducer} from "react";

import {Controller, useFormContext} from "react-hook-form";

import {StyledTab, StyledTabs,} from "../styles";
import {Box, Collapse} from "@mui/material";
import {initialStateLocation, reducerLocation} from "../../store/locationStore";
import {useLocationSearch} from "./hooks/useLocationSearch";
import {CollapseLabel} from "../components";
import LocationSearchTab from "./LocationSearchTab";
import {mergeTwoArrays} from "application/utils";

const locationOptions = [
    {value: "BY_STATE", label: "By state",},
    {value: "BY_CITY", label: "By metro area"},
];

const LocationCollapse = ({label}: { label: string }) => {
    const [state, dispatch] = useReducer(reducerLocation, initialStateLocation);
    const {control, watch} = useFormContext();
    const {
        citySearch, setCitySearch, handleCitySearch, foundCities, loadMoreCities,
        stateSearch, setStateSearch, handleStateSearch, foundStates, loadMoreStates,
        couldLoadMoreCities, couldLoadMoreStates, totalFoundCities, totalFoundStates,
        isLoading, citiesNotFound, statesNotFound, clearCitiesSearch, clearStatesSearch,
    } = useLocationSearch();

    const {
        citiesInclude,
        citiesExclude,
        statesInclude,
        statesExclude,
    } = watch();

    const allSelectedCities = useMemo(() => {
        return mergeTwoArrays(citiesInclude, citiesExclude);
    }, [citiesInclude, citiesExclude]);

    const allSelectedStates = useMemo(() => {
        return mergeTwoArrays(statesInclude, statesExclude);
    }, [statesInclude, statesExclude]);



    const isCityTab = watch("location") === "BY_CITY";

    useEffect(() => {
        const newCounter =
            allSelectedCities.length +
            allSelectedStates.length;
        dispatch({type: "UPDATE_COUNTER", payload: newCounter});
    }, [
        allSelectedCities,
        allSelectedStates
    ]);

    return (
        <Box width={"100%"}>
            <CollapseLabel
                label={label}
                expanded={state.expanded}
                setExpanded={(expanded) =>
                    dispatch({type: "SET_EXPANDED", payload: expanded})
                }
                count={state.counter}
            />
            <Collapse in={state.expanded}>
                <Box display="flex" flexDirection="column" gap={1.5} pt={2.25}>
                    <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                        <Controller
                            name="location"
                            defaultValue="BY_STATE"
                            control={control}
                            render={({field}) => (
                                <StyledTabs
                                    value={field.value || false}
                                    onChange={(event, newValue) => {
                                        field.onChange(newValue);
                                    }}
                                >
                                    {locationOptions.map((item, idx) => (
                                        <StyledTab
                                            key={idx}
                                            label={item.label}
                                            value={item.value}
                                        />
                                    ))}
                                </StyledTabs>
                            )}
                        />
                    </Box>
                    <Box>
                        {
                            isCityTab ?
                                <LocationSearchTab
                                    searchType={"city"}
                                    searchInputValue={citySearch}
                                    setSearchInputValue={(e: string) => setCitySearch(e)}
                                    selectedLocations={allSelectedCities}
                                    fieldNameInclude={"citiesInclude"}
                                    fieldNameExclude={"citiesExclude"}
                                    handleSearch={handleCitySearch}
                                    foundLocations={foundCities}
                                    loadMoreLocations={loadMoreCities}
                                    couldLoadMoreLocations={couldLoadMoreCities}
                                    totalFoundLocations={totalFoundCities}
                                    isLoading={isLoading}
                                    noSearchResults={citiesNotFound}
                                    onClear={clearCitiesSearch}
                                />
                                :
                                <LocationSearchTab
                                    searchType={"state"}
                                    searchInputValue={stateSearch}
                                    setSearchInputValue={(e: string) => setStateSearch(e)}
                                    selectedLocations={allSelectedStates}
                                    fieldNameInclude={"statesInclude"}
                                    fieldNameExclude={"statesExclude"}
                                    handleSearch={handleStateSearch}
                                    foundLocations={foundStates}
                                    loadMoreLocations={loadMoreStates}
                                    couldLoadMoreLocations={couldLoadMoreStates}
                                    totalFoundLocations={totalFoundStates}
                                    isLoading={isLoading}
                                    noSearchResults={statesNotFound}
                                    onClear={clearStatesSearch}
                                />
                        }
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
};
export default LocationCollapse;


import React from 'react'
import { Box, Typography } from '@mui/material';
import theme from 'application/theme';
import { ToggleButton } from 'application/components';
interface Props {
  isExtendedView: boolean;
  onChangeView: (extendedView: boolean) => void;
}
const DisplayModeToggle = ({isExtendedView, onChangeView}: Props) => {
  return (
    <Box display="flex"
         width={'auto'}
         justifyContent={"flex-end"}
         gap={1.5} alignItems="center" >
      <Typography
        variant="caption"
        color={theme.palette.common.grey700}
        textTransform="uppercase">company details view</Typography>
      <ToggleButton
        checked={!isExtendedView}
        onChange={() => onChangeView(!isExtendedView)}/>
    </Box>
  )
}

export default DisplayModeToggle;

import { Box, Select, Typography } from "@mui/material";
import { styled } from "@mui/system";
import theme from "application/theme";

export const CompaniesListWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  gap: ${theme.spacing(1)};
  padding: ${theme.spacing(2, 2)};
`;

export const CompaniesLoadingWrapper = styled(Box)`
  width: ${theme.spacing(50)};
  min-width: ${theme.spacing(50)};
  height: 100%;
  overflow: hidden;
  border: ${theme.spacing(0.125)} solid ${theme.palette.common.grey300};
`;

export const CompanyInfoWrapper = styled(Box)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  min-width: ${theme.spacing(32.5)};
  gap: ${theme.spacing(1)};
  align-self: stretch;
`;

export const CompanyListHeaderWrapper = styled(CompanyInfoWrapper)`
  min-width: ${theme.spacing(42)};
  @media (max-width: 1080px) {
    min-width: ${theme.spacing(32.5)};
  }
`;

export const CompanyItemWrapper = styled(Box)<{ active: boolean | number }>`
  border-radius: ${theme.spacing(1.5)};
  background: linear-gradient(0deg, #fff 0%, #fff 100%), #fff;
  box-shadow: ${theme.spacing(0, 0.125, 0.25, 0)} rgba(16, 24, 40, 0.06),
    ${theme.spacing(0, 0.125, 0.25, 0)} rgba(16, 24, 40, 0.1);
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(2)};
  width: 100%;
  cursor: pointer;
  gap: ${theme.spacing(2.5)};
  border: ${(props) =>
    props.active
      ? `${theme.spacing(0.25)} solid ${theme.palette.common.blue700};`
      : `${theme.spacing(0.25)} solid transparent;`};
  :hover {
    border: ${theme.spacing(0.25)} solid ${theme.palette.common.blue700};
  }
`;

export const GeneralInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.spacing(0.5)};
  max-width: calc(100% - 120px);
`;

export const RatingWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${theme.spacing(1)};
  max-width: ${theme.spacing(15)};
  min-width: ${theme.spacing(15)};
`;

export const RatingNumberWrapper = styled(Box)`
  display: flex;
  height: ${theme.spacing(4)};
  width: ${theme.spacing(4)};
  justify-content: center;
  align-items: center;
  border-radius: ${theme.spacing(1)};
  background: ${theme.palette.common.blue800};
  color: ${theme.palette.common.white};
`;

export const TypographyStyled = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: ${theme.spacing(24.5)};
`;

export const SelectSortStyled = styled(Select)`
    border-radius: 8px;
    border: 1px solid ${theme.palette.common.grey400};
    background: ${theme.palette.common.grey150};
    font-size: ${theme.spacing(2)};
    & .MuiSelect-select {
        padding: ${theme.spacing(0.5, 1)};
        max-width: ${theme.spacing(13)}; 
    }
    & fieldset {
        border: none;
    }
`

import React from "react";

import useBulkExport from "../../useBulkExport";
import {
  ExportEmployeesTab,
  ExportCompaniesTab,
  ExportPermitsTab
} from './components';

const BulkExportTabs = () => {
  const {isExportScopeEmployees, isExportScopeCompanies, isExportScopePermits} = useBulkExport();
  return (
    <>
      {isExportScopeEmployees && <ExportEmployeesTab/> }
      {isExportScopeCompanies && <ExportCompaniesTab/> }
      {isExportScopePermits && <ExportPermitsTab/> }
    </>
  );
};

export default BulkExportTabs;

import { styled } from "@mui/material";

import { Box } from "@mui/system";

import theme from "application/theme";

export const EmptyContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: ${theme.palette.common.white};
  width: 100%;
  padding: ${theme.spacing(5, 0)};
`;

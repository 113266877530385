import {NumericFormat} from "react-number-format";
import {PermitInputStyled} from "./styles";
import React from "react";

interface Props {
    value: number;
    onValueChange: (newValue: number) => void;
    prefix?: string;
    maxValue?: number;
    minValue?: number;
}
const PermitInputFormatted = ({value, onValueChange, maxValue, minValue, prefix}: Props) => {
    const onChange = (value: any) => {
        onValueChange(value.floatValue || 0)
    }
    const checkValues = (values: any) => {
        if (maxValue && values.floatValue > maxValue) {
            return false;
        }
        return !(minValue && values.floatValue < minValue);
    }
    return (
        <NumericFormat value={value}
            // @ts-ignore
                       customInput={PermitInputStyled}
                       prefix={prefix}
                       type="text"
                       thousandSeparator=','
                       onValueChange={onChange}
                       isAllowed={checkValues}
        />
    )
}

export default PermitInputFormatted;
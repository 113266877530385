import React from "react";
import theme from "application/theme";
import { IconProps } from "./types";

export const LeftArrow: React.FC<IconProps> = ({
  color = theme.palette.common.grey700,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="10"
      viewBox="0 0 8 10"
      fill="none"
    >
      <path
        d="M6.17568 10L7.5 8.83333L3.14865 5L7.5 1.16667L6.17568 0L0.5 5L6.17568 10Z"
        fill={color}
      />
    </svg>
  );
};

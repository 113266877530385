import { Box } from "@mui/material";
import { styled } from "@mui/system";
import theme from "application/theme";

export const PhotoWrapper = styled(Box)`
  width: ${theme.spacing(5)};
  height: ${theme.spacing(5)};
  min-width: ${theme.spacing(5)};

  border-radius: 50%;
  overflow: hidden;
  img {
    display: block;
    width: 100%;

    height: 100%;
    object-fit: cover;
  }
`;

export const PlaceholderWrapper = styled(Box)`
  background: ${theme.palette.common.grey300};
  color: ${theme.palette.common.grey800};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

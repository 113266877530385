import React from 'react';
import { useAppSelector } from 'application/store';
import { FiltersCollapse } from 'application';
import { FilterGroupWrapper, FiltersWrapper } from '../components/styles';
import { CheckedFilterItem } from '../components/CheckedFilterItem';
import { getAnnualRevenueLabel } from 'common/companies/companiesFilter/utils';
import { companyFiltersSelector } from 'common/permits/store';
import { getArrayLength } from 'application/utils/arrayUtils';

export const AnnualRevenueFilter = () => {
  const {annualRevenueRange} = useAppSelector(companyFiltersSelector);
  return getArrayLength(annualRevenueRange) > 0 ? (
    <FiltersCollapse label={'REVENUE'} count={getArrayLength(annualRevenueRange)}>
      <FiltersWrapper>
        <FilterGroupWrapper>
          {annualRevenueRange.map((item) => (
            <CheckedFilterItem
              key={item}
              label={getAnnualRevenueLabel(item)}/>
          ))
          }
        </FilterGroupWrapper>
      </FiltersWrapper>
    </FiltersCollapse>
  ) : null;
}
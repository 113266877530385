import { Box, styled } from "@mui/system";
import theme from "application/theme";

export const GroupButtonBorder = styled(Box)`
  display: flex;
  align-items: center;
  width: fit-content;
  min-width: ${theme.spacing(21.5)};
  border: 1px solid ${theme.palette.common.grey400};
  border-radius: ${theme.spacing(1)};
  cursor: pointer;
`;

export const EmailButtonWrapper = styled(Box)`
  padding: ${theme.spacing(0.75, 1)};
  border-right: 1px solid ${theme.palette.common.grey400};
  height: ${theme.spacing(4.5)};
  &:hover {
    background: ${theme.palette.common.grey100};
    border-top-left-radius: ${theme.spacing(1)};
    border-bottom-left-radius: ${theme.spacing(1)};
  }
`;

export const PhoneButtonWrapper = styled(Box)`
  padding: ${theme.spacing(0.75, 1)};
  border-right: 1px solid ${theme.palette.common.grey400};
  height: ${theme.spacing(4.5)};
  &:hover {
    background: ${theme.palette.common.grey100};
  }
`;

export const PhoneAndEmailButtonWrapper = styled(Box)`
  padding: ${theme.spacing(0.75, 1)};
  height: ${theme.spacing(4.5)};
  &:hover {
    background: ${theme.palette.common.grey100};
    border-top-right-radius: ${theme.spacing(1)};
    border-bottom-right-radius: ${theme.spacing(1)};
  }
`;

export const PositionedButton = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
`;

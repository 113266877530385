import React, { useEffect, useRef } from "react";

import {
  AutoSuggestLoaderWrapper,
  ListboxWrapper,
  StyledAutocomplete,
  TotalResultsLoaderWrapper,
  TotalResultsStyled
} from "./styles";
import { useCompanySearchAutocomplete } from '../hooks/useCompanySearchAutocomplete';
import { SearchInput } from './SearchInput'
import { AutosuggestOption } from './AutosuggestOption';
import { useCompaniesPageContext } from 'common/companies/hooks/useCompaniesPageContext';
import { Controller } from 'react-hook-form';
import { useCompaniesSearch } from 'common/companies/hooks/useCompaniesSearch';
import { LoaderOverlap } from 'common/loader';

const MIN_NAME_LENGTH = 3;
const AUTO_SEARCH_TIMEOUT = 1000;
export const CompanySearchAutocomplete = () => {
  const {companiesFilterForm: {watch, control, setValue}} = useCompaniesPageContext();
  const {
    isOptionsLoading,
    options,
    fetchOptions,
    getCompanyInfo,
    clearOptions,
    totalResults,
    isTotalResultsAreLoading
  } = useCompanySearchAutocomplete();
  const {setIsOpenCompanyInfo, isExtendedView} = useCompaniesPageContext();
  const {searchForCompanies} = useCompaniesSearch();
  const optionSelectedRef = useRef<string>('');
  const handleFetchOptions = () => {
    const searchData = watch();
    setValue('companyId', null)
    if (searchData.name === optionSelectedRef.current) {
      return;
    }
    clearOptions();
    if (searchData.name && searchData.name.length >= MIN_NAME_LENGTH) {
      fetchOptions(searchData);
    }
  }
  const handleSearchForCompanies = () => {
    const searchData = watch();
    searchForCompanies(searchData);
  }
  const handleSelectOption = async (companyId: number, name: string) => {
    setValue('name', name)
    setValue('companyId', companyId)
    optionSelectedRef.current = name;
    const searchData = watch();
    await searchForCompanies(searchData);
    isExtendedView && setIsOpenCompanyInfo(true);
    await getCompanyInfo(companyId);
    optionSelectedRef.current = '';
  }
  const handleClearSearch = () => {
    setValue('name', null)
    setValue('companyId', null)
    clearOptions();
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleFetchOptions();
    }, AUTO_SEARCH_TIMEOUT)
    return () => clearTimeout(delayDebounceFn)
  }, [watch('name')])

  const CustomListbox = React.forwardRef((props: React.HTMLAttributes<HTMLElement>, ref) => {
    const {children, ...other} = props;
    return (
      <ListboxWrapper component="ul" role="listbox" {...other}>
        {children}
        <TotalResultsStyled
          onClick={handleSearchForCompanies}>
          {
            isTotalResultsAreLoading ? <TotalResultsLoader/>
              : `Show all ${totalResults} companies`
          }
        </TotalResultsStyled>
      </ListboxWrapper>
    );
  });

  return (
    <Controller
      name="name"
      control={control}
      defaultValue={""}
      render={({field}) => (
        <StyledAutocomplete
          clearOnBlur={false}
          inputValue={field.value ?? ""}
          ListboxComponent={CustomListbox}
          ListboxProps={
            {
              style: {
                maxHeight: '100%'
              }
            }
          }
          onInputChange={(event, newInputValue) => {
            field.onChange(newInputValue)
          }}
          loading={isOptionsLoading}
          loadingText={<AutoSuggestLoader/>}
          id="comppany-autosuggest-box"
          options={options}
          filterOptions={(array, state) => array}
          renderInput={(params) => (
            <SearchInput params={params}
                         value={field.value ?? ""}
                         setValue={field.onChange}
                         disabled={isOptionsLoading}
                         placeholder={'Search by company name or website ...'}
                         onClearSearch={handleClearSearch}
            />
          )}
          getOptionLabel={(option: any) => option.businessName}
          renderOption={(props, option: any) => (
            <AutosuggestOption
              key={option.companyId}
              props={props}
              option={option}
              onSelect={(companyId) => handleSelectOption(companyId, option.businessName)}
            />
          )}
        />
      )}
    />
  )
};

const AutoSuggestLoader = () => {
  return (
    <AutoSuggestLoaderWrapper>
      <LoaderOverlap isLoading={true} size={40}/>
    </AutoSuggestLoaderWrapper>
  )
}

const TotalResultsLoader = () => {
  return <TotalResultsLoaderWrapper>
    <LoaderOverlap isLoading={true} size={20}/>
  </TotalResultsLoaderWrapper>
}

import theme from "application/theme";
import React from "react";

export const InventoryIcon = ({
  color = theme.palette.common.grey800,
}: {
  color?: string;
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons">
        <mask
          id="mask0_2054_11215"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_2054_11215)">
          <path
            id="inventory_2"
            d="M3 20V8.7C2.71667 8.51667 2.47917 8.28333 2.2875 8C2.09583 7.71667 2 7.38333 2 7V4C2 3.45 2.19583 2.97917 2.5875 2.5875C2.97917 2.19583 3.45 2 4 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V7C22 7.38333 21.9042 7.71667 21.7125 8C21.5208 8.28333 21.2833 8.51667 21 8.7V20C21 20.55 20.8042 21.0208 20.4125 21.4125C20.0208 21.8042 19.55 22 19 22H5C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20ZM5 9V20H19V9H5ZM20 7V4H4V7H20ZM9 14H15V12H9V14Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

import { useAppDispatch, useAppSelector } from 'application/store';
import {
  infoMessageSelector,
  setBulkExportNotificationScope,
  setInfoMessage,
  setShowBulkExportNotification,
} from './store';
import { ExportScope } from '../companies/types';


export const useInAppNotification = () => {
  const dispatch = useAppDispatch();
  const infoMessage = useAppSelector(infoMessageSelector);
  const isBulkExportNotificationVisible = useAppSelector(state => state.inAppNotification.showBulkExportNotification);
  const bulkExportNotificationScope = useAppSelector(state => state.inAppNotification.bulkExportNotificationScope);
  const showInfoMessage = (message: string | null, autoCloseDelay?: number) => {
    dispatch(setInfoMessage(message));
    if (autoCloseDelay) {
      setTimeout(() => {
        dispatch(setInfoMessage(null));
      }, autoCloseDelay);
    }
  }
  const showBulkExportNotification = (exportScope: ExportScope) => {
    dispatch(setBulkExportNotificationScope(exportScope));
    dispatch(setShowBulkExportNotification(true));
  }
  const hideBulkExportNotification = () => {
    dispatch(setBulkExportNotificationScope(null));
    dispatch(setShowBulkExportNotification(false));
  }
  return {
    isBulkExportNotificationVisible,
    bulkExportNotificationScope,
    showBulkExportNotification,
    hideBulkExportNotification,
    infoMessage,
    showInfoMessage
  };
}
import { useMemo } from 'react';
import { PermitAffiliationType, PermitsDisplayMode } from '../types';
import { useAppDispatch, useAppSelector } from 'application/store';
import { setMapViewCompaniesTypes, setMapViewModes } from '../store';

export const usePermitsViewSettings = () => {
  const dispatch = useAppDispatch();
  const mapViewModes: PermitsDisplayMode[] = useAppSelector(state => state.permits.viewSettings.mapView.viewModes);
  const executorTypes: PermitAffiliationType[] = useAppSelector(state => state.permits.viewSettings.mapView.executorTypes);

  const displayCompaniesOnMap = useMemo(() =>
    mapViewModes.includes(PermitsDisplayMode.COMPANIES),
    [mapViewModes]);

  const displayPermitsOnMap = useMemo(() =>
    mapViewModes.includes(PermitsDisplayMode.PERMITS),
    [mapViewModes]);

  const changeMapViewModes = (newModes: PermitsDisplayMode[]) => {
    dispatch(setMapViewModes(newModes));
  }

  const changeExecutorTypes = (newTypes: PermitAffiliationType[]) => {
    dispatch(setMapViewCompaniesTypes(newTypes));
  }

  return {
    displayCompaniesOnMap,
    displayPermitsOnMap,
    mapViewModes,
    changeMapViewModes,
    executorTypes,
    changeExecutorTypes
  }
}

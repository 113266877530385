import {Box, styled} from "@mui/system";
import theme from "application/theme";

export const StyledLink = styled("a")`
  color: ${theme.palette.common.grey800};
  text-decoration-thickness: 1px;
  display: flex;
  align-items: center;
`;

export const GeneralInfoItemStyled = styled(Box)`
    display: flex;
    gap: ${theme.spacing(1)};
`

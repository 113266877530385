import { setFilterFormData } from 'common/permits/store';
import { cloneDeep } from 'lodash';
import { useAppDispatch, useAppSelector } from 'application/store';
import useUpdateLastActivityDate from 'application/hooks/useUpdateLastActivityDate';
import {
  handleGetCompanyAndPermitMapView,
  handleSearchCompaniesAndPermitsOnBackground,
  handleSearchPermitsAndCompaniesOnBackground,
} from '../store/actions';
import { PermitWithCompanyFilterDataI } from '../types';


export const usePermitsWithCompaniesSearch = () => {
  const dispatch = useAppDispatch();
  const { updateLastActivityDate } = useUpdateLastActivityDate();
  const { foundCompanies,
    foundPermits,
    filterFormData } = useAppSelector(state => state.permits);

  const searchForPermitsAndCompaniesOnBackground = async (filterData: PermitWithCompanyFilterDataI, page=0, size = 20) => {
    updateLastActivityDate();
    return await dispatch(handleSearchPermitsAndCompaniesOnBackground(filterData, page, size, true));
  }
  const searchForCompaniesAndPermitsOnBackground = async (filterData: PermitWithCompanyFilterDataI, page=0, size = 20) => {
    updateLastActivityDate();
    return await dispatch(handleSearchCompaniesAndPermitsOnBackground(filterData, page, size, true));
  }

  const searchForCompaniesAndPermitsCoordinates = (filterData: PermitWithCompanyFilterDataI) => {
    dispatch(setFilterFormData(cloneDeep(filterData)));
    updateLastActivityDate();
    return dispatch(handleGetCompanyAndPermitMapView(filterData, true));
  }

  return {
    foundCompanies,
    foundPermits,
    filterFormData,
    searchForCompaniesAndPermitsCoordinates,
    searchForPermitsAndCompaniesOnBackground,
    searchForCompaniesAndPermitsOnBackground
  }
}

import { styled, Box } from '@mui/system';
import theme from 'application/theme';
import Drawer from '@mui/material/Drawer';

export const PageWrapper = styled(Box)`
  display: flex;
  height: 100vh;
`

interface PageContentProps {
  sidebaropened: boolean;
  sidebarwidth: string | number;
}

export const PageContentWrapper = styled(Box)<PageContentProps>`
  flex-grow: 1;
  height: 100vh;
  transition: ${theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })};
  margin-left: ${({sidebaropened, sidebarwidth}) =>
          sidebaropened ? 0 : `-${sidebarwidth}`};
`
interface DrawerProps {
  sidebarwidth: string | number;
}
export const DrawerStyled = styled(Drawer)<DrawerProps>`
  position: relative;
  width: ${({sidebarwidth}) => `${sidebarwidth}`};
  z-index: ${({open}) => `${open ? 1 : -1 }`};
  flex-shrink: 0;
  & .MuiDrawer-paper {
    width: ${({sidebarwidth}) => `${sidebarwidth}`};
    position: relative;
    box-sizing: border-box;
  },
`
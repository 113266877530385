import { SubscriptionsI } from "./types";

export const sortSubscriptionsByLastDate = (
  subscriptions: SubscriptionsI[]
) => {
  return subscriptions.slice().sort((a, b) => {
    const dateA = new Date(a.lastDate || "");
    const dateB = new Date(b.lastDate || "");

    if (isNaN(dateA.getTime())) {
      return -1;
    }

    if (isNaN(dateB.getTime())) {
      return 1;
    }

    if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
      return dateA.getTime() - dateB.getTime();
    }

    return 0;
  });
};

import { Box, styled } from '@mui/system';
import theme from 'application/theme';

export const MaxItemsCountTooltipWrapper = styled(Box)`
  display: flex;
  margin-top: ${theme.spacing(1)};
  margin-left: ${theme.spacing(6)};
  padding: ${theme.spacing(1)};
  gap: ${theme.spacing(1.5)};
  position: relative;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.75);
  box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.15);
  max-width: ${theme.spacing(60)};
`

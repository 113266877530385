import { Box } from "@mui/system";
import theme from "application/theme";
import React from "react";
import { Typography } from "@mui/material";
import { Button } from "application";
import { Close } from "@mui/icons-material";
import { ConnetToHubspotWrapper, StyledIconButton } from "./styles";

const TopInfoMessage = ({
  label,
  onClick,
  buttonText,
  icon,
  onClose,
}: {
  label: string;
  buttonText: string;
  onClick: () => void;
  icon: JSX.Element;
  onClose: () => void;
}) => {
  return (
    <ConnetToHubspotWrapper>
      <Box display="flex" alignItems="center">
        {icon}
        <Typography
          variant="overline"
          fontWeight={500}
          color={theme.palette.common.blue800}
          ml={1.5}
        >
          {label}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Button
          label={buttonText}
          height={4}
          type="button"
          mr={1.5}
          onClick={onClick}
        />
        <StyledIconButton onClick={onClose}>
          <Close sx={{ color: theme.palette.common.grey800 }} />
        </StyledIconButton>
      </Box>
    </ConnetToHubspotWrapper>
  );
};
export default TopInfoMessage;

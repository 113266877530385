import { Box } from "@mui/system";
import React from "react";
import { Dot } from "./styles";
import theme from "application/theme";
import { PermitsInfo } from "common/permits/types";
import { CompanyI } from "integration/api/companies/types";

interface DotsNavigationProps {
  items: any[];
  dotSelectItem: (idx: number) => void;
  currentItem: number;
}

const DotsNavigation = ({
  items,
  dotSelectItem,
  currentItem,
}: DotsNavigationProps) => {
  return (
    <Box display="flex" alignItems="center">
      {items.length > 1 ? (
        items.map((e, idx) => (
          <Dot
            mr={items.length > 1 ? theme.spacing(0.75) : 0}
            key={e.id}
            onClick={() => dotSelectItem(idx)}
            active={idx === currentItem ? 1 : 0}
          />
        ))
      ) : (
        <></>
      )}
    </Box>
  );
};

export default DotsNavigation;

import React, { FC, useState } from "react";
import { Typography } from "@mui/material";
import CryptoJS from "crypto-js";
import { InfoWrapper, FormWrapper } from "../styles";
import { useTheme } from "@mui/system";
import { MTheme } from "application/theme";
import { AuthPageWrapper } from "../AuthPageLayout";
import { CreatePasswordForm } from "../PasswordInputs/CreatePasswordForm";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmSdrRegistration } from "integration/api/sdrs";
import { PathNames } from "application/routes";
import { formatDateToYYYYMMDD } from "application/utils";
import { handleErrorNotification } from "common/errorService";
import LoadingButton from "application/components/Button/LoadingButton";

export const Registration: FC = () => {
  const theme: MTheme = useTheme();
  const [password, setPassword] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.search.split("?email=").pop()?.split("&")[0] ?? "";
  const code = location.search.split("code=")[1] ?? "";

  const onSubmit = async () => {
    if (password) {
      const date = formatDateToYYYYMMDD(new Date());
      const key = process.env.REACT_APP_CRYPTO_JS_KEY || "";
      const parsedKey = CryptoJS.enc.Base64.parse(key);
      const parsedPassword = CryptoJS.enc.Utf8.parse(password);
      const encryptedPassword = CryptoJS.AES.encrypt(
        parsedPassword,
        parsedKey,
        { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
      ).toString();
      try {
        setLoading(true);
        const response = await confirmSdrRegistration({
          password: encryptedPassword,
          code,
          email,
          activationDate: date ?? "",
        });

        if (response) {
          navigate(PathNames.login);
        }
      } catch (error) {
        handleErrorNotification(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <AuthPageWrapper>
      <InfoWrapper mt={12}>
        <Typography variant="h3" mb={1.5}>
          Welcome to BuilderBinder!
        </Typography>
        <Typography variant="h5" color={theme.palette.common.grey800} mb={6}>
          Please, create password for your account.
        </Typography>
        <FormWrapper>
          <CreatePasswordForm getPassword={setPassword} />
          <LoadingButton
            label="Set password and continue"
            type="submit"
            variant="contained"
            isLoading={loading}
            disabled={!password}
            width="100%"
            onClick={() => onSubmit()}
          />
        </FormWrapper>
      </InfoWrapper>
    </AuthPageWrapper>
  );
};

import React, { forwardRef, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  CompaniesListWrapper,
  CompaniesLoadingWrapper,
  CompanyListHeaderWrapper,
} from "./styles";

import { Box } from "@mui/material";

import CompanyItem from "./CompanyItem";

import { useAppDispatch, useAppSelector } from "application/store";

import { CompanyI } from "integration/api/companies/types";
import {
  companySelector,
  filterFormSelector,
} from "../store";

import StyledLoader from "common/loader/StyledLoader";
import CompaniesSortSelect from "./CompaniesSortSelect";
import { SearchResultInfo } from '../components';
import { useCompaniesSearch } from '../hooks/useCompaniesSearch';

interface CompaniesListProps {
  scrollToTop: () => void;
}

const CompaniesList = forwardRef<HTMLDivElement, CompaniesListProps>(({  scrollToTop }, ref) => {
  const {content, totalElements, numberOfElements, pageSize,totalPages,
    fetchCompaniesPage, changePageSize, pageNumber } = useCompaniesSearch();
  const selectedCompany = useAppSelector(companySelector);
  const filterForm = useAppSelector(filterFormSelector);
  const prevPageRef = React.useRef(-1);
  const [companiesList, setCompaniesList] = useState<CompanyI[]>([]);

  useEffect(() => {
    changePageSize(20);
  },[])

  const dispatch = useAppDispatch();

  useEffect(() => {
    setCompaniesList(content);
  }, [filterForm]);

  useEffect(() => {
    if(pageNumber === prevPageRef.current && pageNumber !== 0) {
      return;
    }
    setCompaniesList((prevContent) => {
      if (pageNumber === 0) {
        scrollToTop();
        return content;
      }
      return [...prevContent, ...content];
    });
    prevPageRef.current = pageNumber;
  }, [content, pageNumber]);

  const fetchNextPage = () => {
    fetchCompaniesPage(pageNumber + 1, false);
  };

  return (
    <CompaniesLoadingWrapper>
      <InfiniteScroll
        dataLength={companiesList.length}
        next={fetchNextPage}
        hasMore={
          companiesList.length >= 20 && companiesList.length < totalElements
        }
        height={"90vh"}
        className={'companies-infinite-scroll'}
        loader={
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <StyledLoader size={70} />
          </Box>
        }
      >
        <CompaniesListWrapper>
          <CompanyListHeaderWrapper>
            <SearchResultInfo
              totalElements={totalElements}
              ref={ref}
            />
            <CompaniesSortSelect/>
          </CompanyListHeaderWrapper>
          {companiesList.map((company: CompanyI) => (
            <CompanyItem
              company={company}
              key={company.id}
              active={company.id === selectedCompany.company.id}
            />
          ))}
        </CompaniesListWrapper>
      </InfiniteScroll>
    </CompaniesLoadingWrapper>
  );
});

export default CompaniesList;

import React from 'react';
import { Box } from '@mui/system';
import { usePermitsNavigation } from 'common/permits/hooks/usePermitsNavigation';
import { ResultsBarWrapper } from './styles';
import { Typography } from '@mui/material';
import { MapViewResults } from './MapViewResults';
import { TableViewResults } from './TableViewResults';
import theme from 'application/theme'
import {
  StartExportCompaniesButton
} from 'common/permits/components/largeBulkExport/components/StartExportCompaniesButton';
import { StartExportPermitsButton } from '../../largeBulkExport/components/StartExportPermitsButton';
import { StartExportButton } from '../../largeBulkExport/components/StartExportButton';

export const ResultsBar = () => {
  const {isMapView, isPermitTableMode} = usePermitsNavigation();
  return <ResultsBarWrapper>
    <Box display={'flex'} gap={1.5} alignItems={'center'}>
      <Typography variant="subtitle1"
        color={theme.palette.common.grey700}
      >Results:</Typography>
      {isMapView ?  <MapViewResults/> : <TableViewResults/>}
    </Box>
    {isMapView ? <StartExportButton/>
      : isPermitTableMode ? <StartExportPermitsButton/>
        : <StartExportCompaniesButton/>}
  </ResultsBarWrapper>
}


import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import theme from "application/theme";
import React from "react";
import { StickyHeaderWrapper, FiltersApplied } from "../styles";

interface Props {
  count: number;
  onSaveFilters: () => void;
}

const FilterHeader = ({ count, onSaveFilters }: Props) => {
  return (
    <StickyHeaderWrapper>
      <Box display={"flex"} gap={0.5} alignItems={"center"}>
        <Typography variant="h5" color={theme.palette.common.grey800}>
          Filters
        </Typography>
        {count ? <FiltersApplied>{count}</FiltersApplied> : null}
      </Box>
      <Box
        className="save-filter"
        display={"flex"}
        gap={2}
        alignItems={"center"}
        sx={{ cursor: count ? "pointer" : "not-allowed" }}
      >
        <Typography
          variant="body2"
          color={
            count ? theme.palette.common.blue700 : theme.palette.common.grey400
          }
          onClick={() => count && onSaveFilters()}
        >
          Save Filter
        </Typography>
      </Box>
    </StickyHeaderWrapper>
  );
};

export default FilterHeader;

import React from "react";

export const DotsIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons">
        <path
          id="Icon (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.3335 4.16667C8.3335 3.24619 9.07969 2.5 10.0002 2.5C10.9206 2.5 11.6668 3.24619 11.6668 4.16667C11.6668 5.08714 10.9206 5.83333 10.0002 5.83333C9.07969 5.83333 8.3335 5.08714 8.3335 4.16667ZM8.3335 10C8.3335 9.07953 9.07969 8.33333 10.0002 8.33333C10.9206 8.33333 11.6668 9.07953 11.6668 10C11.6668 10.9205 10.9206 11.6667 10.0002 11.6667C9.07969 11.6667 8.3335 10.9205 8.3335 10ZM8.3335 15.8333C8.3335 14.9129 9.07969 14.1667 10.0002 14.1667C10.9206 14.1667 11.6668 14.9129 11.6668 15.8333C11.6668 16.7538 10.9206 17.5 10.0002 17.5C9.07969 17.5 8.3335 16.7538 8.3335 15.8333Z"
          fill="#4D4D4D"
        />
      </g>
    </svg>
  );
};

import { styled, Box } from "@mui/material";
import theme from "application/theme";

export const FirstStepTutorialStyled = styled(Box)`
  width: 100%;
  border-radius: ${theme.spacing(1)};
  background-color: ${theme.palette.common.blue700};
  padding: ${theme.spacing(1.5)};
  display: flex;
  cursor: pointer;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`;

import React, { useState } from "react";

import { useFormContext } from "react-hook-form";

import { ServiceType } from "./const";

import { Box, Collapse, FormGroup } from "@mui/material";

import { CheckboxGroupStyled } from "./styles";
import { CollapseLabel, MultipleCheckboxController } from "./components";
import { ISelect } from "application/components/SelectComponent/types";
import { getServiceTypeLabel } from './utils';

const serviceTypeOptions = [
  { value: ServiceType.RESIDENTIAL, label: getServiceTypeLabel(ServiceType.RESIDENTIAL) },
  { value: ServiceType.COMMERCIAL, label: getServiceTypeLabel(ServiceType.COMMERCIAL) },
  {
    value: ServiceType.NOT_YET_DEFINED,
    label: getServiceTypeLabel(ServiceType.NOT_YET_DEFINED),
    tooltip: "we didn't indentify company as residential or commercial yet",
  },
];

const ServiceTypeCollapse = ({ label }: { label: string }) => {
  const { watch } = useFormContext();
  const [expanded, setExpanded] = useState(false);
  const { serviceType } = watch();

  return (
    <Box width={"100%"} className="service-type-collapse">
      <CollapseLabel
        label={label}
        expanded={expanded}
        setExpanded={setExpanded}
        count={serviceType?.length}
      />
      <Collapse in={expanded}>
        <CheckboxGroupStyled pt={1.5}>
          <FormGroup>
            {serviceTypeOptions.map((item: ISelect) => (
              <MultipleCheckboxController
                fieldName={"serviceType"}
                value={item.value}
                label={item.label}
                key={item.value}
                tooltip={item.tooltip}
              />
            ))}
          </FormGroup>
        </CheckboxGroupStyled>
      </Collapse>
    </Box>
  );
};

export default ServiceTypeCollapse;

import React, { useState } from "react";

import { Controller, useFormContext } from "react-hook-form";

import { Score } from "./const";

import { Box, Checkbox, Collapse, FormGroup } from "@mui/material";

import {
  CheckboxGroupStyled,
  CheckboxLabel,
  FormControlLabelStyled,
} from "./styles";

import { CollapseLabel } from "./components";
import { getReputationScoreLabel } from './utils';

const ReputationScoreCollapse = ({ label }: { label: string }) => {
  const [expanded, setExpanded] = useState(false);
  const { control, getValues, setValue, watch } = useFormContext();
  const { reputationScore } = watch();

  const ScoreOptions = [
    { value: Score.WONDERFUL, label: getReputationScoreLabel(Score.WONDERFUL) },
    { value: Score.VERY_GOOD, label: getReputationScoreLabel(Score.VERY_GOOD) },
    { value: Score.GOOD, label: getReputationScoreLabel(Score.GOOD) },
    { value: Score.PLEASANT, label: getReputationScoreLabel(Score.PLEASANT) },
  ];

  const handleClick = (value: string | null) => {
    if (value !== null) {
      const newValue = value === getValues("reputationScore") ? "" : value;
      setValue("reputationScore", newValue);
    }
  };

  return (
    <Box width={"100%"}>
      <CollapseLabel
        label={label}
        expanded={expanded}
        setExpanded={setExpanded}
        count={reputationScore ? 1 : 0}
      />
      <Collapse in={expanded}>
        <CheckboxGroupStyled pt={1.5}>
          <FormGroup>
            {ScoreOptions.map((item) => (
              <Controller
                key={item.value}
                name="reputationScore"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <FormControlLabelStyled
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value === item.value}
                        onChange={() => handleClick(item.value)}
                      />
                    }
                    label={<CheckboxLabel>{item.label}</CheckboxLabel>}
                  />
                )}
              />
            ))}
          </FormGroup>
        </CheckboxGroupStyled>
      </Collapse>
    </Box>
  );
};

export default ReputationScoreCollapse;

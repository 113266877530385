import React, {useState} from "react";
import Box from "@mui/material/Box";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { QuantityProps } from "./types";
import {
  IconButtonStyled,
  LabelQuantity,
  LabelWrapper,
  QuantityPickerInput,
  QuantityPickerWrapper,
} from "./styles";
import theme from "application/theme";
import { getNumberFormatWithSeparators } from 'application/utils';
import { NumericFormat } from 'react-number-format';

const QuantityPickerForTeamMembers = ({
  label,
  setValue,
  defaultValue = 0,
  error,
  availableCredits,
  min = 0,
  max = 9999,
}: QuantityProps) => {
  const [quantity, setQuantity] = useState(Math.max(defaultValue, min));

  const handleIncrement = () => {
    const newQuantity = Math.min(quantity + 1, max);
    setQuantity(newQuantity);
    if (setValue) {
      setValue(newQuantity);
    }
  };

  const handleDecrement = () => {
    const newQuantity = Math.max(quantity - 1, min);
    setQuantity(newQuantity);
    if (setValue) {
      setValue(newQuantity);
    }
  };

  const handleChangeQuantity = (quantity: number) => {
    let newQuantity = Math.min(quantity, max);
    newQuantity = Math.max(newQuantity, min);
    setQuantity(newQuantity);
    if (setValue) {
      setValue(newQuantity);
    }
  }

  const isValueAllowed = (values: any) => {
    if(values.value === '00') return false;
    return !(max && values.floatValue > max);
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <LabelWrapper
        variant="caption"
        color={
          error ? theme.palette.common.red600 : theme.palette.common.grey800
        }
      >
        {label}: <LabelQuantity>{getNumberFormatWithSeparators(availableCredits)}</LabelQuantity>
      </LabelWrapper>
      <QuantityPickerWrapper
        sx={{
          borderColor: error
            ? theme.palette.common.red600
            : theme.palette.common.grey400,
        }}
      >
        <IconButtonStyled onClick={handleDecrement} size="small">
          <ArrowLeft />
        </IconButtonStyled>
        <NumericFormat value={quantity}
          // @ts-ignore
                       customInput={QuantityPickerInput}
                       type="text"
                       thousandSeparator=','
                       onValueChange={(values) => handleChangeQuantity(values.floatValue || 0)}
                       isAllowed={isValueAllowed}
        />
        <IconButtonStyled onClick={handleIncrement} size="small">
          <ArrowRight />
        </IconButtonStyled>
      </QuantityPickerWrapper>
    </Box>
  );
};

export default QuantityPickerForTeamMembers;

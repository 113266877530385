import theme from "application/theme";
import React from "react";
import { FilledIconProps } from './types';

export const QuestionCircleIcon = (
  {
    color = theme.palette.common.blue800,
    fillColor = theme.palette.common.blue50,
  }: FilledIconProps) => {
  return (
    <svg width="83" height="82" viewBox="0 0 83 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="41.5" cy="41" r="41" fill={fillColor}/>
      <mask id="mask0_4266_70543" maskUnits="userSpaceOnUse" x="20" y="20" width="42" height="42">
        <rect x="20" y="20" width="42" height="42" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_4266_70543)">
        <path
          d="M38.5504 48C38.5504 45.6375 38.7618 43.9385 39.1848 42.9031C39.6077 41.8677 40.5046 40.7375 41.8754 39.5125C43.0712 38.4625 43.9827 37.551 44.6098 36.7781C45.2369 36.0052 45.5504 35.1229 45.5504 34.1313C45.5504 32.9354 45.1494 31.9437 44.3473 31.1562C43.5452 30.3688 42.4296 29.975 41.0004 29.975C39.5129 29.975 38.3827 30.4271 37.6098 31.3313C36.8368 32.2354 36.29 33.1542 35.9691 34.0875L31.4629 32.1625C32.0754 30.2958 33.1983 28.6771 34.8316 27.3063C36.465 25.9354 38.5212 25.25 41.0004 25.25C44.0629 25.25 46.4181 26.1031 48.066 27.8094C49.7139 29.5156 50.5379 31.5646 50.5379 33.9563C50.5379 35.4146 50.2244 36.6615 49.5973 37.6969C48.9702 38.7323 47.9858 39.9062 46.6441 41.2188C45.215 42.5896 44.3473 43.6323 44.041 44.3469C43.7348 45.0615 43.5816 46.2792 43.5816 48H38.5504ZM41.0004 58.5C40.0379 58.5 39.2139 58.1573 38.5285 57.4719C37.8431 56.7865 37.5004 55.9625 37.5004 55C37.5004 54.0375 37.8431 53.2135 38.5285 52.5281C39.2139 51.8427 40.0379 51.5 41.0004 51.5C41.9629 51.5 42.7868 51.8427 43.4723 52.5281C44.1577 53.2135 44.5004 54.0375 44.5004 55C44.5004 55.9625 44.1577 56.7865 43.4723 57.4719C42.7868 58.1573 41.9629 58.5 41.0004 58.5Z"
          fill={color}/>
      </g>
    </svg>
  );
};

import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";

import { useFormContext } from "react-hook-form";

import { CollapseLabel, MultipleCheckboxController } from "./components";
import { InputStyled } from "application/components/FormInput/styles";
import { ShowLessAndMore } from "../components";

import theme from "application/theme";

import { CheckboxGroupStyled } from "./styles";
import { Box, Collapse, FormGroup, Typography } from "@mui/material";
import { useAppSelector } from "application/store";
import { companySelector } from "../store";

const TagsCollapse = ({label}: { label: string }) => {
  const [expanded, setExpanded] = useState(false);
  const [searchTag, setSearchTag] = useState("");
  const [countTagsToDisplay, setCountTagsToDisplay] = useState(5);
  const tagsEndRef = useRef<HTMLDivElement | null>(null);
  const {watch} = useFormContext();
  const {tags} = useAppSelector(companySelector);
  const selectedTags = watch('tags');
  const tagsLength = selectedTags?.length;

  const filteredTags = useMemo(() => {
    return tags.filter((item: string | null) =>
      item ? item.toLowerCase().includes(searchTag.toLowerCase()) : null
    );
  }, [searchTag, tags]);

  const tagsToDisplay = useMemo(
    () => filteredTags.slice(0, countTagsToDisplay)
    , [filteredTags, countTagsToDisplay]);

  const hasMore = useMemo(() => filteredTags.length >= countTagsToDisplay,
    [filteredTags, countTagsToDisplay]);

  const handleShowMoreTags = (showMore: boolean) => {
    if (showMore) {
      setCountTagsToDisplay(prev => prev + 10);
    } else {
      setCountTagsToDisplay(5);
    }
  }

  useEffect(() => {
    if (tagsEndRef.current && countTagsToDisplay > 5) {
      tagsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  },[countTagsToDisplay]);

  const onChange = (e: any) => {
    setSearchTag(e.target.value);
  };

  return (
    <Box width={"100%"}>
      <CollapseLabel
        label={label}
        expanded={expanded}
        setExpanded={setExpanded}
        count={tagsLength}
      />
      <Collapse in={expanded}>
        {selectedTags && selectedTags.length > 0 &&
          <TagsCheckboxGroup tags={selectedTags}/>
        }
        {tags.length ? (
          <Box width={"100%"} pt={1.5}>
            <InputStyled
              font_size={theme.spacing(1.75)}
              sx={{width: "100%"}}
              name={"tagName"}
              value={searchTag}
              placeholder="Search tags"
              height={4}
              autofillcolor={theme.palette.common.grey150}
              onChange={onChange}
            />
          </Box>
        ) : (
          <Typography variant="caption">No available tags</Typography>
        )}
        <TagsCheckboxGroup
          ref={tagsEndRef}
          tags={tagsToDisplay}/>
        {tags?.length > 5 ? (
          <Box ml={1}>
            <ShowLessAndMore
              showAll={!hasMore}
              setShowAll={handleShowMoreTags}
              fontSize={theme.spacing(1.75)}
            />
          </Box>
        ) : null}
      </Collapse>
    </Box>
  );
};

export default TagsCollapse;

const TagsCheckboxGroup = forwardRef(({tags}: { tags: string[] }, ref: any) => {
  return (
    <CheckboxGroupStyled
      mt={1.5}
      height={tags.length > 5 ? 320 : 0}
    >
      <FormGroup>
        {tags.map((item: string) => (
          <MultipleCheckboxController
            fieldName={"tags"}
            value={item}
            label={item}
            key={item}
          />
        ))}
        <div ref={ref}/>
      </FormGroup>
    </CheckboxGroupStyled>
  )
});

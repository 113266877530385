import React from 'react';
import { ProductTour } from '../index';
import { companyInfoTutorialSteps, filtersTutorialSteps } from '../steps';
import { useTutorials } from '../hooks/useTutorials';

export const CompaniesTutorialTours  = () => {
  const {
    hasUnseenTutorials,
    tutorialCompanyInfo,
    tutorialHowToFilter,
    isCompanyFilterTutorialOpen,
    isCompanyInfoTutorialOpen,
    setCompanyFiltersTutorialIsOpen,
    setCompanyInfoTutorialIsOpen,
  } = useTutorials();
  return hasUnseenTutorials ? (
    <>
      {tutorialHowToFilter ? (
        <ProductTour
          steps={filtersTutorialSteps}
          setIsTourOpen={setCompanyFiltersTutorialIsOpen}
          isTourOpen={isCompanyFilterTutorialOpen}
        />
      ) : null}
      {tutorialCompanyInfo ? (
        <ProductTour
          steps={companyInfoTutorialSteps}
          setIsTourOpen={setCompanyInfoTutorialIsOpen}
          isTourOpen={isCompanyInfoTutorialOpen}
        />
      ) : null}
    </>
  ) : null;
}
import { CustomTooltip } from "application";
import { ShowOnMap } from "application/assets/ShowOnMap";
import { PathNames } from "application/routes";
import { useAppDispatch, useAppSelector } from "application/store";
import theme from "application/theme";
import { usePermitFiltersContext } from "common/permits/hooks/usePermitFiltersContext";
import {
  permitsSelector,
  setMapInitialView,
  setShowOnMap, setShowOnMapItemId,
  setShowTooltip,
} from "common/permits/store";
import { handleViewOnMapSearch } from "common/permits/store/actions";
import { PermitsInfo, PermitsView } from "common/permits/types";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ShowOnMapWrapper } from "../styles";

export const ShowOnMapButton = ({ itemInfo }: { itemInfo: PermitsInfo }) => {
  const navigate = useNavigate();
  const { searchChanged } = useAppSelector(permitsSelector);
  const dispatch = useAppDispatch();
  const { companyFilterForm, permitFilterForm } = usePermitFiltersContext();
  const permitFilter = permitFilterForm.watch();
  const companyFilter = companyFilterForm.watch();
  const filterData = { permitFilter, companyFilter };

  const disabled = !itemInfo?.latitude || !itemInfo?.longitude;

  const handleViewOnMap = () => {
    if (!itemInfo?.latitude || !itemInfo?.longitude) return;
    if (searchChanged) {
      dispatch(handleViewOnMapSearch(filterData));
      dispatch(setShowOnMap(true));
    }
    dispatch(
      setMapInitialView({
        latitude: itemInfo.latitude,
        longitude: itemInfo.longitude,
        zoom: 15,
      })
    );
    dispatch(setShowOnMapItemId(itemInfo.id));
    dispatch(setShowTooltip(true));
    navigate(`${PathNames.permits}/${PermitsView.MAP}`);
  };

  return (
    <CustomTooltip title={disabled ? "No coordinates" : "Show on map"}>
      <ShowOnMapWrapper onClick={handleViewOnMap} disabled={disabled}>
        <ShowOnMap
          color={
            disabled
              ? theme.palette.common.grey500
              : theme.palette.common.blue800
          }
        />
      </ShowOnMapWrapper>
    </CustomTooltip>
  );
};

import React, { FC } from "react";
import { SurveyIntro } from "common/survey/surveyIntro/SurveyIntro";
import { SurveyFormWrapper } from "common/survey";
import { Grid } from "@mui/material";

const SurveyPage: FC = () => {
  return (
    <Grid container height={"100%"}>
      <Grid item xs={6} p={4}>
        <SurveyIntro />
      </Grid>
      <Grid item xs={6} px={5} py={4.5}>
        <SurveyFormWrapper />
      </Grid>
    </Grid>
  );
};

export default SurveyPage;

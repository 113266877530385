import React from 'react';
import { useAppSelector } from 'application/store';
import { FiltersCollapse } from 'application';
import { FilterGroupWrapper, FiltersWrapper } from '../components/styles';
import { CheckedFilterItem } from '../components';
import { permitFiltersSelector } from 'common/permits/store';

export const DwellingTypeFilter = () => {
  const {dwellingTypes} = useAppSelector(permitFiltersSelector);
  return dwellingTypes && dwellingTypes.length > 0 ? (
    <FiltersCollapse label={'DWELLING TYPES'} count={dwellingTypes.length}>
      <FiltersWrapper>
        <FilterGroupWrapper>
          {dwellingTypes.map((dwellingType) => (
            <CheckedFilterItem
              key={dwellingType}
              label={dwellingType}/>
          ))}
        </FilterGroupWrapper>
      </FiltersWrapper>
    </FiltersCollapse>
  ) : null;
};

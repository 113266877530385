import { Box } from "@mui/system";
import React from "react";
import { TextItemLabel } from "./styles";
import { Typography } from "@mui/material";
import theme from "application/theme";

interface TextItemProps {
  label: string;
  value: string | null;
}

const TextItem = ({ label, value }: TextItemProps) => {
  return (
    <Box display="flex" mb={theme.spacing(0.875)}>
      <Box>
        <Box width={theme.spacing(13.5)}>
          <TextItemLabel variant="caption">{label}</TextItemLabel>
        </Box>
      </Box>
      <Typography
        variant="caption"
        fontWeight={500}
        color={theme.palette.common.grey800}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default TextItem;

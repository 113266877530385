import { PageRequestI, ThunkDispatchT, VoidThunk } from 'application/types';
import {
  setBulkExportProcesses,
  setNumberOfExportedCompanies,
  setNumberOfExportedPermits,
  setPermitBulkExportProcesses
} from './index';
import { CompanyFilterDataI } from 'integration/api/companies/types';
import {
  exportFilteredCompanies,
  exportFilteredCompaniesByPermitFilters,
  exportFilteredCompaniesOnMapByPermitFilters,
  exportFoundPermits,
  exportFoundPermitsFromMap,
  getCompaniesBulkExportProcesses,
  getNumberOfExportedCompanies,
  getNumberOfExportedCompaniesByPermitFilters,
  getNumberOfExportedCompaniesOnMapByPermitFilters,
  getNumberOfExportedPermitsByPermitFilters,
  getNumberOfExportedPermitsOnMapByPermitFilters,
  getPermitsBulkExportProcesses,
  reexportCompanyBulkExportProcess,
  reexportPermitBulkExportProcess
} from 'integration/api/largeBulkExport';
import { prepareToSearch } from 'common/companies/store/utils';
import { handleErrorNotification, hideLoader, showLoader } from 'common';
import { SortBy } from 'common/companies/companiesFilter/const';
import { ExportTo } from 'common/companies/types';
import { PermitWithCompanyFilterDataI } from '../../permits/types';
import { prepareCompanyWithPermitFiltersToSearch } from '../../permits/utils';


export const handleGetNumberOfExportedCompanies = (
  filterData: CompanyFilterDataI,
  pageRequest: PageRequestI<SortBy>,
) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    const preparedFilter = prepareToSearch(filterData);
    try {
      const response = await getNumberOfExportedCompanies(
        preparedFilter,
        pageRequest
      );
      dispatch(setNumberOfExportedCompanies(response.alreadyExportedCompaniesAmount));
      return true;
    } catch (error) {
      handleErrorNotification(error);
      return false;
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const handleGetNumberOfExportedCompaniesByPermitFilters = (
  filterData: PermitWithCompanyFilterDataI,
  pageRequest: PageRequestI<SortBy>,
) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    const preparedFilter = prepareCompanyWithPermitFiltersToSearch(filterData);
    try {
      const response = await getNumberOfExportedCompaniesByPermitFilters(
        preparedFilter,
        pageRequest
      );
      dispatch(setNumberOfExportedCompanies(response.alreadyExportedCompaniesAmount));
      return true;
    } catch (error) {
      handleErrorNotification(error);
      return false;
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const handleGetNumberOfExportedCompaniesOnMapByPermitFilters = (
  filterData: PermitWithCompanyFilterDataI,
  pageRequest: PageRequestI<SortBy>,
) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    const preparedFilter = prepareCompanyWithPermitFiltersToSearch(filterData);
    try {
      const response = await getNumberOfExportedCompaniesOnMapByPermitFilters(
        preparedFilter,
        pageRequest
      );
      dispatch(setNumberOfExportedCompanies(response.alreadyExportedCompaniesAmount));
      return true;
    } catch (error) {
      handleErrorNotification(error);
      return false;
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const handleGetNumberOfExportedPermitsByCompanyAndPermitFilters = (
  filterData: PermitWithCompanyFilterDataI,
  pageRequest: PageRequestI<SortBy>,
) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    const preparedFilter = prepareCompanyWithPermitFiltersToSearch(filterData);
    try {
      const response = await getNumberOfExportedPermitsByPermitFilters(
        preparedFilter,
        pageRequest
      );
      dispatch(setNumberOfExportedPermits(response.alreadyExportedPermitsAmount));
      return true;
    } catch (error) {
      handleErrorNotification(error);
      return false;
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const handleGetNumberOfExportedPermitsOnMapByCompanyAndPermitFilters = (
  filterData: PermitWithCompanyFilterDataI,
  pageRequest: PageRequestI<SortBy>,
) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    const preparedFilter = prepareCompanyWithPermitFiltersToSearch(filterData);
    try {
      const response = await getNumberOfExportedPermitsOnMapByPermitFilters(
        preparedFilter,
        pageRequest
      );
      dispatch(setNumberOfExportedPermits(response.alreadyExportedPermitsAmount));
      return true;
    } catch (error) {
      handleErrorNotification(error);
      return false;
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const handleExportFilteredCompanies = (
  filterData: CompanyFilterDataI,
  pageRequest: PageRequestI<SortBy>,
  exportTo: ExportTo
) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    const preparedFilter = prepareToSearch(filterData);
    try {
      await exportFilteredCompanies(
        preparedFilter,
        pageRequest,
        exportTo
      );
      return true;
    } catch (error) {
      handleErrorNotification(error);
      return false;
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const handleExportCompaniesByPermitFilters = (
  filterData: PermitWithCompanyFilterDataI,
  pageRequest: PageRequestI<SortBy>,
  exportTo: ExportTo
) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    const preparedFilter = prepareCompanyWithPermitFiltersToSearch(filterData);
    try {
      await exportFilteredCompaniesByPermitFilters(
        preparedFilter,
        pageRequest,
        exportTo
      );
      return true;
    } catch (error) {
      handleErrorNotification(error);
      return false;
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const handleExportCompaniesOnMap = (
  filterData: PermitWithCompanyFilterDataI,
  pageRequest: PageRequestI<SortBy>,
  exportTo: ExportTo
) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    const preparedFilter = prepareCompanyWithPermitFiltersToSearch(filterData);
    try {
      await exportFilteredCompaniesOnMapByPermitFilters(
        preparedFilter,
        pageRequest,
        exportTo
      );
      return true;
    } catch (error) {
      handleErrorNotification(error);
      return false;
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const handleExportPermits = (
  filterData: PermitWithCompanyFilterDataI,
  pageRequest: PageRequestI<SortBy>,
) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    const preparedFilter = prepareCompanyWithPermitFiltersToSearch(filterData);
    try {
      await exportFoundPermits(
        preparedFilter,
        pageRequest,
      );
      return true;
    } catch (error) {
      handleErrorNotification(error);
      return false;
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const handleExportPermitsFromMap = (
  filterData: PermitWithCompanyFilterDataI,
  pageRequest: PageRequestI<SortBy>,
) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    const preparedFilter = prepareCompanyWithPermitFiltersToSearch(filterData);
    try {
      await exportFoundPermitsFromMap(
        preparedFilter,
        pageRequest,
      );
      return true;
    } catch (error) {
      handleErrorNotification(error);
      return false;
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const handleFetchCompaniesBulkExportProcesses = (
  organizationId: number,
  pageRequest: PageRequestI<string>,
  withLoader = true
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    withLoader && dispatch(showLoader());
    try {
      const response = await getCompaniesBulkExportProcesses(organizationId, pageRequest);
      dispatch(setBulkExportProcesses(response));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const handleFetchPermitsBulkExportProcesses = (
  organizationId: number,
  pageRequest: PageRequestI<string>,
  withLoader = true
): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    withLoader && dispatch(showLoader());
    try {
      const response = await getPermitsBulkExportProcesses(organizationId, pageRequest);
      dispatch(setPermitBulkExportProcesses(response));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const handleReexportCompanyBulkExportProcess = (
  bulkExportProcessId: number,
  exportTo: ExportTo
) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await reexportCompanyBulkExportProcess(bulkExportProcessId, exportTo);
      return true;
    } catch (error) {
      handleErrorNotification(error);
      return false;
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const handleReexportPermitBulkExportProcess = (
  bulkExportProcessId: number
) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await reexportPermitBulkExportProcess(bulkExportProcessId);
      return true;
    } catch (error) {
      handleErrorNotification(error);
      return false;
    } finally {
      dispatch(hideLoader());
    }
  };
}
import { Checkbox } from "@mui/material";
import { CustomTooltip } from "application";
import { UncheckIcon } from "application/assets";
import { useAppDispatch } from "application/store";
import { NotGoodFitControlStyled } from "common/companies/companiesListExtended/components/CompanyTableItem/styles";
import { useCompaniesExtraInfo } from "common/companies/hooks/useCompaniesExtraInfo";
import { handleChangeOrganizationNotAGoodFitStatus } from "common/companies/store/actions";
import { CompanyI } from "integration/api/companies/types";
import React, { useEffect } from "react";

export const NotGoodFit = ({
  itemInfo,
}: {
  itemInfo: CompanyI;
}): JSX.Element => {
  const [notGoodFit, setNotGoodFit] = React.useState(false);
  const { companiesExtraInfo } = useCompaniesExtraInfo();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const extraInfo = companiesExtraInfo.find(
      (info) => info.companyId === itemInfo.id
    );
    if (extraInfo) {
      setNotGoodFit(extraInfo.isInNotGoodFit);
    }
  }, [companiesExtraInfo]);

  const handleSetAsNotAGoodFit = async () => {
    const isSuccessful = await dispatch(
      handleChangeOrganizationNotAGoodFitStatus(itemInfo.id, !notGoodFit)
    );
    if (isSuccessful) {
      setNotGoodFit(!notGoodFit);
    }
  };

  return (
    <CustomTooltip title="Not good fit">
      <NotGoodFitControlStyled
        control={
          <Checkbox
            checked={notGoodFit}
            onChange={handleSetAsNotAGoodFit}
            checkedIcon={<UncheckIcon />}
          />
        }
        label={<></>}
      />
    </CustomTooltip>
  );
};

import React, { useEffect, useRef, useState } from "react";

import {
  AutoSuggestLoaderWrapper,
  ListboxWrapper,
  StyledAutocomplete,
  TotalResultsLoaderWrapper,
  TotalResultsStyled,
} from "./styles";
import { SearchInput } from "./SearchInput";
import { AutosuggestOption } from "./AutosuggestOption";
import { Controller } from "react-hook-form";
import { LoaderOverlap } from "common/loader";
import { useCompanySearchAutocomplete } from "../hooks/useCompanySearchAutocomplete";
import { usePermitFiltersContext } from "common/permits/hooks/usePermitFiltersContext";
import { usePermitsNavigation } from "common/permits/hooks/usePermitsNavigation";
import {
  handleGetCompanyAndPermitMapView,
  handleSearchCompaniesAndPermitsOnBackground,
  handleSearchPermitsAndCompaniesOnBackground,
} from "common/permits/store/actions";
import { useAppDispatch, useAppSelector } from "application/store";
import { permitsSelector } from "common/permits/store";

const MIN_NAME_LENGTH = 3;
const AUTO_SEARCH_TIMEOUT = 1000;
export const CompanyPermitSearchAutocomplete = () => {
  const [searchWord, setSearchWord] = useState("");
  const { zoomedArea, searchInArea } = useAppSelector(permitsSelector);
  const { companyFilterForm, permitFilterForm } = usePermitFiltersContext();
  const { watch, control, setValue } = companyFilterForm;
  const dispatch = useAppDispatch();
  const {
    isOptionsLoading,
    options,
    fetchOptions,
    clearOptions,
    totalResults,
    isTotalResultsAreLoading,
  } = useCompanySearchAutocomplete();
  const { isMapView, isPermitTableMode } = usePermitsNavigation();
  const optionSelectedRef = useRef<string>("");
  const handleFetchOptions = () => {
    setValue("companyId", null);
    const permitFilter = permitFilterForm.watch();
    const companyFilter = companyFilterForm.watch();
    if (companyFilter.name === optionSelectedRef.current) {
      return;
    }
    clearOptions();
    if (companyFilter.name && companyFilter.name.length >= MIN_NAME_LENGTH) {
      fetchOptions({ permitFilter, companyFilter });
    }
  };
  const handleSearchForCompanies = () => {
    setValue("companyId", null);
    const permitFilter = permitFilterForm.watch();
    const companyFilter = companyFilterForm.watch();
    const filterData = { permitFilter, companyFilter };

    if (isMapView) {
      dispatch(handleGetCompanyAndPermitMapView(filterData, true));
    } else {
      if (isPermitTableMode) {
        dispatch(handleSearchPermitsAndCompaniesOnBackground(filterData, 0, 20, true, searchInArea ? zoomedArea : undefined));
      } else {
        dispatch(handleSearchCompaniesAndPermitsOnBackground(filterData, 0, 20, true, searchInArea ? zoomedArea : undefined));
      }
    }
  };
  const handleSelectOption = async (companyId: number, name: string) => {
    setValue("name", name);
    setValue("companyId", companyId);
    optionSelectedRef.current = name;
    const permitFilter = permitFilterForm.watch();
    const companyFilter = companyFilterForm.watch();
    const filterData = { permitFilter, companyFilter };
    if (isMapView) {
      dispatch(handleGetCompanyAndPermitMapView(filterData, true));
    } else {
      if (isPermitTableMode) {
        dispatch(handleSearchPermitsAndCompaniesOnBackground(filterData, 0, 20, true));
      } else {
        dispatch(handleSearchCompaniesAndPermitsOnBackground(filterData, 0, 20, true));
      }
    }
    optionSelectedRef.current = "";
  };
  const handleClearSearch = () => {
    setValue("name", null);
    setValue("companyId", null);
    clearOptions();
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleFetchOptions();
    }, AUTO_SEARCH_TIMEOUT);
    return () => clearTimeout(delayDebounceFn);
  }, [searchWord]);

  const CustomListbox = React.forwardRef(
    (props: React.HTMLAttributes<HTMLElement>, ref) => {
      const { children, ...other } = props;
      return (
        <ListboxWrapper component="ul" role="listbox" {...other}>
          {children}
          <TotalResultsStyled onClick={handleSearchForCompanies}>
            {isTotalResultsAreLoading ? (
              <TotalResultsLoader />
            ) : (
              `Show all ${totalResults} companies`
            )}
          </TotalResultsStyled>
        </ListboxWrapper>
      );
    }
  );

  return (
    <Controller
      name="name"
      control={control}
      defaultValue={""}
      render={({ field }) => (
        <StyledAutocomplete
          clearOnBlur={false}
          inputValue={field.value ?? ""}
          ListboxComponent={CustomListbox}
          ListboxProps={{
            style: {
              maxHeight: "100%",
            },
          }}
          onInputChange={(event, newInputValue) => {
            setSearchWord(newInputValue);
            field.onChange(newInputValue);
          }}
          loading={isOptionsLoading}
          loadingText={<AutoSuggestLoader />}
          id="comppany-autosuggest-box"
          options={options}
          filterOptions={(array, state) => array}
          renderInput={(params) => (
            <SearchInput
              params={params}
              value={field.value ?? ""}
              setValue={field.onChange}
              disabled={isOptionsLoading}
              placeholder={"Search by company name or website ..."}
              onClearSearch={handleClearSearch}
            />
          )}
          getOptionLabel={(option: any) => option.businessName}
          renderOption={(props, option: any) => (
            <AutosuggestOption
              key={option.companyId}
              props={props}
              option={option}
              onSelect={(companyId) => {
                handleSelectOption(companyId, option.businessName);
              }}
            />
          )}
        />
      )}
    />
  );
};

const AutoSuggestLoader = () => {
  return (
    <AutoSuggestLoaderWrapper>
      <LoaderOverlap isLoading={true} size={40} />
    </AutoSuggestLoaderWrapper>
  );
};

const TotalResultsLoader = () => {
  return (
    <TotalResultsLoaderWrapper>
      <LoaderOverlap isLoading={true} size={20} />
    </TotalResultsLoaderWrapper>
  );
};

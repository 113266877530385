import { Box, styled } from "@mui/system";
import theme from "application/theme";

export const SettingsBarWrapper = styled(Box)`
  display: flex;
  padding: ${theme.spacing(1)};
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  background: ${theme.palette.common.grey400};
`;

export const ButtonsWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

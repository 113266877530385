import { styled } from "@mui/material";
import { Box } from "@mui/system";
import theme from "application/theme";

export const ExportedListItemStyled = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${theme.spacing(3)};
  height: ${theme.spacing(7.5)};
  padding: ${theme.spacing(0, 2.5)};
  border-bottom: 1px solid ${theme.palette.common.grey200};
`;
import theme from "application/theme";
import React, { useState } from "react";
import useToggleModal from "application/hooks/useToggleModal";
import TopInfoMessage from "application/components/TopInfoMessage";
import { AccountCircle } from "application/assets/AccountCircle";
import { ContactSalesModal } from "common/organization/modals/ContactSalesModal";

const ContactSalesMessage = () => {
  const { isOpen, openModal, closeModal } = useToggleModal();
  const [openMessage, setOpenMessage] = useState(true);

  return (
    <>
      {openMessage ? (
        <TopInfoMessage
          onClick={openModal}
          onClose={() => {
            setOpenMessage(false);
          }}
          label="You have reached the maximum number of seats"
          buttonText="Contact Sales"
          icon={<AccountCircle color={theme.palette.common.blue800} />}
        />
      ) : (
        <></>
      )}
      <ContactSalesModal isOpen={isOpen} handleClose={closeModal} />
    </>
  );
};
export default ContactSalesMessage;

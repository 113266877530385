import React from 'react'
import { Modal } from "@mui/material";
import { CloseButtonStyled, CompanyInfoModalWrapper } from './styles';
import { CloseOutlined } from '@mui/icons-material';
import CompanyInfo from 'common/companies/companiesInfo';


interface Props {
  isOpen: boolean;
  onClose: () => void;
  showTutorial?: boolean
}
const CompanyInfoModal = ({isOpen, onClose, showTutorial = true}:Props) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        "& :focus": {
          outline: "none",
        },
      }}
    >
      <CompanyInfoModalWrapper>
        <CloseButtonStyled onClick={onClose}>
          <CloseOutlined/>
        </CloseButtonStyled>
        <CompanyInfo showTutorial={showTutorial} />
      </CompanyInfoModalWrapper>
    </Modal>
  )
}

export default CompanyInfoModal;
import React from 'react';
import { useAppSelector } from 'application/store';
import { filterFormSelector } from 'common/companies/store';
import { FiltersCollapse } from 'application';
import { FiltersWrapper, FilterGroupWrapper } from './styles';
import { CheckedFilterItem } from './CheckedFilterItem';

export const TagsFilter = () => {
  const {tags} = useAppSelector(filterFormSelector);
  return tags && tags.length > 0 ? (
    <FiltersCollapse label={'TAGS'} count={tags.length}>
      <FiltersWrapper>
        <FilterGroupWrapper>
          {tags.map((tag) => (
            <CheckedFilterItem
              key={tag}
              label={tag}/>
          ))}
        </FilterGroupWrapper>
      </FiltersWrapper>
    </FiltersCollapse>
  ) : null;
};

import React from "react";
import * as yup from "yup";
import { useForm, UseFormReturn } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PermitFilterDataI, PermitStatusI } from "common/permits/types";
import { CompanyFilterDataI } from "integration/api/companies/types";
import { permitFilterInitialState } from '../store';

export interface PermitsFormStateI {
  permitFilterForm: UseFormReturn<PermitFilterDataI, any>;
  companyFilterForm: UseFormReturn<CompanyFilterDataI, any>;
}

export interface InitialStateI {
  affiliations: never[];
  statusFilter: PermitStatusI[];
  locations: {
    statesInclude: string[];
    statesExclude: string[];
    citiesInclude: string[];
    citiesExclude: string[];
    zipCodesInclude: string[];
    zipCodesExclude: string[];
    countiesInclude: string[];
    countiesExclude: string[];
  };
  
}

export const PermitFiltersContext = React.createContext<PermitsFormStateI>(
  {} as PermitsFormStateI
);

export const PermitFiltersContextProvider = (props: any) => {
  const validationSchema = yup.object().shape({});
  const permitFilterForm = useForm<PermitFilterDataI>({
    resolver: yupResolver(validationSchema),
    defaultValues: permitFilterInitialState, //todo get data from storage
  });

  const companyFilterForm = useForm<CompanyFilterDataI>({
    resolver: yupResolver(validationSchema),
  });

  return (
    <PermitFiltersContext.Provider
      value={{
        permitFilterForm,
        companyFilterForm,
      }}
    >
      {props.children}
    </PermitFiltersContext.Provider>
  );
};

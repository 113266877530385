import { Step } from "react-joyride";

export const filtersTutorialSteps: Step[] = [
  {
    target: ".service-type-collapse",
    content:
      "Filter businesses by Residential or Commercial. Businesses categorized by both Residential and Commercial will appear when either category is selected.",
    placement: "right",
    title: "How to filter companies?",
    disableBeacon: true,
  },
  {
    target: ".company-type-collapse",
    content:
      "Select either General Contractor or Subcontractor. Selecting Subcontractor will open options for the different categories of trades businesses in our database.",
    placement: "right",
    title: "How to filter companies?",
    disableBeacon: true,
  },
  {
    target: ".has-permits-collapse",
    content:
      "Permits are typically required for specific types of construction work, but the rules and requirements vary by location. Toggle this button to view only the businesses that we've matched to permit activity.",
    placement: "right",
    title: "How to filter companies?",
    disableBeacon: true,
  },
  {
    target: ".save-filter",
    content:
      "If you're consistently toggling the same filters, use this button to save your search settings. It'll be saved here until you delete the search.",
    placement: "right",
    title: "How to filter companies?",
    disableBeacon: true,
  },
];

export const companyInfoTutorialSteps: Step[] = [
  {
    target: ".general-company-info",
    content:
      "This is where we display all the general information we can find on the company. All companies will have a website to view. We will also display, when available, addresses, social media, revenue, and years in business.",
    placement: "left",
    title: "How to use company info?",
    disableBeacon: true,
  },
  {
    target: ".permits-company-info",
    content:
      "When a business has permits matched, we display the permit date, value, description, and state in which the permit was pulled.",
    placement: "left",
    title: "How to use company info?",
    disableBeacon: true,
  },
  {
    target: ".org-structure-info",
    content:
      "Click the button to return a list of employees related to the business. Use their job titles and locations to target the right people.",
    placement: "left",
    title: "How to use company info?",
    disableBeacon: true,
  },
];

import React from "react";
import theme from 'application/theme';
import { IconProps } from './types';

export const ProcessingIcon: React.FC<IconProps> = ({color = theme.palette.common.grey900}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <mask id="mask0_4781_24970" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="14">
        <rect width="14" height="14" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_4781_24970)">
        <path
          d="M11.9315 7.52053C11.7694 9.04297 10.9068 10.4696 9.47938 11.2938C7.10785 12.663 4.07538 11.8504 2.70617 9.47888L2.56034 9.22629M2.069 6.47887C2.23105 4.95643 3.09368 3.52976 4.52109 2.70565C6.89263 1.33644 9.9251 2.14899 11.2943 4.52052L11.4401 4.77311M2.03809 10.5382L2.46512 8.94448L4.05881 9.37151M9.9417 4.62787L11.5354 5.0549L11.9624 3.4612"
          stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
      </g>
    </svg>
  );
};


import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import theme from "application/theme";
import React, { useEffect, useMemo, useState } from "react";
import { PasswordInput } from "./PasswordInput";
import { PasswordValidationText } from "./PasswordValidationText";

export const CreatePasswordForm = ({
  getPassword,
  width,
  passwordLabel = "Set password",
  confirmPasswordLabel = "Confirm your password",
}: {
  getPassword: React.Dispatch<React.SetStateAction<string | null>>;
  width?: string | number;
  passwordLabel?: string;
  confirmPasswordLabel?: string;
}) => {
  const [characterCount, setCharactersCount] = useState<boolean | null>(null);
  const [upperCase, setUpperCase] = useState<boolean | null>(null);
  const [lowerCase, setLowerCase] = useState<boolean | null>(null);
  const [isNumberIn, setIsNumberIn] = useState<boolean | null>(null);
  const [specialCharacter, setSpecialCharacter] = useState<boolean | null>(
    null
  );

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const passwordErrors = useMemo(
    () =>
      characterCount ||
      upperCase ||
      lowerCase ||
      isNumberIn ||
      specialCharacter,
    [characterCount, upperCase, lowerCase, isNumberIn, specialCharacter]
  );

  const confirmPasswordError =
    confirmPassword !== "" && password !== confirmPassword;

  const hasCapitalLetter = /[A-Z]/;
  const hasLowerCase = /[a-z]/;
  const hasNumber = /\d/;
  const hasSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  const handleValidate = (value: string) => {
    setCharactersCount(value.length < 10);
    setUpperCase(!hasCapitalLetter.test(value));
    setLowerCase(!hasLowerCase.test(value));
    setIsNumberIn(!hasNumber.test(value));
    setSpecialCharacter(!hasSpecialCharacter.test(value));
  };

  useEffect(() => {
    if (password.length > 2) {
      handleValidate(password);
    } else {
      setCharactersCount(null);
      setUpperCase(null);
      setLowerCase(null);
      setIsNumberIn(null);
      setSpecialCharacter(null);
    }
  }, [password]);

  useEffect(() => {
    if (!confirmPasswordError) {
      getPassword(confirmPassword);
    } else {
      getPassword(null);
    }
  }, [confirmPassword, password]);

  return (
    <>
      <Box mb={2.25} width={width}>
        <PasswordInput
          label={passwordLabel}
          setValue={setPassword}
          customError={passwordErrors}
        />
        <Typography
          variant="caption"
          color={
            passwordErrors
              ? theme.palette.common.red700
              : theme.palette.common.grey800
          }
          sx={{ display: "flex", alignSelf: "start" }}
          mb={0.5}
          mt={0.5}
        >
          Your password must have
        </Typography>
        <PasswordValidationText
          helperText="Minimum 10 symbols"
          validationParam={characterCount}
        />
        <PasswordValidationText
          helperText="At least 1 uppercase"
          validationParam={upperCase}
        />
        <PasswordValidationText
          helperText="At least 1 lowercase"
          validationParam={lowerCase}
        />
        <PasswordValidationText
          helperText="At least 1 number"
          validationParam={isNumberIn}
        />
        <PasswordValidationText
          helperText="At least 1 special character"
          validationParam={specialCharacter}
        />
      </Box>
      <Box mb={3.5} width={width}>
        <PasswordInput
          label={confirmPasswordLabel}
          setValue={setConfirmPassword}
          customError={confirmPasswordError}
          errorMessage="Passwords must match"
        />
      </Box>
    </>
  );
};

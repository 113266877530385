
export const getTimeByTimeZone = (timezoneOffset: string) => {
  const timezoneParts = timezoneOffset.match(/([+-])(\d{1,2}):?(\d{2})?/);
  if (!timezoneParts) return null;

  const [, sign, hour, minute] = timezoneParts;

  const utcOffsetMinutes =
    sign === "-"
      ? -Number(hour) * 60 - Number(minute || 0)
      : Number(hour) * 60 + Number(minute || 0);

  const now = new Date();
  const utcTime = new Date(now.getTime() + utcOffsetMinutes * 60 * 1000);

  const utcOffsetString = `UTC${sign}${hour}:${minute || "00"}`;

  const formattedTime = utcTime.toLocaleString(undefined, {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return `${utcOffsetString} (${formattedTime})`;
};

export const formatDate = (dateString: string | Date) => {
  const date = new Date(dateString);
  const month = date.toLocaleString("en-us", { month: "short" });
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
};

export const formatDateToYYYYMMDD = (date: Date) => {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 101).toString().slice(-2);
  const day = (date.getDate() + 100).toString().slice(-2);
  return `${year}-${month}-${day}`;
};

export const calculateDaysUntilDate = (targetDate: string) => {
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

  const today = new Date();

  const target = new Date(targetDate);

  const timeDifference = target.getTime() - today.getTime();
  return Math.ceil(timeDifference / oneDayInMilliseconds);
};


export const extractDomain = (link: string) => {
  if (!link.length) return "-";
  let domain = link.replace(/^(https?:\/\/)?/, "");

  domain = domain.split("/")[0];

  const maxLength = 30;
  if (domain.length > maxLength) {
    domain = domain.substring(0, maxLength - 3) + "...";
  }

  return domain;
};

export const getAbsoluteLink = (link?: string) => {
    if (!link) return null;
    if (link.includes('http')) return link;
    return `http://${link}`;
}

export const mergeTwoArrays = (arr1?: string[] | null, arr2?: string[] | null) => {
  arr1 = arr1 ? arr1 : [];
  arr2 = arr2 ? arr2 : [];
  return [...new Set([...arr1, ...arr2])];
}

export {
  getNumberFormatWithSeparators,
  formatLargeNumbers,
  getPercents
} from './utils/numberUtils';

import { useAppDispatch, useAppSelector } from 'application/store';
import useUpdateLastActivityDate from 'application/hooks/useUpdateLastActivityDate';
import { filteredCompaniesSelector, filterFormSelector, setFilterFormData, setPageSize } from 'common/companies/store';
import { handleSearchCompaniesWithoutLoader, handleSearchCompanies } from 'common/companies/store/actions';
import { CompanyFilterDataI } from 'integration/api/companies/types';
import { cloneDeep } from 'lodash';

export const useCompaniesSearch = () => {
  const dispatch = useAppDispatch();
  const { updateLastActivityDate } = useUpdateLastActivityDate();
  const filteredCompanies = useAppSelector(
    filteredCompaniesSelector
  );
  const filterForm = useAppSelector(filterFormSelector);
  const changePageSize = (pageSize: number) => {
    dispatch(setPageSize(pageSize));
  }

  const searchForCompanies = (filterData: CompanyFilterDataI, page = 0, pageSize = 20) => {
    dispatch(setFilterFormData(cloneDeep(filterData)));
    updateLastActivityDate();
    return dispatch(handleSearchCompanies(filterData, page, pageSize));
  }

  const fetchCompaniesPage = (page: number, withLoader = false) => {
    if (withLoader) {
      dispatch(handleSearchCompanies(filterForm, page, filteredCompanies.size));
    } else {
      dispatch(handleSearchCompaniesWithoutLoader(filterForm, page, filteredCompanies.size));
    }
  }
  return {
    ...filteredCompanies,
    pageSize: filteredCompanies.size,
    pageNumber: filteredCompanies.number,
    fetchCompaniesPage,
    searchForCompanies,
    changePageSize
  }
}
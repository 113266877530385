import * as React from "react";

import { useLocation, useNavigate } from "react-router-dom";
import theme from "application/theme";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import LogoIcon from "../assets/Logo.svg";
import LogoFullIcon from "../assets/LogoFull.svg";
import { PresentationIcon,
  UserProfileIcon,
  SettingsIcon,
  PermitSearchIcon,
  LogOutIcon,
  NotificationIcon } from "../assets";

import {
  BuilderNavLogo,
  DrawerStyled,
  FooterItem,
  SideBarListWrapper,
  LogoWrapper,
  FooterItemOpened,
} from "./styles";

import { IListItem, SideBarProps } from "./types";
import { SideBarItem } from "./SideBarItem";
import { SideBarFooterItem } from "./SideBarFooterItem";

import { PathNames } from "application/routes/constants";
import { LogoutConfirmationModal } from "./LogoutModal";
import useToggleModal from "application/hooks/useToggleModal";
import { SidebarItembutton } from "./SidebarItembutton";
import { useAppDispatch, useAppSelector } from "application/store";
import { sdrProfileSelector } from "common/sdrProfile/store";
import { UserRoles } from "common/sdrProfile/store/types";
import { notificationsSelector } from "common/notificationsPage/store";
import { handleGetNotifications } from "common/notificationsPage/store/actions";
import { NotificationStatus, NotificationsI } from "common/notificationsPage/store/types";
import { PermitsView } from 'common/permits/types';

export const SideBar = ({ isOpen, setIsOpen }: SideBarProps) => {
  const { notifications } = useAppSelector(notificationsSelector);
  const dispatch = useAppDispatch();
  const unreadNotifications = notifications.filter(
    (item: NotificationsI) => item.status === NotificationStatus.NOT_READ
  );
  const listOfItems: IListItem[] = [
    {
      icon: <PresentationIcon />,
      label: "Companies",
      notifications: 0,
      path: PathNames.companies,
    },
    {
      icon: <PermitSearchIcon />,
      label: "Permits",
      notifications: 0,
      path: `${PathNames.permits}/${PermitsView.MAP}`,
    },
    {
      icon: <UserProfileIcon />,
      label: "Exports",
      notifications: 0,
      path: `${PathNames.bulkExport}/employees/new`,
    },
    {
      icon: <NotificationIcon />,
      label: "Notifications",
      notifications: unreadNotifications.length,
      path: PathNames.notifications,
    },
    {
      icon: <SettingsIcon />,
      label: "Team Management",
      notifications: 0,
      path: `${PathNames.teamManagement}/request-history`,
    },
  ];
  const { isOpen: isLogoutModal, openModal, closeModal } = useToggleModal();
  const { pathname } = useLocation();
  const { sdrProfile } = useAppSelector(sdrProfileSelector);
  const [isOrgManager, setIsOrgManager] = React.useState(false);

  React.useEffect(() => {
    if (sdrProfile.organizationName) {
      const orgManager = !!sdrProfile.userRoles.filter(
        (e) => e === UserRoles.orgManager
      ).length;
      setIsOrgManager(orgManager);
    }
  }, [sdrProfile.organizationName]);

  const secondSlashIndex = pathname.indexOf("/", 1);
  const pathFirstPart =
    secondSlashIndex !== -1
      ? pathname.substring(0, secondSlashIndex)
      : pathname;

  const navigate = useNavigate();

  const navigateToSdrProfile = () => {
    dispatch(handleGetNotifications());
    navigate(PathNames.sdrProfile);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <DrawerStyled
        variant="permanent"
        open={isOpen}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <LogoWrapper sx={{ paddingRight: isOpen ? theme.spacing(2) : "0" }}>
          <BuilderNavLogo
            src={isOpen ? LogoFullIcon : LogoIcon}
            alt="logo"
            open={isOpen}
          />
        </LogoWrapper>
        <Divider sx={{ marginRight: isOpen ? theme.spacing(2) : "0" }} />
        <SideBarListWrapper open={isOpen}>
          <List sx={{ paddingTop: 0 }}>
            {listOfItems.map((item: IListItem) => {
              if (!isOrgManager && item.label === "Team Management")
                return null;
              return (
                <SideBarItem
                  item={item}
                  open={isOpen}
                  key={item.path}
                  active={item.path.includes(pathFirstPart) ? 1 : 0}
                />
              );
            })}
          </List>

          <Box>
            <List>
              <SidebarItembutton
                item={{
                  icon: <LogOutIcon />,
                  label: "Log Out",
                }}
                onClick={openModal}
                open={isOpen}
              />
            </List>
            {isOpen ? (
              <Box
                display={"flex"}
                alignItems={"center"}
                p={theme.spacing(0, 1)}
                gap={1}
              >
                <FooterItemOpened
                  onClick={navigateToSdrProfile}
                  active={pathname === PathNames.sdrProfile ? 1 : 0}
                >
                  {sdrProfile.firstName
                    ? sdrProfile?.firstName.charAt(0).toUpperCase()
                    : "-"}
                  {sdrProfile.lastName
                    ? sdrProfile?.lastName.charAt(0).toUpperCase()
                    : ""}
                </FooterItemOpened>
                <SideBarFooterItem
                  username={
                    (sdrProfile.firstName || "-") +
                    " " +
                    (sdrProfile.lastName || "")
                  }
                  email={sdrProfile.email}
                />
                <ArrowDropDownIcon />
              </Box>
            ) : (
              <Box>
                <Divider
                  sx={{ marginRight: isOpen ? theme.spacing(2) : "0" }}
                />
                <FooterItem
                  onClick={navigateToSdrProfile}
                  active={pathname === PathNames.sdrProfile ? 1 : 0}
                >
                  {sdrProfile.firstName
                    ? sdrProfile?.firstName.charAt(0).toUpperCase()
                    : "-"}
                  {sdrProfile.lastName
                    ? sdrProfile?.lastName.charAt(0).toUpperCase()
                    : ""}
                </FooterItem>
              </Box>
            )}
          </Box>
        </SideBarListWrapper>
      </DrawerStyled>
      <LogoutConfirmationModal
        isOpen={isLogoutModal}
        handleClose={closeModal}
      />
    </Box>
  );
};

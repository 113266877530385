import React, { useMemo } from 'react';
import { CollapseFilter } from '../../../CollapseFilter';
import { useAppSelector } from 'application/store';
import { permitsSelector } from 'common/permits/store';
import { useFormContext } from 'react-hook-form';
import { MultiSelectWithSearch } from '../MultiSelectWithSearch';

const PERMIT_TAGS_FILTER_NAME = 'tags';
export const TagsFilter = () => {
  const { tags } = useAppSelector(permitsSelector);
  const {watch} = useFormContext();

  const filtersCount = useMemo(() => {
    const selectedTypes = watch(PERMIT_TAGS_FILTER_NAME);
    return selectedTypes?.length;
  }, [watch(PERMIT_TAGS_FILTER_NAME)]);
  return (
    <CollapseFilter label={'tags'}>
      <MultiSelectWithSearch
        name={PERMIT_TAGS_FILTER_NAME}
        options={tags}
        inputPlaceholder="Search by tags"
        noOptionsLabel="No tags"
      />
    </CollapseFilter>
  )
}

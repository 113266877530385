import React, { forwardRef, useEffect, useState } from "react";
import theme from "application/theme";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import DatePicker from "react-datepicker";
import { DatePickerInputStyled, DatepickerWrapper } from "./styles";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerCustomInput = forwardRef(
  ({ value, onClick }: any, ref: any) => (
    <DatePickerInputStyled onClick={onClick} ref={ref}>
      {value ? value : "All"}
    </DatePickerInputStyled>
  )
);

const DatePickerWithText = ({
  label,
  getValue,
  disabled,
  minDate,
  maxDate,
  defaultDate,
}: {
  label: string;
  getValue?: (value: Date | null | undefined) => void;
  disabled: boolean;
  minDate?: string | null;
  maxDate?: Date | null;
  defaultDate?: string | null;
}) => {
  const [date, setDate] = useState<Date | null | undefined>(null);

  const handleOnChange = (date: Date | null | undefined) => {
    setDate(date);
    if (getValue) getValue(date);
  };
  useEffect(() => {
    if (defaultDate && !date) handleOnChange(new Date(defaultDate));
  }, [defaultDate]);

  return (
    <Box>
      <Typography
        variant="caption"
        color={theme.palette.common.grey800}
        sx={{ display: "block" }}
      >
        {label}
      </Typography>
      <DatepickerWrapper>
        <DatePicker
          onChange={(date: Date) => handleOnChange(date)}
          selected={date}
          customInput={<DatePickerCustomInput value={date} />}
          dateFormat="MMM dd, yyyy"
          disabled={disabled}
          minDate={minDate ? new Date(minDate) : null}
          maxDate={maxDate}
        />
      </DatepickerWrapper>
    </Box>
  );
};

export default DatePickerWithText;

import React, { useState } from "react";
import { Box } from "@mui/system";
import { ArrowOutward } from "application/assets";
import { ShowOnMapButton } from "./ShowOnMap";
import { AddToBulkExport } from "./AddToBulkExport";
import { permitsSelector } from "common/permits/store";
import { useAppDispatch, useAppSelector } from "application/store";
import { NotGoodFit } from "./NotGoodFit";
import { selectedCompanySelector } from "common/companies/store";
import { useViewDetails } from 'common/permits/hooks/useViewDetails';
interface Props {
  id: number;
  isAddedToBulk: boolean;
  onAddToBulk: (permitId: number) => void;
}
const TableActions = ({ id, isAddedToBulk, onAddToBulk }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const { foundCompanies } = useAppSelector(permitsSelector);
  const selectedCompany = useAppSelector(selectedCompanySelector);
  const {showCompanyDetails} = useViewDetails();
  const dispatch = useAppDispatch();
  const itemInfo = foundCompanies.content.filter((e) => e.id === id)[0];

  const handleViewDetails = () => {
    showCompanyDetails(itemInfo.id);
  }
  return (
    <Box display="flex" alignItems="center">
      <AddToBulkExport itemInfo={itemInfo}
                       isAddedToBulk={isAddedToBulk}
                       onAddToBulk={onAddToBulk} />
      <ShowOnMapButton itemInfo={itemInfo} />
      <Box
        sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        onClick={handleViewDetails}
      >
        <ArrowOutward />
      </Box>
      <NotGoodFit itemInfo={itemInfo} />
    </Box>
  );
};

export default TableActions;

import { CreditRequestFormI, OrganizationProfile } from "common/organization";
import { clientRequest } from "../requestHandler";
import { AddMemberI, SurveyDataI, UpdateSdrCredits } from "./types";
import { formatDateToYYYYMMDD } from "application/utils";

export const saveOrganizationSurvey = (surveyData: SurveyDataI) => {
  return clientRequest({
    url: `/api/organizations/surveys`,
    method: "POST",
    data: surveyData,
  });
};

export const getOrganizationProfile = (id: number) =>
  clientRequest({
    url: `/api/organizations/${id}`,
    method: "GET",
  });

export const editOrganization = (
  organization: Partial<OrganizationProfile>,
  id: number | null
) =>
  clientRequest({
    url: `/api/organizations/${id}`,
    method: "PUT",
    data: organization,
  });

export const addTeamMember = (data: AddMemberI) =>
  clientRequest({
    url: `/api/organizations/${data.organizationId}/team`,
    method: "POST",
    data: {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      phoneCredits: data.phoneCredits,
      emailCredits: data.emailCredits,
      companyDataCredits: data.companyDataCredits,
    },
  });

export const resendInvitation = ({
  organizationId,
  email,
  name,
}: {
  organizationId: number;
  email: string;
  name: string;
}) =>
  clientRequest({
    url: `/api/organizations/${organizationId}/team`,
    method: "PUT",
    params: {
      email,
      name,
    },
  });

export const updateSdrCredits = (data: UpdateSdrCredits) =>
  clientRequest({
    url: `/api/sdrs/credits`,
    method: "POST",
    data,
  });

export const deleteSdr = (id: number) =>
  clientRequest({
    url: `/api/sdrs/${id}`,
    method: "DELETE",
  });

export const getOrgRequestHistory = () =>
  clientRequest({
    url: `/api/org-managers/request-histories`,
    method: "GET",
  });

export const createCreditRequest = (creditRequestForm: CreditRequestFormI) =>
  clientRequest({
    url: `/api/organizations/credit-requests`,
    method: "POST",
    data: creditRequestForm,
  });

export const getTeamMembers = (organizationId: number | string) =>
  clientRequest({
    url: `/api/organizations/${organizationId}/team`,
    method: "GET",
  });

export const getSubscriptions = (organizationId: number | string) =>
  clientRequest({
    url: `/api/organizations/${organizationId}/subscriptions`,
    method: "GET",
  });

export const renewSubscriptions = (
  userId: number,
  organizationId: number | string
) =>
  clientRequest({
    url: `/api/organization/${organizationId}/subscription-renew-requests`,
    method: "POST",
    data: {
      requestDate: formatDateToYYYYMMDD(new Date()),
      userId: userId,
      organizationId: organizationId,
    },
  });

export const removeHubspotIntegration = (organizationId: number | string) =>
    clientRequest({
        url: `/api/organizations/hubspot-access-configs/${organizationId}`,
        method: "DELETE",
    });

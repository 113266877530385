import React from 'react';
import theme from "application/theme";
import { Typography } from "@mui/material";
import { ExportedListItemStyled } from "./styles";

interface Props {
  exportedCompanies: {
    companyName: string | null;
    companyLocation: string | null;
    companyId: number | string;
  }[];
}

export const ExportedCompaniesTable = ({ exportedCompanies }: Props) => {
  return (
    <>
      {exportedCompanies.map((company) => (
        <ExportedListItemStyled key={company.companyId}>
          <Typography
            variant="caption"
            color={theme.palette.common.grey800}
          >
            {company.companyName}
          </Typography>
          <Typography
            variant="caption"
            color={theme.palette.common.grey800}
          >
            {company.companyLocation}
          </Typography>
        </ExportedListItemStyled>
      ))}
    </>
  )
}

import React from 'react';
import { PermitDescriptionStyled } from './styles';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { commonColors } from 'application/theme';
import { PermitDescriptionIcon } from 'application/assets';

export const PermitDescription = ({text}: {text: string}) => {
  return (
    <PermitDescriptionStyled>
      <PermitDescriptionIcon/>
      <Box>
        <Typography variant={'body2'} color={commonColors.grey900}>Description</Typography>
        <Typography variant={'caption'} color={commonColors.grey800}>{text}</Typography>
      </Box>
    </PermitDescriptionStyled>
  )
}

import React from "react";
import theme from 'application/theme';
import { IconProps } from 'application/assets/types';
export const MapViewIcon: React.FC<IconProps> = ({color = theme.palette.common.grey800}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M7.5 16.6666L2.5 14.1666V3.33325L7.5 5.83325M7.5 16.6666L12.5 14.1666M7.5 16.6666V5.83325M12.5 14.1666L17.5 16.6666V5.83325L12.5 3.33325M12.5 14.1666V3.33325M7.5 5.83325L12.5 3.33325"
        stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

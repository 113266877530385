import React, { SVGProps, useEffect, useMemo, useRef, useState } from "react";
import { Popper, Typography, Box } from "@mui/material";
import theme from "application/theme";
import { CompanyLocationsI } from "integration/api/companies/types";
import CopyButton from "application/components/CopyButton";
import {
  ListLocations,
  LocationContentWrapper,
  LocationWrapper,
  PaperStyled,
} from "./styles";
import { CloseOutlined } from "@mui/icons-material";
import { selectedCompanySelector } from "common/companies/store";
import { useAppSelector } from "application/store";
import { formatAddress } from "common/companies/companiesList/utils";

interface PopupDialogProps {
  buttonText: string;
  locationList: CompanyLocationsI[];
  Icon: React.FC<SVGProps<SVGSVGElement>>;
}

interface GroupedLocations {
  [state: string]: CompanyLocationsI[];
}

const PopupDialog: React.FC<PopupDialogProps> = ({
  buttonText,
  locationList,
  Icon,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const popperContentRef = useRef<HTMLDivElement | null>(null);
  const openPopper = Boolean(anchorEl);
  const { businessName } = useAppSelector(selectedCompanySelector);

  const handleToggle = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        anchorEl &&
        popperContentRef.current &&
        !popperContentRef.current.contains(event.target as Node)
      ) {
        setAnchorEl(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [anchorEl]);

  const groupedLocations = useMemo<GroupedLocations>(() => {
    const result: GroupedLocations = {};

    locationList.forEach((location) => {
      const state = location.state || "Permit state not found";
      if (!state) return;
      if (!result[state]) {
        result[state] = [];
      }
      result[state].push(location);
    });

    return result;
  }, []);

  const renderLocationsByState = () => {
    return Object.keys(groupedLocations).map((state) => (
      <LocationWrapper key={state}>
        <Typography variant="button" color={theme.palette.common.grey900}>
          {state}
        </Typography>
        {groupedLocations[state].map((location) => (
          <LocationContentWrapper key={location.id}>
            <Box display="flex" gap={1}>
              <Icon />
              <Typography
                variant="overline"
                color={theme.palette.common.grey800}
              >
                {formatAddress(location) || "Permit location not found"}
              </Typography>
            </Box>
            {formatAddress(location) ? (
              <CopyButton textToCopy={formatAddress(location)} />
            ) : (
              <></>
            )}
          </LocationContentWrapper>
        ))}
      </LocationWrapper>
    ));
  };

  return (
    <div>
      {locationList.length ? (
        <Typography
          variant="overline"
          color={theme.palette.common.grey800}
          onClick={handleToggle}
          style={{ textDecoration: "underline", cursor: "pointer" }}
        >
          {buttonText}&nbsp;({locationList.length})
        </Typography>
      ) : (
        <Typography variant="overline" color={theme.palette.common.grey800}>
          -
        </Typography>
      )}
      <Popper open={openPopper} anchorEl={anchorEl} placement="bottom" sx={{zIndex: 1300}}>
        <PaperStyled ref={popperContentRef} elevation={3}>
          <CloseOutlined
            sx={{
              position: "absolute",
              top: theme.spacing(3),
              right: theme.spacing(4),
              cursor: "pointer",
            }}
            onClick={handleToggle}
          />
          <Box display="flex" flexDirection="column" gap={2.5}>
            <Box display="flex" flexDirection="column" gap={1} pr={2}>
              <Typography
                variant="subtitle1"
                color={theme.palette.common.grey900}
              >
                {locationList.length}&nbsp;locations
              </Typography>
              <Typography
                variant="caption"
                color={theme.palette.common.grey800}
              >
                {businessName} has&nbsp;
                {locationList.length}&nbsp; location(s). You can copy address of
                any office.
              </Typography>
            </Box>
            <ListLocations>{renderLocationsByState()}</ListLocations>
          </Box>
        </PaperStyled>
      </Popper>
    </div>
  );
};

export default PopupDialog;

import theme from "application/theme";
import React, { useState } from "react";
import { HubspotIcon } from "application/assets/HubspotIcon";
import useToggleModal from "application/hooks/useToggleModal";
import { ConnectToHubspotModal } from "../../modals/ConnectToHubspotModal";
import TopInfoMessage from "application/components/TopInfoMessage";
import { organizationsSelector } from "common/organization/store";
import { useAppSelector } from "application/store";

const ConnectToHubspot = () => {
  const { isOpen, openModal, closeModal } = useToggleModal();
  const { organizationProfile } = useAppSelector(organizationsSelector);
  const [openMessage, setOpenMessage] = useState(true);

  return (
    <>
      {openMessage ? (
        <TopInfoMessage
          onClick={openModal}
          onClose={() => {
            setOpenMessage(false);
          }}
          label="Allow BuilderBinder to Access your Hubspot Account"
          buttonText="Add Hubspot integration"
          icon={<HubspotIcon color={theme.palette.common.blue800} />}
        />
      ) : (
        <></>
      )}
      <ConnectToHubspotModal
        isOpen={isOpen}
        handleClose={closeModal}
        onConfirmUrl={organizationProfile.hubspotAuthUrl}
      />
    </>
  );
};
export default ConnectToHubspot;

import { clientRequest } from "../requestHandler";
import { CompanyFilterDataI } from '../companies/types';
import { PageRequestI } from 'application/types';
import { SortBy } from 'common/companies/companiesFilter/const';
import { ExportTo } from 'common/companies/types';
import { PermitWithCompanyFilterDataI } from 'common/permits/types';

export const exportFilteredCompanies = (filterData: CompanyFilterDataI, {
  page,
  size,
  sortBy,
  sortOrder
}: PageRequestI<SortBy>, exportTo: ExportTo) =>
  clientRequest({
    url: `/api/companies/exports/large`,
    method: "POST",
    data: filterData,
    params: {
      page,
      size,
      sort: sortBy ? `${sortBy},${sortOrder}` : undefined,
      exportTo
    },
  });

export const exportFilteredCompaniesByPermitFilters = (filterData: PermitWithCompanyFilterDataI, {
  page,
  size,
  sortBy,
  sortOrder
}: PageRequestI<SortBy>, exportTo: ExportTo) =>
  clientRequest({
    url: `/api/companies/permit-filtered/exports/large`,
    method: "POST",
    data: filterData,
    params: {
      page,
      size,
      sort: sortBy ? `${sortBy},${sortOrder}` : undefined,
      exportTo
    },
  });

export const exportFilteredCompaniesOnMapByPermitFilters = (filterData: PermitWithCompanyFilterDataI, {
  page,
  size,
  sortBy,
  sortOrder
}: PageRequestI<SortBy>, exportTo: ExportTo) =>
  clientRequest({
    url: `/api/companies/bulk-export/permit-filtered/by-coordinates/large`,
    method: "POST",
    data: filterData,
    params: {
      page,
      size,
      sort: sortBy ? `${sortBy},${sortOrder}` : undefined,
      exportTo
    },
  });

export const exportFoundPermits = (filterData: PermitWithCompanyFilterDataI, {
  page,
  size,
  sortBy,
  sortOrder
}: PageRequestI<SortBy>) =>
  clientRequest({
    url: `/api/permits/bulk-export/large`,
    method: "POST",
    data: filterData,
    params: {
      page,
      size,
      sort: sortBy ? `${sortBy},${sortOrder}` : undefined,
    },
  });

export const exportFoundPermitsFromMap = (filterData: PermitWithCompanyFilterDataI, {
  page,
  size,
  sortBy,
  sortOrder
}: PageRequestI<SortBy>) =>
  clientRequest({
    url: `/api/permits/bulk-export/by-coordinates/large`,
    method: "POST",
    data: filterData,
    params: {
      page,
      size,
      sort: sortBy ? `${sortBy},${sortOrder}` : undefined,
    },
  });

export const getNumberOfExportedCompanies = (filterData: CompanyFilterDataI, {
  page,
  size,
  sortBy,
  sortOrder
}: PageRequestI<SortBy>) =>
  clientRequest({
    url: `/api/companies/exports/large/exported`,
    method: "POST",
    data: filterData,
    params: {
      page,
      size,
      sort: sortBy ? `${sortBy},${sortOrder}` : undefined,
    },
  });

export const getNumberOfExportedCompaniesByPermitFilters = (filterData: PermitWithCompanyFilterDataI, {
  page,
  size,
  sortBy,
  sortOrder
}: PageRequestI<SortBy>) =>
  clientRequest({
    url: `/api/companies/permit-filtered/exports/large/exported`,
    method: "POST",
    data: filterData,
    params: {
      page,
      size,
      sort: sortBy ? `${sortBy},${sortOrder}` : undefined,
    },
  });

export const getNumberOfExportedCompaniesOnMapByPermitFilters = (filterData: PermitWithCompanyFilterDataI, {
  page,
  size,
  sortBy,
  sortOrder
}: PageRequestI<SortBy>) =>
  clientRequest({
    url: `/api/companies/bulk-export/permit-filtered/by-coordinates/large/exported`,
    method: "POST",
    data: filterData,
    params: {
      page,
      size,
      sort: sortBy ? `${sortBy},${sortOrder}` : undefined,
    },
  });

export const getNumberOfExportedPermitsByPermitFilters = (filterData: PermitWithCompanyFilterDataI, {
  page,
  size,
  sortBy,
  sortOrder
}: PageRequestI<SortBy>) =>
  clientRequest({
    url: `/api/permits/bulk-export/large/exported`,
    method: "POST",
    data: filterData,
    params: {
      page,
      size,
      sort: sortBy ? `${sortBy},${sortOrder}` : undefined,
    },
  });

export const getNumberOfExportedPermitsOnMapByPermitFilters = (filterData: PermitWithCompanyFilterDataI, {
  page,
  size,
  sortBy,
  sortOrder
}: PageRequestI<SortBy>) =>
  clientRequest({
    url: `/api/permits/bulk-export/by-coordinates/large/exported`,
    method: "POST",
    data: filterData,
    params: {
      page,
      size,
      sort: sortBy ? `${sortBy},${sortOrder}` : undefined,
    },
  });

export const getCompaniesBulkExportProcesses = (organizationId: number, {
  page,
  size,
  sortBy,
  sortOrder
}: PageRequestI<string>) => {
  return clientRequest({
    url: `/employees/companies/organization/${organizationId}/bulk-process`,
    method: "GET",
    params: {
      page,
      size,
      sort: sortBy ? `${sortBy},${sortOrder}` : undefined,
    },
  });
}

export const getPermitsBulkExportProcesses = (organizationId: number, {
  page,
  size,
  sortBy,
  sortOrder
}: PageRequestI<string>) => {
  return clientRequest({
    url: `/api/permits/bulk-export/by-org/${organizationId}/bulk-process`,
    method: "GET",
    params: {
      page,
      size,
      sort: sortBy ? `${sortBy},${sortOrder}` : undefined,
    },
  });
}

export const reexportCompanyBulkExportProcess = (bulkExportProcessId: number, exportTo: ExportTo) => {
  return clientRequest({
    url: `/api/companies/exports/large/reexport`,
    method: "POST",
    data: {
      exportTo,
      bulkExportProcessId,
    },
  });
}

export const reexportPermitBulkExportProcess = (bulkExportProcessId: number) => {
  return clientRequest({
    url: `/api/permits/bulk-export/large/reexport`,
    method: "POST",
    data: {
      exportTo : 'CSV',
      bulkExportProcessId,
    },
  });
}

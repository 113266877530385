import React from "react";
import theme from '../theme';
import { FilledIconProps } from './types';

export const LockCircleIcon = (
  {
    color = theme.palette.common.red600,
    fillColor = theme.palette.common.red50,
  }: FilledIconProps) => {
  return (
    <svg width="83" height="82" viewBox="0 0 83 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="41.5" cy="41" r="41" fill={fillColor}/>
      <mask id="mask0_4271_70818" maskUnits="userSpaceOnUse" x="20" y="20" width="42" height="42">
        <rect x="20" y="20" width="42" height="42" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_4271_70818)">
        <path
          d="M30.5 58.5C29.5375 58.5 28.7135 58.1573 28.0281 57.4719C27.3427 56.7865 27 55.9625 27 55V37.5C27 36.5375 27.3427 35.7135 28.0281 35.0281C28.7135 34.3427 29.5375 34 30.5 34H32.25V30.5C32.25 28.0792 33.1031 26.0156 34.8094 24.3094C36.5156 22.6031 38.5792 21.75 41 21.75C43.4208 21.75 45.4844 22.6031 47.1906 24.3094C48.8969 26.0156 49.75 28.0792 49.75 30.5V34H51.5C52.4625 34 53.2865 34.3427 53.9719 35.0281C54.6573 35.7135 55 36.5375 55 37.5V55C55 55.9625 54.6573 56.7865 53.9719 57.4719C53.2865 58.1573 52.4625 58.5 51.5 58.5H30.5ZM30.5 55H51.5V37.5H30.5V55ZM41 49.75C41.9625 49.75 42.7865 49.4073 43.4719 48.7219C44.1573 48.0365 44.5 47.2125 44.5 46.25C44.5 45.2875 44.1573 44.4635 43.4719 43.7781C42.7865 43.0927 41.9625 42.75 41 42.75C40.0375 42.75 39.2135 43.0927 38.5281 43.7781C37.8427 44.4635 37.5 45.2875 37.5 46.25C37.5 47.2125 37.8427 48.0365 38.5281 48.7219C39.2135 49.4073 40.0375 49.75 41 49.75ZM35.75 34H46.25V30.5C46.25 29.0417 45.7396 27.8021 44.7188 26.7812C43.6979 25.7604 42.4583 25.25 41 25.25C39.5417 25.25 38.3021 25.7604 37.2812 26.7812C36.2604 27.8021 35.75 29.0417 35.75 30.5V34Z"
          fill={color}/>
      </g>
    </svg>
  );
};

import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "application/store";
import { notificationsSelector } from "../store";

import { NotificationsTable } from "./NotificationsTable";

import theme from "application/theme";

import { NotificationsPageWrapper } from "./styles";

import { Typography } from "@mui/material";
import { handleReadAllNotifications } from "../store/actions";
import { NotificationStatus, NotificationsI } from "../store/types";

const NotificationsPage = () => {
  const dispatch = useAppDispatch();
  const { notifications } = useAppSelector(notificationsSelector);
  const unreadNotifications = notifications.filter(
    (item: NotificationsI) => item.status === NotificationStatus.NOT_READ
  );

  useEffect(() => {
    const notificationIds = notifications.map((item) => item.id);
    unreadNotifications.length &&
      dispatch(handleReadAllNotifications(notificationIds));
  }, [notifications]);

  return (
    <NotificationsPageWrapper>
      <Typography variant="h4" color={theme.palette.common.grey900} noWrap>
        Notifications
      </Typography>
      <NotificationsTable notifications={notifications} />
    </NotificationsPageWrapper>
  );
};

export default NotificationsPage;

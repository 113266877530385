import React from 'react';
import { Box, Typography } from '@mui/material';
import theme from 'application/theme';
import {
  CompanyNameFilter,
  ServiceTypeFilter,
  TagsFilter,
  CheckedFilterItem,
  CompanyTypeFilter,
  VisibilityScoreFilter,
  LocationFilter,
  PermitFilter,
  AnnualRevenueFilter,
  NumberOfEmployeesFilter,
  YearsInBusinessFilter,
  BlindFilter
} from './components';
import { useAppSelector } from 'application/store';
import { filterFormSelector } from 'common/companies/store';
export const AppliedFiltersList = () => {
  const {skipExported, notGoodFit} = useAppSelector(filterFormSelector);
  return (
    <Box display="flex" flexDirection={"column"}
         gap={2} overflow={'auto'} height={'100%'}
    >
      <Typography variant="button" color={theme.palette.common.grey900}>
        Applied filters:
      </Typography>
      <BlindFilter/>
      <CompanyNameFilter/>
      {skipExported && <CheckedFilterItem label={'Skip exported'}/>}
      <ServiceTypeFilter/>
      <CompanyTypeFilter/>
      <PermitFilter/>
      <TagsFilter/>
      <LocationFilter/>
      <VisibilityScoreFilter/>
      <NumberOfEmployeesFilter/>
      <AnnualRevenueFilter/>
      <YearsInBusinessFilter/>
      {notGoodFit && <CheckedFilterItem label={'Show only not a good fit'}/>}
    </Box>
  )
}

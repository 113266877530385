import { createSlice } from "@reduxjs/toolkit";
import { store } from "application/store";

export const initialState = {
  tutorialTours: {
    isCompanyFilterTutorialOpen: false,
    isCompanyInfoTutorialOpen: false,
  },
};

export const tutorialSlice = createSlice({
  name: "tutorials",
  initialState,
  reducers: {
    setCompanyFiltersTutorialIsOpen: (state, action) => {
      state.tutorialTours.isCompanyFilterTutorialOpen = action.payload;
    },
    setCompanyInfoTutorialIsOpen: (state, action) => {
      state.tutorialTours.isCompanyInfoTutorialOpen = action.payload;
    },
  },
});


export const tutorialToursSelector = () => store.getState().tutorial.tutorialTours;

export const {
  setCompanyFiltersTutorialIsOpen,
  setCompanyInfoTutorialIsOpen
} = tutorialSlice.actions;

import React from 'react';
import { useState } from 'react';
import { Box, Collapse } from '@mui/material';
import CollapseLabel from './CollapseLabel';

interface Props {
  label: string;
  count?: number;
  children?: React.ReactNode;
  display?: string;
}
export const FiltersCollapse = ({label, count, display, children}:Props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Box width={"100%"} display={display}>
      <CollapseLabel
        label={label} count={count}
        expanded={expanded} setExpanded={setExpanded}/>
      <Collapse in={expanded}>
        {children}
      </Collapse>
    </Box>
  )
}
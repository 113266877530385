import React from 'react';
import { CollapseFilter } from '../../../CollapseFilter';
import { PermitLocationFilter } from './PermitLocationFilter';
import { getPermitCities, getPermitStates, getPermitZipCodes, getPermitCounties } from 'integration/api/permits';
import { usePermitFiltersContext } from 'common/permits/hooks/usePermitFiltersContext';

export const LocationFilter = () => {
  const {permitFilterForm} = usePermitFiltersContext();
  const {
    citiesExclude, citiesInclude,
    statesExclude, statesInclude,
    zipCodesExclude, zipCodesInclude,
    countiesExclude, countiesInclude
  } = permitFilterForm.watch('locations');
  const totalCount = citiesExclude.length + citiesInclude.length
    + statesExclude.length + statesInclude.length
    + zipCodesExclude.length + zipCodesInclude.length
    + countiesExclude.length + countiesInclude.length;

  return (
    <CollapseFilter count={totalCount} label={'location'}>
      <PermitLocationFilter
        label={'States'}
        fieldNameInclude={'locations.statesInclude'}
        fieldNameExclude={'locations.statesExclude'}
        searchType={'state'}
        getLocationsCallback={getPermitStates}
      />
      <PermitLocationFilter
        label={'Cities'}
        fieldNameInclude={'locations.citiesInclude'}
        fieldNameExclude={'locations.citiesExclude'}
        searchType={'city'}
        getLocationsCallback={getPermitCities}
      />
      <PermitLocationFilter
        label={'Zip codes'}
        fieldNameInclude={'locations.zipCodesInclude'}
        fieldNameExclude={'locations.zipCodesExclude'}
        searchType={'zip code'}
        getLocationsCallback={getPermitZipCodes}
      />
      <PermitLocationFilter
        label={'Counties'}
        fieldNameInclude={'locations.countiesInclude'}
        fieldNameExclude={'locations.countiesExclude'}
        searchType={'county'}
        getLocationsCallback={getPermitCounties}
      />
    </CollapseFilter>
  )
}

import { Box } from "@mui/system";
import { MultiSelectItem } from "application/components/MultiSelect/MultiSelectItem";
import { CheckboxGroupStyled } from "application/components/MultiSelect/styles";
import React from "react";
import { RadioButton } from "../styles";
import { Typography } from "@mui/material";
import DatePickerWithText from "application/components/DatePicker";
import theme from "application/theme";
import { PermitStatusI } from "common/permits/types";

export interface StatusFilterItemProps {
  filterData: PermitStatusI;
  checked: boolean;
  selectThisFilter: () => void;
  selectCurrent: () => void;
  selectDateRange?: () => void;
  getDateFrom?: (value: Date | null | undefined) => void;
  getDateTo?: (value: Date | null | undefined) => void;
  label: string;
  value: string;
  disabled?: boolean;
  disabledDatePick?: boolean;
  maxDate?: Date | null;
}

const StatusFilterItem = ({
  filterData,
  checked,
  selectThisFilter,
  selectCurrent,
  selectDateRange,
  getDateFrom,
  getDateTo,
  label,
  value,
  disabledDatePick = false,
  disabled = false,
  maxDate,
}: StatusFilterItemProps) => {
  return (
    <>
      <CheckboxGroupStyled>
        <MultiSelectItem
          disabled={disabled}
          checked={checked}
          label={label}
          value={value}
          onClick={selectThisFilter}
        />
      </CheckboxGroupStyled>
      {checked && (
        <Box display={"flex"} flexDirection={"column"} pl={4.5} mb={1} mt={1}>
          <Box display={"flex"} alignItems="center" mb={1.5}>
            <RadioButton
              color="info"
              checked={filterData.current}
              onClick={selectCurrent}
            />
            <Typography
              variant="caption"
              color={theme.palette.common.grey900}
              ml={1}
            >
              Current
            </Typography>
          </Box>
          {value !== "final" && (
            <>
              <Box display={"flex"} alignItems="center" mb={1}>
                <RadioButton
                  color="info"
                  checked={!filterData.current}
                  onClick={selectDateRange}
                />
                <Typography
                  variant="caption"
                  color={theme.palette.common.grey900}
                  ml={1}
                >
                  Date range
                </Typography>
              </Box>
              <Box display={"flex"} gap={0.5}>
                <DatePickerWithText
                  label="From"
                  getValue={getDateFrom}
                  disabled={disabledDatePick}
                  maxDate={maxDate}
                  defaultDate={filterData.from}
                />
                <DatePickerWithText
                  label="To"
                  getValue={getDateTo}
                  disabled={disabledDatePick}
                  minDate={filterData.from}
                  maxDate={maxDate}
                  defaultDate={filterData.to}
                />
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default StatusFilterItem;

import { RootState } from "application/store";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

export type Thunk<R> = ThunkAction<R, RootState, unknown, AnyAction>;
export type VoidPromiseThunk = () => Thunk<Promise<void>>;
export type VoidThunk = Thunk<void>;
export type ThunkDispatchT = ThunkDispatch<RootState, unknown, AnyAction>;

export interface PageResponseI<R> {
    content: R[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    totalPages: number;
    totalElements: number;
    size: number;
}

export const emptyPageResponse : PageResponseI<any> = {
    content: [],
    empty: true,
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    totalPages: 0,
    totalElements: 0,
    size: 20,
}
export interface PageRequestI<S> {
    page: number;
    size: number;
    sortBy?: S;
    sortOrder?: SortOrder;
}

export const enum SortOrder {
    ASC = "ASC",
    DESC = "DESC",
}

export interface SimpleArrayResponseI<R> {
    content: R[];
}
export interface RangeValueI {
    min: number;
    max: number;
}
import { styled } from '@mui/material';
import { Box } from '@mui/system';
import theme from 'application/theme';

export const SearchBarStyled = styled(Box)`
  position: sticky;
  z-index: 1000;
  top:0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing(2)};
  background: ${theme.palette.common.grey150};
  border-bottom: 1px solid ${theme.palette.common.grey300};
`;
import React from "react";
import { Box, MenuItem, Typography } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import theme from "application/theme";
import { SortBy } from "../companiesFilter/const";
import { SortOrder } from "application/types";

import { useAppDispatch, useAppSelector } from "application/store";
import { companySortSelector, setSorting } from "../store";
import { handleSortCompanies } from "../store/actions";
import { SelectSortStyled } from "./styles";


const sortOptions = [
  {
    label: 'Alphabeticaly (A-Z)',
    value: {
      sortBy: SortBy.BUSINESS_NAME,
      sortOrder: SortOrder.ASC,
    }
  },
  {
    label: 'Alphabeticaly (Z-A)',
    value: {
      sortBy: SortBy.BUSINESS_NAME,
      sortOrder: SortOrder.DESC,
    }
  },
  {
    label: 'Revenue: Low to High',
    value: {
      sortBy: SortBy.ANNUAL_REVENUE,
      sortOrder: SortOrder.ASC,
    }
  },
  {
    label: 'Revenue: High to Low',
    value: {
      sortBy: SortBy.ANNUAL_REVENUE,
      sortOrder: SortOrder.DESC,
    }
  },
  {
    label: 'Employees: Low to High',
    value: {
      sortBy: SortBy.EMPLOYEES,
      sortOrder: SortOrder.ASC,
    }
  },
  {
    label: 'Employees: High to Low',
    value: {
      sortBy: SortBy.EMPLOYEES,
      sortOrder: SortOrder.DESC,
    }
  },
  {
    label: 'Visibility score: Low to High',
    value: {
      sortBy: SortBy.REPUTATION_SCORE,
      sortOrder: SortOrder.ASC,
    }
  },
  {
    label: 'Visibility score: High to Low',
    value: {
      sortBy: SortBy.REPUTATION_SCORE,
      sortOrder: SortOrder.DESC,
    }
  },
  {
    label: 'Most 2023 permit activity',
    value: {
      sortBy: SortBy.AVERAGE_ANNUAL_PERMITS_VALUE,
      sortOrder: SortOrder.DESC,
    }
  },
]
interface Props {
  horizontalView?: boolean;
}
const CompaniesSortSelect = ({ horizontalView } : Props) => {
  const sortFromStore = useAppSelector(companySortSelector);
  const dispatch = useAppDispatch();

  const handleChange = (event: any) => {
    const newSorting = sortOptions.find((option) => {
      return valueToString(option.value) === event.target.value
    })?.value;
    if (newSorting) {
      dispatch(setSorting(newSorting))
      dispatch(handleSortCompanies(newSorting));
    }
  }
  const valueToString = (value: any) => {
    return `${value.sortBy}-${value.sortOrder}`;
  }
  const labelByValue = (value: any) => {
    return sortOptions.find((option) => valueToString(option) === valueToString(value))?.label;
  }

  return (
    <Box display="flex"
         flexDirection={horizontalView ? 'row' : 'column'}
         gap={ horizontalView ? 1.5 : 0 }
    >
      <Typography textAlign='right' color={theme.palette.common.grey800}
                  fontSize={theme.typography.pxToRem(14)}>Sort by</Typography>
      <SelectSortStyled
        value={valueToString(sortFromStore)}
        label={labelByValue(sortFromStore)}
        onChange={handleChange}
        IconComponent={KeyboardArrowDownOutlinedIcon}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right"
          },
          PaperProps: {
            sx: {
              marginTop: theme.spacing(0.75),
              borderRadius: theme.spacing(1),
              boxShadow: theme.shadows[2],
              '& .MuiMenuItem-root': {
                padding: theme.spacing(0.5, 1),
                fontSize: theme.spacing(2),
              },
              '& .MuiList-root': {
                padding: 0,
              }
            },
          },
        }}
      >
        {
          sortOptions.map((option) => (
            <MenuItem key={option.label} value={valueToString(option.value)}>{option.label}</MenuItem>
          ))
        }
      </SelectSortStyled>
    </Box>
  )
};

export default CompaniesSortSelect;

import { Box, styled } from "@mui/material";

import theme from "application/theme";

export const BadgeWrapper = styled(Box)`
  display: flex;
  padding: ${theme.spacing(0.25, 1, 0.25, 0.75)};
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing(0.5)};
  border-radius: ${theme.spacing(2)};
  background: ${theme.palette.common.blue50};
  span {
    color: ${theme.palette.common.blue700};
    text-align: center;
    font-size: ${theme.spacing(1.5)};
    font-weight: 400;
    text-transform: capitalize;
    line-height: ${theme.spacing(2.1)};
  }
`;

export const ShowLessAndMoreWrapper = styled(Box)`
  gap: ${theme.spacing(0.5)};
  margin-top: ${theme.spacing(1)};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

import React, { useEffect, useState } from "react";
import { ModalComponent } from "application/components/ModalComponent";
import { StyledButtonContainer, StyledChangePasswordForm } from "./styles";
import { Button } from "application";
import { CreatePasswordForm } from "common/auth/PasswordInputs/CreatePasswordForm";
import { PasswordInput } from "common/auth/PasswordInputs/PasswordInput";
import { changePassword } from "integration/cognito";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ChangePasswordModal = ({ isOpen, onClose }: Props) => {

  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState<string|undefined>(undefined);
  const [newPassword, setNewPassword] = useState<string | null>(null);
  const [hasErrors, setHasErrors] = useState(true);

  useEffect(() => {
    if (oldPassword && newPassword) {
      setHasErrors(false);
    } else {
      setHasErrors(true);
    }
  }, [oldPassword, newPassword]);

  useEffect(() => {
    setOldPasswordError(undefined)
  }, [oldPassword]);

  const onSubmitForm = (event: any) => {
    event.preventDefault();
    if (oldPassword && newPassword) {
      changePassword(oldPassword, newPassword)
        .then((event) => onClose())
        .catch((error) => {
          setOldPasswordError(error?.message || error);
        });
    }
  }

  return (
    <ModalComponent title="Change Password" isOpen={isOpen} handleClose={onClose} width={"30%"}>
      <StyledChangePasswordForm onSubmit={onSubmitForm}>
        <PasswordInput
          label={"Old Password"}
          setValue={setOldPassword}
          customError={!!oldPasswordError}
          errorMessage={oldPasswordError}
        />

        <CreatePasswordForm
          passwordLabel={"New Password"}
          confirmPasswordLabel={"Repeat new password"}
          getPassword={setNewPassword} width={"100%"}/>

        <StyledButtonContainer>
          <Button type="button" label={"Cancel"} onClick={onClose}/>
          <Button type="submit" label={"Save"} disabled={hasErrors}/>
        </StyledButtonContainer>
      </StyledChangePasswordForm>
    </ModalComponent>
  )
}
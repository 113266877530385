import React, { forwardRef } from 'react';
import { CheckboxGroupStyled } from '../styles';
import { FormGroup } from '@mui/material';
import { MultipleCheckboxController } from './index';

interface CheckBoxGroupProps {
  items: string[];
  fieldName: string;
  minCount?: number;
}
export const CheckboxGroupController = forwardRef(({items, fieldName, minCount = 5}: CheckBoxGroupProps, ref: any) => {
  return (
    <CheckboxGroupStyled
      mt={1.5}
      height={items.length > minCount ? 320 : 0}
    >
      <FormGroup>
        {items.map((item: string) => (
          <MultipleCheckboxController
            fieldName={fieldName}
            value={item}
            label={item}
            key={item}
          />
        ))}
        <div ref={ref}/>
      </FormGroup>
    </CheckboxGroupStyled>
  )
});
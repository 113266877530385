import { type } from 'ramda';

type PromiseActionI = (value?: any) => void;
type FinallyActionI = () => void;
type ErrorActionI = (reason?: any) => void;

export const handleParallelPromises = (promises: Promise<any>[], actions : PromiseActionI[], onFinally: FinallyActionI, onError: ErrorActionI) => {
  Promise.allSettled(promises).then((results) => {
    results.forEach((result, index) => {
      if (result.status === 'fulfilled') {
        actions[index](result.value);
      } else if(result.status === 'rejected') {
        onError(result.reason);
      }
    });
  }).finally(() => {
    onFinally();
  });
}

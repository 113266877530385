import React from "react";

export const EmailDisabledIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5835 17.0007L14.0478 12.0007M9.28588 12.0007L3.75019 17.0007M3.3335 7.83398L10.1376 12.5969C10.6886 12.9825 10.9641 13.1754 11.2637 13.2501C11.5284 13.3161 11.8053 13.3161 12.0699 13.2501C12.3696 13.1754 12.6451 12.9825 13.1961 12.5969L20.0002 7.83398M7.3335 18.6673H16.0002C17.4003 18.6673 18.1004 18.6673 18.6351 18.3948C19.1055 18.1552 19.488 17.7727 19.7277 17.3023C20.0002 16.7675 20.0002 16.0674 20.0002 14.6673V9.33398C20.0002 7.93385 20.0002 7.23379 19.7277 6.69901C19.488 6.2286 19.1055 5.84615 18.6351 5.60647C18.1004 5.33398 17.4003 5.33398 16.0002 5.33398H7.3335C5.93336 5.33398 5.2333 5.33398 4.69852 5.60647C4.22811 5.84615 3.84566 6.2286 3.60598 6.69901C3.3335 7.23379 3.3335 7.93385 3.3335 9.33398V14.6673C3.3335 16.0674 3.3335 16.7675 3.60598 17.3023C3.84566 17.7727 4.22811 18.1552 4.69852 18.3948C5.2333 18.6673 5.93336 18.6673 7.3335 18.6673Z"
        stroke="#C9C9C9"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="12" width="12" height="12" rx="6" fill="white" />
      <rect x="14" y="2" width="8" height="8" rx="4" fill="#C9C9C9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 5.06061C20.2929 4.76771 20.2929 4.29284 20 3.99994C19.7071 3.70705 19.2322 3.70705 18.9393 3.99994L18 4.93926L17.0607 3.99994C16.7678 3.70705 16.2929 3.70705 16 3.99994C15.7072 4.29284 15.7072 4.76771 16 5.06061L16.9394 5.99992L16 6.93928C15.7071 7.23217 15.7071 7.70705 16 7.99994C16.2929 8.29284 16.7678 8.29284 17.0607 7.99994L18 7.06059L18.9394 7.99994C19.2323 8.29284 19.7071 8.29284 20 7.99994C20.2929 7.70705 20.2929 7.23217 20 6.93928L19.0607 5.99992L20 5.06061Z"
        fill="white"
      />
    </svg>
  );
};

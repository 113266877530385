import { Paper, Table, TableContainer } from "@mui/material";
import theme from "application/theme";
import { Box } from "@mui/system";
import React from "react";
import { StructureWrapper } from "common/companies/companiesInfo/styles";
import { Pagination } from "application";
import EmptyInfo from "./EmptyInfo";
import { usePageQueryParams } from 'application/hooks/usePageQueryParams';

interface Props {
  totalElements: number;
  numberOfElements: number;
  totalPages: number;
  pageRequest: any;
  children: JSX.Element[] | JSX.Element;
  contentIsEmpty: boolean;
}
export const BulkExportTableWrapper = ({ children, totalPages, totalElements, numberOfElements, pageRequest, contentIsEmpty } : Props) => {
  const {currentPage} = usePageQueryParams();
  return (
    <Box display="flex" flexDirection="column" gap={1.5} width="100%">
      <StructureWrapper>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", borderRadius: theme.spacing(1) }}
        >
          <Table sx={{ minWidth: 350 }} aria-label="simple table">
            {children}
          </Table>
          {contentIsEmpty && <EmptyInfo />}
        </TableContainer>
        <Pagination
          totalElements={totalElements}
          numberOfElements={numberOfElements}
          totalPages={totalPages}
          pageRequest={pageRequest}
          currentPage={currentPage - 1}
        />
      </StructureWrapper>
    </Box>)
}
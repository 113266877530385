import React, { forwardRef } from "react";
import { Box, Typography } from '@mui/material';
import theme from 'application/theme';
import { getNumberFormatWithSeparators } from 'application/utils';

interface PropsI {
  totalElements?: number;
}

const SearchResultInfo = forwardRef<
  HTMLDivElement, PropsI
>(({totalElements}, ref) => {
  return (
    <Box>
      <Typography
        variant="h5"
        fontSize={theme.spacing(2.25)}
        color={theme.palette.common.grey700}
        ref={ref}
      >
        Results:
      </Typography>
      <Typography
        fontSize={theme.spacing(3)}
        fontWeight={500}
        lineHeight={theme.spacing(3.5)}
        color={theme.palette.common.grey900}
      >
        {totalElements ? getNumberFormatWithSeparators(totalElements) : 0}
      </Typography>
    </Box>
  );
});

export default SearchResultInfo;
import { AnnualRevenue, NumbOfEmployees, Score, ServiceType, YearsInBusiness } from './const';

export const getServiceTypeLabel = (serviceType: ServiceType | string) => {
  switch (serviceType) {
    case ServiceType.COMMERCIAL:
      return 'Commercial';
    case ServiceType.RESIDENTIAL:
      return 'Residential';
    default:
      return 'Not yet defined';
  }
}

export const getReputationScoreLabel = (reputationScore: Score | string) => {
  switch (reputationScore) {
    case Score.WONDERFUL:
      return 'Highest: 9+';
    case Score.VERY_GOOD:
      return 'High: 8+';
    case Score.GOOD:
      return 'Medium: 7+';
    case Score.PLEASANT:
      return 'Lower: 6+';
    default:
      return 'Lower: 6+';
  }
}

export const getNumberOfEmployeesLabel = (numberOfEmployees: NumbOfEmployees | string) => {
  switch (numberOfEmployees) {
    case NumbOfEmployees.FROM_0_TO_10:
      return "0-10";
    case NumbOfEmployees.FROM_10_TO_50:
      return "10-50";
    case NumbOfEmployees.FROM_50_TO_300:
      return "50-300";
    case NumbOfEmployees.FROM_300:
      return "300+";
    default:
      return 'Not yet defined';
  }
}

export const getAnnualRevenueLabel = (revenue: AnnualRevenue | string) => {
  switch (revenue) {
    case AnnualRevenue.FROM_0_TO_1M:
      return "$0-$1M";
    case AnnualRevenue.FROM_1M_TO_20M:
      return "$1M-$20M";
    case AnnualRevenue.FROM_20M_TO_50M:
      return "$20M-$50M";
    case AnnualRevenue.FROM_50M_TO_100M:
      return "$50M-$100M";
    case AnnualRevenue.FROM_100M_TO_250M:
      return "$100M-$250M";
    case AnnualRevenue.FROM_250M_TO_500M:
      return "$250M-$500M";
    case AnnualRevenue.FROM_500M_TO_1B:
      return "$500M-$1B";
    case AnnualRevenue.FROM_1B:
      return "$1B+";
    default:
      return 'Not yet defined';
  }
}

export const getYearsInBusinessLabel = (yearsInBusiness: YearsInBusiness | string) => {
  switch (yearsInBusiness) {
    case YearsInBusiness.LESS_THEN_1:
      return "less than 1";
    case YearsInBusiness.FROM_1_TO_5:
      return "1-5 years";
    case YearsInBusiness.FROM_5_TO_10:
      return "5-10 years";
    case YearsInBusiness.FROM_10:
      return "10 years+";
    default:
      return 'Not yet defined';
  }
}

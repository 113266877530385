import { styled } from "@mui/system";
import { Box, Button } from "@mui/material";
import theme from "application/theme";

export const ContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: ${theme.spacing(4)};
  padding: ${theme.spacing(3)};
  color: ${theme.palette.common.grey900};
`;

export const StyledBackToHomeButton = styled(Button)`
  background: ${theme.palette.common.blue700};
  padding: ${theme.spacing(1.5)} ${theme.spacing(2)};
  border-radius: ${theme.spacing(1)};
  font-weight: 500;
  font-size: ${theme.spacing(2)};
  line-height: ${theme.spacing(2.5)};
  border-radius: ${theme.spacing(1)};

  &:hover {
    background: ${theme.palette.common.blue500};
  }

  &:disabled {
    color: ${theme.palette.common.white};
    background-color: ${theme.palette.common.grey400};
  }
`;

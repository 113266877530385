import React from "react";
import { InfoCardWrapper, WarningBadge } from "./styles";
import { Typography } from "@mui/material";
import theme from "application/theme";
import { Box } from "@mui/system";
import { notActiveDays, showNotActiveDays, warningColor } from "./utils";
import { formatDate } from "application/utils";
import { getNumberFormatWithSeparators } from 'application/utils/numberUtils';

export interface PropsI {
  name: string;
  mr?: number;
  type: "credits" | "date" | "seats";
  credits?: number | null;
  usedCredits?: number | null;
  date?: string | null;
  seats?: number | null;
  usedSeats?: number | null;
  width?: string;
  displayInfoBadge?: boolean;
}

const InfoCard = (
  {name,
    mr,
    type,
    seats,
    usedSeats,
    credits,
    usedCredits,
    date,
    width,
    displayInfoBadge = true
  }: PropsI) => {
  const valueByType = () => {
    switch (type) {
      case "credits":
        return { firstValue: usedCredits, secondValue: credits };
      case "date":
        return {
          firstValue: date ? formatDate(date) : date,
          secondValue: undefined,
        };
      case "seats":
        return { firstValue: usedSeats, secondValue: seats };
      default:
        return {
          firstValue: credits,
          secondValue: usedCredits,
        };
    }
  };

  const valueExist = valueByType().firstValue && valueByType().secondValue;
  const haveCredits = credits && usedCredits && type === "credits";
  const haveDate = date && type === "date";

  return (
    <InfoCardWrapper mr={mr} width={width}>
      <Typography
        variant="caption"
        color={theme.palette.common.grey800}
        mb={1.5}
      >
        {name}
      </Typography>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box display={"flex"}>
          {valueExist ? (
            <>
              <Typography variant="h4" color={theme.palette.common.grey800}>
                { type === 'credits' ? getNumberFormatWithSeparators(Number(valueByType().firstValue)) :
                  valueByType().firstValue }&nbsp;
              </Typography>
              {valueByType().secondValue && (
                <Typography
                  variant="h4"
                  color={theme.palette.common.grey600}
                  sx={{ fontSize: 20, lineHeight: "32px" }}
                  display={"flex"}
                  alignItems={"end"}
                >
                  / {getNumberFormatWithSeparators(valueByType().secondValue || 0)}
                </Typography>
              )}
            </>
          ) : (
            <>
              <Typography variant="h4" color={theme.palette.common.grey800}>
                {valueByType().firstValue || 0}
                &nbsp;
              </Typography>
              <Typography
                variant="h4"
                color={theme.palette.common.grey600}
                sx={{ fontSize: 20, lineHeight: "32px" }}
                display={"flex"}
                alignItems={"end"}
              >
                /&nbsp;
                {valueByType().secondValue || 0}
              </Typography>
            </>
          )}
        </Box>
        {displayInfoBadge && !!haveCredits && (
          <WarningBadge
            bgcolor={
              warningColor(Number(Math.round((usedCredits / credits) * 100)))
                .bgColor
            }
            color={
              warningColor(Number(Math.round((usedCredits / credits) * 100)))
                .textColor
            }
          >
            {Math.round((usedCredits / credits) * 100)}%
          </WarningBadge>
        )}
        {displayInfoBadge && !!haveDate && notActiveDays(date) >= 30 && (
          <WarningBadge
            bgcolor={warningColor(notActiveDays(date)).bgColor}
            color={warningColor(notActiveDays(date)).textColor}
          >
            {showNotActiveDays(notActiveDays(date))}
          </WarningBadge>
        )}
      </Box>
    </InfoCardWrapper>
  );
};
export default InfoCard;

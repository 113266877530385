import { buttonHelper } from "./utils";

import theme from "application/theme";

import { styled } from "@mui/system";

import { Box, Button } from "@mui/material";

export const StyledButton = styled(Button)<{
  red?: number;
}>`
  background: ${(props) =>
    props.red
      ? theme.palette.common.red500
      : buttonHelper(props.type).background};
  padding: ${theme.spacing(1.25, 2)};
  border: ${(props) => buttonHelper(props.type).border};
  border-radius: ${theme.spacing(1)};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: ${(props) => buttonHelper(props.type).color};
  &:hover {
    background: ${(props) =>
      props.red
        ? theme.palette.common.red500
        : buttonHelper(props.type).hoverBg};
  }
  &:disabled {
    cursor: not-allowed;
    border: ${(props) => buttonHelper(props.type).disabledBorderColor};
    background: ${(props) =>
      props.type ? theme.palette.common.grey300 : theme.palette.common.white};
    color: ${(props) =>
      props.type ? theme.palette.common.grey600 : theme.palette.common.grey400};
  }
  &.selected {
    color: ${theme.palette.common.grey900};
    background: ${(props) =>
            props.red
                    ? theme.palette.common.red400
                    : theme.palette.common.blue50};
  }
`;

export const spinAnimation = `@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}`;

export const RotatingAdornment = styled(Box)<{ rotate: boolean | number }>`
  display: flex;
  align-items: center;
  ${({ rotate }) =>
    rotate &&
    `
    animation: spin 2s linear infinite;
    ${spinAnimation}
  `}
`;

import React, { useEffect, useMemo, useState } from "react";
import {
  ContentWrapper,
  InfoWrapper,
  LoaderWrapper,
  NavigationWrapper,
  TooltipNavBarWrapper,
  TooltipWrapper,
} from "./styles";
import { LeftArrow } from "application/assets/LeftArrow";
import { RightArrow } from "application/assets/RightArrow";
import DotsNavigation from "./DotsNavigation";
import { useAppSelector } from "application/store";
import { permitsSelector } from "common/permits/store";
import { LocationsLoader } from "common/companies/companiesFilter/LocalionCollapse/LocationsLoader";
import PermitTooltipItem from "./TooltipItem/PermitsTooltipItem";
import CompaniesTooltipItem from "./TooltipItem/CompaniesTooltipItem";
import { Box } from "@mui/system";
import { ClickAwayListener } from "@mui/material";

interface TooltipProps {
  active: boolean;
  onClose: () => void;
}

const MapTooltip = ({ active, onClose }: TooltipProps) => {
  const { companiesInfo, permitsInfo, tooltipLoading, showOnMapItemId } =
    useAppSelector(permitsSelector);

  const allItems = useMemo(() => {
    const companyItems = companiesInfo.map((data: any) => ({
      data,
      isCompanyItem: true,
    }));
    const permitItems = permitsInfo.map((data: any) => ({
      data,
      isCompanyItem: false,
    }));
    return[...companyItems, ...permitItems];
  }, [companiesInfo, permitsInfo]);

  const showNavigation = allItems.length > 1;

  const [currentNumber, setCurrentNumber] = useState(0);

  useEffect(() => {
    if(showOnMapItemId) {
      const idx = allItems.findIndex((item: any) => item.data.id === showOnMapItemId);
      if (idx !== -1) setCurrentNumber(idx);
    }
  }, [showOnMapItemId]);

  const handleShowPrev = () => {
    if (currentNumber > 0) setCurrentNumber(currentNumber - 1);
  };
  const handleShowNext = () => {
    if (currentNumber < allItems.length - 1) setCurrentNumber(currentNumber + 1);
  };

  const dotSelectItem = (idx: number) => {
    setCurrentNumber(idx);
  };
  return (
    <>
      <ClickAwayListener onClickAway={onClose}>
        <TooltipWrapper active={active ? 1 : 0}>
          <NavigationWrapper>
            {showNavigation && (
              <Box onClick={handleShowPrev} sx={{ cursor: "pointer" }}>
                <LeftArrow />
              </Box>
            )}
          </NavigationWrapper>
          {tooltipLoading ? (
            <LoaderWrapper>
              <LocationsLoader size={40} />
            </LoaderWrapper>
          ) : (
            <ContentWrapper>
              <InfoWrapper>
                {
                  allItems.map((item: any, idx) => (
                    item.isCompanyItem ? (
                      <CompaniesTooltipItem
                        show={idx === currentNumber}
                        data={item.data}
                        key={item.data.id}
                      />
                    ) : (
                      <PermitTooltipItem
                        show={idx === currentNumber}
                        data={item.data}
                        key={item.data.id}
                      />
                    )
                  ))
                }
              </InfoWrapper>
              <TooltipNavBarWrapper>
                <DotsNavigation
                  items={allItems}
                  dotSelectItem={dotSelectItem}
                  currentItem={currentNumber}
                />
              </TooltipNavBarWrapper>
            </ContentWrapper>
          )}

          <NavigationWrapper>
            {showNavigation && (
              <Box onClick={handleShowNext} sx={{ cursor: "pointer" }}>
                <RightArrow />
              </Box>
            )}
          </NavigationWrapper>
        </TooltipWrapper>
      </ClickAwayListener>
    </>
  );
};

export default MapTooltip;

import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'application/store';
import { bulkExportSelector } from '../../../store';
import { ExportTab } from '../../../types';
import { PermitBulkExportTable } from './PermitBulkExportTable';
import { BulkExportHistoryTable } from './BulkExportHistoryTable';

export const ExportPermitsTab = () => {
  const { name } = useParams();
  const {
    allPermits, newPermits, exportedPermits
  } = useAppSelector(bulkExportSelector);

  return (
    <>
      {name === ExportTab.NEW && <PermitBulkExportTable exportPermits={newPermits}/>}
      {name === ExportTab.ALL && <PermitBulkExportTable exportPermits={allPermits}/>}
      {name === ExportTab.EXPORTED && <PermitBulkExportTable exportPermits={exportedPermits}/>}
      {name === ExportTab.BULK_HISTORY && <BulkExportHistoryTable />}
    </>
  );
}

import {Table, TableBody, TableCell, TableHead, TableRow, Typography,} from "@mui/material";
import theme from "application/theme";
import React, {useMemo, useState} from "react";
import {TableContainerStyled} from "./styles";
import EmptyInfo from "../emptyInfo";
import {Box} from "@mui/system";
import {Button} from "application";
import {Options} from "./ShowMore";
import BadgeWithCheck from "application/components/BadgeWithCheck";
import {TeamStatus} from "common/organization/store/types";
import {AddCreditsForTeamMember} from "common/organization/modals/AddCreditsForTeamMember";
import useToggleModal from "application/hooks/useToggleModal";
import {organizationsSelector} from "common/organization/store";
import {useAppSelector} from "application/store";
import {sdrProfileSelector} from "common/sdrProfile/store";
import {CreditsDisplayItem} from "./CreditsDisplayItem";

const tableHeadShort = [
  "Team Member Name",
  "Email",
  "Status",
  "Company Credits",
  "Actions",
];

const tableHeadExtended = [
  "Team Member Name",
  "Email",
  "Status",
  "Company Credits",
  "Phone Credits",
  "Email Credits",
  "Actions",
];

const TeamTable = () => {
  const { isOpen, closeModal, openModal } = useToggleModal();
  const [sdrId, setSdrId] = useState<number | null>(null);
  const { sdrProfile } = useAppSelector(sdrProfileSelector);

  
  const handleEdit = (id: number) => {
    setSdrId(id);
    openModal();
  };
  const { teamMembers, organizationProfile } = useAppSelector(
    organizationsSelector
  );
  const { organizationStructureAccessPermitted } = organizationProfile;

  const tableHead = useMemo(() => {
    return organizationStructureAccessPermitted ? tableHeadExtended : tableHeadShort;
  }, [organizationStructureAccessPermitted]);

  return (
    <Box>
      {teamMembers.length > 0 ? (
        <TableContainerStyled>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ background: theme.palette.common.grey200 }}>
              <TableRow sx={{ height: theme.spacing(7.5) }}>
                {tableHead.map((e) => (
                  <TableCell key={e}>
                    <Typography
                      variant="body2"
                      color={theme.palette.common.grey800}
                    >
                      {e}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {teamMembers.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{
                    height: theme.spacing(7.5),
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {`${row.firstName ? row.firstName : ""} ${
                        row.lastName ? row.lastName : ""
                      }`}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {row.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      <BadgeWithCheck status={row.activationStatus} />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <CreditsDisplayItem totalCredits={row.companyDataCredits} usedCredits={row.usedCompanyDataCredits} />
                  </TableCell>
                  {
                    organizationStructureAccessPermitted &&
                        <>
                          <TableCell>
                            <CreditsDisplayItem totalCredits={row.phoneCredits} usedCredits={row.usedPhoneCredits} />
                          </TableCell>
                          <TableCell>
                            <CreditsDisplayItem totalCredits={row.emailCredits} usedCredits={row.usedEmailCredits}/>
                          </TableCell>
                        </>
                  }
                  <TableCell sx={{ width: "21%" }}>
                    <Box display="flex" alignItems="center">
                      {/* TODO when design ready
                      <Typography
                        variant="caption"
                        color={theme.palette.common.grey800}
                        mr={2}
                      >
                        <LinkStyled to={"#"}>View</LinkStyled>
                      </Typography> */}
                      <Button
                        label="Edit credits"
                        height={5}
                        type="submit"
                        width={113}
                        textVariant="caption"
                        disabled={row.activationStatus === TeamStatus.archived}
                        onClick={() => handleEdit(row.id)}
                      />
                      <Options
                        archived={row.activationStatus === TeamStatus.archived}
                        sdr={row}
                        isTeamMember={sdrProfile.id === row.id}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainerStyled>
      ) : (
        <EmptyInfo />
      )}

      {sdrId !== null && (
        <AddCreditsForTeamMember
          isOpen={isOpen}
          handleClose={closeModal}
          sdrId={sdrId}
        />
      )}
    </Box>
  );
};

export default TeamTable;

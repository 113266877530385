import { IconButton } from "@mui/material";
import { styled } from "@mui/system";
import theme from "application/theme";

export const IconButtonStyled = styled(IconButton)`
  display: flex;
  padding: ${theme.spacing(0.5)};
  align-items: center;
  height: fit-content;
  gap: ${theme.spacing(1.25)};
  border-radius: ${theme.spacing(0.5)};
  background: ${theme.palette.common.grey200};
`;

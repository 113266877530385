import React from "react";
import { Box } from "@mui/system";
import { FiltersHeader } from "../FiltersHeader";
import { CompanyPermitSearchAutocomplete } from "./CompanySearchAutocomplete";
import { usePermitFiltersContext } from '../../hooks/usePermitFiltersContext';

interface Props {
  displayFilters: boolean;
  onShowFilters: () => void;
}

export const SearchBar = ({ displayFilters, onShowFilters }: Props) => {
  const { filtersTotalCount} = usePermitFiltersContext();
  return (
    <Box display={"flex"} padding={2} gap={3} position={"relative"}>
      {!displayFilters && (
        <Box width={"max-content"}>
          <FiltersHeader
            count={filtersTotalCount}
            displayFilters={displayFilters}
            onToggleFilters={onShowFilters}
          />
        </Box>
      )}
        <CompanyPermitSearchAutocomplete />
    </Box>
  );
};

import { ExportDataI } from "common/bulkExport/store/types";
import { clientRequest } from "../requestHandler";

export const exportContacts = (exportData: ExportDataI ) =>
  clientRequest({
    url: `/api/companies/exports`,
    method: "POST",
    data: exportData,
  });

export const getNewEmployeesBySdrId = (page: number) =>
  clientRequest({
    url: `/employees/sdr`,
    method: "GET",
    params: {
      page,
      size: 10,
    },
  });

export const getAllEmployeesByOrgId = (orgId: number, page: number) =>
  clientRequest({
    url: `/employees/organization/${orgId}`,
    method: "GET",
    params: {
      page,
      size: 10,
    },
  });

export const getExportedEmployeesByOrgId = (orgId: number, page: number) =>
  clientRequest({
    url: `/employees/exported/${orgId}`,
    method: "GET",
    params: {
      page,
      size: 10,
    },
  });

export const deleteFromBulkExport = (bulkExportIds: number) =>
  clientRequest({
    url: `/employees`,
    method: "DELETE",
    params: {
      bulkExportIds,
    },
  });

export const addEmployeesToBulkExport = (data: any) =>
  clientRequest({
    url: `/employees`,
    method: "POST",
    data: data,
  });


export const getNewCompaniesBySdr = (page: number) =>
  clientRequest({
    url: `/employees/companies/sdr`,
    method: "GET",
    params: {
      page,
      size: 10,
    },
  });

export const getAllCompaniesByOrgId = (orgId: number, page: number) =>
  clientRequest({
    url: `/employees/companies/organization/${orgId}`,
    method: "GET",
    params: {
      page,
      size: 10,
    },
  });

export const getExportedCompaniesByOrgId = (orgId: number, page: number) =>
  clientRequest({
    url: `/employees/companies/organization/${orgId}/exported`,
    method: "GET",
    params: {
      page,
      size: 10,
    },
  });

export const getNewPermitsBySdr = (page: number) =>
  clientRequest({
    url: `api/permits/bulk-export/by-sdr`,
    method: "GET",
    params: {
      page,
      size: 10,
    },
  });

export const getAllPermitsByOrganization = (orgId: number, page: number) =>
  clientRequest({
    url: `api/permits/bulk-export/by-org/${orgId}`,
    method: "GET",
    params: {
      page,
      size: 10,
    },
  });

export const getExportedPermitsByOrganization = (orgId: number, page: number) =>
  clientRequest({
    url: `api/permits/bulk-export/by-org/${orgId}/exported`,
    method: "GET",
    params: {
      page,
      size: 10,
    },
  });


export const deletePermitFromBulkExport = (bulkExportId: number) =>
  clientRequest({
    url: `api/permits/bulk-export`,
    method: "DELETE",
    params: {
      bulkExportIds: bulkExportId,
    },
  });

import { Box, styled } from "@mui/system";
import theme from "application/theme";

export const TagStyled = styled(Box)`
  display: flex;
  padding: ${theme.spacing(1, 1.5)};
  justify-content: center;
  align-items: center;
  border-radius: ${theme.spacing(0.75)};
  border: 1px solid ${theme.palette.common.grey300};
  background: ${theme.palette.common.white};
`;

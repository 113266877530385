import React, { useEffect } from "react";
import theme from 'application/theme';
import { TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useLargeBulkExportInfo } from 'common/largeBulkExport/hooks/useLargeBulkExportInfo';
import { usePageQueryParams } from 'application/hooks/usePageQueryParams';
import { useSdrProfile } from 'common/sdrProfile/hooks/useSdrProfile';
import { BulkExportTableWrapper } from "../BulkExportTableWrapper";
import { TableRowStyled, TableTextSecondaryStyled, TableTextStyled } from './styles';
import { BulkExportStatus, BulkProcessI } from 'common/largeBulkExport/store/types';
import { formatDateTime, getTimeIn12HFormat } from 'application/utils/dateTimeUtil';
import { ExportStatus } from './ExportStatus';
import { Actions } from './Actions';
import { ConnectToHubspotModal } from 'common/organization/modals/ConnectToHubspotModal';
import { useHubspotIntegrationModal } from 'common/organization/hooks/useHubspotIntegrationModal';
import { ExportTo } from 'common/companies/types';
import { useLargeBulkExport } from 'common/largeBulkExport/hooks/useLargeBulkExport';
import { useInAppNotification } from 'common/notify';
import useBulkExport from 'common/bulkExport/useBulkExport';
const tableHead = ["Date and time", "Number", "Done by", "Status", "Actions"];
const UPDATE_STATUS_TIMEOUT = 15000;
export const BulkExportHistoryTable = () => {
  const {reexportCompanyBulkExportProcess, reexportPermitBulkExportProcess } = useLargeBulkExport();
  const {fetchCompaniesBulkExportProcesses,
    fetchPermitsBulkExportProcesses,
    companyBulkExportProcesses, permitBulkExportProcesses
  } = useLargeBulkExportInfo();
  const { showInfoMessage } = useInAppNotification();
  const { currentPage } = usePageQueryParams();
  const {isExportScopePermits} = useBulkExport();
  const {sdrProfile: {organizationId}} = useSdrProfile();
  const { isHubspotIntegrated,
    hubspotAuthUrl,
    hubspotModalIsOpened,
    openHubspotIntegrationModal,
    closeHubspotIntegrationModal} = useHubspotIntegrationModal();

  const fetchBulkExportProcesses = isExportScopePermits ? fetchPermitsBulkExportProcesses : fetchCompaniesBulkExportProcesses;

  const {content,
    totalElements,
    totalPages,
    size} = isExportScopePermits ? permitBulkExportProcesses : companyBulkExportProcesses;

  useEffect(() => {
    if (organizationId && currentPage > 1){
      fetchBulkExportProcesses(organizationId, currentPage - 1);
    }
  },[organizationId]);

  useEffect(() => {
    const interval = setInterval(() => {
      const hasActiveProcess = content?.some((process) => process.exportStatus === BulkExportStatus.IN_PROGRESS);
      if (organizationId && hasActiveProcess) {
        fetchBulkExportProcesses(organizationId, currentPage - 1, false);
      }
    }, UPDATE_STATUS_TIMEOUT);
    return () => clearInterval(interval);
  }, [content, organizationId]);


  const handleExportToHubspot = (processId: number) => {
    if(isExportScopePermits) {
      console.error('Export to Hubspot is not supported for permits');
      return;
    }
    if(isHubspotIntegrated) {
      reexportCompanyBulkExportProcess(processId, ExportTo.HUBSPOT)
        .then((isSuccess) => {
          if(isSuccess) {
            showInfoMessage('Please wait until reexport will be finished', 3000);
            fetchBulkExportProcesses(organizationId, 0);
          }
        });
    } else {
      openHubspotIntegrationModal();
    }
  }
  const handlePageChange = (page: number) => {
    fetchBulkExportProcesses(organizationId, page);
  }

  const handleReexportToCsv = (processId: number) => {
    const reexportCallback = isExportScopePermits ? () => reexportPermitBulkExportProcess(processId)
      : () => reexportCompanyBulkExportProcess(processId, ExportTo.CSV);
    reexportCallback()
      .then((isSuccess) => {
        if(isSuccess) {
          showInfoMessage('Please wait until reexport will be finished', 3000);
          handlePageChange(0);
        }
      });
  }
  return (
    <BulkExportTableWrapper totalElements={totalElements}
                            numberOfElements={size}
                            totalPages={totalPages}
                            pageRequest={handlePageChange}
                            contentIsEmpty={!content?.length}>
      <TableHead sx={{ background: theme.palette.common.grey200 }}>
        <TableRow sx={{ height: theme.spacing(6.25) }}>
          {tableHead.map((title) => (
            <TableCell key={title}>
              <Typography
                variant="body2"
                whiteSpace={'nowrap'}
                color={theme.palette.common.grey800}
              >
                {title}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {content?.length ? (
          content.map((bulkProcess: BulkProcessI) => (
            <TableRowStyled key={bulkProcess.id}>
              <TableCell>
                <TableTextStyled whiteSpace={'nowrap'}>
                  {formatDateTime(bulkProcess.startedAt + 'Z', "MMM DD, YYYY") ?? '-'}
                </TableTextStyled >
                <TableTextSecondaryStyled whiteSpace={'nowrap'}>
                  { getTimeIn12HFormat(bulkProcess.startedAt + 'Z') ?? '-'}
                </TableTextSecondaryStyled>
              </TableCell>
              <TableCell>
                <TableTextStyled>{bulkProcess.numberOfRecords}</TableTextStyled>
              </TableCell>
              <TableCell>
                <TableTextStyled>{bulkProcess.fullName}</TableTextStyled>
                <TableTextSecondaryStyled>{bulkProcess.email}</TableTextSecondaryStyled>
              </TableCell>
              <TableCell>
                <ExportStatus
                  status={bulkProcess.exportStatus}
                  exportTo={bulkProcess.exportTo}/>
              </TableCell>
              <TableCell>
                <Actions
                  processId={bulkProcess.id}
                  status={bulkProcess.exportStatus}
                  exportTo={bulkProcess.exportTo}
                  s3Url={bulkProcess.s3Url}
                  onExportToHubspot={handleExportToHubspot}
                  showHubspotAction={!isExportScopePermits}
                  onReexportToCsv={handleReexportToCsv}
                />
              </TableCell>
            </TableRowStyled>
          ))
        ) : null}
      </TableBody>
      <ConnectToHubspotModal
        isOpen={hubspotModalIsOpened}
        handleClose={closeHubspotIntegrationModal}
        onConfirmUrl={hubspotAuthUrl}
      />
    </BulkExportTableWrapper>
  );
};


import { Box } from "@mui/material";
import {
  CompaniesFilter,
  CompanyInfo,
  CompaniesList,
  CompaniesListExtended,
  SearchBar
} from "common";
import React, { useEffect, useRef, useState } from "react";
import { companiesListViewModeService } from 'common/companies/localStorageService';
import { CompaniesTutorialTours } from 'common/tutorial';
import { useSdrProfile } from 'common/sdrProfile/hooks/useSdrProfile';
import useIsMountedRef from 'application/hooks/useIsMountedRef';
import { useCompaniesSearch } from 'common/companies/hooks/useCompaniesSearch';
import { PageContentWrapper } from './styles';
import { useCompaniesPageContext } from 'common/companies/hooks/useCompaniesPageContext';

const CompaniesPage = () => {
  const {isExtendedView, setIsExtendedView} = useCompaniesPageContext();
  const {sdrProfile, getSdrProfileInfo} = useSdrProfile();
  const infiniteScrollRef = useRef<HTMLDivElement | null>(null);
  const isMounted = useIsMountedRef();
  const {fetchCompaniesPage} = useCompaniesSearch();
  useEffect(() => {
    if (isMounted.current) {
      getSdrProfileInfo();
    }
  }, []);

  const scrollToTop = () => {
    if (infiniteScrollRef.current) {
      infiniteScrollRef.current.scrollIntoView({behavior: "smooth"});
    }
  };
  const handleChangeView = (viewMode: boolean) => {
    companiesListViewModeService.saveIsExtendedView(viewMode);
    setIsExtendedView(viewMode);
    fetchCompaniesPage(0, true);
  }

  return (
    <Box display="flex" position="relative" height="100vh">
      <CompaniesTutorialTours/>
      <CompaniesFilter
        ref={infiniteScrollRef}
        scrollToTop={scrollToTop}
      />
      <Box width={'100%'}>
        <SearchBar isExtendedView={isExtendedView}
                   onChangeView={handleChangeView}/>
        <PageContentWrapper>
          {isExtendedView ?
            <CompaniesListExtended/>
            :
            <>
              <CompaniesList
                ref={infiniteScrollRef}
                scrollToTop={scrollToTop}
              />
              <CompanyInfo/>
            </>
          }
        </PageContentWrapper>
      </Box>
    </Box>
  );
};

export default CompaniesPage;



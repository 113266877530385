import React from "react";
import { Box } from "@mui/system";
import { Button } from "application";
import Joyride, {
  CallBackProps,
  STATUS,
  Step,
  TooltipRenderProps,
} from "react-joyride";
import Typography from "@mui/material/Typography";
import theme from "application/theme";
import { CloseOutlined } from "@mui/icons-material";
import { useAppDispatch } from "application/store";
import { useSdrProfile } from 'common/sdrProfile/hooks/useSdrProfile';

interface ProductTourSteps {
  steps: Step[];
  isTourOpen: boolean;
  setIsTourOpen: (b: boolean) => void;
}
const ProductTour = ({
  steps,
  isTourOpen,
  setIsTourOpen,
}: ProductTourSteps) => {
  const dispatch = useAppDispatch();
  const {getSdrProfileInfo} = useSdrProfile();

  const handleViewed = () => {
    getSdrProfileInfo();
  };

  const Tooltip = ({
    continuous,
    index,
    step,
    backProps,
    skipProps,
    primaryProps,
    isLastStep,
    tooltipProps,
  }: TooltipRenderProps) => (
    <Box
      {...tooltipProps}
      borderRadius={theme.spacing(1)}
      p={3}
      display="flex"
      flexDirection="column"
      gap={3}
      width={theme.spacing(60)}
      bgcolor={theme.palette.common.white}
      role="dialog"
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" justifyContent="space-between">
          {step.title && (
            <Typography variant="h5" color={theme.palette.common.grey700}>
              {step.title}
            </Typography>
          )}
          <Box onClick={handleViewed}>
            <Box {...skipProps} id={"skip"}>
              <CloseOutlined
                sx={{
                  color: theme.palette.common.grey700,
                  cursor: "pointer",
                }}
              />
            </Box>
          </Box>
        </Box>
        <Typography
          variant="overline"
          color={theme.palette.common.grey700}
          letterSpacing={theme.spacing(0.02)}
          lineHeight={theme.spacing(2.8)}
        >
          {step.content}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        {index > 0 ? (
          <Box
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              !isLastStep && event.stopPropagation();
            }}
          >
            <Button
              {...backProps}
              type="button"
              id="back"
              label={"Back"}
              width={theme.spacing(15)}
            />
          </Box>
        ) : (
          <Box />
        )}
        {continuous && (
          <Box
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              !isLastStep && event.stopPropagation();
              isLastStep && handleViewed();
            }}
          >
            <Button
              {...primaryProps}
              type="submit"
              id={!isLastStep ? "next" : "close"}
              label={!isLastStep ? "Next" : "Close"}
              width={theme.spacing(15)}
            />
          </Box>
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Typography variant="overline" color={theme.palette.common.grey700}>
          {index + 1}/{steps.length}
        </Typography>
      </Box>
    </Box>
  );

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setIsTourOpen(false);
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      tooltipComponent={Tooltip}
      hideCloseButton
      run={isTourOpen}
      disableOverlayClose
      scrollToFirstStep
      showProgress
      showSkipButton
      steps={steps}
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
    />
  );
};

export default ProductTour;

import React, {useEffect, useMemo} from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  RangeSelectorSliderStyled,
  RangeSelectorTitleStyled,
  RangeSelectorWrapperStyled
} from "./styles";
import { formatLargeNumbers } from "application/utils";
import theme from "application/theme";
import {Box} from "@mui/system";
import PermitInputFormatted from "./PermitInputFormatted";


interface Props {
  name: string;
  label?: string;
  valuePrefix?: string;
  maxRange?: {
    min: number;
    max: number;
  }
}

const RangeSelector = ({
                         name, label, maxRange, valuePrefix = ''
                       }: Props) => {
  const { control, watch, setValue } = useFormContext();
  const formValue = useMemo(() => watch(name), [watch(name)]);
  useEffect(() => {
    if (!formValue && maxRange) {
      setValue(name, { min: maxRange.min, max: maxRange.max })
    }
  }, [formValue, maxRange]);

  const handleInputMinChange = (newValue: number) => {
    if (formValue && newValue > formValue.max) {
      return;
    }
    setValue(name, { ...formValue, min: newValue })
  };
  const handleInputMaxChange = (newValue: number) => {
    if (formValue && newValue < formValue.min) {
      return;
    }
    setValue(name, { ...formValue, max: newValue })
  }

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setValue(name, { min: newValue[0], max: newValue[1] })
    }
  };

  const rangeToArray = (newRange: { min: number, max: number }) => {
    if (newRange) {
      return [newRange.min, newRange.max]
    }
    return [maxRange?.min || 0, maxRange?.max || 0];
  }

  function valuetext(value: number) {
    return valuePrefix + formatLargeNumbers(value);
  }

  return (
    <RangeSelectorWrapperStyled>
      {label &&
        <RangeSelectorTitleStyled variant="caption">{label}</RangeSelectorTitleStyled>
      }
      <Box display='flex' gap={theme.spacing(1)}>
        <PermitInputFormatted
            prefix={valuePrefix}
            onValueChange={handleInputMinChange}
            minValue={maxRange?.min}
            maxValue={formValue?.max}
            value={formValue?.min || 0}/>
        <PermitInputFormatted
            prefix={valuePrefix}
            onValueChange={handleInputMaxChange}
            minValue={formValue?.min}
            maxValue={maxRange?.max}
            value={formValue?.max || 0}/>
      </Box>
      <Box padding={theme.spacing(0,1)}>
        <Controller
            name={name}
            control={control}
            defaultValue={""}
            render={({ field }) => (
                <RangeSelectorSliderStyled
                    min={maxRange?.min}
                    max={maxRange?.max}
                    getAriaLabel={() => label || ''}
                    value={rangeToArray(field.value)}
                    valueLabelFormat={valuetext}
                    onChange={handleSliderChange}
                    valueLabelDisplay="off"
                />
            )}
        />
      </Box>

    </RangeSelectorWrapperStyled>
  )
}

export default RangeSelector;
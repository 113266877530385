import React from "react";

import { useAppSelector } from "application/store";

import { Typography } from "@mui/material";

import theme from "application/theme";

import noResults from "../../assets/noResults.svg";
import logoWithOpacity from "../../assets/logoWithOpacity.svg";

import {
  EmptyContent,
  EmptyContentWithTutorial,
  EmptyContentWrapper,
} from "./styles";
import { Box } from "@mui/system";
import { CompanyFiltersTutorial } from 'common/tutorial';

const EmptyCompanyInfo = () => {
  const filteredCompaniesAmount = useAppSelector(
    (state) => state.companies.filteredCompanies.numberOfElements
  );

  return (
    <EmptyContentWrapper>
      {!filteredCompaniesAmount ? (
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          p={2}
          width="100%"
        >
          <CompanyFiltersTutorial/>
          <EmptyContentWithTutorial>
            <img src={noResults} alt="No results found" />
            <Typography variant="button" color={theme.palette.common.grey800}>
              No results found
            </Typography>
            <Typography variant="caption" color={theme.palette.common.grey600}>
              Please adjust your filters and try again
            </Typography>
          </EmptyContentWithTutorial>
        </Box>
      ) : (
        <EmptyContent width={theme.spacing(60)}>
          <img src={logoWithOpacity} alt="logo" />
          <Typography
            variant="overline"
            color={theme.palette.common.grey700}
            textAlign="center"
          >
            Select a company from the list to see detailed info
          </Typography>
        </EmptyContent>
      )}
    </EmptyContentWrapper>
  );
};

export default EmptyCompanyInfo;

import React from 'react';
import { Button } from 'application';
import { ExportAllIcon } from 'application/assets';
import { useLargeBulkExportNew } from 'common/permits/components/largeBulkExport/hooks/useLargeBulkExportNew';
import { LargeBulkExportScope } from 'common/largeBulkExport/store/types';

export const StartExportPermitsButton = () => {
  const { startBulkExport, permitsFound } = useLargeBulkExportNew();
  return (
    <Button
      disabled={permitsFound === 0}
      startAdornment={<ExportAllIcon/>}
      label="Export all"
      onClick={() => startBulkExport(LargeBulkExportScope.PERMITS)}
      height={5.5}
      type="submit"
    />
  )
}

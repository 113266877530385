import React, { FC } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import { CommonErrors } from "../../errorService";
import { ResetPasswordDataT } from "./types";

import { TextFieldStyled, InfoWrapper, FormWrapper, BackLink } from "../styles";
import { Box, useTheme } from "@mui/system";
import { MTheme } from "application/theme";
import { PathNames } from "application/routes";
import { BackArrow } from "../assets/BackArrow";
import { useResetPassword } from "./useResetPassword";
import { AuthPageWrapper } from "../AuthPageLayout";
import { authService } from "../authService";
import ContactUs from "../ContactUs";
import { Button } from "application";

const schema = yup
  .object({
    email: yup
      .string()
      .email(CommonErrors.emailFormat)
      .required(CommonErrors.requiredField),
  })
  .required();

export const ForgotPassword: FC = () => {
  const { resetPassword } = useResetPassword();
  const theme: MTheme = useTheme();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordDataT>({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data: ResetPasswordDataT) => {
    authService.saveEmail(data.email);
    await resetPassword(data);
  };

  return (
    <AuthPageWrapper>
      <Box sx={{ width: "100%" }}>
        <Box mb={9.25}>
          <BackLink to={PathNames.login}>
            <BackArrow />
            <Typography
              variant="overline"
              color={theme.palette.common.grey800}
              ml={0.5}
            >
              Back to Log In screen
            </Typography>
          </BackLink>
        </Box>
        <InfoWrapper>
          <Typography variant="h3" mb={1.5}>
            Reset Password
          </Typography>
          <Typography variant="h5" color={theme.palette.common.grey800} mb={6}>
            <p>Please, enter email connected with your account</p>
            <p>we will send you email with a recovery link.</p>
          </Typography>

          <FormWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextFieldStyled
                {...register("email")}
                fullWidth
                type="Email"
                id="email"
                label="Enter your email"
                size="small"
                color="secondary"
                error={!!errors.email}
                helperText={!!errors.email && errors.email?.message}
                sx={{ marginBottom: 2.5 }}
              />
              <Button
                label="Continue"
                type="submit"
                width="100%"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
              />
              <ContactUs />
            </form>
          </FormWrapper>
        </InfoWrapper>
      </Box>
    </AuthPageWrapper>
  );
};

import { Box, styled } from '@mui/system';
import theme from 'application/theme';

export const FilterBodyWrapper = styled(Box)`
  flex: 1;
  overflow-y: scroll;
  padding: ${theme.spacing(1.5, 0)};
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const FilterGroupsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1.5)};
  padding: ${theme.spacing(0, 1, 2)};
`

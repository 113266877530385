import React, { useCallback, useEffect } from "react";
import { Snackbar, Alert, Typography } from "@mui/material";
import { useAppDispatch } from "application/store";
import { Button } from "application";
import { useNavigate } from "react-router-dom";
import { PathNames } from "application/routes";
import theme from "application/theme";
import { ExportScope } from "../types";
import { ExportScopePath, ExportTab } from 'common/bulkExport/types';
import { useInAppNotification } from 'common/notify';

export const NotifyReviewBulkExports = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    isBulkExportNotificationVisible,
    bulkExportNotificationScope: exportScope,
    hideBulkExportNotification } = useInAppNotification();

  const navigateToBulkExport = useCallback(() => {
    let path = '';
    switch (exportScope) {
      case ExportScope.EMPLOYEE:
        path = ExportScopePath.EMPLOYEES;
        break;
      case ExportScope.COMPANY:
        path = ExportScopePath.COMPANIES;
        break;
      case ExportScope.PERMIT:
        path = ExportScopePath.PERMITS;
        break;
    }
    navigate(`${PathNames.bulkExport}/${path}/${ExportTab.NEW}`);
    hideBulkExportNotification();
  }, [exportScope, navigate]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={isBulkExportNotificationVisible}
      onClose={hideBulkExportNotification}
      key={"bulkExportNotify"}
      autoHideDuration={4000}
    >
      <Alert onClose={hideBulkExportNotification} severity={"success"} sx={{ width: "100%" }}>
        <Typography
          variant="body2"
          color={theme.palette.common.green900}
          mb={2}
        >
          Contact was successfully added to bulk export.
        </Typography>
        <Button
          label={"Review Bulk Export"}
          type="button"
          width={theme.spacing(24)}
          height={4}
          onClick={navigateToBulkExport}
        />
      </Alert>
    </Snackbar>
  );
};

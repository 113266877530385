import React from "react";
import { CenteredWrapper, LinkStyled, StyledDivider } from "./styles";
import { Typography } from "@mui/material";
import theme from "application/theme";

const ContactUs = () => {
  return (
    <>
      <CenteredWrapper mb={1.5} mt={6}>
        <StyledDivider />
        <Typography
          variant="caption"
          color={theme.palette.common.grey800}
          mr={2}
          ml={2}
        >
          or
        </Typography>
        <StyledDivider />
      </CenteredWrapper>
      <Typography
        variant="caption"
        color={theme.palette.common.grey800}
        sx={{ fontWeight: 500 }}
      >
        Don’t have an access to your email?
        <LinkStyled to={"#"}>&nbsp;Contact Us.</LinkStyled>
      </Typography>
    </>
  );
};

export default ContactUs;

import React from "react";

export const InstagramIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 5.00781C11.9609 5.00781 13.9922 7.03906 13.9922 9.5C13.9922 12 11.9609 13.9922 9.5 13.9922C7 13.9922 5.00781 12 5.00781 9.5C5.00781 7.03906 7 5.00781 9.5 5.00781ZM9.5 12.4297C11.1016 12.4297 12.3906 11.1406 12.3906 9.5C12.3906 7.89844 11.1016 6.60938 9.5 6.60938C7.85938 6.60938 6.57031 7.89844 6.57031 9.5C6.57031 11.1406 7.89844 12.4297 9.5 12.4297ZM15.2031 4.85156C15.2031 4.26562 14.7344 3.79688 14.1484 3.79688C13.5625 3.79688 13.0938 4.26562 13.0938 4.85156C13.0938 5.4375 13.5625 5.90625 14.1484 5.90625C14.7344 5.90625 15.2031 5.4375 15.2031 4.85156ZM18.1719 5.90625C18.25 7.35156 18.25 11.6875 18.1719 13.1328C18.0938 14.5391 17.7812 15.75 16.7656 16.8047C15.75 17.8203 14.5 18.1328 13.0938 18.2109C11.6484 18.2891 7.3125 18.2891 5.86719 18.2109C4.46094 18.1328 3.25 17.8203 2.19531 16.8047C1.17969 15.75 0.867188 14.5391 0.789062 13.1328C0.710938 11.6875 0.710938 7.35156 0.789062 5.90625C0.867188 4.5 1.17969 3.25 2.19531 2.23438C3.25 1.21875 4.46094 0.90625 5.86719 0.828125C7.3125 0.75 11.6484 0.75 13.0938 0.828125C14.5 0.90625 15.75 1.21875 16.7656 2.23438C17.7812 3.25 18.0938 4.5 18.1719 5.90625ZM16.2969 14.6562C16.7656 13.5234 16.6484 10.7891 16.6484 9.5C16.6484 8.25 16.7656 5.51562 16.2969 4.34375C15.9844 3.60156 15.3984 2.97656 14.6562 2.70312C13.4844 2.23438 10.75 2.35156 9.5 2.35156C8.21094 2.35156 5.47656 2.23438 4.34375 2.70312C3.5625 3.01562 2.97656 3.60156 2.66406 4.34375C2.19531 5.51562 2.3125 8.25 2.3125 9.5C2.3125 10.7891 2.19531 13.5234 2.66406 14.6562C2.97656 15.4375 3.5625 16.0234 4.34375 16.3359C5.47656 16.8047 8.21094 16.6875 9.5 16.6875C10.75 16.6875 13.4844 16.8047 14.6562 16.3359C15.3984 16.0234 16.0234 15.4375 16.2969 14.6562Z"
        fill="#6D6D6D"
      />
    </svg>
  );
};

import { ThunkDispatchT, VoidThunk } from "application/types";
import { handleErrorNotification, hideLoader, showLoader } from "common";
import {
  editSdrProfile,
  getSdrProfile,
  setSdrLastActivityDay,
  setSdrtTutorial,
} from "integration/api/sdrs";
import { setSdrProfile } from ".";
import { EditSdrProfileI, SdrProfileI } from "./types";
import { handleResponse } from "common/responseService/responseService";
import { formatDateToYYYYMMDD } from "application/utils";

export const handleGetSdrProfile = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: SdrProfileI = await getSdrProfile();
      dispatch(setSdrProfile(response));
      dispatch(hideLoader());
    } catch (error) {
      handleErrorNotification(error);
      dispatch(hideLoader());
    }
  };
};

export const handleSetSdrTutorial = (id: number): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    try {
      await setSdrtTutorial(id);
    } catch (error) {
      handleErrorNotification(error);
    }
  };
};

export const handleSetSdrLastActivityDay = (): VoidThunk => {
  return async () => {
    try {
      const today = formatDateToYYYYMMDD(new Date());
      await setSdrLastActivityDay(today);
    } catch (error) {
      handleErrorNotification(error);
    }
  };
};

export const handleGetSdrProfileWithoutLoading = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    try {
      const response: SdrProfileI = await getSdrProfile();
      dispatch(setSdrProfile(response));
    } catch (error) {
      handleErrorNotification(error);
    }
  };
};

export const handleUpdateSdrProfile = (profile: EditSdrProfileI): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await editSdrProfile(profile);
      dispatch(handleGetSdrProfileWithoutLoading());
      handleResponse({
        message: `Profile was successfully updated`,
      });
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleGetSdrProfileAfterLogin = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    try {
      const response: SdrProfileI = await getSdrProfile();
      dispatch(setSdrProfile(response));
      dispatch(handleSetSdrLastActivityDay());
    } catch (error) {
      handleErrorNotification(error);
    }
  };
};

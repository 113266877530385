import React from "react";
import {Box} from "@mui/system";
import {Typography} from "@mui/material";
import theme from "application/theme";
import CopyIcon from "application/assets/CopyIcon";
import {CheckIconCircle} from "application/assets/CheckIconCircle";

interface Props {
    phones: string[];
    emails: string[];
}

export const ContactsTable = ({phones, emails}: Props) => {
    const [copiedContact, setCopiedContact] = React.useState<string | null>(null);
    const handleCopyContact = (contact: string) => {
        navigator.clipboard.writeText(contact);
        setCopiedContact(contact);
        setTimeout(() => setCopiedContact(null), 5000);
    }

    return (
        <Box display='flex' gap={3}>
            <Box display='flex' flexDirection='column' gap={2}>
                {phones.map((phone, index) => <ContactItem key={phone}
                                                           onCopyContact={handleCopyContact}
                                                           contactIsCopied={copiedContact === phone}
                                                           type={`Phone ${index + 1}`} contact={phone}/>)}
            </Box>
            <Box display='flex' flexDirection='column' gap={2}>
                {emails.map((email, index) => <ContactItem key={email}
                                                           onCopyContact={handleCopyContact}
                                                           contactIsCopied={copiedContact === email}
                                                           type={`Email ${index + 1}`} contact={email}/>)}
            </Box>
        </Box>
    )
}

interface ContactItemProps {
    type: string;
    contact: string;
    onCopyContact: (contact: string) => void;
    contactIsCopied?: boolean;
}

const ContactItem = ({type, contact, onCopyContact, contactIsCopied}: ContactItemProps) => {
    const handleClick = () => {
        onCopyContact(contact);
    }
    return <Box display='flex'
                onClick={handleClick}
                gap={1} alignItems='center' sx={{cursor: 'pointer'}}>
        {contactIsCopied}
        {
            contactIsCopied ? <CheckIconCircle/> :
                <CopyIcon
                    color={theme.palette.common.grey800}
                    hoverColor={theme.palette.common.grey600}
                />}
        <Typography variant='overline'
                    whiteSpace='nowrap'
                    color={theme.palette.common.grey800}>{type}</Typography>
        <Typography variant='overline'
                    fontWeight={500}
                    whiteSpace='nowrap'
                    textOverflow='ellipsis'
                    color={theme.palette.common.grey800}>{contact}</Typography>
    </Box>
}
import { styled, Typography } from '@mui/material';
import theme from 'application/theme';
import { Box } from '@mui/system';

export const ModalWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80vh;
  width: 100%;
`


export const ExportResultsStyled = styled(Typography)`
  font-weight: 500;
  font-size: ${theme.spacing(2.5)};
  line-height: ${theme.spacing(3)};
  color: ${theme.palette.common.grey900};
  .export-count {
    color: ${theme.palette.common.orange500};
  }
`

export const IncludeExportedCompaniesInfoStyled = styled(Typography)`
  font-weight: 500;
  font-size: ${theme.spacing(2)};
  line-height: ${theme.spacing(2.5)};
  color: ${theme.palette.common.grey900};
  .export-count {
    color: ${theme.palette.common.orange500};
  }
`
export const InfoPanelWrapper = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(3)};
  height: 60vh;
`

export const ActionsWrapper = styled(Box)`
  flex: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${theme.spacing(1.5)};
`

import React, { FC } from "react";

import theme from "application/theme";

import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";

interface Props {
  title: string;
  description: string;
  imgSrc: string;
}
export const SurveyIntroItem: FC<Props> = ({ title, description, imgSrc }) => {
  return (
    <Box display="flex" gap={2} alignItems="flex-start">
      <img src={imgSrc} alt={title} />
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="subtitle1" color={theme.palette.common.grey900}>
          {title}
        </Typography>
        <Typography variant="overline" color={theme.palette.common.grey800}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export enum ExportTab {
  NEW = 'new',
  ALL = 'all',
  EXPORTED = 'exported',
  BULK_HISTORY = 'bulk-history',
}

export enum ExportScopePath {
  EMPLOYEES = 'employees',
  COMPANIES = 'company',
  PERMITS = 'permit',
}
import {Box, IconButton} from "@mui/material";
import {InputStyled} from "application/components/FormInput/styles";
import theme from "application/theme";
import React from "react";
import {Close} from "@mui/icons-material";

interface LocationSearchInputProps {
    value: string;
    placeholder: string;
    onChange: (value: string) => void;
    onSubmit: () => void;
    disabled: boolean;
    onClear: () => void;
}

const LocationSearchInput = ({value, placeholder, onChange, onSubmit, disabled, onClear}: LocationSearchInputProps) => {
    const onEnterPressed = (e: any) => {
        if (e.key === "Enter") {
            onSubmit();
        }
    }
    return (
        <Box width={"100%"} mt={1}>
            <InputStyled
                font_size={theme.spacing(1.75)}
                sx={{width: "100%"}}
                value={value}
                placeholder={placeholder}
                height={4}
                autofillcolor={theme.palette.common.grey150}
                onChange={(e) => onChange(e.target.value)}
                onKeyDown={onEnterPressed}
                disabled={disabled}
                InputProps={{
                    endAdornment: (
                        <IconButton
                            sx={{ visibility: value ? "visible" : "hidden", padding: `2px` }}
                            onClick={onClear}
                        >
                            <Close sx={{ color: theme.palette.common.grey800 }} />
                        </IconButton>)}}
            />
        </Box>
    )
}
export default LocationSearchInput;
import React from "react";
import { Typography } from "@mui/material";
import theme from "application/theme";
import { Box } from "@mui/system";

interface PropsI {
  label: string;
  amount?: number;
  withoutAmount?: boolean;
}
const CompanyInfoLabel = ({ label, amount, withoutAmount = false }: PropsI) => {
  return (
    <Box display={"flex"} gap={1} alignItems={"center"}>
      <Typography variant="subtitle2" color={theme.palette.common.grey900}>
        {label}
      </Typography>
      {!withoutAmount ? (
        <Typography
          fontSize={theme.spacing(2)}
          fontWeight={500}
          lineHeight={theme.spacing(3.5)}
          color={theme.palette.common.grey500}
        >
          ({amount || 0})
        </Typography>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default CompanyInfoLabel;

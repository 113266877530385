import {styled, Typography} from '@mui/material'
import theme from 'application/theme'

interface Props {
  disabled?: boolean
}

export const ActionStyled = styled(Typography)<Props>`
  color: ${({disabled}) => (disabled ? theme.palette.common.grey400 : theme.palette.common.blue800)};
  font-size: ${theme.spacing(1.75)};
  line-height: 140%;
  font-weight: 500;
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
  white-space: nowrap;
`
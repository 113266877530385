import React, { useState } from "react";

import { NumbOfEmployees } from "./const";

import { Box, Collapse, FormGroup } from "@mui/material";

import { CheckboxGroupStyled } from "./styles";

import { CollapseLabel, MultipleCheckboxController } from "./components";
import { useFormContext } from "react-hook-form";
import { getNumberOfEmployeesLabel } from './utils';

const NumberOfEmployeesCollapse = ({ label }: { label: string }) => {
  const [expanded, setExpanded] = useState(false);
  const { watch } = useFormContext();
  const { companySizeRange } = watch();

  const ScoreOptions = [
    { value: NumbOfEmployees.FROM_0_TO_10, label: getNumberOfEmployeesLabel(NumbOfEmployees.FROM_0_TO_10) },
    { value: NumbOfEmployees.FROM_10_TO_50, label: getNumberOfEmployeesLabel(NumbOfEmployees.FROM_10_TO_50) },
    { value: NumbOfEmployees.FROM_50_TO_300, label: getNumberOfEmployeesLabel(NumbOfEmployees.FROM_50_TO_300) },
    { value: NumbOfEmployees.FROM_300, label: getNumberOfEmployeesLabel(NumbOfEmployees.FROM_300) },
  ];

  return (
    <Box width={"100%"}>
      <CollapseLabel
        label={label}
        expanded={expanded}
        setExpanded={setExpanded}
        count={companySizeRange?.length}
      />
      <Collapse in={expanded}>
        <CheckboxGroupStyled pt={1.5}>
          <FormGroup>
            {ScoreOptions.map((item: any) => (
              <MultipleCheckboxController
                fieldName={"companySizeRange"}
                value={item.value}
                label={item.label}
                key={item.value}
              />
            ))}
          </FormGroup>
        </CheckboxGroupStyled>
      </Collapse>
    </Box>
  );
};

export default NumberOfEmployeesCollapse;

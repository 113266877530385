import { ThunkDispatchT, VoidThunk } from "application/types";
import { setIsAuth, setLoading } from "./authStore";
import { store } from 'application/store';
import { getAuthToken } from "integration/cognito";
import { handleGetSdrProfileWithoutLoading } from "common/sdrProfile/store/actions";
import { handleGetNotifications } from "common/notificationsPage/store/actions";

export const handleIsAuth = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(setLoading(true));
    const token = await getAuthToken();
    if (token) {
      dispatch(setIsAuth(true));
      dispatch(handleGetSdrProfileWithoutLoading());
      dispatch(handleGetNotifications());
    } else {
      dispatch(setIsAuth(false));
    }
    dispatch(setLoading(false));
  };
};

export const handleLogout = () => {
  store.dispatch(setIsAuth(false));
};

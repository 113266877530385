import React from "react";
import { BadgeWrapper, Dot } from "./style";
import { Typography } from "@mui/material";
import { badgeHelper } from "./utils";
import { ActivationStatus } from "common/organization";
import theme from "application/theme";

const BadgeWithDot = ({
  status,
  height = 3.5,
}: {
  status: ActivationStatus | string;
  height?: number;
}) => {
  return (
    <BadgeWrapper status={status} height={theme.spacing(height)}>
      <Dot status={status} />
      <Typography variant="overline">{badgeHelper(status).text}</Typography>
    </BadgeWrapper>
  );
};

export default BadgeWithDot;

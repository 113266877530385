import React, { useState } from "react";
import { Box, Collapse } from "@mui/material";

import { CustomSwitch, FilterCounter } from "./styles";
import { CollapseLabelWrapper, HasPermitsSwitcherStyled } from "./components/styles";
import { Controller, useFormContext } from "react-hook-form";
import { RangeSelector } from "./components";
import { useAppSelector } from "../../../application/store";
import { companySelector } from "../store";

const PermitFilters = ({ label }: { label: string }) => {
  const [expanded, setExpanded] = useState(false);
  const { control, watch } = useFormContext();
  const { hasPermit } = watch();
  const { permitRangeValues } = useAppSelector(companySelector);
  const handleToggle = () => {
    setExpanded(!expanded);
  };
  return (
    <Box width={"100%"} className="has-permits-collapse">
      <CollapseLabelWrapper onClick={handleToggle} variant="caption">
        <HasPermitsSwitcherStyled>
          {label}
          {hasPermit ? (
            <FilterCounter>{hasPermit ? 1 : 0}</FilterCounter>
          ) : null}
        </HasPermitsSwitcherStyled>
        <Controller
          name="hasPermit"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <CustomSwitch
              name={field.name}
              checked={field.value || false}
              onChange={field.onChange}
            />
          )}
        />
      </CollapseLabelWrapper>
      <Collapse in={hasPermit}>
        <RangeSelector
          maxRange={permitRangeValues.annualPermitCount}
          name="permitFilters.annualPermitCount"
          label="Annual Permit Count"/>
        <RangeSelector
          maxRange={permitRangeValues.annualPermitValue}
          valuePrefix={"$"}
          name="permitFilters.annualPermitValue"
          label="Annual Permit Value"/>
      </Collapse>
    </Box>
  );
};

export default PermitFilters;

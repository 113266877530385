import React, { useEffect } from "react";
import { Box } from "@mui/system";
import { Checkbox, Typography } from "@mui/material";
import theme from "../../../application/theme";
import { CheckboxLabel, FormControlLabelStyled } from "../companiesFilter/styles";
import { useAppDispatch, useAppSelector } from "../../../application/store";
import { companySelector } from "../store";
import { handleChangeOrganizationNotAGoodFitStatus } from "../store/actions";

export const NotAGoodFitCheckbox = () => {
  const { company } = useAppSelector(companySelector);
  const dispatch = useAppDispatch();
  const [checked, setChecked] = React.useState(false);
  useEffect(() => {
    setChecked(company.notGoodFit)
  }, [company])

  const handleChange = () => {
    setChecked(!checked);
    dispatch(handleChangeOrganizationNotAGoodFitStatus(company.id, !checked));
  }
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <FormControlLabelStyled
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
          />
        }
        label={<CheckboxLabel>{'Not a good fit'}</CheckboxLabel>}
      />
      <Typography variant="caption" color={theme.palette.common.grey700}>
        This company will no longer appear in search results.
      </Typography>
    </Box>
  );
};

import React from 'react';
import { useInAppNotification } from './useInAppNotification';
import { Alert, Snackbar } from '@mui/material';

type Props = {
  type: "error" | "success" | "warning" | "info";
}
export const InAppNotification = ({type} : Props) => {
  const { infoMessage, showInfoMessage } = useInAppNotification();
  const handleClose = () => {
    showInfoMessage(null);
  }
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={!!infoMessage}
      autoHideDuration={5000}
    >
      <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
        {infoMessage}
      </Alert>
    </Snackbar>
  );
};

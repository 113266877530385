import type { FillLayer, LayerProps } from "react-map-gl";
import { MapViewSource } from './types';

export const companyLayer: LayerProps = {
  id: "companies",
  type: "circle",
  source: MapViewSource.COMPANY,
  filter: ["has", "point_count"],
  paint: {
    "circle-color": [
      "step",
      ["get", "point_count"],
      "#2176FF",
      100,
      "#2863EC",
      750,
      "#2F40CD",
    ],
    "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
  },
};

export const companyCountLayer: LayerProps = {
  id: "companies-count",
  type: "symbol",
  source: MapViewSource.COMPANY,
  filter: ["has", "point_count"],
  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    "text-size": 12,
  },
};

export const singleCompanyLayer: LayerProps = {
  id: "single-company-point",
  type: "circle",
  //TODO - ICONS
  // type: "symbol",
  source: MapViewSource.COMPANY,
  filter: ["!", ["has", "point_count"]],
  // layout: {
  //   "icon-image": "company-icon", // reference the image
  //   // "icon-anchor": "bottom",
  //   "icon-size": 0.2,
  // },
  paint: {
    'circle-color': '#108AFF',
    'circle-radius': 6,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#108AFF'
  }
};

export const permitLayer: LayerProps = {
  id: "permits",
  type: "circle",
  source: MapViewSource.PERMIT,
  filter: ["has", "point_count"],
  paint: {
    "circle-color": [
      "step",
      ["get", "point_count"],
      "#EC7E1F",
      100,
      "#E56F1C",
      750,
      "#DB5719",
    ],
    "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
  },
};

export const permitCountLayer: LayerProps = {
  id: "permit-count",
  type: "symbol",
  source: MapViewSource.PERMIT,
  filter: ["has", "point_count"],
  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    "text-size": 12,
  },
};

export const singlePermitLayer: LayerProps = {
  id: "single-permit-point",
  type: "circle",
  // type: "symbol",
  source: MapViewSource.PERMIT,
  filter: ["!", ["has", "point_count"]],
  // layout: {
  //   "icon-image": "permit-icon", // reference the image
  //   // "icon-anchor": "top",
  //   "icon-size": 0.2,
  // },
  paint: {
    'circle-color': '#F38D21',
    'circle-radius': 6,
    'circle-stroke-width': 1,
    'circle-stroke-color': '#F38D21'
  }
};

export const countiesLayer: FillLayer = {
  id: "counties",
  type: "fill",
  "source-layer": "original",
  paint: {
    "fill-outline-color": "rgba(0,0,0,0.1)",
    "fill-color": "rgba(0,0,0,0.1)",
  },
};

import React from "react";
import { Box, Typography } from "@mui/material";
import theme from "application/theme";
import { ModalComponent } from "application/components/ModalComponent";
import { Button } from "application";
import { ContactPersonStyled } from "../styles";
import { AddUserIcon } from "application/assets/AddUserIcon";

export const ReInvitationModal = ({
  isOpen,
  handleClose,
  email
}: {
  isOpen: boolean;
  handleClose: () => void;
  email: string;
}) => {
  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleClose}
      padding={3}
      width={475}
    >
      <Box textAlign="center">
        <Box display="flex" justifyContent="center" mb={4}>
          <AddUserIcon />
        </Box>
        <Typography variant="h5" color={theme.palette.common.grey800} mb={1.5}>
          Invitation was sent
        </Typography>

        <Typography
          variant="caption"
          color={theme.palette.common.grey700}
          display="block"
        >
          We resent an invitation to&nbsp;
          <ContactPersonStyled>{email}</ContactPersonStyled>
        </Typography>

        <Box
          display="flex"
          justifyContent="center"
          gap={theme.spacing(2)}
          mt={4}
        >
          <Button label="Okay" height={5.5} width={120} type="submit" onClick={handleClose} />
        </Box>
      </Box>
    </ModalComponent>
  );
};

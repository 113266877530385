import React from "react";

import theme from "application/theme";
import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import {
  ButtonsWrapper,
  IconButtonStyled,
  RemoveIconButtonStyled,
} from "./styles";
import { AddBtnIcon } from "application/assets/AddBtnIcon";
import { RemoveBtnIcon } from "application/assets/RemoveBtnIcon";

interface Props {
  name: string;
  onClick: (item: string) => void;
  isSelected: [string[], string[]];
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    backgroundColor: theme.palette.common.grey800,
    padding: theme.spacing(0.5, 1),
    marginTop: `-4px !important`,
    marginLeft: theme.spacing(2.75),
    fontSize: theme.spacing(1.75),
    lineHeight: "normal",
    fontWeight: 400,
  },
});

const LocationSelect = ({ name, onClick, isSelected }: Props) => {
    const addIsSelected = isSelected[0].filter((item) => item === name).length > 0;
    const removeIsSelected = isSelected[1].filter((item) => item === name).length > 0;
    const addIsDisabled = isSelected[1].length > 0;
    const removeIsDisabled = isSelected[0].length > 0;
    const addTooltipTitle = addIsDisabled ? "Disabled when selected exclude filter" : "Included in the search";
    const removeTooltipTitle = removeIsDisabled ? "Disabled when selected include filter" : "Excluded from search";
    const handleIncludeClick = () => {
      onClick("+")
    }
    const handleClickItem = () => {
      //todo implement if click on list item will be needed.
    }
  return (
    <ButtonsWrapper>
      <button style={{display: 'none'}} onClick={handleClickItem}/>
      <StyledTooltip title={addTooltipTitle} placement="bottom-start">
          <span>
              <IconButtonStyled
                className="add-button"
                sx={{
                  borderRadius: theme.spacing(0.5, 0, 0, 0.5),
                  border: `1px solid ${addIsSelected ? theme.palette.common.blue700 : theme.palette.common.grey400}`,
                }}
                disabled={addIsDisabled}
                onClick={handleIncludeClick}
              >
              <AddBtnIcon
                color={addIsSelected ? theme.palette.common.blue700 : theme.palette.common.grey500}
              />
            </IconButtonStyled>
          </span>
      </StyledTooltip>
      <StyledTooltip title={removeTooltipTitle} placement="bottom-start">
          <span>
              <RemoveIconButtonStyled
                sx={{
                  borderRadius: theme.spacing(0, 0.5, 0.5, 0),
                  border: `1px solid ${removeIsSelected ? theme.palette.common.blue700 : theme.palette.common.grey400
                  }`,
                }}
                disabled={removeIsDisabled}
                onClick={() => onClick("-")}
              >
                  <RemoveBtnIcon
                    color={removeIsSelected ? theme.palette.common.blue700 : theme.palette.common.grey500
                    }
                  />
            </RemoveIconButtonStyled>
          </span>

      </StyledTooltip>
    </ButtonsWrapper>
  );
};

export default LocationSelect;

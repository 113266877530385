import React from 'react';
import { useAppSelector } from 'application/store';
import { FiltersCollapse } from 'application';
import { FiltersWrapper } from '../components/styles';
import { permitFiltersSelector } from 'common/permits/store';
import { getArrayLength } from 'application/utils/arrayUtils';
import { LocationsFilterGroup } from '../components/LocationsFilterGroup';

export const PermitLocationFilter = () => {
  const {locations} = useAppSelector(permitFiltersSelector);
  const {
    citiesInclude, citiesExclude,
    statesInclude, statesExclude,
    zipCodesInclude, zipCodesExclude,
    countiesInclude, countiesExclude,
  } = locations;

  const generalCount = getArrayLength(citiesInclude) + getArrayLength(citiesExclude)
    + getArrayLength(statesInclude) + getArrayLength(statesExclude)
    + getArrayLength(zipCodesInclude) + getArrayLength(zipCodesExclude)
    + getArrayLength(countiesInclude) + getArrayLength(countiesExclude);

  return generalCount > 0 ? (
    <FiltersCollapse label={'LOCATION'} count={generalCount}>
      <FiltersWrapper>
        <LocationsFilterGroup label={'States include'} locations={statesInclude}/>
        <LocationsFilterGroup excludeFilter label={'States exclude'} locations={statesExclude}/>
        <LocationsFilterGroup label={'Cities include'} locations={citiesInclude}/>
        <LocationsFilterGroup excludeFilter label={'Cities exclude'} locations={citiesExclude}/>
        <LocationsFilterGroup label={'Zip codes include'} locations={zipCodesInclude}/>
        <LocationsFilterGroup excludeFilter label={'Zip codes exclude'} locations={zipCodesExclude}/>
        <LocationsFilterGroup label={'Counties include'} locations={zipCodesInclude}/>
        <LocationsFilterGroup excludeFilter label={'Counties exclude'} locations={zipCodesExclude}/>
      </FiltersWrapper>
    </FiltersCollapse>
  ) : <></>;
}

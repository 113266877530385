import React from 'react';
import { useAppSelector } from 'application/store';
import { FiltersCollapse } from 'application';
import { FiltersWrapper, FilterGroupWrapper } from '../components/styles';
import { CheckedFilterItem } from '../components/CheckedFilterItem';
import { permitFiltersSelector } from 'common/permits/store';

export const PermitTagsFilter = () => {
  const {tags} = useAppSelector(permitFiltersSelector);
  return tags && tags.length > 0 ? (
    <FiltersCollapse label={'TAGS'} count={tags.length}>
      <FiltersWrapper>
        <FilterGroupWrapper>
          {tags.map((tag) => (
            <CheckedFilterItem
              key={tag}
              label={tag}/>
          ))}
        </FilterGroupWrapper>
      </FiltersWrapper>
    </FiltersCollapse>
  ) : <></>;
};

import React, { ReactNode } from "react";

import theme from "application/theme";

import { ButtonProps, Typography, TypographyVariant } from "@mui/material";
import { Box } from "@mui/system";

import { RotatingAdornment, StyledButton } from "./styles";

type CustomButtonProps = {
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  label: string | undefined;
  mr?: number;
  ml?: number;
  height?: number;
  width?: number | string;
  red?: boolean;
  rotate?: boolean;
  textVariant?: TypographyVariant;
};

const Button = (props: ButtonProps & CustomButtonProps) => {
  const {
    startAdornment,
    endAdornment,
    onClick,
    label,
    variant,
    mr,
    ml,
    height = 5.5,
    width,
    red,
    type,
    rotate,
    textVariant = "button",
    fullWidth
  } = props;

  return (
    <Box
      width={fullWidth ? "100%" : "auto"}
      mr={mr}
      ml={ml}
      sx={{ cursor: props.disabled ? "not-allowed" : "pointer" }}
    >
      <StyledButton
        variant={variant}
        onClick={onClick}
        type={type}
        red={red ? 1 : 0}
        disabled={props.disabled}
        sx={{
          height: theme.spacing(height),
          width: fullWidth ? '100%' : width && width,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <RotatingAdornment rotate={rotate ? 1 : 0}>
            {startAdornment}
          </RotatingAdornment>
          <Typography
            variant={textVariant}
            fontWeight={500}
            style={{
              marginLeft: startAdornment ? theme.spacing(1.25) : 0,
              marginRight: endAdornment ? theme.spacing(1.25) : 0,
            }}
          >
            {label}
          </Typography>
          <RotatingAdornment rotate={rotate ? 1 : 0}>
            {endAdornment}
          </RotatingAdornment>
        </Box>
      </StyledButton>
    </Box>
  );
};

export default Button;

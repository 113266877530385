import React from "react";

export const LoadingIcon = ({ color = "#2B2B2B" }: { color?: string }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2143_15320"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="24"
      >
        <rect x="0.5" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2143_15320)">
        <path
          d="M20.9532 12.8923C20.6754 15.5022 19.1967 17.948 16.7497 19.3607C12.6842 21.7079 7.48566 20.315 5.13845 16.2495L4.88845 15.8165M4.04617 11.1066C4.32397 8.49674 5.80276 6.05102 8.24974 4.63825C12.3152 2.29104 17.5138 3.68398 19.861 7.74947L20.111 8.18248M3.99316 18.0654L4.72522 15.3334L7.45727 16.0654M17.5422 7.93349L20.2743 8.66554L21.0063 5.93349"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

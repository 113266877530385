import React, { useMemo } from 'react';
import { BulkExportStatus } from 'common/largeBulkExport/store/types';
import { ExportStatusWrapper } from './styles';
import {
  CheckIcon,
  ProcessingIcon,
  CrossIcon,
  ClockIcon,
  DownloadIcon
} from 'application/assets';
import { Typography } from '@mui/material';
import theme from 'application/theme';
import { ExportTo } from 'common/companies/types';

interface Props {
  status: BulkExportStatus;
  exportTo: ExportTo;
}
export const ExportStatus = ({status, exportTo}:Props) => {

  const color = useMemo(() => {
    switch (status) {
      case BulkExportStatus.IN_PROGRESS:
        return theme.palette.common.orange800;
      case BulkExportStatus.FINISHED:
        return theme.palette.common.green900;
      case BulkExportStatus.FAILED:
        return theme.palette.common.red600;
      case BulkExportStatus.EXPIRED:
        return theme.palette.common.grey900;
      default:
        return theme.palette.common.grey900;
    }
  }, [status]);

  const text = useMemo(() => {
    switch (status) {
      case BulkExportStatus.IN_PROGRESS:
        return exportTo===ExportTo.CSV ? 'CSV export In progress' : 'Hubspot export In progress';
      case BulkExportStatus.FINISHED:
        return exportTo===ExportTo.CSV ? 'Ready for download CSV' : 'Exported to Hubspot';
      case BulkExportStatus.FAILED:
        return <>Error occurred,<br/> please try again or contact support</>;
      case BulkExportStatus.EXPIRED:
        return exportTo===ExportTo.CSV ? 'CSV download is expired' : 'Exported to Hubspot';
      default:
        return 'Problem occurred, please contact support';
    }
  }, [status, exportTo]);
  const getIcon = () => {
    switch (status) {
      case BulkExportStatus.IN_PROGRESS:
        return <ProcessingIcon color={color}/>;
      case BulkExportStatus.FINISHED:
        return exportTo===ExportTo.CSV ? <DownloadIcon color={color}/>
          : <CheckIcon color={color}/>;
      case BulkExportStatus.FAILED:
        return <CrossIcon color={color}/>;
      case BulkExportStatus.EXPIRED:
        return <ClockIcon color={color}/>;
      default:
        return null;
    }
  }

  return (
    <ExportStatusWrapper status={status} >
      {getIcon()}
      <Typography variant="caption" color={color} whiteSpace={'nowrap'}>
        {text}
      </Typography>
    </ExportStatusWrapper>
  )
}
import React from 'react';
import { ModalComponent } from 'application/components/ModalComponent';
import theme from 'application/theme';
import { ModalContentWrapper } from './styles';
import { Box } from '@mui/system';
import { QuestionCircleIcon } from 'application/assets';
import { Typography } from '@mui/material';
import { Button } from 'application';
import { useLargeBulkExportNew } from 'common/permits/components/largeBulkExport/hooks/useLargeBulkExportNew';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const BackToSearchModal = ({isOpen, onClose}: Props) => {
  const {skipExportedAndUpdateFilters} = useLargeBulkExportNew();
  const handleUpdateSearchResults = () => {
    skipExportedAndUpdateFilters();
    onClose();
  }
  return (
    <ModalComponent
      isOpen={isOpen}
      padding={4}
      width={theme.spacing(60)}
      handleClose={onClose}
      closeOnClickOutside={false}
    >
      <ModalContentWrapper>
        <Box>
          <QuestionCircleIcon/>
        </Box>
        <Typography variant="subtitle1" color={theme.palette.common.grey800}>
          Do you want to update search results?
        </Typography>
        <Typography variant="caption" textAlign="center">
          Last exported companies will be excluded from results.
        </Typography>
        <Box display={'flex'} width={'100%'}>
          <Box display="flex" width="100%" gap={2}>
            <Button
              fullWidth
              label="No"
              onClick={onClose}
              height={5.5}
              type="button"
            />
            <Button
              fullWidth
              label="Yes"
              onClick={handleUpdateSearchResults}
              height={5.5}
              type="submit"
            />
          </Box>
        </Box>
      </ModalContentWrapper>
    </ModalComponent>
  )
}

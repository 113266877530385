import { Box } from "@mui/system";
import React from "react";
import { InfoCard } from "application";
import { useAppSelector } from "application/store";
import { organizationsSelector } from "common/organization/store";

const InfoBlock = () => {
  const { organizationProfile } = useAppSelector(organizationsSelector);
  const { organizationStructureAccessPermitted, companyDataCredits, usedCompanyDataCredits } = organizationProfile;
  return (
    <Box display={"flex"} justifyContent={"space-between"} mb={4}>
      <InfoCard
        name="Company credits used"
        mr={1.5}
        type="credits"
        usedCredits={usedCompanyDataCredits}
        credits={companyDataCredits}
      />
      {
        organizationStructureAccessPermitted &&
        <>
          <InfoCard
            name="Email credits used"
            mr={1.5}
            type="credits"
            usedCredits={organizationProfile.usedEmailCredits}
            credits={organizationProfile.emailCredits}
          />
          <InfoCard
            name="Phone credits used"
            mr={1.5}
            type="credits"
            usedCredits={organizationProfile.usedPhoneCredits}
            credits={organizationProfile.phoneCredits}
          />
        </>
      }
      <InfoCard
        name="The number of used seats"
        type="seats"
        seats={organizationProfile.seats}
        usedSeats={organizationProfile.usedSeats}
      />
    </Box>
  );
};

export default InfoBlock;

import { Grid } from "@mui/material";
import React from "react";
import { ContactCard } from "application/components";
import { extractDomain, getTimeByTimeZone } from "application/utils";
import useOrganizationProfile from "application/hooks/useOrganizationProfile";

const ContactBlock = () => {
  const { isHubspotIntegrated,
      removeHubspotIntegration,
      organizationProfile } = useOrganizationProfile();

  return (
    <Grid container spacing={2.5} mb={6}>
      <Grid item xs={3}>
        <ContactCard
          name="Contact Person"
          label={organizationProfile.contactPerson}
        />
      </Grid>
      <Grid item xs={3}>
        <ContactCard name="Location" label={organizationProfile.location} />
      </Grid>
      <Grid item xs={3}>
        <ContactCard
          name="Contact Person Email"
          label={organizationProfile.emailContactPerson}
          link
          url={`mailto:${organizationProfile.emailContactPerson}`}
          withIcon
        />
      </Grid>
      <Grid item xs={3}>
        <ContactCard
          name="Company Website"
          label={extractDomain(organizationProfile.website || "")}
          link
          url={organizationProfile.website || ""}
          withIcon
        />
      </Grid>
      <Grid item xs={3}>
        <ContactCard
          name="Contact Person Job Position"
          label={organizationProfile.jobPosition}
        />
      </Grid>
      <Grid item xs={3}>
        <ContactCard
          name="Time Zone"
          label={getTimeByTimeZone(organizationProfile.timezone || "")}
        />
      </Grid>
      <Grid item xs={3}>
        <ContactCard name="Phone" label={organizationProfile.phone} link />
      </Grid>

      {isHubspotIntegrated ? (
        <Grid item xs={3}>
          <ContactCard
            name="Hubspot integration"
            label="Remove"
            onClick={removeHubspotIntegration}
            badgeText={organizationProfile.hubSpotIntegrationStatus}
            withIcon
          />
        </Grid>
      ) : (
        <Grid item xs={3}>
          <ContactCard
            name="Hubspot integration"
            label="hubspot.account"
            link
            url={organizationProfile.hubspotAuthUrl || ""}
            withIcon
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ContactBlock;

import React, {useMemo} from "react";

import {Button} from "application";
import {Box} from "@mui/system";
import {Typography} from "@mui/material";
import theme from "application/theme";
import {CreditChargedText, HubspotModalContentWrapper, ListHideEffect,} from "./styles";
import {ModalComponent} from "application/components/ModalComponent";
import {useAppDispatch, useAppSelector} from "application/store";
import {bulkExportSelector, setExportedContactsTypes,} from "common/bulkExport/store";
import {
  hideExportResult,
  organizationSelector,
  resultExportSelector,
  selectedCompanySelector,
  setSelectedOrganization,
} from "../store";
import {ExportedEmployeesTable} from "common/bulkExport/modal/ExportedEmployeesTable";
import {ExportedCompaniesTable} from "common/bulkExport/modal/ExportedCompaniesTable";

export const ExportResultModal = () => {
  const dispatch = useAppDispatch();
  const { exportResult } = useAppSelector(bulkExportSelector);
  const organization = useAppSelector(organizationSelector);
  const company = useAppSelector(selectedCompanySelector)
  const isOpen = useAppSelector(resultExportSelector);

  const handleCloseModal = () => {
    dispatch(hideExportResult());
    dispatch(setSelectedOrganization({}));
    dispatch(setExportedContactsTypes({}));
  };
  const exportedEmployees = useMemo(()=> {
    return {
      firstName: organization?.firstName,
      lastName: organization?.lastName,
      title: organization.title,
      photoUrl: organization?.photoUrl || "",
      state: organization.state,
      city: organization.city,
      country: organization.country,
      emailExported: !!exportResult?.exportResultInfos[0]?.emailExported,
      phoneExported: !!exportResult?.exportResultInfos[0]?.phoneExported,
      id: 0,
    }
  }, [exportResult, organization]);
  const exportedCompany = useMemo(()=> {
    const location = company?.locations[0];
    return {
      companyId: company.id || 0,
      companyName: company.businessName || "-",
      companyLocation: `${location?.state || "-"}, ${location?.city || "-"}`,
    }
  }, [exportResult, organization]);

  const isCompanyExportScope = useMemo(()=> {
    return exportResult?.exportResultInfos[0]?.exportScope === "COMPANY";
  },[exportResult]);

  return (
    <ModalComponent
      isOpen={isOpen}
      padding={3}
      width={theme.spacing(78.5)}
      handleClose={handleCloseModal}
    >
      <Box>
        <Typography variant="subtitle1" color={theme.palette.common.grey900}>
          Contact was Successfully Exported as CSV
        </Typography>
        <Typography variant="overline" color={theme.palette.common.grey900}>
          This organization appear...
        </Typography>
        <HubspotModalContentWrapper
          mt={2}
          sx={{
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: theme.palette.common.grey400,
              width: theme.spacing(0.5),
              height: theme.spacing(13.75),
            },
          }}
        >
          {
            isCompanyExportScope ?
                <ExportedCompaniesTable exportedCompanies={[exportedCompany]}/>
                :
                <ExportedEmployeesTable exportedEmployees={[exportedEmployees]}/>
          }
          <ListHideEffect />
        </HubspotModalContentWrapper>
        <Box mt={3} display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            {isCompanyExportScope ?
                <CreditsChargedItem text={'Total company credits charged:'}
                                    creditsCharged={exportResult.companyDataCreditsCharged}/>
                : <>
                  <CreditsChargedItem text={'Total email credits charged:'}
                                      creditsCharged={exportResult.emailCreditsCharged}/>
                  <CreditsChargedItem text={'Total phone credits charged:'}
                                      creditsCharged={exportResult.phoneCreditsCharged}/>
                </>
            }
          </Box>
          <Button
            label="Back to Search"
            height={5.5}
            type="submit"
            onClick={handleCloseModal}
          />
        </Box>
      </Box>
    </ModalComponent>
  );
};

const CreditsChargedItem = ({text, creditsCharged}: {text: string, creditsCharged: number}) => {
  return (
      <Typography variant="overline" color={theme.palette.common.grey800}>
        {text}&nbsp;
        <CreditChargedText>
          {creditsCharged} credits
        </CreditChargedText>
      </Typography>
  )
}

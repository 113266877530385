import React from "react";
import theme from 'application/theme';
import { IconProps } from './types';

export const CrossIcon: React.FC<IconProps> = ({color = theme.palette.common.grey900}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <mask id="mask0_4781_14731" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="14">
        <rect width="14" height="14" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_4781_14731)">
        <path
          d="M3.73366 11.0832L2.91699 10.2665L6.18366 6.99984L2.91699 3.73317L3.73366 2.9165L7.00033 6.18317L10.267 2.9165L11.0837 3.73317L7.81699 6.99984L11.0837 10.2665L10.267 11.0832L7.00033 7.8165L3.73366 11.0832Z"
          fill={color}/>
      </g>
    </svg>
  );
};


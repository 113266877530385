import { Typography } from "@mui/material";
import theme from "application/theme";
import { NavbarItemWrapper } from "common/organization/styles";
import React from "react";

export const TabItem = ({
  label,
  count,
  active,
  onClick,
}: {
  label: string;
  count: number;
  active?: boolean;
  onClick: () => void;
}) => {
  return (
    <NavbarItemWrapper active={active ? 1 : 0} onClick={onClick}>
      <Typography
        variant="h5"
        color={
          active ? theme.palette.common.blue700 : theme.palette.common.grey600
        }
        mr={1}
        fontWeight={500}
      >
        {label}
      </Typography>
    </NavbarItemWrapper>
  );
};

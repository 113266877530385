import React from 'react';
import { ProductTypeFilter } from '../../ProductTypeFilter';
import { usePermitFiltersContext } from 'common/permits/hooks/usePermitFiltersContext';
import { useAppSelector } from 'application/store';
import { permitsSelector } from 'common/permits/store';
import { ProductTypeI } from 'common/permits/types';
import { getPermitProductTypeBrand, getPermitProductTypeBrandName } from 'integration/api/permits';

const PRODUCT_TYPE_FILTER_NAME = "productTypes";
export const PermitProductTypeFilter = () => {
  const {permitFilterForm: {watch, setValue}} = usePermitFiltersContext();
  const {productTypes} = useAppSelector(permitsSelector);
  const selectedTypes = watch(PRODUCT_TYPE_FILTER_NAME) || [];
  const handleChangeSelected = (selectedTypes: ProductTypeI[]) => {
    setValue(PRODUCT_TYPE_FILTER_NAME, selectedTypes);
  }
  return (
    <ProductTypeFilter
      productTypes={productTypes}
      selectedTypes={selectedTypes}
      onSelectedTypesChange={handleChangeSelected}
      fetchProductBrandsCallback={getPermitProductTypeBrand}
      fetchProductNamesCallback={getPermitProductTypeBrandName}
    />
  )
}

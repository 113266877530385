import { CompanyFilterDataI } from 'integration/api/companies/types';
import { countProductTypeFilters } from 'common/permits/utils';

export const getCountCompaniesFilters = (filterData: CompanyFilterDataI) => {
  const {
    name,
    companySizeRange,
    annualRevenueRange,
    reputationScore,
    serviceType,
    yearsInBusinessRange,
    skipExported,
    isGeneralContractor,
    isSubContractor,
    subContractorCategories,
    tags,
    hasPermit,
    citiesInclude,
    citiesExclude,
    statesInclude,
    statesExclude,
    notGoodFit,
  } = filterData;
  const countProductTypes = countProductTypeFilters(filterData.productTypes || []);
  return (
    (name ? 1 : 0) +
    (skipExported ? 1 : 0) +
    (notGoodFit ? 1 : 0) +
    (companySizeRange?.length ?? 0) +
    (annualRevenueRange?.length ?? 0) +
    (reputationScore ? 1 : 0) +
    (hasPermit ? 1 : 0) +
    (serviceType?.length ?? 0) +
    (yearsInBusinessRange?.length ?? 0) +
    (isGeneralContractor ? 1 : 0) +
    (isSubContractor ? 1 : 0) +
    (citiesInclude?.length ?? 0) +
    (citiesExclude?.length ?? 0) +
    (statesInclude?.length ?? 0) +
    (statesExclude?.length ?? 0) +
    (subContractorCategories?.length ?? 0) +
    (tags?.length ?? 0) +
    countProductTypes
  );
}

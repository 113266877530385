import React from 'react';
import { ExportTab } from '../../../types';
import { BulkExportHistoryTable, CompanyBulkExportTable } from './index';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'application/store';
import { bulkExportSelector } from '../../../store';

export const ExportCompaniesTab = () => {
  const { name } = useParams();
  const {
    allCompanies, newCompanies, exportedCompanies
  } = useAppSelector(bulkExportSelector);

  return (
    <>
      {name === ExportTab.NEW && <CompanyBulkExportTable exportCompanies={newCompanies}/>}
      {name === ExportTab.ALL && <CompanyBulkExportTable exportCompanies={allCompanies}/>}
      {name === ExportTab.EXPORTED && <CompanyBulkExportTable exportCompanies={exportedCompanies}/>}
      {name === ExportTab.BULK_HISTORY && <BulkExportHistoryTable />}
    </>
  );
}

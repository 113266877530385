import React from 'react';
import { useAppSelector } from 'application/store';
import { FiltersCollapse } from 'application';
import { FiltersWrapper, FilterGroupWrapper } from '../components/styles';
import { CheckedFilterItem } from '../components';
import { permitFiltersSelector } from 'common/permits/store';

export const PermitTypesFilter = () => {
  const {permitTypes} = useAppSelector(permitFiltersSelector);
  return permitTypes && permitTypes.length > 0 ? (
    <FiltersCollapse label={'PERMIT TYPES'} count={permitTypes.length}>
      <FiltersWrapper>
        <FilterGroupWrapper>
          {permitTypes.map((permitType) => (
            <CheckedFilterItem
              key={permitType}
              label={permitType}/>
          ))}
        </FilterGroupWrapper>
      </FiltersWrapper>
    </FiltersCollapse>
  ) : <></>;
};

import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "application/store";
import { handleGetCompanyStructure } from "common/companies/store/actions";
import {
  companyStructureContentSelector,
  isStructureLoading,
} from "common/companies/store";

import { Box } from "@mui/system";
import {
  Skeleton,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import theme from "application/theme";
import { Button } from "application";
import { TimeIcon } from "application/assets/TimeIcon";

import { LoadingIcon } from "application/assets/LoadingIcon";
import { PositionedButton } from "./styles";
import { selectedCompanySelector } from "common/companies/store";

const TableSkeleton = () => {
  const dispatch = useAppDispatch();
  const selectedCompany = useAppSelector(selectedCompanySelector);
  const isLoading = useAppSelector(isStructureLoading);
  const [buttonText, setButtonText] = useState("Get the Latest Data");
  const companyStructureContent = useAppSelector(
    companyStructureContentSelector
  );

  useEffect(() => {
    if (companyStructureContent && !companyStructureContent.length) {
      setButtonText("Nothing found");
    }
  }, [companyStructureContent, isLoading]);

  const renderTableRows = () => {
    const rowCount = 3;

    const renderSkeletonText = () => (
      <Skeleton variant="text" sx={{ bgcolor: theme.palette.common.grey200 }} />
    );

    return Array.from(Array(rowCount), (_, idx) => (
      <Box
        key={idx}
        height={theme.spacing(7.5)}
        display="flex"
        alignItems="center"
        borderBottom={`1px solid ${theme.palette.common.grey200}`}
      >
        <Box width="30%" padding={2.5}>
          <Box gap={2} display="flex">
            <Skeleton
              variant="circular"
              sx={{
                display: "flex",
                bgcolor: theme.palette.common.grey200,
                width: theme.spacing(5),
                minWidth: theme.spacing(5),
                height: theme.spacing(5),
              }}
            />
            <Box display="flex" flexDirection="column">
              <Skeleton
                variant="text"
                sx={{
                  bgcolor: theme.palette.common.grey200,
                  width: theme.spacing(15),
                }}
              />
              <Skeleton
                variant="text"
                sx={{
                  bgcolor: theme.palette.common.grey200,
                  width: theme.spacing(15),
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box width="25%" pt={2.5} pb={2.5}>
          <Typography variant="caption" color={theme.palette.common.grey800}>
            <Box display="flex" flexDirection="column">
              {renderSkeletonText()}
              {renderSkeletonText()}
            </Box>
          </Typography>
        </Box>
        <Box width="35%" minWidth={theme.spacing(18.75)} padding={2.5}>
          {renderSkeletonText()}
        </Box>
      </Box>
    ));
  };

  return (
    <TableBody>
      <TableRow>
        <TableCell
          colSpan={3}
          sx={{
            borderBottom: "none",
            padding: theme.spacing(0),
            position: "relative",
            width: "100%",
          }}
        >
          {renderTableRows()}
          <PositionedButton>
            <Button
              label={isLoading ? "Loading" : buttonText}
              width={theme.spacing(28)}
              type="button"
              startAdornment={isLoading ? <LoadingIcon /> : <TimeIcon />}
              onClick={() =>
                dispatch(handleGetCompanyStructure(selectedCompany.id, 0))
              }
              rotate={isLoading}
            />
          </PositionedButton>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default TableSkeleton;

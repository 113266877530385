import { EditSdrProfileI } from "common/sdrProfile/store/types";
import { clientRequest } from "../requestHandler";
import { ConfirmRegistrationI } from "./types";
import { formatDateToYYYYMMDD } from "application/utils";

export const getSdrProfile = () => {
  return clientRequest({
    url: `/api/sdrs/profile`,
    method: "GET",
  });
};

export const setSdrtTutorial = (id: number) => {
  return clientRequest({
    url: `/api/sdrs/tutorials`,
    method: "PATCH",
    data: {
      id,
      displayDate: formatDateToYYYYMMDD(new Date()),
    },
  });
};

export const editSdrProfile = (profile: EditSdrProfileI) => {
  return clientRequest({
    url: `/api/sdrs/profile`,
    method: "PATCH",
    data: profile,
  });
};

export const confirmSdrRegistration = (data: ConfirmRegistrationI) =>
  clientRequest({
    url: `api/sdrs/confirm`,
    method: "PATCH",
    data,
  });

export const setSdrLastActivityDay = (date: string) => {
  return clientRequest({
    url: `/api/sdrs/profile/last-activity?date=${date}`,
    method: "PATCH",
  });
};

import React from "react";
import { ButtonProps, TypographyVariant } from "@mui/material";
import { LoadingIcon } from "application/assets/LoadingIcon";
import { buttonHelper } from "./utils";
import { Button } from "application";

type CustomButtonProps = {
  label: string | undefined;
  mr?: number;
  ml?: number;
  height?: number;
  width?: number | string;
  red?: boolean;
  isLoading?: boolean;
  textVariant?: TypographyVariant;
};

const LoadingButton = (props: ButtonProps & CustomButtonProps) => {
  const {
    onClick,
    label,
    variant,
    mr,
    ml,
    height = 5.5,
    width,
    red,
    type,
    isLoading,
    textVariant = "button",
  } = props;

  return (
    <Button
      ml={ml}
      mr={mr}
      height={height}
      width={width}
      red={red}
      label={label}
      textVariant={textVariant}
      type={type}
      variant={variant}
      rotate={isLoading}
      disabled={props.disabled}
      onClick={onClick}
      startAdornment={
        isLoading && <LoadingIcon color={buttonHelper(type).color} />
      }
    />
  );
};

export default LoadingButton;

interface State {
  expanded: boolean;
  counter: number;
  searchLocation: string;
}

type Action =
  | { type: "SET_EXPANDED"; payload: boolean }
  | { type: "SET_SEARCH_LOCATION"; payload: string }
  | { type: "UPDATE_COUNTER"; payload: number };

export const initialStateLocation = {
  expanded: false,
  counter: 0,
  searchLocation: "",
};

export const reducerLocation = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_EXPANDED":
      return { ...state, expanded: action.payload };
    case "SET_SEARCH_LOCATION":
      return { ...state, searchLocation: action.payload };
    case "UPDATE_COUNTER":
      return { ...state, counter: action.payload };
    default:
      return state;
  }
};

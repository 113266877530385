import React from 'react';
import { useAppSelector } from 'application/store';
import { FiltersCollapse } from 'application';
import { FilterGroupWrapper, FiltersWrapper } from '../components/styles';
import { CheckedFilterItem } from '../components/CheckedFilterItem';
import { getYearsInBusinessLabel } from 'common/companies/companiesFilter/utils';
import { companyFiltersSelector } from 'common/permits/store';

export const YearsInBusinessFilter = () => {
  const {yearsInBusinessRange} = useAppSelector(companyFiltersSelector);
  return yearsInBusinessRange.length > 0 ? (
    <FiltersCollapse label={'YEARS IN BUSINESS'} count={yearsInBusinessRange.length}>
      <FiltersWrapper>
        <FilterGroupWrapper>
          {yearsInBusinessRange.map((item) => (
            <CheckedFilterItem
              key={item}
              label={getYearsInBusinessLabel(item)}/>
          ))
          }
        </FilterGroupWrapper>
      </FiltersWrapper>
    </FiltersCollapse>
  ) : <></>;
}

import React, { useEffect, useMemo } from "react";
import { Box } from "@mui/system";
import theme from "application/theme";

import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { StyledCheckbox } from "../../../styles";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "application/store";
import {
  bulkExportSelector, setSelectedCompanies,
  setTotalSelectedCompanies,
} from "../../../store";
import { CompaniesDataI, ExportCompanyI } from "../../../store/types";
import { BinIcon } from "application/assets/BinIcon";
import { sdrProfileSelector } from "common/sdrProfile/store";
import { BulkExportTableWrapper } from "./BulkExportTableWrapper";
import useBulkExport from "../../../useBulkExport";

const tableHead = ["Location", ""];

const CompanyBulkExportTable = ({
                                  exportCompanies,
                                }: {
  exportCompanies: CompaniesDataI;
}) => {
  const {
    fetchNewCompaniesPage,
    fetchAllCompaniesPage,
    fetchExportedCompaniesPage,
    deleteCompanyFromBulkExport
  } = useBulkExport();
  const dispatch = useAppDispatch();
  const {
    name: currentTab
  } = useParams();
  const { sdrProfile } = useAppSelector(sdrProfileSelector);
  const location = useLocation();
  const currentPage = Number(location.search.split("?")[1]);

  const { selectedCompanies, totalSelectedCompanies } = useAppSelector(bulkExportSelector);

  const { totalElements, totalPages, numberOfElements, content } =
    exportCompanies;

  const pageRequest = (page: number) => {
    currentTab === "new" && fetchNewCompaniesPage(page);
    currentTab === "all" && fetchAllCompaniesPage(sdrProfile.organizationId, page);
    currentTab === "exported" && fetchExportedCompaniesPage(sdrProfile.organizationId, page);
  };

  const handleDeleteCompany = async (id: number) => {
    await deleteCompanyFromBulkExport(id);
    const selectedCompanies = totalSelectedCompanies.filter((item: ExportCompanyI) => item.id !== id);
    dispatch(setTotalSelectedCompanies(selectedCompanies));
  };

  useEffect(() => {
    const selectedOnPage = content.filter((item: ExportCompanyI) => {
      return totalSelectedCompanies.some((selectedItem: ExportCompanyI) => item.id === selectedItem.id);
    });
    dispatch(setSelectedCompanies(selectedOnPage))
  }, [content, totalSelectedCompanies])

  const isAllSelectedOnPage = useMemo(() => {
    return selectedCompanies.length === content.length;
  }, [selectedCompanies, content]);

  const onSelectAll = () => {
    if (isAllSelectedOnPage) {
      const allExcludeCompaniesOnPage = totalSelectedCompanies.filter((item: ExportCompanyI) => {
        return !content.some((selectedItem: ExportCompanyI) => item.id === selectedItem.id);
      });
      dispatch(setTotalSelectedCompanies(allExcludeCompaniesOnPage));
    } else {
      const unselectedCompanies = content.filter((item: ExportCompanyI) => {
        return !totalSelectedCompanies.some((selectedItem: ExportCompanyI) => item.id === selectedItem.id);
      });
      dispatch(setTotalSelectedCompanies([...totalSelectedCompanies, ...unselectedCompanies]));
    }
  };

  const handleSelectCompany = (id: number) => {
    const alreadySelected = totalSelectedCompanies.filter((e: ExportCompanyI) => e.id === id);
    if (alreadySelected.length > 0) {
      dispatch(setTotalSelectedCompanies(totalSelectedCompanies.filter((e: ExportCompanyI) => e.id !== id)));
    } else {
      const selectedItem = content?.filter((e: ExportCompanyI) => e.id === id);
      dispatch(setTotalSelectedCompanies([...totalSelectedCompanies, selectedItem[0]]));
    }
  }
  const companyIsSelected = (id: number) => {
    return totalSelectedCompanies.filter((e: ExportCompanyI) => e.id === id).length > 0;
  }

  return (
    <BulkExportTableWrapper totalElements={totalElements}
                            numberOfElements={numberOfElements}
                            totalPages={totalPages}
                            pageRequest={pageRequest}
                            contentIsEmpty={!content?.length}>
      <TableHead sx={{ background: theme.palette.common.grey200 }}>
        <TableRow sx={{ height: theme.spacing(6.25) }}>
          <TableCell key={"check"}>
            <Box display="flex" alignItems="center">
              {content?.length ? (
                <StyledCheckbox
                  onClick={onSelectAll}
                  checked={isAllSelectedOnPage}
                />
              ) : null}
              <Typography
                variant="body2"
                color={theme.palette.common.grey800}
              >
                Company
              </Typography>
            </Box>
          </TableCell>
          {tableHead.map((title) => (
            <TableCell key={title}>
              <Typography
                variant="body2"
                color={theme.palette.common.grey800}
              >
                {title}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {content?.length ? (
          content.map((row: ExportCompanyI) => (
            <TableRow
              key={row.id}
              sx={{
                height: theme.spacing(7.5),
              }}
            >
              <TableCell width={'45%'}>
                <StyledCheckbox checked={companyIsSelected(row.id)} onClick={() => handleSelectCompany(row.id)}/>
                <Typography
                  variant="caption"
                  color={theme.palette.common.grey800}
                >
                  {row.companyName || "-"}
                </Typography>
              </TableCell>

              <TableCell width={'45%'}>
                <Typography
                  variant="caption"
                  color={theme.palette.common.grey800}
                >
                  {row.state ? `${row.state}` : ""}
                </Typography>
              </TableCell>
              <TableCell align='right' sx={{ width: '45px' }}>
                <Box
                  onClick={() => handleDeleteCompany(row.id)}
                  sx={{ cursor: "pointer" }}
                >
                  <BinIcon
                    color={theme.palette.common.grey600}
                    width="20"
                    height="20"
                  />
                </Box>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <></>
        )}
      </TableBody>
    </BulkExportTableWrapper>
  )
}

export default CompanyBulkExportTable;

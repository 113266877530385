import React from 'react';
import { useAppSelector } from 'application/store';
import { filterFormSelector } from 'common/companies/store';
import { FiltersCollapse } from 'application';
import { FiltersWrapper, FilterTextStyled } from './styles';

export const CompanyNameFilter = () => {
  const {name} = useAppSelector(filterFormSelector);
  return name ? (
    <FiltersCollapse label={'COMPANY NAME OR WEBSITE'} count={1}>
      <FiltersWrapper>
        <FilterTextStyled>
          {name}
        </FilterTextStyled>
      </FiltersWrapper>
    </FiltersCollapse>
  ) : null;
}
import React from "react";
import theme from "application/theme";
import {Skeleton} from "@mui/material";
import {Box} from "@mui/system";

export const ContactsTableSkeleton = () => {

    return (
        <Box display='flex' gap={2} position='relative'>
            <Box sx={{ width: '15%' }}>
                <SceletonContent />
                <SceletonContent />
                <SceletonContent />
            </Box>
            <Box sx={{ width: '25%' }}>
                <SceletonContent />
                <SceletonContent />
                <SceletonContent />
            </Box>
            <Box sx={{ width: '15%' }}>
                <SceletonContent />
                <SceletonContent />
                <SceletonContent />
            </Box>
            <Box sx={{ width: '45%' }}>
                <SceletonContent />
                <SceletonContent />
                <SceletonContent />
            </Box>
        </Box>
    );
}

const SceletonContent = () => {
    return <Skeleton sx={{ bgcolor: theme.palette.common.grey200 }} />
}
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Button, QuantityPicker } from "application";
import { Box, Typography } from "@mui/material";
import theme from "application/theme";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ContactPersonStyled } from "../styles";
import { ModalComponent } from "application/components/ModalComponent";
import { AddCredits } from "application/assets/AddCredits";
import { useAppDispatch, useAppSelector } from "application/store";
import { organizationsSelector } from "../store";
import { handleCreateCreditRequest } from "../store/actions";
import { formatDateToYYYYMMDD } from "application/utils";
import { sdrProfileSelector } from "common/sdrProfile/store";

const schema = yup.object().shape({
  phoneCredits: yup.number(),
  emailCredits: yup.number(),
});

export const AddCreditsForCompanyModal = ({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { organizationProfile } = useAppSelector(organizationsSelector);
  const { sdrProfile } = useAppSelector(sdrProfileSelector);
  const [emailCredits, setEmailCredits] = useState<number>(0);
  const [phoneCredits, setPhoneCredits] = useState<number>(0);
  const [companyDataCredits, setCompanyDataCredits] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);

  const sdrName = `${sdrProfile.firstName ? sdrProfile.firstName : ""} ${
    sdrProfile.lastName ? sdrProfile.lastName : ""
  }`;

  useEffect(() => {
    if (emailCredits || phoneCredits || companyDataCredits) setError(false);
  }, [emailCredits, phoneCredits, companyDataCredits]);

  const onSubmit = () => {
    if (!(emailCredits || phoneCredits || companyDataCredits)) {
      setError(true);
    } else {
      setError(false);
      handleClose();
      dispatch(
        handleCreateCreditRequest({
          name: sdrName,
          date: formatDateToYYYYMMDD(new Date()),
          phoneCredits,
          emailCredits,
          companyDataCredits,
          organizationId: organizationProfile.id,
        })
      );
    }
  };

  const { register } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleClose}
      padding={3}
      width={525}
    >
      <Box textAlign="center" display="flex" flexDirection="column" gap={2.5}>
        <Box display="flex" justifyContent="center">
          <AddCredits />
        </Box>
        <Box>
          <Typography variant="h5" color={theme.palette.common.grey800}>
            Add new credit exports
          </Typography>
          <Typography variant="h5" color={theme.palette.common.turquoise600}>
            for {organizationProfile.companyName}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" gap={theme.spacing(4)}>
          <QuantityPicker
            label={"Company credits"}
            register={register("companyDataCredits")}
            getValue={setCompanyDataCredits}
            defaultValue={0}
            max={999999}
            error={error}
          />
          {organizationProfile.organizationStructureAccessPermitted &&
            <>
              <QuantityPicker
                label={"Email credits"}
                register={register("emailCredits")}
                getValue={setEmailCredits}
                defaultValue={0}
                error={error}
              />
              <QuantityPicker
                label={"Phone credits"}
                register={register("phoneCredits")}
                getValue={setPhoneCredits}
                defaultValue={0}
                error={error}
              />
            </>
          }

        </Box>
        {error ? (
          <Typography variant="caption" color={theme.palette.common.red600}>
            Select at least 1 credit export
          </Typography>
        ) : (
          <></>
        )}
        <Typography variant="caption" color={theme.palette.common.grey700}>
          Then contact person:&nbsp;
          <ContactPersonStyled>
            {organizationProfile.contactPerson}
          </ContactPersonStyled>
          &nbsp;will get email notification about it.
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          gap={theme.spacing(2)}
          mt={0.5}
        >
          <Button
            label="Cancel"
            height={5.5}
            width={120}
            type="button"
            onClick={handleClose}
          />
          <Button
            label="Request"
            disabled={error}
            height={5.5}
            width={120}
            type="submit"
            onClick={onSubmit}
          />
        </Box>
      </Box>
    </ModalComponent>
  );
};

import React from 'react';
import TutorialIntro from './TutorialIntro';
import { companyInfoTutorialSteps } from '../steps';
import { tutorialType } from 'common/tutorial/store/types';
import { useTutorials } from 'common/tutorial/hooks/useTutorials';

export const CompanyInfoTutorial = () => {
  const {setCompanyInfoTutorialIsOpen} = useTutorials()
  const handleClickStartTutorial = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setCompanyInfoTutorialIsOpen(true);
  };
  return (
    <TutorialIntro
      handleStartTutorial={handleClickStartTutorial}
      steps={companyInfoTutorialSteps}
      title={"How to use company info"}
      type={tutorialType.COMPANY_INFO_TUTORIAL}
    />
  )
}
import { Box, styled } from '@mui/material';
import theme from 'application/theme';

export const ContentWrapper = styled(Box)`
  gap: ${theme.spacing(4)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LabelIconWrapper = styled(Box)`
  width: ${theme.spacing(10.25)};
  height: ${theme.spacing(10.25)};
  border-radius: 50%;
  background: ${theme.palette.common.blue50};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LabelContentWrapper = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing(2)};
`;

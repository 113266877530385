import {useMemo} from "react";
import { useAppDispatch, useAppSelector } from "application/store";
import {sdrProfileSelector} from "common/sdrProfile/store";
import {tutorialType} from "common/sdrProfile/store/types";

import { setCompanyInfoTutorialIsOpen, setCompanyFiltersTutorialIsOpen, tutorialToursSelector } from '../store';

export const useTutorials = () => {
    const dispatch = useAppDispatch();
    const {
        sdrProfile: { tutorials },
    } = useAppSelector(sdrProfileSelector);
    const tutorialTours = useAppSelector(tutorialToursSelector);

    const tutorialCompanyInfo = useMemo(() => {
            return tutorials ? tutorials.find(
                (tutorial) => tutorial.type === tutorialType.COMPANY_INFO_TUTORIAL && !tutorial.isViewed
            )
            : null;
    }, [tutorials]);

    const tutorialHowToFilter = useMemo(() => {
        return tutorials
            ? tutorials.find(
                (tutorial) => tutorial.type === tutorialType.FILTERS_TUTORIAL && !tutorial.isViewed
            )
            : null;
    },[tutorials]);

    const hasUnseenTutorials = useMemo(() => {
        return tutorials ? tutorials.some(tutorial=> !tutorial.isViewed) : false;
    },[tutorials]);

    const setFiltersTutorialIsOpen = (isOpen: boolean) => {
        dispatch(setCompanyFiltersTutorialIsOpen(isOpen));
    };

    const setInfoTutorialIsOpen = (isOpen: boolean) => {
        dispatch(setCompanyInfoTutorialIsOpen(isOpen));
    };


    return {
        hasUnseenTutorials,
        tutorialCompanyInfo,
        tutorialHowToFilter,
        setCompanyFiltersTutorialIsOpen: setFiltersTutorialIsOpen,
        setCompanyInfoTutorialIsOpen: setInfoTutorialIsOpen,
        ...tutorialTours,
    }
}
import React from "react";
import theme from "application/theme";

import {
  Box,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import { InfoIcon } from "application/assets/InfoIcon";

interface Props {
  title: string;
  children?: JSX.Element;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: theme.spacing(28),
    backgroundColor: theme.palette.common.grey800,
    padding: theme.spacing(0.5, 1),
    marginTop: `${theme.spacing(0.5)} !important`,
    marginLeft: theme.spacing(2.75),
    fontSize: theme.spacing(1.75),
    lineHeight: "normal",
    fontWeight: 400,
  },
});

const CustomTooltip = ({ title, children }: Props) => {
  return (
    <StyledTooltip title={title} placement="bottom">
      <Box display="flex" alignItems="center">
        {children ? children : <InfoIcon />}
      </Box>
    </StyledTooltip>
  );
};

export default CustomTooltip;

// @ts-ignore

import {Button} from "application";
import {PositionedButton} from "../organizationStructure/styles";
import React from "react";
import {LockIcon, LoadingIcon} from "application/assets";
import theme from "application/theme";

interface Props {
    isLoading: boolean;
    onClick: () => void;
}

export const UnlockContactsButton = ({isLoading, onClick}: Props) => {
    const handleClick = () => {
        if(!isLoading) {
            onClick();
        }

    }
    return (
        <PositionedButton>
            <Button
                label={'Unlock company data'}
                width='auto'
                type="submit"
                startAdornment={isLoading ? <LoadingIcon color={theme.palette.common.white}/> : <LockIcon/>}
                onClick={handleClick}
                rotate={isLoading}
            />
        </PositionedButton>
    )
}
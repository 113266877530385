import React, { useEffect } from "react";
import { Box } from "@mui/system";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import { companyColumnsList } from './companyColumnsList'
import { TabelWrapper } from "./styles";
import theme from "application/theme";
import TableActions from "./TableActions";
import { Typography } from "@mui/material";
import { Pagination } from "application";
import { useAppDispatch, useAppSelector } from "application/store";
import { permitsSelector } from "common/permits/store";
import { getServiceTypeLabel } from "common/companies/companiesFilter/utils";
import { getNumberFormatWithSeparators } from "application/utils";
import { usePermitFiltersContext } from "common/permits/hooks/usePermitFiltersContext";
import { TextAlignType } from "common/permits/types";
import { useCompaniesExtraInfo } from "common/companies/hooks/useCompaniesExtraInfo";
import { handleSearchCompaniesByPermitFilters } from "common/permits/store/actions";
import { showReputationScore } from "common/companies/companiesList/utils";
import { ReputationScoreStyled } from "common/companies/companiesListExtended/components/CompanyTableItem/styles";
import { useCompaniesAndPermitsExport } from '../../../../hooks/useCompaniesAndPermitsExport';

const gridStyle = {
  minHeight: 780,
  marginTop: 10,
  borderRadius: 8,
  overflow: "hidden",
};


export const CompanyTableView = () => {
  const {foundCompanies, selectedCompaniesColumns, searchInArea, zoomedArea} =
    useAppSelector(permitsSelector);
  const dispatch = useAppDispatch();

  const {permitFilterForm, companyFilterForm} = usePermitFiltersContext();
  const {getCompaniesExtraInfo, companiesExtraInfo} = useCompaniesExtraInfo();
  const {addCompanyToBulkExport} = useCompaniesAndPermitsExport();

  useEffect(() => {
    if (companiesExtraInfo?.length) {
      const addedToBulk = companiesExtraInfo.filter((e) => e.isInBulkExport).map((e) => e.companyId);
      setAddedToBulk(addedToBulk);
    }
  }, [companiesExtraInfo]);


  const [addedToBulk, setAddedToBulk] = React.useState<number[]>([]);

  const isAddedToBulk = (permitId: number) => {
    return addedToBulk.includes(permitId);
  }
  const handleAddToBulk = (companyId: number) => {
    addCompanyToBulkExport(companyId).then((result) => {
      if (result) {
        setAddedToBulk([...addedToBulk, companyId]);
      }
    });
  }

  const textAlign: TextAlignType = "start";
  const headerAlign: TextAlignType = "start";

  const columns = companyColumnsList.map((e) => {
    return {
      name: e.value,
      header: e.label,
      minWidth: 200,
      defaultFlex: 2,
      textAlign,
      headerAlign,
      textVerticalAlign: "top",
      headerProps: {
        style: {
          background: theme.palette.common.grey200,
          color: theme.palette.common.grey800,
        },
      },
      render: ({value}: any) => {
        switch (e.value) {
          case "id":
            return <TableActions id={value} key={value} isAddedToBulk={isAddedToBulk(value)}
                                 onAddToBulk={handleAddToBulk}/>;
          case "reputationScore":
            return (
              <ReputationScoreStyled
                score={value}
                variant="caption"
                color={theme.palette.common.grey800}
              >
                {value
                  ? `${showReputationScore(value)}: ${Math.floor(value / 10)}+`
                  : "-"}
              </ReputationScoreStyled>
            );
          case "generalContractorCategory":
            return (
              <Typography
                color={theme.palette.common.grey800}
                variant="caption"
              >
                {value ? value : "Subcontractor"}
              </Typography>
            );
          case "companyContactInfo":
            return (
              <Typography
                color={theme.palette.common.grey800}
                variant="caption"
              >
                {value.website === null ? "-" : value.website}
              </Typography>
            );
          case "serviceType":
            return (
              <Typography
                color={theme.palette.common.grey800}
                variant="caption"
              >
                {getServiceTypeLabel(value)}
              </Typography>
            );
          case "permit":
            return (
              <Typography
                color={theme.palette.common.grey800}
                variant="caption"
              >
                {value ? "Yes" : "No"}
              </Typography>
            );
          case "avgAnnualValue":
            return (
              <Typography
                color={theme.palette.common.grey800}
                variant="caption"
              >
                {value ? `$${getNumberFormatWithSeparators(value)}` : "-"}
              </Typography>
            );
          default:
            return (
              <Typography
                color={theme.palette.common.grey800}
                variant="caption"
              >
                {value ? value : "-"}
              </Typography>
            );
        }
      },
    };
  });

  const permitFilter = permitFilterForm.watch();
  const companyFilter = companyFilterForm.watch();

  useEffect(() => {
    const companyIds = foundCompanies?.content.map((e) => e.id);
    if (companyIds.length) getCompaniesExtraInfo(companyIds);
  }, [foundCompanies]);

  const handlePageRequest = (page: number) => {
    dispatch(
      handleSearchCompaniesByPermitFilters(
        {
          permitFilter,
          companyFilter,
        },
        page,
        20,
        false,
        searchInArea ? zoomedArea : undefined,
        false
      )
    );
  }

  const renderPaginationToolbar = () => {
    return (
      <Box sx={{borderTop: `1px solid ${theme.palette.common.grey200}`}}>
        <Pagination
          totalElements={foundCompanies.totalElements}
          numberOfElements={foundCompanies.numberOfElements}
          totalPages={foundCompanies.totalPages}
          hidePageSelector={true}
          pageSize={foundCompanies.size}
          pageRequest={handlePageRequest}
          currentPage={foundCompanies.number}
        />
      </Box>
    );
  };

  const filteredColumns = columns.filter((e) =>
    selectedCompaniesColumns.includes(e.name) || e.name === "id" // action column (id) should always be visible
  );

  return (
    <TabelWrapper>
      <ReactDataGrid
        idProperty="id"
        dataSource={foundCompanies.content}
        style={gridStyle}
        columns={filteredColumns}
        pagination={true}
        defaultLimit={100}
        showColumnMenuTool={false}
        rowHeight={56}
        headerHeight={44}
        showCellBorders="horizontal"
        showZebraRows={false}
        showActiveRowIndicator={false}
        nativeScroll={true}
        renderPaginationToolbar={renderPaginationToolbar}
      />
    </TabelWrapper>
  );
};

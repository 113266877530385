import React, { useState } from "react";
import theme from "application/theme";
import { Button } from "application";
import { useAppDispatch } from "application/store";

import { Box } from "@mui/system";
import { Typography } from "@mui/material";

import {
  LabelIconWrapper,
  LabelContentWrapper,
  ContentWrapper,
} from "./styles";

import { InputStyled } from "application/components/FormInput/styles";
import { ModalComponent } from "application/components/ModalComponent";
import { StarIcon } from 'application/assets';
import { useSavedFilters } from 'common/permits/hooks/useSavedFilters';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const SaveFiltersModal = ({ isOpen, onClose }: Props) => {
  const dispatch = useAppDispatch();
  const [filterName, setFilterName] = useState("");
  const {saveFilters, getSavedFilters} = useSavedFilters();
  const handleChangeName = (event: any) => {
    setFilterName(event.target.value);
  };

  const handleSaveFilter = async () => {
    const result = await saveFilters(filterName);
    if (result) {
      getSavedFilters();
      setFilterName("");
      onClose();
    }
  };

  const handleClose = () => {
    onClose();
    setFilterName("");
  };

  return (
    <ModalComponent isOpen={isOpen} handleClose={handleClose} width={"30%"}>
      <ContentWrapper>
        <LabelContentWrapper>
          <LabelIconWrapper>
            <StarIcon />
          </LabelIconWrapper>
          <Typography variant="subtitle1" color={theme.palette.common.grey700}>
            Save search
          </Typography>
          <Box width={"100%"}>
            <Typography
              variant="caption"
              color={theme.palette.common.grey800}
              sx={{ display: "block" }}
              mb={0.75}
            >
              Add name to this search
            </Typography>
            <InputStyled
              sx={{ width: "100%" }}
              name="searchName"
              placeholder="Search"
              onChange={handleChangeName}
            />
          </Box>
        </LabelContentWrapper>
        <Box display={"flex"} justifyContent={"space-between"} gap={3}>
          <Button
            label="No"
            height={5.5}
            width={theme.spacing(15)}
            type="button"
            onClick={handleClose}
          />
          <Button
            disabled={filterName.length === 0}
            label="Save"
            height={5.5}
            width={theme.spacing(15)}
            type="submit"
            onClick={handleSaveFilter}
          />
        </Box>
      </ContentWrapper>
    </ModalComponent>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import { getTimezones } from "./api";
import { prepareTimezones } from "./utils";
import { TimezoneT } from "./types";
import { VoidThunk, ThunkDispatchT } from "application/types";
import { RootState } from "application/store";

export const initialState = {
  timezone: [],
};

export const dictionarySlice = createSlice({
  name: "dictionary",
  initialState,
  reducers: {
    setTimezone: (state, { payload }) => {
      state.timezone = payload;
    },
    cleanDictionaryStore: (state) => {
      state.timezone = initialState.timezone;
    },
  },
});

export const { setTimezone } = dictionarySlice.actions;
export const { cleanDictionaryStore } = dictionarySlice.actions;

export const timezoneSelector = (state: RootState) => state.dictionary.timezone;

export const handleGetTimezoneItems = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    getTimezones()
      .then((response: { zones: TimezoneT }) => {
        const preparedTimezoneOptions = prepareTimezones(response.zones);
        dispatch(setTimezone(preparedTimezoneOptions));
      })
      .catch((error: any) => {
        console.error(error);
      });
  };
};

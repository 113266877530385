import React, { useMemo } from "react";
import { ExportedEmailIcon } from "../assets/ExportedEmailIcon";
import { ExportedPhoneIcon } from "../assets/ExportedPhoneIcon";
import { EmailIcon } from "application/assets/EmailIcon";
import { PhoneIcon } from "application/assets/PhoneIcon";
import { IconButtonStyled, ButtonsWrapper } from "../styles";
import theme from "application/theme";
import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import { Box } from "@mui/system";
import { useEmployeeBulkExport } from '../hooks/useEmployeeBulkExport';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    backgroundColor: theme.palette.common.grey800,
    padding: theme.spacing(0.5, 1),
    marginTop: `-4px !important`,
    marginLeft: theme.spacing(2.75),
    fontSize: theme.spacing(1.75),
    lineHeight: "normal",
    fontWeight: 400,
  },
});

interface ExportTypeSelectProps {
  exportedEmail: boolean;
  exportedPhone: boolean;
  id: number;
  companyId: string | null;
}
const ExportTypeSelect = ({
  exportedEmail,
  exportedPhone,
  id,
  companyId
}: ExportTypeSelectProps) => {
  const {
    addEmployeeToSelected,
    isPhoneSelected,
    isEmailSelected,
  } = useEmployeeBulkExport();

  const phoneSelected = useMemo(() => isPhoneSelected(id),
    [isPhoneSelected]);
  const emailSelected = useMemo(() => isEmailSelected(id),
    [isEmailSelected]);

  const onPhoneSelect = () => {
    addEmployeeToSelected(id, companyId,!phoneSelected, emailSelected);
  };

  const onEmailSelect = () => {
    addEmployeeToSelected(id, companyId, phoneSelected, !emailSelected);
  };

  return (
    <Box>
      <ButtonsWrapper>
        <StyledTooltip
          title={
            exportedEmail ? "Item already exported" : "Available to export"
          }
          placement="bottom-start"
        >
          <IconButtonStyled
            sx={{
              borderRight: `1px solid ${theme.palette.common.grey400}`,
              borderRadius: theme.spacing(1, 0, 0, 1),
            }}
            onClick={onEmailSelect}
          >
            {exportedEmail ? (
              <ExportedEmailIcon
                color={
                  emailSelected
                    ? theme.palette.common.blue700
                    : theme.palette.common.grey800
                }
              />
            ) : (
              <EmailIcon
                color={
                  emailSelected
                    ? theme.palette.common.blue700
                    : theme.palette.common.grey800
                }
              />
            )}
          </IconButtonStyled>
        </StyledTooltip>
        <StyledTooltip
          title={
            exportedPhone ? "Item already exported" : "Available to export"
          }
          placement="bottom-start"
        >
          <IconButtonStyled
            sx={{ borderRadius: theme.spacing(0, 1, 1, 0) }}
            onClick={onPhoneSelect}
          >
            {exportedPhone ? (
              <ExportedPhoneIcon
                color={
                  phoneSelected
                    ? theme.palette.common.blue700
                    : theme.palette.common.grey800
                }
              />
            ) : (
              <PhoneIcon
                color={
                  phoneSelected
                    ? theme.palette.common.blue700
                    : theme.palette.common.grey800
                }
              />
            )}
          </IconButtonStyled>
        </StyledTooltip>
      </ButtonsWrapper>
    </Box>
  );
};

export default ExportTypeSelect;

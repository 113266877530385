export interface OrganizationTeamMemberI {
  firstName: string;
  lastName: string;
  email: string;
}

export interface EditSdrProfileI {
  firstName: string;
  lastName: string;
  emailNotificationEnabled: boolean;
}

export enum tutorialType {
  COMPANY_INFO_TUTORIAL = "COMPANY_INFO_TUTORIAL",
  FILTERS_TUTORIAL = "FILTERS_TUTORIAL",
}
export interface TutorialsI {
  id: number;
  isViewed: boolean;
  type: tutorialType;
}

export interface SdrProfileI {
  organizationName: string;
  organizationId: number;
  firstName: string | null;
  lastName: string | null;
  email: string;
  emailNotificationEnabled: boolean;
  phoneCredits: number;
  emailCredits: number;
  usedPhoneCredits: number;
  usedEmailCredits: number;
  companyDataCredits: number;
  usedCompanyDataCredits: number;
  team: OrganizationTeamMemberI[];
  userRoles: UserRoles[];
  id: string | number;
  activatedByEmail: string | null;
  tutorials: TutorialsI[];
  organizationStructureAccessPermitted: boolean;
}

export enum UserRoles {
  sdr = "SDR",
  orgManager = "ORG_MANAGER",
}

import { CompanyLocationsI } from "integration/api/companies/types";

export const showReputationScore = (param: number) => {
  if (param >= 90) return "Highest";
  if (param >= 80) return "High";
  if (param >= 70) return "Medium";
  if (param >= 60) return "Lower";
  if (param < 60) return "Lower";
  return;
};

export const labelByType = (serviceType: string | undefined) => {
  switch (serviceType?.toUpperCase()) {
    case "COMMERCIAL":
      return "Commercial";
    case "RESIDENTIAL":
      return "Residential";
    case "COMMERCIAL_AND_RESIDENTIAL":
      return "Commercial and residential";
    default:
      return "Unable to classify";
  }
};

export const formatAddress = (location: CompanyLocationsI) => {
  const { address, city, state, zipCode } = location;
  const parts = [];

  if (address) {
    parts.push(address);
  }

  if (city) {
    parts.push(city);
  }

  if (state) {
    parts.push(state);
  }

  if (zipCode) {
    parts.push(zipCode);
  }

  return parts.length > 0 ? parts.join(", ") : "";
};

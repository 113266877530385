export enum Errors {
  organizationNameRequired = 'Organization name is required',
  contactFirstNameRequired = 'First name is required',
  contactLastNameRequired = 'Last name is required',
  contactPhoneRequired = "Phone number is required",
  contactPhoneFormat = "Invalid phone number format",
  organizationWebsiteRequired = "Website is required",
  timezoneRequired = "Timezone is required",
  emailRequired = "Email is required",
  emailFormat = 'Invalid email format',
}

import { Box, styled } from '@mui/system';
import theme from 'application/theme';

export const CompanyInfoModalWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100vh;
  position: absolute;
  right: 0;
  background: ${theme.palette.common.white};
`

export const CloseButtonStyled = styled(Box)`
  position: absolute;
  margin-left: ${theme.spacing(-4.5)};
  background-color: white;
  line-height: 1rem;
  padding: ${theme.spacing(1)};
  cursor: pointer;
`

import React, { useEffect, useMemo, useRef, useState } from "react";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { Checkbox } from "@mui/material";
import { Box } from "@mui/system";
import theme from "application/theme";
import {
  CheckboxLabel,
  CheckboxLabelWithOverflow,
  CheckItemWrapper,
  LoaderWrapper,
} from "../styles";
import { ShowLessAndMore } from "common/companies/components";
import { SmallLoader } from "common/loader/SmallLoader";
import { ProductBrandMultiselect } from '../ProductBrandMultiselect';
import { handleErrorNotification } from 'common/errorService';
import { ProductBrandI, ProductNameI, ProductTypeI } from 'common/permits/types';
import { cloneDeep } from 'lodash';
import { CollapseFilter } from '../../CollapseFilter';
import { PageResponseI } from 'application/types';

interface ProductTypeMultiselectProps {
  checked: boolean;
  productType: ProductTypeI;
  onChangeSelected: (selectedOptions: any) => void;
  fetchProductBrandsCallback: (typeId: number) => Promise<PageResponseI<ProductBrandI>>;
  fetchProductNamesCallback: (typeId: number, brandId: number) => Promise<PageResponseI<ProductNameI>>;
  field?: ControllerRenderProps<any, any>;
}

export const ProductTypeMultiselect = ({
                                         productType,
                                         checked,
                                         field,
                                         onChangeSelected,
                                         fetchProductBrandsCallback,
                                         fetchProductNamesCallback
                                       }: ProductTypeMultiselectProps) => {
  const [loading, setLoading] = useState(false);
  const [countToDisplay, setCountToDisplay] = useState(5);

  const selectedBrands = useMemo(() => {
    return productType.brands || [];
  }, [productType.brands]);
  const [productBrandsOptions, setProductBrandsOptions] = useState<any[]>([])
  const optionsEndRef = useRef<HTMLDivElement | null>(null);

  const fetchOptions = async () => {
    setLoading(true);
    try {
      const response = await fetchProductBrandsCallback(productType.id);
      setProductBrandsOptions(response.content);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (checked) {
      fetchOptions();
    }
  }, [checked]);

  const handleCheckProductBrand = async () => {
    if (checked) {
      onChangeSelected(null);
    } else {
      onChangeSelected(productType)
    }
  };
  const handleSelectBrand = (brandId: number, selectedBrand: ProductBrandI) => {
    let newSelectedBrands = selectedBrands.filter((brand: ProductBrandI) => brand.id !== brandId);
    if (selectedBrand) {
      newSelectedBrands = cloneDeep([...newSelectedBrands, selectedBrand]);
    }
    onChangeSelected({
      ...productType,
      brands: newSelectedBrands
    })
  }

  const productBrandsToDisplay = useMemo(
    () => (productBrandsOptions?.length ? productBrandsOptions.slice(0, countToDisplay) : []),
    [productBrandsOptions, countToDisplay]
  );
  const handleShowMore = (showMore: boolean) => {
    if (showMore) {
      setCountToDisplay((prev) => prev + 10);
    } else {
      setCountToDisplay(5);
    }
  };

  const hasMore = useMemo(
    () => productBrandsOptions?.length && productBrandsOptions?.length >= countToDisplay,
    [productBrandsOptions, countToDisplay]
  );

  useEffect(() => {
    if (optionsEndRef.current && countToDisplay > 5) {
      optionsEndRef.current.scrollIntoView({behavior: "smooth"});
    }
  }, [countToDisplay]);

  const brandIsSelected = (brandId: number) => {
    if (!selectedBrands) {
      return false;
    }
    return selectedBrands.some((selectedBrand: ProductBrandI) => selectedBrand.id === brandId);
  }
  const getProductBrandFromSelected = (productBrand: ProductBrandI) => {
    const selectedBrand = selectedBrands.find((e) => e.id === productBrand.id);
    return selectedBrand || productBrand;
  }

  return (
    <>
      <CheckItemWrapper>
        <Checkbox  {...field} checked={checked} onChange={handleCheckProductBrand}/>
        <CheckboxLabel>
          <Box display="flex" gap={0.5} alignItems="center">
            <CheckboxLabelWithOverflow
              variant="caption"
              color={theme.palette.common.grey900}
            >
              {productType.name}
            </CheckboxLabelWithOverflow>
          </Box>
        </CheckboxLabel>
      </CheckItemWrapper>
      {loading ? (
        <LoaderWrapper p={1}>
          <SmallLoader size={60} width={12.5}/>
        </LoaderWrapper>
      ) : checked && productBrandsOptions?.length ? (
        <Box pl={4.5} sx={{maxWidth: "calc(100% - 8px)"}} mt={0.5}>
          <CollapseFilter
            label={"Product brand"}
            count={selectedBrands?.length}
            opened={true}
            small={true}
          >
            <Box mt={1}>
              <Box>
                {
                  productBrandsToDisplay.map(brand => (
                    <ProductBrandMultiselect
                      key={`${productType.id}-${brand.id}`}
                      typeId={productType.id}
                      brand={getProductBrandFromSelected(brand)}
                      checked={brandIsSelected(brand.id)}
                      onChangeSelected={(selectedBrand) => handleSelectBrand(brand.id, selectedBrand)}
                      fetchProductNamesCallback={fetchProductNamesCallback}
                    />
                  ))
                }
              </Box>
              {productBrandsOptions?.length > 5 ? (
                <ShowLessAndMore
                  showAll={!hasMore}
                  setShowAll={handleShowMore}
                  fontSize={theme.spacing(1.75)}
                />
              ) : null}
            </Box>
          </CollapseFilter>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

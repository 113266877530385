import React from 'react';
import { useAppSelector } from 'application/store';
import { FiltersCollapse } from 'application';
import { FilterGroupWrapper, FiltersWrapper, FilterTitleStyled } from '../components/styles';
import { CheckedFilterItem } from '../components/CheckedFilterItem';
import { companyFiltersSelector } from 'common/permits/store';
import { getArrayLength } from 'application/utils/arrayUtils';


export const CompanyTypeFilter = () => {
  const {
    isGeneralContractor, isSubContractor,
    subContractorCategories
  } = useAppSelector(companyFiltersSelector);
  const filtersCount = (isGeneralContractor ? 1 : 0) + (isSubContractor ? 1 : 0) + getArrayLength(subContractorCategories);
  return isGeneralContractor || isSubContractor ? (
    <FiltersCollapse label={'COMPANY TYPE'} count={filtersCount}>
      <FiltersWrapper>
        {isGeneralContractor && <CheckedFilterItem label={'General Contractor'}/>}
        {isSubContractor && <FilterGroupWrapper>
          <FilterTitleStyled>Subcontractor</FilterTitleStyled>
          {subContractorCategories.map((category) => (
            <CheckedFilterItem
              key={category}
              label={category}/>
          ))}
        </FilterGroupWrapper>}
      </FiltersWrapper>
    </FiltersCollapse>
  ) : null;
}

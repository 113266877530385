import React from "react";
import { useFormContext } from "react-hook-form";
import { FormGroup } from "@mui/material";
import { FiltersCollapse } from 'application';
import { AnnualRevenue } from "./const";
import { CheckboxGroupStyled } from "./styles";
import { MultipleCheckboxController } from "./components";
import { getAnnualRevenueLabel } from './utils';

const AnnualRevenueCollapse = ({ label }: { label: string }) => {
  const { watch } = useFormContext();
  const { annualRevenueRange } = watch();

  const ScoreOptions = [
    { value: AnnualRevenue.FROM_0_TO_1M, label: getAnnualRevenueLabel(AnnualRevenue.FROM_0_TO_1M) },
    { value: AnnualRevenue.FROM_1M_TO_20M, label: getAnnualRevenueLabel(AnnualRevenue.FROM_1M_TO_20M) },
    { value: AnnualRevenue.FROM_20M_TO_50M, label: getAnnualRevenueLabel(AnnualRevenue.FROM_20M_TO_50M) },
    { value: AnnualRevenue.FROM_50M_TO_100M, label: getAnnualRevenueLabel(AnnualRevenue.FROM_50M_TO_100M) },
    { value: AnnualRevenue.FROM_100M_TO_250M, label: getAnnualRevenueLabel(AnnualRevenue.FROM_100M_TO_250M) },
    { value: AnnualRevenue.FROM_250M_TO_500M, label: getAnnualRevenueLabel(AnnualRevenue.FROM_250M_TO_500M) },
    { value: AnnualRevenue.FROM_500M_TO_1B, label: getAnnualRevenueLabel(AnnualRevenue.FROM_500M_TO_1B) },
    { value: AnnualRevenue.FROM_1B, label: getAnnualRevenueLabel(AnnualRevenue.FROM_1B) },
  ];
  return (
    <FiltersCollapse label={label} count={annualRevenueRange?.length}>
      <CheckboxGroupStyled pt={1.5}>
        <FormGroup>
          {ScoreOptions.map((item: any) => (
            <MultipleCheckboxController
              fieldName={"annualRevenueRange"}
              value={item.value}
              label={item.label}
              key={item.value}
            />
          ))}
        </FormGroup>
      </CheckboxGroupStyled>
    </FiltersCollapse>
  )
};

export default AnnualRevenueCollapse;

import { Box } from "@mui/system";
import React from "react";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import theme from "application/theme";
import { Typography } from "@mui/material";

const StyledLoader = ({ size, width }: { size: number; width?: number }) => {
  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Box position="relative" width={size} height={size}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: theme.palette.common.blue100,
          }}
          size={size}
          thickness={4}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: theme.palette.common.blue700,
            animationDuration: "550ms",
            position: "absolute",
            left: 0,
            bottom: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          size={size}
          thickness={4}
        />
      </Box>
      <Typography
        variant="subtitle1"
        color={theme.palette.common.grey800}
        mt={3}
        sx={{ width: width, textAlign: "center" }}
      >
        Loading...
      </Typography>
    </Box>
  );
};

export default StyledLoader;

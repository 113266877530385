import { CompanyFilterDataI, FilterSavedDataI } from "integration/api/companies/types";

export const prepareFilterToSave = (filterData: FilterSavedDataI) => {
  const { filterValue, ...rest } = filterData;
  const byState = filterValue?.location === "BY_STATE";
  return {
    ...rest,
    filterValue: {
      companyId: filterValue.companyId ?? null,
      name: filterValue.name || null,
      companySizeRange: filterValue.companySizeRange || [],
      annualRevenueRange: filterValue.annualRevenueRange || [],
      yearsInBusinessRange: filterValue.yearsInBusinessRange || [],
      reputationScore: filterValue.reputationScore || null,
      permit: filterValue.permit || null,
      serviceType: filterValue.serviceType || [],
      tags: filterValue.tags || [],
      isSubContractor: filterValue.isSubContractor || null,
      isGeneralContractor: filterValue.isGeneralContractor || null,
      skipExported: filterValue.skipExported || null,
      subContractorCategories: filterValue.subContractorCategories || [],
      hasPermit: filterValue.hasPermit || null,
      notGoodFit: filterValue.notGoodFit || null,
      permitFilters: filterValue.hasPermit ? filterValue.permitFilters : undefined,
      citiesInclude: (!byState && filterValue.citiesInclude) || [],
      citiesExclude: (!byState && filterValue.citiesExclude) || [],
      statesInclude: (byState && filterValue.statesInclude) || [],
      statesExclude: (byState && filterValue.statesExclude) || [],
      location: filterValue.location || null,
      productTypes: filterValue.productTypes || [],
    },
  };
};

export const prepareToSearch = (filterData: CompanyFilterDataI) => {
  const byState = filterData?.location === "BY_STATE";
  return {
    companyId: filterData.companyId ?? null,
    name: filterData.name || null,
    companySizeRange: filterData.companySizeRange || [],
    annualRevenueRange: filterData.annualRevenueRange || [],
    yearsInBusinessRange: filterData.yearsInBusinessRange || [],
    reputationScore: filterData.reputationScore || null,
    permit: filterData.permit || null,
    serviceType: filterData.serviceType || [],
    tags: filterData.tags || [],
    isSubContractor: filterData.isSubContractor || null,
    isGeneralContractor: filterData.isGeneralContractor || null,
    skipExported: filterData.skipExported || null,
    subContractorCategories: filterData.subContractorCategories || [],
    hasPermit: filterData.hasPermit || null,
    notGoodFit: filterData.notGoodFit || null,
    permitFilters: filterData.hasPermit ? filterData.permitFilters : undefined,
    citiesInclude: (!byState && filterData.citiesInclude) || [],
    citiesExclude: (!byState && filterData.citiesExclude) || [],
    statesInclude: (byState && filterData.statesInclude) || [],
    statesExclude: (byState && filterData.statesExclude) || [],
    location: filterData.location || null,
    zoomedArea: filterData.zoomedArea,
    productTypes: filterData.productTypes || [],
  };
};


export const prepareFilterToRender = (filterData: FilterSavedDataI) => {
  const { filterValue, ...rest } = filterData;

  const citiesIncludeNotEmpty = filterValue.citiesInclude && filterValue.citiesInclude.length > 0;
  const citiesExcludeNotEmpty = filterValue.citiesExclude && filterValue.citiesExclude.length > 0;
  const statesIncludeNotEmpty = filterValue.statesInclude && filterValue.statesInclude.length > 0;
  const statesExcludeNotEmpty = filterValue.statesExclude && filterValue.statesExclude.length > 0;

  return {
    ...rest,
    filterValue: {
      companyId: filterValue.companyId ?? null,
      name: filterValue.name || "",
      companySizeRange: filterValue.companySizeRange || "",
      annualRevenueRange: filterValue.annualRevenueRange || "",
      yearsInBusinessRange: filterValue.yearsInBusinessRange || "",
      reputationScore: filterValue.reputationScore || "",
      permit: filterValue.permit || false,
      permitFilters: filterValue.hasPermit ? filterValue.permitFilters : undefined,
      serviceType: filterValue.serviceType || [],
      tags: filterValue.tags || [],
      isSubContractor: filterValue.isSubContractor || false,
      isGeneralContractor: filterValue.isGeneralContractor || false,
      skipExported: filterValue.skipExported || false,
      subContractorCategories: filterValue.subContractorCategories || [],
      hasPermit: filterValue.hasPermit || false,
      citiesInclude: filterValue.citiesInclude || [],
      citiesExclude: filterValue.citiesExclude || [],
      statesInclude: filterValue.statesInclude || [],
      statesExclude: filterValue.statesExclude || [],
      productTypes: filterValue.productTypes || [],
      notGoodFit: filterValue.notGoodFit || false,
      location:
        citiesIncludeNotEmpty || citiesExcludeNotEmpty
          ? "BY_CITY"
          : statesIncludeNotEmpty || statesExcludeNotEmpty
          ? "BY_STATE"
          : filterValue.location || "",
    },
  };
};

export const mapPermitRangeValuesResponse = (permitRangeValues: any) => {
  const permitCount = permitRangeValues?.filter((item: any) => item?.metricType === "ANNUAL_PERMIT_COUNT")?.[0];
  const permitValue = permitRangeValues?.filter((item: any) => item?.metricType === "ANNUAL_PERMIT_VALUE")?.[0];
  const result =  {
    annualPermitCount: {
      min: permitCount?.minValue,
      max: permitCount?.maxValue
    },
    annualPermitValue: {
      min: permitValue?.minValue,
      max: permitValue?.maxValue
    }
  }
  return result;
}

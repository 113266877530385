import React, { useEffect } from "react";
import { useSdrProfile } from 'common/sdrProfile/hooks/useSdrProfile';
import useIsMountedRef from 'application/hooks/useIsMountedRef';
import { DrawerStyled, PageContentWrapper, PageWrapper } from './styles';
import { PageContent, PermitFilters, SearchBar } from 'common/permits/components';
import theme from 'application/theme';
import { PermitFiltersContextProvider } from 'common/permits/contexts/PermitFiltersContext';
import { handleGetSavedFilters } from 'common/permits/store/actions';
import { useAppDispatch } from 'application/store';

export const PermitsPage = () => {
  const filtersDrawerWidth = theme.spacing(36);
  const {sdrProfile, getSdrProfileInfo} = useSdrProfile();
  const isMounted = useIsMountedRef();
  const [sidebarOpened, setSidebarOpened] = React.useState(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isMounted.current) {
      getSdrProfileInfo();
      dispatch(handleGetSavedFilters());
    }
  }, []);

  return (
    <PageWrapper>
      <PermitFiltersContextProvider>
        <DrawerStyled
          sidebarwidth={filtersDrawerWidth}
          variant="persistent"
          anchor="left"
          open={sidebarOpened}
        >
          <PermitFilters onCloseSidebar={() => setSidebarOpened(false)}/>
        </DrawerStyled>
        <PageContentWrapper sidebaropened={sidebarOpened} sidebarwidth={filtersDrawerWidth}>
          <SearchBar displayFilters={sidebarOpened}
                     onShowFilters={() => setSidebarOpened(true)}/>
          <PageContent/>
        </PageContentWrapper>
      </PermitFiltersContextProvider>
    </PageWrapper>
  );
};


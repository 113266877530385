import React, { useMemo } from "react";
import DropdownWithMultiselect from 'application/components/DropdownWithMultiselect';
import { PermitAffiliationI, PermitAffiliationType, PermitsDisplayMode } from 'common/permits/types';
import { usePermitsViewSettings } from 'common/permits/hooks/usePermitsViewSettings';
import { usePermitFiltersContext } from 'common/permits/hooks/usePermitFiltersContext';
import { cloneDeep } from 'lodash';
import { usePermitsWithCompaniesSearch } from 'common/permits/hooks/usePermitsWithCompaniesSearch';

const mapViewOptions = [{
  label: "Companies",
  value: PermitsDisplayMode.COMPANIES,
}, {
  label: "Permits",
  value: PermitsDisplayMode.PERMITS,
}]
const allExecutorTypes = [
  {
    label: "Owner",
    value: PermitAffiliationType.OWNER,
  },
  {
    label: "Designer",
    value: PermitAffiliationType.DESIGNER,
  },
  {
    label: "Architect",
    value: PermitAffiliationType.ARCHITECT,
  },
  {
    label: "Engineer",
    value: PermitAffiliationType.ENGINEER,
  },
  {
    label: "Contractor",
    value: PermitAffiliationType.CONTRACTOR_COMPANY,
  }
]
export const MapSettings = () => {
  const {mapViewModes,
    changeMapViewModes,
    executorTypes,
    changeExecutorTypes
  } = usePermitsViewSettings();
  const handleChangeMapViewModes = (value: any[]) => {
    changeMapViewModes(value);
  };

  const {permitFilterForm: { watch}, companyFilterForm } = usePermitFiltersContext();
  const {searchForCompaniesAndPermitsCoordinates} = usePermitsWithCompaniesSearch();

  const executorTypesOptions = useMemo(() => {
    const appliedExecutors = watch('affiliations');
    if (!appliedExecutors || appliedExecutors.length === 0) return allExecutorTypes;
    const executorsWithNoTypes = appliedExecutors.filter((executor: PermitAffiliationI) => executor.types.length === 0);
    if (executorsWithNoTypes.length > 0) return allExecutorTypes;
    const typesFromFilters = new Set(appliedExecutors.flatMap((executor: PermitAffiliationI) => executor.types))
    return allExecutorTypes.filter((type) => typesFromFilters.has(type.value))
  },[watch('affiliations')]);

  const handleExecutorTypesChange = (newValues: any[]) => {
    newValues = executorTypesOptions.map(type => type.value)
      .filter((value) => newValues.includes(value));
    changeExecutorTypes(newValues);
    applySearchWithNewExecutorTypes(newValues);
  }
  const applySearchWithNewExecutorTypes = (executorTypes: any[]) => {
    const affiliations = watch("affiliations");
    let newAffiliations = cloneDeep(affiliations);
    if(!affiliations || affiliations.length === 0) {
      newAffiliations = [{name: '', types: executorTypes}];
    }
    else {
      newAffiliations.forEach((affiliation: PermitAffiliationI) => {
        affiliation.types = affiliation.types.filter((type: any) => executorTypes.includes(type));
      });
    }
    const permitFilters = watch();
    const companyFilters = companyFilterForm.watch();
    searchForCompaniesAndPermitsCoordinates({
      permitFilter: {
        ...permitFilters,
        affiliations: newAffiliations
      },
      companyFilter: companyFilters,
    })

  }

  return (
    <>
      <DropdownWithMultiselect
        options={mapViewOptions}
        selectedOptions={mapViewModes}
        onChangeSelected={(value) => handleChangeMapViewModes(value)}
        label={"Companies / Permits"}
        buttonWidth={"auto"}
      />
      <DropdownWithMultiselect
        options={executorTypesOptions}
        selectedOptions={executorTypes}
        onChangeSelected={handleExecutorTypesChange}
        label={"Company types"}
        buttonWidth={"auto"}
      />
    </>
  );
};

import { Box, styled, Typography } from '@mui/material';
import theme from 'application/theme';

export const FiltersWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1.5)};
  padding: ${theme.spacing(1.5,2)};
  width: 100%;
  border-bottom: 1px solid ${theme.palette.common.grey300};
`;

export const FilterGroupWrapper = styled(Box)`
display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
  width: 100%;
`;

export const FilterTitleStyled = styled(Typography)`
  font-size: ${theme.spacing(1.5)};
  color: ${theme.palette.common.grey700};
  text-transform: uppercase;
  line-height: 140%;
  letter-spacing: 0.6px;
`;

export const FilterTextStyled = styled(Typography)`
  font-size: ${theme.spacing(1.75)};
  color: ${theme.palette.common.grey900};
  line-height: 140%;
`;

import React from 'react'
import { PageContentWrapper } from './styles';
import { ResultsBar } from './ResultsBar';
import { SettingsBar } from './SettingsBar';
import { Views } from './Views';
import { LargeBulkExportNew } from '../largeBulkExport';
import { ViewDetailsContextProvider } from '../../contexts/ViewDetailsContext';
import { NotifyReviewBulkExports } from 'common/companies/modal/NotifyReviewBulkExports';
import { ExportResultModal } from 'common/companies/modal/ExportResultModal';
import { useAppSelector } from 'application/store';
import { resultExportSelector } from 'common/companies/store';
export const PageContent = () => {
  const isExportResultModalOpen = useAppSelector(resultExportSelector);
  return <PageContentWrapper>
    <LargeBulkExportNew/>
    <ResultsBar/>
    <SettingsBar/>
    <ViewDetailsContextProvider>
      <Views/>
    </ViewDetailsContextProvider>
    <NotifyReviewBulkExports/>
    {isExportResultModalOpen ? <ExportResultModal /> : <></>}
  </PageContentWrapper>
}

import { useAppDispatch, useAppSelector } from 'application/store';
import { setExportStage, setNumberOfExportedCompanies } from '../store';
import { ExportStage } from '../store/types';
import { useSdrProfile } from 'common/sdrProfile/hooks/useSdrProfile';
import { useCompaniesSearch } from 'common/companies/hooks/useCompaniesSearch';
import {
  handleExportFilteredCompanies,
  handleGetNumberOfExportedCompanies,
  handleReexportCompanyBulkExportProcess, handleReexportPermitBulkExportProcess
} from '../store/actions';
import {
  companySortSelector,
  filteredCompaniesSelector,
  filterFormSelector,
  setFilterFormData
} from 'common/companies/store';
import { handleSearchCompanies } from 'common/companies/store/actions';
import { ExportTo } from 'common/companies/types';

export const LARGE_BULK_EXPORT_LIMIT = 5000;
export const useLargeBulkExport = () => {
  const dispatch = useAppDispatch();
  const {getSdrProfileInfo} = useSdrProfile();
  const filteredCompanies = useAppSelector(
    filteredCompaniesSelector
  );
  const {totalElements: companiesFound,
    pageNumber} = useCompaniesSearch();
  const {sortBy, sortOrder} = useAppSelector(companySortSelector);
  const filterFormData = useAppSelector(filterFormSelector);
  const {availableCredits: {companyDataCredits}} = useSdrProfile();
  const {
    exportStage,
    alreadyExportedCompaniesAmount
  } = useAppSelector((state) => state.largeBulkExport);

  const countOfCompaniesForExport = Math.min(companiesFound, companyDataCredits, LARGE_BULK_EXPORT_LIMIT);

  const getNumberOfExportedCompanies = () => {
    const pageRequest = {
      page: 0,
      size: countOfCompaniesForExport,
      sortBy,
      sortOrder,
    }
    return dispatch(handleGetNumberOfExportedCompanies(filterFormData, pageRequest));
  }
  const openConfirmExportModal = () => {
    getNumberOfExportedCompanies().then(result => {
      if (result) {
        updateExportStage(ExportStage.CONFIRM);
      }
    });
  }
  const skipExportedAndUpdateFilters = () => {
    const newFilterFormData = {
      ...filterFormData,
      skipExported: true,
    }
    dispatch(setFilterFormData(newFilterFormData))
    dispatch(handleSearchCompanies(newFilterFormData, pageNumber, filteredCompanies.size));
    dispatch(setNumberOfExportedCompanies(0));
  }

  const exportCompanies = (exportTo: ExportTo) => {
    const pageRequest = {
      page: 0,
      size: countOfCompaniesForExport,
      sortBy,
      sortOrder,
    }
    dispatch(handleExportFilteredCompanies(filterFormData, pageRequest, exportTo))
      .then(result => {
        if (result) {
          updateExportStage(ExportStage.PROCESSING);
          getSdrProfileInfo();
        }
      });
  }

  const reexportCompanyBulkExportProcess = async (bulkExportProcessId: number, exportTo: ExportTo) => {
    return await dispatch(handleReexportCompanyBulkExportProcess(bulkExportProcessId, exportTo));
  }

  const reexportPermitBulkExportProcess = async (bulkExportProcessId: number) => {
    return await dispatch(handleReexportPermitBulkExportProcess(bulkExportProcessId));
  }

  const startBulkExport = async () => {
    if (companyDataCredits === 0) {
      dispatch(setExportStage(ExportStage.NO_CREDITS));
      return;
    }
    if (companiesFound > LARGE_BULK_EXPORT_LIMIT || companiesFound > companyDataCredits) {
      dispatch(setExportStage(ExportStage.START));
      return;
    }
    openConfirmExportModal();
  }

  const updateExportStage = (exportStage: ExportStage | null) => {
    dispatch(setExportStage(exportStage));
  }
  return {
    alreadyExportedCompaniesAmount,
    companiesFound,
    countOfCompaniesForExport,
    exportStage,
    startBulkExport,
    updateExportStage,
    openConfirmExportModal,
    skipExportedAndUpdateFilters,
    exportCompanies,
    reexportCompanyBulkExportProcess,
    reexportPermitBulkExportProcess
  }
}
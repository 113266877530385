import { useAppDispatch, useAppSelector } from 'application/store';
import { bulkExportSelector, setSelectedEmployees } from '../store';
import { ExportEmployeesI } from '../store/types';
import { useParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

export const useEmployeeBulkExport = () => {
  const { name } = useParams();
  const {
    selectedEmployees,
    allEmployees,
    newEmployees,
    exportedEmployees,
    totalSelectedContacts,
  } = useAppSelector(bulkExportSelector);
  const dispatch = useAppDispatch();

  const currentEmployeesList = useMemo(() => {
    return name === "new"
      ? newEmployees.content
      : name === "all"
        ? allEmployees.content
        : exportedEmployees.content
  },[name, allEmployees?.content, newEmployees?.content, exportedEmployees?.content])

  const addEmployeeToSelected = (externalId: number, companyId: string | null, phoneExported: boolean, emailExported: boolean) => {
    const filteredItems = selectedEmployees.filter(
      (e) => e.externalId !== externalId
    );
    const newItem = {
      externalId,
      companyId,
      phoneExported,
      emailExported,
    };
    if (newItem.phoneExported || newItem.emailExported) {
      dispatch(
        setSelectedEmployees([
          ...filteredItems,
          newItem,
        ])
      );
    } else {
      dispatch(
        setSelectedEmployees([
          ...filteredItems,
        ])
      );
    }
  }

  const selectEmployeesList = useCallback((employeeList: ExportEmployeesI[], isSelected: boolean) => {
    const unselected = selectedEmployees
      .filter((item) =>
        !employeeList.some((e) => e.externalId === item.externalId)
      );
    const selected = employeeList.map((item) => ({
      externalId: item.externalId,
      companyId: item.companyId,
      phoneExported: isSelected,
      emailExported: isSelected,
    }));
    if (isSelected) {
      dispatch(setSelectedEmployees([...unselected, ...selected]));
    } else {
      dispatch(setSelectedEmployees([...unselected]));
    }
  }, [selectedEmployees, dispatch]);

  const isPhoneSelected = useCallback((externalId: number)  =>
    selectedEmployees.some((item) => item.externalId === externalId && item.phoneExported)
  , [selectedEmployees]);

  const isEmailSelected = useCallback((externalId: number) =>
    selectedEmployees.some((item) => item.externalId === externalId && item.emailExported)
  , [selectedEmployees]);

  return {
    isPhoneSelected,
    isEmailSelected,
    addEmployeeToSelected,
    selectEmployeesList,
  }
}
import { Add } from "@mui/icons-material";
import { Box } from "@mui/system";
import { Button } from "application";
import { useAppDispatch, useAppSelector } from "application/store";
import theme from "application/theme";
import { AddTeamMemberModal } from "common/organization/modals/AddTeamMemberModal";
import { ManagementTeamCreditsModal } from "common/organization/modals/ManagementTeamCreditsModal/ManagementTeamCreditsModal";
import {
  organizationsSelector,
  setIsAddMembeModalOpen,
} from "common/organization/store";
import React, { useState } from "react";

export const TeamInfo = () => {
  const dispatch = useAppDispatch();
  const { isAddMemberModalOpen, organizationProfile } = useAppSelector(
    organizationsSelector
  );
  const isReachedNumberOfSeats =
    organizationProfile.seats === organizationProfile.usedSeats;
  const [isManagementCreditsOpen, setIsManagementCreditsOpen] = useState(false);
  const openManagementTeamCredits = () => {
    setIsManagementCreditsOpen(true);
  };
  const closeManagementTeamCredits = () => {
    setIsManagementCreditsOpen(false);
  };
  const openAddTeamMemberModal = () => {
    dispatch(setIsAddMembeModalOpen(true));
  };
  const closeAddTeamMemberModal = () => {
    dispatch(setIsAddMembeModalOpen(false));
  };

  return (
    <>
      <Box display="flex">
        <Button
          label="Manage Team Credits"
          startAdornment={<Add sx={{ color: theme.palette.common.grey800 }} />}
          height={5.5}
          mr={1.5}
          type="button"
          onClick={openManagementTeamCredits}
        />
        <Button
          label="Add team member"
          startAdornment={
            <Add
              sx={{
                color: isReachedNumberOfSeats
                  ? theme.palette.common.grey600
                  : theme.palette.common.grey800,
              }}
            />
          }
          mr={1.5}
          type="button"
          disabled={isReachedNumberOfSeats}
          onClick={openAddTeamMemberModal}
        />
      </Box>
      <AddTeamMemberModal
        isOpen={isAddMemberModalOpen}
        handleClose={closeAddTeamMemberModal}
      />
      <ManagementTeamCreditsModal
        isOpen={isManagementCreditsOpen}
        handleClose={closeManagementTeamCredits}
      />
    </>
  );
};

import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "application/store";

import { Typography } from "@mui/material";

import theme from "application/theme";

import { Box } from "@mui/system";
import { sdrProfileSelector } from "common/sdrProfile/store";
import { tutorialType } from "common/sdrProfile/store/types";
import { NextBtnIcon } from "application/assets/NextBtnIcon";
import { Step } from "react-joyride";
import { FirstStepTutorialStyled } from "../styles";
import { handleSetSdrTutorial } from "common/sdrProfile/store/actions";

interface TutorialIntroProps {
  handleStartTutorial: (event: React.MouseEvent<HTMLElement>) => void;
  steps: Step[];
  title: string;
  type: tutorialType;
}
const TutorialIntro = ({
  handleStartTutorial,
  steps,
  title,
  type,
}: TutorialIntroProps) => {
  const dispatch = useAppDispatch();
  const [showIntro, setShowIntro] = useState(true);
  const {
    sdrProfile: { tutorials },
  } = useAppSelector(sdrProfileSelector);
  const tutorial = tutorials
    ? tutorials.find((tutorial) => tutorial.type === type)
    : null;

  useEffect(() => {
    return setShowIntro(true);
  }, []);

  return (
    <Box width="100%">
      {tutorial && tutorial.id && !tutorial.isViewed && showIntro ? (
        <FirstStepTutorialStyled
          onClick={(e) => {
            setShowIntro(false);
            dispatch(handleSetSdrTutorial(tutorial.id));
            handleStartTutorial(e);
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography
              variant="caption"
              color={theme.palette.common.white}
              fontSize={theme.spacing(1.5)}
            >
              Get started
            </Typography>
            <Typography variant="caption" color={theme.palette.common.white}>
              1/{steps?.length}
            </Typography>
          </Box>
          <Box display="flex" gap={1} alignItems="center">
            <Typography variant="button" color={theme.palette.common.white}>
              {title}
            </Typography>
            <NextBtnIcon />
          </Box>
        </FirstStepTutorialStyled>
      ) : null}
    </Box>
  );
};

export default TutorialIntro;

import React, { useState } from "react";
import Box from "@mui/material/Box";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { QuantityPickerProps } from "./types";
import {
  IconButtonStyled,
  LabelQuantity,
  LabelWrapper,
  QuantityPickerInput,
  QuantityPickerWrapper,
} from "./styles";
import theme from "application/theme";
import { getNumberFormatWithSeparators } from 'application/utils/numberUtils';
import { NumericFormat } from 'react-number-format';

const QuantityPicker = ({
  label,
  register: { onChange, onBlur, ref, name: fieldName },
  getValue,
  defaultValue = 0,
  error,
  min = 0,
  max = 9999,
}: QuantityPickerProps) => {
  const [quantity, setQuantity] = useState(Math.max(defaultValue, min));

  const handleIncrement = () => {
    const newQuantity = Math.min(quantity + 1, max);
    setQuantity(newQuantity);
    onChange({
      target: {
        name: fieldName,
        value: newQuantity,
      },
    });
    if(getValue){
      getValue(newQuantity);
    }
  };

  const handleDecrement = () => {
    const newQuantity = Math.max(quantity - 1, min);
    setQuantity(newQuantity);
    onChange({ target: { name: fieldName, value: newQuantity } });
    if (getValue) {
      getValue(newQuantity);
    }
  };

  const handleChangeQuantity = (quantity: number) => {
    let newQuantity = Math.min(quantity, max);
    newQuantity = Math.max(newQuantity, min);
    setQuantity(newQuantity);
    if (getValue) {
      getValue(newQuantity);
    }
    onChange({ target: { name: fieldName, value: newQuantity } });
  }

  const isValueAllowed = (values: any) => {
    if(values.value === '00') return false;
    return !(max && values.floatValue > max);
  }

  return (
    <Box>
      <LabelWrapper
        variant="caption"
        color={
          error ? theme.palette.common.red600 : theme.palette.common.grey800
        }
      >
        {label}: <LabelQuantity>{getNumberFormatWithSeparators(quantity)}</LabelQuantity>
      </LabelWrapper>
      <QuantityPickerWrapper
        sx={{
          borderColor: error
            ? theme.palette.common.red600
            : theme.palette.common.grey400,
        }}
      >
        <IconButtonStyled onClick={handleDecrement} size="small">
          <ArrowLeft />
        </IconButtonStyled>
        <NumericFormat value={quantity}
          // @ts-ignore
                       customInput={QuantityPickerInput}
                       type="text"
                       thousandSeparator=','
                       onValueChange={(values) => handleChangeQuantity(values.floatValue || 0)}
                       isAllowed={isValueAllowed}
        />
        <IconButtonStyled onClick={handleIncrement} size="small">
          <ArrowRight />
        </IconButtonStyled>
      </QuantityPickerWrapper>
    </Box>
  );
};

export default QuantityPicker;

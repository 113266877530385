import * as React from "react";

import { Box, Typography } from "@mui/material";

import theme from "application/theme";

import { TypographyWithOverflow } from "./styles";

interface FooterProps {
  username: string;
  email: string;
}

export const SideBarFooterItem = ({ username, email }: FooterProps) => {
  return (
    <Box display="flex" flexDirection="column" gap={0.25}>
      <Typography variant="button" color={theme.palette.common.grey900}>
        {username}
      </Typography>
      <TypographyWithOverflow
        variant="caption"
        color={theme.palette.common.grey700}
      >
        {email}
      </TypographyWithOverflow>
    </Box>
  );
};

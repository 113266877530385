export const enum ServiceType {
  RESIDENTIAL = "RESIDENTIAL",
  COMMERCIAL = "COMMERCIAL",
  NOT_YET_DEFINED = "UNABLE_TO_CLASSIFY",
}

export const enum NumbOfEmployees {
  FROM_0_TO_10 = "FROM_0_TO_10",
  FROM_10_TO_50 = "FROM_10_TO_50",
  FROM_50_TO_300 = "FROM_50_TO_300",
  FROM_300 = "FROM_300",
}

export const enum AnnualRevenue {
  FROM_0_TO_1M = "FROM_0_TO_1M",
  FROM_1M_TO_20M = "FROM_1M_TO_20M",
  FROM_20M_TO_50M = "FROM_20M_TO_50M",
  FROM_50M_TO_100M = "FROM_50M_TO_100M",
  FROM_100M_TO_250M = "FROM_100M_TO_250M",
  FROM_250M_TO_500M = "FROM_250M_TO_500M",
  FROM_500M_TO_1B = "FROM_500M_TO_1B",
  FROM_1B = "FROM_1B",
}

export const enum Score {
  WONDERFUL = "WONDERFUL",
  VERY_GOOD = "VERY_GOOD",
  GOOD = "GOOD",
  PLEASANT = "PLEASANT",
}

export const enum YearsInBusiness {
  LESS_THEN_1 = "LESS_THEN_1",
  FROM_1_TO_5 = "FROM_1_TO_5",
  FROM_5_TO_10 = "FROM_5_TO_10",
  FROM_10 = "FROM_10",
}

export const enum EmailStatuses {
  VERIFIED = "verified",
  EXTRAPOLATED = "extrapolated",
  UNAVAILABLE = "unavailable",
}

export const enum SortBy {
  BUSINESS_NAME = "businessName",
  REPUTATION_SCORE = "reputationScore",
  ANNUAL_REVENUE = "annualRevenue",
  EMPLOYEES = "employees",
  AVERAGE_ANNUAL_PERMITS_VALUE = "averageAnnualPermitsValue",
}

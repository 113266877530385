import { StyledTeamTableContainer } from "./styles";
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import theme from "application/theme";
import { OrganizationTeamMemberI } from "../store/types";
import EmptyInfoSdrTeam from "../emptyInfo";

interface Props {
  teamMembers: OrganizationTeamMemberI[];
}

export const OrganizationTeamTable = ({ teamMembers }: Props) => {
  const tableHead = ["First Name", "Last Name", "Email"];
  return (
    <StyledTeamTableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ background: theme.palette.common.grey200 }}>
          <TableRow>
            {tableHead.map((header) => (
              <TableCell key={header}>
                <Typography
                  variant="body2"
                  color={theme.palette.common.grey800}
                >
                  {header}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {teamMembers?.length ? (
          <TableBody>
            {teamMembers.map((row: OrganizationTeamMemberI, idx) => (
              <TableRow key={idx}>
                <TableCell width={theme.spacing(27)}>
                  <Typography
                    variant="caption"
                    color={theme.palette.common.grey800}
                  >
                    {row.firstName}
                  </Typography>
                </TableCell>
                <TableCell width={theme.spacing(27)}>
                  <Typography
                    variant="caption"
                    color={theme.palette.common.grey800}
                  >
                    {row.lastName}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="caption"
                    color={theme.palette.common.grey800}
                  >
                    {row.email}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        ) : null}
      </Table>
      {!teamMembers?.length ? <EmptyInfoSdrTeam /> : null}
    </StyledTeamTableContainer>
  );
};

import React, { FC, SVGProps, useState } from "react";
import { Typography } from "@mui/material";
import theme from "../../../../application/theme";

interface Props {
  label: string | null | undefined;
  value?: string | null;
}
export const PermitDataItem = ({label, value}: Props) => {
  if(!value) {
    return null;
  }
  return (
    <Typography
      variant="overline"
      color={theme.palette.common.grey900}
    >
      {label} <Typography variant="overline" fontWeight='medium'>{value}</Typography>
    </Typography>
  )
}

import theme from 'application/theme';
import { Box, styled } from '@mui/system';

export const SectionStyled = styled(Box)`
  width: 100%;
  height: auto;
  padding: ${theme.spacing(4,4, 2 ,4)};
  border-bottom: 1px solid ${theme.palette.common.grey300};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1.5)};
`

export const PermitInfoContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  width: 100%;
  background-color: ${theme.palette.common.white};
`

export const PermitInfoContainerStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  background-color: ${theme.palette.common.grey150};
`;

export const BottomMenuStyled = styled(Box)`
  flex: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing(1.5, 2)};
  background-color: ${theme.palette.common.grey150};
  border-top: 1px solid ${theme.palette.common.grey200};
`

import { styled, Box } from '@mui/system';
import theme from 'application/theme';

export const FiltersHeaderWrapper = styled(Box)`
  flex: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing(1)};
`

export const FiltersCountStyled = styled(Box)`
  display: flex;
  width: ${theme.spacing(3)};
  height: ${theme.spacing(3)};
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing(1)};
  border-radius: ${theme.spacing(0.75)};
  font-size: ${theme.spacing(2)};
  font-weight: 500;
  color: ${theme.palette.common.grey700};
  background: ${theme.palette.common.grey300};
`;

export const FiltersToggleStyled = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
`

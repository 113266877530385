import { Link } from "react-router-dom";
import { styled } from "@mui/system";
import { Box, Checkbox, Divider, TextField } from "@mui/material";
import authBg from "./assets/authBg.png";
import theme from "application/theme";

export const TextFieldStyled = styled(TextField)`
  color: ${theme.palette.common.grey800};
  font-weight: 500;
  & .MuiOutlinedInput-root {
    height: ${theme.spacing(5.75)};
  }
  & .MuiInputBase-adornedEnd {
    padding-right: ${theme.spacing(0.75)};
  }
  & .MuiInputLabel-root {
    color: ${theme.palette.common.grey900};
    font-weight: 400;
  }
  & fieldset {
    border-radius: ${theme.spacing(1)};
  }
  & label.Mui-focused {
    color: ${theme.palette.common.grey900};
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: ${theme.spacing(0.125)} solid ${theme.palette.common.blue700};
    }
    &.Mui-fo &:hover fieldset {
      border-color: inherit;
    }
  }
  & .MuiOutlinedInput-root.Mui-error {
    &.MuiOutlinedInput-root.Mui-focused fieldset {
      border: ${theme.spacing(0.125)} solid ${theme.palette.common.red700};
    }
  }
  & .MuiInputLabel-root.Mui-error {
    color: ${theme.palette.common.red700};
  }
`;
export const LinkStyled = styled(Link)`
  font-family: Rubik;
  color: ${theme.palette.common.blue700};
  font-weight: 500;
  font-size: ${theme.spacing(1.75)};
  line-height: ${theme.spacing(2.5)};
`;
export const FormWrapper = styled(Box)`
  width: ${theme.spacing(45)};
`;

export const paperStyled = {
  maxWidth: 400,
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  background: "#fff",
  borderRadius: 1,
  padding: 2,
};

export const LoginPageWrapper = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const LoginPageContentWrapper = styled(Box)`
  padding: ${theme.spacing(4)};
  width: 50%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

export const InfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${theme.palette.common.grey900};
`;

export const ImageAuth = styled("div")`
  width: 100%;
  height: 100%;
  background-image: url(${authBg});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: ${theme.spacing(2)};
  padding-top: ${theme.spacing(8)};
  display: flex;
  justify-content: center;
  background-position: center;
`;

export const BuilderLogo = styled("img")`
  max-width: ${theme.spacing(45)};
  max-height: ${theme.spacing(10)};
`;

export const BackLink = styled(Link)`
  color: ${theme.palette.common.grey800};
  text-decoration: none;
  font-weight: 400;
  font-size: ${theme.spacing(2)};
  line-height: ${theme.spacing(2.5)};
  left: ${theme.spacing(4)};
  display: flex;
  align-self: start;
  align-items: center;
  cursor: pointer;
`;

export const CenteredWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledDivider = styled(Divider)`
  width: ${theme.spacing(20)};
  border-color: ${theme.palette.common.grey400};
`;

export const HelperTextWrapper = styled(Box)`
  display: flex;
  align-self: start;
  align-items: center;
`;

export const UserEmailStyled = styled("span")`
  color: ${theme.palette.common.turquoise300};
  display: inline;
`;

export const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root:hover {
    background-color: ${theme.palette.common.blue50};
  }
  &.Mui-checked {
    color: ${theme.palette.common.blue700};
  }
`;

export const PrivacyLink = styled(Link)`
  color: ${theme.palette.common.grey800};
`;

import React from 'react';
import { FilterTextStyled } from './styles';
import { Box } from '@mui/system';
import theme from 'application/theme'
import { CheckIcon, RemoveBtnIcon } from 'application/assets';
interface Props {
  label: string;
  isExcluded?: boolean;
}
export const CheckedFilterItem = ({ label, isExcluded }: Props) => {
  const iconColor = theme.palette.common.grey700;
  return (
    <Box display='flex'
         gap={isExcluded ? theme.spacing(1) : theme.spacing(0.5)}
         alignItems="center">
      {isExcluded ? <RemoveBtnIcon color={iconColor}/>
        : <CheckIcon color={iconColor}/>}
      <FilterTextStyled>
        {label}
      </FilterTextStyled>
    </Box>
  )
}
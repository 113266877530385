import { createSlice } from "@reduxjs/toolkit";
import { store } from "application/store";

const initialState = {
  infoMessage: null,
  showBulkExportNotification: false,
  bulkExportNotificationScope: "",
};

export const inAppNotificationSlice = createSlice({
  name: "inAppNotification",
  initialState,
  reducers: {
    setInfoMessage: (state, action) => {
      state.infoMessage = action.payload;
    },
    setShowBulkExportNotification: (state, action) => {
      state.showBulkExportNotification = action.payload;
    },
    setBulkExportNotificationScope: (state, action) => {
      state.bulkExportNotificationScope = action.payload;
    },
  },
});

export const {
  setInfoMessage,
  setShowBulkExportNotification,
  setBulkExportNotificationScope,
} = inAppNotificationSlice.actions;
export const infoMessageSelector = () => store.getState().inAppNotification.infoMessage;

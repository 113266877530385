import React from "react";
import { Typography } from "@mui/material";
import theme from "application/theme";

import { ModalComponent } from "application/components/ModalComponent";

import { ContentWrapper, StyledBackToHomeButton } from "./styles";

import { SuccessIcon } from "./SuccessIcon";

interface Props {
  open: boolean;
  onClose: () => void;
}

const SaveSurveyModal = ({ open, onClose }: Props) => {
  const handleClose = () => {
    onClose()
  };

  return (
    <ModalComponent isOpen={open} handleClose={handleClose} width={"30%"}>
      <ContentWrapper>
        <SuccessIcon/>
        <Typography variant="subtitle1" color={theme.palette.common.grey900}>
          Request was successfully sent
        </Typography>
        <StyledBackToHomeButton
          onClick={handleClose}
          variant="contained">
          Back to Homepage
        </StyledBackToHomeButton>
      </ContentWrapper>
    </ModalComponent>
  );
};

export default SaveSurveyModal;

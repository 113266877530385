import React from 'react';
import { PermitAffiliationI, PermitAffiliationType } from 'common/permits/types';
import { Box } from '@mui/system';
import {
  RemoveAffiliationStyled,
  SelectedAffiliationNameStyled,
  SelectedAffiliationTypesStyled,
  SelectedAffiliationWrapper
} from './styles';
import { affiliationTypeOptions } from './index';
import { CrossIcon } from 'application/assets';

interface SelectedAffiliationsProps {
  affiliations: PermitAffiliationI[];
  onRemove: (index: number) => void;
}
export const SelectedAffiliations = ({affiliations, onRemove}: SelectedAffiliationsProps) => {

  const mapTypeToLabel = (type: PermitAffiliationType) => {
    return affiliationTypeOptions.find((option) => option.value === type)?.label;
  }

  const getUniqueKey = (affiliation: PermitAffiliationI, index: number) => {
    return `${affiliation.name}_${affiliation.types.join('_')}_${index}`;
  }
  if(!affiliations) {
    return null;
  }
  return (
    <SelectedAffiliationWrapper>
      {
        affiliations.slice(0, affiliations.length - 1)
          .map((affiliation: PermitAffiliationI, index: number) => (
            <Box display={'flex'} justifyContent={'space-between'}
                 gap={1}
                 key={getUniqueKey(affiliation, index)}>
              <Box>
                {affiliation.name && <SelectedAffiliationNameStyled>{affiliation.name}</SelectedAffiliationNameStyled>}
                {affiliation.types ? <SelectedAffiliationTypesStyled>
                  {affiliation.types.map(mapTypeToLabel).join(', ')}
                </SelectedAffiliationTypesStyled> : null}
              </Box>
              <RemoveAffiliationStyled onClick={() => onRemove(index)}>
                <CrossIcon/>
              </RemoveAffiliationStyled>
            </Box>
          ))
      }
    </SelectedAffiliationWrapper>

  )
}

import React, { useState } from "react";

interface CheckIconProps {
  color?: string;
}
export const CheckIconCircle: React.FC<CheckIconProps> = ({ color = '#42B271', ...rest }) => {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g clipPath="url(#clip0_3983_54620)">
          <path d="M4.66667 7.84445L6.47179 9.8L10.5333 5.4M14.2 7.6C14.2 11.2451 11.2451 14.2 7.6 14.2C3.95492 14.2 1 11.2451 1 7.6C1 3.95492 3.95492 1 7.6 1C11.2451 1 14.2 3.95492 14.2 7.6Z"
                stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_3983_54620">
            <rect width="16" height="16" fill="white"/>
          </clipPath>
        </defs>
      </svg>
  );
};


import React from 'react';
import { Box } from '@mui/system';
import { InfoItemsWrapper } from './styles';
import { PermitInfoItem } from './PermitInfoItem';
import { CompassIcon, BuildingIcon, MarkerIcon, GlobeIcon } from 'application/assets';
import { useAppSelector } from 'application/store';
import { selectedPermitSelector } from 'common/permits/store';

export const PermitLocationInfo = () => {
  const selectedPermit = useAppSelector(selectedPermitSelector);
  const location = selectedPermit?.permitLocation;
  return (
    <Box display={'flex'}>
      <InfoItemsWrapper>
        <PermitInfoItem
          display={true}
          icon={<CompassIcon/>}
          title={location?.street || '-'}
        />
        <PermitInfoItem
          display={true}
          icon={<BuildingIcon/>}
          title={location?.city || '-'}
        />
        <PermitInfoItem
          display={true}
          icon={<MarkerIcon/>}
          title={location?.county || '-'}
        />
      </InfoItemsWrapper>
      <InfoItemsWrapper>
        <PermitInfoItem
          display={true}
          icon={<GlobeIcon/>}
          title={location?.state || '-'}
        />
        <PermitInfoItem
          display={true}
          icon={<CompassIcon/>}
          title={location?.zipcode || '-'}
        />

      </InfoItemsWrapper>
    </Box>
  )
}

import React from 'react';
import { useAppSelector } from 'application/store';
import { FiltersCollapse } from 'application';
import { FiltersWrapper, FilterTextStyled } from '../components/styles';
import { formatLargeNumbers } from 'application/utils';
import { permitFiltersSelector } from 'common/permits/store';

export const PermitValueFilter = () => {
  const {permitValue} = useAppSelector(permitFiltersSelector);
  const displayFilter  = permitValue?.min || permitValue?.max;
  return displayFilter ? (
    <FiltersCollapse label={'PERMIT VALUE'} count={1}>
      <FiltersWrapper>
        <FilterTextStyled>
                {`$${formatLargeNumbers(permitValue?.min || 0)}
                 - $${formatLargeNumbers(permitValue?.max || 0)}`}
        </FilterTextStyled>
      </FiltersWrapper>
    </FiltersCollapse>
  ) : <></>;
}

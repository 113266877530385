import React, { useEffect } from "react";
import DropdownWithMultiselect from "application/components/DropdownWithMultiselect";
import { useAppDispatch, useAppSelector } from "application/store";
import { permitsSelector, setSelectedPermitsColumns } from "common/permits/store";
import { getPermitColumns, savePermitColumns } from "../../Views/LocalStorageService";
import { permitColumnsList } from '../../Views/PermitTableView/permitColumnsList';

export const PermitColumnsSettings = () => {
  const dispatch = useAppDispatch();
  const { selectedPermitsColumns } = useAppSelector(permitsSelector);

  const onSelectChange = (value: string[]) => {
    dispatch(setSelectedPermitsColumns(value));
    savePermitColumns(value);
  };

  useEffect(() => {
    const savedSelectedColumns = getPermitColumns();
    if (savedSelectedColumns) {
      dispatch(setSelectedPermitsColumns(savedSelectedColumns));
    }
  }, []);

  const columnListWithoutId = permitColumnsList.filter((column) => column.value !== "id");

  return (
      <DropdownWithMultiselect
        options={columnListWithoutId}
        selectedOptions={selectedPermitsColumns}
        onChangeSelected={(value) => onSelectChange(value)}
        label={"Columns"}
      />
  );
};

import React from 'react'
import noResults from 'common/companies/assets/noResults.svg';
import { Typography } from '@mui/material';
import theme from 'application/theme';
import { EmptyTableWrapper } from './styles';

const EmptyTable = () => {
  return (
    <EmptyTableWrapper>
      <img src={noResults} alt="No results found" />
      <Typography variant="button" color={theme.palette.common.grey800}>
        No results found
      </Typography>
      <Typography variant="caption" color={theme.palette.common.grey600}>
        Please adjust your filters and try again
      </Typography>
    </EmptyTableWrapper>
  )
}

export default EmptyTable;
import {useEffect, useMemo, useState} from "react";
import {PageResponseI} from "application/types";
import {getCities, getStates} from "integration/api/companies";

const initialPageState: PageResponseI<string> = {
    content: [],
    empty: true,
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    totalPages: 0,
    totalElements: 0,
    size: 0
}
export const useLocationSearch = () => {
    const [citySearch, setCitySearch] = useState('');
    const [stateSearch, setStateSearch] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isCityResponseReceived, setIsCityResponseReceived] = useState(false);
    const [isStateResponseReceived, setIsStateResponseReceived] = useState(false);
    const [citiesPage, setCitiesPage] = useState<PageResponseI<string>>(initialPageState);
    const [statesPage, setStatesPage] = useState<PageResponseI<string>>(initialPageState);
    const [foundCities, setFoundCities] = useState<string[]>([]);
    const [foundStates, setFoundStates] = useState<string[]>([]);

    useEffect(() => {
        setIsCityResponseReceived(false);
    },[citySearch]);

    useEffect(() => {
        setIsStateResponseReceived(false);
    },[stateSearch]);

    const citiesNotFound = useMemo(() => {
        return isCityResponseReceived && !isLoading && citiesPage.totalElements === 0;
    },[isCityResponseReceived, isLoading, citiesPage.totalElements]);

    const statesNotFound = useMemo(() => {
        return isStateResponseReceived && !isLoading && statesPage.totalElements === 0;
    },[isStateResponseReceived, isLoading, statesPage.totalElements]);

    const couldLoadMoreCities = useMemo(() => !citiesPage.last, [citiesPage.last]);
    const couldLoadMoreStates = useMemo(() => !statesPage.last, [statesPage.last]);
    const totalFoundCities = useMemo(() => citiesPage.totalElements, [citiesPage.totalElements]);
    const totalFoundStates = useMemo(() => statesPage.totalElements, [statesPage.totalElements]);

    const fetchCities = async (page = 0, addToFounded = false) => {
        setIsLoading(true);
        const response : PageResponseI<string> = await getCities(citySearch, page, 10);
        setCitiesPage(response);
        addToFounded ? setFoundCities([...foundCities, ...response.content]) : setFoundCities(response.content);
        setIsCityResponseReceived(true)
        setIsLoading(false);
    }

    const fetchStates = async (page = 0, addToFounded = false) => {
        setIsLoading(true);
        const response : PageResponseI<string> = await getStates(stateSearch, page, 10);
        setStatesPage(response);
        addToFounded ? setFoundStates([...foundStates, ...response.content]) : setFoundStates(response.content);
        setIsStateResponseReceived(true)
        setIsLoading(false);
    }

    const loadMoreCities = () => {
        fetchCities(citiesPage.number + 1, true);
    }
    const loadMoreStates = () => {
        fetchStates(statesPage.number + 1, true);
    }

    const clearCitiesSearch = () => {
        setCitySearch('');
        setFoundCities([]);
        setCitiesPage(initialPageState);
    }

    const clearStatesSearch = () => {
        setStateSearch('');
        setFoundStates([]);
        setStatesPage(initialPageState);
    }

    return {
        citySearch,
        setCitySearch,
        stateSearch,
        setStateSearch,
        handleCitySearch: fetchCities,
        handleStateSearch: fetchStates,
        foundCities,
        foundStates,
        loadMoreCities,
        loadMoreStates,
        citiesNotFound,
        statesNotFound,
        isLoading,
        totalFoundCities,
        totalFoundStates,
        couldLoadMoreCities,
        couldLoadMoreStates,
        clearCitiesSearch,
        clearStatesSearch
    }
}
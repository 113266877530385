import * as yup from "yup";

const phoneNumberRegex =
  /^(\+?)(\d{1,3})(\s?)(\d{3})(\s?)(\d{3})(\s?)(\d{2})(\s?)(\d{2})$/;

export const schema = yup.object().shape({
  companyName: yup.string().required("Organization name is required"),
  organizationType: yup.string().required("Organization type is required"),
  contactPerson: yup.string().required("Contact Person is required"),
  jobPosition: yup.string().required("Job Position is required"),
  location: yup.string().required("Location is required"),
  timezone: yup.string().required("Time zone is required"),
  phone: yup
    .string()
    .matches(phoneNumberRegex, "Invalid phone number format")
    .required("Phone is required"),
  emailContactPerson: yup
    .string()
    .email("Invalid email")
    .required("Email is required"),
  website: yup.string().url("Invalid website URL"),
  phoneCredits: yup.number(),
  emailCredits: yup.number(),
});

import { CompanyFilterDataI } from "integration/api/companies/types";

export const enum ExportType {
  PHONE = "PHONE",
  EMAIL = "EMAIL",
  EMAIL_AND_PHONE = "BOTH",
  COMPANY_DATA = "COMPANY_DATA",
}

export const enum ExportScope {
  EMPLOYEE = "EMPLOYEE",
  COMPANY = "COMPANY",
  PERMIT = "PERMIT",
}

export const enum ExportTo {
  CSV= 'CSV',
  HUBSPOT = 'HUBSPOT'
}

export interface ICompanies {
  companyName: string;
}

export interface ISavedSearchesData {
  id: number;
  userId: number;
  name: string | null;
  filterValue: CompanyFilterDataI;
}

import { Box, styled } from '@mui/system';
import { FormControlLabel, Typography } from '@mui/material';
import theme from 'application/theme';

export interface ReputationScoreProps {
  score?: number;
}
export const ReputationScoreStyled = styled(Typography)<ReputationScoreProps>`
  font-weight: 500;
  font-size: pxToRem(14);
  line-height: pxToRem(18);
  color: ${({ score }) => getColorByScore(score)};
`;
export const WebsiteLinkStyled = styled("a")`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(0.5)};
  text-decoration: none;
  & .MuiTypography-root {
    color: ${theme.palette.common.grey700};
    font-weight: 500;
    font-size: pxToRem(14);
    line-height: pxToRem(18);
  }
`

export const ActionsWrapperStyled = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
  min-width: ${theme.spacing(12)};
`
export const ActionItemStyled = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(0.5)};
  cursor: pointer;
  white-space: nowrap;
  & .MuiTypography-root {
    font-size: pxToRem(14);
    line-height: pxToRem(18);
  }
`
export const NotGoodFitControlStyled = styled(FormControlLabel)`
  margin: 0;
  gap: ${theme.spacing(0.5)};
  .MuiFormControlLabel-label {
    font-size: ${theme.spacing(1.75)};
    font-weight: 400;
    width: 100%;
    color: ${theme.palette.common.grey800} !important;
  }
  .Mui-checked,
  .MuiCheckbox-indeterminate {
    color: ${theme.palette.common.blue700} !important;
  }
  .MuiCheckbox-root {
    padding: ${theme.spacing(0.25)};
    border-radius: ${theme.spacing(0.25)};
  }
  svg {
    width: ${theme.spacing(2.25)};
    height: ${theme.spacing(2.25)};
    font-size: 1.1rem;
  }
  .MuiSvgIcon-root path {
    width: ${theme.spacing(2)};
    height: ${theme.spacing(2)};
  }
`;

const getColorByScore = (score?: number) => {
  const common = theme.palette.common;
  if(!score) return common.orange600;
  if (score >= 90) return common.turquoise800;
  if (score >= 80) return common.turquoise600;
  if (score >= 70) return common.orange900;
  return common.orange600;
}

import React from 'react';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { CheckboxLabel, CheckboxLabelWithOverflow, FormControlLabelStyled } from './styles';
import { Checkbox } from '@mui/material';
import { Box } from '@mui/system';
import theme, {commonColors} from 'application/theme';
import { CustomTooltip } from '../index';

interface MultiSelectItemProps {
  checked: boolean;
  label: string;
  value: any;
  onClick: (value: any, checked: boolean) => void;
  field?: ControllerRenderProps<any, any>;
  tooltip?: string;
  disabled?: boolean;
}
export const MultiSelectItem = ({checked, label, value, onClick, field, tooltip, disabled}: MultiSelectItemProps) => {
  return (
    <FormControlLabelStyled
      className={'class-marker'}
      control={
        <Checkbox
          disabled={disabled}
          {...field}
          checked={checked}
          onChange={() => onClick(value, checked)}
        />
      }
      label={
        <CheckboxLabel>
          <Box display="flex" gap={0.5} alignItems="center">
            <CheckboxLabelWithOverflow
              variant="caption"
              color={disabled ? commonColors.grey600 : commonColors.grey900}
            >
              {label}
            </CheckboxLabelWithOverflow>
            {tooltip ? <CustomTooltip title={tooltip} /> : <></>}
          </Box>
        </CheckboxLabel>
      }
    />
  )
}

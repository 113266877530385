import { styled } from "@mui/system";
import { Box } from "@mui/material";
import theme from "application/theme";

export const StyledSurveyFormWrapper = styled(Box)`
  display: flex;
  max-width: ${theme.spacing(67)};
  padding: ${theme.spacing(3)};
  flex-direction: column;
  margin: 0 auto;
  gap: ${theme.spacing(4)};
`;

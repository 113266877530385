import { Box } from "@mui/system";
import React from "react";
import { TabItem } from "./TabItem";
import { useNavigate, useParams } from "react-router-dom";
import { PathNames } from "application/routes";
import { RoutesParam } from "application/routes/constants";
import { TeamInfo } from "./TeamInfo";
import { RequestHistoryInfo } from "./RequestHistoryInfo";

const NavBar = () => {
  const { name } = useParams();
  const navigate = useNavigate();
  const requestHistory = name === RoutesParam.requestHistory;
  const team = name === RoutesParam.team;
  const subscriptionHistory = name === RoutesParam.subscriptionHistory;
  return (
    <Box display="flex" justifyContent="space-between" mb={2.5}>
      <Box display="flex">
        <TabItem
          label="Requests History "
          count={34}
          active={requestHistory}
          onClick={() =>
            navigate(
              `${PathNames.teamManagement}/${RoutesParam.requestHistory}`
            )
          }
        />
        <TabItem
          label="Team"
          count={34}
          active={team}
          onClick={() =>
            navigate(`${PathNames.teamManagement}/${RoutesParam.team}`)
          }
        />
        <TabItem
          label="Subscription History"
          count={34}
          active={subscriptionHistory}
          onClick={() =>
            navigate(
              `${PathNames.teamManagement}/${RoutesParam.subscriptionHistory}`
            )
          }
        />
      </Box>
      {requestHistory && <RequestHistoryInfo />}
      {team && <TeamInfo />}
    </Box>
  );
};

export default NavBar;

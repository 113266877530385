import React from 'react';
import { useAppSelector } from 'application/store';
import { filterFormSelector } from 'common/companies/store';
import { FiltersCollapse } from 'application';
import { FiltersWrapper, FilterTitleStyled, FilterGroupWrapper } from './styles';
import { CheckedFilterItem } from './CheckedFilterItem';

export const CompanyTypeFilter = () => {
  const {isGeneralContractor, isSubContractor, subContractorCategories} = useAppSelector(filterFormSelector);
  const filtersCount = (isGeneralContractor ? 1 : 0) + (isSubContractor ? 1 : 0) + subContractorCategories.length;
  return isGeneralContractor || isSubContractor ? (
    <FiltersCollapse label={'COMPANY TYPE'} count={filtersCount}>
      <FiltersWrapper>
        {isGeneralContractor && <CheckedFilterItem label={'General Contractor'}/>}
        {isSubContractor && <FilterGroupWrapper>
          <FilterTitleStyled>Subcontractor</FilterTitleStyled>
          {subContractorCategories.map((category) => (
            <CheckedFilterItem
              key={category}
              label={category}/>
          ))}
        </FilterGroupWrapper>}
      </FiltersWrapper>
    </FiltersCollapse>
  ) : null;
}

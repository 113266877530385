import { Box, styled } from '@mui/material';
import { BulkExportStatus } from 'common/largeBulkExport/store/types';
import theme from 'application/theme';

interface ExportStatusProps {
  status: BulkExportStatus;
}

export const ExportStatusWrapper = styled(Box)<ExportStatusProps>`
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  gap: ${theme.spacing(0.5)};
  padding: ${theme.spacing(0.5, 1.5)};
  align-items: center;
  background-color: ${({ status }) => getBackgroundColor(status)};
  border-radius: ${theme.spacing(4)};
`;

const getBackgroundColor = (status: BulkExportStatus) => {
  switch (status) {
    case BulkExportStatus.IN_PROGRESS:
      return theme.palette.common.orange50;
    case BulkExportStatus.FINISHED:
      return theme.palette.common.green100;
    case BulkExportStatus.FAILED:
      return theme.palette.common.red50;
    case BulkExportStatus.EXPIRED:
    default:
      return theme.palette.common.grey200;
  }
};

import React, { useEffect, useMemo, useRef, useState } from "react";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { Checkbox } from "@mui/material";
import { Box } from "@mui/system";
import theme from "application/theme";
import {
  CheckboxLabel,
  CheckboxLabelWithOverflow,
  CheckItemWrapper,
  LoaderWrapper,
} from "../styles";
import { MultiSelect, MultiSelectOptionI } from "application/components/MultiSelect";
import { ShowLessAndMore } from "common/companies/components";
import { SmallLoader } from "common/loader/SmallLoader";
import { ProductBrandI, ProductNameI } from 'common/permits/types';
import { handleErrorNotification } from 'common/errorService';
import { cloneDeep } from 'lodash';
import { PageResponseI } from 'application/types';
import { CollapseFilter } from '../../CollapseFilter';

interface ProductBrandMultiselectProps {
  typeId: number;
  brand: ProductBrandI;
  checked: boolean;
  onChangeSelected: (selectedOptions: any) => void;
  field?: ControllerRenderProps<any, any>;
  fetchProductNamesCallback: (typeId: number, brandId: number) => Promise<PageResponseI<ProductNameI>>;
}
export const ProductBrandMultiselect = ({
  typeId,
  brand,
  checked,
  field,
  onChangeSelected,
  fetchProductNamesCallback
}: ProductBrandMultiselectProps) => {
  const [loading, setLoading] = useState(false);
  const [countToDisplay, setCountToDisplay] = useState(5);
  const [options, setOptions] = useState<MultiSelectOptionI[]>([]);

  const selectedOptions = useMemo(() => {
    return brand?.names?.map(({id}) => id) || [];
  },[brand.names]);

  const fetchProductNames = async () => {
    setLoading(true);
    try {
      const response = await fetchProductNamesCallback(typeId, brand.id);
      setOptions(namesToOptions(response.content));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      setLoading(false);
    }
  }

  const optionsEndRef = useRef<HTMLDivElement | null>(null);

  const namesToOptions = (prodNames: ProductNameI[]): MultiSelectOptionI[] => {
    return prodNames ? prodNames.map(({id, name}) => ({label: name, value: id})) : [];
  }

  useEffect(() => {
    if (checked) {
      fetchProductNames();
    }
  }, [checked]);

  const handleChangeSelected = async () => {
    if(checked) {
      onChangeSelected(null);
    }else {
      onChangeSelected({
        id: brand.id,
        name: brand.name
      })
    }
  };
  const handleNamesSelectionChange = (selectedNameIds: any[]) => {
    const prodNames = options
      .filter(nameOption => selectedNameIds.some(value => value === nameOption.value))
      .map(({value, label}) => ({id: value, name: label}));
    const newBrand = cloneDeep({
      ...brand,
      names: prodNames
    });
    onChangeSelected(newBrand);
  }

  const displayedOptions = useMemo(
    () => (options?.length ? options.slice(0, countToDisplay) : []),
    [options, countToDisplay]
  );

  const handleShowMore = (showMore: boolean) => {
    if (showMore) {
      setCountToDisplay((prev) => prev + 10);
    } else {
      setCountToDisplay(5);
    }
  };

  const hasMore = useMemo(
    () => options?.length && options?.length >= countToDisplay,
    [options, countToDisplay]
  );

  useEffect(() => {
    if (optionsEndRef.current && countToDisplay > 5) {
      optionsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [countToDisplay]);

  return (
    <>
      <CheckItemWrapper>
        <Checkbox  {...field} checked={checked} onChange={handleChangeSelected} />
        <CheckboxLabel>
          <Box display="flex" gap={0.5} alignItems="center">
            <CheckboxLabelWithOverflow
              variant="caption"
              color={theme.palette.common.grey900}
            >
              {brand.name}
            </CheckboxLabelWithOverflow>
          </Box>
        </CheckboxLabel>
      </CheckItemWrapper>
      {loading ? (
        <LoaderWrapper p={1}>
          <SmallLoader size={60} width={12.5} />
        </LoaderWrapper>
      ) : checked && options?.length ? (
        <Box pl={4.5} sx={{ maxWidth: "calc(100% - 8px)" }} mt={0.5}>
          <CollapseFilter
            label={"Product name"}
            count={selectedOptions?.length}
            opened={true}
            small={true}
          >
            <Box mt={1}>
              <MultiSelect
                options={displayedOptions}
                selectedOptions={selectedOptions}
                onChangeSelected={handleNamesSelectionChange}
                height={!loading ? 180 : undefined}
              />
              {options?.length > 5 ? (
                <ShowLessAndMore
                  showAll={!hasMore}
                  setShowAll={handleShowMore}
                  fontSize={theme.spacing(1.75)}
                />
              ) : null}
            </Box>
          </CollapseFilter>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

import React from "react";
import { Typography } from "@mui/material";

import theme from "application/theme";

import noInfo from "application/assets/noInfo.png";
import { EmptyContentWrapper } from "./styles";

const EmptyTableInfo = ({ title }: { title: string }) => {
  return (
    <EmptyContentWrapper>
      <img src={noInfo} alt="No results found" />
      <Typography variant="button" color={theme.palette.common.grey800} mt={2}>
        {title}
      </Typography>
    </EmptyContentWrapper>
  );
};

export default EmptyTableInfo;

import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import theme from "application/theme";
import { RootState } from "application/store";
import CustomSelect from "application/components/CustomSelect";
import { range } from "ramda";
import { PaginationWrapper, StyledIconButton } from "./styles";
import { usePageQueryParams } from 'application/hooks/usePageQueryParams';

export interface PaginationI {
  totalElements: number;
  totalPages: number;
  numberOfElements: number;
  pageRequest: (page: number) => void;
  currentPage: number;
  pageSize?: number;
  hidePageSelector?: boolean;
}

const Pagination = ({
  totalElements,
  totalPages,
  numberOfElements,
  pageRequest,
  currentPage,
  pageSize = 10,
  hidePageSelector = false
}: PaginationI) => {
  const [page, setPage] = useState(0);
  const isLoading = useSelector((state: RootState) => state.loader.isLoading);
  const pages = useMemo(() => hidePageSelector ? [] : range(1, totalPages + 1), [totalPages]);
  const disableNext = page >= totalPages - 1;
  const disablePrev = page <= 0;
  const {setCurrentPage} = usePageQueryParams();
  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  const nextPage = () => {
    if (!disableNext) {
      setPage(page + 1);
      pageRequest(page + 1);
      setCurrentPage(page + 2);
    }
  };
  const prevPage = () => {
    if (!disablePrev) {
      setPage(page - 1);
      pageRequest(page - 1);
      setCurrentPage(page);
    }
  };
  const onSelectChange = (value: string) => {
    setPage(Number(value) - 1);
    pageRequest(Number(value) - 1);
    setCurrentPage(Number(value));
  };

  useEffect(() => {
    return () => {
      setPage(0);
    };
  }, []);

  return (
    <PaginationWrapper>
      <Box pl={2.5}>
        {totalElements && !isLoading ? (
          <Typography
            sx={{ fontSize: theme.spacing(1.5), lineHeight: "140%" }}
            color={theme.palette.common.grey800}
          >
            {`${1 + page * pageSize}-${Math.min(page * pageSize + numberOfElements, totalElements)}`} of{" "}
            {totalElements} results
          </Typography>
        ) : (
          <></>
        )}
      </Box>
      {totalElements > 0 && (
        <Box display="flex">
          {!hidePageSelector && (
            <CustomSelect
              label={`Page ${(page + 1).toString()}`}
              values={pages}
              selected={page + 1}
              onChange={(value) => onSelectChange(value)}
              height={totalPages * 24}
              maxHeight={166}
              top
              itemWidth={theme.spacing(11.25)}
              fontWeight={400}
            />
          )}
          <Box display="flex">
            <StyledIconButton onClick={prevPage} disabled={disablePrev}>
              <NavigateBefore
                sx={{
                  color: disablePrev
                    ? theme.palette.common.grey400
                    : theme.palette.common.grey800,
                }}
              />
            </StyledIconButton>
            <StyledIconButton onClick={nextPage} disabled={disableNext}>
              <NavigateNext
                sx={{
                  color: disableNext
                    ? theme.palette.common.grey400
                    : theme.palette.common.grey800,
                }}
              />
            </StyledIconButton>
          </Box>
        </Box>
      )}
    </PaginationWrapper>
  );
};
export default Pagination;

import React, { useState } from 'react';
import { Box } from '@mui/system';
import { Button } from 'application';
import { ExportPermitModal } from '../../ExportPermitModal';

export const ExportPermitDataButton = () => {
  const [showExportModal, setShowExportModal] = useState<boolean>(false);

  const handleClick = () => {
    setShowExportModal(true)
  }
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Button
        label="Export permit data"
        onClick={handleClick}
        height={5.5}
        type="submit"
      />
      <ExportPermitModal
        isOpen={showExportModal}
        onClose={() => setShowExportModal(false)}
      />
    </Box>
  );
}

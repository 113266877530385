import { Box, Checkbox, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/system";
import theme from "application/theme";

export const IconButtonStyled = styled(IconButton)`
  &:hover {
    background-color: transparent;
  }
`;

export const ButtonsWrapper = styled(Box)`
  border: 1px solid ${theme.palette.common.grey400};
  border-radius: ${theme.spacing(1)};
  width: fit-content;
`;

export const StyledCheckbox = styled(Checkbox)`
  color: ${theme.palette.common.grey500};
  &.MuiCheckbox-root:hover {
    background-color: transparent;
  }
  &.Mui-checked {
    color: ${theme.palette.common.blue700};
  }
`;

export const NavbarItemWrapper = styled(Box)<{ active: number }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: ${theme.spacing(2)};
  padding: ${theme.spacing(0, 0.5, 2.25, 0.5)};
  border-bottom: ${(props) =>
    props.active ? `2px solid ${theme.palette.common.blue700}` : "none"};
`;

export const NavbarBadge = styled(Box)<{ active: number }>`
  background-color: ${(props) =>
    props.active ? theme.palette.common.blue50 : theme.palette.common.grey200};
  border-radius: ${theme.spacing(2)};
  padding: ${theme.spacing(0, 1)};
`;

export const NavbarBadgeText = styled(Typography)<{ active: number }>`
  font-size: ${theme.spacing(1.5)};
  font-weight: 500;
  font-family: Rubik;
  line-height: ${theme.spacing(2.75)};
  color: ${(props) =>
    props.active ? theme.palette.common.blue700 : theme.palette.common.grey600};
`;

export const HubspotModalContentWrapper = styled(Box)`
  position: relative;
  max-height: ${theme.spacing(60)};
  overflow-y: auto;
`;

export const ListHideEffect = styled(Box)`
  height: ${theme.spacing(3.75)};
  width: 100%;
  position: fixed;
  bottom: ${theme.spacing(11.5)};
  left: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
`;

export const CreditChargedText = styled("span")`
  font-family: Rubik;
  font-size: ${theme.spacing(2)};
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;
  color: ${theme.palette.common.orange500};
`;


export const TypographyStyled = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: ${theme.spacing(31.25)};
`;


import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";

import { useAppDispatch, useAppSelector } from "application/store";

import { FormProvider } from "react-hook-form";


import SavedFiltersCollapse from "./SavedFiltersCollapse";
import ReputationScoreCollapse from "./ReputationScoreCollapse";
import NumberOfEmployeesCollapse from "./NumberOfEmployeesCollapse";
import YearsInBusinessCollapse from "./YearsInBusinessCollapse";
import ServiceTypeCollapse from "./ServiceTypeCollapse";
import AnnualRevenueCollapse from "./AnnualRevenueCollapse";
import { SaveFilterSearchModal } from "common";
import { Box } from "@mui/material";
import { CompanyFilterDataI } from "integration/api/companies/types";
import { CollapseWrapper, FilterWrapper, StickyFooterWrapper } from "./styles";
import { FilterHeader } from "./components";
import { handleGetFiltersList } from "../store/actions";
import theme from "application/theme";
import { Button } from "application";
import CompanyTypeCollapse from "./CompanyTypeCollapse";
import SkipExportedCompanies from "./SkipExportedCompanies";
import TagsCollapse from "./TagsCollapse";
import {
  filterFormSelector,
  filterInitialValues,
  savedSearchesSelector,
  setSelectedSearch,
} from "../store";
import { sdrProfileSelector } from "common/sdrProfile/store";
import PermitFilters from "./PermitFilters";
import LocationCollapse from "./LocalionCollapse";
import ShowNotGoodFit from "./ShowNotGoodFit";
import useUpdateLastActivityDate from 'application/hooks/useUpdateLastActivityDate';
import { useCompaniesSearch } from '../hooks/useCompaniesSearch';
import { useCompaniesPageContext } from '../hooks/useCompaniesPageContext';
import { getCountCompaniesFilters } from '../utils';

interface CompaniesFilterProps {
  scrollToTop: () => void;
}
const CompaniesFilter = forwardRef<HTMLDivElement, CompaniesFilterProps>(
  ({ scrollToTop }, ref) => {
  const dispatch = useAppDispatch();
  const [isSaveFiltersModalOpened, setIsSaveFiltersModalOpened] = useState(false);
  const { sdrProfile } = useAppSelector(sdrProfileSelector);
  const { updateLastActivityDate } = useUpdateLastActivityDate();
  const { searchForCompanies } = useCompaniesSearch();
  const savedFilters = useAppSelector(savedSearchesSelector);
  const filterFormData = useAppSelector(filterFormSelector);
  const {pageSize} = useCompaniesSearch();
  const isMounted = useRef(true);

  const {companiesFilterForm : methods} = useCompaniesPageContext();

  useEffect(() => {
    if (sdrProfile.organizationId && isMounted.current) {
      dispatch(handleGetFiltersList());
      isMounted.current = false;
    }
  }, [sdrProfile.organizationId, dispatch]);

  const filtersCount = useMemo(() => getCountCompaniesFilters(methods.watch()), [methods.watch()])

  useEffect(() => {
    const isUpdate = Object.keys(savedFilters)?.length === 0;
    !isUpdate && methods.reset(savedFilters.filterValue);
  }, [savedFilters]);

  useEffect(() => {
    methods.reset(filterFormData);
  }, []);

  const handleApply = (data: CompanyFilterDataI) => {
    searchForCompanies(data, 0, pageSize);
  };

  const handleClear = () => {
    methods.reset(filterInitialValues);
    dispatch(setSelectedSearch({}));
  };

  return (
    <FilterWrapper>
      <FormProvider {...methods}>
        <FilterHeader count={filtersCount} onSaveFilters={() => setIsSaveFiltersModalOpened(true)} />
        <CollapseWrapper>
          <Box display={"flex"} flexDirection={"column"} gap={4}>
            <SavedFiltersCollapse
              label="SAVED SEARCH"
              scrollTo={scrollToTop}
            />
            <SkipExportedCompanies label="Skip exported companies" />
            <ServiceTypeCollapse label="SERVICE TYPE" />
            <CompanyTypeCollapse label="COMPANY TYPE" />
            <PermitFilters label="Has permit" />
            <TagsCollapse label="TAGS" />
            <LocationCollapse label="LOCATION" />
            <ReputationScoreCollapse label="VISIBILITY SCORE" />
            <NumberOfEmployeesCollapse label="# OF EMPLOYEES" />
            <AnnualRevenueCollapse label="REVENUE" />
            <YearsInBusinessCollapse label="YEARS IN BUSINESS" />
            <ShowNotGoodFit label={"Show only not a good fit"} />
          </Box>

          <StickyFooterWrapper>
            <Button
              label="Clear all"
              height={5.5}
              width={theme.spacing(15)}
              type="button"
              onClick={handleClear}
            />
            <Button
              label="Apply"
              height={5.5}
              width={theme.spacing(15)}
              type="submit"
              onClick={methods.handleSubmit(handleApply)}
            />
          </StickyFooterWrapper>
        </CollapseWrapper>
        <SaveFilterSearchModal open={isSaveFiltersModalOpened} setOpen={setIsSaveFiltersModalOpened} />
      </FormProvider>
    </FilterWrapper>
  );
});

export default CompaniesFilter;

import React from "react";

import { Controller, useFormContext } from "react-hook-form";

import { Checkbox } from "@mui/material";
import { CheckboxLabel, CheckboxLabelWithOverflow, FormControlLabelStyled } from "../styles";
import { CustomTooltip } from "application";
import { Box } from "@mui/system";
import LocationSelect from "./LocationSelect";
import theme from "application/theme";

interface Props {
  fieldNameInclude: string;
  fieldNameExclude: string;
  value: string;
  label: string;
  tooltip?: boolean;
  iconButton?: boolean;
}
const MultipleController = ({
  fieldNameInclude,
  fieldNameExclude,
  value,
  label,
  tooltip,
  iconButton = false,
}: Props) => {
  const { control, getValues, setValue } = useFormContext();
  const tooltipTitle =
    "we didn't indentify company as residential or commercial yet";

  const handleClick = (value: string | null) => {
    if (value === "+") {
      const selectedOptions = getValues(fieldNameInclude) || [];
      const updatedOptions = selectedOptions.includes(label)
        ? selectedOptions.filter((option: string) => option !== label)
        : [...selectedOptions, label];
      setValue(fieldNameInclude, updatedOptions);
    } else if (value === "-") {
      const selectedOptions = getValues(fieldNameExclude) || [];
      const updatedOptions = selectedOptions.includes(label)
        ? selectedOptions.filter((option: string) => option !== label)
        : [...selectedOptions, label];
      setValue(fieldNameExclude, updatedOptions);
    }
  };
  return (
    <Controller
      key={fieldNameInclude}
      name={fieldNameInclude}
      control={control}
      defaultValue={[]}
      render={({ field }) => (
        <FormControlLabelStyled
          control={
            iconButton ? (
              <LocationSelect
                name={label}
                isSelected={[
                  getValues(fieldNameInclude) || [],
                  getValues(fieldNameExclude) || [],
                ]}
                onClick={handleClick}
              />
            ) : (
              <Checkbox
                {...field}
                checked={field.value.includes(value)}
                onChange={() => handleClick(value)}
              />
            )
          }
          label={
            <CheckboxLabel>
              <Box display="flex" gap={0.5} alignItems="center">
                <CheckboxLabelWithOverflow
                  variant="caption"
                  color={theme.palette.common.grey900}
                >
                  {label}
                </CheckboxLabelWithOverflow>
                {tooltip ? <CustomTooltip title={tooltipTitle} /> : <></>}
              </Box>
            </CheckboxLabel>
          }
        />
      )}
    />
  );
};

export default MultipleController;

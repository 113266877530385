import React from 'react';
import { Box } from "@mui/system";
import theme from "application/theme";
import { UserPhoto } from "application";
import { Typography } from "@mui/material";
import { TypographyStyled } from "../styles";
import ExportedType from "./ExportedType";

interface Props {
  exportedEmployees: {
    id: number;
    firstName?: string | null;
    lastName?: string | null;
    photoUrl: string | null;
    title: string | null;
    state: string | null;
    city: string | null;
    country: string | null;
    emailExported: boolean;
    phoneExported: boolean;
  }[];
}

export const ExportedEmployeesTable = ({ exportedEmployees }: Props) => {
  return (
    <>
      {exportedEmployees.map((contact) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height={theme.spacing(7.5)}
          p={theme.spacing(0, 2.5)}
          key={contact.id}
        >
          <Box display="flex" alignItems="center">
            <UserPhoto
              photoUrl={contact.photoUrl}
              id={contact.id}
              firstName={contact.firstName || ""}
              lastName={contact.lastName || ""}
            />
            <Box display={"flex"} flexDirection={"column"} ml={2} mr={2}>
              <Typography
                variant="caption"
                color={theme.palette.common.grey800}
              >
                {contact.firstName} {contact.lastName}
              </Typography>
              <TypographyStyled
                variant="caption"
                color={theme.palette.common.grey600}
              >
                {contact.title}
              </TypographyStyled>
            </Box>
          </Box>
          <Typography
            variant="caption"
            color={theme.palette.common.grey800}
          >
            <Box display={"flex"} flexDirection={"column"} mr={2}>
              <Typography
                variant="caption"
                color={theme.palette.common.grey800}
              >
                {contact.city}
              </Typography>
              <Typography
                variant="caption"
                color={theme.palette.common.grey600}
              >
                {contact.state},{contact.country}
              </Typography>
            </Box>
          </Typography>
          <ExportedType
            exportedPhone={contact.phoneExported}
            exportedEmail={contact.emailExported}
          />
        </Box>
      ))}
    </>
  )
}

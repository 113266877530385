import React, { FC, useEffect } from "react";
import { Snackbar, Alert, SnackbarProps } from "@mui/material";

type Props = {
  type: "error" | "success" | "warning" | "info";
  message: string;
};
type State = {
  open?: boolean;
  vertical: "top" | "bottom";
  horizontal: "center" | "left" | "right";
} & SnackbarProps;

const initialState: State = {
  open: false,
  vertical: "top",
  horizontal: "right",
};

export const Notify: FC<Props> = ({
  type = "success",
  message = "",
  ...props
}: Props) => {
  const [state, setState] = React.useState<State>(initialState);

  useEffect(() => {
    setState({
      ...initialState,
      open: message.length > 1,
    });
  }, [message]);

  const handleClose = () => {
    setState(initialState);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: state.vertical, horizontal: state.horizontal }}
      open={state.open}
      key={state.vertical + state.horizontal}
      autoHideDuration={5000}
      {...props}
    >
      <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

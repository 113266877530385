import React from "react";

import { useSelector } from "react-redux";

import { HubspotIntegrationStatus, IOrganizationForm } from "./store/types";

import {
  SelectComponent,
  FormInput,
  typeOfOrganisationOptions,
  typeOfOrganisationConst,
} from "application";

import { timezoneSelector } from "common";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import theme from "application/theme";

export const OrganizationForm = ({
  errors,
  register,
  watch,
  dirtyFields,
}: IOrganizationForm) => {
  const timezone = useSelector(timezoneSelector);
  return (
    <>
      <FormInput
        name="name"
        placeholder="Organization name"
        label="Organization name"
        error={errors.companyName?.message}
        register={register("companyName")}
        value={watch("companyName")}
        isValid={dirtyFields.companyName}
      />
      <SelectComponent
        label="Type of organization"
        options={typeOfOrganisationOptions}
        name="organizationType"
        defaultValue={
          watch("organizationType") || typeOfOrganisationConst.MARKETING
        }
        placeholder="Select Type of organization"
        error={errors.organizationType?.message}
        register={register("organizationType")}
      />
      <FormInput
        name="contactPerson"
        placeholder="Contact Person"
        label="Contact Person"
        error={errors.contactPerson?.message}
        register={register("contactPerson")}
        value={watch("contactPerson")}
        isValid={dirtyFields.contactPerson}
      />
      <FormInput
        name="jobPosition"
        placeholder="Job Position"
        label="Job Position"
        error={errors.jobPosition?.message}
        register={register("jobPosition")}
        value={watch("jobPosition")}
        isValid={dirtyFields.jobPosition}
      />
      <FormInput
        name="location"
        placeholder="Location"
        label="Location"
        error={errors.location?.message}
        register={register("location")}
        value={watch("location")}
        isValid={dirtyFields.location}
      />
      <SelectComponent
        placeholder="Select Time Zone"
        label="Time Zone"
        options={timezone}
        defaultValue={"+14:00"}
        name="timezone"
        error={errors.timezone?.message}
        register={register("timezone")}
      />
      <FormInput
        name="phone"
        placeholder="+380 935019296"
        label="Phone"
        error={errors.phone?.message}
        register={register("phone")}
        value={watch("phone")}
        isValid={dirtyFields.phone}
      />
      <FormInput
        name="emailContactPerson"
        type="email"
        placeholder="Rayna.Botosh@example.com"
        label="Email Contact Person"
        error={errors.emailContactPerson?.message}
        register={register("emailContactPerson")}
        value={watch("emailContactPerson")}
        isValid={dirtyFields.emailContactPerson}
      />
      <FormInput
        name="website"
        placeholder="Enter link to website"
        label="Company Website (optional)"
        error={errors.website?.message}
        register={register("website")}
        value={watch("website")}
        isValid={dirtyFields.website}
      />

      {watch("hubSpotIntegrationStatus") ===
      HubspotIntegrationStatus.INTEGRATED ? (
        <Box>
          <Typography variant="caption" sx={{ display: "block" }} mb={2}>
            Hubspot Integration
          </Typography>
          <Typography
            variant="overline"
            color={theme.palette.common.grey900}
            mr={1.5}
          >
            Added
          </Typography>
          <Typography
            variant="overline"
            color={theme.palette.common.blue800}
            fontWeight={500}
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() =>
              window.open(
                watch("hubspotRemoveAppUrl") || "#",
                "_blank",
                "noreferrer"
              )
            }
          >
            Remove
          </Typography>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

import React, { useEffect, useMemo } from "react";
import { Box } from "@mui/system";
import theme from "application/theme";

import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { StyledCheckbox } from "../../../styles";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "application/store";
import {
  bulkExportSelector,
  setSelectedPermits,
  setTotalSelectedPermits,
} from "common/bulkExport/store";
import { ExportPermitI } from "common/bulkExport/store/types";
import { BinIcon } from "application/assets/BinIcon";
import { sdrProfileSelector } from "common/sdrProfile/store";
import { BulkExportTableWrapper } from "./BulkExportTableWrapper";
import useBulkExport from "../../../useBulkExport";
import { PageResponseI } from 'application/types';
import { usePageQueryParams } from 'application/hooks/usePageQueryParams';

const tableHead = ["Description", "Status", ""];

interface Props {
  exportPermits: PageResponseI<ExportPermitI>;
}
export const PermitBulkExportTable = ({
                                  exportPermits,
                                }: Props) => {
  const {
    fetchNewPermitsPage,
    fetchAllPermitsPage,
    fetchExportedPermitsPage,
    deletePermitFromBulkExport,
  } = useBulkExport();

  const dispatch = useAppDispatch();
  const {
    name: currentTab
  } = useParams();
  const { sdrProfile } = useAppSelector(sdrProfileSelector);
  const location = useLocation();
  const {currentPage} = usePageQueryParams();

  const { selectedPermits, totalSelectedPermits } = useAppSelector(bulkExportSelector);

  const { totalElements, totalPages, numberOfElements, content } =
    exportPermits;

  const pageRequest = (page: number) => {
    currentTab === "new" && fetchNewPermitsPage(page);
    currentTab === "all" && fetchAllPermitsPage(sdrProfile.organizationId, page);
    currentTab === "exported" && fetchExportedPermitsPage(sdrProfile.organizationId, page);
  };

  const handleDeletePermit = async (permitId: number) => {
    await deletePermitFromBulkExport(permitId);
    const selectedPermits = totalSelectedPermits.filter((item: ExportPermitI) => item.id !== permitId);
    dispatch(setTotalSelectedPermits(selectedPermits));
  };

  useEffect(() => {
    const selectedOnPage = content.filter((item: ExportPermitI) => {
      return totalSelectedPermits.some((selectedItem: ExportPermitI) => item.id === selectedItem.id);
    });
    dispatch(setSelectedPermits(selectedOnPage))
  }, [content, totalSelectedPermits])

  const isAllSelectedOnPage = useMemo(() => {
    return selectedPermits.length === content.length;
  }, [selectedPermits, content]);

  const onSelectAll = () => {
    if (isAllSelectedOnPage) {
      const allExcludeCompaniesOnPage = totalSelectedPermits.filter((item: ExportPermitI) => {
        return !content.some((selectedItem: ExportPermitI) => item.id === selectedItem.id);
      });
      dispatch(setTotalSelectedPermits(allExcludeCompaniesOnPage));
    } else {
      const unselectedCompanies = content.filter((item: ExportPermitI) => {
        return !totalSelectedPermits.some((selectedItem: ExportPermitI) => item.id === selectedItem.id);
      });
      dispatch(setTotalSelectedPermits([...totalSelectedPermits, ...unselectedCompanies]));
    }
  };

  const handleSelectPermit = (permitId: number) => {
    const alreadySelected = totalSelectedPermits.filter((e: ExportPermitI) => e.id === permitId);
    if (alreadySelected.length > 0) {
      dispatch(setTotalSelectedPermits(totalSelectedPermits.filter((e: ExportPermitI) => e.id !== permitId)));
    } else {
      const selectedItem = content?.filter((e: ExportPermitI) => e.id === permitId);
      dispatch(setTotalSelectedPermits([...totalSelectedPermits, selectedItem[0]]));
    }
  }
  const permitIsSelected = (permitId: number) => {
    return totalSelectedPermits.filter((e: ExportPermitI) => e.id === permitId).length > 0;
  }

  return (
    <BulkExportTableWrapper totalElements={totalElements}
                            numberOfElements={numberOfElements}
                            totalPages={totalPages}
                            pageRequest={pageRequest}
                            contentIsEmpty={!content?.length}>
      <TableHead sx={{ background: theme.palette.common.grey200 }}>
        <TableRow sx={{ height: theme.spacing(6.25) }}>
          <TableCell key={"check"}>
            <Box display="flex" alignItems="center">
              {content?.length ? (
                <StyledCheckbox
                  onClick={onSelectAll}
                  checked={isAllSelectedOnPage}
                />
              ) : <></>}
            </Box>
          </TableCell>
          {tableHead.map((title) => (
            <TableCell key={title}>
              <Typography
                variant="body2"
                color={theme.palette.common.grey800}
              >
                {title}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {content?.length ? (
          content.map((row: ExportPermitI) => (
            <TableRow
              key={row.id}
              sx={{
                height: theme.spacing(7.5),
              }}
            >
              <TableCell width={'auto'}>
                <StyledCheckbox checked={permitIsSelected(row.id)} onClick={() => handleSelectPermit(row.id)}/>
              </TableCell>
              <TableCell width={'45%'}>
                <Typography
                  variant="caption"
                  color={theme.palette.common.grey800}
                >
                  {row.description ?? "-"}
                </Typography>
              </TableCell>

              <TableCell width={'45%'}>
                <Typography
                  variant="caption"
                  color={theme.palette.common.grey800}
                >
                  {row.statusNormalized ? `${row.statusNormalized}` : ""}
                </Typography>
              </TableCell>
              <TableCell align='right' sx={{ width: '45px' }}>
                <Box
                  onClick={() => handleDeletePermit(row.id)}
                  sx={{ cursor: "pointer" }}
                >
                  <BinIcon
                    color={theme.palette.common.grey600}
                    width="20"
                    height="20"
                  />
                </Box>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <></>
        )}
      </TableBody>
    </BulkExportTableWrapper>
  )
}

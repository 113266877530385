import { Box, Typography, styled } from "@mui/material";

import theme from "application/theme";

export const ExportedLabel = styled(Box)`
  display: flex;
  height: ${theme.spacing(3.5)};
  padding: ${theme.spacing(0.25, 1.5)};
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing(0.75)};
  border-radius: ${theme.spacing(2)};
  background: ${theme.palette.common.green100};
`;

export const StyledDot = styled(Box)`
  border-radius: 50%;
  width: ${theme.spacing(0.75)};
  height: ${theme.spacing(0.75)};
  background-color: ${theme.palette.common.green900};
`;

export const RatingWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${theme.spacing(1)};
  width: ${theme.spacing(16)};
`;

export const GeneralInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${theme.spacing(0.5)};
  flex-shrink: 0;
`;

export const TypographyStyled = styled(Typography)`
  color: ${theme.palette.common.grey900};
  white-space: initial;
  owerflow-wrap: brake-all;
  width: 100%;
`;

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  companiesSlice,
  dictionarySlice,
  errorSlice,
  loaderSlice,
  loadingSlice,
  sdrSlice,
  inAppNotificationSlice,
  tutorialSlice,
} from "common";
import { authSlice } from "common/auth/store/authStore";
import { bulkExportSlice } from "common/bulkExport/store";
import { notificationsSlice } from "common/notificationsPage/store";
import { organizationsSlice } from "common/organization/store";
import { responseSlice } from "common/responseService";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { largeBulkExportSlice } from 'common/largeBulkExport/store';
import { permitsSlice } from '../common/permits/store';

const reducer = combineReducers({
  auth: authSlice.reducer,
  error: errorSlice.reducer,
  loading: loadingSlice.reducer,
  dictionary: dictionarySlice.reducer,
  companies: companiesSlice.reducer,
  loader: loaderSlice.reducer,
  bulkExport: bulkExportSlice.reducer,
  organizations: organizationsSlice.reducer,
  sdr: sdrSlice.reducer,
  response: responseSlice.reducer,
  notifications: notificationsSlice.reducer,
  largeBulkExport: largeBulkExportSlice.reducer,
  inAppNotification: inAppNotificationSlice.reducer,
  tutorial: tutorialSlice.reducer,
  permits: permitsSlice.reducer,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

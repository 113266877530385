import React, { useEffect } from 'react';
import { FilterBodyWrapper, FilterGroupsWrapper } from './styles';
import { SavedPermitFilters } from './SavedPermitFilters';
import { PermitFiltersGroup } from './PermitFiltersGroup';
import { CompanyFiltersGroup } from './CompanyFiltersGroup';
import { usePermitFiltersContext } from 'common/permits/hooks/usePermitFiltersContext';
import { FormProvider } from 'react-hook-form';

export const FiltersBody = () => {
  const {
    permitFilterForm,
    companyFilterForm} = usePermitFiltersContext();
  return (<FilterBodyWrapper>
    <SavedPermitFilters/>
    <FilterGroupsWrapper>
      <FormProvider {...permitFilterForm}>
        <PermitFiltersGroup/>
      </FormProvider>
      <FormProvider {...companyFilterForm}>
        <CompanyFiltersGroup/>
      </FormProvider>
    </FilterGroupsWrapper>
  </FilterBodyWrapper>)
}
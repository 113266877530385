import React from "react";

export interface ViewDetailsStateI {
  isOpenCompanyInfo: boolean;
  isOpenPermitInfo: boolean;
  setIsOpenCompanyInfo: (isOpen: boolean) => void;
  setIsOpenPermitInfo: (isOpen: boolean) => void;
}

export const ViewDetailsContext = React.createContext<ViewDetailsStateI>(
  {} as ViewDetailsStateI
);

export const ViewDetailsContextProvider = (props: any) => {
  const [isOpenInfo, setIsOpenInfo] = React.useState({
    isOpenCompanyInfo: false,
    isOpenPermitInfo: false,
  });
  const setIsOpenCompanyInfo = (isOpen: boolean) => {
    setIsOpenInfo({ isOpenPermitInfo: false, isOpenCompanyInfo: isOpen });
  }
  const setIsOpenPermitInfo = (isOpen: boolean) => {
    setIsOpenInfo({ isOpenCompanyInfo: false, isOpenPermitInfo: isOpen });
  }

  return (
    <ViewDetailsContext.Provider
      value={{
        isOpenCompanyInfo: isOpenInfo.isOpenCompanyInfo,
        isOpenPermitInfo: isOpenInfo.isOpenPermitInfo,
        setIsOpenCompanyInfo,
        setIsOpenPermitInfo
      }}
    >
      {props.children}
    </ViewDetailsContext.Provider>
  );
};

import {
  PageResponseI,
  RangeValueI,
  SimpleArrayResponseI,
  ThunkDispatchT,
  VoidThunk,
} from "application/types";
import { hideLoader, showLoader } from "common/loaderModal";
import { handleParallelPromises } from "application/utils/promiseUtils";
import {
  addPermitToBulkExport,
  exportPermitsToCsv,
  getAllDwellingTypes,
  getAllPermitTags,
  getAllPermitTypes,
  getPermitRangeValues,
  getSinglePermitById,
  getSquareFeetRangeValues,
} from "integration/api/permits";
import {
  setCompaniesInfo,
  setCompaniesLoading,
  setCompanyMapView,
  setDwellingTypes,
  setFoundCompanies,
  setFoundPermits,
  setMapIsLoading,
  setPermitMapView,
  setPermitTags,
  setPermitTypes,
  setPermitValueRange,
  setPermitsInfo,
  setPermitsLoading,
  setPermitProductTypes,
  setSearchChanged,
  setSquareFeetRange,
  setTooltipLoading,
  setPermitInfoLoading,
  setSelectedPermit,
  setFilterFormData,
  setSavedSearches,
  setSelectedSavedSearch,
} from "./index";
import { handleErrorNotification } from "common/errorService";
import {
  CompaniesTooltipResponse,
  CoordinatesContent,
  PermitWithCompanyFilterDataI,
  PermitsTooltipResponse,
  ProductTypeResponse,
  PermitExportResponseI,
  ZoomedAreaI,
  SinglePermitExportRequestI,
  MultiplePermitsExportRequestI,
  PermitInfoExtendedI,
} from "../types";
import {
  deleteSavedFilteredSearch,
  getAllTags,
  getCompaniesInfoById,
  getCompanyPermitRangeValues,
  getCompanyProductTypes,
  getSubContractorCategories,
  searchCompaniesByPermitFilters,
  searchCompaniesMapView,
} from "integration/api/companies";

import {
  getPermitProductType,
  getPermitsInfoById,
  searchPermitsByCompanyFilters,
  searchPermitsMapView,
} from "integration/api/permits";

import {
  addZoomedAreaToFilterData,
  mappingMapResponse,
  prepareCompanyWithPermitFiltersToSearch,
} from "../utils";
import {
  setCompanyProductTypes,
  setPermitRangeValues,
  setSubContractorCategories,
  setTags,
} from "common/companies/store";
import { mapPermitRangeValuesResponse } from "common/companies/store/utils";
import { downloadFileFromS3 } from 'application/utils/fileUtils';
import { cloneDeep } from 'lodash';
import { getSavedCompanyAndPermitFilters, saveCompanyAndPermitFilters } from 'integration/api/savedSearches';

export const handleGetPrepopulatedFilterValues = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    const promises = [
      getAllPermitTags(),
      getAllPermitTypes(),
      getAllDwellingTypes(),
      getSquareFeetRangeValues(),
      getPermitRangeValues(),
      getAllTags(),
      getSubContractorCategories(),
      getCompanyPermitRangeValues(),
      getPermitProductType(),
      getCompanyProductTypes(),
    ];
    const actions = [
      (response: SimpleArrayResponseI<string>) =>
        dispatch(setPermitTags(response.content)),
      (response: SimpleArrayResponseI<string>) =>
        dispatch(setPermitTypes(response.content)),
      (response: SimpleArrayResponseI<string>) =>
        dispatch(setDwellingTypes(response.content)),
      (response: RangeValueI) => dispatch(setSquareFeetRange(response)),
      (response: RangeValueI) => dispatch(setPermitValueRange(response)),
      (response: string[]) => dispatch(setTags(response)),
      (response: string[]) => dispatch(setSubContractorCategories(response)),
      (response: any) =>
        dispatch(setPermitRangeValues(mapPermitRangeValuesResponse(response))),
      (response: ProductTypeResponse) =>
        dispatch(setPermitProductTypes(response.content)),
      (response: ProductTypeResponse) =>
        dispatch(setCompanyProductTypes(response.content)),
    ];
    handleParallelPromises(
      promises,
      actions,
      () => dispatch(hideLoader()),
      (error: any) => handleErrorNotification(error)
    );
  };
};

export const handleSearchCompaniesByPermitFilters = (
  filterData: PermitWithCompanyFilterDataI,
  page: number,
  size = 20,
  searchChanged?: boolean,
  zoomedArea?: ZoomedAreaI,
  withLoader?: boolean
) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(handleSearchCompanies({
      filterData,
      page,
      size,
      searchChanged,
      zoomedArea,
      withGlobalLoader: true,
      withLoader,
    }));
  };
};

export const handleSearchPermitsByCompanyFilters = (
  filterData: PermitWithCompanyFilterDataI,
  page: number,
  size = 20,
  searchChanged?: boolean,
  zoomedArea?: ZoomedAreaI,
) => {
  return async (dispatch: ThunkDispatchT) => {
    return dispatch(handleSearchPermits({
      filterData,
      page,
      size,
      searchChanged,
      zoomedArea,
      withGlobalLoader: true,
    }));
  };
};

export const handleViewOnMapSearch = (
  filterData: PermitWithCompanyFilterDataI
) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(setMapIsLoading(true));
    filterData = prepareCompanyWithPermitFiltersToSearch(filterData);
    try {
      const permitResponse = await searchPermitsMapView(filterData);
      const companiesResponse = await searchCompaniesMapView(filterData);
      dispatch(setPermitMapView(mappingMapResponse(permitResponse)));
      dispatch(setCompanyMapView(mappingMapResponse(companiesResponse)));
      dispatch(setMapIsLoading(false));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(setMapIsLoading(false));
    }
  };
};

export const handleSearchPermitsAndCompaniesOnBackground = (
  filterData: PermitWithCompanyFilterDataI,
  page: number,
  size = 20,
  searchChanged?: boolean,
  zoomedArea?: ZoomedAreaI,
) => {
  return async (dispatch: ThunkDispatchT) => {
    await dispatch(handleSearchPermits({
      filterData,
      page,
      size,
      searchChanged,
      zoomedArea,
      withGlobalLoader: true,
    }));
    return dispatch(handleSearchCompanies({
      filterData,
      page,
      size,
      searchChanged,
      zoomedArea,
      withGlobalLoader: false,
    }));
  };
};

export const handleSearchCompaniesAndPermitsOnBackground = (
  filterData: PermitWithCompanyFilterDataI,
  page: number,
  size = 20,
  searchChanged?: boolean,
  zoomedArea?: ZoomedAreaI
) => {
  return async (dispatch: ThunkDispatchT) => {
    await dispatch(handleSearchCompanies({
      filterData,
      page,
      size,
      searchChanged,
      zoomedArea,
      withGlobalLoader: true,
    }));
    return dispatch(handleSearchPermits({
      filterData,
      page,
      size,
      searchChanged,
      zoomedArea,
      withGlobalLoader: false,
    }))
  };
};
interface SearchParams {
  filterData: PermitWithCompanyFilterDataI;
  page: number;
  size: number;
  searchChanged?: boolean;
  zoomedArea?: ZoomedAreaI;
  withLoader?: boolean;
  withGlobalLoader?: boolean;
}

const handleSearchPermits = ({
  filterData, page, size = 20, searchChanged, zoomedArea, withLoader = true, withGlobalLoader
}: SearchParams
) => {
  return async (dispatch: ThunkDispatchT) => {
    if (withGlobalLoader) {
      dispatch(showLoader());
    }
    if(withLoader) {
      dispatch(setPermitsLoading(true));
    }
    filterData = addZoomedAreaToFilterData(filterData, zoomedArea);
    dispatch(setFilterFormData(cloneDeep(filterData)));
    filterData = prepareCompanyWithPermitFiltersToSearch(filterData);
    try {
      const permitsResponse = await searchPermitsByCompanyFilters(
        filterData,
        page,
        size
      );

      dispatch(setFoundPermits(permitsResponse));

    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(setPermitsLoading(false));
      if (searchChanged) dispatch(setSearchChanged(true));
      if (withGlobalLoader) {
        dispatch(hideLoader());
      }
    }
  };
};

const handleSearchCompanies = (
  {
    filterData, page, size = 20, searchChanged, zoomedArea, withGlobalLoader, withLoader = true
  }: SearchParams
) => {
  return async (dispatch: ThunkDispatchT) => {
    if (withGlobalLoader) {
      dispatch(showLoader());
    }
    if(withLoader) {
      dispatch(setCompaniesLoading(true));
    }
    filterData = addZoomedAreaToFilterData(filterData, zoomedArea);
    dispatch(setFilterFormData(cloneDeep(filterData)));
    filterData = prepareCompanyWithPermitFiltersToSearch(filterData);
    try {
      const response = await searchCompaniesByPermitFilters(
        filterData,
        page,
        size
      );
      dispatch(setFoundCompanies(response));

    } catch (error) {
      handleErrorNotification(error);
    } finally {
      if(withGlobalLoader) {
        dispatch(hideLoader());
      }
      dispatch(setCompaniesLoading(false));
      if (searchChanged) dispatch(setSearchChanged(true));
    }
  };
};

export const handleGetCompanyAndPermitMapView = (
  filterData: PermitWithCompanyFilterDataI,
  searchChanged?: boolean
) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    filterData = prepareCompanyWithPermitFiltersToSearch(filterData);
    const promises = [
      searchPermitsMapView(filterData),
      searchCompaniesMapView(filterData),
    ];
    const actions = [
      (response: PageResponseI<CoordinatesContent>) =>
        dispatch(setPermitMapView(mappingMapResponse(response))),
      (response: PageResponseI<CoordinatesContent>) =>
        dispatch(setCompanyMapView(mappingMapResponse(response))),
    ];
    handleParallelPromises(
      promises,
      actions,
      () => {
        dispatch(hideLoader());
        if (searchChanged) dispatch(setSearchChanged(true));
      },
      (error: any) => handleErrorNotification(error)
    );
  };
};

export const handleGetCompaniesCoordinates = (
  filterData: PermitWithCompanyFilterDataI,
  withLoader?: boolean
) => {
  return async (dispatch: ThunkDispatchT) => {
    if (withLoader) {
      dispatch(showLoader());
    }
    dispatch(setFilterFormData(cloneDeep(filterData)));
    filterData = prepareCompanyWithPermitFiltersToSearch(filterData);
    try {
      const response = await searchCompaniesMapView(filterData);
      dispatch(setCompanyMapView(mappingMapResponse(response)));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const handleGetCompaniesInfoById = (companyIds: string) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(setTooltipLoading(true));
    try {
      const response: CompaniesTooltipResponse = await getCompaniesInfoById(
        companyIds
      );
      dispatch(setCompaniesInfo(response.content));
      dispatch(setTooltipLoading(false));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(setTooltipLoading(false));
    }
  };
};

export const handleGetPermitsInfoById = (permitIds: string) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(setTooltipLoading(true));
    try {
      const response: PermitsTooltipResponse = await getPermitsInfoById(
        permitIds
      );
      dispatch(setPermitsInfo(response.content));
      dispatch(setTooltipLoading(false));
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(setTooltipLoading(false));
    }
  };
};

export const handleGetPermitInfoById = (id: number) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(setPermitInfoLoading(true));
    try {
      const response: PermitInfoExtendedI = await getSinglePermitById(id);
      dispatch(setSelectedPermit(response));
    } catch (error) {
      setSelectedPermit(null);
      handleErrorNotification(error);
    } finally {
      dispatch(setPermitInfoLoading(false));
    }
  }
}

export const handleExportPermits = (exportData: MultiplePermitsExportRequestI): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      const response: PermitExportResponseI = await exportPermitsToCsv(exportData);
      downloadFileFromS3(response?.s3FileUrl);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const handleAddPermitToBulkExport = (data: SinglePermitExportRequestI) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await addPermitToBulkExport(data);
      return true;
    } catch (error) {
      handleErrorNotification(error);
      return false;
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const handleSaveFilters = (name: string, filterData: PermitWithCompanyFilterDataI) => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await saveCompanyAndPermitFilters({ name, filterValue: filterData });
      return true;
    } catch (error) {
      handleErrorNotification(error);
      return false;
    } finally {
      dispatch(hideLoader());
    }
  };
}

export const handleGetSavedFilters = (): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());

    try {
      const response = await getSavedCompanyAndPermitFilters();
      dispatch(setSavedSearches(response));
    } catch (error) {
      dispatch(setSavedSearches([]));
      handleErrorNotification(error);
    } finally {
      dispatch(hideLoader());
    }
  };
};

export const handleDeleteSavedSearch = (searchId: number): VoidThunk => {
  return async (dispatch: ThunkDispatchT) => {
    dispatch(showLoader());
    try {
      await deleteSavedFilteredSearch(searchId);
    } catch (error) {
      handleErrorNotification(error);
    } finally {
      await dispatch(handleGetSavedFilters());
      await dispatch(setSelectedSavedSearch(null));
    }
  };
};

import { Box, styled } from '@mui/system';
import theme from 'application/theme';
import { Typography } from '@mui/material';

export const ModalContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing(3)};
`

export const ModalHeaderStyled = styled(Typography)`
  font-weight: 500;
  font-size: ${theme.spacing(2.5)};
  line-height: ${theme.spacing(3)};
  color: ${theme.palette.common.grey800};
  .export-count {
    color: ${theme.palette.common.orange500};
  }
`

export const DescriptionTextStyled = styled(Typography)`
  text-align: center;
  color: ${theme.palette.common.grey700};
  font-size: ${theme.spacing(1.75)};
  line-height: ${theme.spacing(2.5)};
`

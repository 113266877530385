import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ExportModal } from "../modal/ExportModal";
import { useAppDispatch, useAppSelector } from "application/store";
import { bulkExportSelector, } from "../store";
import { handleExportContacts, } from "../store/actions";
import { convertCompaniesExportData, convertEmployeesExportData } from "../store/utils";
import { ExportEmployeesI } from "../store/types";
import { sdrProfileSelector } from "common/sdrProfile/store";
import { ConnectToHubspotModal } from "common/organization/modals/ConnectToHubspotModal";
import { NavTabs } from "./NavTabs";
import useBulkExport from "../useBulkExport";
import { ExportContactsBar } from "./ExportContactsBar";
import useOrganizationProfile from "application/hooks/useOrganizationProfile";
import { useLargeBulkExportInfo } from 'common/largeBulkExport/hooks/useLargeBulkExportInfo';
import { usePageQueryParams } from 'application/hooks/usePageQueryParams';
import { ExportTab } from '../types';
import { ExportTo } from 'common/companies/types';
import { useHubspotIntegrationModal } from 'common/organization/hooks/useHubspotIntegrationModal';
import { useCompaniesAndPermitsExport } from 'common/permits/hooks/useCompaniesAndPermitsExport';

const NavBar = () => {
  const { name } = useParams();
  const dispatch = useAppDispatch();
  const {
    fetchEmployees,
    fetchCompanies,
    fetchPermits,
    isExportScopeEmployees,
    isExportScopeCompanies,
    isExportScopePermits,
    clearAllSelection } = useBulkExport();
  const { fetchPermitsBulkExportProcesses, fetchCompaniesBulkExportProcesses } = useLargeBulkExportInfo();
  const { currentPage } = usePageQueryParams();
  const newTab = name === ExportTab.NEW;
  const allTab = name === ExportTab.ALL;
  const [isOpen, setIsOpen] = useState(false);
  const { sdrProfile } = useAppSelector(sdrProfileSelector);
  const { fetchOrganizationProfile } = useOrganizationProfile();

  const { isHubspotIntegrated,
    hubspotAuthUrl,
    hubspotModalIsOpened,
    openHubspotIntegrationModal,
    closeHubspotIntegrationModal} = useHubspotIntegrationModal();
  const {exportMultiplePermitsToCsv} = useCompaniesAndPermitsExport();

  const {
    exportResult,
    newEmployees,
    allEmployees,
    exportedEmployees,
    selectedEmployees,
    selectedCompanies,
    totalSelectedCompanies,
    selectedPermits,
    totalSelectedPermits,
  } = useAppSelector(bulkExportSelector);

  const totalSelectedItems = useMemo(() => {
    return isExportScopeEmployees ? selectedEmployees
      : isExportScopeCompanies ?  totalSelectedCompanies
      :totalSelectedPermits;
  }, [isExportScopeEmployees, selectedEmployees, totalSelectedCompanies, isExportScopeCompanies, totalSelectedPermits]);

  const employeesByTab = newTab
    ? newEmployees.content
    : allTab
    ? allEmployees.content
    : exportedEmployees.content;

  const selectedEmployeesOnPage = useMemo(() => {
    const secondIds = selectedEmployees.map(
      (item) => item.externalId
    );
    return employeesByTab.filter((item: ExportEmployeesI) =>
    {
      return secondIds.includes(item.externalId)
    }
    );
  }, [selectedEmployees, employeesByTab]);

  const currentlySelectedItems = useMemo(() => {
    return isExportScopeEmployees ? selectedEmployeesOnPage
      : isExportScopeCompanies ? selectedCompanies
      : selectedPermits;
  },[selectedEmployeesOnPage, isExportScopeEmployees, selectedCompanies, isExportScopeCompanies, selectedPermits]);

  useEffect(() => {
    if (sdrProfile.organizationId) {
      fetchEmployees(sdrProfile.organizationId);
      fetchCompanies(sdrProfile.organizationId);
      fetchPermits(sdrProfile.organizationId);
      fetchOrganizationProfile(sdrProfile.organizationId);
      if (currentPage < 2) {
        fetchPermitsBulkExportProcesses(sdrProfile.organizationId, 0)
        fetchCompaniesBulkExportProcesses(sdrProfile.organizationId, 0);
      }
    }
  }, [sdrProfile.organizationId]);

  const handleDownloadCsv = () => {
    if(isExportScopePermits) {
      exportMultiplePermitsToCsv(totalSelectedPermits.map((permit) => permit.permitDataId));
      return;
    }
    const itemsToExport = isExportScopeEmployees ? convertEmployeesExportData(selectedEmployees)
      : convertCompaniesExportData(totalSelectedCompanies);
    dispatch(
      handleExportContacts({
        employeeExportInfoDtos: itemsToExport,
        organizationId: sdrProfile.organizationId,
        exportTo: ExportTo.CSV,
      })
    );
  };

  const handleDownloadHubspot = () => {
    if(isExportScopePermits) {
      console.error('Permits export to Hubspot is not supported');
      return;
    }
    if (isHubspotIntegrated) {
      const itemsToExport = isExportScopeEmployees ? convertEmployeesExportData(selectedEmployees)
        : convertCompaniesExportData(totalSelectedCompanies);
      dispatch(
        handleExportContacts({
          employeeExportInfoDtos: itemsToExport,
          organizationId: sdrProfile.organizationId,
          exportTo: ExportTo.HUBSPOT,
        })
      );
    } else {
      openHubspotIntegrationModal();
    }
  };

  useEffect(() => {
    exportResult &&
      ((exportResult?.exportResultInfos &&
        exportResult?.exportResultInfos.length) ||
        exportResult.s3FileUrl) &&
      setIsOpen(true);
  }, [exportResult]);


  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={2.5}
    >
      {!totalSelectedItems.length ? (
        <NavTabs/>
      ) : (
        <ExportContactsBar itemsSelected={currentlySelectedItems.length}
                           totalItemsSelected={totalSelectedItems.length}
                           onClearSelection={clearAllSelection}
                           onExportToCsv={handleDownloadCsv}
                           onExportToHubspot={handleDownloadHubspot}
                           showExportToHubspot={!isExportScopePermits}/>
      )}
      {isOpen ? (
        <ExportModal
          isOpen={isOpen}
          handleClose={() => {
            setIsOpen(false);
            clearAllSelection();
          }}
        />
      ) : null}
      <ConnectToHubspotModal
        isOpen={hubspotModalIsOpened}
        handleClose={closeHubspotIntegrationModal}
        onConfirmUrl={hubspotAuthUrl}
      />
    </Box>
  );
};

export default NavBar;

import React from 'react';
import { useAppSelector } from 'application/store';
import { FiltersCollapse } from 'application';
import { FilterGroupWrapper, FiltersWrapper, FilterTitleStyled } from '../components/styles';
import { permitFiltersSelector } from 'common/permits/store';
import { getArrayLength } from 'application/utils/arrayUtils';
import { CheckedFilterItem } from '../components';
import { Box } from '@mui/system';
import { ProductBrandI, ProductTypeI } from 'common/permits/types';

export const PermitProductTypesFilter = () => {
  const {productTypes} = useAppSelector(permitFiltersSelector);
  const getGeneralCount = () => {
    if(!productTypes) return 0;
    return (productTypes.length +
    productTypes.flatMap((productType) => productType.brands).length);
  }
  return getGeneralCount() > 0 ? (
    <FiltersCollapse label={'PRODUCT TYPES'} count={getGeneralCount()}>
      <FiltersWrapper>
        <FilterTitleStyled>Types:</FilterTitleStyled>
        {
          productTypes.map((productType) => (
            <ProductTypeItem productType={productType}/>
          ))
        }
      </FiltersWrapper>
    </FiltersCollapse>
  ) : null;
}

interface ProductTypeItemProps {
  productType : ProductTypeI
}
const ProductTypeItem = ({productType} : ProductTypeItemProps) => {
  return (
    <FilterGroupWrapper>
      <CheckedFilterItem label={productType.name}/>
        {
          getArrayLength(productType?.brands) > 0 ?
            <Box marginLeft={1}>
              <FilterTitleStyled>Brands:</FilterTitleStyled>
              {
                productType?.brands?.map((brand) => (
                  <ProductBrandItem productBrand={brand}/>
                ))
              }
            </Box>
            : <></>
        }
    </FilterGroupWrapper>
  )
}

const ProductBrandItem = ({productBrand} : { productBrand: ProductBrandI }) => {
  return (
    <FilterGroupWrapper>
      <CheckedFilterItem label={productBrand.name || ''}/>
      <Box marginLeft={1}>
        {
          getArrayLength(productBrand?.names) > 0 ?
            <Box marginLeft={1}>
              <FilterTitleStyled>Names:</FilterTitleStyled>
              {
                productBrand?.names?.map((name) => (
                  <CheckedFilterItem label={name.name || ''}/>
                ))
              }

            </Box>
            : <></>
        }
      </Box>
    </FilterGroupWrapper>
  )
}

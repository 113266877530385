import React from 'react';
import { useAppSelector } from 'application/store';
import { FiltersCollapse } from 'application';
import { FilterGroupWrapper, FiltersWrapper } from '../components/styles';
import { CheckedFilterItem } from '../components/CheckedFilterItem';
import { getNumberOfEmployeesLabel } from 'common/companies/companiesFilter/utils';
import { companyFiltersSelector } from 'common/permits/store';

export const NumberOfEmployeesFilter = () => {
  const {companySizeRange} = useAppSelector(companyFiltersSelector);
  return companySizeRange.length > 0 ? (
    <FiltersCollapse label={'# OF EMPLOYEES'} count={companySizeRange.length}>
      <FiltersWrapper>
        <FilterGroupWrapper>
          {companySizeRange.map((item) => (
            <CheckedFilterItem
              key={item}
              label={getNumberOfEmployeesLabel(item)}/>
          ))
          }
        </FilterGroupWrapper>
      </FiltersWrapper>
    </FiltersCollapse>
  ) : <></>;
}

import React from "react";
import { Box } from '@mui/system';
import { Button } from 'application';
import { AddToBulkIcon, ArrowOutward, CheckIcon, ExportAllIcon } from 'application/assets';
import {
  ActionItemStyled
} from 'common/companies/companiesListExtended/components/CompanyTableItem/styles';
import { Typography } from '@mui/material';
import { StyledCheckbox } from './styles';
import theme from 'application/theme';

interface TooltipActionsProps {
  onExportNow: () => void;
  onAddToBulkExport: () => void;
  onViewDetails: () => void;
  showNotGoodFit?: boolean;
  isNotGoodFit?: boolean;
  onSetNotGoodFit?: () => void;
  addedToBulk?: boolean;
}

export const TooltipActions = ({
                                 onExportNow,
                                 onAddToBulkExport,
                                 onViewDetails,
                                 showNotGoodFit,
                                 onSetNotGoodFit,
                                 isNotGoodFit,
                                 addedToBulk,
                               }: TooltipActionsProps) => {
  return (
    <>
      <Box display="flex" mb={1.5}>
        <Button
          disabled={false}
          startAdornment={<ExportAllIcon/>}
          label="Export now"
          onClick={onExportNow}
          height={4.5}
          type="submit"
          mr={1}
          textVariant="caption"
        />
        <Button
          label="Add to bulk export"
          height={4.5}
          width={"100%"}
          type="reset"
          startAdornment={addedToBulk ? <CheckIcon/> : <AddToBulkIcon/>}
          onClick={onAddToBulkExport}
          mr={1}
          textVariant="caption"
        />
        <ActionItemStyled onClick={onViewDetails}>
          <ArrowOutward/>
          <Typography
            variant="caption"
            fontWeight={500}
            color={theme.palette.common.blue800}
          >
            View details
          </Typography>
        </ActionItemStyled>
      </Box>
      {
        showNotGoodFit && (
          <Box>
            <StyledCheckbox
              onClick={onSetNotGoodFit}
              checked={isNotGoodFit}
            />
            <Typography variant="caption" color={theme.palette.common.grey800}>
              Not a good fit
            </Typography>
          </Box>
        )
      }
    </>
  )
}

import { useState } from 'react';
import { useAppDispatch } from 'application/store';
import { getCompaniesAutoSuggest, searchCompanies } from 'integration/api/companies';
import { CompanyFilterDataI } from 'integration/api/companies/types';
import { prepareToSearch } from '../../store/utils';
import { handleGetCompanyInfo } from '../../store/actions';
import { CompanyAutoSuggestOptionI } from '../types';

export const useCompanySearchAutocomplete = () => {
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);
  const [isTotalResultsAreLoading, setTotalResultsAreLoading] = useState(false);
  const [options, setOptions] = useState<CompanyAutoSuggestOptionI[]>([
  ]);

  const [totalResults, setTotalResults] = useState(0);
  const dispatch = useAppDispatch();

  const fetchOptions = async (filterData: CompanyFilterDataI) => {
    setIsOptionsLoading(true);
    setTotalResultsAreLoading(true);
    filterData = prepareToSearch(filterData);
    try {
      const autoSuggestResult = await getCompaniesAutoSuggest(filterData, 0, 5);
      setOptions(autoSuggestResult.content);
    } finally {
      setIsOptionsLoading(false)
    }
    try {
      const totalResultsResponse = await searchCompanies(filterData, 0, 20)
      setTotalResults(totalResultsResponse.totalElements);
    } finally {
      setTotalResultsAreLoading(false);
    }
  }
  const getCompanyInfo = (companyId: number) => {
    return dispatch(handleGetCompanyInfo(companyId));
  }
  const clearOptions = () => {
    setOptions([]);
  }

  return {
    isOptionsLoading,
    options,
    fetchOptions,
    getCompanyInfo,
    clearOptions,
    totalResults,
    isTotalResultsAreLoading,
  }

}
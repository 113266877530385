import React, { useState } from "react";

import { YearsInBusiness } from "./const";

import { Box, Collapse, FormGroup } from "@mui/material";

import { CheckboxGroupStyled } from "./styles";
import { CollapseLabel, MultipleCheckboxController } from "./components";

import { ISelect } from "application/components/SelectComponent/types";
import { useFormContext } from "react-hook-form";
import { getYearsInBusinessLabel } from './utils';

const YearsInBusinessCollapse = ({ label }: { label: string }) => {
  const [expanded, setExpanded] = useState(false);

  const { watch } = useFormContext();
  const { yearsInBusinessRange } = watch();

  const ScoreOptions = [
    { value: YearsInBusiness.LESS_THEN_1, label: getYearsInBusinessLabel(YearsInBusiness.LESS_THEN_1) },
    { value: YearsInBusiness.FROM_1_TO_5, label: getYearsInBusinessLabel(YearsInBusiness.FROM_1_TO_5) },
    { value: YearsInBusiness.FROM_5_TO_10, label: getYearsInBusinessLabel(YearsInBusiness.FROM_5_TO_10) },
    { value: YearsInBusiness.FROM_10, label: getYearsInBusinessLabel(YearsInBusiness.FROM_10) },
  ];

  return (
    <Box width={"100%"}>
      <CollapseLabel
        label={label}
        expanded={expanded}
        setExpanded={setExpanded}
        count={yearsInBusinessRange?.length}
      />
      <Collapse in={expanded}>
        <CheckboxGroupStyled pt={1.5}>
          <FormGroup>
            {ScoreOptions.map((item: ISelect) => (
              <MultipleCheckboxController
                fieldName={"yearsInBusinessRange"}
                value={item.value}
                label={item.label}
                key={item.value}
              />
            ))}
          </FormGroup>
        </CheckboxGroupStyled>
      </Collapse>
    </Box>
  );
};

export default YearsInBusinessCollapse;

import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import theme from "application/theme";
import React from "react";
import { Edit } from "@mui/icons-material";
import { Button } from "application";
import BadgeWithDot from "application/components/BadgeWithDot";
import { LeftSideHeader } from "../styles";

const ProfileHeader = ({
  name,
  onEdit,
  status,
  type,
}: {
  name: string;
  onEdit: () => void;
  status: string;
  type: string | null;
}) => {
  return (
    <Box display={"flex"} justifyContent={"space-between"} mb={1.5}>
      <LeftSideHeader>
        <Box display={"flex"} alignItems={"center"}>
          <Typography
            variant="h4"
            color={theme.palette.common.grey900}
            mr={1.5}
          >
            {name}
          </Typography>
          <BadgeWithDot status={status} />
        </Box>
        <Typography variant="overline" color={theme.palette.common.grey700}>
          {type || ""}
        </Typography>
      </LeftSideHeader>
      <Box display={"flex"}>
        <Button
          label="Edit"
          startAdornment={<Edit sx={{ color: theme.palette.common.grey800 }} />}
          height={5.5}
          onClick={onEdit}
          type="button"
        />
      </Box>
    </Box>
  );
};

export default ProfileHeader;

import React from "react";
import { IconProps } from './types';
import theme from '../theme';

export const UserIcon: React.FC<IconProps> = ({color = theme.palette.common.grey600, ...rest}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M10.0001 4C8.06709 4 6.50008 5.567 6.50008 7.5C6.50008 9.433 8.06709 11 10.0001 11C11.9331 11 13.5001 9.433 13.5001 7.5C13.5001 5.567 11.9331 4 10.0001 4ZM4.50008 7.5C4.50008 4.46243 6.96252 2 10.0001 2C13.0376 2 15.5001 4.46243 15.5001 7.5C15.5001 10.5376 13.0376 13 10.0001 13C6.96252 13 4.50008 10.5376 4.50008 7.5ZM7.32635 14.5C7.38341 14.5 7.44132 14.5 7.50009 14.5H12.0001C12.5524 14.5 13.0001 14.9477 13.0001 15.5C13.0001 16.0523 12.5524 16.5 12.0001 16.5H7.50009C6.03177 16.5 5.51929 16.5109 5.12923 16.6292C4.17042 16.92 3.42011 17.6703 3.12926 18.6291C3.01094 19.0192 3.00008 19.5317 3.00008 21C3.00008 21.5523 2.55237 22 2.00008 22C1.4478 22 1.00008 21.5523 1.00008 21C1.00008 20.9412 1.00006 20.8833 1.00004 20.8263C0.999587 19.599 0.999277 18.761 1.21538 18.0486C1.70013 16.4506 2.95065 15.2 4.54866 14.7153C5.26106 14.4992 6.09908 14.4995 7.32635 14.5ZM22.7072 15.2929C23.0977 15.6834 23.0977 16.3166 22.7072 16.7071L18.7072 20.7071C18.3167 21.0976 17.6835 21.0976 17.293 20.7071L15.293 18.7071C14.9025 18.3166 14.9025 17.6834 15.293 17.2929C15.6835 16.9024 16.3167 16.9024 16.7072 17.2929L18.0001 18.5858L21.293 15.2929C21.6835 14.9024 22.3167 14.9024 22.7072 15.2929Z"
            fill={color}/>
    </svg>
  );
};

import React from "react";
import { Box, Typography } from "@mui/material";
import theme from "application/theme";
import { ModalComponent } from "application/components/ModalComponent";
import { Button } from "application";
import { ContactPersonStyled } from "../styles";
import { AddUserIcon } from "application/assets/AddUserIcon";
import { useAppDispatch, useAppSelector } from "application/store";
import { handleRenewSubscriptions } from "../store/actions";
import { SubscriptionsI } from "../store/types";
import { organizationsSelector } from "../store";

export const ContactSales = ({
  isOpen,
  handleClose,
  subscriptionItem,
}: {
  isOpen: boolean;
  handleClose: () => void;
  subscriptionItem: SubscriptionsI;
}) => {
  const dispatch = useAppDispatch();
  const { organizationProfile } = useAppSelector(organizationsSelector);

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleClose}
      padding={3}
      width={475}
    >
      <Box textAlign="center">
        <Box display="flex" justifyContent="center" mb={4}>
          <AddUserIcon />
        </Box>
        <Typography variant="h5" color={theme.palette.common.grey800} mb={1.5}>
          Your subscription for &nbsp;
          <ContactPersonStyled>
            {organizationProfile.companyName}
          </ContactPersonStyled>{" "}
          &nbsp; will expire soon
        </Typography>

        <Typography
          variant="caption"
          color={theme.palette.common.grey700}
          display="block"
        >
          Please contact:&nbsp;
          <ContactPersonStyled>
            {subscriptionItem.salesPersonEmail}
          </ContactPersonStyled>
          &nbsp; or request more seats to your organization to add more
          coworkers.
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          gap={theme.spacing(2)}
          mt={4}
        >
          <Button
            label="Cancel"
            height={5.5}
            width={120}
            type="button"
            onClick={handleClose}
          />
          <Button
            label="Contact Sales"
            height={5.5}
            type="submit"
            onClick={() => {
              dispatch(
                handleRenewSubscriptions(
                  subscriptionItem.id,
                  organizationProfile.id
                )
              );
              handleClose();
            }}
          />
        </Box>
      </Box>
    </ModalComponent>
  );
};

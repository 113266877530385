import { Box } from "@mui/system";
import React from "react";
import { EmailIcon } from "../../../application/assets/EmailIcon";
import theme from "application/theme";
import { PhoneIcon } from "../../../application/assets/PhoneIcon";
import { EmailDisabledIcon } from "../assets/EmailDisabledIcon";
import { PhoneDisabledIcon } from "../assets/PhoneDisabledIcon";
import { ButtonsWrapper } from "../styles";

const ExportedType = ({
  exportedEmail,
  exportedPhone,
}: {
  exportedEmail: boolean;
  exportedPhone: boolean;
}) => {
  return (
    <Box>
      <ButtonsWrapper>
        <Box display="flex">
          <Box
            display="flex"
            alignItems="center"
            p={theme.spacing(0.75, 1)}
            sx={{
              borderRight: `1px solid ${theme.palette.common.grey400}`,
              borderRadius: theme.spacing(1, 0, 0, 1),
            }}
          >
            {exportedEmail ? (
              <EmailIcon color={theme.palette.common.blue700} />
            ) : (
              <EmailDisabledIcon />
            )}
          </Box>
          <Box
            sx={{ borderRadius: theme.spacing(0, 1, 1, 0) }}
            display="flex"
            alignItems="center"
            p={theme.spacing(0.75, 1)}
          >
            {exportedPhone ? (
              <PhoneIcon color={theme.palette.common.blue700} />
            ) : (
              <PhoneDisabledIcon />
            )}
          </Box>
        </Box>
      </ButtonsWrapper>
    </Box>
  );
};

export default ExportedType;

import React from 'react';
import { Typography, Box } from '@mui/material';
import theme from 'application/theme';
import { ToggleButton } from 'application';
interface Props {
  message: string;
  onClick: () => void;
}
export const SkipExportedToggle = ({message, onClick}: Props) => {
  const [checked, setChecked] = React.useState(false);
  const handleChange = () => {
    setChecked(!checked);
    onClick();
  };
  return (
    <Box display="flex" gap={1.5}>
      <Typography
        variant="caption"
        color={theme.palette.common.grey700}>
        {message}
      </Typography>
      <ToggleButton
        checked={checked}
        onChange={handleChange}/>
    </Box>
  )
}

import React, { useState } from "react";
import SvgIcon from "@mui/material/SvgIcon";

interface CopyIconProps {
  color?: string;
  hoverColor?: string;
}

const CopyIcon: React.FC<CopyIconProps> = ({ color, hoverColor, ...rest }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <SvgIcon
      {...rest}
      viewBox="0 0 16 16"
      style={{ color: "none", width: 16, height: 16, fill: "none" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <g clipPath="url(#clip0_2410_48190)">
        <path
          d="M3.33325 9.99992C2.712 9.99992 2.40137 9.99992 2.15634 9.89843C1.82964 9.7631 1.57007 9.50353 1.43475 9.17683C1.33325 8.9318 1.33325 8.62117 1.33325 7.99992V3.46659C1.33325 2.71985 1.33325 2.34648 1.47858 2.06126C1.60641 1.81038 1.81038 1.60641 2.06126 1.47858C2.34648 1.33325 2.71985 1.33325 3.46659 1.33325H7.99992C8.62117 1.33325 8.9318 1.33325 9.17683 1.43475C9.50353 1.57007 9.7631 1.82964 9.89843 2.15634C9.99992 2.40137 9.99992 2.712 9.99992 3.33325M8.13325 14.6666H12.5333C13.28 14.6666 13.6534 14.6666 13.9386 14.5213C14.1895 14.3934 14.3934 14.1895 14.5213 13.9386C14.6666 13.6534 14.6666 13.28 14.6666 12.5333V8.13325C14.6666 7.38652 14.6666 7.01315 14.5213 6.72793C14.3934 6.47705 14.1895 6.27307 13.9386 6.14524C13.6534 5.99992 13.28 5.99992 12.5333 5.99992H8.13325C7.38652 5.99992 7.01315 5.99992 6.72793 6.14524C6.47705 6.27307 6.27307 6.47705 6.14524 6.72793C5.99992 7.01315 5.99992 7.38652 5.99992 8.13325V12.5333C5.99992 13.28 5.99992 13.6534 6.14524 13.9386C6.27307 14.1895 6.47705 14.3934 6.72793 14.5213C7.01315 14.6666 7.38652 14.6666 8.13325 14.6666Z"
          stroke={isHovered ? hoverColor : color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2410_48190">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default CopyIcon;

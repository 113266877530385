import { clientRequest } from "../requestHandler";
import { CompanyFilterDataI, FilterSavedDataI } from "./types";
import { SortBy } from "common/companies/companiesFilter/const";
import { SortOrder } from "application/types";
import { PermitWithCompanyFilterDataI } from "common/permits/types";

export const getSavedFilteredSearches = () =>
  clientRequest({
    url: `/api/companies/saved-searches`,
    method: "GET",
  });

export const searchCompanies = (
  filterData: CompanyFilterDataI,
  page: number,
  size: number,
  sortBy?: SortBy,
  sortOrder?: SortOrder
) =>
  clientRequest({
    url: `/api/companies`,
    method: "POST",
    data: filterData,
    params: {
      page,
      size,
      sort: sortBy ? `${sortBy},${sortOrder}` : undefined,
    },
  });

export const saveFilteredSearches = (filterSavedData: FilterSavedDataI) =>
  clientRequest({
    url: `/api/companies/saved-searches`,
    method: "POST",
    data: filterSavedData,
  });

export const deleteSavedFilteredSearch = (userId: number) =>
  clientRequest({
    url: `/api/companies/saved-searches/${userId}`,
    method: "DELETE",
  });

export const getSavedSearchById = (userId: number) =>
  clientRequest({
    url: `/api/companies/saved-searches/${userId}`,
    method: "GET",
  });

export const getCompanyById = (id: number) =>
  clientRequest({
    url: `/api/companies/${id}`,
    method: "GET",
  });

export const getCompanyStructure = (id: number, page: number) =>
  clientRequest({
    url: `/api/companies/${id}/structure`,
    method: "POST",
    params: {
      page,
    },
  });

export const getAllTags = () =>
  clientRequest({
    url: `/api/companies/tags`,
    method: "GET",
  });

export const getCities = (name: string, page: number, size: number) =>
  clientRequest({
    url: `/api/companies/cities`,
    method: "GET",
    params: {
      page,
      size,
      name,
    },
  });

export const getStates = (name: string, page: number, size: number) =>
  clientRequest({
    url: `/api/companies/states`,
    method: "GET",
    params: {
      page,
      size,
      name,
    },
  });

export const getSubContractorCategories = () =>
  clientRequest({
    url: `/api/companies/sub-contractor-categories`,
    method: "GET",
  });

export const getCompanyPermitRangeValues = () =>
  clientRequest({
    url: `/api/companies/permit-range-values`,
    method: "GET",
  });

export const setOrganizationAsNotAGoodFit = (id: number) =>
  clientRequest({
    url: `/api/not-good-fit-companies`,
    method: "POST",
    data: {
      companyId: id,
    },
  });

export const deleteOrganizationFromNotAGoodFit = (id: number) =>
  clientRequest({
    url: `/api/not-good-fit-companies/${id}`,
    method: "DELETE",
  });

export const getCompanyContacts = (id: number) =>
  clientRequest({
    url: `/api/companies/${id}/contacts`,
    method: "GET",
  });

export const getCompaniesExtraInfo = (companyIds: number[]) =>
  clientRequest({
    url: `/api/companies/information`,
    method: "GET",
    params: {
      companyIds: companyIds.join(","),
    },
  });

export const getCompaniesAutoSuggest = (
  filterData: CompanyFilterDataI,
  page: number,
  size: number
) =>
  clientRequest({
    url: `/api/companies/suggestions`,
    method: "POST",
    data: filterData,
    params: {
      page,
      size,
    },
  });

export const searchCompaniesByPermitFilters = (
  filterData: PermitWithCompanyFilterDataI,
  page: number,
  size: number,
  sortBy?: SortBy,
  sortOrder?: SortOrder
) =>
  clientRequest({
    url: `/api/companies/permits`,
    method: "POST",
    data: filterData,
    params: {
      page,
      size,
      sort: sortBy ? `${sortBy},${sortOrder}` : undefined,
    },
  });

export const searchCompaniesMapView = (
  filterData: PermitWithCompanyFilterDataI,
  sortBy?: SortBy,
  sortOrder?: SortOrder
) =>
  clientRequest({
    url: `/api/companies/coordinates`,
    method: "POST",
    data: filterData,
    params: {
      sort: sortBy ? `${sortBy},${sortOrder}` : undefined,
    },
  });

export const getCompaniesInfoById = (companyIds: string) =>
  clientRequest({
    url: `/api/companies`,
    method: "GET",
    params: {
      companyIds,
    },
  });


export const getCompaniesAndPermitsAutoSuggest = (
    filterData: PermitWithCompanyFilterDataI,
    page: number,
    size: number
  ) =>
    clientRequest({
      url: `/api/companies/suggestions-by-permit-filters`,
      method: "POST",
      data: filterData,
      params: {
        page,
        size,
      },
    });

export const getCompanyProductTypes = () =>
  clientRequest({
    url: `/api/companies/product-types`,
    method: "GET",
  });

export const getCompanyProductTypeBrands = (id: number) =>
  clientRequest({
    url: `/api/companies/product-types/${id}/brands`,
    method: "GET",
  });

export const getCompanyProductTypeBrandName = (typeId: number, brandId: number) =>
  clientRequest({
    url: `api/companies/product-types/${typeId}/brands/${brandId}/names`,
    method: "GET",
  });

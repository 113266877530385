import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import theme from "application/theme";

export const TabelWrapper = styled(Box)`
  height: calc(100% - 32px);
  .InovuaReactDataGrid--theme-default-light {
    min-height: 100% !important;
  }
  .InovuaReactDataGrid__column-header--show-border-right {
    border: none;
  }
  .InovuaReactDataGrid--theme-default-light
    .InovuaReactDataGrid__column-header__resize-wrapper--show-border-left {
    border: none !important;
  }
  .InovuaReactDataGrid__column-header__resize-wrapper--show-border-left {
    border: none;
  }
  .InovuaReactDataGrid--theme-default-light
    .InovuaReactDataGrid__column-header__content {
    padding: ${theme.spacing(1.5)};
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
  .InovuaReactDataGrid--theme-default-light
    .InovuaReactDataGrid__cell:not(.InovuaReactDataGrid__cell--no-padding) {
    padding: ${theme.spacing(1.5)};
  }
  .InovuaReactDataGrid__cell__content {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .InovuaReactDataGrid--theme-default-light.InovuaReactDataGrid--show-hover-rows
    .InovuaReactDataGrid__row--no-zebra:not(.InovuaReactDataGrid__row--scrolling):not(.InovuaReactDataGrid__row--empty)
    .InovuaReactDataGrid__row-hover-target:hover {
    background: transparent !important;
  }
`;

export const PeopleCellWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const StyledLink = styled(Typography)`
  color: ${theme.palette.common.blue700};
  text-decoration: underline;
  cursor: pointer;
`;

export const ShowOnMapWrapper = styled(Box)<{ disabled: boolean }>`
  cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};
  display: flex;
  alignitems: center;
`;

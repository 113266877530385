import React, {FC, useMemo, useState} from "react";

import { useAppSelector } from "application/store";

import { ShowLessAndMore } from "common/companies/components";
import PopupDialog from "common/companies/components/popup";
import CompanyInfoLabel from "../InfoLabel";
import LinkInfoItem from "./LinkInfoItem";

import { CurrencyDollarIcon, CheckVerifiedIcon, InstagramIcon, BuildingIcon, FacebookIcon,
  LinkedinIcon, MarkerIcon, CursorIcon, UsersIcon, CheckListIcon } from "common/companies/assets";
import { PhoneIcon, EmailIcon} from "application/assets";

import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import theme from "application/theme";
import { GeneralInfoItemStyled } from "./styles";
import { selectedCompanySelector } from "../../store";
import { getAbsoluteLink } from "application/utils";

const GeneralInfo: FC = () => {
  const [showAll, setShowAll] = useState(true);

  const selectedCompany = useAppSelector(selectedCompanySelector);

  const companyPrimaryLocation = useMemo(() =>  {
    return selectedCompany.locations && selectedCompany.locations.length ? selectedCompany.locations[0] : null;
  }, [selectedCompany])

  const today = new Date();
  const yearsInBusiness = today.getFullYear() - selectedCompany.foundedYear;

  return (
    <Box
      className="general-company-info"
      display="flex"
      flexDirection="column"
      gap={1.5}
      width="100%"
      p={theme.spacing(2, 4)}
    >
      <CompanyInfoLabel label="General info" withoutAmount />
      <Box
        display="flex"
        width="100%"
        justifyContent="flex-start"
        flexWrap="wrap"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={1.5}
          mb={2}
          width="60%"
          minWidth={theme.spacing(50)}
        >
          <GeneralInfoItem
              display = {!!selectedCompany?.generalContractorCategory}
              startIcon={null}
              content={selectedCompany?.generalContractorCategory}/>
          <GeneralInfoItem
              display = {!!selectedCompany?.subContractorCategory}
              startIcon={null}
              content={selectedCompany?.subContractorCategory}/>
          <GeneralInfoItem
              display = {!!selectedCompany.zipCode}
              startIcon={<MarkerIcon />}
              content={selectedCompany.zipCode}/>
          <GeneralInfoItem
              display = {!!companyPrimaryLocation?.state}
              startIcon={<MarkerIcon />}
              content={companyPrimaryLocation?.state}/>
          {selectedCompany.locations.length ? (
            <GeneralInfoItemStyled>
              <BuildingIcon />
              <PopupDialog
                buttonText={"Mailing address locations"}
                locationList={selectedCompany.locations}
                Icon={BuildingIcon}
              />
            </GeneralInfoItemStyled>
          ) : null}
          {showAll ? (
            <>
              <GeneralInfoItem
                  display = {!!selectedCompany.companySize}
                  startIcon={<UsersIcon />}
                  content={`${selectedCompany.companySize} employees`}/>
              <GeneralInfoItem
                  display = {!!selectedCompany.foundedYear}
                  startIcon={<CheckVerifiedIcon />}
                  content={`${yearsInBusiness} years in business`}/>
              <GeneralInfoItem
                  display = {!!selectedCompany.activeLicenseFound}
                  startIcon={<CheckListIcon/>}
                  content={'Active license found'}/>
              <GeneralInfoItem
                  display = {!!selectedCompany.companyContactData?.availableEmailsCount}
                  startIcon={<EmailIcon color={theme.palette.common.grey600}/>}
                  content={`${selectedCompany.companyContactData?.availableEmailsCount} Emails Available`}/>
              <GeneralInfoItem
                  display = {!!selectedCompany.companyContactData?.availablePhonesCount}
                  startIcon={<PhoneIcon color={theme.palette.common.grey600}/>}
                  content={`${selectedCompany.companyContactData?.availablePhonesCount} Phone Numbers Available`}/>
            </>
          ) : null}
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={1.5}
          width={"40%"}
          minWidth={theme.spacing(25)}
        >
          <GeneralInfoItem
            display = {!!selectedCompany?.annualRevenue}
            startIcon={<CurrencyDollarIcon/>}
            content={selectedCompany?.annualRevenue}
          />
          <LinkInfoItem
            label="Website"
            url={getAbsoluteLink(selectedCompany.companyContactInfo?.website)}
            Icon={CursorIcon}
          />
          <LinkInfoItem
            label="Facebook"
            url={selectedCompany.companyContactInfo?.facebook}
            Icon={FacebookIcon}
          />
          {showAll && (
            <>
              {selectedCompany.companyContactInfo?.instagram ? (
                <LinkInfoItem
                  label="Instagram"
                  url={selectedCompany.companyContactInfo?.instagram}
                  Icon={InstagramIcon}
                />
              ) : null}
              {selectedCompany.companyContactInfo?.linkedIn ? (
                <LinkInfoItem
                  label="LinkedIn"
                  url={selectedCompany.companyContactInfo?.linkedIn}
                  Icon={LinkedinIcon}
                />
              ) : null}
            </>
          )}
        </Box>
      </Box>
      {selectedCompany.companyContactInfo?.instagram &&
      selectedCompany.companySize &&
      selectedCompany.foundedYear &&
      selectedCompany.companyContactInfo?.linkedIn ? (
        <ShowLessAndMore showAll={showAll} setShowAll={setShowAll} />
      ) : null}
    </Box>
  );
};

export default GeneralInfo;


interface GeneralInfoItemProps {
  display: boolean;
  startIcon?: React.ReactNode;
  content?: string | null;
}
const GeneralInfoItem = ({display, startIcon, content}: GeneralInfoItemProps) => {
  if (!display) return null;
  return (
      <GeneralInfoItemStyled>
        <>
          {startIcon}
          <Typography
              variant="overline"
              color={theme.palette.common.grey800}
          >
            {content || ''}
          </Typography>
        </>

      </GeneralInfoItemStyled>
  )
}

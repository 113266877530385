import {Typography} from "@mui/material";
import theme from "application/theme";
import {StyledWarning} from "./styles";
import {warningColor} from "application/components/InfoCard/utils";
import { getNumberFormatWithSeparators, getPercents } from "application/utils";
import React, {useMemo} from "react";

interface Props {
    totalCredits: number;
    usedCredits: number;
}

export const CreditsDisplayItem = ({totalCredits, usedCredits}: Props) => {
    const displayWaring = useMemo(() => {
        return (usedCredits <= totalCredits) && totalCredits > 0;
    }, [usedCredits, totalCredits]);
    return (
        <>
            <Typography
                variant="caption"
                color={theme.palette.common.grey800}
                mr={2}
                sx={{display: "inline-block", alignItems: "center"}}
            >
                {`${getNumberFormatWithSeparators(usedCredits ?? "0")}/
                ${getNumberFormatWithSeparators(totalCredits ?? "0")}`}
            </Typography>
            {displayWaring ? (
                <StyledWarning
                    bgcolor={
                        warningColor(
                            getPercents(usedCredits, totalCredits)
                        ).bgColor
                    }
                    color={
                        warningColor(
                            getPercents(usedCredits, totalCredits)
                        ).textColor
                    }
                    sx={{display: "inline-block", alignSelf: "center"}}
                >
                    {getPercents(usedCredits, totalCredits)}%
                </StyledWarning>
            ) : null}
        </>
    )
}
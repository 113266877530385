import { Box, styled } from "@mui/system";
import { Typography } from "@mui/material";
import theme from "application/theme";

interface Props {
  active?: number;
}
export const PermitsTableModeTabItemStyled = styled(Typography)<Props>`
  color: ${({ active }) =>
    active ? theme.palette.common.blue700 : theme.palette.common.grey800};
  font-size: ${theme.spacing(2.5)};
  font-weight: 500;
  border-bottom: ${({ active }) =>
    active ? `2px solid ${theme.palette.common.blue700}` : `none`};
  padding: ${theme.spacing(0, 0.5, 1)};
  cursor: pointer;
`;

export const LoaderWrapper = styled(Box)`
  width: ${theme.spacing(16)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

import { Box, Paper, styled } from "@mui/material";
import theme from "application/theme";

export const PaperStyled = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: ${theme.spacing(3, 3, 3, 4)};
  max-width: ${theme.spacing(95)};
  border-radius: ${theme.spacing(1.5)};
  border: 1px solid ${theme.palette.common.grey200};
  max-height: ${theme.spacing(72.5)};
`;

export const ListLocations = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2.5)};
  max-height: ${theme.spacing(52.5)};
  padding-right: ${theme.spacing(2)};
  overflow-y: auto;
`;

export const LocationWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${theme.spacing(1.5)};
`;

export const LocationContentWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing(1)};
`;

import React from "react";
import { BadgeWrapper } from "./style";
import { Typography } from "@mui/material";
import theme from "application/theme";

const BadgeComponent = ({
  text,
  height = 3.5,
}: {
  text: string;
  height?: number;
}) => {
  return (
    <BadgeWrapper height={theme.spacing(height)} mr={1} width="fit-content">
      <Typography textTransform={"capitalize"} variant="overline">
        {text.toLowerCase()}
      </Typography>
    </BadgeWrapper>
  );
};

export default BadgeComponent;

import { Box, styled } from "@mui/system";
import { Button } from "@mui/material";
import theme from "application/theme";
import MuiAppBar from "@mui/material/AppBar";
import { AppBarProps } from "./types";

export const MainContentWrapper = styled("main")`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
  background-color: ${theme.palette.common.grey100};
`;

export const AppBarSpacer = styled("div")`
  min-height: ${theme.spacing(12)};
`;

export const ContentContainer = styled(Box)`
  max-width: 100%;
  height: 100%;
`;

export const AppBarWrapper = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ open }) => ({
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: "none",
  color: `${theme.palette.common.grey900}`,
  backgroundColor: `${theme.palette.common.grey100}`,
  marginLeft: `calc(${theme.spacing(9.125)})`,
  width: `calc(100% - ${theme.spacing(7.125)})`,
  ...(open && {
    marginLeft: theme.spacing(31.25),
    width: `calc(100% - ${theme.spacing(31.25)})`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const ToolbarWrapper = styled(Box)`
  padding: ${theme.spacing(4, 4, 2.5, 4)};
  align-items: center;
  display: flex;
`;

export const ButtonStyled = styled(Button)`
  width: ${theme.spacing(30)};
  background: ${theme.palette.common.blue700};
  color: ${theme.palette.common.white};
  padding: ${theme.spacing(1.5)};
  border-radius: ${theme.spacing(1)};
  font-weight: 500;
  font-size: ${theme.spacing(2)};
  line-height: ${theme.spacing(2.5)};
  border-radius: ${theme.spacing(1)};
  &:hover {
    background: ${theme.palette.common.blue500};
  }
`;

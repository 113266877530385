import { useContext, useMemo } from 'react';
import { PermitFiltersContext } from '../contexts/PermitFiltersContext';
import { getCountCompaniesFilters } from 'common/companies/utils';
import { addZoomedAreaToFilterData, getCountPermitFilters } from '../utils';
import { useAppDispatch, useAppSelector } from 'application/store';
import {
  companyFilterInitialState,
  permitFilterInitialState,
  permitsSelector,
  setSearchInArea,
  setZoomedArea
} from '../store';

export const usePermitFiltersContext = () => {
  const context = useContext(PermitFiltersContext);
  if (!context) throw new Error('PermitFiltersContext  must be use inside PermitFiltersContextProvider');

  const { zoomedArea, searchInArea } = useAppSelector(permitsSelector);
  const dispatch = useAppDispatch();
  const companyFilterFormData = context.companyFilterForm.watch();
  const permitFilterFormData = context.permitFilterForm.watch();

  const filtersTotalCount = useMemo(() => {
    const companyFilters = companyFilterFormData;
    const permitFilters = permitFilterFormData;
    const companyFiltersCount = getCountCompaniesFilters(companyFilters);
    const permitFiltersCount = getCountPermitFilters(permitFilters);
    return companyFiltersCount + permitFiltersCount;
  }, [permitFilterFormData, companyFilterFormData]);

  const filtersWithoutZoomedArea = useMemo(() => {
    return {
      permitFilter: {
        ...permitFilterFormData,
        zoomedArea: undefined,
      },
      companyFilter: {
        ...companyFilterFormData,
        zoomedArea: undefined,
      },
    };
  }, [companyFilterFormData, permitFilterFormData]);

  const filtersWithZoomedArea = useMemo(() => {
    let filterData = {
      permitFilter: permitFilterFormData,
      companyFilter: companyFilterFormData,
    }
    if (searchInArea) {
      filterData = addZoomedAreaToFilterData(filterData, zoomedArea);
    }
    return filterData;
  }, [companyFilterFormData, permitFilterFormData, searchInArea, zoomedArea]);

  const clearFilters = () => {
    context.permitFilterForm.reset(permitFilterInitialState);
    context.companyFilterForm.reset(companyFilterInitialState);
    dispatch(setSearchInArea(false));
    dispatch(setZoomedArea(null));
  }

  return {
    ...context,
    filtersTotalCount,
    filtersWithZoomedArea,
    filtersWithoutZoomedArea,
    clearFilters,
  };
};

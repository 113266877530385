import React from "react";

import { handleResponse } from "common/responseService/responseService";

import theme from "application/theme";
import CopyIcon from "application/assets/CopyIcon";
import { IconButtonStyled } from "./styles";

interface CopyButtonProps {
  textToCopy: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ textToCopy }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy);
    handleResponse({ message: "Address is copied to clipboard" });
  };

  return (
    <IconButtonStyled onClick={copyToClipboard}>
      <CopyIcon
        color={theme.palette.common.grey700}
        hoverColor={theme.palette.common.blue700}
      />
    </IconButtonStyled>
  );
};

export default CopyButton;

import React from "react";

export const HubspotModalIcon = () => {
  return (
    <svg
      width="83"
      height="82"
      viewBox="0 0 83 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="41.5" cy="41" r="41" fill="#EBF2FF" />
      <mask
        id="mask0_1271_35798"
        maskUnits="userSpaceOnUse"
        x="20"
        y="20"
        width="42"
        height="42"
      >
        <rect x="20" y="20" width="42" height="42" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1271_35798)">
        <path
          d="M41.6768 38.7305L31.6484 31.6553C31.1562 31.9629 30.6025 32.1475 29.9873 32.1475C28.2031 32.1475 26.7881 30.7324 26.7881 28.9482C26.7881 27.1641 28.2031 25.6875 29.9873 25.6875C31.7715 25.6875 33.1865 27.1641 33.1865 28.9482C33.1865 29.1943 33.1865 29.502 33.0635 29.748C36.2627 32.209 41.7998 36.1465 43.4609 37.4385C44.2607 37.0078 45.1836 36.7617 46.1064 36.6387V32.8242C44.999 32.3936 44.3838 31.4092 44.3838 30.2402C44.3838 28.5791 45.6143 27.2871 47.2139 27.2871C48.875 27.2871 50.1055 28.5791 50.1055 30.2402C50.1055 31.4092 49.4902 32.3936 48.3828 32.8242V36.6387C52.2588 37.2539 55.1504 40.5762 55.1504 44.5752C55.1504 49.0049 51.582 52.5732 47.1523 52.5732C45.3682 52.5732 43.6455 51.958 42.292 50.9121L39.2158 53.9883C39.2773 54.2344 39.3389 54.4805 39.3389 54.7266C39.3389 55.4033 39.0928 56.0186 38.6006 56.5107C38.1699 57.0029 37.4932 57.1875 36.8779 57.1875C36.2012 57.1875 35.5859 56.9414 35.0938 56.5107C34.6631 56.0186 34.3555 55.4033 34.3555 54.7266C34.3555 54.1113 34.6631 53.4346 35.0938 53.0039C35.5859 52.5117 36.2012 52.2656 36.8779 52.2656C37.1855 52.2656 37.4316 52.3271 37.7393 52.3887L40.7539 49.374C39.7695 48.0205 39.1543 46.3594 39.1543 44.5752C39.1543 42.2373 40.1387 40.207 41.6768 38.7305ZM47.1523 48.8203C49.5518 48.8203 51.3975 46.9131 51.3975 44.5752C51.3975 42.2373 49.5518 40.3301 47.1523 40.3301C44.8145 40.3301 42.9072 42.2373 42.9072 44.5752C42.9072 46.9131 44.8145 48.8203 47.1523 48.8203Z"
          fill="#2863EC"
        />
      </g>
    </svg>
  );
};

import React from "react";

export const NextBtnIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_3611_11661"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect
          y="24"
          width="24"
          height="24"
          transform="rotate(-90 0 24)"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_3611_11661)">
        <path
          d="M18 12L12 18L10.6 16.6L14.2 13H5V11H14.2L10.6 7.4L12 6L18 12Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

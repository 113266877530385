import { IconButton, TableCell, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/system";
import theme from "application/theme";
import { Link } from "react-router-dom";

export const QuantityPickerWrapper = styled(Box)`
  border: ${theme.spacing(0.125)} solid ${theme.palette.common.grey400};
  border-radius: ${theme.spacing(1)};
  display: flex;
  align-items: center;
  width: 100%;
  max-width: ${theme.spacing(17.5)};
  padding: ${theme.spacing(0, 0.25)};
`;

export const IconButtonStyled = styled(IconButton)`
  &:hover: {
    background: transparent;
  }
`;

export const QuantityPickerInput = styled(TextField)`
  width: ${theme.spacing(12.5)};
  height: ${theme.spacing(4)};
  & .MuiOutlinedInput-root {
    font-size: ${theme.spacing(2)};
    height: ${theme.spacing(5.5)};
    font-weight: 400;
  }
  & .MuiOutlinedInput-input {
    text-align: center;
    padding: ${theme.spacing(0)};
  }
  & fieldset {
    border: none;
  }
  & input[type="number"] {
    -moz-appearance: textfield;
  }
  & input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const LabelQuantity = styled("span")`
  font-weight: 500;
  color: ${theme.palette.common.orange600};
  margin-left: ${theme.spacing(0.5)};
`;

export const LinkStyled = styled(Link)`
  color: ${theme.palette.common.blue700};
  font-weight: 500;
  font-weight: ${theme.typography.fontWeightMedium};
  font-size: ${theme.spacing(1.75)};
`;

export const TableCellCustom = styled(TableCell)`
  padding: ${theme.spacing(1.5, 2.5)};
`;

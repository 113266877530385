import { Box, styled, Typography } from '@mui/material';
import theme from 'application/theme';

export const CollapseLabelWrapper = styled(Typography)`
  color: ${theme.palette.common.grey700};
  width: 100%;
  display: flex;
  height: ${theme.spacing(2.5)};
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const FilterCounter = styled(Box)`
  width: ${theme.spacing(2.5)};
  height: ${theme.spacing(2.5)};
  font-size: ${theme.spacing(1.5)};
  color: ${theme.palette.common.grey800};
  background: ${theme.palette.common.white};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing(1)};
  border-radius: ${theme.spacing(0.75)};
  font-weight: 500;
`;

import { useAppDispatch, useAppSelector } from 'application/store';
import { organizationSelector } from 'common/companies/store';
import { sdrProfileSelector } from 'common/sdrProfile/store';
import { ExportScope, ExportTo } from 'common/companies/types';
import { handleAddDataToBulkExport, handleExportOrganization } from 'common/companies/store/actions';
import { handleAddPermitToBulkExport, handleExportPermits } from '../store/actions';
import { useInAppNotification } from 'common/notify';

export const useCompaniesAndPermitsExport = () => {
  const dispatch = useAppDispatch();
  const organization = useAppSelector(organizationSelector);
  const { sdrProfile } = useAppSelector(sdrProfileSelector);
  const { showBulkExportNotification } = useInAppNotification();

  const exportMultiplePermitsToCsv = (permitIds: number[]) => {
    dispatch(
      handleExportPermits({
        permitIds,
        organizationId: sdrProfile.organizationId
      })
    )
  }

  const exportPermit = (permitId: number) => {
    exportMultiplePermitsToCsv([permitId]);
  }

  const exportCompanyTo = (companyId: number, exportTo: ExportTo) => {
    dispatch(
      handleExportOrganization(
        {
          employeeExportInfoDtos: [
            {
              id: organization.id,
              companyId,
              exportScope: ExportScope.COMPANY,
            },
          ],
          organizationId: sdrProfile.organizationId,
          exportTo,
        },
        exportTo
      )
    );
  }
  const exportCompanyToHubspot = (companyId: number) => {
    exportCompanyTo(companyId, ExportTo.HUBSPOT);
  }

  const exportCompanyToCSV = (companyId: number) => {
    exportCompanyTo(companyId, ExportTo.CSV);
  }

  const addPermitToBulkExport = async (permitId: number) => {
    const result =  await dispatch(
      handleAddPermitToBulkExport({
        organizationId: sdrProfile.organizationId,
        permitDataId: permitId,
        sdrId: +sdrProfile.id,
      })
    );
    if(result) {
      showBulkExportNotification(ExportScope.PERMIT)
    }
    return result;
  }

  const addCompanyToBulkExport = async (companyId: number) => {
    const result = await dispatch(
      handleAddDataToBulkExport({
        sdrId: sdrProfile.id,
        organizationId: sdrProfile.organizationId,
        companyId,
        exportScope: ExportScope.COMPANY,
      })
    );
    if(result) {
      showBulkExportNotification(ExportScope.COMPANY)
    }
    return result;
  }

  return {
    exportCompanyToCSV,
    exportCompanyToHubspot,
    exportPermit,
    exportMultiplePermitsToCsv,
    addPermitToBulkExport,
    addCompanyToBulkExport
  }
}

import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form/dist/types";

export enum HubspotIntegrationStatus {
  INTEGRATED = "INTEGRATED",
  NOT_INTEGRATED = "NOT_INTEGRATED",
}

export interface TeamMemberI {
  id: number;
  organization: {
    id: number;
    companyName: string;
    website: string;
    activationStatus: TeamStatus;
    organizationType: string | null;
    emailCredits: number;
    phoneCredits: number;
    usedEmailCredits: number;
    usedPhoneCredits: number;
    companyDataCredits: number;
    usedCompanyDataCredits: number;
    contactPerson: string;
    jobPosition: string;
    location: string;
    phone: string;
    emailContactPerson: string;
    timezone: string;
    seats: number;
    usedSeats: number;
    registrationDate: string;
    activationDate: string;
    lastDateOfActivity: string;
    archiveReason: "BREAK_RULES";
    hubspotRemoveAppUrl?: string | null;
    hubspotAuthUrl: string | null;
    hubSpotIntegrationStatus: HubspotIntegrationStatus;
    archivationDate: string;
  };
  email: string;
  firstName: string;
  lastName: string;
  phoneCredits: number;
  emailCredits: number;
  companyDataCredits: number;
  usedCompanyDataCredits: number;
  usedPhoneCredits: number;
  usedEmailCredits: number;
  registrationDate: string;
  activationDate: string;
  activationStatus: TeamStatus;
  emailNotificationEnabled: true;
}

export interface IModal {
  isOpen: boolean;
  handleClose: () => void;
}

export interface IEditModal extends IModal {
  organization: OrganizationProfile;
  onConfirm: (data: OrganizationProfile) => void;
}

export interface IOrganizationForm {
  register: UseFormRegister<OrganizationProfile>;
  errors: FieldErrors<OrganizationProfile>;
  dirtyFields: any;
  watch: UseFormWatch<OrganizationProfile>;
  setValue: UseFormSetValue<OrganizationProfile>;
}

export enum TeamStatus {
  active = "ACTIVE",
  archived = "ARCHIVED",
  waitingActivation = "WAITING_FOR_ACTIVATION",
  declined = "DECLINED",
  blocked = "BLOCKED",
}

export interface OrganizationProfile {
  activationDate: string | null;
  activationStatus: ActivationStatus | string;
  companyDataCredits: number | null;
  companyName: string | null;
  contactPerson: string | null;
  emailContactPerson: string | null;
  emailCredits: number | null;
  id: number;
  jobPosition: string | null;
  lastDateOfActivity: string | null;
  location: string | null;
  organizationType: string | null;
  phone: string | null;
  phoneCredits: number | null;
  registrationDate: string | null;
  seats: number | null;
  timezone: string | null;
  usedEmailCredits: number | null;
  usedPhoneCredits: number | null;
  usedCompanyDataCredits: number | null;
  usedSeats: number | null;
  website: string | null;
  type?: string | null;
  hubspotRemoveAppUrl: string | null;
  hubspotAuthUrl: string | null;
  hubSpotIntegrationStatus: HubspotIntegrationStatus;
  activatedByEmail: string | null;
  organizationStructureAccessPermitted: boolean;
}

export interface SuggestionI {
  id: number;
  organizationName: string;
}

export enum ActivationStatus {
  accepted = "ACCEPTED",
  declined = "DECLINED",
  new = "NEW",
}

export type ManagementCreditsType = {
  id: number;
  emailCredits: number;
  phoneCredits: number;
  companyDataCredits: number;
  phoneAmountError: boolean;
  emailAmountError: boolean;
  companyDataAmountError?: boolean;
};

export enum RequestType {
  credits = "CREDITS",
  subscription_renewal = "SUBSCRIPTION_RENEWAL",
  seats = "SEATS",
}

export interface OrgRequestHistoryI {
  name: string;
  date: string;
  phoneCreditsQuantity: number | null;
  emailCreditsQuantity: number | null;
  companyDataCreditsQuantity: number | null;
  seatsQuantity: number | null;
  subscriptionQuantity: number | null;
  status: string;
  requestType: RequestType;
  doneBy: string;
}

export interface CreditRequestFormI {
  name: string;
  date: string;
  phoneCredits: number;
  emailCredits: number;
  companyDataCredits: number;
  status?: ActivationStatus;
  organizationId: number;
}

export interface SubscriptionsI {
  id: number;
  startDate: string | null;
  lastDate: string | null;
  salesPersonEmail: string | null;
  status: string;
}

import React, { useMemo } from 'react';
import LocationSearchTab from 'common/companies/companiesFilter/LocalionCollapse/LocationSearchTab';
import { usePermitLocationSearch } from 'common/permits/hooks/usePermitLocationSearch';
import { usePermitFiltersContext } from 'common/permits/hooks/usePermitFiltersContext';
import { mergeTwoArrays } from 'application/utils';
import { CollapseFilter } from '../../../CollapseFilter';
import { PageResponseI } from 'application/types';
import { LocationFilterWrapper } from './styles';

type FiledNameInclude = 'locations.citiesInclude' | 'locations.statesInclude' | 'locations.zipCodesInclude' | 'locations.countiesInclude';
type FiledNameExclude = 'locations.citiesExclude' | 'locations.statesExclude' | 'locations.zipCodesExclude' | 'locations.countiesExclude';
interface PermitLocationFilterPropsI {
  label: string;
  fieldNameInclude: FiledNameInclude;
  fieldNameExclude: FiledNameExclude;
  searchType: string;
  getLocationsCallback: (search: string, page: number, size: number) => Promise<PageResponseI<string>>;
}
export const PermitLocationFilter = (
  {
    label,
    fieldNameInclude,
    fieldNameExclude,
    searchType,
    getLocationsCallback,
  }: PermitLocationFilterPropsI
) => {
  const {searchInput, setSearchInput,
    fetchLocations, foundLocations,
    loadMoreLocations, couldLoadMoreLocations,
    totalLocationsFound,
    isLoading,
    locationsNotFound, clearSearch} = usePermitLocationSearch(
    {
      getLocationsCallback
    }
  );
  const {permitFilterForm} = usePermitFiltersContext();
  const {watch} = permitFilterForm;

  const allSelectedCities = useMemo(() => {
    const locationsInclude = watch(fieldNameInclude) ?? [];
    const locationsExclude = watch(fieldNameExclude) ?? [];
    return mergeTwoArrays(locationsInclude, locationsExclude);
  }, [watch(fieldNameInclude), watch(fieldNameExclude)]);

  return (
    <LocationFilterWrapper>
      <CollapseFilter
        label={label}
        count={allSelectedCities.length}
        opened={true}
        small={true}
      >
        <LocationSearchTab
          searchType={searchType}
          searchInputValue={searchInput}
          setSearchInputValue={(e: string) => setSearchInput(e)}
          selectedLocations={allSelectedCities}
          fieldNameInclude={fieldNameInclude}
          fieldNameExclude={fieldNameExclude}
          handleSearch={fetchLocations}
          foundLocations={foundLocations}
          loadMoreLocations={loadMoreLocations}
          couldLoadMoreLocations={couldLoadMoreLocations}
          totalFoundLocations={totalLocationsFound}
          isLoading={isLoading}
          noSearchResults={locationsNotFound}
          onClear={clearSearch}
        />
      </CollapseFilter>
    </LocationFilterWrapper>
  )
}
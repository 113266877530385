import React from 'react';
import { Box } from "@mui/system";
import theme from "application/theme";
import { Typography } from "@mui/material";
import { StyledButton } from "./styles";
import { CloudIcon } from "application/assets/CloudIcon";
import { Button } from "application";
import { Add } from "@mui/icons-material";

interface Props {
  itemsSelected: number;
  totalItemsSelected: number;
  onClearSelection: () => void;
  onExportToCsv: () => void;
  onExportToHubspot: () => void;
  showExportToHubspot: boolean;
}

export const ExportContactsBar = ({
  itemsSelected,
  totalItemsSelected,
  onClearSelection,
  onExportToCsv,
  onExportToHubspot,
  showExportToHubspot}: Props) => {

  return (
    <>
      <Box display="flex" gap={1.5}>
        <Typography variant="button" color={theme.palette.common.grey900}>
          {itemsSelected}&nbsp;contact(s) selected
        </Typography>
        <StyledButton variant="button" color={theme.palette.common.blue700}>
          {totalItemsSelected}&nbsp; total contact(s) selected
        </StyledButton>
        <StyledButton
          variant="button"
          color={theme.palette.common.blue700}
          onClick={onClearSelection}
        >
          Clear selection
        </StyledButton>
      </Box>
      <Box display="flex">
        <Button
          label="Export as CSV"
          startAdornment={
            <CloudIcon color={theme.palette.common.grey800} />
          }
          mr={2}
          onClick={onExportToCsv}
          type="button"
        />
        {showExportToHubspot && (
          <Button
            label="Export to Hubspot"
            type="submit"
            startAdornment={
              <Add sx={{color: theme.palette.common.white}}/>
            }
            onClick={onExportToHubspot}/>
        )}
      </Box>
    </>
  )

}
import { styled } from '@mui/system';
import { Switch } from '@mui/material';
import theme from 'application/theme';

export const ToggleButtonStyled = styled(Switch)`
  width: ${theme.spacing(4.5)};
  height: ${theme.spacing(2.5)};
  padding: 0;

  & .MuiSwitch-switchBase {
    padding: 0;
    margin: ${theme.spacing(0.25)};
    transition-duration: 300ms;

    &.Mui-checked {
      transform: translateX(${theme.spacing(2)});
      color: ${theme.palette.common.white};

      & + .MuiSwitch-track {
        background-color: ${theme.palette.common.blue700};
        opacity: 1;
        border: 0;
      }

      &.Mui-disabled + .MuiSwitch-track {
        opacity: 0.5;
      }
    }

    &.Mui-disabled + .MuiSwitch-track {
      opacity: 0.3;
    }
  }

  & .MuiSwitch-thumb {
    box-sizing: border-box;
    width: ${theme.spacing(2)};
    height: ${theme.spacing(2)};
  }

  & .MuiSwitch-track {
    border-radius: ${theme.spacing(1.5)};
    background-color: ${theme.palette.common.grey400};
    opacity: 1;
  }
`;
import { Box, styled } from '@mui/system';
import theme from 'application/theme';
import { Collapse } from '@mui/material';


export const FiltersGroupWrapper = styled(Box)`
  border-radius: ${theme.spacing(0.5)};
  overflow: hidden;
`

export const FilterCollapseStyled = styled(Collapse)`
  background-color: ${theme.palette.common.blue50};
`

export const FiltersGroupCollapseLabelWrapper = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: ${theme.spacing(1, 1.5)};
  background-color: ${theme.palette.common.blue100};
  
`

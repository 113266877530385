import { Box, styled } from '@mui/system';
import { Typography } from '@mui/material';
import theme from 'application/theme';

export const AddAffiliationStyled = styled(Typography)<{disabled?: boolean}>`
  cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer'};
  margin-left: ${theme.spacing(1.5)};
  font-size: ${theme.spacing(2)};
  color: ${(props) => props.disabled ? theme.palette.common.grey400 : theme.palette.common.blue700};
  font-weight: 400;
`;

export const RemoveAffiliationStyled = styled(Typography)`
  svg {
    width: ${theme.spacing(2.5)};
    height: ${theme.spacing(2.5)};
  }
  cursor: pointer;
`
export const SelectedAffiliationNameStyled = styled(Typography)`
  font-size: ${theme.typography.pxToRem(14)};
  font-weight: 400;
  color: ${theme.palette.common.grey900};
  line-height: 140%;
`;

export const SelectedAffiliationTypesStyled = styled(SelectedAffiliationNameStyled)`
  color: ${theme.palette.common.grey600};
`;

export const SelectedAffiliationWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(0.5)};
  margin: ${theme.spacing(1.5, 0)};
`;

import React, { FC, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Button, FormInput, SelectComponent } from "application";
import { SurveyFormDataT } from "../types";
import { StyledButtonWrapper } from "./styles";
import { saveOrganizationSurvey } from "integration/api/organizations";
import SaveSurveyModal from "../surveyModal/SaveSurveyModal";
import { useSurveyForm } from "./useSurveyForm";
import theme from "application/theme";
import { handleGetTimezoneItems } from "common/dictionary";
import { useAppDispatch } from "application/store";
import { handleError, hideLoader, showLoader, timezoneSelector } from "common";
import { useSelector } from "react-redux";
import { formatDateToYYYYMMDD } from "application/utils";

const BUILDERBINDER_HOMEPAGE = process.env.REACT_APP_BUILDERBINDER_HOMEPAGE;

export const SurveyForm: FC = () => {
  const [modalIsOpened, setModalIsOpened] = useState(false);
  const dispatch = useAppDispatch();
  const timezoneOptions = useSelector(timezoneSelector);
  const { register, handleSubmit, reset, errors } = useSurveyForm();

  useEffect(() => {
    dispatch(handleGetTimezoneItems());
  }, []);

  const onSubmit = async (data: SurveyFormDataT) => {
    dispatch(showLoader());
    const response: any = await saveOrganizationSurvey({
      ...data,
      requestDate: formatDateToYYYYMMDD(new Date()),
    }).catch((error: any) => {
      handleError(error);
    });
    dispatch(hideLoader());
    if (response) {
      setModalIsOpened(true);
      handleResetForm();
    }
  };

  const handleBackToHomepage = () => {
    setModalIsOpened(false);
    BUILDERBINDER_HOMEPAGE && window.location.replace(BUILDERBINDER_HOMEPAGE);
  };

  const handleResetForm = () => {
    reset();
  };
  return (
    <>
      <SaveSurveyModal open={modalIsOpened} onClose={handleBackToHomepage} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={6}>
            <FormInput
              label={"Your first name"}
              name={"contactFirstName"}
              register={register("contactFirstName")}
              error={errors.contactFirstName?.message}
              placeholder={"Name"}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              label={"Your last name"}
              name={"contactLastName"}
              register={register("contactLastName")}
              error={errors.contactLastName?.message}
              placeholder={"Surname"}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              label={"Your phone"}
              name={"contactPhone"}
              register={register("contactPhone")}
              error={errors.contactPhone?.message}
              placeholder={"Enter phone"}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              label={"Your work email"}
              name={"contactEmail"}
              type={"email"}
              register={register("contactEmail")}
              error={errors.contactEmail?.message}
              placeholder={"email@gmail.com"}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              label={"Your company name"}
              name={"organizationName"}
              register={register("organizationName")}
              error={errors.organizationName?.message}
              placeholder={"Enter your company name"}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              label={"Company website"}
              name={"organizationWebsite"}
              type={"website"}
              register={register("organizationWebsite")}
              error={errors.organizationWebsite?.message}
              placeholder={"Enter your company website"}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectComponent
              placeholder="Select Time Zone"
              label="Time Zone"
              options={timezoneOptions}
              defaultValue={"+14:00"}
              name="timezone"
              error={errors.timezone?.message}
              register={register("timezone")}
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <Typography
                variant="overline"
                color={theme.palette.common.grey800}
                align="right"
              >
                You’ll hear back from us within 24 hours.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: 2 }}>
            <StyledButtonWrapper>
              <Button
                label="Cancel"
                width={theme.spacing(15)}
                type="button"
                onClick={handleResetForm}
              />
              <Button
                label="Send"
                width={theme.spacing(15)}
                type="submit"
                onClick={handleSubmit(onSubmit)}
              />
            </StyledButtonWrapper>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

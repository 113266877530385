import React from "react";

export const CheckListIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M7.50649 4.22228C6.35128 4.22313 5.74575 4.23709 5.27639 4.46443C4.83653 4.67748 4.47891 5.01744 4.25479 5.43558C4 5.91094 4 6.53322 4 7.77778V18.4444C4 19.689 4 20.3113 4.25479 20.7866C4.47891 21.2048 4.83653 21.5447 5.27639 21.7578C5.77644 22 6.43105 22 7.74026 22H16.6234C17.9326 22 18.5872 22 19.0872 21.7578C19.5271 21.5447 19.8847 21.2048 20.1088 20.7866C20.3636 20.3113 20.3636 19.689 20.3636 18.4444V7.77778C20.3636 6.53322 20.3636 5.91094 20.1088 5.43558C19.8847 5.01744 19.5271 4.67748 19.0872 4.46443C18.6179 4.23709 18.0124 4.22313 16.8571 4.22228M7.50649 4.22228V6.44444H16.8571V4.22228M7.50649 4.22228V3.89679C7.50649 3.39373 7.71671 2.91127 8.09091 2.55556C8.4651 2.19984 8.97262 2 9.50181 2H14.8618C15.391 2 15.8985 2.19984 16.2727 2.55556C16.6469 2.91127 16.8571 3.39373 16.8571 3.89679V4.22228M16.8571 10.8889H14.5195M16.8571 16.4444H14.5195M7.50649 10.8889L8.67532 12L11.013 9.77778M7.50649 16.4444L8.67532 17.5556L11.013 15.3333"
                stroke="#818181" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

import React from "react";
import { MultiSelectItem } from "./MultiSelectItem";
import { CheckboxGroupStyled } from "./styles";
import { Box } from "@mui/system";
export interface MultiSelectOptionI {
  label: string;
  value?: any;
}

interface MultiSelectProps {
  options: MultiSelectOptionI[];
  selectedOptions: any[];
  getKeyFromValue?: (value: any) => string;
  onChangeSelected: (selectedOptions: any) => void;
  height?: number;
}
export const MultiSelect = ({
  options,
  selectedOptions,
  onChangeSelected,
  getKeyFromValue,
  height,
}: MultiSelectProps) => {
  const handleSelect = (value: any, checked: boolean) => {
    if (!checked) {
      onChangeSelected([...selectedOptions, value]);
    } else if(getKeyFromValue){
      onChangeSelected(selectedOptions.filter((option: any) => getKeyFromValue(option) !== getKeyFromValue(value)));
    } else {
      onChangeSelected(selectedOptions.filter((option: any) => option !== value));
    }
  }

  const isOptionSelected = (value: any) => {
    if(getKeyFromValue){
      return selectedOptions.some((selectedOption: any) => getKeyFromValue(selectedOption) === getKeyFromValue(value));
    } else {
      return selectedOptions.some((selectedOption: any) => selectedOption === value);
    }
  }

  const getKey = (option: MultiSelectOptionI) => {
    if(getKeyFromValue){
      return getKeyFromValue(option.value);
    }
    if(option.value){
      return option.value;
    }
    return option.label;
  }

  return (
    <CheckboxGroupStyled height={height}>
      {options.map((option: MultiSelectOptionI) => (
        <Box key={getKey(option)}>
          <MultiSelectItem
            checked={isOptionSelected(option.value || option.label)}
            label={option.label}
            value={option.value || option.label}
            onClick={handleSelect}
          />
        </Box>
      ))}
    </CheckboxGroupStyled>
  )
}

import React from 'react';
import { Box } from '@mui/system';
import { Paper, Table, TableContainer } from '@mui/material';
import theme from 'application/theme';
import { Pagination } from 'application';
import { StructureWrapper } from './styles';
import EmptyTable from './EmptyTable';
import { useCompaniesSearch } from 'common/companies/hooks/useCompaniesSearch';

interface Props {
  totalElements: number;
  numberOfElements: number;
  totalPages: number;
  pageRequest: any;
  children: JSX.Element[] | JSX.Element;
  contentIsEmpty: boolean;
}

const CompaniesTableWrapper = (
  {
    children,
    totalPages,
    totalElements,
    numberOfElements,
    pageRequest,
    contentIsEmpty
  }: Props
) => {
  const { pageNumber, pageSize} = useCompaniesSearch();
  return (
    <Box display="flex" flexDirection="column" gap={1.5} width="100%">
      <StructureWrapper>
        <TableContainer
          component={Paper}
          sx={{boxShadow: "none", borderRadius: theme.spacing(1)}}
        >
          <Table sx={{minWidth: 350}} aria-label="simple table">
            {children}
          </Table>
          {contentIsEmpty && <EmptyTable/>}
        </TableContainer>
        <Pagination
          totalElements={totalElements}
          numberOfElements={numberOfElements}
          totalPages={totalPages}
          pageRequest={pageRequest}
          currentPage={pageNumber}
          pageSize={pageSize}
          hidePageSelector={true}
        />
      </StructureWrapper>
    </Box>
  )
}
export default CompaniesTableWrapper;
import React from 'react';
import { Box } from '@mui/system';
import theme from 'application/theme';
import { ModalComponent } from 'application/components/ModalComponent';
import { QuestionCircleIcon } from 'application/assets';
import { ModalContentWrapper } from './styles';
import { Button } from 'application';
import { useLargeBulkExportNew } from '../../../hooks/useLargeBulkExportNew';
import { LargeBulkExportScope } from 'common/largeBulkExport/store/types';
import { DescriptionTextStyled } from '../../StartExportModal/styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const SelectExportScopeModal = ({isOpen, onClose}: Props) => {
  const { startBulkExport } = useLargeBulkExportNew();

  const handleStartExportPermits = () => {
    startBulkExport(LargeBulkExportScope.PERMITS_BY_COORDINATES);
    onClose();
  }
  const handleStartExportCompanies = () => {
    startBulkExport(LargeBulkExportScope.COMPANIES_BY_COORDINATES);
    onClose();
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      padding={4}
      width={theme.spacing(50)}
      handleClose={onClose}
    >
      <ModalContentWrapper>
        <QuestionCircleIcon/>
        <DescriptionTextStyled>
          {'Please select what kind of data would you like to export:'}
        </DescriptionTextStyled>
        <Box display="flex" gap={2}>
          <Button
            label="Export permits"
            onClick={handleStartExportPermits}
            height={5.5}
            type="submit"
          />
          <Button
            label="Export companies"
            onClick={handleStartExportCompanies}
            height={5.5}
            type="submit"
          />
        </Box>
      </ModalContentWrapper>
    </ModalComponent>
  )
}

import React from "react";
import theme from '../theme';

export const ArrowOutward = ({ color=theme.palette.common.blue800 }: { color?: string }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icons">
        <mask
          id="mask0_1118_1604"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <rect id="Bounding box" width="20" height="20" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1118_1604)">
          <path
            id="arrow_outward"
            d="M5.33366 15.0003L4.16699 13.8337L12.167 5.83366H5.00033V4.16699H15.0003V14.167H13.3337V7.00033L5.33366 15.0003Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

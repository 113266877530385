import { PageResponseI } from "application/types";
import { CompanyFilterDataI, CompanyI } from "integration/api/companies/types";

export enum PermitsView {
  MAP = "map",
  TABLE = "table",
}

export enum PermitsDisplayMode {
  PERMITS = "permits",
  COMPANIES = "companies",
}

export enum PermitAffiliationType {
  OWNER = "OWNER",
  DESIGNER = "DESIGNER",
  ARCHITECT = "ARCHITECT",
  ENGINEER = "ENGINEER",
  CONTRACTOR_COMPANY = "CONTRACTOR_COMPANY",
  CONTRACTOR_NAME = "CONTRACTOR_NAME",
}

export interface PermitAffiliationI {
  name: string;
  types: PermitAffiliationType[];
}

export enum FilterStatus {
  inReview = "IN_REVIEW",
  active = "ACTIVE",
  final = "FINAL",
}

export interface PermitStatusI {
  status: FilterStatus;
  current: boolean;
  to: string | null;
  from: string | null;
}

export interface ZoomedAreaI {
  minLongitude: number;
  minLatitude: number;
  maxLongitude: number;
  maxLatitude: number;
}

export interface PermitFilterDataI {
  permitValue: {
    min?: number;
    max?: number;
  };
  affiliations: PermitAffiliationI[];
  squareFootage: {
    min?: number;
    max?: number;
  };
  tags: string[];
  permitTypes: string[];
  dwellingTypes: string[];
  statusFilter: PermitStatusI[];
  productTypes: ProductTypeI[];
  zoomedArea?: ZoomedAreaI;
  locations: {
    statesInclude: string[];
    statesExclude: string[];
    citiesInclude: string[];
    citiesExclude: string[];
    zipCodesInclude: string[];
    zipCodesExclude: string[];
    countiesInclude: string[];
    countiesExclude: string[];
  };
}

export interface PermitWithCompanyFilterDataI {
  companyFilter: CompanyFilterDataI;
  permitFilter: PermitFilterDataI;
}

export interface CoordinatesContent {
  id: number;
  lt: number;
  lg: number;
}

export interface FeaturesArray {
  type: string;
  properties: {
    id: number;
  };
  geometry: { type: string; coordinates: [number, number] };
}

export interface PermitsInfo {
  city: string | null;
  commercial: boolean;
  contractorBizNameOriginal: string | null;
  contractorCity: string | null;
  contractorEmail: string | null;
  contractorLicense: string | null;
  contractorName: string | null;
  contractorPhone: string | null;
  contractorState: string | null;
  contractorStreet: string | null;
  contractorZipcode: string | null;
  county: string | null;
  description: string | null;
  designerCity: string | null;
  designerEmail: string | null;
  designerLicense: string | null;
  designerName: string | null;
  designerPhone: string | null;
  designerState: string | null;
  designerStreet: string | null;
  designerZipcode: string | null;
  dwellingType: string | null;
  engineerCity: string | null;
  engineerEmail: string | null;
  engineerLicense: string | null;
  engineerName: string | null;
  engineerPhone: string | null;
  engineerState: string | null;
  engineerStreet: string | null;
  engineerZipcode: string | null;
  fileDate: string | null;
  filePermitTimeframe: string | null;
  finalDate: string | null;
  id: number;
  jobValue: number;
  latitude: number;
  longitude: number;
  ownerCity: string | null;
  ownerEmail: string | null;
  ownerName: string | null;
  ownerPhone: string | null;
  ownerState: string | null;
  ownerStreet: string | null;
  ownerZipcode: string | null;
  permitDate: string | null;
  permitFinalTimeframe: string | null;
  permitUniqueId: string | null;
  residential: boolean;
  sizeSquareFeet: string | null;
  state: string | null;
  statusNormalized: string | null;
  street: string | null;
  totalFees: number;
  zipcode: string | null;
}

export interface PermitLocationI {
  city?: string;
  state?: string;
  zipcode?: string;
  county?: string;
  street?: string;
  latitude?: number;
  longitude?: number;
}
export interface PermitExecutorI {
  name?: string;
  companyBusinessName?: string;
  license?: string;
  street?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  email?: string;
  phone?: string;
}
export interface PermitInfoExtendedI {
  id?: number;
  statusNormalized?: string;
  jobValue?: number;
  totalFees?: number;
  fileDate?: string;
  permitDate?: string;
  finalDate?: string;
  filePermitTimeframe?: number;
  permitFinalTimeframe?: number;
  description?: string;
  commercial?: boolean;
  residential?: boolean;
  permitType?: string;
  dwellingType?: string;
  tags?: string[];
  permitLocation?: PermitLocationI;
  contractor?: PermitExecutorI;
  applicant?: PermitExecutorI;
  architect?: PermitExecutorI;
  engineer?: PermitExecutorI;
  designer?: PermitExecutorI;
  owner?: PermitExecutorI;
}

export interface CompaniesTooltipResponse {
  content: CompanyI[];
}

export interface PermitsTooltipResponse {
  content: PermitsInfo[];
}

export interface ProductTypeResponse {
  content: ProductTypeI[];
}

export const companyDataResponse: PageResponseI<CompanyI> = {
  content: [],
  empty: true,
  first: true,
  last: true,
  number: 0,
  numberOfElements: 0,
  totalPages: 0,
  totalElements: 0,
  size: 20,
};

export const permitsDataResponse: PageResponseI<PermitsInfo> = {
  content: [],
  empty: true,
  first: true,
  last: true,
  number: 0,
  numberOfElements: 0,
  totalPages: 0,
  totalElements: 0,
  size: 20,
};

export interface SinglePermitExportRequestI {
  permitDataId: number;
  organizationId: number;
  sdrId: number;
}

export interface MultiplePermitsExportRequestI {
  permitIds: number[];
  organizationId: number;
}

export interface PermitExportResponseI {
  companyDataCreditsCharged: number;
  s3FileUrl: string;
}

export type TextAlignType =
  | "start"
  | "right"
  | "left"
  | "center"
  | "end"
  | undefined;

export interface ProductNameI {
  id: number;
  name: string;
}

export interface ProductBrandI {
  id: number;
  name: string;
  names?: ProductNameI[];
}

export interface ProductTypeI {
  id: number;
  name: string;
  brands?: ProductBrandI[];
}

export interface SavedPermitAndCompanyFiltersI {
  id?: number;
  name: string;
  filterValue: PermitWithCompanyFilterDataI;
}

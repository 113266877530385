import { Box, styled } from "@mui/system";
import theme from "application/theme";

export const MapViewWrapper = styled(Box)`
  height: calc(100% - ${theme.spacing(2)});
  width: 100%;
  position: relative;
  border-radius: 8px;
  border: 1px solid var(--Grey-400, #c9c9c9);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  overflow: hidden;
  .mapboxgl-popup-close-button {
    display: none;
  }
  .mapboxgl-popup-content {
    padding: 0;
    width: fit-content;
    border-radius: 12px;
  }
  .mapboxgl-popup-anchor-bottom {
    max-width: 100% !important;
  }
  .mapboxgl-ctrl-attrib-inner {
    display: none;
  }
  .mapboxgl-ctrl-logo {
    display: none !important;
  }
`;

export const LoaderWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import React, { FC, useState } from "react";
import { Typography } from "@mui/material";
import {
  InfoWrapper,
  FormWrapper,
  StyledCheckbox,
  PrivacyLink,
} from "../styles";
import { Box, useTheme } from "@mui/system";
import { MTheme } from "application/theme";
import { AuthPageWrapper } from "../AuthPageLayout";
import { useCreateNewPassword } from "./useCreateNewPassword";
import { CreatePasswordForm } from "../PasswordInputs/CreatePasswordForm";
import { SearchPage } from "../constants";
import { useLocation } from "react-router-dom";
import { Button } from "application";

export const CreateNewPassword: FC = () => {
  const theme: MTheme = useTheme();
  const { createNewPassword } = useCreateNewPassword();
  const [password, setPassword] = useState<string | null>(null);
  const [checked, setChecked] = useState<boolean>(false);
  const location = useLocation();
  const firstAuth = location.search === SearchPage.welcome;
  const email = location.search.split("?email=").pop()?.split("&")[0] ?? "";
  const code = location.search.split("code=")[1] ?? "";

  const onSubmit = async () => {
    if (password) {
      createNewPassword({
        email,
        verificationCode: code,
        newPassword: password,
      });
    }
  };

  return (
    <AuthPageWrapper>
      <InfoWrapper mt={12}>
        {firstAuth && (
          <Box display="flex">
            <Typography
              variant="h3"
              mb={1.5}
              color={theme.palette.common.grey900}
            >
              Hello,&nbsp;
            </Typography>
            <Typography
              variant="h3"
              mb={1.5}
              color={theme.palette.common.turquoise300}
            >
              name of user.
            </Typography>
          </Box>
        )}
        <Typography variant="h3" mb={1.5} color={theme.palette.common.grey900}>
          {firstAuth ? "Welcome to BuilderBinder!" : "Create New Password"}
        </Typography>
        <Typography variant="h5" color={theme.palette.common.grey800} mb={6}>
          Please, create new password for your <p>account</p>
        </Typography>
        <FormWrapper>
          <CreatePasswordForm getPassword={setPassword} />
          {firstAuth && (
            <Box mb={3.5}>
              <StyledCheckbox onChange={() => setChecked(!checked)} />
              <Typography
                variant="caption"
                color={theme.palette.common.grey800}
              >
                I agree <PrivacyLink to={"#"}>Privacy Policy</PrivacyLink> and{" "}
                <PrivacyLink to={"#"}>Term of a Service</PrivacyLink>
              </Typography>
            </Box>
          )}
          <Button
            label={firstAuth ? "Set password and continue " : "Set password"}
            type="submit"
            variant="contained"
            width="100%"
            onClick={onSubmit}
            disabled={firstAuth ? !checked : false}
          />
        </FormWrapper>
      </InfoWrapper>
    </AuthPageWrapper>
  );
};

import { Box, styled } from "@mui/system";
import theme from "application/theme";

export const NavBarStyled = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${theme.spacing(2.5)};
  gap: ${theme.spacing(2)};
`;

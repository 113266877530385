import {Box, styled} from "@mui/system";
import theme from "application/theme";

export const PermitInfoItemStyled = styled(Box)`
    display: flex;
    gap: ${theme.spacing(1)};
`

export const InfoItemsWrapper = styled(Box)`
  display: flex;
  flex:1;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

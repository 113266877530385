import React from 'react';
import { Box } from '@mui/system';
import { FiltersGroupCollapseLabel } from './FiltersGroupCollapseLabel';
import { FilterCollapseStyled, FiltersGroupWrapper } from './styles';

interface Props {
  label: string;
  children: React.ReactNode;
}
export const FiltersGroup = ({label, children}: Props) => {
  const [expanded, setExpanded] = React.useState(false);

  return (<FiltersGroupWrapper>
    <FiltersGroupCollapseLabel
      label={label}
      expanded={expanded}
      setExpanded={setExpanded}
    />
    <FilterCollapseStyled in={expanded}>
      <Box paddingX={1} paddingY={1.5}>
        {children}
      </Box>
    </FilterCollapseStyled>
  </FiltersGroupWrapper>
  )
}

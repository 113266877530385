import React from "react";
import { useAppSelector } from "application/store";
import { selectedPermitSelector } from "common/permits/store";
import {
  BottomMenuStyled,
  PermitInfoContainerStyled,
  PermitInfoContentWrapper,
} from "./styles";
import { PermitInfoSection } from "./components/PermitInfoSection";
import { ExportPermitDataButton, PermitGeneralInfo } from "./components";
import { PermitLocationInfo } from "./components/PermitLocationInfo";
import { ExecutorInfo } from "./components/ExecutorInfo";
import { PermitExecutorI } from "common/permits/types";
import { ExecutorSection } from "./components/ExecutorSection";

export const PermitInfo = () => {
  const selectedPermit = useAppSelector(selectedPermitSelector);
  const executorSectionsInfo = [
    {
      id: "contractor",
      title: "Contractor",
      executor: selectedPermit?.contractor,
    },
    {
      id: "owner",
      title: "Owner",
      executor: selectedPermit?.owner,
    },
    {
      id: "applicant",
      title: "Applicant",
      executor: selectedPermit?.applicant,
    },
    {
      id: "architect",
      title: "Architect",
      executor: selectedPermit?.architect,
    },
    {
      id: "engineer",
      title: "Engineer",
      executor: selectedPermit?.engineer,
    },
    {
      id: "designer",
      title: "Designer",
      executor: selectedPermit?.designer,
    },
  ];

  return (
    <PermitInfoContainerStyled>
      <PermitInfoContentWrapper>
        <PermitInfoSection title={"General info"}>
          <PermitGeneralInfo />
        </PermitInfoSection>

        <PermitInfoSection title={"Permit location"}>
          <PermitLocationInfo />
        </PermitInfoSection>
        {executorSectionsInfo.map((e) => (
          <ExecutorSection key={e.id} title={e.title} executor={e.executor} />
        ))}
      </PermitInfoContentWrapper>
      <BottomMenuStyled>
        <ExportPermitDataButton />
      </BottomMenuStyled>
    </PermitInfoContainerStyled>
  );
};

import React from 'react';
import { useAppSelector } from 'application/store';
import { filterFormSelector } from 'common/companies/store';
import { FiltersCollapse } from 'application';
import { FiltersWrapper } from './styles';
import { CheckedFilterItem } from './CheckedFilterItem';
import { getReputationScoreLabel } from 'common/companies/companiesFilter/utils';

export const VisibilityScoreFilter = () => {
  const {reputationScore} = useAppSelector(filterFormSelector);
  return reputationScore ? (
    <FiltersCollapse label={'VISIBILITY SCORE'} count={1}>
      <FiltersWrapper>
        <CheckedFilterItem label={getReputationScoreLabel(reputationScore)}/>
      </FiltersWrapper>
    </FiltersCollapse>
  ) : null;
};

import React from 'react';
import { useAppSelector } from 'application/store';
import { FiltersCollapse } from 'application';
import { FilterGroupWrapper, FiltersWrapper, FilterTextStyled, FilterTitleStyled } from '../components/styles';
import { formatLargeNumbers } from 'application/utils';
import { companyFiltersSelector } from 'common/permits/store';

export const CompanyPermitFilter = () => {
  const {hasPermit, permitFilters} = useAppSelector(companyFiltersSelector);
  return hasPermit ? (
    <FiltersCollapse label={'PERMIT'} count={1}>
      <FiltersWrapper>
        <FilterGroupWrapper>
          <FilterTitleStyled>Annual permit count</FilterTitleStyled>
          <FilterTextStyled>
            {`${formatLargeNumbers(permitFilters?.annualPermitCount?.min || 0)}
             - ${formatLargeNumbers(permitFilters?.annualPermitCount?.max || 0)}`}
          </FilterTextStyled>
        </FilterGroupWrapper>
        <FilterGroupWrapper>
          <FilterTitleStyled>Annual permit value</FilterTitleStyled>
          <FilterTextStyled>
            {`$${formatLargeNumbers(permitFilters?.annualPermitValue?.min || 0)}
             - $${formatLargeNumbers(permitFilters?.annualPermitValue?.max || 0)}`}
          </FilterTextStyled>
        </FilterGroupWrapper>
      </FiltersWrapper>
    </FiltersCollapse>
  ) : null;
}
import { styled } from "@mui/system";
import { Box } from "@mui/material";
import theme from "../../../application/theme";

export const StyledSurveyIntroWrapper = styled(Box)`
  background-color: ${theme.palette.common.orange50};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - ${theme.spacing(16)});
  padding: ${theme.spacing(12, 12, 4)};
  border-radius: ${theme.spacing(2)};
`;

import { Button } from "application";
import React from "react";
import { usePermitsNavigation } from "common/permits/hooks/usePermitsNavigation";
import { PermitsView } from "common/permits/types";
import { MapViewIcon, TableViewIcon } from "common/permits/assets";
import { permitsSelector, setSearchChanged, setSearchInArea, setShowOnMap } from "common/permits/store";
import { useAppDispatch, useAppSelector } from "application/store";
import {
  handleSearchPermitsAndCompaniesOnBackground,
  handleViewOnMapSearch,
} from "common/permits/store/actions";
import { usePermitFiltersContext } from "common/permits/hooks/usePermitFiltersContext";

export const ViewToggleButton = () => {
  const { isMapView, toggleViewMode } = usePermitsNavigation();
  const { searchChanged, zoomedArea } = useAppSelector(permitsSelector);
  const { permitFilterForm, companyFilterForm } = usePermitFiltersContext();
  const dispatch = useAppDispatch();
  const handleToggleView = () => {
    if (isMapView) {
      const permitFilter = permitFilterForm.watch();
      const companyFilter = companyFilterForm.watch();
      dispatch(setSearchChanged(false));
      dispatch(setSearchInArea(true));
      dispatch(
        handleSearchPermitsAndCompaniesOnBackground(
          { permitFilter, companyFilter },
          0,
          20,
          false,
          zoomedArea
        )
      );
    } else if (searchChanged) {
      const permitFilter = permitFilterForm.watch();
      const companyFilter = companyFilterForm.watch();
      dispatch(setSearchInArea(false));
      dispatch(setShowOnMap(true));
      dispatch(handleViewOnMapSearch({ permitFilter, companyFilter }));
    }
    toggleViewMode(isMapView ? PermitsView.TABLE : PermitsView.MAP);
  };

  return (
    <Button
      label={isMapView ? "View in table" : "View on map"}
      startAdornment={isMapView ? <TableViewIcon /> : <MapViewIcon />}
      height={4.5}
      onClick={handleToggleView}
      type="button"
    />
  );
};

export enum PathNames {
  home = "/",
  login = "/login",
  registration = "/registration",
  confirmChange = "/confirm-password",
  forgotPassword = "/forgot-password",
  createPassword = "/create-new-password",
  error = "/error",
  companies = "/companies",
  permits = "/permits",
  bulkExport = "/bulk-export",
  bulkExportCompany = "/bulk-export/company",
  bulkExportEmployees = "/bulk-export/employees",
  notifications = "/notifications",
  teamManagement = "/team-management",
  survey = "/survey",
  sdrProfile = "/sdr-profile",
}

export enum RoutesParam {
  requestHistory = "request-history",
  team = "team",
  subscriptionHistory = "subscription-history",
}

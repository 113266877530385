import React from "react";
import { PermitInfoSection } from "./PermitInfoSection";
import { ExecutorInfo } from "./ExecutorInfo";
import { PermitExecutorI } from "common/permits/types";
interface PropsI {
  executor?: PermitExecutorI;
  title: string;
}

export const ExecutorSection = ({ executor, title }: PropsI) => {
  const hardcodedValue = "99999";
  const showExecutorInfo = (value?: PermitExecutorI) => {
    const showValue =
      value?.license ||
      value?.companyBusinessName ||
      value?.name ||
      value?.state ||
      value?.city ||
      (value?.zipcode !== hardcodedValue && value?.zipcode);
    return showValue;
  };
  return (
    <>
      {showExecutorInfo(executor) ? (
        <PermitInfoSection title={title}>
          <ExecutorInfo executor={executor} />
        </PermitInfoSection>
      ) : (
        <></>
      )}
    </>
  );
};

import React, { useMemo, useState } from 'react';
import { SavedPermitFiltersWrapper } from './styles';
import { CollapseLabel } from 'common/companies/companiesFilter/components';
import { Collapse, Typography } from '@mui/material';
import { SaveFiltersItem } from './SaveFiltersItem';
import { Box } from '@mui/system';
import { useSavedFilters } from 'common/permits/hooks/useSavedFilters';
import {commonColors} from 'application/theme';
import { StarIcon } from 'application/assets';
import { FilterLabelWrapper, SavedFiltersWrapper } from './styles';
import { BinIcon } from 'application/assets/BinIcon';

export const SavedPermitFilters = () => {
  const [expanded, setExpanded] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const {savedSearches,
    selectedSavedSearch,
    getSavedFilters,
    applySavedSearch,
    deleteSavedSearch} = useSavedFilters();

  const itemsToDisplay = useMemo(
    () => (showAll ? savedSearches : savedSearches.slice(0, 5)),
    [savedSearches, showAll]
  );

  const filterIsSelected = (id: number) => {
    return selectedSavedSearch?.id === id;
  }

  return (
    <>
    <SavedPermitFiltersWrapper>
      <CollapseLabel
        label={'SAVED SEARCH'}
        expanded={expanded}
        setExpanded={setExpanded}
      />
      <Collapse in={expanded}>
        <Box pt={1.5}>
          {
            itemsToDisplay?.length > 0 ? itemsToDisplay.map((item : any, index) => (
                <SavedFiltersWrapper
                  key={item.id}
                  active={filterIsSelected(item.id)}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}
                    flexGrow={1}
                    onClick={() => applySavedSearch(item.id)}
                  >
                    <StarIcon size={24} color={commonColors.grey700}/>
                    <FilterLabelWrapper>{item.name || "-"}</FilterLabelWrapper>
                  </Box>
                  <Box
                    onClick={() => deleteSavedSearch(item.id)}
                    className="delete-icon"
                  >
                    <BinIcon />
                  </Box>
                </SavedFiltersWrapper>
              ))
              : <Typography variant="caption">No saved filters</Typography>

          }
        </Box>
      </Collapse>
    </SavedPermitFiltersWrapper>
    <SaveFiltersItem/>
  </>
  )
}
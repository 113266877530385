import theme from "application/theme";
import React from "react";

export const HubspotIcon = ({
  color = theme.palette.common.grey800,
}: {
  color?: string;
}) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2054_11195"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="24"
      >
        <rect x="0.75" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2054_11195)">
        <path
          d="M13.1367 10.7031L7.40625 6.66016C7.125 6.83594 6.80859 6.94141 6.45703 6.94141C5.4375 6.94141 4.62891 6.13281 4.62891 5.11328C4.62891 4.09375 5.4375 3.25 6.45703 3.25C7.47656 3.25 8.28516 4.09375 8.28516 5.11328C8.28516 5.25391 8.28516 5.42969 8.21484 5.57031C10.043 6.97656 13.207 9.22656 14.1562 9.96484C14.6133 9.71875 15.1406 9.57812 15.668 9.50781V7.32812C15.0352 7.08203 14.6836 6.51953 14.6836 5.85156C14.6836 4.90234 15.3867 4.16406 16.3008 4.16406C17.25 4.16406 17.9531 4.90234 17.9531 5.85156C17.9531 6.51953 17.6016 7.08203 16.9688 7.32812V9.50781C19.1836 9.85938 20.8359 11.7578 20.8359 14.043C20.8359 16.5742 18.7969 18.6133 16.2656 18.6133C15.2461 18.6133 14.2617 18.2617 13.4883 17.6641L11.7305 19.4219C11.7656 19.5625 11.8008 19.7031 11.8008 19.8438C11.8008 20.2305 11.6602 20.582 11.3789 20.8633C11.1328 21.1445 10.7461 21.25 10.3945 21.25C10.0078 21.25 9.65625 21.1094 9.375 20.8633C9.12891 20.582 8.95312 20.2305 8.95312 19.8438C8.95312 19.4922 9.12891 19.1055 9.375 18.8594C9.65625 18.5781 10.0078 18.4375 10.3945 18.4375C10.5703 18.4375 10.7109 18.4727 10.8867 18.5078L12.6094 16.7852C12.0469 16.0117 11.6953 15.0625 11.6953 14.043C11.6953 12.707 12.2578 11.5469 13.1367 10.7031ZM16.2656 16.4688C17.6367 16.4688 18.6914 15.3789 18.6914 14.043C18.6914 12.707 17.6367 11.6172 16.2656 11.6172C14.9297 11.6172 13.8398 12.707 13.8398 14.043C13.8398 15.3789 14.9297 16.4688 16.2656 16.4688Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

import React from "react";
import { Typography } from "@mui/material";
import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from "@mui/icons-material";
import theme from "application/theme";
import { ShowLessAndMoreWrapper } from "./styles";

interface Props {
  showLessText?: string;
  showMoreText?: string;
  showAll: boolean;
  setShowAll: (b: boolean) => void;
  fontSize?: string;
}

const ShowLessAndMore: React.FC<Props> = ({
  showLessText = "Show less",
  showMoreText = "Show more",
  showAll,
  setShowAll,
  fontSize = theme.spacing(2),
}) => {
  const handleClick = () => {
    setShowAll(!showAll);
  };

  return (
    <ShowLessAndMoreWrapper onClick={handleClick}>
      <Typography
        fontSize={fontSize}
        fontWeight={400}
        color={theme.palette.common.blue700}
      >
        {showAll ? showLessText : showMoreText}
      </Typography>
      {!showAll ? (
        <KeyboardArrowDownOutlined
          sx={{
            color: theme.palette.common.blue700,
          }}
        />
      ) : (
        <KeyboardArrowUpOutlined
          sx={{
            color: theme.palette.common.blue700,
          }}
        />
      )}
    </ShowLessAndMoreWrapper>
  );
};

export default ShowLessAndMore;

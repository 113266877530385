import React from "react";

import theme from "application/theme";
import BulkExportTabs from "common/bulkExport/BulkExportTable/tabs";
import NavBar from "common/bulkExport/NavBar";

import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { BulkExportHeader } from "common/bulkExport/BulkExportHeader";

const BulkExportPage = () => {
  return (
    <Box p={theme.spacing(4)}>
      <BulkExportHeader/>
      <Box display="flex" width="100%">
        <Box width="100%">
          <NavBar/>
          <BulkExportTabs />
        </Box>
        <Box mt={8.25}>
          <Box
            p={theme.spacing(2.5, 2)}
            width={theme.spacing(33)}
            ml={3}
            bgcolor={theme.palette.common.grey300}
            borderRadius={theme.spacing(1)}
          >
            <Typography
              variant="overline"
              fontWeight={500}
              color={theme.palette.common.grey900}
              mb={1}
            >
              What are shared contacts?
            </Typography>
            <Typography variant="caption" color={theme.palette.common.grey800}>
              Contacts that you add to this list will be shared with everyone in
              your company. This allows your team to keep a single source of
              truth for your contacts.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BulkExportPage;

import React from "react";
import { BadgeWrapper } from "./styles";
import awardsIcon from "../assets/award.svg";

interface PropsI {
  businessType: string;
}

const BadgeCompanyType = ({ businessType }: PropsI) => {
  return (
    <BadgeWrapper>
      {businessType === "GENERAL_CONTRACTOR" && (
        <img src={awardsIcon} alt="awards" />
      )}
      <span>{businessType ? businessType?.toLowerCase() : ""}</span>
    </BadgeWrapper>
  );
};

export default BadgeCompanyType;

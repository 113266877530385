import React from "react";

export const SuccessIcon = () => {
  return (
    <svg width="83" height="82" viewBox="0 0 83 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="41.5" cy="41" r="41" fill="#ECFDF3"/>
      <mask id="mask0_1041_31361" maskUnits="userSpaceOnUse" x="20" y="20" width="43"
            height="42">
        <rect x="20.5" y="20" width="42" height="42" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_1041_31361)">
        <path
          d="M37.2123 51.4998L27.2373 41.5248L29.7311 39.0311L37.2123 46.5123L53.2686 30.4561L55.7623 32.9498L37.2123 51.4998Z"
          fill="#42B271"/>
      </g>
    </svg>
  );
};

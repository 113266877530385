import React from "react";
import { Box, Typography } from "@mui/material";
import { ModalComponent } from "application/components/ModalComponent";
import theme from "application/theme";
import { Button } from "application";
import { HubspotModalIcon } from "application/assets/HubspotModalIcon";

export const ConnectToHubspotModal = ({
  isOpen,
  handleClose,
  onConfirmUrl,
}: {
  isOpen: boolean;
  handleClose: () => void;
  onConfirmUrl: string | null;
}) => {
  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };

  const onSubmit = () => {
    openInNewTab(onConfirmUrl || "#");
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleClose}
      padding={4}
      width={475}
    >
      <Box textAlign="center">
        <Box display="flex" justifyContent="center" mb={4}>
          <HubspotModalIcon />
        </Box>
        <Typography variant="h5" color={theme.palette.common.grey800} mb={2.5}>
          Allow BuilderBinder to Access your
          <br />
          Hubspot Account
        </Typography>
        <Typography
          variant="caption"
          color={theme.palette.common.grey700}
          display="block"
        >
          Link Hubspot to automatically dedupe against your existing
          <br />
          contacts/leads, bidirectionally sync activities, and enrich your
          <br />
          Hubspot records.
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          gap={theme.spacing(2)}
          mt={3}
        >
          <Button
            label="Cancel"
            type="button"
            width={theme.spacing(15)}
            onClick={handleClose}
          />
          <Button
            label="Continue"
            type="submit"
            width={theme.spacing(15)}
            onClick={onSubmit}
          />
        </Box>
      </Box>
    </ModalComponent>
  );
};

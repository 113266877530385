import { TextField } from "@mui/material";
import { styled } from "@mui/system";
import theme from "application/theme";

export const InputStyled = styled(TextField)<{
  height?: number;
  autofillcolor?: string;
  font_size?: string;
}>`
  height: ${(props) =>
    !!props.height ? theme.spacing(props.height) : theme.spacing(5.5)};
  color: ${theme.palette.common.grey800};
  font-weight: 500;

  & .MuiInputLabel-root {
    color: ${theme.palette.common.grey600};
    font-weight: 400;
    font-size: ${theme.spacing(1.75)};
  }

  & .MuiOutlinedInput-root {
    font-size: ${(props) =>
      props.font_size ? props.font_size : theme.spacing(2)};
    height: ${(props) =>
      !!props.height ? theme.spacing(props.height) : theme.spacing(5.5)};
    font-weight: 400;
    background-color: white;
    border-radius: ${theme.spacing(1)};
  }

  & .MuiFormHelperText-root {
    margin-top: ${theme.spacing(1)};
  }

  & fieldset {
    border-radius: ${theme.spacing(1)};
    border: ${theme.spacing(0.125)} solid ${theme.palette.common.grey400};
  }

  & label.Mui-focused {
    color: ${theme.palette.common.grey900};
  }

  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border: ${theme.spacing(0.125)} solid ${theme.palette.common.blue700};
    }
    &:hover fieldset {
      border: ${theme.spacing(0.125)} solid ${theme.palette.common.grey400};
      border-color: ${theme.palette.common.grey400};
    }
  }
  &.MuiOutlinedInput-root.Mui-error {
    &.MuiOutlinedInput-root.Mui-focused fieldset {
      border: ${theme.spacing(0.125)} solid ${theme.palette.common.red700};
    }
  }
  & .MuiInputLabel-root.Mui-error {
    color: ${theme.palette.common.red700};
    font-size: ${theme.spacing(1.75)};
  }
  & .MuiFormHelperText-root {
    margin-left: ${theme.spacing(0)};
  }
  input:-internal-autofill-selected {
    background-color: ${(props) =>
      !!props.autofillcolor
        ? props.autofillcolor
        : theme.palette.common.white} !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
`;

import React from 'react';
import { FiltersGroup } from '../FiltersGroup';
import { CompanyFiltersGroupWrapper } from './styles';
import {
  SkipExportedCompanies,
  CompanyTypeCollapse,
  PermitFilters,
  LocationCollapse,
  ReputationScoreCollapse,
  NumberOfEmployeesCollapse,
  AnnualRevenueCollapse,
  YearsInBusinessCollapse,
  ShowNotGoodFit,
  ServiceTypeCollapse,
  TagsCollapse
} from 'common/companies/companiesFilter/filters';
import { CompanyProductTypeFilter } from './CompanyProductTypeFilter';

export const CompanyFiltersGroup = () => {
  return (
    <FiltersGroup label={'COMPANY FILTERS'}>
      <CompanyFiltersGroupWrapper>
        <SkipExportedCompanies label="Skip exported companies" />
        <CompanyTypeCollapse label="COMPANY TYPE" />
        <ServiceTypeCollapse label="SERVICE TYPE"/>
        <PermitFilters label="Has permit" />
        <TagsCollapse label="TAGS" />
        <LocationCollapse label="LOCATION" />
        <ReputationScoreCollapse label="VISIBILITY SCORE" />
        <NumberOfEmployeesCollapse label="# OF EMPLOYEES" />
        <AnnualRevenueCollapse label="REVENUE" />
        <YearsInBusinessCollapse label="YEARS IN BUSINESS" />
        <CompanyProductTypeFilter/>
        <ShowNotGoodFit label={"Show only not a good fit"} />
      </CompanyFiltersGroupWrapper>
    </FiltersGroup>
  )
}

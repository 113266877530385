import React, { useState } from "react";
import { Headerwrapper, RatingNumberWrapper } from "./styles";
import { Typography } from "@mui/material";
import theme, { commonColors } from "application/theme";
import { Box } from "@mui/system";
import { labelByType, showReputationScore } from "common/companies/companiesList/utils";
import TextItem from "./TextItem";
import { getNumberFormatWithSeparators } from "application/utils";
import { CompanyI } from "integration/api/companies/types";
import { TooltipActions } from './TooltipActions';
import { useViewDetails } from 'common/permits/hooks/useViewDetails';
import { useCompaniesAndPermitsExport } from 'common/permits/hooks/useCompaniesAndPermitsExport';
import { ExportCompanyModal } from 'common/permits/components/ExportCompanyModal';
import { handleChangeOrganizationNotAGoodFitStatus } from 'common/companies/store/actions';
import { useAppDispatch } from 'application/store';

interface TooltipItemProps {
  show: boolean;
  data: CompanyI;
}

const CompaniesTooltipItem = ({show, data}: TooltipItemProps) => {
  const [isNotGoodFit, setIsNotGoodFit] = useState(false);
  const [addedToBulk, setAddedToBulk] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const {showCompanyDetails} = useViewDetails();
  const {addCompanyToBulkExport} = useCompaniesAndPermitsExport();
  const dispatch = useAppDispatch();
  const handleViewDetails = () => {
    showCompanyDetails(data.id);
  }
  const handleExportNow = () => {
    setShowExportModal(true);
  }

  const handleAddToBulkExport = () => {
    addCompanyToBulkExport(data.id).then((result) => {
      if (result) {
        setAddedToBulk(true);
      }
    });
  }
  const handleSetAsNotAGoodFit = async () => {
    const isSuccessful = await dispatch(handleChangeOrganizationNotAGoodFitStatus(data.id, !isNotGoodFit));
    if (isSuccessful) {
      setIsNotGoodFit(!isNotGoodFit);
    }
  }

  return (
    <Box sx={{display: show ? "block" : "none"}}>
      <Headerwrapper>
        <Typography
          variant="overline"
          color={commonColors.grey900}
          fontWeight={500}
          mr={theme.spacing(2.5)}
        >
          {data.businessName}
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography
            color={commonColors.grey900}
            variant="caption"
            mr={theme.spacing(1)}
          >
            {showReputationScore(data.reputationScore)}
          </Typography>
          <RatingNumberWrapper>
            <Typography
              color={commonColors.white}
              variant="overline"
              fontWeight={500}
            >
              {data.reputationScore / 10}
            </Typography>
          </RatingNumberWrapper>
        </Box>
      </Headerwrapper>
      <Box mb={theme.spacing(1.5)}>
        <TextItem label="Type" value={labelByType(data.serviceType)}/>
        <TextItem
          label="Major category"
          value={
            data.generalContractorCategory
              ? "General contractor"
              : "Subcontactor"
          }
        />
        {!data.generalContractorCategory && (
          <TextItem label="Minor category" value={data.subContractorCategory}/>
        )}
        <TextItem
          label="Average Permit Value"
          value={
            data.avgAnnualValue
              ? `$${getNumberFormatWithSeparators(data.avgAnnualValue)}`
              : " - "
          }
        />
      </Box>
      <TooltipActions
        onExportNow={handleExportNow}
        onAddToBulkExport={handleAddToBulkExport}
        onViewDetails={handleViewDetails}
        showNotGoodFit={true}
        isNotGoodFit={isNotGoodFit}
        onSetNotGoodFit={handleSetAsNotAGoodFit}
        addedToBulk={addedToBulk}
      />
      <ExportCompanyModal
        companyId={data.id}
        isOpen={showExportModal}
        onClose={() => setShowExportModal(false)}
      />
    </Box>
  );
};

export default CompaniesTooltipItem;

import { styled } from "@mui/system";
import { Box } from "@mui/material";
import theme from "application/theme";

export const DropdownWrapper = styled(Box)<{ visible: number }>`
  display: ${(props) => props.visible !== 1 && "none"};
  position: absolute;
  background-color: ${theme.palette.common.white};
  overflow: auto;
  top: ${theme.spacing(4.5)};
  left: 0;
  height: auto;
  max-height: ${theme.spacing(24)};
  padding: ${theme.spacing(0.5,0)};
  z-index: 1000;
  width: ${theme.spacing(24)};
  border-radius: ${theme.spacing(1)};
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.06), 0 1px 3px 0 rgba(16, 24, 40, 0.10);
`;

export const SelectContainer = styled(Box)`
  position: relative;
  margin: ${theme.spacing(0)};
  display: flex;
  align-items: center;
`;

export const SelectButton = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing(1,2)};
  cursor: pointer;
  height: ${theme.spacing(4.5)};
  background-color: ${theme.palette.common.white};
  border: 1px solid ${theme.palette.common.grey400};
  border-radius: ${theme.spacing(1)};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`;

import React, { FC } from "react";
import { Typography } from "@mui/material";

import {
  InfoWrapper,
  BackLink,
  CenteredWrapper,
  StyledDivider,
  LinkStyled,
  UserEmailStyled,
} from "../styles";
import { Box, useTheme } from "@mui/system";
import { MTheme } from "application/theme";
import { PathNames } from "application/routes";
import { BackArrow } from "../assets/BackArrow";
import { AuthPageWrapper } from "../AuthPageLayout";
import { authService } from "../authService";
import { useLocation } from "react-router-dom";
import { SearchPage } from "../constants";

export const ConfirmPasswordChange: FC = () => {
  const theme: MTheme = useTheme();
  const email = authService.getEmail();
  const location = useLocation();
  const expired = location.search === SearchPage.expired;

  return (
    <AuthPageWrapper>
      <Box sx={{ width: "100%" }}>
        <Box mb={9.25}>
          <BackLink to={PathNames.login}>
            <BackArrow />
            <Typography
              variant="overline"
              color={theme.palette.common.grey800}
              ml={0.5}
            >
              Back to Log In screen
            </Typography>
          </BackLink>
        </Box>
        <InfoWrapper>
          <Typography
            variant="h3"
            mb={1.5}
            color={theme.palette.common.grey900}
          >
            Reset Password
          </Typography>
          {!expired && (
            <Typography
              variant="h5"
              color={theme.palette.common.grey800}
              mb={6}
            >
              <p>We have sent a password recovery link</p>
              <span>
                to <UserEmailStyled>{email}</UserEmailStyled>
              </span>
              <p>Please, check your email. </p>
            </Typography>
          )}

          {!!expired ? (
            <Typography
              variant="overline"
              color={theme.palette.common.grey800}
              mb={6}
              mt={4.5}
            >
              This link has expired{" "}
              <LinkStyled to={PathNames.forgotPassword}>Resend link</LinkStyled>
            </Typography>
          ) : (
            <Typography
              variant="overline"
              color={theme.palette.common.grey800}
              mb={6}
            >
              This link still be active{" "}
              <Typography
                sx={{ display: "inline", fontWeight: 500 }}
                variant="overline"
                color={theme.palette.common.yellow900}
              >
                1 h
              </Typography>
            </Typography>
          )}

          <CenteredWrapper mb={1.5}>
            <StyledDivider />
            <Typography
              variant="caption"
              color={theme.palette.common.grey800}
              mr={2}
              ml={2}
            >
              or
            </Typography>
            <StyledDivider />
          </CenteredWrapper>

          <Typography
            variant="caption"
            color={theme.palette.common.grey800}
            sx={{ fontWeight: 500 }}
          >
            <p>Still no code in your inbox? Check your spam, or:</p>
            <LinkStyled to={PathNames.forgotPassword}>
              Use Other Email
            </LinkStyled>
          </Typography>
        </InfoWrapper>
      </Box>
    </AuthPageWrapper>
  );
};

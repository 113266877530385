import React from "react";

import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

import { CollapseLabelWrapper } from "./styles";
import { FilterCounter } from "../styles";
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import theme from "application/theme";

interface Props {
  label: string;
  expanded: boolean;
  setExpanded: (b: boolean) => void;
  count?: number;
}

const CollapseLabel = ({ label, expanded, setExpanded, count = 0 }: Props) => {
  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <CollapseLabelWrapper onClick={handleToggle} variant="caption">
      <Box display={"flex"} gap={0.5} alignItems={"center"}>
        <Typography
          variant="caption"
          color={theme.palette.common.grey700}
          letterSpacing={0.7}
        >
          {label}
        </Typography>
        {count ? <FilterCounter>{count}</FilterCounter> : <></>}
      </Box>
      {expanded ? (
        <KeyboardArrowDownOutlinedIcon />
      ) : (
        <ChevronRightOutlinedIcon />
      )}
    </CollapseLabelWrapper>
  );
};

export default CollapseLabel;

import React from 'react';
import { useAppSelector } from 'application/store';
import { FiltersCollapse } from 'application';
import { FiltersWrapper, FilterGroupWrapper } from '../components/styles';
import { CheckedFilterItem } from '../components/CheckedFilterItem';
import { getServiceTypeLabel } from 'common/companies/companiesFilter/utils';
import { companyFiltersSelector } from 'common/permits/store';

export const ServiceTypeFilter = () => {
  const {serviceType} = useAppSelector(companyFiltersSelector);
  return serviceType && serviceType.length > 0 ? (
    <FiltersCollapse label={'SERVICE TYPE'} count={serviceType.length}>
      <FiltersWrapper>
        <FilterGroupWrapper>
          {serviceType.map((item) => (
            <CheckedFilterItem
              key={item}
              label={getServiceTypeLabel(item)}/>
          ))}
        </FilterGroupWrapper>
      </FiltersWrapper>
    </FiltersCollapse>
  ) : <></>;
}

import React from "react";
import theme from "../../theme";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { StyledSwitch } from "./styles";

export interface FormSwitchProps {
  label: string;
  register: any;
  checked?: boolean;
  error?: string;
}

export const FormSwitch = ({
  label,
  error,
  register,
  checked,
}: FormSwitchProps) => {
  return (
    <Box sx={{ width: "100%" }} mb={theme.spacing(1)}>
      {label && (
        <Typography
          variant="caption"
          color={
            error ? theme.palette.common.red700 : theme.palette.common.grey800
          }
          sx={{ display: "block" }}
          mb={0.75}
        >
          {label}
        </Typography>
      )}
      <StyledSwitch checked={checked} {...register} />
    </Box>
  );
};

import React, { useState } from "react";
import { Box, CssBaseline } from "@mui/material";

import { SideBar } from "./sidebar/SideBar";
import { NavBar } from "./NavBar";

import {
  AppBarWrapper,
  AppBarSpacer,
  ContentContainer,
  MainContentWrapper,
} from "./styles";

interface MainLayoutProps {
  children: JSX.Element;
  withNavBar: boolean;
}

export const MainLayout = ({
  children,
  withNavBar = false,
}: MainLayoutProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {withNavBar && (
        <AppBarWrapper position="fixed" open={open}>
          <NavBar />
        </AppBarWrapper>
      )}
      <SideBar isOpen={open} setIsOpen={setOpen} />
      <MainContentWrapper onClick={() => setOpen(false)}>
        {withNavBar && <AppBarSpacer />}
        <ContentContainer maxWidth="lg">{children}</ContentContainer>
      </MainContentWrapper>
    </Box>
  );
};

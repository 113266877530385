import React, { useMemo } from "react";
import { StyledCheckbox } from "../../../styles";
import { useEmployeeBulkExport } from '../../../hooks/useEmployeeBulkExport';

interface SelectItemProps {
  externalId: number;
  companyId: string | null;
}
export const SelectEmployeeCheckbox = ({ externalId, companyId }: SelectItemProps) => {
  const {addEmployeeToSelected, isPhoneSelected, isEmailSelected } = useEmployeeBulkExport();

  const selected = useMemo(() => isPhoneSelected(externalId) || isEmailSelected(externalId),
    [externalId, isPhoneSelected, isEmailSelected]);

  const onSelect = () => {
    addEmployeeToSelected(externalId, companyId, !selected, !selected);
  };

  return <StyledCheckbox checked={selected} onClick={onSelect} />;
};

import React from 'react'
import { InfoCard } from "application";
import { Grid } from "@mui/material";

interface Props {
  phoneCredits: number;
  emailCredits: number;
  usedPhoneCredits: number;
  usedEmailCredits: number;
  companyDataCredits: number;
  usedCompanyDataCredits: number;
  organizationStructureAccessPermitted: boolean;
}
export const CreditInfoBoard = ({ phoneCredits, usedPhoneCredits, emailCredits, usedEmailCredits, companyDataCredits, usedCompanyDataCredits, organizationStructureAccessPermitted }: Props) => {
  return (
      <Grid container spacing={3}>
          <Grid item md={organizationStructureAccessPermitted ? 4 : 6}>
              <InfoCard
                  name="Your company exports used"
                  mr={1.5}
                  type="credits"
                  usedCredits={usedCompanyDataCredits}
                  credits={companyDataCredits}
                  width={'100%'}
                  displayInfoBadge={false}
              />
          </Grid>
          {
              organizationStructureAccessPermitted ? <>
                  <Grid item md={4}>
                      <InfoCard
                          name="Your email exports used"
                          mr={1.5}
                          type="credits"
                          usedCredits={usedEmailCredits}
                          credits={emailCredits}
                          width={'100%'}
                          displayInfoBadge={false}
                      />
                  </Grid>
                  <Grid item md={4}>
                      <InfoCard
                          name="Your phone exports used"
                          mr={1.5}
                          type="credits"
                          usedCredits={usedPhoneCredits}
                          credits={phoneCredits}
                          width={'100%'}
                          displayInfoBadge={false}
                      />
                  </Grid>
              </> : <></>
          }

      </Grid>
  )
}


import React from 'react';
import { ExportTab } from '../../../types';
import { EmployeeBulkExportTable } from './index';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'application/store';
import { bulkExportSelector } from 'common/bulkExport/store';

export const ExportEmployeesTab = () => {
  const { name } = useParams();
  const {
    allEmployees, newEmployees, exportedEmployees,
    allCompanies, newCompanies, exportedCompanies
  } = useAppSelector(bulkExportSelector);

  return (
    <>
      {name === ExportTab.NEW && <EmployeeBulkExportTable exportContacts={newEmployees}/>}
      {name === ExportTab.ALL && <EmployeeBulkExportTable exportContacts={allEmployees}/>}
      {name === ExportTab.EXPORTED && <EmployeeBulkExportTable exportContacts={exportedEmployees}/>}
    </>
  );
}

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import theme from "application/theme";
import React from "react";
import {ActivationBadge, TableCellTextStyled, TableContainerStyled} from "./styles";
import { Check, CloseOutlined } from "@mui/icons-material";
import { ActivationStatus, RequestType } from "common/organization/store/types";
import noInfo from "application/assets/noInfo.png";
import { formatDate } from "application/utils";
import { useAppSelector } from "application/store";
import { organizationsSelector } from "common/organization/store";
import { EmptyContentWrapper } from "../emptyInfo/styles";
import {CreditsQuantityItem} from "./CreditsQuantityItem";

const tableHead = ["Name", "Date", "Quantity", "Done by", "Status"];

const CreditTable = () => {
  const { requestHistory } = useAppSelector(organizationsSelector);
  return (
    <>
      {requestHistory.length ? (
        <TableContainerStyled>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ background: theme.palette.common.grey200 }}>
              <TableRow>
                {tableHead.map((headerItem) => (
                  <TableCell key={headerItem}>
                    <Typography
                      variant="body2"
                      color={theme.palette.common.grey800}
                    >
                      {headerItem}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {requestHistory &&
                requestHistory.map((row, idx) => (
                  <TableRow
                    key={idx}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <TableCellTextStyled>
                        {row.name}
                      </TableCellTextStyled>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <TableCellTextStyled>
                        {row.date ? formatDate(row.date) : row.date}
                      </TableCellTextStyled>
                    </TableCell>
                    <TableCell component="th" scope="row" sx={{ p: `${theme.spacing(1,2)}`}}>
                      <Box display="flex" flexDirection="column">
                        {row.requestType === RequestType.credits ? (
                          <>
                            <CreditsQuantityItem
                                label="Company"
                                value={row?.companyDataCreditsQuantity}
                            />
                            <CreditsQuantityItem
                                label="Email"
                                value={row?.emailCreditsQuantity}
                            />
                            <CreditsQuantityItem
                                label="Phone"
                                value={row?.phoneCreditsQuantity}
                            />
                          </>
                        ) : row.requestType === RequestType.seats ? (
                          <TableCellTextStyled>
                            Seats: {row.seatsQuantity}
                          </TableCellTextStyled>
                        ) : (
                          <TableCellTextStyled>
                            Subscription: {row.subscriptionQuantity}
                          </TableCellTextStyled>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <TableCellTextStyled>
                        {row.doneBy || "-"}
                      </TableCellTextStyled>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <ActivationBadge>
                        {row.status === ActivationStatus.accepted ? (
                          <Check
                            sx={{
                              width: theme.spacing(1.5),
                              height: theme.spacing(1.5),
                              color: theme.palette.common.grey900,
                            }}
                          />
                        ) : null}
                        {row.status === ActivationStatus.declined ? (
                          <CloseOutlined
                            sx={{
                              width: theme.spacing(1.5),
                              height: theme.spacing(1.5),
                              color: theme.palette.common.grey900,
                            }}
                          />
                        ) : null}
                        <Typography
                          variant="caption"
                          color={theme.palette.common.grey900}
                          ml={0.5}
                          textTransform="capitalize"
                        >
                          {row.status.toLowerCase()}
                        </Typography>
                      </ActivationBadge>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainerStyled>
      ) : (
        <EmptyContentWrapper>
          <img src={noInfo} alt="No results found" />
          <Typography
            variant="button"
            color={theme.palette.common.grey800}
            mt={2}
          >
            No requests history yet
          </Typography>
        </EmptyContentWrapper>
      )}
    </>
  );
};

export default CreditTable;


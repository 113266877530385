import React, { useMemo } from 'react';
import { MaxItemsCountTooltipWrapper } from './styles';
import { AlertCircle } from 'application/assets';
import { Typography } from '@mui/material';
import { getNumberFormatWithSeparators } from 'application/utils/numberUtils';
import {commonColors} from 'application/theme';
import { usePermitsViewSettings } from 'common/permits/hooks/usePermitsViewSettings';
import { useAppSelector } from 'application/store';
import { permitsSelector } from 'common/permits/store';

export const MaxItemsCountTooltip = () => {
  const { permitMapView, companyMapView } = useAppSelector(permitsSelector);
  const { displayCompaniesOnMap, displayPermitsOnMap } =
    usePermitsViewSettings();

  const isShown = useMemo(() => {
    if(displayPermitsOnMap && permitMapView?.totalElements > permitMapView?.size) {
      return true;
    }
    else if(displayCompaniesOnMap && companyMapView?.totalElements > companyMapView?.size) {
      return true;
    }
    return false;
  }, [permitMapView, companyMapView, displayCompaniesOnMap, displayPermitsOnMap]);

  const maxItemsCount = useMemo(() => {
    return Math.max(permitMapView?.size || 0, companyMapView?.size || 0);
  }, [permitMapView, companyMapView]);

  if (!isShown) {
    return <></>;
  }
  return (
    <MaxItemsCountTooltipWrapper>
      <AlertCircle color={commonColors.grey800}/>
      <Typography variant={'body2'} color={'textSecondary'}>
        {`You can view only ${getNumberFormatWithSeparators(maxItemsCount)} locations. In order to see the most relevant ones, use additional filters.`}
      </Typography>
    </MaxItemsCountTooltipWrapper>
  )
}

import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import theme from "application/theme";
import { MapPointIcon } from "common/permits/assets/MapPointIcon";
import { getNumberFormatWithSeparators } from "application/utils/numberUtils";
import { permitsSelector } from "common/permits/store";
import { useAppSelector } from "application/store";
export const MapViewResults = () => {
  const { permitMapView, companyMapView } = useAppSelector(permitsSelector);
  const totalCompaniesCount = companyMapView.totalElements;
  const totalPermitsCount = permitMapView.totalElements;
  return (
    <Box display="flex" gap={1.5}>
      <MapResultsItem label="Companies" count={totalCompaniesCount} />
      <MapResultsItem
        iconColor={theme.palette.common.orange800}
        label="Permits"
        count={totalPermitsCount}
      />
    </Box>
  );
};

interface MapResultsItemProps {
  count: number;
  label: string;
  iconColor?: string;
}
const MapResultsItem = ({ label, count, iconColor }: MapResultsItemProps) => {
  return (
    <Box display="flex" gap={0.5} alignItems={"center"}>
      <MapPointIcon color={iconColor} />
      <Typography variant="subtitle1" color={theme.palette.common.grey800}>
        {label} {getNumberFormatWithSeparators(count, " ")}
      </Typography>
    </Box>
  );
};

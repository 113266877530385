import React from 'react';
import TutorialIntro from './TutorialIntro';
import { filtersTutorialSteps } from '../steps';
import { tutorialType } from 'common/tutorial/store/types';
import { useTutorials } from 'common/tutorial/hooks/useTutorials';

export const CompanyFiltersTutorial = () => {
  const { setCompanyFiltersTutorialIsOpen} = useTutorials()
  const handleClickStartTutorial = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setCompanyFiltersTutorialIsOpen(true);
  };
  return (
    <TutorialIntro
      handleStartTutorial={handleClickStartTutorial}
      steps={filtersTutorialSteps}
      title={"How to filter companies"}
      type={tutorialType.FILTERS_TUTORIAL}
    />
  )
}
import theme from "application/theme";
import React, { useState } from "react";
import SvgIcon from "@mui/material/SvgIcon";

interface BinIconProps {
  color?: string;
  width?: number | string;
  height?: number | string;
  hoverColor?: string;
}

export const BinIcon = ({
  color = theme.palette.common.grey600,
  hoverColor = theme.palette.common.blue700,
  width = "14",
  height = "15",
  ...rest
}: BinIconProps) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <SvgIcon
      {...rest}
      viewBox="0 0 14 15"
      style={{ color: "none", width: width, height: height, fill: "none" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <path
        d="M8.20312 12.125C8.01172 12.125 7.875 11.9883 7.875 11.7969V5.89062C7.875 5.72656 8.01172 5.5625 8.20312 5.5625H8.85938C9.02344 5.5625 9.1875 5.72656 9.1875 5.89062V11.7969C9.1875 11.9883 9.02344 12.125 8.85938 12.125H8.20312ZM12.6875 2.9375C12.9062 2.9375 13.125 3.15625 13.125 3.375V3.8125C13.125 4.05859 12.9062 4.25 12.6875 4.25H12.25V13.4375C12.25 14.1758 11.6484 14.75 10.9375 14.75H3.0625C2.32422 14.75 1.75 14.1758 1.75 13.4375V4.25H1.3125C1.06641 4.25 0.875 4.05859 0.875 3.8125V3.375C0.875 3.15625 1.06641 2.9375 1.3125 2.9375H3.55469L4.48438 1.40625C4.70312 1.05078 5.19531 0.75 5.60547 0.75H8.36719C8.77734 0.75 9.26953 1.05078 9.48828 1.40625L10.418 2.9375H12.6875ZM5.55078 2.14453L5.08594 2.9375H8.88672L8.42188 2.14453C8.39453 2.11719 8.3125 2.0625 8.28516 2.0625H5.71484H5.6875C5.66016 2.0625 5.57812 2.11719 5.55078 2.14453ZM10.9375 13.4375V4.25H3.0625V13.4375H10.9375ZM5.14062 12.125C4.94922 12.125 4.8125 11.9883 4.8125 11.7969V5.89062C4.8125 5.72656 4.94922 5.5625 5.14062 5.5625H5.79688C5.96094 5.5625 6.125 5.72656 6.125 5.89062V11.7969C6.125 11.9883 5.96094 12.125 5.79688 12.125H5.14062Z"
        fill={isHovered ? hoverColor : color}
      />
    </SvgIcon>
  );
};

import React from 'react'
import { useLargeBulkExport } from './hooks/useLargeBulkExport';
import { ExportStage } from './store/types';
import {
  ConfirmExportModal,
  NoCreditsModal,
  StartExportModal,
  BackToSearchModal
} from './components';
import { ExportProcessingModal } from './components/ExportProcessingModal';
import { useSelector } from 'react-redux';
import { isLoaderSelector } from '../loaderModal/loaderSlice';

const LargeBulkExport = () => {
  const {exportStage, updateExportStage, openConfirmExportModal} = useLargeBulkExport();
  const { isLoading } = useSelector(isLoaderSelector);
  const showStartExportModal = exportStage === ExportStage.START;
  const showNoCreditsModal = exportStage === ExportStage.NO_CREDITS;
  const showConfirmExportModal = exportStage === ExportStage.CONFIRM;
  const showExportProcessingModal = exportStage === ExportStage.PROCESSING;
  const showBackToSearchModal = exportStage === ExportStage.BACK_TO_SEARCH;
  const handleCloseExport = () => {
    updateExportStage(null);
  }
  return (
    <>
      {
        isLoading ? null :
          <>
            <NoCreditsModal
              isOpen={showNoCreditsModal}
              onClose={handleCloseExport}/>
            <StartExportModal
              isOpen={showStartExportModal}
              onConfirm={openConfirmExportModal}
              onReject={handleCloseExport}
            />
            <ConfirmExportModal
              isOpen={showConfirmExportModal}
              onClose={handleCloseExport}/>
            <ExportProcessingModal
              isOpen={showExportProcessingModal}
              onClose={handleCloseExport}/>
            <BackToSearchModal
              isOpen={showBackToSearchModal}
              onClose={handleCloseExport}/>
          </>
      }
    </>
  )
}

export default LargeBulkExport;
import dayjs from 'dayjs';

const TIME_FORMAT_12H = 'hh:mm A';
const TIME_FORMAT_24H = 'HH:mm';

export const formatDateTime = (date: Date | string | undefined, format= "MMM DD, YYYY") => {
  return date ? dayjs(date).format(format) : null;
};

export const getTimeIn12HFormat = (date?: Date | string | null) => {
  return date ? dayjs(date).format(TIME_FORMAT_12H) : null;
}

export const getTimeIn24HFormat = (date?: Date | string | null) => {
  return date ? dayjs(date).format(TIME_FORMAT_24H) : null;
}
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import theme from "application/theme";
import React, { useState } from "react";
import { StyledWarning, TableContainerStyled } from "./styles";
import { Box } from "@mui/system";
import { Button } from "application";
import BadgeWithCheck from "application/components/BadgeWithCheck";
import { SubscriptionsI, TeamStatus } from "common/organization/store/types";
import { calculateDaysUntilDate, formatDate } from "application/utils";
import useToggleModal from "application/hooks/useToggleModal";
import { organizationsSelector } from "common/organization/store";
import { useAppSelector } from "application/store";
import { showLessThan60Days } from "./utils";
import EmptyInfoSubscription from "./emptyInfo";
import { ContactSales } from "common/organization/modals/ContactSales";

const tableHead = [
  "Start Date",
  "Sales Person Email",
  "Status",
  "Last Date",
  "Actions",
];

const SubscriptionTable = () => {
  const { isOpen, closeModal, openModal } = useToggleModal();
  const [subscriptionItem, setSubscriptionItem] =
    useState<SubscriptionsI | null>(null);
  const { subscriptions } = useAppSelector(organizationsSelector);

  const lastSubscriptionsIndex = subscriptions.length - 1;

  return (
    <Box>
      {subscriptions.length ? (
        <TableContainerStyled>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ background: theme.palette.common.grey200 }}>
              <TableRow sx={{ height: theme.spacing(7.5) }}>
                {tableHead.map((e) => (
                  <TableCell key={e}>
                    <Typography
                      variant="body2"
                      color={theme.palette.common.grey800}
                    >
                      {e}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptions.map((row: SubscriptionsI, index) => (
                <TableRow
                  key={row.id}
                  sx={{
                    height: theme.spacing(7.5),
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {formatDate(row.startDate || "")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      {row.salesPersonEmail}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="caption"
                      color={theme.palette.common.grey800}
                    >
                      <BadgeWithCheck status={row.status} />
                    </Typography>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Box display="flex" gap={1}>
                      <Typography
                        variant="caption"
                        color={theme.palette.common.grey800}
                      >
                        {formatDate(row.lastDate || "")}
                      </Typography>
                      {row.lastDate &&
                        calculateDaysUntilDate(row.lastDate) < 60 && (
                          <StyledWarning>
                            {showLessThan60Days(
                              calculateDaysUntilDate(row.lastDate)
                            )}
                          </StyledWarning>
                        )}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ width: "21%" }}>
                    {calculateDaysUntilDate(row.lastDate || "") < 60 &&
                      subscriptions.indexOf(row) === lastSubscriptionsIndex && (
                        <Button
                          label="Contact Sales"
                          height={4}
                          type="submit"
                          width={theme.spacing(16)}
                          textVariant="caption"
                          disabled={row.status === TeamStatus.archived}
                          onClick={() => {
                            setSubscriptionItem(row);
                            openModal();
                          }}
                        />
                      )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainerStyled>
      ) : (
        <EmptyInfoSubscription />
      )}
      {subscriptionItem && (
        <ContactSales
          isOpen={isOpen}
          handleClose={closeModal}
          subscriptionItem={subscriptionItem}
        />
      )}
    </Box>
  );
};

export default SubscriptionTable;

import { Box, styled } from "@mui/system";
import theme from "application/theme";

export const Dot = styled(Box)<{ active: number }>`
  width: ${theme.spacing(0.75)};
  height: ${theme.spacing(0.75)};
  border-radius: 100%;
  background-color: ${(props) =>
    props.active ? theme.palette.common.blue600 : theme.palette.common.grey400};
  cursor: pointer;
`;

import { createSlice } from "@reduxjs/toolkit";
import { store } from "application/store";

import {
  CompanyContactDataI,
  CompanyExtraInfoI,
  CompanyI,
  CompanyStructureI,
  CompanyFilterDataI,
  StructureI,
} from "integration/api/companies/types";
import { SortBy } from "../companiesFilter/const";
import { SortOrder } from 'application/types';

export const filterInitialValues : CompanyFilterDataI = {
  companyId: null,
  name: "",
  companySizeRange: [],
  annualRevenueRange: [],
  yearsInBusinessRange: [],
  isSubContractor: false,
  isGeneralContractor: false,
  subContractorCategories: [],
  hasPermit: false,
  permitFilters: {
    annualPermitCount: undefined,
    annualPermitValue: undefined
  },
  serviceType: [],
  reputationScore: "",
  permit: false,
  skipExported: false,
  tags: [],
  citiesInclude: [],
  citiesExclude: [],
  statesInclude: [],
  statesExclude: [],
  location: "BY_STATE",
  notGoodFit: null,
  productTypes: [],
};

const emptyFilter = {
  id: 0,
  userId: 0,
  name: "",
  filterValue: filterInitialValues,
};
export const initialState = {
  savedSearches: [emptyFilter],
  selectedSavedSearch: emptyFilter,
  filterFormData: filterInitialValues,
  filteredCompanies: {
    content: [],
    pageable: {
      sort: {
        empty: true,
        sorted: false,
        unsorted: true,
      },
      offset: 0,
      pageNumber: 0,
      pageSize: 20,
      paged: true,
      unpaged: false,
    },
    totalPages: 0,
    totalElements: 0,
    last: true,
    size: 20,
    number: 0,
    sort: {
      empty: true,
      sorted: false,
      unsorted: true,
    },
    numberOfElements: 0,
    first: true,
    empty: true,
  },
  company: {} as CompanyI,
  isCompanyInfoLoading: false,
  structure: {} as CompanyStructureI,
  selectedOrganization: {} as StructureI,
  isStructureLoading: false,
  isCompanyContactsLoading: false,
  tags: [],
  subContractorCategories: [],
  permitRangeValues: {
    annualPermitCount: undefined,
    annualPermitValue: undefined
  },
  exportResultModal: false,
  addedNotify: false,
  sort: {
    sortBy: SortBy.REPUTATION_SCORE,
    sortOrder: SortOrder.DESC
  },
  companyExtraInformation: {
    isLoading: true,
    data: [] as CompanyExtraInfoI[],
  },
  productTypes: [],
};

export const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    showCompanyInfoLoader: (state) => {
      state.isCompanyInfoLoading = true;
    },
    hideCompanyInfoLoader: (state) => {
      state.isCompanyInfoLoading = false;
    },
    setSavedSearches: (state, { payload }) => {
      state.savedSearches = payload;
    },
    setSelectedSearch: (state, { payload }) => {
      state.selectedSavedSearch = payload;
    },
    setFilteredCompanies: (state, { payload }) => {
      state.filteredCompanies = payload;
    },
    setSelectedCompany: (state, { payload }) => {
      state.company = payload;
    },
    setCompanyStructure: (state, { payload }) => {
      state.structure = payload;
    },
    showLoaderStructure: (state) => {
      state.isStructureLoading = true;
    },
    hideLoaderStructure: (state) => {
      state.isStructureLoading = false;
    },
    setFilterFormData: (state, { payload }) => {
      state.filterFormData = payload;
    },
    showCompanyContactsLoader: (state) => {
        state.isCompanyContactsLoading = true;
    },
    hideCompanyContactsLoader: (state) => {
        state.isCompanyContactsLoading = false;
    },
    setCompanyContacts: (state, { payload }) => {
      const newCompanyContactData: CompanyContactDataI = {
        ...state.company?.companyContactData,
        phones: payload.phones,
        emails: payload?.emails,
        isContactsAlreadyUnlocked: true
      }
      state.company = {
        ...state.company,
        companyContactData: newCompanyContactData,
      };
    },
    setSorting(state, { payload }) {
      state.sort = payload;
    },
    setPageSize: (state, { payload }) => {
      state.filteredCompanies = {
        ...state.filteredCompanies,
        size: payload,
      };
    },
    setTags: (state, { payload }) => {
      state.tags = payload;
    },
    setSubContractorCategories: (state, { payload }) => {
      state.subContractorCategories = payload;
    },
    setPermitRangeValues: (state, { payload }) => {
      state.permitRangeValues = payload;
    },
    setSelectedOrganization: (state, { payload }) => {
      state.selectedOrganization = payload;
    },
    showExportResult: (state) => {
      state.exportResultModal = true;
    },
    hideExportResult: (state) => {
      state.exportResultModal = false;
    },
    showNotify: (state) => {
      state.addedNotify = true;
    },
    hideNotify: (state) => {
      state.addedNotify = false;
    },
    setExtraInformationIsLoading: (state, { payload }) => {
      state.companyExtraInformation = {
        ...state.companyExtraInformation,
        isLoading: payload,
      };
    },
    setCompaniesExtraInformation: (state, { payload }) => {
      state.companyExtraInformation = {
        ...state.companyExtraInformation,
        data: payload,
      };
    },
    cleanUpCompaniesStore: (state) => {
      state.company = initialState.company;
      state.filterFormData = initialState.filterFormData;
      state.isCompanyInfoLoading = initialState.isCompanyInfoLoading;
      state.filteredCompanies = initialState.filteredCompanies;
      state.savedSearches = initialState.savedSearches;
      state.selectedSavedSearch = initialState.selectedSavedSearch;
      state.structure = initialState.structure;
      state.isStructureLoading = initialState.isStructureLoading;
      state.isCompanyContactsLoading = initialState.isCompanyContactsLoading;
      state.tags = initialState.tags;
      state.selectedOrganization = initialState.selectedOrganization;
      state.subContractorCategories = initialState.subContractorCategories;
      state.exportResultModal = initialState.exportResultModal;
      state.addedNotify = initialState.addedNotify;
      state.companyExtraInformation = initialState.companyExtraInformation;
    },
    setCompanyProductTypes: (state, { payload }) => {
      state.productTypes = payload;
    }
  },
});

export default companiesSlice.reducer;

export const savedSearchesSelector = () => store.getState().companies.selectedSavedSearch;

export const companyStructureSelector = () =>
  store.getState().companies.structure;

export const companyStructureContentSelector = () =>
  store.getState().companies.structure.content;

export const isStructureLoading = () =>
  store.getState().companies.isStructureLoading;

export const companyContactsLoadingSelector = () =>
    store.getState().companies.isCompanyContactsLoading;

export const selectedCompanyTags = () =>
  store.getState().companies.company.tags;

export const companySelector = () => store.getState().companies;

export const filteredCompaniesSelector = () =>
  store.getState().companies.filteredCompanies;

export const filterFormSelector = () =>
  store.getState().companies.filterFormData;

export const subContractorCategoriesSelector = () =>
  store.getState().companies.subContractorCategories;


export const selectedCompanySelector = () => store.getState().companies.company;

export const organizationSelector = () =>
  store.getState().companies.selectedOrganization;

export const resultExportSelector = () =>
  store.getState().companies.exportResultModal;

export const companySortSelector = () => store.getState().companies.sort;
export const addedNotifySelector = () => store.getState().companies.addedNotify;
export const companyExtraInformationSelector = () => store.getState().companies.companyExtraInformation;
export const { showCompanyInfoLoader } = companiesSlice.actions;
export const { hideCompanyInfoLoader } = companiesSlice.actions;
export const { setSavedSearches } = companiesSlice.actions;
export const { setSelectedSearch } = companiesSlice.actions;
export const { setFilteredCompanies } = companiesSlice.actions;
export const { setSelectedCompany } = companiesSlice.actions;
export const { setCompanyStructure } = companiesSlice.actions;
export const { showLoaderStructure } = companiesSlice.actions;
export const { hideLoaderStructure } = companiesSlice.actions;
export const { showCompanyContactsLoader } = companiesSlice.actions;
export const { hideCompanyContactsLoader } = companiesSlice.actions;
export const { setCompanyContacts } = companiesSlice.actions;
export const { setFilterFormData } = companiesSlice.actions;
export const { setTags } = companiesSlice.actions;
export const { setSubContractorCategories } = companiesSlice.actions;
export const { cleanUpCompaniesStore } = companiesSlice.actions;
export const { setSelectedOrganization } = companiesSlice.actions;
export const { hideExportResult } = companiesSlice.actions;
export const { showExportResult } = companiesSlice.actions;
export const { showNotify } = companiesSlice.actions;
export const { hideNotify } = companiesSlice.actions;
export const { setPermitRangeValues } = companiesSlice.actions;
export const { setSorting, setPageSize } = companiesSlice.actions;
export const { setExtraInformationIsLoading,
  setCompaniesExtraInformation,
  setCompanyProductTypes } = companiesSlice.actions;

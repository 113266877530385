import React from 'react';
import { useAppSelector } from 'application/store';
import { FiltersCollapse } from 'application';
import { FiltersWrapper, FilterTextStyled } from '../components/styles';
import { companyFiltersSelector } from 'common/permits/store';

export const CompanyNameFilter = () => {
  const {name} = useAppSelector(companyFiltersSelector);
  return name ? (
    <FiltersCollapse label={'COMPANY NAME OR WEBSITE'} count={1}>
      <FiltersWrapper>
        <FilterTextStyled>
          {name}
        </FilterTextStyled>
      </FiltersWrapper>
    </FiltersCollapse>
  ) : null;
}
export const permitColumnsList = [
  {
    label: "Status normalized",
    value: "statusNormalized",
  },
  {
    label: "File date",
    value: "fileDate",
  },
  {
    label: "Permit date",
    value: "permitDate",
  },
  {
    label: "File permit timeframe",
    value: "filePermitTimeframe",
  },
  {
    label: "Final date",
    value: "finalDate",
  },
  {
    label: "Permit final timeframe",
    value: "permitFinalTimeframe",
  },
  {
    label: "Job value",
    value: "jobValue",
  },
  {
    label: "Street",
    value: "street",
  },
  {
    label: "City",
    value: "city",
  },
  {
    label: "Zipcode",
    value: "zipcode",
  },
  {
    label: "County",
    value: "county",
  },
  {
    label: "State",
    value: "state",
  },
  {
    label: "Description",
    value: "description",
  },

  // record_type_original ???
  // *record_subtype_original ???
  {
    label: "Total fees",
    value: "totalFees",
  },
  {
    label: "Contractor license",
    value: "contractorLicense",
  },
  {
    label: "Contractor biz name original",
    value: "contractorBizNameOriginal",
  },
  {
    label: "Contractor name",
    value: "contractorName",
  },
  {
    label: "Contractor city",
    value: "contractorCity",
  },
  {
    label: "Contractor zipcode",
    value: "contractorZipcode",
  },
  {
    label: "Owner name",
    value: "ownerName",
  },
  {
    label: "Owner street",
    value: "ownerStreet",
  },
  {
    label: "Owner city",
    value: "ownerCity",
  },
  {
    label: "Owner zipcode",
    value: "ownerZipcode",
  },
  {
    label: "Applicant name",
    value: "applicantName",
  },
  {
    label: "Applicant street",
    value: "applicantStreet",
  },
  {
    label: "Applicant city",
    value: "applicantCity",
  },
  {
    label: "Applicant state",
    value: "applicantState",
  },
  {
    label: "Applicant zipcode",
    value: "applicantZipcode",
  },
  {
    label: "Architect license",
    value: "architectLicense",
  },
  {
    label: "Architect name",
    value: "architectName",
  },
  {
    label: "Architect street",
    value: "architectStreet",
  },
  {
    label: "Architect city",
    value: "architectCity",
  },
  {
    label: "Architect state",
    value: "architectState",
  },
  {
    label: "Architect zipcode",
    value: "architectZipcode",
  },
  {
    label: "Engineer license",
    value: "engineerLicense",
  },
  {
    label: "Engineer name",
    value: "engineerName",
  },
  {
    label: "Engineer street",
    value: "engineerStreet",
  },
  {
    label: "Engineer city",
    value: "engineerCity",
  },
  {
    label: "Engineer state",
    value: "engineerState",
  },
  {
    label: "Engineer zipcode",
    value: "engineerZipcode",
  },
  {
    label: "Designer license",
    value: "designerLicense",
  },
  {
    label: "Designer name",
    value: "designerName",
  },
  {
    label: "Designer street",
    value: "designerStreet",
  },
  {
    label: "Designer city",
    value: "designerCity",
  },
  {
    label: "Designer state",
    value: "designerState",
  },
  {
    label: "Designer zipcode",
    value: "designerZipcode",
  },
  {
    label: "Actions",
    value: "id",
  },
];

import { Box, styled } from '@mui/system';
import theme from 'application/theme';

export const CompanyFiltersGroupWrapper = styled(Box)`
   > .MuiBox-root {
    padding: ${theme.spacing(2.5, 0)};
    border-bottom: 1px solid ${theme.palette.common.grey400};
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
`
import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import theme from "application/theme";

export const TooltipWrapper = styled(Box)<{ active: number }>`
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: ${theme.spacing(1.5)};
  z-index: 100;
  // height: ${theme.spacing(43.75)};
  background-color: ${theme.palette.common.white};
  display: ${(props) => (props.active ? "flex" : "none")};
  width: ${theme.spacing(68)};
`;
export const ArrowDown = styled(Box)`
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  border-top: 20px solid #f00;
`;

export const NavigationWrapper = styled(Box)`
  width: ${theme.spacing(2.75)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const InfoWrapper = styled(Box)`
  width: 100%;
  padding: ${theme.spacing(2)};
  border-radius: ${theme.spacing(2)};
  border: 1px solid ${theme.palette.common.grey300};
  margin-top: ${theme.spacing(2.75)};
  height: 100%;
`;

export const TooltipNavBarWrapper = styled(Box)`
  height: ${theme.spacing(2.75)};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const TypographyStyled = styled(Typography)`
  color: ${theme.palette.common.grey900};
  white-space: initial;
  owerflow-wrap: brake-all;
  width: 100%;
`;

export const RatingWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${theme.spacing(1)};
  width: ${theme.spacing(16)};
`;

export const LoaderWrapper = styled(Box)`
  height: ${theme.spacing(29.25)};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

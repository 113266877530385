export const companyColumnsList = [
  {
    label: "Company name",
    value: "businessName",
  },
  {
    label: "Visibility Score",
    value: "reputationScore",
  },
  {
    label: "Website",
    value: "companyContactInfo",
  },
  {
    label: "Major category",
    value: "serviceType",
  },
  {
    label: "Minor category",
    value: "generalContractorCategory",
  },
  {
    label: "Pulls permit",
    value: "permit",
  },
  {
    label: "Average Permit Value",
    value: "avgAnnualValue",
  },
  {
    label: "Actions",
    value: "id",
  },
];

import { Box, styled } from '@mui/system';
import theme from 'application/theme'

export const ActionsWrapper = styled(Box)`
  display: flex;
  flex: 0;
  justify-content: space-between;
  padding: ${theme.spacing(1,1,2,1)};
  gap: ${theme.spacing(2)};
  align-self: stretch;
`

import { useLocation, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

export const usePageQueryParams = () => {
  const location = useLocation();
  const pageParams = location.search.split("?")[1];
  const currentPage = useMemo(() => {
    let currentPage = Number(pageParams);
    return isNaN(currentPage) ? 1 : currentPage;
  }, [pageParams]);
  const navigate = useNavigate();
  const setCurrentPage = (page: number) => {
    navigate(`${location.pathname}?${page}`);
  };
  return { currentPage, setCurrentPage };
}
import React, { useEffect, useRef, useState } from "react";
import { PermitFiltersWrapper } from "./styles";
import { FiltersHeader } from "../FiltersHeader";
import { Box } from "@mui/system";
import { Actions } from "./Actions";
import { FiltersBody } from "./FiltersBody";
import { useSdrProfile } from "common/sdrProfile/hooks/useSdrProfile";
import { useAppDispatch, useAppSelector } from "application/store";
import {
  handleGetCompanyAndPermitMapView,
  handleGetPrepopulatedFilterValues,
  handleSearchCompaniesAndPermitsOnBackground,
  handleSearchPermitsAndCompaniesOnBackground,
} from "common/permits/store/actions";
import { usePermitFiltersContext } from "common/permits/hooks/usePermitFiltersContext";
import { usePermitsNavigation } from "common/permits/hooks/usePermitsNavigation";
import { permitFilterInitialState, permitsSelector, setFilterFormData } from '../../store';
import { cloneDeep } from 'lodash';
import useUpdateLastActivityDate from 'application/hooks/useUpdateLastActivityDate';
import { usePermitsWithCompaniesSearch } from 'common/permits/hooks/usePermitsWithCompaniesSearch';

interface Props {
  onCloseSidebar: () => void;
}
export const PermitFilters = ({ onCloseSidebar }: Props) => {
  const [isFiltersShown, setFiltersShown] = useState(true);
  const { isMapView, isPermitTableMode } = usePermitsNavigation();
  const { zoomedArea, searchInArea, filterFormData } = useAppSelector(permitsSelector);
  const { filtersTotalCount, permitFilterForm, companyFilterForm,
    filtersWithZoomedArea, filtersWithoutZoomedArea, clearFilters } = usePermitFiltersContext();
  const { searchForCompaniesAndPermitsCoordinates, searchForCompaniesAndPermitsOnBackground, searchForPermitsAndCompaniesOnBackground } = usePermitsWithCompaniesSearch()
  const { updateLastActivityDate } = useUpdateLastActivityDate();
  const { sdrProfile } = useSdrProfile();
  const dispatch = useAppDispatch();
  const isMounted = useRef(true);

  useEffect(() => {
    if (sdrProfile.organizationId && isMounted.current) {
      dispatch(handleGetPrepopulatedFilterValues());
      isMounted.current = false;
    }
  }, [sdrProfile.organizationId, dispatch]);

  const isMountedForFilters = useRef(true);
  useEffect(() => {
    if(isMountedForFilters.current) {
      isMountedForFilters.current = false;
      permitFilterForm.reset(filterFormData.permitFilter);
      companyFilterForm.reset(filterFormData.companyFilter);
    }
  },[]);

  const handleSubmit = () => {
    if (isMapView) {
      searchForCompaniesAndPermitsCoordinates(filtersWithoutZoomedArea);
    } else {
      if (isPermitTableMode) {
        searchForPermitsAndCompaniesOnBackground(filtersWithZoomedArea)
      } else {
        searchForCompaniesAndPermitsOnBackground(filtersWithZoomedArea)
      }
    }
  };
  const handleClear = () => {
    clearFilters();
  };

  return (
    <PermitFiltersWrapper>
      <Box paddingX={1} flex={0}>
        <FiltersHeader
          count={filtersTotalCount}
          displayFilters={isFiltersShown}
          onToggleFilters={onCloseSidebar}
        />
      </Box>
      <FiltersBody />
      <Actions onSubmit={handleSubmit}
               onClear={handleClear} />
    </PermitFiltersWrapper>
  );
};

import React from "react";
import { Button } from "application";
import { Box, Typography } from "@mui/material";
import theme from "application/theme";
import { TeamMemberNameStyled } from "../../styles";
import { ModalComponent } from "application/components/ModalComponent";
import ManagementTeamCreditsTable from "./ManagementTeamCreditsTable";
import { LinkStyled } from "./styles";
import { useAppDispatch, useAppSelector } from "application/store";
import {
  openAddCreditsModal,
  organizationsSelector,
} from "common/organization/store";
import { handleUpdateSdrCredits } from "common/organization/store/actions";
import { UpdateSdrCredits } from "integration/api/organizations/types";
import { PathNames } from "application/routes";
import { RoutesParam } from "application/routes/constants";
import {CreditsType} from "../AddCreditsForTeamMember";

export const ManagementTeamCreditsModal = ({
  isOpen,
  handleClose,
}: {
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const {
    managementTeamCreditsError,
    changedCredits,
    organizationProfile,
    teamMembers,
  } = useAppSelector(organizationsSelector);
  const { organizationStructureAccessPermitted } =  organizationProfile
  const dispatch = useAppDispatch();
  const prepareCreditsToUpdate = (credits: CreditsType) => {
    return organizationStructureAccessPermitted ? {
          phoneCredits: credits.phoneCredits,
          emailCredits: credits.emailCredits,
          companyDataCredits: credits.companyDataCredits
        } :
        {companyDataCredits: credits.companyDataCredits}
  }
  const onSubmit = () => {
    const dataForRequest: UpdateSdrCredits = changedCredits.map((e) => {
      return {
        sdrId: e.id,
        ...prepareCreditsToUpdate(e),
      };
    });
    handleClose();
    dispatch(handleUpdateSdrCredits(dataForRequest, organizationProfile.id));
  };


  const handleAddMoreCredits = () => {
    handleClose();
    dispatch(openAddCreditsModal());
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      handleClose={handleClose}
      padding={4}
      width={"fit-content"}
    >
      <Box>
        <Box mb={1.5}>
          <Typography
            variant="h5"
            color={theme.palette.common.grey800}
            mb={1.5}
          >
            Manage Team Credits
          </Typography>
          <Typography variant="caption" color={theme.palette.common.grey700}>
            Then contact persons:{" "}
            {teamMembers.map((e, idx) => (
              <TeamMemberNameStyled key={idx}>
                {e.firstName},&nbsp;
              </TeamMemberNameStyled>
            ))}
            will get email notification about it.
          </Typography>
        </Box>
        <Box>
          <ManagementTeamCreditsTable />
        </Box>
        <Box mt={3} display="flex" justifyContent="space-between">
          <Box>
            <Typography
              variant="caption"
              color={theme.palette.common.grey700}
              display="block"
            >
              To add more credits to a seat, please
            </Typography>
            <LinkStyled
              to={`${PathNames.teamManagement}/${RoutesParam.requestHistory}`}
              onClick={handleAddMoreCredits}
            >
              request new export credits
            </LinkStyled>
            &nbsp;
            <Typography
              variant="caption"
              color={theme.palette.common.grey600}
              fontWeight={500}
            >
              for&nbsp;
            </Typography>
            <Typography
              variant="caption"
              color={theme.palette.common.turquoise600}
              fontWeight={500}
            >
              {organizationProfile.companyName}
            </Typography>
          </Box>
          <Box display="flex" gap={2} ml={2}>
            <Button
              label="Cancel"
              height={5.5}
              width={120}
              type="button"
              onClick={handleClose}
            />
            <Button
              label="Apply"
              disabled={!!managementTeamCreditsError}
              height={5.5}
              width={120}
              type="submit"
              onClick={onSubmit}
            />
          </Box>
        </Box>
      </Box>
    </ModalComponent>
  );
};

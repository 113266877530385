import React from "react";
import theme from 'application/theme';
import { IconProps } from 'application/assets/types';

export const TableViewIcon: React.FC<IconProps> = ({color = theme.palette.common.grey800}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
      <path
        d="M7.16667 4.99992L18 5.00057M7.16667 9.99992L18 10.0006M7.16667 14.9999L18 15.0005M3 5.41659H3.83333V4.58325H3V5.41659ZM3 10.4166H3.83333V9.58325H3V10.4166ZM3 15.4166H3.83333V14.5833H3V15.4166Z"
        stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};


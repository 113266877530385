import React, { useState } from "react";
import { Headerwrapper, TypographyWithOverflow, } from "./styles";
import theme, { commonColors } from "application/theme";
import { Box } from "@mui/system";
import TextItem from "./TextItem";
import { getNumberFormatWithSeparators } from "application/utils";
import { PermitsInfo } from "common/permits/types";
import { TooltipActions } from './TooltipActions';
import { useViewDetails } from 'common/permits/hooks/useViewDetails';
import { useCompaniesAndPermitsExport } from 'common/permits/hooks/useCompaniesAndPermitsExport';

interface PermitTooltipItemProps {
  show: boolean;
  data: PermitsInfo;
}

const PermitTooltipItem = ({show, data}: PermitTooltipItemProps) => {
  const {showPermitDetails} = useViewDetails();
  const {exportPermit, addPermitToBulkExport} = useCompaniesAndPermitsExport();
  const [addedToBulk, setAddedToBulk] = useState(false);
  const handleExportNow = () => {
    exportPermit(data.id);
  }
  const handleAddToBulkExport = () => {
    addPermitToBulkExport(data.id).then((result) => {
      if (result) {
        setAddedToBulk(true);
      }
    });
  }
  const handleViewDetails = () => {
    showPermitDetails(data.id);
  }
  return (
    <Box sx={{display: show ? "block" : "none"}}>
      <Headerwrapper>
        <TypographyWithOverflow
          variant="overline"
          color={commonColors.grey900}
          fontWeight={500}
        >
          {data.description ? data.description : "No description"}
        </TypographyWithOverflow>
      </Headerwrapper>
      <Box mb={theme.spacing(1.5)}>
        <TextItem
          label="Type"
          value={
            data.residential && data.commercial
              ? "Residential / Commercial"
              : data.residential
                ? "Residential"
                : "Commercial"
          }
        />
        <TextItem
          label="Current status"
          value={
            data.statusNormalized ? data.statusNormalized : "Unable to classify"
          }
        />
        <TextItem
          label="Address"
          value={`${data.state}, ${data.zipcode}, ${data.county}, ${data.city}, ${data.street}`}
        />
        <TextItem
          label="Job Value"
          value={data?.jobValue ? `$${getNumberFormatWithSeparators(data?.jobValue)}` : ' - '}
        />
      </Box>
      <TooltipActions
        onExportNow={handleExportNow}
        onAddToBulkExport={handleAddToBulkExport}
        onViewDetails={handleViewDetails}
        showNotGoodFit={false}
        addedToBulk={addedToBulk}
      />
    </Box>
  );
};

export default PermitTooltipItem;
